export class InternalError extends Error {
  message = 'Произошла ошибка удаленного сервера, попробуйте перезагрузить страницу.';
}

export class EmailCheckError extends Error {
  constructor(errorDescription: string[]) {
    const message = `Для указанных email ( ${errorDescription.join(',')} ) уже запланировано формирование отчетов. Проверьте введенные email и попробуйте снова.`
    super(message)
  }
}


