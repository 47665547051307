import React, { useEffect, useState } from 'react';
import { IRuleReport, TRuleReportDataSales } from '../../../../types/app/fraud/RuleReport';
import { Box, Card, Grid, Typography } from '@mui/material';
import BackButton from '../../../../UI/Button/BackButton';
import Divider from '../../../../UI/Delimiter/Divider';
import TypographyLoader from '../../../Common/Loader/TypographyLoader';
import {getPeriod, getRuleName} from '../../../../utils/fraudUtils';
import {ClientWithCard, useFraudDataset} from '../hooks/useFraudDataset';
import EmptyCell from '../../../../UI/Table/EmptyCell';
import { FraudSettingConditionNameEnum, FraudSettingConditionNames } from '../../../../enums/FraudRules';
import { TPersonal } from '../../../../types/app/client/Personal';
import { IIdentificationVirtualCard } from '../../../../types/app/account/Identification';

const SalesFraudShowCard = (props: IRuleReport) => {
  const ruleData = props.data as TRuleReportDataSales;

  const [personalData, setPersonalData] = useState<TPersonal>();
  const [card, setCard] = useState<IIdentificationVirtualCard>();
  const [client, setClient] = useState<ClientWithCard>();

  const { partner, clients } = useFraudDataset({
    partnerId: ruleData.partnerId,
    userIds: [props.userId],
  });

  useEffect(() => {
    if (clients) {
      setPersonalData(clients[0]?.personalData ?? null);
      setCard(clients[0]?.card ?? null);
      setClient(clients[0] ?? null);
    }
  }, [clients]);

  return (
    <Box>
      <BackButton />

      <Card sx={{ padding: '70px 50px', width: '85%' }}>
        <Box>
          <Typography mb={1} variant="body2" color="grey.600">
            Наименование правила:
          </Typography>
          <Typography variant="h2">{getRuleName(props.ruleSetting.rule.name)}</Typography>
        </Box>

        <Divider />

        <Grid container columns={3} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              {FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.ORDERS_COUNT_MAX)}
            </Typography>
            <Typography>{ruleData.ordersCountMax ?? <EmptyCell />}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Фактическое количество транзакций Пользователя:
            </Typography>
            <Typography>{ruleData.ordersCount ?? <EmptyCell />}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Период отслеживания:
            </Typography>
            <Typography>{getPeriod(ruleData.startPeriodDate, ruleData.endPeriodDate)}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container columns={3} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              ФИО Пользователя:
            </Typography>
            <TypographyLoader object={personalData} index="fullName" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Телефон Пользователя:
            </Typography>
            <TypographyLoader object={personalData} index="phone" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Email Пользователя:
            </Typography>
            <TypographyLoader object={client} index="email" />
          </Grid>
        </Grid>

        <Divider />

        <Grid container columns={3} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Номер виртуальной карты Пользователя:
            </Typography>
            <TypographyLoader object={card} index="number" />
          </Grid>
        </Grid>

        <Divider />

        <Grid container columns={3} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              ID терминальной точки:
            </Typography>
            <Typography>{ruleData.partnerTerminalId}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Телефон Партнера:
            </Typography>
            <TypographyLoader object={partner} index="phone" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Email Партнера:
            </Typography>
            <TypographyLoader object={partner} index="email" />
          </Grid>


        </Grid>

        <Divider />

        <Box>
          <Typography mb={1} variant="body2" color="grey.600">
            Наименование организации Партнера:
          </Typography>
          <TypographyLoader object={partner} index="label" />
        </Box>
      </Card>
    </Box>
  );
};

export default SalesFraudShowCard;
