import React from 'react';
import { Box } from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import SettingsTabForm from './SettingsTabForm';

const SettingsTabContentMain = () => {
  return (
    <Box>
      <TabTitle title="Общие настройки" />

      <SettingsTabForm />
    </Box>
  );
};

export default SettingsTabContentMain;