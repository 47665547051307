import React, {useState} from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '../../../UI/Table/TableContainer';
import {IPaginatable} from '../../../types/app/common/Paginatable';
import {getReportTypeName} from '../../../enums/ReportTypes';
import {Grid} from '@mui/material';
import DeleteButton from '../../../UI/Button/DeleteButton';
import Confirm from '../../Common/Modal/Confirm';
import {IReportRulesItem} from '../../../types/app/reports/IGetReportRulesData';
import {getReportPeriodName} from '../../../enums/ReportPeriodTypes';
import {RouteList} from '../../../http/RouteList';
import ShowButton from '../../../UI/Button/ShowButton';
import {ReportService} from '../../../http/reports/ReportService';
import {messages} from '../../../notifications/messages';
import {useNotifications} from '../../../hooks/useNotifications';

interface ReportRulesTabContentProps extends IPaginatable<IReportRulesItem> {
  setRerender: ()=>void
}

const AutoReportsTabContentData = (props: ReportRulesTabContentProps) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [reportId, setReportId] = useState<number>(null)
  const message = useNotifications();

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const deleteReportHandler = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await ReportService.deleteReportRule({id: reportId});
      props.setRerender()
      message.success(messages.success.reportDeleteOperation);
    }
    catch {
      message.error(messages.errors.generalError);

    }
    finally {
      setReportId(null)
      setIsLoading(false);
      closeConfirm();
    }
  };
  return (
    <TableContainer>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteReportHandler}
        text={`Вы действительно хотите удалить отчет? Данное действие нельзя будет отменить.`}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={360}> Тип отчёта </TableCell>
            <TableCell width={200}> Количество дней/недель/месяцев/лет </TableCell>
            <TableCell width={100}> Периодичность </TableCell>
            <TableCell width={300}> Email для отправки отчёта </TableCell>
            <TableCell width={300}> Партнер </TableCell>
            <TableCell width={80}> Формат отчёта </TableCell>
            <TableCell width={80}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(report => (
            <TableRow key={report.id} sx={{'&:last-child td, &:last-child th': {border: 0}, width: '100%'}}>
              <TableCell>{getReportTypeName(report.reportType)}</TableCell>
              <TableCell align='center'>{report.periodValue}</TableCell>
              <TableCell align='center'>{getReportPeriodName(report.periodType)}</TableCell>
              <TableCell>{report.email}</TableCell>
              <TableCell>{report.partnerName}</TableCell>
              <TableCell>{report.format}</TableCell>
              <TableCell>
                <Grid container display='flex' columns={2} spacing={2}  justifyContent="flex-end">
                  <Grid item xs={1}>
                    <ShowButton linkProps={ {to: RouteList.REPORTS_RULES_SHOW + '/' + report.id} } />
                  </Grid>
                  <Grid item xs={1}>
                    <DeleteButton disabled={isLoading} onClick={() => {
                      setReportId(report.id)
                      setShowConfirm(true)
                    }} />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AutoReportsTabContentData;