import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { ButtonProps } from '@mui/material/Button';

const FormSelectRemoveAllTweak = (props?: ButtonProps) => {
  return (
    <Tooltip title="Удалить все выбранные">
      <IconButton {...props}>
        <CancelIcon color="error" sx={{ fontSize: 20 }} />
      </IconButton>
    </Tooltip>
  );
};

export default FormSelectRemoveAllTweak;
