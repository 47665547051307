import { IAuthUser } from '../../types/app/auth/AuthUser';
import { AuthActions } from './AuthActions';
import { defer } from 'react-router-dom';
import { decode } from 'js-base64';
import { IRole } from '../../types/app/auth/Role';
import { AuthService } from './AuthService';
import { IGetUserByEmailRequest } from '../../types/request/auth/GetUserByEmailRequest';
import { ISiteSection } from '../../types/app/auth/SiteSection';
import { SiteTypes } from '../../enums/SiteTypes';
import {ILoggedUser} from '../../types/app/auth/LoggedUser';

export interface IGetUserLoader {
  userData: {
    user: ILoggedUser;
    roles: IRole[];
  };
}

export interface IGetRolesLoader {
  roles: IRole[];
}

export interface IGetSiteSectionsLoader {
  sections: ISiteSection[];
}

export interface IGetRoleLoader {
  role: IRole,
}

export class AuthLoaders {
  public static authenticateLoader = async (): Promise<IAuthUser> => {
    try {
      return AuthActions.getAuthUserAction();
    } catch (e) {
      return null;
    }
  };

  public static getRolesLoader = async () => {
    return defer({
      roles: AuthService.getRoles(),
    });
  };

  public static getUserWithRolesLoader = async ({ params }) => {
    const email = decode(params.email);

    const request: IGetUserByEmailRequest = { email };

    return defer({
      userData: Promise.all([AuthService.getUserByEmail(request), AuthService.getRoles()]).then(
        response =>
          ({
            userData: {
              user: response[0],
              roles: response[1],
            },
          } as IGetUserLoader)
      ),
    });
  };

  public static getRoleLoader({ params }) {
    return defer({
      role: AuthService.getRole({ id_or_name: params.id }),
    });
  }

  public static getOperatorSectionsLoader() {
    return defer({
      sections: AuthService.getSiteSections({ site: SiteTypes.OPERATOR }),
    });
  }

  public static getPartnerSectionsLoader() {
    return defer({
      sections: AuthService.getSiteSections({ site: SiteTypes.PARTNER }),
    });
  }
}
