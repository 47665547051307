import React, {Dispatch} from 'react';
import FormOutlinedInput from '../../../../../UI/Form/FormOutlinedInput';
import {TariffTypes} from '../../../../../enums/TariffTypes';
import Divider from '../../../../../UI/Delimiter/Divider';
import {ITariffDetail} from '../../../../../types/app/billing/TariffDetail';

interface ITariffCommonInputsProps {
  type: TariffTypes,
  percentOrAmount: number | string,
  dispatchPercentOrAmount: Dispatch<number>,
  isDisabled?: boolean
  tariff?: ITariffDetail
  lifetimeDays?: number
  setLifetimeDays?: Function
}

const TariffCommonInputs = (props: ITariffCommonInputsProps) => {
  return (
    <>
      <FormOutlinedInput
        title={
          props.type === TariffTypes.AMOUNT && 'Размер бонуса'
          || props.type === TariffTypes.CASHBACK && 'Размер вознаграждения (процент)'
          || props.type === TariffTypes.PERCENT && 'Размер скидки (процент)'
        }
        inputProps={{
          disabled: props.isDisabled,
          name: props.type === TariffTypes.AMOUNT ? 'amount' : 'percent',
          placeholder: 'Введите размер ' + (props.type === TariffTypes.AMOUNT ? 'бонуса' : 'скидки (процент)'),
          value: props.percentOrAmount,
          onChange: e => props.dispatchPercentOrAmount(Number(e.target.value)),
          sx: {
            width: '50%',
          },
        }}
        rule={e => !isNaN(Number(e.target.value))}
      />

      <Divider/>

      {props.type === TariffTypes.AMOUNT || props.type === TariffTypes.CASHBACK ? (
        <>
          <FormOutlinedInput
            title="Срок действия баллов"
            rule={e => !isNaN(Number(e.target.value))}
            inputProps={{
              disabled: props.tariff.active === 1,
              name: 'lifetime_days',
              onChange: e => props.setLifetimeDays(Number(e.target.value)),
              value: props.lifetimeDays,
              sx: {
                width: '50%',
              },
            }}
          />

          <Divider/>
        </>
      ) : null}
    </>
  );
};

export default TariffCommonInputs;