import React, { useContext } from 'react';
import {Box, Button, Typography} from '@mui/material';
import { FormContext } from '../../store/context/form/FormContext';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';

const FormEditButtonV2 = () => {
  const { setConfig, config } = useContext(FormContext);

  const readOnlyHandler = () => {
    setConfig({ ...config, readonly: false });
  };

  return (
    <Box position="absolute" right={50} bottom={35}>
      <Button sx={{ backgroundColor: '#ECF5FF' }} onClick={readOnlyHandler} variant="outlined" endIcon={<EditIcon />}>
        <Typography fontFamily="Lato-Semibold" variant="body2">Редактировать</Typography>
      </Button>
    </Box>
  );
};

export default FormEditButtonV2;
