import React from 'react';
import {Grid} from '@mui/material';
import HeaderAvatarButton from './HeaderAvatarButton';
import HeaderInfo from './HeaderInfo';
import HeaderBalances from './HeaderBalances';

const Header = () => {
  return (
    <Grid
      sx={{paddingTop: '58px', marginBottom: ' 50px'}}
      alignItems="center"
      container
      columns={12}
      spacing={5}
      justifyContent="space-between"
    >
      <Grid item xs={6}>
        <HeaderBalances/>
        {/*<HeaderControls />*/}
      </Grid>
      <Grid item xs={5}>
        <Grid columns={5} gap={1} container justifyContent="flex-end">
          <Grid item xs={1}>
            <HeaderInfo/>
          </Grid>
          <Grid item xs={3} sx={{position: 'relative'}}>
            <HeaderAvatarButton/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
