import React from 'react';
import {Grid} from '@mui/material';
import FilterGroupButtons from '../../UI/Button/FilterGroupButtons';
import PerPage from '../../UI/Table/PerPage';
import {IFilterable} from '../../types/app/common/Filterable';

const OrdersTabDataTweaks = (props: IFilterable) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
      </Grid>
      <Grid item>
        <PerPage />
      </Grid>
    </Grid>
  );
};

export default OrdersTabDataTweaks;