import React, { useEffect } from 'react';
import { CommunicationService } from '../../../http/communication/CommunicationService';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import { Box } from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import TemplateTabData from './TemplateTabData';
import { TemplateSystems } from '../../../enums/TemplateTypes';
import TemplateTabDataTweaks from './TemplateTabDataTweaks';
import TemplateTabFilters from './TemplateTabFilters';
import TabLoader from '../../Common/Loader/TabLoader';
import { ITemplate } from '../../../types/app/communication/Template';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilterState: IFilters = {
  like: {
    header: '',
    short: '',
    category: '',
  },
  eq: {
    type: null,
  },
  comparison: {
    header: 'web.header',
    short: 'web.short',
    category: 'web.category',
  },
};

const TemplateTabMain = () => {
  const { setItems, setFilters, items, getPaginatable, getFilterable, getDialogFilterable } = useTweakTool<ITemplate>();

  useEffect(() => {
    CommunicationService.getTemplates().then(templates =>
      setItems(templates.filter(template => template.name !== TemplateSystems.CLEAR))
    );

    setFilters(defaultFilterState);
  }, []);

  if (items === null) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="Шаблоны уведомлений" />

      <TemplateTabFilters {...getDialogFilterable} />

      <TemplateTabDataTweaks {...getFilterable} />

      <Divider />

      <TemplateTabData {...getPaginatable} dispatch={setItems} />
    </Box>
  );
};

export default TemplateTabMain;
