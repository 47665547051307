import React, {useContext, useEffect, useState} from 'react';
import {useAsyncValue, useNavigate} from 'react-router-dom';
import {Box, Grid, LinearProgress, Typography} from '@mui/material';
import {RouteList} from '../../../../http/RouteList';
import Divider from '../../../../UI/Delimiter/Divider';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import {FormContext} from '../../../../store/context/form/FormContext';
import MainForm from '../../../Common/Form/MainForm';
import FormActions from '../../../../UI/Form/FormActions';
import FormDatePicker from '../../../../UI/Pickers/FormDatePicker';
import {IGetDataForNewReportLoader} from '../../../../http/reports/ReportLoaders';
import {getReportTypeNames, ReportTypes} from '../../../../enums/ReportTypes';
import {messages} from '../../../../notifications/messages';
import {useNotifications} from '../../../../hooks/useNotifications';

const ReportCreateForm = () => {

  const {partners, reportTypes} = useAsyncValue() as IGetDataForNewReportLoader;
  const {setConfig} = useContext(FormContext);

  const message = useNotifications();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportType, setReportType] = useState<ReportTypes>(ReportTypes.TURNOVER_POINTS);

  const navigate = useNavigate()

  const getRules = () => {
    if (reportType === ReportTypes.TURNOVER_POINTS || reportType === ReportTypes.SUMMARY) {

      return {
        partner: ['required'],
        format: ['required'],
        start_date: ['required'],
        end_date: ['required'],
      }
    } else {

      return {
        partner: ['required'],
        format: ['required'],
      }
    }
  }

  useEffect(() => {
    setConfig({
      action: RouteList.REPORTS_CREATE,
      readonly: false,
      rules: getRules(),
      afterSubmitHandler: formResponse => {
        setIsLoading(true);
        if (formResponse.status) {
          message.success(messages.success.reportCreateOperation);

          navigate(RouteList.REPORTS_SHOW + '/' + formResponse.payload)
        }
        else {
          message.error(messages.errors.generalError);
        }

        setIsLoading(false);
      },
    });
  }, [reportType]);

  return (
    <MainForm back={RouteList.REPORTS}>

      <Typography variant="h1" mb={5}>
        Формирование отчета
      </Typography>

      <Grid container>
        <Grid item xs={6}>
          <FormSelect
            title="Партнер"
            items={partners}
            selectProps={{
              defaultValue: partners[0].key,
              name: 'partner',
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={6}>
          <FormSelect
            title="Тип отчета"
            items={getReportTypeNames(reportTypes)}
            selectProps={{
              value: reportType,
              name: 'type',
              onChange: (e) => setReportType(e.target.value as ReportTypes)
            }}
          />
        </Grid>
      </Grid>

      {reportType && (reportType === ReportTypes.TURNOVER_POINTS || reportType === ReportTypes.SUMMARY) &&
          <>
            <Divider/>

            <Grid container display="flex" flexDirection="column" alignItems="flex-start">
              <Grid item>
                <Typography variant="body2" color="grey.700">
                  Период формирования:
                </Typography>
              </Grid>
              <Grid item>
                <Grid container paddingTop={2} spacing={4} display="flex" flexDirection="row" alignItems="start">
                  <Grid item xs={5}>
                    <FormDatePicker title="Укажите дату начала" fieldProps={{name: 'start_date'}}/>
                  </Grid>
                  <Grid item xs={5}>
                    <FormDatePicker title="Укажите дату окончания" fieldProps={{name: 'end_date'}}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
      <Divider/>

      <Grid container>
        <Grid item xs={2}>
          <FormSelect
            items={[
              {
                key: 1,
                label: 'XLSX',
              },
              {
                key: 2,
                label: 'PDF',
              }
            ]}
            title="Формат"
            selectProps={{name: 'format', defaultValue: 1}}
          />
        </Grid>
      </Grid>

      <Divider/>

      <FormActions title="Сформировать отчет">
        {isLoading &&
            <Box sx={{width: '80%'}} mt={2}>
              <LinearProgress/>
            </Box>
        }
      </FormActions>

    </MainForm>
  );
};

export default ReportCreateForm;