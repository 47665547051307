import { JsonRpcService } from '../JsonRpcService';
import { IResponse } from '../../types/response/common/Response';
import { IPartner } from '../../types/app/partner/Partner';
import { TGetListData } from '../../types/response/partner/GetListData';
import { GetPartnerByCompanyName, IPartnerGetRequest } from '../../types/request/partner/PartnerGetRequest';
import {
  IGetPartnerIdsByCompanyName,
  TGetPartnerData,
  TGetPartnerIdsByCompanyName,
} from '../../types/response/partner/GetPartnerData';
import { PartnerFactory } from './PartnerFactory';
import { IRegistrationAppeal } from '../../types/app/partner/PartnerRegistrationAppeal';
import { IPartnerUpdateRequest } from '../../types/request/partner/PartnerUpdateRequest';
import { IPartnerCreateRequest } from '../../types/request/partner/PartnerCreateRequest';
import { IRegistrationApplicationStatusGetListResponse } from '../../types/response/partner/RegistrationApplicationStatusGetListResponse';
import { IPartnerRegistrationAppealStatus } from '../../types/app/partner/PartnerRegistrationAppealStatus';
import { PartnerRegistrationApplicationData, TRegistrationAppealData } from '../../types/response/partner/RegistrationAppealData';
import { RegistrationAppealGetRequest } from '../../types/request/partner/RegistrationAppealGetRequest';
import { IPartnerGetSettingRequest } from '../../types/request/partner/PartnerGetSettingRequest';
import { TPartnerGetResponse } from '../../types/response/partner/PartnerGetResponse';
import { IPartnerSettings } from '../../types/app/partner/PartnerSettings';
import { IChangeRegistrationAppealStatusRequest } from '../../types/request/partner/ChangeRegistrationAppealStatusRequest';
import { IStorageGetRequest } from '../../types/request/partner/StorageGetRequest';
import { TStorageGetResponseData } from '../../types/response/partner/StorageGetResponseData';
import { IPartnerUpdateSettingsRequest } from '../../types/request/partner/PartnerUpdateSettingRequest';
import { InternalSaveError, PartnerIsAlreadyExists } from './PartnerErrors';
import { TGetPartnerTerminalListData, TPartnerTerminal } from '../../types/response/partner/GetPartnerTerminalListData';
import { IPartnerTerminal } from '../../types/app/partner/PartnerTerminal';
import { IGetPartnersFilteredList } from './IGetPartnersFilteredList';
import { IPaginatedResponse } from '../../types/response/common/PaginatedResponse';
import { IPaginated } from '../../types/app/common/Paginated';
import { TPartnerData } from '../../types/response/partner/PartnerData';
import { GetPartnersRequest } from '../../types/request/partner/GetPartnersRequest';
import { IGetPartnerTerminalsFilteredRequest } from '../../types/request/partner/GetPartnerTerminalsFilteredRequest';

export class PartnerService {
  private static serviceName: string = 'partner';

  public static async getPartners(request?: GetPartnersRequest): Promise<IPartner[]> {
    const response = await JsonRpcService.request<IResponse<TGetListData>>(this.serviceName, 'partner_get_list', {
      limit: 1000,
      ...request,
    });

    return PartnerFactory.getPartnersFromResponseData(response.result.data.partners);
  }

  public static async getPartnersFilteredList(request: IGetPartnersFilteredList): Promise<IPaginated<IPartner[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TPartnerData[]>>(
      'partner',
      'partner_get_filtered_list',
      request
    );

    const items = response.result.data.items.map(item => PartnerFactory.getPartnerFromResponseData(item));

    return { items, ...response.result.data.pagination };
  }

  public static async getPartnerById(request: IPartnerGetRequest): Promise<IPartner | null> {
    const response = await JsonRpcService.request<IResponse<TGetPartnerData>>(this.serviceName, 'partner_get', request);

    if (response.error) {
      return null;
    }

    return PartnerFactory.getPartnerFromResponseData(response.result.data.partner);
  }

  public static async getPartnerRegistrationApplicationFilteredList(request): Promise<IPaginated<IRegistrationAppeal[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TRegistrationAppealData[]>>(
      PartnerService.serviceName,
      'partner_registration_application_get_filtered_list',
      request
    );

    const items = response.result.data.items.map(appeal => PartnerFactory.getRegistrationAppealsFromResponseData(appeal));

    return { items, ...response.result.data.pagination };
  }

  public static async updatePartner(request: IPartnerUpdateRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'partner_update', request);

    return response.result.status;
  }

  public static async createPartner(request: IPartnerCreateRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'partner_create', request);

    if (response.error) {
      if (response.error.data?.system[0] === 'Partner with that company already exists') {
        throw new PartnerIsAlreadyExists();
      }

      throw new InternalSaveError();
    }

    return response.result.status;
  }

  public static async getRegistrationAppealsStatusesList(): Promise<IPartnerRegistrationAppealStatus[]> {
    const response = await JsonRpcService.request<IRegistrationApplicationStatusGetListResponse>(
      this.serviceName,
      'partner_registration_application_status_get_list'
    );

    return response.result.data.statuses;
  }

  public static async getRegistrationAppeal(request: RegistrationAppealGetRequest): Promise<IRegistrationAppeal> {
    const response = await JsonRpcService.request<IResponse<PartnerRegistrationApplicationData>>(
      this.serviceName,
      'partner_registration_application_get',
      request
    );

    return PartnerFactory.getRegistrationAppealsFromResponseData(response.result.data.partner_registration_application);
  }

  public static async changeRegistrationAppealStatus(request: IChangeRegistrationAppealStatusRequest): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'partner_registration_application_change_status', request);
  }

  public static async getPartnerSetting(request: IPartnerGetSettingRequest): Promise<IPartnerSettings> {
    const response = await JsonRpcService.request<IResponse<TPartnerGetResponse>>(
      this.serviceName,
      'partner_get_settings',
      request
    );

    return PartnerFactory.getPartnerSettingsFromResponse(response.result.data.partner_settings);
  }

  public static async getFileContract(request: IStorageGetRequest): Promise<TStorageGetResponseData> {
    const response = await JsonRpcService.request<IResponse<TStorageGetResponseData>>('storage', 'get', request);

    return response.result.data;
  }

  public static async updateSettings(request: IPartnerUpdateSettingsRequest): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'partner_update_settings', request);
  }

  public static async getPartnerTerminals(request?: { partner_id }): Promise<IPartnerTerminal[]> {
    const response = await JsonRpcService.request<IResponse<TGetPartnerTerminalListData>>(
      this.serviceName,
      'partner_terminal_get_list',
      { ...request, limit: 1000 }
    );

    return response.result.data.partner_terminals.map(item => PartnerFactory.getPartnerTerminalFromResponse(item));
  }

  public static async getPartnersByCompanyName(request: GetPartnerByCompanyName): Promise<IGetPartnerIdsByCompanyName> {
    const response = await JsonRpcService.request<IResponse<TGetPartnerIdsByCompanyName>>(
      PartnerService.serviceName,
      'partner_get_by_company_name',
      request
    );

    return PartnerFactory.partnersByCompanyName(response.result.data);
  }

  public static async getPartnerTerminalsFiltered(
    request?: IGetPartnerTerminalsFilteredRequest
  ): Promise<IPaginated<IPartnerTerminal[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TPartnerTerminal[]>>(
      PartnerService.serviceName,
      'partner_terminal_get_filtered_list',
      request
    );

    const items = response.result.data.items.map(item => PartnerFactory.getPartnerTerminalFromResponse(item));

    return { items, ...response.result.data.pagination };
  }
}
