import React from 'react';
import {LoadingButton, LoadingButtonProps} from '@mui/lab';
import {styled, Typography} from '@mui/material';

interface FullButtonProps extends LoadingButtonProps{
  label?: string
}

const StyledLoadingButton = styled(LoadingButton)({
  color: '#CBDDF7',
})

const FormFullButton = (props: FullButtonProps) => {
  return (
    <StyledLoadingButton
      type="submit"
      sx={{ borderRadius: '16px', padding: '19px 32px', marginTop: 2 }}
      fullWidth
      {...props}
    >
      <Typography variant="subtitle2" fontFamily="Lato-ExtraBold">
        {props.label ? props.label : 'Применить'}
      </Typography>
    </StyledLoadingButton>
  );
};

export default FormFullButton;
