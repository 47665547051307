export enum TransactionTypeNames {
  BUY = 'Покупка',
  BONUS_ACCRUAL = 'Начисление бонусов',
  DEPOSIT = 'Ввод средств на счет',
  WITHDRAWAL = 'Вывод средств со счета',
  CONVERT = 'Конвертация',
  BONUS_RETURN = 'Возврат бонуса после сгорания',
  BONUS_TRANSFER = 'Трансфер',
  CORRECTION = 'Корректировка баланса',
  TRANSFER = 'Перевод средств между счетами',
  CERTIFICATE_CREATE = 'Создание сертификата',
  CERTIFICATE_REPAY = 'Использование сертииката',
  CERTIFICATE_CLOSE = 'Выплата средств по сертификату'
}

export enum TransactionTypes {
  BUY = 1 ,
  BONUS_ACCRUAL,
  DEPOSIT,
  WITHDRAWAL,
  CONVERT,
  BONUS_RETURN,
  BONUS_TRANSFER,
  CORRECTION,
  TRANSFER = 10,
  CERTIFICATE_CREATE,
  CERTIFICATE_REPAY,
  CERTIFICATE_CLOSE
}

export const getTypeByValue = (value: TransactionTypes): TransactionTypeNames => {
  switch (value) {
    case TransactionTypes.BONUS_ACCRUAL: return TransactionTypeNames.BONUS_ACCRUAL;
    case TransactionTypes.BONUS_RETURN: return TransactionTypeNames.BONUS_RETURN;
    case TransactionTypes.BONUS_TRANSFER: return TransactionTypeNames.BONUS_TRANSFER;
    case TransactionTypes.BUY: return TransactionTypeNames.BUY;
    case TransactionTypes.CONVERT: return TransactionTypeNames.CONVERT;
    case TransactionTypes.CORRECTION: return TransactionTypeNames.CORRECTION;
    case TransactionTypes.DEPOSIT: return TransactionTypeNames.DEPOSIT;
    case TransactionTypes.WITHDRAWAL: return TransactionTypeNames.WITHDRAWAL;
  }
}

export const getColorByType = (type: TransactionTypes): string => {
  switch (type) {
    case TransactionTypes.BONUS_ACCRUAL: return '#388b48';
    case TransactionTypes.BONUS_RETURN: return '#070c99';
    case TransactionTypes.BONUS_TRANSFER: return '#e320ea';
    case TransactionTypes.BUY: return '#0042E7';
    case TransactionTypes.CONVERT: return '#1997D6';
    case TransactionTypes.CORRECTION: return '#dfb128';
    case TransactionTypes.DEPOSIT: return '#00AA1B';
    case TransactionTypes.WITHDRAWAL: return '#B03A3A';
  }
}

export const getValueByType = (type: TransactionTypeNames): TransactionTypes => {
  switch (type) {
    case TransactionTypeNames.BONUS_ACCRUAL: return TransactionTypes.BONUS_ACCRUAL;
    case TransactionTypeNames.BONUS_RETURN: return TransactionTypes.BONUS_RETURN;
    case TransactionTypeNames.BONUS_TRANSFER: return TransactionTypes.BONUS_TRANSFER;
    case TransactionTypeNames.BUY: return TransactionTypes.BUY;
    case TransactionTypeNames.CONVERT: return TransactionTypes.CONVERT;
    case TransactionTypeNames.CORRECTION: return TransactionTypes.CORRECTION;
    case TransactionTypeNames.DEPOSIT: return TransactionTypes.DEPOSIT;
    case TransactionTypeNames.WITHDRAWAL: return TransactionTypes.WITHDRAWAL;
  }
}
