import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { ITariffCreateLoader } from '../../../http/billing/BillingLoaders';
import TariffCreateFormRefactored
  from '../../../Widgets/Billing/BillingTab/Operations/Tariff/TariffCreateFormRefactored';

const TariffCreate = () => {
  const { content } = useLoaderData() as { content: Promise<ITariffCreateLoader> };

  return (
    <Await resolve={content}>
      <TariffCreateFormRefactored />
    </Await>
  );
};

export default TariffCreate;
