import React from 'react';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';

export interface IDashboardCardContentProps {
  title: string;
  data: number | string;
  id: string;
  symbol?: string;
}

interface IDashboardCardProps {
  title: string;
  content: IDashboardCardContentProps[];
}

const DashboardCard = (props: IDashboardCardProps) => {
  if (props.content.length === 0) {
    return <></>;
  }

  return (
    <Grid item xs>
      <Card sx={{ borderRadius: '32px' }}>
        <CardContent sx={{ padding: '26px', minHeight: 316 }}>
          <Grid container columns={4} flexDirection="column">
            {props.title && (
              <Grid item xs minHeight={64} borderBottom="1px solid #E7E9EB">
                <Typography variant="subtitle1">{props.title}:</Typography>
              </Grid>
            )}

            <Box mt={3}>
              {props.content.length &&
                props.content.map((item, index) => (
                  <Grid key={item.id} item xs>
                    <Typography variant="subtitle2">{item.title}:</Typography>
                    <Typography variant="h2" color="primary">
                      {item.data.toLocaleString()} {item.symbol}
                    </Typography>

                    {index !== props.content.length - 1 && (
                      <Box pb={3} pt={3}>
                        <Divider />
                      </Box>
                    )}
                  </Grid>
                ))}
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DashboardCard;
