import React, {useMemo, useState} from 'react';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';
import {Button, Grid} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {useLoaderData} from 'react-router-dom';
import {IDashboardInfo as ReportInfo} from '../../../types/app/report/DashboardInfo';
import {IDashboardInfo as AccountInfo} from '../../../types/app/account/DashboardInfo';
import DashboardCard, {IDashboardCardContentProps} from '../../../Widgets/Dashboard/DashboardCard';
import DashboardFilters from '../../../Widgets/Dashboard/DashboardFilters';
import EmptyWidget from '../../../Widgets/Common/Empty/GeneralEmptyWidget';
import DashboardIcon from '@mui/icons-material/Dashboard';

export const defaultViewState = {
  usersCount: true,
  partnersCount: true,
  registrationAppealsCount: true,

  totalPointsDeposit: true,
  totalRublsDeposit: true,

  todayRublsDepositByPartners: true,
  weeklyRublsDepositByPartners: true,
  monthlyRublsDepositByPartners: true,

  todayRublsWithdrawByPartners: true,
  weeklyRublsWithdrawByPartners: true,
  monthlyRublsWithdrawByPartners: true,

  ordersCount: true,
  averageAmount: true,
  averageOrderItemsCount: true,
};


const DashboardIndex = () => {
  const data = useLoaderData() as ReportInfo & AccountInfo;

  const [view, setView] = useState(defaultViewState);
  const [open, setOpen] = useState<boolean>(false);

  const isAllOff = useMemo(() => {
    let isOff = true;

    for (const viewKey in view) {
      if (view[viewKey]) {
        return false;
      }
    }

    return isOff;
  }, [view]);

  /**
   * Apply dashboard filters
   *
   * @param items
   */
  const withFilters = (items: IDashboardCardContentProps[]): IDashboardCardContentProps[] => {
    let result = [...items];

    items.forEach(item => {
      const currentFilter = view[item.id];

      if (!currentFilter) {
        result = result.filter(resItem => resItem.id !== item.id);
      }
    });

    return result;
  };

  return (
    <ContentContainer title="Дашборд">
      <DashboardFilters setOpen={setOpen} open={open} view={view} setView={setView} />

      <Button onClick={() => setOpen(true)} sx={{ mb: 3 }} variant="outlined" endIcon={<FilterListIcon />}>
        Настроить отображение
      </Button>

      {isAllOff ? (
        <EmptyWidget
          title="Вид не настроен"
          description="Попробуйте выбрать другие настройки"
          icon={<DashboardIcon />}
        />
      ) : (
        <Grid container columns={5} spacing={2} alignItems="stretch">
          <DashboardCard
            title="Количество"
            content={withFilters([
              { title: 'Пользователей', data: data.usersCount, id: 'usersCount' },
            ])}
          />

          <DashboardCard
            title="Чеки за все время"
            content={withFilters([
              { title: 'Всего чеков', data: data.ordersCount, id: 'ordersCount' },
              { title: 'Средний чек', data: data.averageAmount, id: 'averageAmount', symbol: '₽' },
            ])}
          />
        </Grid>
      )}
    </ContentContainer>
  );
};

export default DashboardIndex;
