import { JsonRpcService } from '../JsonRpcService';
import { IResponse } from '../../types/response/common/Response';
import {
  InternalError, PromoCreateError,
  PromoUpdateError,
  TariffIsAlreadyExists,
  TariffNotFound,
  ValidationErrors
} from './BillingErrors';
import { BillingFactory } from './BillingFactory';
import { HttpCodes } from '../../enums/HttpCodes';
import { TListTariffData } from '../../types/response/billing/ListTariffData';
import { TGetTariffData } from '../../types/response/billing/GetTariffData';
import { IGetTariffRequest } from '../../types/request/billing/GetTariffRequest';
import { ITariffDetail } from '../../types/app/billing/TariffDetail';
import { TChangeTariffData } from '../../types/response/billing/ChangeTariffData';
import { TAddTariffData } from '../../types/response/billing/AddTariffData';
import { ITariff } from '../../types/app/billing/bonuses/Tariff';
import { TGetRankSettingsData } from '../../types/response/billing/GetRankSettingsData';
import moment from 'moment';
import { Bonus } from '../../types/app/billing/Bonus';
import { TGetBonusData } from '../../types/response/billing/GetBonusData';
import { BillingBonuses } from '../../enums/BillingBonuses';
import { ISetBonusRequest } from '../../types/request/billing/SetBonusRequest';
import { IUpdateTariffRequest } from '../../types/request/billing/UpdateTariffRequest';
import { ICreateTariffRequest } from '../../types/request/billing/CreateTariffRequest';
import { AddRankSettingsRequest } from '../../types/request/billing/AddRankSettingsRequest';
import { TAddRankSettingsData } from '../../types/response/billing/AddClientRankSettingsData';
import { UpdateClientRankSettingsData } from '../../types/response/billing/UpdateClientRankSettingsData';
import { TPromo } from '../../types/response/billing/ListPromoData';
import {IPromo} from '../../types/app/billing/bonuses/Promo';
import {ICreatePromoRequest} from '../../types/request/billing/CreatePromoRequest';
import {IUpdatePromoRequest} from '../../types/request/billing/UpdatePromoRequest';
import { IClientRankSetting } from '../../types/app/billing/ClientRankSetting';
import {IGetPromoRequest} from '../../types/request/billing/GetPromoRequest';
import {IGetRankSetting} from '../../types/app/billing/GetRankSetting';

export class BillingService {
  private static serviceName: string = 'billing';

  public static async getClientRankSettings(): Promise<IClientRankSetting[]> {
    const response = await JsonRpcService.request<IResponse<TGetRankSettingsData[]>>(this.serviceName, 'list_rank_settings');

    return response.result.data.map(setting => ({
      id: setting.id,
      rankId: setting.rank_id,
      percent: Number(setting.percent),
      createdAt: setting.created_at ? moment(setting.created_at).unix() : null,
      updatedAt: setting.updated_at ? moment(setting.updated_at).unix() : null,
      bonusAmount: Number(setting.bonus_amount),
      lifetimeDays: setting.lifetime_days,
    }));
  }

  public static async getRankSettings(rankId: string | number): Promise<IGetRankSetting> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'get_rank_setting', {
      rank_id: rankId
    });

    return response.result.data;
  }

  public static async changeBonus(request: ISetBonusRequest): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'change_bonus', request);
  }

  public static async getBonus(request: { id: BillingBonuses }): Promise<Bonus> {
    const response = await JsonRpcService.request<IResponse<TGetBonusData>>(this.serviceName, 'get_bonus', request);

    const data = response.result.data;

    return {
      active: data.active === '1',
      amount: data.amount,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      id: data.id,
      lifetimeDays: data.lifetime_days,
      name: data.name,
      percent: data.percent,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
    };
  }

  public static async getTariff(request: IGetTariffRequest): Promise<ITariffDetail> {
    const response = await JsonRpcService.request<IResponse<TGetTariffData>>(this.serviceName, 'get_tariff', request);

    if (response.error && response.error.code === HttpCodes.NOT_FOUND) {
      throw new TariffNotFound();
    }

    return BillingFactory.getTariffFromGetTariffResponseData(response.result.data);
  }

  public static async createPromo(request: ICreatePromoRequest): Promise<IPromo> {
    const response = await JsonRpcService.request<IResponse<TPromo>>(
      this.serviceName,
      'add_promo',
      request,
    );

    if (response.error) {
      throw new PromoCreateError();
    }

    return BillingFactory.getPromoFromResponse(response.result.data);
  }

  public static async updatePromo(request: IUpdatePromoRequest): Promise<IPromo> {
    const response = await JsonRpcService.request<IResponse<TPromo>>(
      this.serviceName,
      'change_promo',
      request,
    );

    if (response.error) {
      throw new PromoUpdateError();
    }

    return BillingFactory.getPromoFromResponse(response.result.data);
  }

  public static async getPromo(request: IGetPromoRequest): Promise<IPromo> {
    const response = await JsonRpcService.request<IResponse<TPromo>>(
      this.serviceName,
      'get_promo',
      request,
    );

    return BillingFactory.getPromoFromResponse(response.result.data, 'YYYY-MM-DD HH:mm:ss');
  }

  public static async getPromos(): Promise<IPromo[]> {
    const response = await JsonRpcService.request<IResponse<TPromo[]>>(
      this.serviceName,
      'list_promos',
    );

    return response.result.data.map(item => BillingFactory.getPromoFromResponse(item));
  }

  public static async getTariffs(): Promise<ITariff[]> {
    const response = await JsonRpcService.request<IResponse<TListTariffData[]>>(this.serviceName, 'list_tariffs');

    return BillingFactory.getTariffsFromListTariffResponseData(response.result.data);
  }

  public static async updateTariff(request: IUpdateTariffRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<TChangeTariffData>>(this.serviceName, 'change_tariff', request);

    if (response.error) {
      if (response.error.code < 0) {
        throw new ValidationErrors();
      }

      if (response.error.code === HttpCodes.NOT_IMPLEMENTED) {
        throw new TariffIsAlreadyExists();
      }

      throw new InternalError();
    }

    return response.result.status;
  }

  public static async createTariff(request: ICreateTariffRequest): Promise<number> {
    const response = await JsonRpcService.request<IResponse<TAddTariffData>>(this.serviceName, 'add_tariff', request);

    if (response.error) {
      if (response.error.code < 0) {
        throw new ValidationErrors();
      }

      if (response.error.code === HttpCodes.NOT_IMPLEMENTED) {
        throw new TariffIsAlreadyExists();
      }

      throw new InternalError();
    }

    return response.result.data.id;
  }

  public static async addRankSettings(request: AddRankSettingsRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<TAddRankSettingsData>>(this.serviceName, 'add_rank_setting', request);

    if (response.error) {
      if (response.error.code < 0) {
        throw new ValidationErrors();
      }

      if (response.error.code === HttpCodes.NOT_IMPLEMENTED) {
        throw new TariffIsAlreadyExists();
      }

      throw new InternalError();
    }

    return response.result.status;
  }

  public static async updateClientRankSettings(request: AddRankSettingsRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<UpdateClientRankSettingsData>>(
      this.serviceName,
      'change_rank_setting',
      request
    );

    if (response.error) {
      if (response.error.code < 0) {
        throw new ValidationErrors();
      }

      if (response.error.code === HttpCodes.NOT_IMPLEMENTED) {
        throw new TariffIsAlreadyExists();
      }

      throw new InternalError();
    }

    return response.result.status;
  }
}
