import { JsonRpcService } from '../JsonRpcService';
import { IResponse } from '../../types/response/common/Response';
import {
  IClientsDataRequestedByEmail,
  TClientsData,
  TClientsDataRequestedByEmail
} from '../../types/response/client/ClientsData';
import { ClientFactory } from './ClientFactory';
import { TClientData, TClientRank} from '../../types/response/client/ClientData';
import { InternalError } from './ClientErrors';
import { IClientRank } from '../../types/app/client/ClientRank';
import {
  IIdentification,
  IIdentificationArbitraryCard,
  IIdentificationBankCard,
  IIdentificationTransportCard,
  IIdentificationVirtualCard,
} from '../../types/app/account/Identification';
import { ICardBankData } from '../../types/response/client/CardBankData';
import { ICardTransportData } from '../../types/response/client/CardTransportData';
import { ICardArbitraryData } from '../../types/response/client/CardArbitraryData';
import { ICardData } from '../../types/response/client/CardData';
import moment from 'moment/moment';
import { TClientRankData } from '../../types/response/client/ClientRankData';
import { IGetClientRankRequest } from '../../types/request/client/GetClientRankRequest';
import { IDeleteClientRequest } from '../../types/request/client/DeleteClientRequest';
import { IUpdateClientRequest } from '../../types/request/client/UpdateClientRequest';
import { UpdateClientRankRequest } from '../../types/request/client/UpdateClientRankRequest';
import { BillingService } from '../billing/BillingService';
import { AddClientRankRequest } from '../../types/request/client/AddClientRankRequest';
import { TAddRankResponse } from '../../types/response/client/AddRankResponse';
import { IGetClientRequest } from '../../types/request/account/GetClientRequest';
import { IGetClientByExtIdRequest } from '../../types/request/account/GetClientByExtIdRequest';
import { ICardVirtualData } from '../../types/response/client/CardVirtualData';
import {IGetClientsListRequest} from '../../types/request/account/GetClienstListRequest';
import {IPaginatedResponse} from '../../types/response/common/PaginatedResponse';
import {IClient} from '../../types/app/client/Client';
import {IPaginated} from '../../types/app/common/Paginated';
import {GetClientByEmail} from '../../types/request/client/GetClientByEmail';
import { PhoneAlreadyTakenError } from '../auth/AuthErrors';

export class ClientService {
  private static serviceName: string = 'client';

  public static async updateClientRank(request: UpdateClientRankRequest): Promise<IClientRank> {
    const response = await JsonRpcService.request<IResponse<TClientRank>>(this.serviceName, 'client_ranks_update', request);

    return ClientFactory.getClientRankFromResponse(response.result.data);
  }

  public static async addClientRank(request: AddClientRankRequest): Promise<TAddRankResponse> {
    const response = await JsonRpcService.request<IResponse<TAddRankResponse>>(this.serviceName, 'client_ranks_store', request);

    return response.result.data;
  }

  public static async getClientRanks(): Promise<IClientRank[]> {
    const response = await JsonRpcService.request<IResponse<TClientRank[]>>(this.serviceName, 'client_ranks');

    const data = response.result.data;

    return data.map(responseData => ClientFactory.getClientRankFromResponse(responseData));
  }

  public static async getClients(request?: IGetClientsListRequest): Promise<IClient[]> {
    const response = await JsonRpcService.request<IResponse<TClientsData[]>>(this.serviceName, 'clients', {...request, active: true});

    return response.result.data.map(data => ClientFactory.getClientsFromResponseData(data));
  }

  public static async getClientsData(request?: IGetClientsListRequest): Promise<IPaginated<IClient[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TClientsData[]>>(
      ClientService.serviceName,
      'clients',
      {...request, active: true
      });

    const items = response.result.data.items.map(data => ClientFactory.getClientsFromResponseData(data));

    return {items, ...response.result.data.pagination};
  }

  public static async fetchClient(request: IGetClientRequest): Promise<IClient> {
    const response = await JsonRpcService.request<IResponse<TClientData>>(this.serviceName, 'clients_show', request);

    return ClientFactory.getClientFromResponseData(response.result.data);
  }

  public static async fetchClientByExtId(request: IGetClientByExtIdRequest): Promise<IClient | null> {
    const response = await JsonRpcService.request<IResponse<TClientData>>(this.serviceName, 'clients_show_by_ext_id', request);

    if (response.error) {
      return null;
    }

    return ClientFactory.getClientFromResponseData(response.result.data);
  }

  public static async updateClient(request: IUpdateClientRequest): Promise<IClient> {
    const response = await JsonRpcService.request<IResponse<TClientData>>(this.serviceName, 'clients_update', request);
    
    if (response.error) {
      if (response.error.data.phone) {
        throw new PhoneAlreadyTakenError();
      }
    }

    return ClientFactory.getClientFromResponseData(response.result.data);
  }

  public static async deactivateClient(request: IDeleteClientRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<boolean>>(this.serviceName, 'clients_deactivate', request);

    return response.result.status;
  }

  public static async getIdentifiersBankCard(request: { client_id: number }): Promise<IIdentificationBankCard[]> {
    const response = await JsonRpcService.request<IResponse<ICardBankData[]>>(this.serviceName, 'identifiers_bank_card', request);

    try {
      const data = response.result.data;

      return data.map(bankCard => ({
        ...this.getIdentificationByRequestData(bankCard),
        ...bankCard,
      }));
    } catch (e) {
      throw new InternalError();
    }
  }

  public static async getIdentifiersTransportCard(request: { client_id: number }): Promise<IIdentificationTransportCard[]> {
    const response = await JsonRpcService.request<IResponse<ICardTransportData[]>>(
      this.serviceName,
      'identifiers_transport_card',
      request
    );

    try {
      const data = response.result.data;

      return data.map(transportCard => ({
        ...this.getIdentificationByRequestData(transportCard),
        number: transportCard.number,
      }));
    } catch (e) {
      throw new InternalError();
    }
  }

  public static async getIdentifiersArbitraryCard(request: { client_id: number }): Promise<IIdentificationArbitraryCard[]> {
    const response = await JsonRpcService.request<IResponse<ICardArbitraryData[]>>(
      this.serviceName,
      'identifiers_arbitrary',
      request
    );

    try {
      const data = response.result.data;

      return data.map(arbitraryCard => ({
        ...this.getIdentificationByRequestData(arbitraryCard),
        arbitraryId: arbitraryCard.arbitrary_id,
      }));
    } catch (e) {
      throw new InternalError();
    }
  }

  public static async clientIdentifiersArbitraryUnlink(request: { id: number }): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<boolean>>(
      ClientService.serviceName,
      'identifiers_arbitrary_unlink',
      request
    );

    return response.result.data;
  }

  public static async clientIdentifiersBankUnlink(request: { id: number }): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<boolean>>(
      ClientService.serviceName,
      'identifiers_bank_card_unlink',
      request
    );

    return response.result.data;
  }

  public static async clientIdentifiersTransportUnlink(request: { id: number }): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<boolean>>(
      ClientService.serviceName,
      'identifiers_transport_card_unlink',
      request
    );

    return response.result.data;
  }

  public static async getClientRank(request: IGetClientRankRequest): Promise<IClientRank> {
    const response = await JsonRpcService.request<IResponse<TClientRankData>>(
      ClientService.serviceName,
      'client_ranks_show',
      request
    );

    const rankSetting = await BillingService.getRankSettings(response.result.data.id);

    return ClientFactory.getClientRankFromResponseData(
      response.result.data,
      rankSetting
    );
  }

  public static async getIdentifiersVirtualCard(request?: { client_id: number }): Promise<IIdentificationVirtualCard[]> {
    const response = await JsonRpcService.request<IResponse<ICardVirtualData[]>>(
      this.serviceName,
      'identifiers_virtual_card',
      request
    );

    try {
      const data = response.result.data;

      return data.map(virtualCard => ({
        ...this.getIdentificationByRequestData(virtualCard),
        number: virtualCard.number,
      }));
    } catch (e) {
      throw new InternalError();
    }
  }

  private static getIdentificationByRequestData(cardData: ICardData): IIdentification {
    const identification: IIdentification = {
      createdAt: moment(cardData.created_at).unix(),
      id: cardData.id,
      updatedAt: moment(cardData.updated_at).unix(),
    };

    const clientId = cardData.client_id;

    if (clientId) {
      identification.clientId = clientId;
    }

    return identification;
  }

  public static async getClientsByEmail(request: GetClientByEmail): Promise<IClientsDataRequestedByEmail> {
    const response = await JsonRpcService.request<IResponse<TClientsDataRequestedByEmail>>(
      ClientService.serviceName,
      'clients_get_by_email',
      request
    );

    return ClientFactory.clientsDataByEmail(response.result.data)
  }
}
