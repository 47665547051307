import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormContext } from '../../store/context/form/FormContext';
import { Box, FormControl, TextFieldProps, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import { DateHelper } from '../../helpers/DateHelper';
import { Variant } from '@mui/material/styles/createTypography';
import { TimePickerProps } from '@mui/lab';
import FormErrorText from '../Form/FormErrorText';
import RemoveIcon from '@mui/icons-material/Remove';
import FormPickerTextField from './FormPickerTextField';

export interface FormTimePickerProps {
  nullable?: boolean;
  title?: string;
  typography?: Variant;
  fieldProps?: TextFieldProps;
  timepickerProps?: TimePickerProps<Moment>;
}

const FormTimePicker = (props: FormTimePickerProps) => {
  const getValue = useCallback(() => {
    return DateHelper.getTimePickerValueByProps(props);
  }, [
    props.fieldProps?.value,
    props.fieldProps?.defaultValue,
    props.timepickerProps?.value,
    props.timepickerProps?.defaultValue,
    props.nullable,
  ]);

  const { errors, config, errorWatcher } = useContext(FormContext);
  const [value, setValue] = useState(getValue());

  useEffect(() => {
    setValue(getValue());
  }, [getValue]);

  return (
    <Box position="relative" mb={1}>
      {props.title && (
        <Typography marginBottom={1} variant="body2" color="grey.700">
          {props.title}:
        </Typography>
      )}
      {config.readonly ? (
        <Box>
          {props.fieldProps?.label && (
            <Typography mb={1} variant="body1" color="grey.500">
              {props.fieldProps.label}
            </Typography>
          )}
          <Typography variant={props.typography ?? 'body1'}>
            {getValue() ? DateHelper.timeToReadFormat(getValue()) : <RemoveIcon sx={{ fontSize: 20 }} />}
          </Typography>
        </Box>
      ) : (
        <FormControl
          fullWidth
          sx={{
            '& label': {
              mt: '-5px',
            },
          }}
        >
          <TimePicker
            renderInput={params => (
              <FormPickerTextField
                value={value}
                dispatch={setValue}
                params={params}
                fieldProps={props.fieldProps}
                nullable={props.nullable}
              />
            )}
            onChange={(date: Moment | null) => {
              const value = DateHelper.getPickerValue(date);

              setValue(value);

              if (props.fieldProps?.name) {
                errorWatcher(props.fieldProps.name, value ? String(value) : '');
              }
            }}
            value={DateHelper.getMomentFromValue(value)}
            {...props.timepickerProps}
          />
          {props.fieldProps?.name && (
            <Box>
              <input type="hidden" value={value ?? ''} name={props.fieldProps.name} />
              <Box>{errors[props.fieldProps?.name] && <FormErrorText error={errors[props.fieldProps.name]} />}</Box>
            </Box>
          )}
        </FormControl>
      )}
    </Box>
  );
};

export default FormTimePicker;
