import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/rtk/store';
import { RouterProvider } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CssBaseline, responsiveFontSizes, styled, ThemeProvider } from '@mui/material';
import { themeConfig } from './layout/assets/config/themeConfig';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { routes } from './http/routes';

const container = document.getElementById('root')!;
const root = createRoot(container);

export const SnackbarProvider = styled(NotistackSnackbarProvider)({
  '.SnackbarItem-message': {
    width: 400,
    fontSize: 16,
    lineHeight: '25px',
  },
  // '&.SnackbarItem-variantWarning': {
  //   color: 'rgb(102, 86, 42)',
  //   backgroundColor: 'rgb(255, 251, 240)',
  // },
  // '&.SnackbarItem-variantError': {
  //   color: '#721c24',
  //   backgroundColor: '#f8d7da',
  // },
});

root.render(
  <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
    <Provider store={store}>
      <ThemeProvider theme={responsiveFontSizes(themeConfig)}>
        <CssBaseline />
        <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <RouterProvider router={routes} />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </LocalizationProvider>
);
