import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {IPaginatable} from '../../../types/app/common/Paginatable';
import ShowButton from '../../../UI/Button/ShowButton';
import {RouteList} from '../../../http/RouteList';
import {ICategory} from '../../../types/app/catalog/Category';
import TableContainer from '../../../UI/Table/TableContainer';

const CategoriesTabContentData = (props: IPaginatable<ICategory>) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" width={450}> Наименование категории </TableCell>
            <TableCell variant="head" width={50}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(category => (
            <TableRow key={category.key} sx={{'&:last-child td, &:last-child th': {border: 0}, width: '100%'}}>
              <TableCell>{category.label}</TableCell>
              <TableCell>
                <ShowButton linkProps={{to: RouteList.CATALOG_CATEGORY_SHOW + '/' + category.key}}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CategoriesTabContentData;