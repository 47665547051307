import React, {useState} from 'react';
import {IRuleSetting} from '../../../../types/app/fraud/RuleSetting';
import FraudUpdateForm from './Common/FraudUpdateForm';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import {FraudSettingConditionNameEnum, FraudSettingConditionNames} from '../../../../enums/FraudRules';
import Divider from '../../../../UI/Delimiter/Divider';
import {getConditionDefaultValue} from '../../../../utils/fraudUtils';

const MoneyBackFraudForm = (props: IRuleSetting) => {
  const [refundsSumMax, setRefundSumMax] = useState<number>(
        getConditionDefaultValue(FraudSettingConditionNameEnum.MONEY_BACK_SUM_MAX, props.ruleSettingConditions)
  );
  const [refundsPeriodDaysCount, setRefundsPeriodDaysCount] = useState<number>(
        getConditionDefaultValue(FraudSettingConditionNameEnum.MONEY_BACK_PERIOD_DAYS_COUNT, props.ruleSettingConditions)
  );

  return (
    <FraudUpdateForm settings={props}>
      <FormOutlinedInput
        inputProps={{
          name: FraudSettingConditionNameEnum.MONEY_BACK_SUM_MAX,
          value: refundsSumMax,
          onChange: e => setRefundSumMax(+e.target.value)
        }}
        rule={e => !isNaN(+e.target.value)}
        title={FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.MONEY_BACK_SUM_MAX)}
      />

      <Divider />

      <FormOutlinedInput
        inputProps={{
          name: FraudSettingConditionNameEnum.MONEY_BACK_PERIOD_DAYS_COUNT,
          value: refundsPeriodDaysCount,
          onChange: e => setRefundsPeriodDaysCount(+e.target.value)
        }}
        rule={e => !isNaN(+e.target.value) && +e.target.value <= 100}
        title={FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.MONEY_BACK_PERIOD_DAYS_COUNT)}
      />
    </FraudUpdateForm>
  );
};

export default MoneyBackFraudForm;