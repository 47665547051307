export class StructureGetError extends Error {
  message = 'Пользователь не найден';
}

export class MovingImpossibleError extends Error {
  message = 'Перемещение невозможно. Родитель в структуре находится на нижнем уровне.';
}

export class ValidationError extends Error {
  message = 'У данного Пользователя нет реферальной структуры.';
}