import React from 'react';
import {Grid, Typography} from '@mui/material';
import {IFormGridProps} from './FormGridAlone';

const FormGridFull = (props: IFormGridProps) => {
  return (
    <Grid position="relative" container width="100%" {...props}>
      {props.title && <Typography textAlign='left' mb={3} variant="h1">{props.title}</Typography>}
      {props.children}
    </Grid>
  );
};

export default FormGridFull;
