import {useAsyncValue} from 'react-router-dom';
import {IClient} from '../../../../types/app/client/Client';
import React, {useContext, useLayoutEffect, useState} from 'react';
import {FormContext} from '../../../../store/context/form/FormContext';
import {useSnackbar} from 'notistack';
import {ISelectable} from '../../../../types/app/common/Selectable';
import {RouteList} from '../../../../http/RouteList';
import {snackConfig} from '../../../../layout/assets/config/snackConfig';
import {ClientService} from '../../../../http/client/ClientService';
import {AuthService} from '../../../../http/auth/AuthService';
import {
  IIdentificationArbitraryCard,
  IIdentificationBankCard,
  IIdentificationTransportCard,
} from '../../../../types/app/account/Identification';
import {CardTypes} from '../../../../enums/Cards';
import {AccountService} from '../../../../http/account/AccountService';
import {OperationTypes} from '../../../../enums/OperationTypes';
import {getUserTypeByValue} from '../../../../enums/UserTypes';
import TabLoader from '../../../Common/Loader/TabLoader';
import MainForm from '../../../Common/Form/MainForm';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../UI/Delimiter/Divider';
import {Grid, Typography} from '@mui/material';
import FormDatePicker from '../../../../UI/Pickers/FormDatePicker';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import {GenderTypes} from '../../../../enums/Genders';
import {MaritalStatusesType} from '../../../../enums/MaritalStatuses';
import FormSwitch from '../../../../UI/Form/FormSwitch';
import FormActions from '../../../../UI/Form/FormActions';
import {useNotifications} from '../../../../hooks/useNotifications';
import {messages} from '../../../../notifications/messages';
import {LoadingButton} from '@mui/lab';
import {HttpCodes} from '../../../../enums/HttpCodes';
import CardInformation, {IIdentityCard} from './CardInformation';
import {StructureService} from '../../../../http/structure/StructureService';
import ReferralInformation from './ReferralInformation';
import {Simulate} from 'react-dom/test-utils';
import submit = Simulate.submit;
import { StringHelper } from '../../../../helpers/StringHelper';


const ClientShowForm = () => {
  const client = useAsyncValue() as IClient;

  const {setConfig, config} = useContext(FormContext);
  const {enqueueSnackbar} = useSnackbar();
  const message = useNotifications();

  const [ranks, setClientRanks] = useState<ISelectable[]>(null);
  const [balance, setBalance] = useState<number>(0);
  const [cards, setCards] = useState<IIdentityCard[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [referrals, setReferrals] = useState<number | null>(null);

  const [cardError, setCardError] = useState<string | null>(null);
  const [referralError, setReferralError] = useState<string | null>(null);

  useLayoutEffect(() => {
    setConfig({
      action: RouteList.CLIENTS_SHOW + '/' + client.id,
      rules: {
        name: ['required'],
        surname: ['required'],
        birthdate: ['required'],
      },
      readonly: true,
      data: [
        {field: 'email', value: client.email},

      ],
      afterSubmitHandler: (formResponse, config, setConfig) => {        
        if (formResponse.error || !formResponse.status) {
          return;
        }
        
        enqueueSnackbar('Клиент ' + client.id + ' успешно обновлен. Изменения сохранены.', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        });

        setConfig({...config, readonly: true});
      },
    });

    ClientService.getClientRanks().then(ranks => setClientRanks(ranks.map(rank => ({key: rank.id, label: rank.name}))));

    StructureService.getCountReferrals({user_ids: [client.id]})
      .then(response => {
        setReferrals(response[client.id] ? response[client.id].direct : 0);
      });

    Promise.all([
      ClientService.getIdentifiersArbitraryCard({client_id: client.id}),
      ClientService.getIdentifiersTransportCard({client_id: client.id}),
      ClientService.getIdentifiersBankCard({client_id: client.id}),
    ]).then(response => {
      const arbitraryCards: IIdentificationArbitraryCard[] = response[0];
      const transportCards: IIdentificationTransportCard[] = response[1];
      const bankCards: IIdentificationBankCard[] = response[2];

      const result: IIdentityCard[] = [];

      arbitraryCards.forEach(card => {
        result.push({
          id: card.id,
          type: CardTypes.ARBITRARY_CARD,
          number: card.arbitraryId,
        });
      });

      transportCards.forEach(card => {
        result.push({
          id: card.id,
          type: CardTypes.TRANSPORT_CARD,
          number: card.number,
        });
      });

      bankCards.forEach(card => {
        result.push({
          id: card.id,
          type: CardTypes.BANK_CARD,
          number: card.masked,
        });
      });

      setCards(result);
    })
      .catch(() => {
        setCardError('Не удалось загрузить данные по методам идентификации');
        setReferralError('Не удалось загрузить данные по количеству рефералов');
      });

    AccountService.getBalance({
      type: OperationTypes.POINT,
      user_id: client.id,
      user_type: getUserTypeByValue(0),
    }).then(result => setBalance(result.balance));

  }, []);

  const resetPassword = async () => {
    setIsLoading(true);

    try {
      const response = await AuthService.forgotPassword({email: client.email});

      if (response.error) {
        switch (response.error.code) {
          case HttpCodes.TOO_MANY_REQUESTS:
            return message.error(messages.errors.passwordResetAttemptLimit);
          case HttpCodes.NOT_FOUND:
            return message.error(messages.errors.passwordResetUserNotFound);
          default:
            return message.error(messages.errors.generalError);
        }
      }
      else {
        message.success(messages.success.emailReset);
      }
    }
    finally {
      setIsLoading(false);
    }
  };

  if (!ranks) {
    return <TabLoader/>;
  }

  return (
    <MainForm back={RouteList.CLIENTS}>
      <FormOutlinedInput
        title="Идентификатор Пользователя"
        typography="h2"
        inputProps={{
          defaultValue: client.id,
          disabled: true,
        }}
      />

      <Divider/>

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormOutlinedInput title="Фамилия" inputProps={{name: 'surname', defaultValue: client.personalData.surname}}/>
        </Grid>
        <Grid item xs>
          <FormOutlinedInput title="Имя" inputProps={{name: 'name', defaultValue: client.personalData.name}}/>
        </Grid>
        <Grid item xs>
          <FormOutlinedInput title="Отчество"
                             inputProps={{name: 'middle_name', defaultValue: client.personalData.middleName}}/>
        </Grid>
      </Grid>

      <Divider/>

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormDatePicker title="Дата рождения" fieldProps={{name: 'birthdate', value: client.personalData.birthdate}}/>
        </Grid>
        <Grid item xs>
          <FormOutlinedInput title="Email" inputProps={{name: 'email', defaultValue: client.email, disabled: true}}/>
        </Grid>
        <Grid item xs>
          <FormDatePicker
            title="Дата регистрации"
            fieldProps={{value: client.registrationDate}}
            datepickerProps={{disabled: true}}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormDatePicker title="Дата последней авторизации"
                          fieldProps={{disabled: true, value: client.lastLoginDate}}/>
        </Grid>
        <Grid item xs>
          <FormSelect items={ranks} title="Статус"
                      selectProps={{name: 'client_rank_id', defaultValue: client.clientRankId}}/>
        </Grid>
        <Grid item xs>
          <FormOutlinedInput
            title="Телефон"
            mask={'+7(999)-999-99-99'}
            inputProps={{
              name: 'phone', defaultValue: StringHelper.formatPhoneNumber(client.personalData.phone ?? '')
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <Grid item xs>
            <FormSelect
              items={[
                {key: GenderTypes.M, label: 'Мужской'},
                {key: GenderTypes.F, label: 'Женский'},
              ]}
              title="Пол"
              selectProps={{name: 'gender', defaultValue: client.personalData.gender}}
            />
          </Grid>
        </Grid>

        <Grid item xs ml={!config.readonly && 2}>
          <Typography variant="body2" color="grey.600">
            Баланс:
          </Typography>

          <Typography variant="body1" mt={config.readonly ? 1 : 3}>{balance.toLocaleString()}</Typography>
        </Grid>

        <Grid item xs>
          <Typography variant="body2" color="grey.600">
            Количество рефералов:
          </Typography>

          <Typography variant="body1" mt={config.readonly ? 1 : 3}>
            <ReferralInformation referrals={referrals} error={referralError}/>
          </Typography>
        </Grid>
      </Grid>

      <Divider/>

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormSelect
            items={[
              {
                key: MaritalStatusesType.SINGLE,
                label: client.personalData.gender === GenderTypes.F ? 'Не замужем' : 'Не женат',
              },
              {
                key: MaritalStatusesType.MARRIED,
                label: client.personalData.gender === GenderTypes.F ? 'Замужем' : 'Женат',
              },
            ]}
            title="Семейное положение"
            selectProps={{name: 'marital_status', defaultValue: client.personalData.maritalStatus}}
          />
        </Grid>
        <Grid item xs={2}>
          <FormOutlinedInput title="Место жительства"
                             inputProps={{name: 'region', defaultValue: client.personalData.region}}/>
        </Grid>
      </Grid>

      <Divider/>

      <FormActions>
        <Grid container columns={13} display="flex" flexDirection="row" alignItems="flex-start">
          <Grid item xs={8}>
            <LoadingButton loading={isLoading} variant="outlined" onClick={resetPassword}>
              Сброс пароля
            </LoadingButton>
          </Grid>
        </Grid>
      </FormActions>
    </MainForm>
  );
};

export default ClientShowForm;
