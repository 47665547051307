import {JsonRpcService} from '../JsonRpcService';
import {IResponse} from '../../types/response/common/Response';
import {CommunicationFactory} from './CommunicationFactory';
import {INotification, IReadMark} from '../../types/app/common/Notification';
import {
  IWebNotificationsGetLastData,
  TWebNotificationsMarkAsReadAllBeforeThisData,
  TWebNotificationsMarkAsReadData
} from '../../types/response/communication/WebNotificationsGetLastData';
import {TTemplateData} from '../../types/response/communication/TemplateData';
import {ICreateTemplateRequest} from '../../types/request/communication/CreateTemplateRequest';
import {IDeleteTemplateRequest} from '../../types/request/communication/DeleteTemplateRequest';
import {IGetTemplateRequest} from '../../types/request/communication/GetTemplateRequest';
import {IUpdateTemplateRequest} from '../../types/request/communication/UpdateTemplateRequest';
import {TAntispamData} from '../../types/response/communication/AntispamData';
import {GetAntispamRequest} from '../../types/request/communication/GetAntispamRequest';
import {TGroupsData} from '../../types/response/communication/GroupsData';
import {ICreateAntispamRequest} from '../../types/request/communication/CreateAntispamRequest';
import {IAntispamCreateTemplate} from '../../types/app/communication/AntispamCreateTemplate';
import {ITemplate} from '../../types/app/communication/Template';
import {IAntispamTemplate} from '../../types/app/communication/AntispamTemplate';
import {IGroupsTemplate} from '../../types/app/communication/GroupsTemplate';
import {INews} from '../../types/app/communication/News';
import {TNewsData} from '../../types/response/communication/NewsData';
import {IProfile} from '../../types/app/communication/Profile';
import {TGetProfileData} from '../../types/response/communication/GetProfileData';
import moment from 'moment';
import {ICreateNewsRequest} from '../../types/request/communication/CreateNewsRequest';
import {IUpdateNewsRequest} from '../../types/request/communication/UpdateNewsRequest';
import {IFeedback} from '../../types/app/communication/IFeedback';
import {TFeedbackData} from '../../types/response/communication/FeedbackData';
import {IQuestion} from '../../types/app/communication/IQuestion';
import {TQuestionsData} from '../../types/response/communication/QuestionsData';
import {ISendQuestionRequest} from '../../types/request/communication/SendQuestionRequest';
import {TSendQuestionsData} from '../../types/response/communication/SendQuestionsData';
import {InternalError, StoreTemplateFailed, WebNotificationsMarkAsReadAllBeforeThisError} from './CommunicationErrors';
import {TUpdateQuestionsData} from '../../types/response/communication/UpdateQuestionsData';
import {IUpdateQuestionRequest} from '../../types/request/communication/UpdateQuestionRequest';
import {ISelectable} from '../../types/app/common/Selectable';
import {IGetFeedbacks} from '../../types/request/support/GetFeedbakcs';
import {IPaginatedResponse} from '../../types/response/common/PaginatedResponse';
import {IPaginated} from '../../types/app/common/Paginated';
import {IGetQuestions} from '../../types/request/support/GetQuestions';

export class CommunicationService {
  private static serviceName = 'communication';

  public static async createTemplate(request: ICreateTemplateRequest): Promise<number> {
    const response = await JsonRpcService.request<IResponse<{ id: number }>>(this.serviceName, 'templates_create', request);

    if (response.error) {
      throw new StoreTemplateFailed();
    }

    return response.result.data.id;
  }

  public static async updateTemplate(request: IUpdateTemplateRequest): Promise<void> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'templates_update', request);

    if (response.error) {
      throw new StoreTemplateFailed();
    }
  }

  public static async deleteTemplate(request: IDeleteTemplateRequest): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'templates_delete', request);
  }

  public static async getTemplates(): Promise<ITemplate[]> {
    const response = await JsonRpcService.request<IResponse<TTemplateData[]>>(this.serviceName, 'templates_get_all');

    return response.result.data.map(data => CommunicationFactory.responseToTemplate(data));
  }

  public static async getTemplate(request: IGetTemplateRequest): Promise<ITemplate> {
    const response = await JsonRpcService.request<IResponse<TTemplateData>>(this.serviceName, 'templates_get_by_id', request);

    return CommunicationFactory.responseToTemplate(response.result.data);
  }

  public static async webNotificationsGetLast(): Promise<INotification | null> {
    const response = await JsonRpcService.request<IResponse<IWebNotificationsGetLastData>>(
      this.serviceName,
      'web_notifications_get_last',
      { limit: 1000 }
    );

    if (!response) {
      return null;
    }

    const data = response.result?.data;

    if (!data) {
      return null;
    }    

    return CommunicationFactory.responseToNotification(data);
  }

  public static async webNotificationsMarkAsReadAllBeforeThis(request: { all_notifications_are_already_read_before_this_notification_id: number }): Promise<IProfile> {
    const response = await JsonRpcService.request<IResponse<TWebNotificationsMarkAsReadAllBeforeThisData>>(
      CommunicationService.serviceName,
      'web_notifications_mark_as_read_all_before_this',
      request
    );

    if (response.error) {
      throw new WebNotificationsMarkAsReadAllBeforeThisError();
    }

    return {
      id: response.result.data.id,
      createdAt: moment(response.result.data.created_at).unix(),
      updatedAt: moment(response.result.data.updated_at).unix(),
      groupId: response.result.data.group_id,
      allNotificationsAreAlreadyReadBeforeThisNotificationId: response.result.data.all_notifications_are_already_read_before_this_notification_id,
      enableWebNotice: response.result.data.enable_web_notice,
      enableEmailNotice: response.result.data.enable_email_notice,
    }
  }

  public static async webNotificationsMarkAsRead(request: { ids: number[] }): Promise<IReadMark[]> {
    const response = await JsonRpcService.request<IResponse<TWebNotificationsMarkAsReadData[]>>(
      CommunicationService.serviceName,
      'web_notifications_mark_as_read',
      request
    );

    return response.result.data.map(item => ({
      noticeId: item.notice_id,
      profileId: item.profile_id,
      updatedAt: item.updated_at,
      createdAt: item.created_at,
      id: item.id,
    }));
  }

  public static async getAllAntispamRule(): Promise<IAntispamTemplate[]> {
    const response = await JsonRpcService.request<IResponse<TAntispamData[]>>(this.serviceName, 'antispam_rule_get');

    return response.result.data.map(data => CommunicationFactory.responseToAntispam(data));
  }

  public static async getAntispamRule(request: GetAntispamRequest): Promise<IAntispamTemplate> {
    const response = await JsonRpcService.request<IResponse<TAntispamData>>(this.serviceName, 'antispam_rule_get', request);

    return CommunicationFactory.responseToAntispam(response.result.data[0]);
  }

  public static async deleteAntispamRule(request: { id: number }): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'antispam_rule_delete', request);
  }

  public static async getAllGroups(): Promise<IGroupsTemplate[]> {
    const response = await JsonRpcService.request<IResponse<TGroupsData[]>>(this.serviceName, 'groups_get_all');

    return response.result.data.map(data => CommunicationFactory.responseToGroupsFetch(data));
  }

  public static async createAntispamRule(request: ICreateAntispamRequest): Promise<IAntispamCreateTemplate> {
    const response = await JsonRpcService.request<IResponse<ICreateAntispamRequest>>(
      this.serviceName,
      'antispam_rule_create',
      request
    );

    return CommunicationFactory.responseToAntispamCreate(response.result.data);
  }

  public static async updateAntispamRule(request: ICreateAntispamRequest): Promise<IAntispamCreateTemplate> {
    const response = await JsonRpcService.request<IResponse<ICreateAntispamRequest>>(
      this.serviceName,
      'antispam_rule_update',
      request
    );

    return CommunicationFactory.responseToAntispamCreate(response.result.data);
  }

  public static async getAntispamRuleGetPeriodicityTypes(): Promise<string[]> {
    const response = await JsonRpcService.request<IResponse<string[]>>(this.serviceName, 'antispam_rule_get_periodicity_types');

    return response.result.data;
  }

  public static async getNews(request?: { id?: number, is_active?: boolean }): Promise<INews[]> {
    const response = await JsonRpcService.request<IResponse<TNewsData[]>>(this.serviceName, 'newsletter_get', {
      ...request,
    });

    const templates = await this.getTemplates();
    const map = new Map<number, ITemplate>();

    templates.forEach(template => map.set(template.id, template));

    const news: INews[] = [];

    response.result.data.forEach(item => {
      const newsItem = CommunicationFactory.responseToNews(item);

      newsItem.template = map.get(newsItem.templateId);
      news.push(newsItem);
    });

    return news;
  }

  public static async deactivate(request: { id: number }): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'newsletter_deactivate', request);
  }

  public static async getProfiles(): Promise<IProfile[]> {
    const response = await JsonRpcService.request<IResponse<TGetProfileData[]>>(this.serviceName, 'profiles_get_all');

    return response.result.data.map(profile => ({
      id: profile.id,
      allNotificationsAreAlreadyReadBeforeThisNotificationId:
        profile.all_notifications_are_already_read_before_this_notification_id,
      enableWebNotice: profile.enable_web_notice,
      enableEmailNotice: profile.enable_email_notice,
      groupId: profile.group_id,
      createdAt: profile.created_at ? moment(profile.created_at).unix() : null,
      updatedAt: profile.updated_at ? moment(profile.updated_at).unix() : null,
      email: profile.email,
    }));
  }

  public static async createNews(request: ICreateNewsRequest): Promise<INews> {
    const response = await JsonRpcService.request<IResponse<TNewsData>>(this.serviceName, 'newsletter_create', request);

    const newsItem = CommunicationFactory.responseToNews(response.result.data);
    newsItem.template = await this.getTemplate({ id: newsItem.templateId });

    return newsItem;
  }

  public static async updateNews(request: IUpdateNewsRequest): Promise<INews> {
    const response = await JsonRpcService.request<IResponse<TNewsData>>(this.serviceName, 'newsletter_update', request);

    const newsItem = CommunicationFactory.responseToNews(response.result.data);
    newsItem.template = await this.getTemplate({ id: newsItem.templateId });

    return newsItem;
  }

  public static async getFeedback(request: IGetFeedbacks): Promise<IPaginated<IFeedback[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TFeedbackData[]>>(
      CommunicationService.serviceName,
      'feedback_get',
      request
    );

    const items = response.result.data.items.map(feedbackMessage => CommunicationFactory.responseToFeedback(feedbackMessage))

    return { items, ...response.result.data.pagination }
  }

  public static async getQuestions(request: IGetQuestions): Promise<IPaginated<IQuestion[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TQuestionsData[]>>(
      CommunicationService.serviceName,
      'questions_get',
      request
    );

    const items = response.result.data.items.map(question => CommunicationFactory.responseToQuestion(question));

    return { items, ...response.result.data.pagination }
  }

  public static async getQuestion(request: { id: number }): Promise<IQuestion[]> {
    const response = await JsonRpcService.request<IResponse<TQuestionsData[]>>(
      this.serviceName,
      'questions_get',
      request
    );

    return response.result.data.map(question => CommunicationFactory.responseToQuestion(question));
  }


  public static async updateQuestions(request: IUpdateQuestionRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<TUpdateQuestionsData>>(
      this.serviceName,
      'questions_update',
      request
    );

    if (response.error) {
      throw new InternalError();
    }

    return response.result.status;
  }

  public static async sendQuestions(request: ISendQuestionRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<TSendQuestionsData[]>>(
      this.serviceName,
      'questions_send',
      request
    );

    if (response.error) {
      throw new InternalError();
    }

    return response.result.status;
  }

  public static async sendEmegrgencyNotificationToEmail(request: { template_id: number, email: string }): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(
      CommunicationService.serviceName,
      'notices_emergency',
      request
    );

    if (response.error) {
      throw new InternalError();
    }

    return response.result.status;
  }

  public static async getQuestionSubjects(): Promise<ISelectable[]> {
    const response = await JsonRpcService.request<IResponse<{id: number, name: string}[]>>(
      CommunicationService.serviceName,
      'questions_subjects',
    )

    return response.result.data.map(subject => ({key: subject.id, label: subject.name}))
  }
}
