export enum AccountProperties {
  REQUISITES = 'operator_requisites',
  CONVERT_RATE =  'convert_rate',
  PAYIN_RATE = 'payin_rate',
  PAYOUT_RATE = 'payout_rate',
  LIFETIME_DAYS_FOR_ALL_RETURNED_POINTS = 'lifetime_days_for_all_returned_points',
  FAMILY_MINIMAL_DAYS_ENTRY = 'family_minimal_days_entry',
  POINT_BALANCE_CREDIT_LIMIT = 'point_balance_credit_limit',
  LEVEL_FOR_LOW_RUB_BALANCE_NOTIFICATION = 'level_for_low_rub_balance_notification',
  LEVEL_FOR_LOW_POINT_BALANCE_NOTIFICATION = 'level_for_low_point_balance_notification',
  MAX_POINTS_QUOTE_FOR_ORDER = 'max_points_quote_for_order',
}

export enum AccountObjects {
  COMMON = 'common',
  PARTNER = 'partner',
}