import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useAsyncValue, useLocation, useNavigate, useNavigation, useParams} from 'react-router-dom';
import {IRole} from '../../../../../types/app/auth/Role';
import {ISiteSection} from '../../../../../types/app/auth/SiteSection';
import PageLoader from '../../../Loader/PageLoader';
import {AuthService} from '../../../../../http/auth/AuthService';
import MainForm from '../../../Form/MainForm';
import {Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography} from '@mui/material';
import FormOutlinedInput from '../../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../../UI/Delimiter/Divider';
import FormActions from '../../../../../UI/Form/FormActions';
import {RouteList} from '../../../../../http/RouteList';
import {UserTypeValues} from '../../../../../enums/UserTypes';
import {SiteTypes} from '../../../../../enums/SiteTypes';
import {FormContext, IFormConfig} from '../../../../../store/context/form/FormContext';
import {snackConfig} from '../../../../../layout/assets/config/snackConfig';
import {useSnackbar} from 'notistack';
import {useActions, useAppSelector} from '../../../../../hooks/hooks';
import GuardLoader from '../../../Loader/GuardLoader';
import {IAuthUser} from '../../../../../types/app/auth/AuthUser';
import {NavigationStates} from '../../../../../enums/NavigationStates';
import {UserRoleTypes} from '../../../../../enums/SystemUserRoles';

const RoleShowForm = () => {
  const {pathname} = useLocation();

  const isPartnerRoute = useMemo(() => pathname.includes(RouteList.PARTNERS_ROLE_SHOW), []);
  const isOperatorRoute = useMemo(() => pathname.includes(RouteList.USERS_ROLE_SHOW), []);

  const role = useAsyncValue() as IRole;

  const user = useAppSelector(state => state.auth.user);

  const route = useNavigate();
  const params = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const {setConfig, config} = useContext(FormContext);
  const {login} = useActions();

  const [sections, setSections] = useState<ISiteSection[]>(null);
  const [selectedSectionIds, setSelectedSectionIds] = useState<number[]>(
    role.sections
      .filter(section => section.site === (isPartnerRoute ? SiteTypes.PARTNER : SiteTypes.OPERATOR))
      .map(section => section.id)
  );

  const navigation = useNavigation();

  const getData = useCallback(
    () => [
      {field: 'sections_ids', value: selectedSectionIds.toString()},
      {field: 'id', value: role.id},
      {field: 'email', value: user.roleId === role.id ? user.email : ''},
    ],
    [selectedSectionIds]
  );

  const formConfig: IFormConfig = {
    readonly: true,
    action: isPartnerRoute ? RouteList.PARTNERS_ROLE_SHOW + '/' + role.id : RouteList.USERS_ROLE_SHOW + '/' + role.id,
    rules: {
      name: ['required', 'text'],
      description: ['required'],
    },
    data: getData(),
    afterSubmitHandler: (formResponse, config, setConfig) => {
      enqueueSnackbar('Роль успешно обновлена. Изменения сохранены.', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      setConfig({...config, readonly: true});

      if (formResponse.payload) {
        login(formResponse.payload as IAuthUser);
      }
    },
  };

  const isDisabled = useCallback((section: ISiteSection) => {
    return selectedSectionIds.length === 1 && selectedSectionIds.includes(section.id);
  }, [selectedSectionIds]);

  useEffect(() => {
    if (isPartnerRoute && !role.types.includes(UserTypeValues.PARTNER)) {
      route(RouteList.USERS_ROLE_SHOW + '/' + params.id);

      return;
    }

    if (isOperatorRoute && !role.types.includes(UserTypeValues.OPERATOR)) {
      route(RouteList.PARTNERS_ROLE_SHOW + '/' + params.id);

      return;
    }

    AuthService.getSiteSections({site: isPartnerRoute ? SiteTypes.PARTNER : SiteTypes.OPERATOR}).then(allSections => {
        const filteredSections = allSections.filter(section => section.id !== 31);
        setSections(filteredSections);
      }
    );

    setConfig(formConfig);
  }, []);
  useEffect(() => {
    if (config.readonly === false) {
      setConfig({
        ...formConfig,
        data: getData(),
        readonly: false,
      });
    }
  }, [selectedSectionIds]);

  const checkSectionHandler = (section: ISiteSection, isChecked: boolean) => {
    if (isChecked) {
      setSelectedSectionIds([...selectedSectionIds, section.id]);
    }
    else {
      setSelectedSectionIds(selectedSectionIds.filter(sectionId => sectionId !== section.id));
    }
  };

  if (navigation.state !== NavigationStates.IDLE && role.id === user.roleId) {
    return <GuardLoader/>;
  }

  if (!sections) {
    return <PageLoader top={40}/>;
  }

  return (
    <MainForm back={isPartnerRoute ? RouteList.PARTNERS : RouteList.USERS}
              isHiddenEditAndCloseButtons={role.type === UserRoleTypes.SYSTEM}>
      <FormOutlinedInput
        title="Наименование роли"
        typography="h1"
        inputProps={{
          name: 'name',
          defaultValue: role.name,
        }}
      />

      <Divider/>

      <FormOutlinedInput
        title="Описание роли"
        inputProps={{
          name: 'description',
          defaultValue: role.description,
          multiline: true,
          minRows: 3,
        }}
      />

      <Divider/>

      <Box>
        <Typography variant="body2" color="grey.600" mb={1}>
          Перечень доступных разделов личного кабинета Оператора:
        </Typography>

        <Grid container columns={3}>
          {sections.map(section => (
            <Grid key={section.id} item xs={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={config.readonly || isDisabled(section)}
                      size="small"
                      checked={selectedSectionIds.includes(section.id)}
                      onChange={e => checkSectionHandler(section, e.target.checked)}
                    />
                  }
                  label={section.description}
                />
              </FormGroup>
            </Grid>
          ))}
        </Grid>
      </Box>

      <FormActions/>
    </MainForm>
  );
};

export default RoleShowForm;
