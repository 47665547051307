import {BillingService} from './BillingService';
import {FormDataHelper} from '../../helpers/FormDataHelper';
import {IFormResponse} from '../../store/context/form/types/FormResponse';
import {AccountService} from '../account/AccountService';
import {AccountObjects, AccountProperties} from '../../enums/AccountProperties';
import moment from 'moment';
import {ICreateTariffRequest} from '../../types/request/billing/CreateTariffRequest';
import {IUpdateTariffRequest} from '../../types/request/billing/UpdateTariffRequest';
import {Switchable} from '../../types/app/common/Switchable';
import {ICreatePromoRequest} from '../../types/request/billing/CreatePromoRequest';
import {IUpdatePromoRequest} from '../../types/request/billing/UpdatePromoRequest';
import {TariffTypes} from '../../enums/TariffTypes';

interface ITariffFormData {
  active?: 'on' | 'off';
  bonus?: 'on' | 'off';
  exclusivity?: 'on' | 'off';
  amount?: string; // "0"
  percent?: string; // "0"
  categories_ids: string; // 0,1,2,3...
  min_product_quantity?: string; // "0""
  name: string;
  partners_ids: string; // 0,1,2,3...
  priority: string; // 0 | 1 | 2
  products_ids: string; // 0,1,2,3...
  valid_from: string; // yyyy-mm-dd
  valid_from_hour: string; // timestamp
  valid_to: string; // yyyy-mm-dd
  valid_to_hour: string; // timestamp
  id?: number;
  comment: string;
  lifetime_days?: number;
  tariff_type: number;
}

export class BillingActions {
  /**
   * Get tariff request for server action
   *
   * @param requestData
   * @private
   */
  private static getTariffRequest = (requestData: ITariffFormData): ICreateTariffRequest | IUpdateTariffRequest => {
    const addTariffRequest: ICreateTariffRequest = {
      name: requestData.name,
      partners_ids: requestData.partners_ids,
      categories_ids: requestData.categories_ids,
      products_ids: requestData.products_ids,
      priority: Number(requestData.priority),
      valid_from: requestData.valid_from,
      valid_to: requestData.valid_to,
      comment: requestData.comment,
      lifetime_days: requestData.lifetime_days,
      tariff_type: requestData.tariff_type,
    };

    if (requestData.valid_from_hour !== '') {
      addTariffRequest.valid_from_hour = moment.unix(Number(requestData.valid_from_hour)).get('hour');
    }

    if (requestData.valid_to_hour !== '') {
      addTariffRequest.valid_to_hour = moment.unix(Number(requestData.valid_to_hour)).get('hour');
    }

    if (Object.hasOwn(requestData, 'amount')) {
      addTariffRequest.amount = Number(requestData.amount);
    }
    else {
      addTariffRequest.percent = Number(requestData.percent);
    }

    if (requestData.bonus === 'on') {
      addTariffRequest.bonus = 'Кешбэк бонус';
    }

    if (requestData.exclusivity === 'on') {
      addTariffRequest.exclusivity = 1;
    }
    else {
      addTariffRequest.exclusivity = 0;
    }

    if (requestData.active === 'on') {
      addTariffRequest.active = 1;
    }
    else {
      addTariffRequest.active = 0;
    }

    if (!requestData.id) {
      return addTariffRequest;
    }

    return {
      id: requestData.id,
      ...addTariffRequest,
    } as IUpdateTariffRequest;
  };

  /**
   * Update current tariff
   *
   * @param request
   */
  public static updateTariffRouteAction = async ({request}): Promise<IFormResponse> => {
    const formData: FormData = await request.formData();
    const requestData = FormDataHelper.toObject<ITariffFormData>(formData);

    if (+requestData.tariff_type === TariffTypes.AMOUNT) {
      delete requestData.percent;
    }
    else if (+requestData.tariff_type === TariffTypes.PERCENT || +requestData.tariff_type === TariffTypes.CASHBACK) {
      delete requestData.amount;
    }

    try {
      await BillingService.updateTariff(this.getTariffRequest(requestData) as IUpdateTariffRequest);

      return {status: true};
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  /**
   * Store new billing settings
   *
   * @param request
   */
  public static storeSettings = async ({request}) => {
    const formData: FormData = await request.formData();

    const settings = FormDataHelper.toObject<{
      convert_rate?: string;
      life_time_days_for_all_returned_points?: string;
      max_points_quote_for_order: string;
    }>(formData);

    try {
      if (settings.life_time_days_for_all_returned_points) {
        await AccountService.setProperty({
          obj_name: AccountObjects.COMMON,
          property: AccountProperties.LIFETIME_DAYS_FOR_ALL_RETURNED_POINTS,
          value: settings.life_time_days_for_all_returned_points,
        });
      }

      if (settings.max_points_quote_for_order) {
        await AccountService.setProperty({
          obj_name: AccountObjects.COMMON,
          property: AccountProperties.MAX_POINTS_QUOTE_FOR_ORDER,
          value: settings.max_points_quote_for_order,
        });
      }
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }

    return {status: true};
  };

  /**
   * Create new tariff
   *
   * @param request
   */
  public static createTariffRouteAction = async ({request}): Promise<IFormResponse> => {
    const modifiedFormData: ITariffFormData = {
      name: request.name,
      bonus: request.bonus ? 'on' : 'off',
      priority: request.priority,
      exclusivity: request.exclusivity ? 'on' : 'off',
      partners_ids: request.partners_ids.join(','),
      categories_ids: request.categories_ids.join(','),
      products_ids: request.products_ids.join(','),
      valid_from: request.valid_from,
      valid_to: request.valid_to,
      valid_from_hour: request.valid_from_hour,
      valid_to_hour: request.valid_to_hour,
      active: request.active ? 'on' : 'off',
      comment: request.comment,
      lifetime_days: request.lifetime_days && Number(request.lifetime_days),
      tariff_type: request.tariff_type,
    };

    if (request.tariff_type === TariffTypes.AMOUNT) {
      modifiedFormData.amount = request.amount;
    }
    else if (request.tariff_type === TariffTypes.PERCENT || request.tariff_type === TariffTypes.CASHBACK) {
      modifiedFormData.percent = request.percent;
    }

    try {
      const tariffId = await BillingService.createTariff(this.getTariffRequest(modifiedFormData));

      return {
        status: true,
        payload: tariffId,
      };
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  public static updatePromoRouteAction = async ({request, params}): Promise<IFormResponse> => {
    const formData = FormDataHelper.toObject<{
      active: Switchable;
      valid_to_hour: string;
      valid_to: string;
      valid_from_hour?: string;
      valid_from?: string;
      required_minimal_amount: string;
      product_ids: string;
      product_counts: string;
      points_additional_amount?: string;
      points_multiplier?: string;
      points_lifetime_days?: string;
      certificate_type?: string;
      quantity?: string;
      is_mix?: Switchable;
      is_part?: Switchable;
      expires_date?: string;
      amount?: string;
      user_email?: string;
      partner_id: string;
      name: string;
      description: string;
      category_ids: string;
      category_counts: string;
      productId_for_certificate: string
      product_name_for_certificate: string
    }>(await request.formData());

    const updateRequest: IUpdatePromoRequest = {
      id: params.id,
      active: formData.active === Switchable.ON,
      name: formData.name,
      description: formData.description,
      partner_id: +formData.partner_id,
      valid_from: moment(formData.valid_from).format('YYYY-MM-DD'),
      valid_to: moment(formData.valid_to).format('YYYY-MM-DD'),
      add_points: !!formData.points_additional_amount,
      multiply_points: !!formData.points_multiplier,
      issue_certificate: !!formData.certificate_type,
      is_categories_required: !!formData.category_counts,
      is_products_required: !!formData.product_counts,
      is_minimal_amount_required: !!formData.required_minimal_amount,
      is_users_required: false,
    };

    updateRequest.valid_from_hour = formData.valid_from_hour !== '' ? formData.valid_from_hour : null;
    updateRequest.valid_to_hour = formData.valid_to_hour !== '' ? formData.valid_to_hour : null;

    if (formData.productId_for_certificate) {
      updateRequest.productId_for_certificate = Number(formData.productId_for_certificate);
    }

    if (formData.product_name_for_certificate) {
      updateRequest.product_name_for_certificate = formData.product_name_for_certificate;
    }

    if (updateRequest.is_minimal_amount_required) {
      updateRequest.required_minimal_amount = +formData.required_minimal_amount;
    }
    else {
      updateRequest.required_minimal_amount = null;
    }

    if (updateRequest.add_points || updateRequest.multiply_points) {
      updateRequest.points_lifetime_days = +formData.points_lifetime_days;
    }
    else {
      updateRequest.points_lifetime_days = null;
    }

    if (updateRequest.add_points) {
      updateRequest.points_additional_amount = +formData.points_additional_amount;
    }
    else {
      updateRequest.points_additional_amount = null;
    }

    if (updateRequest.multiply_points) {
      updateRequest.points_multiplier = +formData.points_multiplier;
    }
    else {
      updateRequest.points_multiplier = null;
    }

    if (updateRequest.issue_certificate) {
      BillingActions.addCertificateParamsToPromoRequest(updateRequest, formData);
    }
    else {
      updateRequest.certificate_params = null;
    }

    if (updateRequest.is_products_required) {
      BillingActions.addProductsParamsToPromoRequest(updateRequest, formData);
    }
    else {
      updateRequest.products = null;
    }

    if (updateRequest.is_categories_required) {
      BillingActions.addCategoriesParamsToPromoRequest(updateRequest, formData);
    }
    else {
      updateRequest.categories = null;
    }

    try {
      return {
        status: true,
        payload: await BillingService.updatePromo(updateRequest),
      };
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  public static createPromoRouteAction = async ({request}): Promise<IFormResponse> => {
    const formData = FormDataHelper.toObject<{
      valid_to_hour: string;
      valid_to: string;
      valid_from_hour?: string;
      valid_from?: string;
      required_minimal_amount: string;
      product_ids: string;
      product_counts: string;
      points_additional_amount?: string;
      points_multiplier?: string;
      points_lifetime_days?: string;
      certificate_type?: string;
      quantity?: string;
      is_mix?: Switchable;
      is_part?: Switchable;
      expires_date?: string;
      amount?: string;
      user_email?: string;
      partner_id: string;
      name: string;
      description: string;
      category_ids: string;
      category_counts: string;
      productId_for_certificate: string
      product_name_for_certificate: string
    }>(await request.formData());

    const createRequest: ICreatePromoRequest = {
      active: true,
      name: formData.name,
      description: formData.description,
      partner_id: +formData.partner_id,
      valid_from: formData.valid_from,
      valid_to: formData.valid_to,
      add_points: !!formData.points_additional_amount,
      multiply_points: !!formData.points_multiplier,
      issue_certificate: !!formData.certificate_type,
      is_categories_required: !!formData.category_counts,
      is_products_required: !!formData.product_counts,
      is_minimal_amount_required: !!formData.required_minimal_amount,
      is_users_required: false,
    };

    if (formData.productId_for_certificate) {
      createRequest.productId_for_certificate = Number(formData.productId_for_certificate);
    }

    if (formData.product_name_for_certificate) {
      createRequest.product_name_for_certificate = formData.product_name_for_certificate;
    }

    if (formData.valid_from_hour) {
      createRequest.valid_from_hour = formData.valid_from_hour;
    }

    if (formData.valid_to_hour) {
      createRequest.valid_to_hour = formData.valid_to_hour;
    }

    if (createRequest.is_minimal_amount_required) {
      createRequest.required_minimal_amount = +formData.required_minimal_amount;
    }

    if (createRequest.add_points || createRequest.multiply_points) {
      createRequest.points_lifetime_days = +formData.points_lifetime_days;
    }

    if (createRequest.add_points) {
      createRequest.points_additional_amount = +formData.points_additional_amount;
    }

    if (createRequest.multiply_points) {
      createRequest.points_multiplier = +formData.points_multiplier;
    }

    if (createRequest.issue_certificate) {
      BillingActions.addCertificateParamsToPromoRequest(createRequest, formData);
    }

    if (createRequest.is_products_required) {
      BillingActions.addProductsParamsToPromoRequest(createRequest, formData);
    }

    if (createRequest.is_categories_required) {
      BillingActions.addCategoriesParamsToPromoRequest(createRequest, formData);
    }

    try {
      const newPromo = await BillingService.createPromo(createRequest);

      return {
        status: true,
        payload: newPromo.id,
      };
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  private static addCertificateParamsToPromoRequest(request: ICreatePromoRequest | IUpdatePromoRequest, formData): void {
    const certificateParams = {
      type: formData.certificate_type,
      quantity: formData.quantity,
      is_mix: formData.is_mix === Switchable.ON,
      is_part: formData.is_part === Switchable.ON,
    };

    if (formData.expires_date) {
      certificateParams['expires_date'] = formData.expires_date;
    }

    if (formData.amount) {
      certificateParams['amount'] = formData.amount;
    }

    if (formData.user_email) {
      certificateParams['user_email'] = formData.user_email;
    }

    if (formData.productId_for_certificate) {
      certificateParams['products'] = {
        [formData.productId_for_certificate]: formData.product_name_for_certificate
      };
    }

    request.certificate_params = JSON.stringify(certificateParams);
  }

  private static addProductsParamsToPromoRequest(request: ICreatePromoRequest | IUpdatePromoRequest, formData): void {
    const counts = formData.product_counts.split(',');

    request.products = formData.product_ids.split(',').map((productId, index) => ({
      product_id: +productId,
      required_quantity: +counts[index],
    }));
  }

  private static addCategoriesParamsToPromoRequest(request: ICreatePromoRequest | IUpdatePromoRequest, formData): void {
    const counts = formData.category_counts.split(',');

    request.categories = formData.category_ids.split(',').map((categoryId, index) => ({
      category_id: +categoryId,
      required_quantity: +counts[index],
    }));
  }
}
