import React, {useState} from 'react';
import {IPaginatable} from '../../../types/app/common/Paginatable';
import TableContainer from '../../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {getAntispamPeriodicityName} from '../../../enums/AntispamPeriodicity';
import {RouteList} from '../../../http/RouteList';
import ShowButton from '../../../UI/Button/ShowButton';
import {Grid} from '@mui/material';
import DeleteButton from '../../../UI/Button/DeleteButton';
import Confirm from '../../Common/Modal/Confirm';
import {CommunicationService} from '../../../http/communication/CommunicationService';
import {snackConfig} from '../../../layout/assets/config/snackConfig';
import {useSnackbar} from 'notistack';
import {IAntispamTemplate} from '../../../types/app/communication/AntispamTemplate';
import {IGroupsTemplate} from '../../../types/app/communication/GroupsTemplate';
import {getGroupTypeTranslation} from '../../../enums/Groups';

interface IAntispamTabDataProps extends IPaginatable<IAntispamTemplate> {
  dispatch: Function;
  groups: IGroupsTemplate[];
  periodicityTypes: string[];
}

const AntispamTabData = (props: IAntispamTabDataProps) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templateToDelete, setTemplateToDelete] = useState<IAntispamTemplate>(null);

  const { enqueueSnackbar } = useSnackbar();

  const findGroupsByIds = (ids: number[]) => {
    return ids.map(id => {
      const group = props.groups?.find(element => element.id === id);
      return group ? getGroupTypeTranslation(group.name) : null;
    }).filter(Boolean).join(', ');
  };

  const deleteTemplateHandler = async () => {
    setIsLoading(true);

    await CommunicationService.deleteAntispamRule({
      id: templateToDelete.id,
    });

    props.dispatch(props.items.filter(item => item.id !== templateToDelete.id));

    setIsLoading(false);
    closeConfirm();

    enqueueSnackbar('Правило антиспама было удалено.', {
      variant: 'info',
      autoHideDuration: snackConfig.duration,
    })
  };

  const openConfirm = (template: IAntispamTemplate) => {
    setTemplateToDelete(template);
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
    setTemplateToDelete(null);
  };

  return(
    <>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteTemplateHandler}
        text='Вы действительно хотите удалить правило? Данное действие нельзя будет отменить'
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={200}>Частота отправки</TableCell>
              <TableCell width={250}>Условия</TableCell>
              <TableCell width={250}>Для кого</TableCell>
              <TableCell width={150}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map(item => (
              <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>1 {getAntispamPeriodicityName(item.periodicity).toLowerCase()}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{findGroupsByIds(item.groups)}</TableCell>
                <TableCell>
                  <Grid container justifyContent="flex-end" flexDirection="row">
                    <Grid item>
                      <ShowButton linkProps={{ to: RouteList.NOTIFICATIONS_ANTISPAM_SHOW + '/' + item.id }} />
                    </Grid>
                    <Grid item>
                      <DeleteButton onClick={() => openConfirm(item)} />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AntispamTabData