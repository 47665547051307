import {IProduct} from '../types/app/catalog/Product';

export enum ProductStatuses {
  CHECK_IS_REQUIRED = 1,
  PUBLISHED = 2,
  RETURNED = 3,
  DEACTIVATED = 4,
}

export enum ProductStatusesDescription {
  CHECK_IS_REQUIRED = "На проверке",
  PUBLISHED = "Опубликован",
  RETURNED = "На доработке",
  DEACTIVATED = "Деактивирован",
}


export const getColorByProductStatus = (status: ProductStatuses): string => {
  switch (status) {
    case ProductStatuses.CHECK_IS_REQUIRED:
      return '#0042E7';
    case ProductStatuses.PUBLISHED:
      return '#00AA1B';
    case ProductStatuses.RETURNED:
      return '#FFCE45';
    case ProductStatuses.DEACTIVATED:
      return '#B03A3A';

    default: return 'red';
  }
};

export const getProductDescriptionByStatusId = (status: ProductStatuses): string => {
  switch (status) {
    case ProductStatuses.CHECK_IS_REQUIRED:
      return ProductStatusesDescription.CHECK_IS_REQUIRED;
    case ProductStatuses.PUBLISHED:
      return ProductStatusesDescription.PUBLISHED;
    case ProductStatuses.RETURNED:
      return ProductStatusesDescription.RETURNED;
    case ProductStatuses.DEACTIVATED:
      return ProductStatusesDescription.DEACTIVATED;

    default: return 'red';
  }
};

export const getProductItems = (products: IProduct[]) => {

  return products.map((product => {
    return {
      key: product.id,
      label: product.name
    }
  }))
};
