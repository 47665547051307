import React from 'react';
import Tabs from '../../../UI/Tab/Tabs';
import TabPanel from '../../../UI/Tab/TabPanel';
import RuleSettingsTabMain from '../../../Widgets/FraudMonitoring/RuleSettingsTab/RuleSettingsTabMain';
import RuleReportTabMain from '../../../Widgets/FraudMonitoring/RuleReportTab/RuleReportTabMain';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';

const FraudMonitoringIndex = () => {
  return (
    <ContentContainer title="Фрод мониторинг">
      <Tabs
        items={[
          { title: 'Оповещения', tabIndex: 0 },
          { title: 'Управление правилами', tabIndex: 1 },
        ]}
      />

      <TabPanel index={0}>
        <RuleReportTabMain />
      </TabPanel>

      <TabPanel index={1} width={100}>
        <RuleSettingsTabMain />
      </TabPanel>
    </ContentContainer>
  );
};

export default FraudMonitoringIndex;