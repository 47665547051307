import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { ITariffShowLoader } from '../../../http/billing/BillingLoaders';
import TariffShowForm from '../../../Widgets/Billing/BillingTab/Operations/Tariff/TariffShowForm';

const TariffShow = () => {
  const { content } = useLoaderData() as { content: Promise<ITariffShowLoader> };

  return (
    <Await resolve={content}>
      <TariffShowForm />
    </Await>
  );
};

export default TariffShow;
