import { authSlice } from '../reducers/authSlice';
import { historySlice } from '../reducers/historySlice';
import { tweakToolsSettingSlice } from '../reducers/tweakToolsSettingSlice';
import { storageSlice } from '../reducers/storageSlice';
import { serverPaginationSlice } from '../reducers/serverPaginationSlice';

export const actionCreators = {
  ...authSlice.actions,
  ...historySlice.actions,
  ...tweakToolsSettingSlice.actions,
  ...storageSlice.actions,
  ...serverPaginationSlice.actions,
};
