import React, { useLayoutEffect } from 'react';
import { Outlet, useLoaderData } from 'react-router-dom';
import { IAuthUser } from './types/app/auth/AuthUser';
import { FormContextProvider } from './store/context/form/FormContext';
import { useActions, useAppSelector } from './hooks/hooks';

const App = () => {
  const loadUser = useLoaderData() as IAuthUser;
  const authUser = useAppSelector(state => state.auth.user);

  const { login } = useActions();

  useLayoutEffect(() => {
    if (!authUser) {
      login(loadUser);
    }
  }, []);

  return (
    <FormContextProvider>
      <Outlet context={loadUser} />
    </FormContextProvider>
  );
};

export default App;
