import {ISelectable} from '../types/app/common/Selectable';
import {getRuleName} from '../utils/fraudUtils';

export enum FraudRuleReportIds {
  SALES_FRAUD = 1,
  BIG_TRANSACTION_FRAUD,
  MONEY_BACK_FRAUD,
  REFERRAL_FRAUD,
}

export enum FraudSettingConditionNameEnum {
  ORDERS_COUNT_MAX = 'orders_count_max',
  ORDERS_PERIOD_DAYS_COUNT = 'orders_period_days_count',

  ORDER_AMOUNT_MAX = 'order_amount_max',

  MONEY_BACK_SUM_MAX = 'money_back_sum_max',
  MONEY_BACK_PERIOD_DAYS_COUNT = 'money_back_period_days_count',

  REFERRALS_COUNT_MAX = 'referrals_count_max',
  REFERRALS_PERIOD_DAYS_COUNT = 'referrals_period_days_count',
}

export namespace FraudSettingConditionNames {
  export const toView = (name: FraudSettingConditionNameEnum) => {
    switch (name) {
      case FraudSettingConditionNameEnum.ORDERS_COUNT_MAX:
        return 'Допустимое количество транзакций Пользователя';
      case FraudSettingConditionNameEnum.ORDERS_PERIOD_DAYS_COUNT:
        return 'Период (дней, не более 100)';

      case FraudSettingConditionNameEnum.ORDER_AMOUNT_MAX:
        return 'Допустимая сумма чека не более';

      case FraudSettingConditionNameEnum.MONEY_BACK_SUM_MAX:
        return 'Максимальный размер общей суммы возврата за период';
      case FraudSettingConditionNameEnum.MONEY_BACK_PERIOD_DAYS_COUNT:
        return 'Период (дней, не более 100)';

      case FraudSettingConditionNameEnum.REFERRALS_COUNT_MAX:
        return 'Допустимое количество рефералов';
      case FraudSettingConditionNameEnum.REFERRALS_PERIOD_DAYS_COUNT:
        return 'Период (дней, не более 100)';

      default:
        return 'Настройка не поддерживается';
    }
  };
}

export namespace FraudRuleReportIds {
  export const asSelectable = (): ISelectable[] => {
    return [
      {
        key: FraudRuleReportIds.SALES_FRAUD,
        label: getRuleName('sales_fraud'),
      },
      {
        key: FraudRuleReportIds.BIG_TRANSACTION_FRAUD,
        label: getRuleName('big_transaction_fraud'),
      },
      {
        key: FraudRuleReportIds.MONEY_BACK_FRAUD,
        label: getRuleName('money_back_fraud'),
      },
      {
        key: FraudRuleReportIds.REFERRAL_FRAUD,
        label: getRuleName('referral_fraud'),
      },
    ];
  };
}
