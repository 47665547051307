import {IClientRank} from '../types/app/client/ClientRank';
import {IClientRankSetting} from '../types/app/billing/ClientRankSetting';

/**
 * Get client rank name by id
 *
 * @param {number} clientId
 * @param {IClientRank[]} ranks
 */
export const getStatusNameByClientId = (clientId: number, ranks: IClientRank[]): string => {
  const result: IClientRank | undefined = ranks.find(client => client.id === clientId);

  if (!result) {
    return '';
  }

  return result.name;
}

/**
 * Get setting percent by current rank
 *
 * @param {IClientRank} rank
 * @param {IClientRankSetting[]} settings
 */
export const getClientSettingPercentByRank = (rank: IClientRank, settings: IClientRankSetting[]): number => {
  const setting = settings.filter(setting => setting.rankId === rank.id);

  if (!setting.length) {
    return 0;
  }

  return setting[0].percent;
}
