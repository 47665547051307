import React from 'react';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';
import Tabs from '../../../UI/Tab/Tabs';
import TabPanel from '../../../UI/Tab/TabPanel';
import TemplateTabMain from '../../../Widgets/Notifications/TemplateTab/TemplateTabMain';
import AntispamTabMain from "../../../Widgets/Notifications/AntispamTab/AntispamTabMain";
import NotificationTabMain from '../../../Widgets/Notifications/NotificationsTab/NotificationTabMain';
import NewsTabMain from '../../../Widgets/Notifications/NewsTabMain/NewsTabMain';

const NotificationIndex = () => {
  return (
    <ContentContainer title="Уведомления">
      <Tabs items={[
        { title: 'Рассылки', tabIndex: 0 },
        { title: 'Шаблоны уведомлений', tabIndex: 1 },
        { title: 'Уведомления', tabIndex: 2 },
        { title: 'Антиспам', tabIndex: 3 },
        { title: 'Архив рассылок', tabIndex: 4 },
      ]} />

      <TabPanel index={0} width={100}>
        <NewsTabMain active />
      </TabPanel>
      <TabPanel index={1} width={100}>
        <TemplateTabMain />
      </TabPanel>
      <TabPanel index={2} width={100}>
        <NotificationTabMain />
      </TabPanel>
      <TabPanel index={3} width={100}>
        <AntispamTabMain />
      </TabPanel>
      <TabPanel index={4} width={100}>
        <NewsTabMain active={false} />
      </TabPanel>
    </ContentContainer>
  );
};

export default NotificationIndex;