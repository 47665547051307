import TabTitle from '../../../../UI/Tab/TabTitle';
import {Box} from '@mui/material';
import {IUserListItemPaginated} from '../../../../types/app/auth/UserListItem';
import {useEffect, useState} from 'react';
import TabLoader from '../../Loader/TabLoader';
import {useTweakTool} from '../../../../hooks/table/useTweakTool';
import {IRole} from '../../../../types/app/auth/Role';
import {AuthService} from '../../../../http/auth/AuthService';
import {getUserValueByType, UserTypes} from '../../../../enums/UserTypes';
import AccountTabFilters from './AccountTabFilters';
import AccountTabDataTweaks from './AccountTabDataTweaks';
import AccountTabContentData from './AccountTabContentData';
import Divider from '../../../../UI/Delimiter/Divider';

interface IUsersTabContentMainProps {
  type: UserTypes.PARTNER | UserTypes.OPERATOR;
}

export const defaultFilterState = {
  like: {
    name: '',
    email: '',
  },
  eq: {
    roleId: '',
    typeId: '',
  },
};

const filterRolesByType = (roles: IRole[], type: UserTypes) => {
  if (type === UserTypes.OPERATOR) {
    return roles;
  }

  return roles.filter(role => role.types.includes(getUserValueByType(type)));
};

const rolesToMap = (roles: IRole[]) => {
  const result = new Map<number, IRole>();

  roles.forEach(role => result.set(role.id, role));

  return result;
};

const AccountTabContentMain = (props: IUsersTabContentMainProps) => {
  const { items, getPaginatable, getFilterable, getDialogFilterable, setFilters, setServerMethod, setMutator} = useTweakTool<IUserListItemPaginated>();
  const [roles, setRoles] = useState<IRole[]>(null);

  useEffect(() => {
    setFilters(defaultFilterState);

    setMutator(params => {
      params.type_id = getUserValueByType(props.type)

      return params
    })

    AuthService.getRoles().then(response => setRoles(response))

    setServerMethod(AuthService.getUsersPaginated)

  }, []);

  if (!items || !roles) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="Учетные записи" />

      <AccountTabFilters {...getDialogFilterable} roles={filterRolesByType(roles, props.type)} />

      <AccountTabDataTweaks {...getFilterable} type={props.type} />

      <Divider />

      <AccountTabContentData
        type={props.type}
        roleMap={rolesToMap(roles)}
        {...getPaginatable}
      />
    </Box>
  );
};

export default AccountTabContentMain;
