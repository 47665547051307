import React from 'react';
import PromoCommon from './PromoCommon';
import { useAsyncValue } from 'react-router-dom';
import { IPromoCreateLoader } from '../../../../../http/billing/BillingLoaders';

const PromoCreateForm = () => {
  const content = useAsyncValue() as IPromoCreateLoader;

  return <PromoCommon {...content} />;
};

export default PromoCreateForm;
