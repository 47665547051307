import { INews } from '../types/app/communication/News';
import { IGroupsTemplate } from '../types/app/communication/GroupsTemplate';
import { UserTypes } from '../enums/UserTypes';

export const isHasGroups = (newsItem: INews) => {
  let result = false;

  newsItem.recipients.forEach(recipient => {
    if (recipient.groupId !== null) {
      result = true;

      return;
    }
  });

  return result;
};

export const isHasProfiles = (newsItem: INews) => {
  let result = false;

  newsItem.recipients.forEach(recipient => {
    if (recipient.profileId !== null) {
      result = true;

      return;
    }
  });

  return result;
};

export const getProfileIds = (newsItem: INews) => {
  const ids = [];

  newsItem.recipients.forEach(recipient => {
    if (recipient.profileId !== null) {
      ids.push(recipient.profileId);
    }
  });

  return ids;
};

export const getGroupIds = (newsItem: INews) => {
  const ids = [];

  newsItem.recipients.forEach(recipient => {
    if (recipient.groupId !== null) {
      ids.push(recipient.groupId);
    }
  });

  return ids;
};

export const getNewsItemType = (newsItem: INews, groups: IGroupsTemplate[]): string => {
  const profiles = getProfileIds(newsItem);

  if (profiles.length) {
    return 'Выбранным Пользователям';
  }

  const types: string[] = [];

  newsItem.recipients.forEach(recipient => {
    if (recipient.groupId !== null) {
      const group = groups.find(group => group.id === recipient.groupId);

      if (group) {
        switch (group.name) {
          case UserTypes.PARTNER:
            types.push('Всем Партнерам');

            break;
          case UserTypes.OPERATOR:
            types.push('Всем Операторам');

            break;
          default:
            types.push('Всем Пользователям');
        }
      }
    }
  });

  return types.join(', ');
};
