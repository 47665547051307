import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const DEFAULT_ROWS = [10, 25, 50, 75, 100];
const DEFAULT_PER_PAGE = DEFAULT_ROWS[0];

interface ITweakSliceProps {
  perPage: number;
  page: number;
  rows: number[];
}

const initialState: ITweakSliceProps = {
  rows: DEFAULT_ROWS,
  perPage: DEFAULT_PER_PAGE,
  page: 1,
};

export const tweakToolsSettingSlice = createSlice({
  name: 'handlerSlice',
  initialState,
  reducers: {
    resetTweakPerPage(state: ITweakSliceProps) {
      state.perPage = DEFAULT_PER_PAGE;
    },
    setTweakRows(state: ITweakSliceProps, action: PayloadAction<number[]>) {
      state.rows = action.payload;
    },
    resetTweakRows(state: ITweakSliceProps) {
      state.rows = DEFAULT_ROWS;
    },
    setTweakPage(state: ITweakSliceProps, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setTweakPerPage(state: ITweakSliceProps, action: PayloadAction<number>) {
      state.perPage = action.payload;
    },
  },
});

export default tweakToolsSettingSlice.reducer;
