import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import TabLoader from '../../Common/Loader/TabLoader';
import { defaultFilterState } from '../CatalogTab/CatalogTabContentMain';
import CategoriesTabDataTweaks from './CategoriesTabDataTweaks';
import CategoriesTabContentData from './CategoriesTabContentData';
import { ICategory } from '../../../types/app/catalog/Category';
import {CatalogService} from '../../../http/catalog/CatalogService';
import Divider from '../../../UI/Delimiter/Divider';

const CategoriesTabContentMain = () => {
  const { getPaginatable, items, setItems, setFilters } = useTweakTool<ICategory>();

  useEffect(() => {
    setFilters(defaultFilterState);

    CatalogService
      .getCategories()
      .then(categories => setItems(categories))
  }, []);

  if (items === null) {
    return <TabLoader />;
  }

  return (
    <Box width="100%">
      <TabTitle title="Категории товаров/услуг" />

      <CategoriesTabDataTweaks />

      <CategoriesTabContentData {...getPaginatable} />
    </Box>
  );
};

export default CategoriesTabContentMain;
