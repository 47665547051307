/**
 * Form validator
 */
export class Validator {
  /**
   * Validate value is not a number
   *
   * @param value
   */
  public static isTruth(value: string): boolean {
    return value === 'true' || value === '1';
  }

  /**
   * Validate value is not a number
   *
   * @param value
   */
  public static isNaN(value: string): boolean {
    return isNaN(Number(value));
  }

  /**
   * Validate required field
   *
   * @param field
   * @param value
   */
  public static required(field: string, value: string): boolean {
    return !!value;
  }

  /**
   * Validate email field
   *
   * @param field
   * @param value
   */
  public static email(field: string, value: string): boolean {
    const exp = "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])";

    return new RegExp(exp).test(value);
  }

  /**
   * Validate field by regexp
   *
   * @param field
   * @param value
   * @param exp
   */
  public static regex(field: string, value: string, exp: string): boolean {
    return new RegExp(exp).test(value);
  }

  /**
   * The field corresponds to another
   *
   * @param field
   * @param value
   * @param sameAs
   */
  public static same(field: string, value: string, sameAs: string): boolean {
    return sameAs === value;
  }

  /**
   * The field's length is eq min number
   *
   * @param field
   * @param value
   * @param min
   */
  public static min(field: string, value: string, min: number): boolean {
    return value.length >= min;
  }

  /**
   * The field's value greater than number
   *
   * @param field
   * @param value
   * @param number
   */
  public static greaterThan(field: string, value: string | number, number: number): boolean {
    return Number(value) > number;
  }

  /**
   * The field's value less than number
   *
   * @param field
   * @param value
   * @param number
   */
  public static lessThan(field: string, value: string, number: number): boolean {
    return Number(value) <= number;
  }

  /**
   * The field's length is eq max number
   *
   * @param field
   * @param value
   * @param min
   */
  public static max(field: string, value: string, min: number): boolean {
    return value.length <= min;
  }

  /**
   * The field has min 1 digit
   *
   * @param field
   * @param value
   */
  public static digits(field: string, value: string): boolean {
    return /[0-9]+/.test(value);
  }

  /**
   * The field has 1 or more spec character
   *
   * @param field
   * @param value
   */
  public static characters(field: string, value: string): boolean {
    return /[!$#%]+/.test(value);
  }

  /**
   * The field has 1 or more lowercase letter
   *
   * @param field
   * @param value
   */
  public static lowercase(field: string, value: string): boolean {
    return /[a-zа-я]+/.test(value);
  }

  /**
   * The field has 1 or more uppercase letter
   *
   * @param field
   * @param value
   */
  public static uppercase(field: string, value: string): boolean {
    return /[A-ZА-Я]+/.test(value);
  }

  /**
   * the field contain only latin characters
   *
   * @param field
   * @param value
   */
  public static latinonly(field: string, value: string): boolean {
    return /^(?!.*[А-Яа-яЁё]).*$/.test(value);
  }
}
