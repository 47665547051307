import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import OperationHistoryTabContentData from './OperationHistoryTabContentData';
import {AccountService} from '../../../http/account/AccountService';
import {useTweakTool} from '../../../hooks/table/useTweakTool';
import {ITransactionWithPagination} from '../../../types/app/account/Transaction';
import TabLoader from '../../Common/Loader/TabLoader';
import OperationHistoryTabDataTweaks from './OperationHistoryTabDataTweaks';
import OperationHistoryTabFilters from './OperationHistoryTabFilters';
import {IFilters} from '../../../store/rtk/reducers/serverPaginationSlice';
import {unix} from 'moment/moment';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilters: IFilters = {
  like: {
    userFrom: '',
    userTo: '',
    transactionId: '',
  },
  from: {
    amount: null,
    createdDate: null,
    createdTime: null,
  },
  to: {
    amount: null,
    createdDate: null,
    createdTime: null,
  },
  eq: {
    typeId: null,
  },
};

const OperationHistoryTabContentMain = () => {
  const { setFilters, items, getPaginatable, getFilterable, getDialogFilterable, setServerMethod, setMutator } = useTweakTool<ITransactionWithPagination>();

  useEffect(() => {
    setMutator(params => {
      if (params.created_date_end) {
        params.created_date_end = unix(params.created_date_end).format('YYYY-MM-DD');
      }

      if (params.created_date_start) {
        params.created_date_start = unix(params.created_date_start).format('YYYY-MM-DD');
      }

      if (params.created_time_end) {
        params.created_time_end = unix(params.created_time_end).format('HH:mm');
      }

      if (params.created_time_start) {
        params.created_time_start = unix(params.created_time_start).format('HH:mm');
      }

      return params;
    });

    setServerMethod(AccountService.getTransactionsPaginated);

    setFilters(defaultFilters);
  }, []);

  if (!items) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="История операций" />

      <OperationHistoryTabFilters {...getDialogFilterable} />

      <OperationHistoryTabDataTweaks {...getFilterable} />

      <Divider />

      <OperationHistoryTabContentData {...getPaginatable} />
    </Box>
  );
};

export default OperationHistoryTabContentMain;
