import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import { useUrlManager } from '../../hooks/useUrlManager';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  width?: number;
  minheight?: number;
}

function TabPanel(props: TabPanelProps) {
  const { tabIndex } = useUrlManager();

  const width = props.width ?? '80';
  const minHeight = props.minheight ?? '1000px';

  return (
    <Card
      sx={{
        width: width + '%',
        minHeight: minHeight,
        pb: 3,
        position: 'relative',
        padding: '20px 40px',
      }}
      hidden={tabIndex !== props.index}
      {...props}
    >
      <CardContent>{tabIndex === props.index && props.children}</CardContent>
    </Card>
  );
}

export default TabPanel;
