import {ISideBarItem} from './SideBarItem';
import {ReactComponent as OrdersIcon} from '../../../icons/bar/orders.svg';
import {ReactComponent as DashboardIcon} from '../../../icons/bar/dashboard.svg';
import {ReactComponent as RequisitesIcon} from '../../../icons/bar/requisites.svg';
import {ReactComponent as AccountIcon} from '../../../icons/bar/account.svg';
import {ReactComponent as UsersIcon} from '../../../icons/bar/users.svg';
import {ReactComponent as CoreIcon} from '../../../icons/bar/core.svg';
import {ReactComponent as BankingIcon} from '../../../icons/bar/banking.svg';
import {ReactComponent as NotificationIcon} from '../../../icons/bar/notification.svg';
import {ReactComponent as CartIcon} from '../../../icons/bar/cart.svg';
import {ReactComponent as FraudMonitoringIcon} from '../../../icons/bar/fraudMonitoring.svg';
import {ReactComponent as ReportsIcon} from '../../../icons/bar/reports.svg';
import {ReactComponent as Certificates} from '../../../icons/bar/certificate.svg';
import {RouteList} from '../../../http/RouteList';

export const SideBarMap: ISideBarItem[] = [
  { Icon: <DashboardIcon />, title: 'Дашборд', path: RouteList.DASHBOARD },
  { Icon: <RequisitesIcon />, title: 'Реквизиты', path: RouteList.REQUISITES },
  {
    Icon: <AccountIcon />,
    title: 'Учетные записи',
    path: RouteList.USERS,
    childrenPaths: [RouteList.USERS_SHOW, RouteList.USERS_CREATE, RouteList.USERS_ROLE_CREATE, RouteList.USERS_ROLE_SHOW],
  },
  {
    Icon: <Certificates />,
    title: 'Сертификаты',
    path: RouteList.CERTIFICATES,
    childrenPaths: [RouteList.SHOW_CERTIFICATE, RouteList.CREATE_CERTIFICATE],
  },
  {
    Icon: <UsersIcon />,
    title: 'Пользователи',
    path: RouteList.CLIENTS,
    childrenPaths: [RouteList.CLIENTS_SHOW, RouteList.CLIENTS_CREATE, RouteList.CLIENTS_RANK_SHOW, RouteList.CLIENTS_RANK_CREATE],
  },
  {
    Icon: <OrdersIcon />,
    title: 'Заказы',
    path: RouteList.ORDERS,
    childrenPaths: [RouteList.ORDERS_SHOW],
  },
  {
    Icon: <CartIcon />,
    title: 'Витрина',
    path: RouteList.CATALOG,
    childrenPaths: [RouteList.CATALOG_SHOW, RouteList.CATALOG_CATEGORY_CREATE, RouteList.CATALOG_CATEGORY_SHOW],
  },
  {
    Icon: <CoreIcon />,
    title: <>Ядро&nbsp;лояльности</>,
    path: RouteList.BILLING,
    childrenPaths: [
      RouteList.BILLING_CREATE_TARIFF,
      RouteList.BILLING_SHOW_TARIFF,
      RouteList.BILLING_CREATE_PROMO,
      RouteList.BILLING_SHOW_PROMO,
    ],
  },
  {
    Icon: <BankingIcon />,
    title: 'Счета',
    path: RouteList.BANKING,
    childrenPaths: [RouteList.BANKING_USER_SHOW],
  },
  {
    Icon: <ReportsIcon />,
    title: 'Отчеты',
    path: RouteList.REPORTS,
    childrenPaths: [
      RouteList.REPORTS_SHOW,
      RouteList.REPORTS_RULES_SHOW,
      RouteList.REPORTS_RULES_CREATE,
      RouteList.REPORTS_CREATE,
    ],
  },
  {
    Icon: <NotificationIcon />,
    title: 'Уведомления',
    path: RouteList.NOTIFICATIONS,
    childrenPaths: [
      RouteList.NOTIFICATIONS_TEMPLATE_CREATE,
      RouteList.NOTIFICATIONS_TEMPLATE_SHOW,
      RouteList.NOTIFICATIONS_ANTISPAM_CREATE,
      RouteList.NOTIFICATIONS_ANTISPAM_SHOW,
      RouteList.NOTIFICATIONS_NEWS_CREATE,
      RouteList.NOTIFICATIONS_NEWS_SHOW,
    ],
  },
  {
    Icon: <FraudMonitoringIcon />,
    title: <>Фрод&nbsp;мониторинг</>,
    path: RouteList.FRAUD_MONITORING,
    childrenPaths: [
      RouteList.FRAUD_MONITORING_REPORT_SHOW,
      RouteList.FRAUD_MONITORING_RULE_SETTING_SHOW,
      RouteList.FRAUD_MONITORING_RULE_SETTING_CREATE,
    ],
  },
];

export default SideBarMap;
