import React, { useState } from 'react';
import { IPaginatable } from '../../../../types/app/common/Paginatable';
import { IRole } from '../../../../types/app/auth/Role';
import TableContainer from '../../../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ShowButton from '../../../../UI/Button/ShowButton';
import { getRoleColor, UserRoleTypes } from '../../../../enums/SystemUserRoles';
import DeleteButton from '../../../../UI/Button/DeleteButton';
import { Grid } from '@mui/material';
import Confirm from '../../Modal/Confirm';
import { AuthService } from '../../../../http/auth/AuthService';
import { UserTypes } from '../../../../enums/UserTypes';
import { RouteList } from '../../../../http/RouteList';
import { useNotifications } from '../../../../hooks/useNotifications';

interface IRolesTabContentDataProps extends IPaginatable<IRole> {
  dispatch: Function;
  type: UserTypes;
}

const RolesTabContentData = (props: IRolesTabContentDataProps) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [roleToDelete, setRoleToDelete] = useState<IRole>(null);

  const message = useNotifications();

  const deleteRole = async () => {
    setIsLoading(true);

    try {
      await AuthService.deleteRole({ id_or_name: roleToDelete.id.toString() });
      message.success('Роль была успешно удалена.');

      props.dispatch(props.items.filter(item => item.id !== roleToDelete.id));

      closeConfirm();
    } catch (e) {
      message.error(e.message);
      closeConfirm();
    }
    
    setIsLoading(false);
  };

  const closeConfirm = () => {
    setRoleToDelete(null);
    setOpenConfirm(false);
  };

  const showConfirm = (role: IRole) => {
    setOpenConfirm(true);
    setRoleToDelete(role);
  };

  const rolesWithFilteredSections: IRole[] = props.items?.map(role => {
    return {
      ...role,
      sections: role.sections?.filter(section => section.id !== 31)
    };
  });

return (
    <TableContainer>
      <Confirm
        open={openConfirm}
        loading={isLoading}
        submit={deleteRole}
        close={closeConfirm}
        text="Вы действительно хотите удалить роль ? Данное действие нельзя будет отменить"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={250}>Наименование</TableCell>
            <TableCell width={230}>Тип</TableCell>
            <TableCell width={600}>Перечень разделов</TableCell>
            <TableCell width={100}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rolesWithFilteredSections?.map(role => (
            <TableRow key={role.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell sx={{ wordBreak: 'break-word' }}>{role.name}</TableCell>
              <TableCell
                sx={{
                  color: getRoleColor(role),
                }}
              >
                {role.type === UserRoleTypes.SYSTEM ? 'Системный' : 'Пользовательский'}
              </TableCell>
              <TableCell sx={{ wordBreak: 'break-word' }}>
                {role.sections.map(section => section.description).join(', ')}
              </TableCell>
              <TableCell>
                <Grid container flexDirection="row">
                  <Grid item xs>
                    <ShowButton
                      linkProps={{
                        to:
                          props.type === UserTypes.PARTNER
                            ? RouteList.PARTNERS_ROLE_SHOW + '/' + role.id
                            : RouteList.USERS_ROLE_SHOW + '/' + role.id,
                      }}
                    />
                  </Grid>
                  {role.type !== UserRoleTypes.SYSTEM && (
                    <Grid item xs>
                      <DeleteButton onClick={() => showConfirm(role)} />
                    </Grid>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RolesTabContentData;
