import { FormDataHelper } from '../../helpers/FormDataHelper';
import { IFormResponse } from '../../store/context/form/types/FormResponse';
import { AccountService } from './AccountService';
import { ServerObjectNames } from '../../enums/ServerObjectNames';
import {IPropertyRequest} from '../../types/request/account/PropertyRequest';
import {AccountProperties} from '../../enums/AccountProperties';
import { IChangeCertificateUser } from '../../types/request/account/ChangeCertificateUser';
import {Switchable} from '../../types/app/common/Switchable';
import {AuthService} from '../auth/AuthService';
import {messages} from '../../notifications/messages';
import {ICreateCertificateRequest} from '../../types/request/certificates/CreateCertificateRequest';
import {CertificateTypeCodes} from '../../enums/CertificateTypes';
import {IProduct} from '../../types/app/catalog/Product';
import {ICategory} from '../../types/app/catalog/Category';

export class AccountActions {
  /**
   * Set property form action
   *
   * @param request
   */
  public static setPropertyRouteAction = async ({ request }): Promise<IFormResponse> => {
    const formData: FormData = await request.formData();
    const requisites = FormDataHelper.toObject<{
      operator_name: string,
      legal_address: string,
      inn: string,
      kpp: string,
      bank_name: string,
      bank_bik: string,
      bank_account: string,
      bank_operator_account: string,
    }>(formData);

    requisites.inn = requisites.inn.replace(/[_-]/g, '');
    requisites.kpp = requisites.kpp.replace(/[_-]/g, '');
    requisites.bank_bik = requisites.bank_bik.replace(/[_-]/g, '');

    requisites.bank_account = requisites.bank_account.replace(/[._]/g, '');
    requisites.bank_operator_account = requisites.bank_operator_account.replace(/[._]/g, '');

    const propertyRequest: IPropertyRequest = {
      property: AccountProperties.REQUISITES,
      obj_name: ServerObjectNames.COMMON,
      value: JSON.stringify(requisites),
    };

    try {
      await AccountService.setProperty(propertyRequest);

      return { status: true };
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  /**
   * Change certificate user
   *
   * @param request
   */

  public static certificateChangeUser = async ({request}): Promise<IFormResponse> => {
    const formData = FormDataHelper.toObject<{id_or_code: string, new_user_id: string}>(await request.formData())    

    const changeRequest: IChangeCertificateUser = {
      id_or_code: formData.id_or_code,
      new_user_id: +formData.new_user_id
    }

    try {
      await AccountService.certificateChangeUser(changeRequest);

      return { status: true };
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  }

  public static async createCertificatesAction({request}): Promise<IFormResponse> {
    const formData = FormDataHelper.toObject<{
      user_email: string,
      type: string,
      amount: string,
      categories: string,
      expires_date: string,
      is_mix: Switchable,
      is_part: Switchable,
      products: string,
      productData: string,
      categoryData: string,
    }>(await request.formData());

    const createRequest: ICreateCertificateRequest = {
      expires_date: formData.expires_date && formData.expires_date ,
      quantity: 1,
      is_mix: formData.is_mix === Switchable.ON,
      is_part: formData.is_part === Switchable.ON,
      type: +formData.type as CertificateTypeCodes,
    }

    if (formData.user_email) {
      createRequest.user_email = formData.user_email ?? null;

      try {
        const user = await AuthService.getUserByEmail({email: createRequest.user_email});

        if (user.internalId === null) {
          return {
            status: false,
            error: messages.errors.createCertificateError,
          }
        }
      }
      catch (e) {
        return {
          status: false,
          error: e.message,
        }
      }
    }

    if (formData.products) {
      const decodedProductData = JSON.parse(formData.productData);
      const productMap = new Map<number, IProduct>();


      for (const productIndex in decodedProductData) {
        productMap.set(+decodedProductData[productIndex].id, decodedProductData[productIndex].name);
      }

      createRequest.products = {};

      formData.products.split(',').forEach(productId => {
          createRequest.products[productId] = productMap.get(+productId)
        }
      )
    }

    if (formData.categories) {
      const decodedCategoryData = JSON.parse(formData.categoryData);
      const categoryMap = new Map<number, ICategory>();

      for (const categoryIndex in decodedCategoryData) {
        categoryMap.set(+decodedCategoryData[categoryIndex].key, decodedCategoryData[categoryIndex].label)
      }

      createRequest.categories = {};

      formData.categories.split(',').forEach(categoryId => {
          createRequest.categories[categoryId] = categoryMap.get(+categoryId)
        }
      )
    }

    createRequest.amount = +formData.amount ?? null;

    try {
      const createCertificateRequest = await AccountService.createCertificate(createRequest)

      if (createCertificateRequest.status) {
        return {
          status: true,
          payload: createCertificateRequest.payload,
        }
      } else {
        return {
          status: false,
          error: messages.errors.generalError,
        }
    }
    } catch (e) {
      return {
        status: false,
        error: messages.errors.generalError,
      };
    }
  }
}