import React from 'react';
import FilterInput from '../../Filter/FilterInput';
import FilterDialog from '../../Filter/FilterDialog';
import {IRole} from '../../../../types/app/auth/Role';
import Divider from '../../../../UI/Delimiter/Divider';
import FilterSelect from '../../Filter/FilterSelect';
import {IDialogFilterable} from '../../../../types/app/common/DialogFilterable';
import {defaultFilterState} from './AccountTabContentMain';

interface PartnerTabFiltersProps extends IDialogFilterable<typeof defaultFilterState>{
  roles: IRole[];
}

const AccountTabFilters = (props: PartnerTabFiltersProps) => {
  return (
    <FilterDialog
      close={props.closeFilters}
      open={props.isFiltersOpen}
      apply={props.applyFilters}
    >
      <FilterInput
        label="ФИО сотрудника"
        addFilter={props.addFilter}
        field="name"
        filters={props.filters}
        placeholder="Укажите ФИО Пользователя"
      />

      <Divider />

      <FilterInput
        label="Email сотрудника"
        addFilter={props.addFilter}
        field="email"
        filters={props.filters}
        placeholder="Укажите Email Пользователя"
      />

      <Divider />

      <FilterSelect
        label="Роль"
        filters={props.filters}
        addFilter={props.addFilter}
        items={props.roles.map(role => ({key: role.id, label: role.name}))}
        field="roleId"
        placeholder="Выберите роль"
      />
    </FilterDialog>
  );
};

export default AccountTabFilters;
