import './assets/styles/style.scss';
import React from 'react';
import { Grid } from '@mui/material';
import 'moment/locale/ru';
import SideBar from '../Widgets/Layout/Sidebar/SideBar';
import ContentRouter from '../Widgets/Layout/Content/ContentRouter';
import { useAppSelector } from '../hooks/hooks';
import PageLoader from '../Widgets/Common/Loader/PageLoader';

const DashboardLayout = () => {
  const user = useAppSelector(state => state.auth.user);

  if (!user) {
    return <PageLoader />;
  }

  return (
    <Grid minHeight="100vh" container columns={12}>
      <SideBar />
      <Grid item xs overflow="auto" ml="312px" position="relative">
        <ContentRouter />
      </Grid>
    </Grid>
  );
};

export default DashboardLayout;
