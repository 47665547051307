import React, { Dispatch, useEffect } from 'react';
import { Box } from '@mui/material';
import FormOutlinedInput from '../../../../../../UI/Form/FormOutlinedInput';
import { FraudSettingConditionNameEnum, FraudSettingConditionNames } from '../../../../../../enums/FraudRules';
import Divider from '../../../../../../UI/Delimiter/Divider';
import { IRuleCondition } from '../../../../../../types/app/fraud/RuleCondition';
import { IFraudSettingCreateDataset } from '../../FraudMonitoringRuleSettingCreateForm';

interface IFraudCreateFormStepConditionsProps {
  dataset: IFraudSettingCreateDataset;
  dispatchDataset: Dispatch<IFraudSettingCreateDataset>;
}

const FraudCreateFormStepConditions = (props: IFraudCreateFormStepConditionsProps) => {
  useEffect(() => {
    const newDataset = {...props.dataset};

    newDataset.conditionList = {};

    props.dataset.rule.conditions.forEach(condition => {
      newDataset.conditionList[condition.name] = 1;
    });

    props.dispatchDataset(newDataset);
  }, []);

  const getRulesByCondition = (value: number, condition: IRuleCondition): boolean => {
    const isNumber = !isNaN(value) ;

    if (condition.name.includes('period')) {
      return isNumber && value <= 100;
    }

    return isNumber;
  };

  return (
    <Box>
      {props.dataset.rule.conditions.map((condition, index) => (
        <Box key={condition.id}>
          <FormOutlinedInput
            rule={e => getRulesByCondition(+e.target.value, condition)}
            title={FraudSettingConditionNames.toView(condition.name as FraudSettingConditionNameEnum)}
            inputProps={{
              name: condition.name,
              value: props.dataset.conditionList[condition.name] ?? '',
              onChange: e => {
                if (e.target.value === '' || +e.target.value === 0) {
                  props.dispatchDataset({
                    ...props.dataset, conditionList: {
                      ...props.dataset.conditionList, [condition.name]: '',
                    }
                  });

                  return;
                }

                if (e.target.value.startsWith('0') && e.target.value.length > 1) {
                  props.dispatchDataset({
                    ...props.dataset, conditionList: {
                      ...props.dataset.conditionList, [condition.name]: +e.target.value.substring(1),
                    }
                  });

                  return;
                }

                props.dispatchDataset({
                  ...props.dataset, conditionList: {
                    ...props.dataset.conditionList, [condition.name]: +e.target.value,
                  }
                });
              },
            }}
          />

          {index !== props.dataset.rule.conditions.length - 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
};

export default FraudCreateFormStepConditions;
