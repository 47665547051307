import React, {Dispatch, useContext, useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {CertificateCategories} from '../../../../enums/CertificateCategories';
import {CertificateTypeCodes} from '../../../../enums/CertificateTypes';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import Divider from '../../../../UI/Delimiter/Divider';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import FormDatePicker from '../../../../UI/Pickers/FormDatePicker';
import FormSwitch from '../../../../UI/Form/FormSwitch';
import {IPromo} from '../../../../types/app/billing/bonuses/Promo';
import {FormContext} from '../../../../store/context/form/FormContext';
import {keys} from 'lodash';

interface IPromoCertificateProps {
  certificateType: CertificateTypeCodes;
  certificateCategory: CertificateCategories;
  dispatchCertificateCategory: Dispatch<CertificateCategories>;
  dispatchCertificateType: Dispatch<CertificateTypeCodes>;
  promo: IPromo | null;
  partnerId?: number | null;
  isIssueCertificate?: boolean;
  setSelectedPartnerProductId?: (id: number) => void;
  partnerProductId?: number;
  fetchPartnerProducts?: () => void;
  partnerProductsItems?: any;
  isLoading?: boolean;
  selectedPartnerProductId?: number;
  partnerProductsItemsMap?: Map<number, string>;
}

const PromoCertificate = (props: IPromoCertificateProps) => {
  const [quantity, setQuantity] = useState<number>(1);
  const {errors, config} = useContext(FormContext);

  useEffect(() => {
    config.readonly && props.fetchPartnerProducts();

    props.partnerId && props.isIssueCertificate && props.fetchPartnerProducts();

  }, [props.partnerId, props.isIssueCertificate, config]);

  return (
    <Box bgcolor="#F9FCFF" p={5} mt={3} mb={3} borderRadius={4}>
      <Typography variant="subtitle2">Настройка механики: выдача сертификата Пользователю</Typography>

      <Divider/>

      <FormSelect
        title="Тип сертификата"
        items={CertificateCategories.asSelectable()}
        selectProps={{
          sx: {
            width: '50%',
          },
          value: props.certificateCategory,
          onChange: e => props.dispatchCertificateCategory(e.target.value as CertificateCategories),
        }}
      />

      <Divider/>

      {props.certificateCategory === CertificateCategories.USER && (
        <Box>
          <FormOutlinedInput
            title="Email Пользователя"
            inputProps={{
              sx: {
                width: '50%',
              },
              name: 'user_email',
              defaultValue: props.promo?.certificateParams?.userEmail ?? '',
            }}
          />

          <Divider/>
        </Box>
      )}

      <FormSelect
        title="Вид сертификата"
        items={CertificateTypeCodes.asSelectable()}
        selectProps={{
          sx: {
            width: '50%',
          },
          name: 'certificate_type',
          value: props.certificateType,
          onChange: e => props.dispatchCertificateType(e.target.value as CertificateTypeCodes),
        }}
      />

      <Divider/>

      {props.certificateType === CertificateTypeCodes.PRODUCT &&
          <Box>
            <FormSelect
                title="Товар, к которому можно применить сертификат"
                items={props.partnerProductsItems ? props.partnerProductsItems : []}
                selectProps={{
                  error: !!errors['productId_for_certificate'],
                  disabled: props.isLoading,
                  name: 'productId_for_certificate',
                  value: props.partnerProductId,
                  defaultValue: Number(keys(props.promo?.certificateParams.products)[0] ?? ''),
                  onChange: (e) => props.setSelectedPartnerProductId(e.target.value as number)
                }}
            />
            <input
                type="hidden"
                name="product_name_for_certificate"
                value={props.partnerProductsItemsMap ? props.partnerProductsItemsMap.get(
                  props.selectedPartnerProductId) : ''}
            />

            <Divider/>
          </Box>
      }

      {props.certificateType === CertificateTypeCodes.NOMINAL && (
        <Box>
          <FormOutlinedInput
            title="Номинальная сумма сертификата (только цифры)"
            inputProps={{
              sx: {
                width: '50%',
              },
              name: 'amount',
              defaultValue: props.promo?.certificateParams?.amount ?? '',
            }}
            rule={e => !isNaN(Number(e.target.value))}
          />

          <Divider/>
        </Box>
      )}

      <Box width="50%">
        <FormDatePicker
          title="Срок годности"
          fieldProps={{
            name: 'expires_date',
            value: props.promo?.certificateParams?.expiresDate ?? '',
          }}
        />
      </Box>

      <Divider/>

      <FormOutlinedInput
        title="Количество сертификатов (только цифры, не меньше 1)"
        inputProps={{
          sx: {
            width: '25%',
          },
          name: 'quantity',
          value: quantity,
          onChange: e => setQuantity(+e.target.value),
        }}
        rule={e => {
          const value = Number(e.target.value);

          return !isNaN(value) && value >= 1;
        }}
      />

      <Divider/>

      <FormSwitch
        title="Возможность доплаты по товару/услуге баллам"
        switchProps={{
          name: 'is_mix',
          defaultChecked: props.promo?.certificateParams?.isMix ?? false,
        }}
      />

      <Divider/>

      <FormSwitch
        title="Возможность частичного использования сертификата"
        switchProps={{
          name: 'is_part',
          defaultChecked: props.promo?.certificateParams?.isPart ?? false,
        }}
      />
    </Box>
  );
};

export default PromoCertificate;
