import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { RouteList } from '../../../http/RouteList';
import { IRuleReport } from '../../../types/app/fraud/RuleReport';
import ReferralFraudShowCard from '../../../Widgets/FraudMonitoring/RuleReportTab/Operations/ReferralFraudShowCard';
import BigTransactionFraudShowCard from '../../../Widgets/FraudMonitoring/RuleReportTab/Operations/BigTransactionFraudShowCard';
import SalesFraudShowCard from '../../../Widgets/FraudMonitoring/RuleReportTab/Operations/SalesFraudShowCard';
import {FraudRuleReportIds} from '../../../enums/FraudRules';
import MoneyBackFraudShowCard from '../../../Widgets/FraudMonitoring/RuleReportTab/Operations/MoneyBackFraudShowCard';

const FraudMonitoringReportShow = () => {
  const { state } = useLocation();

  const report: IRuleReport = state;

  if (!report) {
    return <Navigate to={RouteList.FRAUD_MONITORING} />;
  }

  switch (report.ruleSetting.rule.id) {
    case FraudRuleReportIds.MONEY_BACK_FRAUD:
      return <MoneyBackFraudShowCard {...report} />;

    case FraudRuleReportIds.REFERRAL_FRAUD:
      return <ReferralFraudShowCard {...report} />;

    case FraudRuleReportIds.BIG_TRANSACTION_FRAUD:
      return <BigTransactionFraudShowCard {...report} />;

    default:
      return <SalesFraudShowCard {...report} />;
  }
};

export default FraudMonitoringReportShow;
