import React, {Ref} from 'react';
import {SelectProps} from '@mui/material';
import {ISelectable} from '../../../types/app/common/Selectable';
import {Variant} from '@mui/material/styles/createTypography';
import FormSelectMultiple from './FormSelectMultiple';
import FormSelectSingle from './FormSelectSingle';
import RemoveIcon from '@mui/icons-material/Remove';

export interface IFormSelect {
  ref: Ref<HTMLElement>,
  title?: string;
  items: ISelectable[];
  selectProps?: SelectProps | ISelectPropsMultiple;
  typography?: Variant;
  beforeOnchange?: (e) => void,
  onAfterClearAll?: () => void,
}

export interface IFormSelectSingle extends IFormSelect {
  selectProps?: SelectProps,
  singletweaks?: {
    unselect: boolean,
  },
  onBeforeClearAll?: (selectedId: string | number) => void,
}

export interface ISelectPropsMultiple extends SelectProps {
  multiple: true,
}

export interface IFormSelectMultiple extends IFormSelect {
  selectProps: ISelectPropsMultiple,
  multipletweaks?: {
    disabled?: boolean
    selectAll?: boolean;
    unselectAll?: boolean;
    selectAllItemsAction?: () => void
    removeAllItemsAction?: () => void
  },
  onBeforeClearAll?: (selectedId?: number[]) => void,
  onSelectedChange?: (selectedItems: number[]) => void,
}

export const getSelectNameByValue = (items: ISelectable[], selectValue: string | null | number): string | React.ReactNode => {
  if (selectValue === null) {
    return 'Не задано';
  }

  const item = items.find(item => String(item.key) == String(selectValue));

  if (!item) {
    return <RemoveIcon sx={{ fontSize: 20 }} />;
  }

  return item.label;
};

const FormSelect = React.forwardRef((props: IFormSelectSingle | IFormSelectMultiple, ref) => {
  if (props.selectProps && 'multiple' in props.selectProps) {
    // @ts-ignore
    return (<FormSelectMultiple {...props} ref={ref} />)
  }

  // @ts-ignore
  return (<FormSelectSingle {...props} ref={ref} />);
});

export default FormSelect;