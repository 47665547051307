import React from 'react';
import TabPanel from '../../../UI/Tab/TabPanel';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';
import CategoriesTabContentMain from '../../../Widgets/Catalog/CategoryTab/CategoriesTabContentMain';
import CatalogTabContentMain from '../../../Widgets/Catalog/CatalogTab/CatalogTabContentMain';
import Tabs from '../../../UI/Tab/Tabs';

const CatalogIndex = () => {
  return (
    <ContentContainer title="Витрина">
      <Tabs
        items={[
          { title: 'Товары/услуги', tabIndex: 0 },
          { title: 'Категории', tabIndex: 1 },
        ]}
      />

      <TabPanel index={0}>
        <CatalogTabContentMain />
      </TabPanel>

      <TabPanel index={1}>
        <CategoriesTabContentMain />
      </TabPanel>
    </ContentContainer>
  );
};

export default CatalogIndex;
