import React, {useEffect, useState} from 'react';
import {Tree, TreeNode} from 'react-organizational-chart';
import StructureReferralsNode from './StructureReferralsNode';
import {Box, Grid, LinearProgress, Typography} from '@mui/material';
import {StructureService} from '../../../http/structure/StructureService';
import {ISelectable} from '../../../types/app/common/Selectable';
import FormSelect from '../../../UI/Form/Select/FormSelect';
import {TStructure} from '../../../types/response/structure/GetPropertyResponse';

interface IStructureReferralTree {
  currentUser: { id: number, name: string };
  setChildUsers: React.Dispatch<React.SetStateAction<number[]>>;
}

const levels: ISelectable[] = [];

for (let i = 1; i <= 7; i++) {
  levels.push({key: i, label: i.toString()});
}

const StructureReferralTree = ({currentUser, setChildUsers}: IStructureReferralTree) => {
  const [sourceStructure, setSourceStructure] = useState<TStructure[]>(null);
  const [struct, setStructure] = useState<React.ReactNode>();

  const [level, setLevel] = useState<number>(7);
  const [currentFirst, setCurrentFirst] = useState<string>('');
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    if (currentUser) {
      homeHandler();
      setTimeout(() => setIsLoad(true), 1000);
    }

    return () => {
      setIsLoad(false);
    };
  }, [currentUser]);

  useEffect(() => {
    if (sourceStructure) updateStructure(level);
  }, [sourceStructure]);

  const levelHandler = (level: string) => {
    updateStructure(parseInt(level));
    setLevel(parseInt(level));
  };

  const nodeClickHandler = (user_id: number, name: string) => {
    getStructure(user_id);
    setCurrentFirst(name);
  };

  const homeHandler = () => {
    nodeClickHandler(currentUser.id, currentUser.name);
  };

  const getStructure = (userId: number) => {
    StructureService.getStructure({user_id: userId})
      .then(structure => {
        setSourceStructure(structure);
        const userExtIds = structure.map(extId => extId.user_ext_id);
        setChildUsers(userExtIds);
      });
  };

  const updateStructure = (show: number) => {
    const structure = getChart(sourceStructure, show, 0);
    setStructure(structure);
  };

  const getChart = (structure: TStructure[], show: number, level: number) => {
    level++;
    if (level > show) {
      return;
    }

    return structure.map((item: TStructure) => (
      <TreeNode
        label={
          <StructureReferralsNode
            name={item.user_name}
          />
        }
        key={item.user_ext_id}
      >
        {getChart(item.downlines, show, level)}
      </TreeNode>
    ));
  };

  if (!currentUser) {
    return (
      <Box
        sx={{minHeight: '300px'}}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          minHeight={'200px'}
        >
          <Typography variant="body2">
            Введите email Пользователя для просмотра структуры
          </Typography>
        </Box>
      </Box>
    );
  }

  if (!isLoad) {
    return (<Box sx={{minHeight: '300px'}}><LinearProgress/></Box>);
  }

  return (
    <Box sx={{ minHeight: '300px' }}>
      <Grid container justifyContent="space-between" mb={3} mt={3}>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="body2" color="grey.600">
                Уровней в дереве:
              </Typography>
            </Grid>
            <Grid item>
              <FormSelect
                selectProps={{
                  value: level,
                  sx: {
                    width: 75,
                    height: 35,
                    borderRadius: '8px',
                  },
                  onChange: e => levelHandler(e.target.value as string),
                }}
                items={levels}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ overflowX: 'auto', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ minWidth: '100%', maxWidth: 'fit-content', paddingBottom: 2 }}>
          <Tree
            lineWidth="2px"
            lineColor="#9DA5AD"
            lineBorderRadius="10px"
            label={<StructureReferralsNode name={currentFirst} />}
          >
            {struct}
          </Tree>
        </Box>
      </Box>
    </Box>
  );
};

export default StructureReferralTree;
