import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetTemplateLoader } from '../../../http/communication/CommunicationLoaders';
import NotificationTemplateShowForm from '../../../Widgets/Notifications/TemplateTab/Operations/NotificationTemplateShowForm';

const NotificationTemplateShow = () => {
  const { template } = useLoaderData() as IGetTemplateLoader;

  return (
    <Await resolve={template}>
      <NotificationTemplateShowForm />
    </Await>
  );
};

export default NotificationTemplateShow;
