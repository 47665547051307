import React from 'react';
import FilterDialog from '../Common/Filter/FilterDialog';
import {IDialogFilterable} from '../../types/app/common/DialogFilterable';
import Divider from '../../UI/Delimiter/Divider';
import FilterDateRange from '../../UI/Filter/FilterDateRange';
import moment from 'moment';
import FilterTimeRange from '../../UI/Filter/FilterTimeRange';
import FilterInputRange from '../Common/Filter/FilterInputRange';
import {defaultFilterState} from './OrdersIndex';
import {getOrderStatusesAsSelectable} from '../../enums/OrderStatuses';
import FilterMultiCheckbox from '../Common/Filter/FilterMultiCheckbox';
import {IClient} from '../../types/app/client/Client';
import {IPartner} from '../../types/app/partner/Partner';
import FilterSelectWithSearchData from '../Common/Filter/FilterSelectWithSearchData';
import {ClientService} from '../../http/client/ClientService';
import {IClientsDataRequestedByEmail} from '../../types/response/client/ClientsData';
import {GetClientByEmail} from '../../types/request/client/GetClientByEmail';
import {PartnerService} from '../../http/partner/PartnerService';
import {GetPartnerByCompanyName} from '../../types/request/partner/PartnerGetRequest';
import {IGetPartnerIdsByCompanyName} from '../../types/response/partner/GetPartnerData';

const OrdersTabFilters = (props: IDialogFilterable<typeof defaultFilterState>) => {

  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>

      <FilterDateRange
        addFilter={props.addFilter}
        field="createdDate"
        filters={props.filters}
        label="Дата"
        placeholderTo={moment().add(1, 'day').format('DD.MM.YYYY')}
        placeholderFrom={moment().format('DD.MM.YYYY')}
      />

      <Divider/>

      <FilterTimeRange
        label="Время"
        addFilter={props.addFilter}
        field="createdTime"
        filters={props.filters}
        placeholderTo={moment().add(1, 'h').format('HH:mm')}
        placeholderFrom={moment().format('HH:mm')}
      />

      <Divider/>

      <FilterSelectWithSearchData<IClient, IClientsDataRequestedByEmail>
        label="Email"
        fetchData={(request) => ClientService.getClients(request)}
        searchData={(request) => ClientService.getClientsByEmail(request as GetClientByEmail)}
        placeholder="Для начала поиска введите не менее 3 символов"
        addFilter={props.addFilter}
        filters={props.filters}
        field="clientIds"
        type='byEmail'
      />

      <Divider/>

      <FilterSelectWithSearchData<IPartner, IGetPartnerIdsByCompanyName>
        label="Наименование организации Партнера"
        fetchData={(request) => PartnerService.getPartners({filter_list: [{
                field: "id",
                operator: "IN",
                value: request.ids.toString()
            }]})}
        searchData={(request) => PartnerService.getPartnersByCompanyName(request as GetPartnerByCompanyName)}
        placeholder="Для начала поиска введите не менее 3 символов"
        addFilter={props.addFilter}
        filters={props.filters}
        field="partnerIds"
        type='byCompanyName'
      />

      <Divider/>

      <FilterInputRange
        label="Стоимость заказа"
        addFilter={props.addFilter}
        field="amount"
        filters={props.filters}
        placeholderFrom="0 ₽"
        placeholderTo="300 000 ₽"
      />

      <Divider/>

      <FilterInputRange
        label="Сумма, оплаченная по банковской карте"
        addFilter={props.addFilter}
        field="paid"
        filters={props.filters}
        placeholderFrom="0 ₽"
        placeholderTo="300 000 ₽"
      />

      <Divider/>

      <FilterMultiCheckbox
        label={'Статус'}
        field="statusId"
        items={getOrderStatusesAsSelectable()}
        addFilter={props.addFilter}
        filters={props.filters}
      />

    </FilterDialog>
  );
};

export default OrdersTabFilters;
