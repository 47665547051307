import React from 'react';
import {Alert, CircularProgress} from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

interface IReferralInformationProps {
  referrals: number | null;
  error: string | null;
}

const ReferralInformation = (props: IReferralInformationProps) => {
  if (props.error) {
    return (
      <Alert icon={<ReportGmailerrorredIcon sx={{ fontSize: 20 }} />} color="error">
        {props.error}
      </Alert>
    )
  }

  if (props.referrals === null) {
    return <CircularProgress size={20} />;
  }

  return <>{props.referrals}</>;
};

export default ReferralInformation;
