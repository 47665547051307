export class InternalError extends Error {
  message = 'Произошла ошибка удаленного сервера, данные по аккаунту не удалось получить.';
}

export class FamilyFallowError extends Error {
  message = 'Недопустимое действие для главы семейного счета.';
}

export class OperationAccountNotFoundError extends Error {
  message = 'Операцию невозможно завершить. Пользователь не найден.';
}

export class OperationInsufficientFundsError extends Error {
  message = 'Недостаточно средств на балансе Пользователя'
}

export class GeneralError extends Error {
  message = 'Ошибка, попробуйте еще раз.'
}

export class GetAllPropertiesForPartnerError extends Error {
  message = 'Ошибка при получении свойства'
}