export class TableHelper {
  /**
   * View index transform
   *
   * @param indexNumber
   * @param keyIndex
   */
  public static viewIndex(indexNumber: number, keyIndex: number): string {
    return ((indexNumber + keyIndex + 1))
      .toString()
      .replace(/\./, '')
  }
}