import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAsyncValue, useLocation, useNavigate } from 'react-router-dom';
import { ISiteSection } from '../../../../../types/app/auth/SiteSection';
import MainForm from '../../../Form/MainForm';
import FormOutlinedInput from '../../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../../UI/Delimiter/Divider';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { FormContext, IFormConfig } from '../../../../../store/context/form/FormContext';
import FormActions from '../../../../../UI/Form/FormActions';
import { RouteList } from '../../../../../http/RouteList';
import { useSnackbar } from 'notistack';
import { snackConfig } from '../../../../../layout/assets/config/snackConfig';
import { IFormResponse } from '../../../../../store/context/form/types/FormResponse';

const RoleCreateForm = () => {
  const sections = useAsyncValue() as ISiteSection[];

  const { setConfig } = useContext(FormContext);
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const route = useNavigate();

  const [selectedSectionIds, setSelectedSectionIds] = useState<number[]>(sections.map(section => section.id));

  const getData = useCallback(() => [{ field: 'sections_ids', value: selectedSectionIds.toString() }], [selectedSectionIds]);
  const isPartner = useMemo(() => pathname.includes(RouteList.PARTNERS_ROLE_CREATE), []);

  const formConfig: IFormConfig = {
    action: isPartner ? RouteList.PARTNERS_ROLE_CREATE : RouteList.USERS_ROLE_CREATE,
    rules: {
      name: ['required', 'text'],
      description: ['required'],
    },
    data: getData(),
    afterSubmitHandler: (formResponse: IFormResponse<{ roleId: number }>) => {
      enqueueSnackbar('Новая роль успешно создана.', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      const roleId = formResponse.payload.roleId;

      route(isPartner ? RouteList.PARTNERS_ROLE_SHOW + '/' + roleId : RouteList.USERS_ROLE_SHOW + '/' + roleId);
    },
  };

  const isDisabled = useCallback((section: ISiteSection) => {
    return selectedSectionIds.length === 1 && selectedSectionIds.includes(section.id)
  }, [selectedSectionIds]);


  useEffect(() => {
    setConfig(formConfig)
  }, []);

  useEffect(() => {
    setConfig({ ...formConfig, data: getData() })
  }, [selectedSectionIds]);

  const checkSectionHandler = (section: ISiteSection, isChecked: boolean): void => {
    if (isChecked) {
      setSelectedSectionIds([...selectedSectionIds, section.id]);
    } else {
      setSelectedSectionIds(selectedSectionIds.filter(sectionId => sectionId !== section.id));
    }
  };

  return (
    <MainForm back={isPartner ? RouteList.PARTNERS : RouteList.USERS}>
      <Typography variant="h1" mb={3}>
        Добавление роли
      </Typography>

      <FormOutlinedInput
        title="Наименование роли"
        inputProps={{
          name: 'name',
        }}
      />

      <Divider />

      <FormOutlinedInput
        title="Описание роли"
        inputProps={{
          name: 'description',
          multiline: true,
          minRows: 3,
        }}
      />

      <Divider />

      <Box>
        <Typography variant="body2" color="grey.600" mb={1}>
          Перечень доступных разделов личного кабинета Оператора:
        </Typography>

        <Grid container columns={3}>
          {sections.map(section => (
            <Grid key={section.id} item xs={1}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      disabled={isDisabled(section)}
                      checked={selectedSectionIds.includes(section.id)}
                      onChange={e => checkSectionHandler(section, e.target.checked)}
                    />
                  }
                  label={section.description}
                />
              </FormGroup>
            </Grid>
          ))}
        </Grid>
      </Box>

      <FormActions />
    </MainForm>
  );
};

export default RoleCreateForm;
