import moment from 'moment';
import {
  IWebNotificationsGetLastData,
  ILastNotice as ILastNoticeResponseData,
} from '../../types/response/communication/WebNotificationsGetLastData';
import { ILastNotice, INotification } from '../../types/app/common/Notification';
import { ITemplate } from '../../types/app/communication/Template';
import { TTemplateData } from '../../types/response/communication/TemplateData';
import { TAntispamData } from '../../types/response/communication/AntispamData';
import { IAntispamTemplate } from '../../types/app/communication/AntispamTemplate';
import { TGroupsData } from '../../types/response/communication/GroupsData';
import { IGroupsTemplate } from '../../types/app/communication/GroupsTemplate';
import { ICreateAntispamRequest } from '../../types/request/communication/CreateAntispamRequest';
import { IAntispamCreateTemplate } from '../../types/app/communication/AntispamCreateTemplate';
import { TNewsData } from '../../types/response/communication/NewsData';
import { INews } from '../../types/app/communication/News';
import {TFeedbackData} from '../../types/response/communication/FeedbackData';
import {IFeedback} from '../../types/app/communication/IFeedback';
import { TQuestionsData } from '../../types/response/communication/QuestionsData';
import { IQuestion } from '../../types/app/communication/IQuestion';

export class CommunicationFactory {
  public static responseToTemplate(dataResponse: TTemplateData): ITemplate {
    return {
      createdAt: dataResponse.created_at ? moment(dataResponse.created_at).unix() : null,
      defaultParams: dataResponse.default_params,
      email: dataResponse.email,
      id: dataResponse.id,
      name: dataResponse.name,
      type: dataResponse.type,
      updatedAt: dataResponse.updated_at ? moment(dataResponse.updated_at).unix() : null,
      web: dataResponse.web,
    };
  }

  public static responseToNotification(dataResponse: IWebNotificationsGetLastData): INotification {
    const notification: INotification = {
      lastNotices: dataResponse.last_notices.map(lastNotice => CommunicationFactory.getLastNotice(lastNotice)),
      unreadNoticeCount: dataResponse.unread_notice_count,
    };

    if (dataResponse.profile) {
      notification.profile = {
        id: dataResponse.profile.id,
        createdAt: moment(dataResponse.profile.created_at).unix(),
        updatedAt: moment(dataResponse.profile.updated_at).unix(),
        groupId: dataResponse.profile.group_id,
        allNotificationsAreAlreadyReadBeforeThisNotificationId:
          dataResponse.profile.all_notifications_are_already_read_before_this_notification_id,
        enableWebNotice: dataResponse.profile.enable_web_notice,
        enableEmailNotice: dataResponse.profile.enable_email_notice,
      };
    }

    return notification;
  }

  public static getLastNotice(lastNoticeData: ILastNoticeResponseData): ILastNotice {
    return {
      createdAt: lastNoticeData.created_at ? moment(lastNoticeData.created_at).unix() : null,
      data: lastNoticeData.data,
      id: lastNoticeData.id,
      read: lastNoticeData.read,
      readAt: lastNoticeData.read_at,
      templateName: lastNoticeData.template_name,
    };
  }

  public static responseToAntispam(dataResponse: TAntispamData): IAntispamTemplate {
    return {
      templateId: dataResponse.template_id,
      periodicity: dataResponse.periodicity,
      quantity: dataResponse.quantity,
      isActive: dataResponse.is_active,
      groups: dataResponse.groups,
      updatedAt: dataResponse.updated_at,
      createdAt: dataResponse.created_at,
      id: dataResponse.id,
    };
  }

  public static responseToGroupsFetch(dataResponse: TGroupsData): IGroupsTemplate {
    return {
      id: dataResponse.id,
      createdAt: dataResponse.created_at,
      updatedAt: dataResponse.updated_at,
      name: dataResponse.name,
    };
  }

  public static responseToAntispamCreate(dataResponse: ICreateAntispamRequest): IAntispamCreateTemplate {
    return {
      periodicity: dataResponse.periodicity,
      isActive: dataResponse.is_active,
      groups: dataResponse.groups,
      templateId: dataResponse.template_id,
      quantity: dataResponse.quantity,
      id: dataResponse.id,
    };
  }

  public static responseToNews(dataResponse: TNewsData): INews {
    return {
      templateId: dataResponse.template_id,
      periodicity: dataResponse.periodicity,
      quantity: dataResponse.quantity,
      params: dataResponse.params,
      isActive: dataResponse.is_active,
      startWithoutTime: dataResponse.start
        ? moment({
            month: moment(dataResponse.start).month(),
            year: moment(dataResponse.start).year(),
            day: moment(dataResponse.start).day(),
          }).unix()
        : null,
      startWithoutDate: dataResponse.start
        ? moment({
            date: moment(0).date(),
            hours: moment(dataResponse.start).hours(),
            minutes: moment(dataResponse.start).minutes(),
          }).unix()
        : null,
      start: dataResponse.start ? moment(dataResponse.start, 'YYYY-MM-DD HH:mm:ss').unix() : null,
      end: dataResponse.end ? moment(dataResponse.end, 'YYYY-MM-DD HH:mm:ss').unix() : null,
      updatedAt: dataResponse.updated_at ? moment(dataResponse.updated_at).unix() : null,
      createdAt: dataResponse.created_at ? moment(dataResponse.created_at).unix() : null,
      id: dataResponse.id,
      title: dataResponse.title,
      description: dataResponse.description,
      deliveryMethod: dataResponse.delivery_method,
      recipients: dataResponse.recipients.map(item => ({
        id: item.id,
        newsletterId: item.newsletter_id,
        profileId: item.profile_id,
        groupId: item.group_id,
      })),
      icon: dataResponse.icon,
      mailCreatedCount: dataResponse.mail_created_count,
      webCreatedCount : dataResponse.web_created_count,
      webMarkedCount: dataResponse.web_marked_count,
    };
  }

  public static responseToFeedback(feedbackMessage: TFeedbackData): IFeedback {
    return {
      id: feedbackMessage.id,
      feedback: feedbackMessage.feedback,
      createdAt: feedbackMessage.created_at,
      updatedAt: feedbackMessage.updated_at,
    }
  }

  public static responseToQuestion(question: TQuestionsData): IQuestion {
    return {
      id: question.id,
      lastName: question.lastname,
      firstName: question.firstname,
      middleName: question.middlename,
      birth: question.birth,
      phone: question.phone,
      email: question.email,
      quest: question.quest,
      answer: question.answer,
      createdAt: question.created_at,
      updatedAt: question.updated_at,
      subjectId: question.subject_id,
    }
  }
}
