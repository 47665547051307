import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Card, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { ReactComponent as UserIcon } from '../../icons/input-user.svg';
import { ReactComponent as LockIcon } from '../../icons/input-lock.svg';
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FormContext, IFormConfig } from '../../store/context/form/FormContext';
import { IFormResponse } from '../../store/context/form/types/FormResponse';
import FormOutlinedInput from '../../UI/Form/FormOutlinedInput';
import FormFullButton from '../../UI/Button/FormFullButton';
import Form from '../../UI/Form/Form';
import { useActions } from '../../hooks/hooks';
import { IAuthUser } from '../../types/app/auth/AuthUser';
import { RouteList } from '../../http/RouteList';
import { snackConfig } from '../../layout/assets/config/snackConfig';
import { useSnackbar } from 'notistack';
import {NavigationStates} from '../../enums/NavigationStates';

const Login = () => {
  const { isValid, setConfig } = useContext(FormContext);
  const navigation = useNavigation();
  const { login } = useActions();

  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const route = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const formConfig: IFormConfig = {
    rules: {
      email: ['required', 'email'],
      password: ['required'],
    },
    action: RouteList.AUTH_LOGIN,
    afterSubmitHandler: (formResponse: IFormResponse<IAuthUser>) => login(formResponse.payload),
  };

  useEffect(() => {
    if (state?.roleIsNotAvailable) {
      enqueueSnackbar('Роль не сконфигурирована. Для входа в систему обратитесь к администратору.', {
        variant: 'error',
        autoHideDuration: snackConfig.duration + 5000,
      });

      route(RouteList.AUTH_LOGIN);
    }

    setConfig(formConfig);
  }, []);

  return (
    <Grid container columns={12} justifyContent="center">
      <Grid item xs sm={10} md={7} lg={7} xl={5}>
        <Grid
          item
          bgcolor="white"
          width="100%"
          borderRadius={4}
          component={Card}
          padding={5}
          pb={15}
          maxWidth={720}
          margin="auto"
        >
          <Grid item xs margin="auto" sm={8} md={7} lg={5} pt={15} minWidth={200}>
            <Box>
              <Form redirect={RouteList.DASHBOARD}>
                <Grid container justifyContent="center">
                  <Box marginBottom={5}>
                    <Typography textAlign="center" variant="h1">
                      Вход&nbsp;в&nbsp;профиль
                    </Typography>

                    <Typography color="error.main" textAlign="center" variant="h4">
                      Оператор
                    </Typography>
                  </Box>
                </Grid>
                <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                  <Grid item width="100%">
                    <FormOutlinedInput
                      inputProps={{
                        sx: {
                          '& > input': {
                            padding: '15px 10px',
                          },
                        },
                        startAdornment: (
                          <InputAdornment sx={{ padding: '0 10px' }} position="start">
                            <UserIcon />
                          </InputAdornment>
                        ),
                        type: 'text',
                        placeholder: 'Логин/e-mail',
                        name: 'email',
                        autoComplete: 'on',
                      }}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <FormOutlinedInput
                      inputProps={{
                        sx: {
                          '& > input': {
                            padding: '15px 10px',
                          },
                        },
                        startAdornment: (
                          <InputAdornment sx={{ padding: '0 10px' }} position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? (
                              <VisibilityOffIcon sx={{ fontSize: 20 }} />
                            ) : (
                              <VisibilityIcon sx={{ fontSize: 20 }} />
                            )}
                          </IconButton>
                        ),
                        type: showPassword ? 'text' : 'password',
                        placeholder: 'Пароль',
                        name: 'password',
                        autoComplete: 'on',
                      }}
                    />
                  </Grid>
                </Grid>
                <FormFullButton loading={navigation.state !== NavigationStates.IDLE} disabled={!isValid} label="Вход" />
              </Form>
            </Box>
          </Grid>
          <Grid container justifyContent="center" mt={2}>
            <Grid item>
              <Grid container justifyContent="space-between" columns={2} spacing={3}>
                <Grid item xs={1}>
                  <Link to={RouteList.AUTH_FORGOT_PASSWORD}>
                    <Button variant="text">
                      Забыли&nbsp;пароль&nbsp;?
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
