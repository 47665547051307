import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {IPaginatable} from '../../../types/app/common/Paginatable';
import {RouteList} from '../../../http/RouteList';
import ShowButton from '../../../UI/Button/ShowButton';
import {IProductExtended} from './CatalogTabContentMain';
import TableContainer from '../../../UI/Table/TableContainer';
import {getColorByProductStatus, getProductDescriptionByStatusId} from '../../../enums/ProductStatuses';

const CatalogTabContentData = (props: IPaginatable<IProductExtended>) => {

  return (
    <TableContainer boxprops={{ style: {top: '-16px'}}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={200}> Идентификатор товара внутренний </TableCell>
            <TableCell width={400}> Наименование товара/услуги </TableCell>
            <TableCell width={150}> Стоимость </TableCell>
            <TableCell width={150}> Статус товара </TableCell>
            <TableCell width={50}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(product => (
            <TableRow key={product.id} sx={{'&:last-child td, &:last-child th': {border: 0}, width: '100%'}}>
              <TableCell>{product.id}</TableCell>
              <TableCell>{product.name}</TableCell>
              <TableCell>{Number(product.price).toLocaleString()} ₽</TableCell>
              <TableCell sx={{ color: getColorByProductStatus(product.productStatusId) }}>
                {getProductDescriptionByStatusId(product.productStatusId)}
              </TableCell>
              <TableCell>
                <ShowButton linkProps={{to: RouteList.CATALOG_SHOW + '/' + product.id}}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CatalogTabContentData;