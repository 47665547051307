import {ISelectable} from '../types/app/common/Selectable';

export enum CertificateStatuses {
  RELEASE = 0,
  ISSUE = 1,
  REPAID = 2,
  BLOCK = 3,
  OVERDUE = 4,
  DEACTIVATE = 5,
}

export enum CertificateStatusNames {
  RELEASE = 'Выпущен',
  ISSUE = 'Частично погашен',
  REPAID = 'Погашенный',
  BLOCK = 'Заблокированный',
  OVERDUE = 'Просрочен',
  DEACTIVATE = 'Деактивирован',
}

/**
 * Get certificate name by status
 *
 * @param {CertificateStatuses} status
 */
export const getCertificateNameByStatus = (status: CertificateStatuses): string => {
  switch (status) {
    case CertificateStatuses.BLOCK: return CertificateStatusNames.BLOCK;
    case CertificateStatuses.ISSUE: return CertificateStatusNames.ISSUE;
    case CertificateStatuses.REPAID: return CertificateStatusNames.REPAID;
    case CertificateStatuses.OVERDUE: return CertificateStatusNames.OVERDUE;
    case CertificateStatuses.DEACTIVATE: return CertificateStatusNames.DEACTIVATE;

    default: return CertificateStatusNames.RELEASE;
  }
}

export namespace CertificateStatusCodes {
  export const asSelectable = (): ISelectable[] => {
    return [
      {key: CertificateStatuses.ISSUE, label: getCertificateNameByStatus(CertificateStatuses.ISSUE)},
      {key: CertificateStatuses.RELEASE, label: getCertificateNameByStatus(CertificateStatuses.RELEASE)},
      {key: CertificateStatuses.REPAID, label: getCertificateNameByStatus(CertificateStatuses.REPAID)},
      {key: CertificateStatuses.BLOCK, label: getCertificateNameByStatus(CertificateStatuses.BLOCK)},
      {key: CertificateStatuses.OVERDUE, label: getCertificateNameByStatus(CertificateStatuses.OVERDUE)},
      {key: CertificateStatuses.DEACTIVATE, label: getCertificateNameByStatus(CertificateStatuses.DEACTIVATE)},
    ]
  }
}

export namespace CertificateStatus {
  export const asSelectable = (): ISelectable[] => {
    return [
      {key: CertificateStatuses.ISSUE, label: CertificateStatusNames.ISSUE},
      {key: CertificateStatuses.RELEASE, label: CertificateStatusNames.RELEASE},
      {key: CertificateStatuses.REPAID, label: CertificateStatusNames.REPAID},
      {key: CertificateStatuses.BLOCK, label: CertificateStatusNames.BLOCK},
      {key: CertificateStatuses.OVERDUE, label: CertificateStatusNames.OVERDUE},
      {key: CertificateStatuses.DEACTIVATE, label: CertificateStatusNames.DEACTIVATE},
    ]
  }
}