import React, {useState} from 'react';
import {IRuleSetting} from '../../../../types/app/fraud/RuleSetting';
import FraudUpdateForm from './Common/FraudUpdateForm';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import {FraudSettingConditionNameEnum, FraudSettingConditionNames} from '../../../../enums/FraudRules';
import {getConditionDefaultValue} from '../../../../utils/fraudUtils';
import Divider from '../../../../UI/Delimiter/Divider';

const ReferralFraudForm = (props: IRuleSetting) => {

  const [referralsCountMax, setReferralsCountMax] = useState(
    getConditionDefaultValue(FraudSettingConditionNameEnum.REFERRALS_COUNT_MAX, props.ruleSettingConditions)
  );

  const [referralsPeriodDaysCount, setReferralsPeriodDaysCount] = useState(
    getConditionDefaultValue(FraudSettingConditionNameEnum.REFERRALS_PERIOD_DAYS_COUNT, props.ruleSettingConditions)
  );

  return (
    <FraudUpdateForm settings={props}>
      <FormOutlinedInput
        inputProps={{
          name: FraudSettingConditionNameEnum.REFERRALS_COUNT_MAX,
          value: referralsCountMax,
          onChange: e => setReferralsCountMax(+e.target.value)
        }}
        rule={e => !isNaN(+e.target.value)}
        title={FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.REFERRALS_COUNT_MAX)}
      />

      <Divider />

      <FormOutlinedInput
        inputProps={{
          name: FraudSettingConditionNameEnum.REFERRALS_PERIOD_DAYS_COUNT,
          value: referralsPeriodDaysCount,
          onChange: e => setReferralsPeriodDaysCount(+e.target.value)
        }}
        rule={e => !isNaN(+e.target.value) && +e.target.value <= 100}
        title={FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.REFERRALS_PERIOD_DAYS_COUNT)}
      />

    </FraudUpdateForm>
  );
};

export default ReferralFraudForm;