import moment from 'moment';
import {TClientData, TClientRank} from '../../types/response/client/ClientData';
import {
  IClientsDataRequestedByEmail,
  TClientsData,
  TClientsDataRequestedByEmail
} from '../../types/response/client/ClientsData';
import {IClientDetail} from '../../types/app/client/ClientDetail';
import {IClient, TClientId} from '../../types/app/client/Client';
import {getGenderType} from '../../enums/Genders';
import {getMaritalStatusType} from '../../enums/MaritalStatuses';
import {IClientRank} from '../../types/app/client/ClientRank';
import {IGetRankSetting} from '../../types/app/billing/GetRankSetting';

export class ClientFactory {

  /**
   * Get client rank from response
   *
    * @param data
   */
  public static getClientRankFromResponse(data: TClientRank): IClientRank {
    return {
      createdAt: moment(data.created_at).unix(),
      description: data.description,
      icon: data.icon,
      id: data.id,
      minDirectReferrals: Number(data.min_direct_referrals),
      minExpenses: Number(data.min_expenses),
      minReferrals: Number(data.min_referrals),
      name: data.name,
      order: data.order,
      snilsIsSet: data.snils_is_set === '1',
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
    }
  }
  /**
   * Get client objects from response data
   *
   * @param {TClientsData[]} data
   */
  public static getClientsFromResponseData(data: TClientsData): IClient {
    return {
      id: data.id,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
      externalId: data.external_id,
      personalDataId: data.personal_data_id,
      externalPersonalDataId: data.external_personal_data_id,
      loyalityId: data.loyality_id,
      virtualAmount: Number(data.virtual_amount),
      registrationDate: data.registration_date ? moment(data.registration_date).unix() : null,
      lastLoginDate: data.last_login_date ? moment(data.last_login_date).unix() : null,
      clientRankId: data.client_rank_id,
      mailingAcceptance: data.mailing_acceptance,
      interviewAcceptance: data.interview_acceptance,
      enableWebNotice: data.enable_web_notice,
      enableEmailNotice: data.enable_email_notice,
      email: data.email,
      personalData: {
        id: data.personal_data.id,
        createdAt: data.personal_data.created_at ? moment(data.personal_data.created_at).unix() : null,
        updatedAt: data.personal_data.updated_at ? moment(data.personal_data.updated_at).unix() : null,
        surname: data.personal_data.surname ?? '',
        name: data.personal_data.name ?? '',
        middleName: data.personal_data.middle_name ?? '',
        phone: data.personal_data.phone ?? '',
        birthdate: data.personal_data.birthdate ? moment(data.personal_data.birthdate).unix() : null,
        passportNumber: data.personal_data.passport_number ?? '',
        snils: data.personal_data.snils ?? '',
        pensionCertificateNumber: data.personal_data.pension_certificate_number ?? '',
        birthCertificateNumber: data.personal_data.birth_certificate_number ?? '',
        inn: data.personal_data.inn ?? '',
        gender: getGenderType(data.personal_data.gender),
        region: data.personal_data.region ?? '',
        maritalStatus: getMaritalStatusType(data.personal_data.marital_status),
        education: data.personal_data.education ?? '',
        profession: data.personal_data.profession ?? '',
        fullName: data.personal_data.full_name ?? '',
      },
    }
  }

  /**
   * Get client detail object from response data
   *
   * @param {TClientData} data
   */
  public static getClientFromResponseData(data: TClientData): IClientDetail {
    return {
      clientRank: {
        id: data.client_rank.id,
        createdAt: data.client_rank.created_at ? moment(data.client_rank.created_at).unix() : null,
        updatedAt: data.client_rank.updated_at ? moment(data.client_rank.updated_at).unix() : null,
        name: data.client_rank.name,
        order: data.client_rank.order,
        description: data.client_rank.description,
        icon: data.client_rank.icon,
        minDirectReferrals: Number(data.client_rank.min_direct_referrals),
        minExpenses: Number(data.client_rank.min_expenses),
        minReferrals: Number(data.client_rank.min_referrals),
        snilsIsSet: data.client_rank.snils_is_set !== '0',
      },
      clientRankHistories: [],
      clientRankId: data.client_rank_id,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      email: data.email,
      enableEmailNotice: data.enable_email_notice,
      enableWebNotice: data.enable_web_notice,
      externalId: Number(data.external_id),
      externalPersonalDataId: data.external_personal_data_id,
      id: data.id,
      interviewAcceptance: data.interview_acceptance,
      lastLoginDate: data.last_login_date ? moment(data.last_login_date).unix() : null,
      loyalityId: data.loyality_id,
      mailingAcceptance: data.mailing_acceptance,
      personalData: {
        id: data.personal_data.id,
        createdAt: data.personal_data.created_at ? moment(data.personal_data.created_at).unix() : null,
        updatedAt: data.personal_data.updated_at ? moment(data.personal_data.updated_at).unix() : null,
        surname: data.personal_data.surname ?? '',
        name: data.personal_data.name ?? '',
        middleName: data.personal_data.middle_name ?? '',
        phone: data.personal_data.phone ?? '',
        birthdate: data.personal_data.birthdate ? moment(data.personal_data.birthdate).unix() : null,
        passportNumber: data.personal_data.passport_number ?? '',
        snils: data.personal_data.snils ?? '',
        pensionCertificateNumber: data.personal_data.pension_certificate_number ?? '',
        birthCertificateNumber: data.personal_data.birth_certificate_number ?? '',
        inn: data.personal_data.inn ?? '',
        gender: getGenderType(data.personal_data.gender),
        region: data.personal_data.region ?? '',
        maritalStatus: getMaritalStatusType(data.personal_data.marital_status),
        education: data.personal_data.education ?? '',
        profession: data.personal_data.profession ?? '',
        fullName: data.personal_data.full_name ?? '',
      },
      personalDataId: data.personal_data_id,
      registrationDate: data.registration_date ? moment(data.registration_date).unix() : null,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
      virtualAmount: Number(data.virtual_amount),
    };
  }

  /**
   * Get client rant from response
   *
   * @param {TClientRank} data
   * @param rankSetting
   */
  public static getClientRankFromResponseData(data: TClientRank, rankSetting: IGetRankSetting): IClientRank {
    const paymentBonus = rankSetting.bonuses.find(bonus => bonus.bonus_name === 'payment_bonus_' + data.id)
    const refferalBonus = rankSetting.bonuses.find(bonus => bonus.bonus_name === 'refferal_bonus_' + data.id)

    const paymentBonusPercent = paymentBonus?.bonus_percent
    const refferalBonusAmount = refferalBonus?.bonus_amount

    const bonus = rankSetting.bonuses.find(bonus =>  bonus !== paymentBonus && bonus !== refferalBonus)

    return  {
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
      description: data.description,
      icon: data.icon,
      id: data.id,
      minDirectReferrals: Number(data.min_direct_referrals),
      minExpenses: Number(data.min_expenses),
      minReferrals: Number(data.min_referrals),
      name: data.name,
      order: data.order,
      snilsIsSet: data.snils_is_set === '1',
      percent: Number(rankSetting.percent),
      bonusAmount: Number(bonus.bonus_amount),
      isMinExpenses: Number(data.min_expenses) !== 0,
      isMinDirectReferrals: Number(data.min_direct_referrals) !== 0,
      isMinReferrals: Number(data.min_referrals) !== 0,
      lifetimeDays: bonus.lifetime_days,
      paymentBonus: paymentBonusPercent ? +paymentBonusPercent : 0,
      refferalBonus:  refferalBonusAmount ? +refferalBonusAmount : 0,
    }
  }

  /**
   * Array of partners to Map
   *
   * @param {IClient[]} clients
   */
  public static clientsArrayToMap(clients: IClient[]): Map<TClientId, IClient>  {
    const map = new Map<TClientId, IClient>();

    clients.forEach(client => {
      map.set(client.id, client);
    })

    return map;
  }

  public static clientsDataByEmail(data: TClientsDataRequestedByEmail): IClientsDataRequestedByEmail  {

    return {
      items: data.items.map(item => {
        return {
          clientRankId: item.client_rank_id,
          createdAt: item.created_at,
          email: item.email,
          enableEmailNotice: item.enable_email_notice,
          enableWebNotice: item.enable_web_notice,
          externalId: item.external_id,
          externalPersonalDataId: item.external_personal_data_id,
          id: item.id,
          interviewAcceptance: item.interview_acceptance,
          lastLoginDate: item.last_login_date,
          loyalityId: item.loyality_id,
          mailingAcceptance: item.mailing_acceptance,
          personalDataId: item.personal_data_id,
          registrationDate: item.registration_date,
          updatedAt: item.updated_at,
          virtualAmount: item.virtual_amount,
        }
      }),
      limitExceeded: data.limit_exceeded
    }
  }
}
