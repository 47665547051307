import React, { ForwardedRef, useContext, useEffect, useState } from 'react';
import { Form as RouterForm, useActionData, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { FormContext } from '../../store/context/form/FormContext';
import { IFormResponse } from '../../store/context/form/types/FormResponse';
import { useNotifications } from '../../hooks/useNotifications';
import { NavigationStates } from '../../enums/NavigationStates';

interface IFormProps {
  children?: React.ReactNode;
  redirect?: string;
}

const Form = React.forwardRef((props: IFormProps, ref: ForwardedRef<HTMLFormElement>) => {
  const { setConfig, config } = useContext(FormContext);
  const formResponse = useActionData() as IFormResponse;
  const route = useNavigate();
  const navigation = useNavigation();

  const { error } = useNotifications();

  const { state, pathname } = useLocation();
  const [hasPayload, setHasPayload] = useState<boolean>(false);

  useEffect(() => {
    if (hasPayload && navigation.state === NavigationStates.IDLE) {
      config.afterSubmitHandler(formResponse, config, setConfig);
    }
  }, [hasPayload, navigation.state]);

  useEffect(() => {
    if (formResponse) {
      if (!formResponse.status) {
        error(formResponse.error);

        if (state) {
          route(pathname, { state });
        }

        return;
      }

      if (formResponse.status && config.afterSubmitHandler) {
        setHasPayload(true);

        return;
      }

      if (formResponse.status) {
        if (props.redirect) {
          route(props.redirect, {
            state: formResponse.payload,
          });
        }
      }
    }
  }, [formResponse]);

  return (
    <RouterForm id="router-form" ref={ref} method="post">
      {props.children}
    </RouterForm>
  );
});

export default Form;
