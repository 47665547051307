import React, {useCallback, useEffect, useState} from 'react';
import {StylesConfig} from 'react-select';
import AsyncSelect from 'react-select/async';
import {Grid, Typography} from '@mui/material';
import {themeConfig} from '../../../layout/assets/config/themeConfig';
import {IFilter} from '../../../hooks/table/useFilters';
import {IFilters} from '../../../store/rtk/reducers/serverPaginationSlice';
import {FilterSelectSearchByClientEmailType} from '../../../enums/FilterSelectSearchByClientEmailType';
import {isArray, isString} from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import {GetPartnerByCompanyName} from '../../../types/request/partner/PartnerGetRequest';
import {GetClientByEmail} from '../../../types/request/client/GetClientByEmail';
import {IGetUserByEmailRequest} from '../../../types/request/auth/GetUserByEmailRequest';

const styles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    fontSize: '14px',
    borderRadius: '8px',
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: '10px',
    color: themeConfig.palette.grey[600],
  }),
};

interface Option {
  value: number;
  label: string;
}

interface SelectComponentProps<F extends OptionFields, S extends ItemsFromResponse> {
  placeholder?: string;
  addFilter: (filter: IFilter) => void;
  field: string;
  filters: IFilters;
  label: string;
  fetchData: (request: FetchDataRequest) => Promise<F[]>;
  searchData: (request: GetClientByEmail | GetPartnerByCompanyName | IGetUserByEmailRequest) => Promise<S>;
  type: 'byEmail' | 'byCompanyName';
}

interface FetchDataRequest {
  ids: number[];
}

interface OptionFields {
  id?: number;
  key?: number;
  companyName?: string;
  label?: string;
  email?: string;
}

interface ItemsFromResponse {
  items: ExtensionForMaping [];
}

interface ExtensionForMaping {
  id?: number;
  key?: number;
}

const FilterSelectWithSearchData = <F extends OptionFields, S extends ItemsFromResponse>({
                                                                                           type,
                                                                                           fetchData,
                                                                                           searchData,
                                                                                           field,
                                                                                           addFilter,
                                                                                           label,
                                                                                           filters,
                                                                                           placeholder
                                                                                         }: SelectComponentProps<F, S>) => {
  const [initialized, setInitialized] = useState(false);
  const getSelectedOptions = async (): Promise<Option[]> => {
    if (
      filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field] &&
      isArray(filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field]) &&
      filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field].length !== 0
    ) {
      const itemsByIds = await fetchData({
        ids: filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field],
      });
      const itemsMap = new Map<number, F>();
      itemsByIds.forEach((item) => itemsMap.set(item.id || item.key, item));

      return filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field].map((selectedOption) => ({
        value: selectedOption,
        label:
          type === 'byEmail' && itemsMap.get(selectedOption)?.email ||
          type === 'byCompanyName' && itemsMap.get(selectedOption)?.label,
      }));
    }

    if (filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field] && isString(
      filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field])) {
      const itemsByIds = await fetchData({
        ids: filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field].split(','),
      });
      const itemsMap = new Map<number, F>();
      itemsByIds.forEach((item) => itemsMap.set(item.id || item.key, item));

      return filters[FilterSelectSearchByClientEmailType.EQ_ARRAY][field]
        .split(',')
        .map((selectedOption) => ({
          value: +selectedOption,
          label:
            type === 'byEmail' && itemsMap.get(selectedOption)?.email ||
            type === 'byCompanyName' && itemsMap.get(selectedOption)?.label
        }));
    }

    return [];
  };

  const [clientSelectedOptions, setClientSelectedOptions] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const getOptionsData = async (inputValue: string): Promise<Option[]> => {

    let request;

    if (type === 'byEmail') {
      request = {
        email: inputValue
      };
    }

    if (type === 'byCompanyName') {
      request = {
        company_name: inputValue
      };
    }

    try {
      const itemsFromResponse = await searchData(request);

      const itemsByIds = await fetchData({ids: itemsFromResponse.items.map(item => item.id)});

      const itemsMap = new Map<number, F>;

      itemsByIds.forEach(item => itemsMap.set(item.id | item.key, item));

      return itemsFromResponse.items.map((item) => ({
        value: item.id,
        label:
          type === 'byEmail' && itemsMap.get(item.id)?.email ||
          type === 'byCompanyName' && itemsMap.get(item.id)?.label,
      }));
    }
    catch (error) {

      return [];
    }
  };

  const loadOptions = useCallback((inputValue: string, callback: (options: Option[]) => void) => {

    if (searchValue && searchValue.split('').length >= 2) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const newTimeoutId = setTimeout(() => {
        getOptionsData(inputValue).then((options) => {
          callback(options);
        });
      }, 1000);

      setTimeoutId(newTimeoutId);
    }
  }, [searchValue]);

  const handleInputChange = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const handleSelectChange = (selectedOptions: Option[]) => {

    setClientSelectedOptions(selectedOptions);

    addFilter({
      name: field,
      value: selectedOptions.map(option => option.value),
      type: FilterSelectSearchByClientEmailType.EQ_ARRAY
    });
  };

  useEffect(() => {
    const initializeSelectedOptions = async () => {

      const selectedOptions = await getSelectedOptions();
      setClientSelectedOptions(selectedOptions);
      setInitialized(true);
    };

    initializeSelectedOptions();
  }, [initialized]);

  if (!initialized) {
    return (
      <Grid container display="flex" alignItems="center" justifyContent="center">
        <Grid item>
          <CircularProgress style={{height: '25px', width: '25px'}}/>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid alignItems="center" container justifyContent="space-between" columns={12} spacing={1}>
      <Grid item xs={4} position="relative">
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={8}>
        <AsyncSelect
          styles={styles}
          placeholder={placeholder}
          isMulti
          value={clientSelectedOptions}
          loadOptions={loadOptions}
          onInputChange={handleInputChange}
          onChange={handleSelectChange}
          noOptionsMessage={() => 'Нет данных'}
          loadingMessage={() => 'Загрузка...'}
        />
      </Grid>
    </Grid>
  );
};

export default FilterSelectWithSearchData;
