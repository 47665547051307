import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import moment from 'moment';
import TableContainer from '../../../UI/Table/TableContainer';
import { IPaginatable } from '../../../types/app/common/Paginatable';
import { ITariff } from '../../../types/app/billing/bonuses/Tariff';
import { IPartner } from '../../../types/app/partner/Partner';
import ShowButton from '../../../UI/Button/ShowButton';
import { RouteList } from '../../../http/RouteList';
import { TableHelper } from '../../../helpers/TableHelper';
import { IPromo } from '../../../types/app/billing/bonuses/Promo';
import {getPartner, getType} from '../../../utils/billingUtils';
import EmptyCell from '../../../UI/Table/EmptyCell';

interface IBillingTabContentDataProps<T> extends IPaginatable<T> {
  partners: IPartner[];
}

const BillingTabContentData = (props: IBillingTabContentDataProps<ITariff | IPromo>) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={100}>№</TableCell>
            <TableCell width={200}>Наименование тарифа/акции</TableCell>
            <TableCell width={150}>Тип&nbsp;тарифа/Акция</TableCell>
            <TableCell width={500}>Партнер</TableCell>
            <TableCell width={150}>Приоритет</TableCell>
            <TableCell width={150}>Дата начала</TableCell>
            <TableCell width={170}>Дата завершения</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((tariffOrPromo, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {TableHelper.viewIndex(props.pageIndex, index)}
              </TableCell>
              <TableCell>{tariffOrPromo.name}</TableCell>
              <TableCell>{getType(tariffOrPromo)}</TableCell>
              <TableCell>{getPartner(tariffOrPromo, props.partners)}</TableCell>
              <TableCell>{tariffOrPromo.priority ?? <EmptyCell />}</TableCell>
              <TableCell>
                {tariffOrPromo.validFrom ? moment.unix(tariffOrPromo.validFrom).format('DD.MM.YYYY') : 'Не установлена'}
              </TableCell>
              <TableCell>
                {tariffOrPromo.validTo ? moment.unix(tariffOrPromo.validTo).format('DD.MM.YYYY') : 'Не установлена'}
              </TableCell>
              <TableCell>
                <ShowButton linkProps={{
                  to: tariffOrPromo.type === 'tariff'
                    ? RouteList.BILLING_SHOW_TARIFF + '/' + tariffOrPromo.id
                    : RouteList.BILLING_SHOW_PROMO + '/' + tariffOrPromo.id
                }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillingTabContentData;
