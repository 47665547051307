import React, { useContext, useRef } from 'react';
import { Grid } from '@mui/material';
import { FormContext } from '../../store/context/form/FormContext';
import { LoadingButton } from '@mui/lab';
import { useNavigation } from 'react-router-dom';
import { NavigationStates } from '../../enums/NavigationStates';

interface FormActionsProps {
  children?: React.ReactNode;
  title?: string;
  loading?: boolean;
  disabled?: boolean;
  beforeSubmit?: (e) => boolean;
}

const FormActions = (props: FormActionsProps) => {
  const {isValid, config, onSubmit} = useContext(FormContext);
  const {state} = useNavigation();

  const title = props.title ?? 'Сохранить';
  const ref = useRef(null);

  const send = (e: React.MouseEvent) => {
    let isOk = true;

    if (props.beforeSubmit) {
      isOk = props.beforeSubmit(e)
    }

    if (!isOk) {
      return;
    }

    onSubmit((e.target as HTMLButtonElement).form);
  }

  return (
    <Grid
      mt={5}
      container
      justifyContent="space-between"
    >
      <Grid item xs={8}>
        {props.children}
      </Grid>

      <Grid item >
        {!config.readonly &&
            <LoadingButton
                form="router-form"
                ref={ref}
                loading={state !== NavigationStates.IDLE || props.loading}
                disabled={!isValid || props.disabled}
                onClick={send}
            >
              {title}
            </LoadingButton>
        }
      </Grid>
    </Grid>
  );
};

export default FormActions;