import React, {useCallback, useContext, useEffect, useState} from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { IRule } from '../../../../types/app/fraud/Rule';
import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { RouteList } from '../../../../http/RouteList';
import { LoadingButton } from '@mui/lab';
import MainForm from '../../../Common/Form/MainForm';
import Divider from '../../../../UI/Delimiter/Divider';
import FraudCreateFormStepSwitcher from './Common/FraudCreateFormSteps/FraudCreateFormStepSwitcher';
import { clone } from 'lodash';
import { formatLabel } from '../../../../utils/fraudUtils';
import { IPartner } from '../../../../types/app/partner/Partner';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { FormContext } from '../../../../store/context/form/FormContext';
import { useNotifications } from '../../../../hooks/useNotifications';
import { messages } from '../../../../notifications/messages';
import { FraudRuleReportIds } from '../../../../enums/FraudRules';

export interface IFraudSettingCreateDataset {
  rule?: IRule;
  partner?: IPartner;
  partners?: number[];
  isActive?: boolean;
  conditionList?: { [conditionName: string]: number | string };
  terminals?: Record<string, number[]>;
}

export enum STEPS {
  STEP_START = 1,
  STEP_CONDITIONS,
  STEP_FINISH,
}

const initSteps = ['Выбор правила', 'Выбор условий для правила', 'Завершение настройки'];

const FraudMonitoringRuleSettingCreateForm = () => {
  const rules = useAsyncValue() as IRule[];

  const { setConfig, onSubmit } = useContext(FormContext);
  const { success } = useNotifications();

  const route = useNavigate();

  const [dataset, setDataset] = useState<IFraudSettingCreateDataset>({
    rule: null,
    partner: null,
    partners: [],
    isActive: true,
    conditionList: {},
    terminals: {},
  });

  const [stepIndex, setStepIndex] = useState<STEPS>(STEPS.STEP_START);
  const [steps, setSteps] = useState<string[]>(initSteps);
  const [isNextDisable, setIsNextDisable] = useState(true);

  const stepNextHandler = () => {
    setStepIndex(clone(stepIndex) + 1);
  };

  const stepBackHandler = () => {
    setStepIndex(clone(stepIndex) - 1);
  };

  const formConfig = {
    action: RouteList.FRAUD_MONITORING_RULE_SETTING_CREATE,
    data: [
      { field: 'rule_name', value: dataset.rule?.name },
      { field: 'is_active', value: String(dataset.isActive) },
      { field: 'condition_list', value: JSON.stringify(dataset.conditionList) },
      { field: 'partner_terminal_id_list', value: JSON.stringify(dataset.terminals) },
      { field: 'partner_id', value: dataset.partner?.key ?? '' },
      { field: 'partner_id_list', value: dataset.partners?.join(',') ?? '' },
    ],
    afterSubmitHandler: formResponse => {
      route(dataset.rule?.id === FraudRuleReportIds.BIG_TRANSACTION_FRAUD ? RouteList.FRAUD_MONITORING : RouteList.FRAUD_MONITORING_RULE_SETTING_SHOW + '/' + formResponse.payload);
      success(messages.success.fraudSettingCreated);
    },
  };

  useEffect(() => {
    setConfig(formConfig);
  }, [dataset]);

  useEffect(() => {
    if (stepIndex > 1 && dataset.rule.id) {
      const newStepState = [...steps];

      newStepState[0] = rules.find(rule => rule.id === dataset.rule.id).name;

      setSteps(newStepState);
    } else {
      setSteps(initSteps);
    }
  }, [stepIndex]);

  useEffect(() => {
    if (
      stepIndex === STEPS.STEP_FINISH ||
      dataset.rule === null ||
      isConditionHasEmptyValues()
    ) {
      setIsNextDisable(true);

      return;
    }

    setIsNextDisable(false);
  }, [stepIndex, dataset]);

  const isConditionHasEmptyValues = useCallback(() => {
    let result = false;

    for (let conditionName in dataset.conditionList) {
      if (result) {
        return result;
      }

      if (dataset.conditionList[conditionName] === '') {
        result = true;
      }
    }

    return result;
  }, [dataset.conditionList])

  return (
    <Box>
      <MainForm back={RouteList.FRAUD_MONITORING}>
        <Typography mb={5} variant="h1">
          Добавление новых настроек
        </Typography>

        <Stepper activeStep={stepIndex - 1} alternativeLabel variant="outlined">
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{formatLabel(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box mt={6} mb={6}>
          <Divider />
        </Box>

        <Box minHeight={300}>
          <FraudCreateFormStepSwitcher
            steps={steps}
            rules={rules}
            step={stepIndex}
            initSteps={initSteps}
            dataset={dataset}
            dispatchSteps={setSteps}
            dispatchDataset={setDataset}
          />
        </Box>

        <Grid container mt={5} justifyContent="space-between">
          <Grid item>
            <LoadingButton
              form="router-form"
              disabled={stepIndex < STEPS.STEP_FINISH}
              color="success"
              startIcon={<SaveAsIcon />}
              onClick={(e) => onSubmit((e.target as HTMLButtonElement).form)}
            >
              Сохранить
            </LoadingButton>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <LoadingButton
                  disabled={stepIndex === STEPS.STEP_START}
                  color="error"
                  startIcon={<UndoIcon />}
                  onClick={stepBackHandler}
                >
                  Назад
                </LoadingButton>
              </Grid>

              <Grid item>
                <LoadingButton
                  disabled={isNextDisable}
                  endIcon={<RedoIcon />}
                  onClick={stepNextHandler}
                  type="button"
                >
                  Далее
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainForm>
    </Box>
  );
};

export default FraudMonitoringRuleSettingCreateForm;
