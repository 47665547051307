import axios from 'axios';
import {CookieRepository} from '../repositories/CookieRepository';
import {IRequest} from '../types/request/common/Request';
import {IAuthResponse} from '../types/response/auth/AuthResponse';
import {RouteList} from './RouteList';
import React from 'react';

/**
 * A common service for accessing and invoking remote procedures
 * using the jrpc protocol
 */

const REACT_APP_API_BUS_GATEWAY_URL = (process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_API_BUS_GATEWAY_URL : window?._env_?.REACT_APP_API_BUS_GATEWAY_URL ?? process.env.REACT_APP_API_BUS_GATEWAY_URL);

export class JsonRpcService extends React.PureComponent {
  /**
   * Main request method
   *
   * @param {string} service
   * @param {string} method
   * @param {any} params
   */
  public static request<T>(service: string, method: string, params = {}) {
    const http = axios.create({
      withCredentials: true,
      baseURL: REACT_APP_API_BUS_GATEWAY_URL,
    });

    http.interceptors.request.use(
      config => {
        const token = CookieRepository.getToken();

        if (token) {
          config.headers['Authorization'] =`Bearer ${token}`;
          config.headers['Content-type'] = 'application/json';
        }

        return config;
      },
      error => Promise.reject(error)
    );

    http.interceptors.response.use(
      config => config,
      async error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && error.config && !error.config.isRetry) {
          originalRequest.isRetry = true;

          try {
            const response = await axios.post<IAuthResponse>(process.env.REACT_APP_API_BUS_GATEWAY_URL, {
              jsonrpc: '2.0',
              method: 'auth@refresh',
              params: {},
              id: new Date().getTime(),
            } as IRequest);

            if (response.data.result.status) {
              CookieRepository.setToken(response.data.result.auth.token);
            }

            return http.request(originalRequest);
          } catch (e) {
            window.location.href = RouteList.AUTH_LOGIN;

            CookieRepository.removeToken();
          }
        }

        throw error;
      }
    );

    return http
      .post('', {
        jsonrpc: '2.0',
        method: service + '@' + method,
        params: { ...params },
        id: new Date().getTime(),
      } as IRequest)
      .then<T>(response => response.data)
      .catch<T>(response => response.data);
  }
}
