import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import EmptyCell from '../../../UI/Table/EmptyCell';

type TObject = object | null | undefined;

interface IRowLoaderProps {
  object: TObject;
  index: string;
  formatter?: Function;
}

const TypographyLoader = (props: IRowLoaderProps) => {
  const getValue = () => {
    const value = props.object[props.index];

    if (!props.formatter) {
      return value;
    }

    return props.formatter(value);
  };

  if (props.object === null) {
    return <EmptyCell />;
  }

  if (props.object === undefined) {
    return <CircularProgress size={15} />;
  }

  if (props.object[props.index] === null || props.object[props.index] === undefined || props.object[props.index] === "") {
    return <EmptyCell />;
  }

  return <Typography>{getValue()}</Typography>;
};

export default TypographyLoader;
