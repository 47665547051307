import React, { useMemo } from 'react';
import { IDialogFilterable } from '../../../types/app/common/DialogFilterable';
import FilterDialog from '../../Common/Filter/FilterDialog';
import { defaultFilterState } from './BillingTabContentMain';
import { IPartner } from '../../../types/app/partner/Partner';
import FilterSelect from '../../Common/Filter/FilterSelect';
import Divider from '../../../UI/Delimiter/Divider';
import FilterInput from '../../Common/Filter/FilterInput';
import FilterDate from '../../../UI/Filter/FilterDate';
import { Grid, Typography } from '@mui/material';

interface IBillingTabFiltersProps extends IDialogFilterable<typeof defaultFilterState> {
  partners: IPartner[];
}

const BillingTabFilters = (props: IBillingTabFiltersProps) => {
  const isRangeFiltersError = useMemo(() => {
    if (!props.filters) {
      return true;
    }

    if (!props.filters.eq) {
      return true;
    }

    const max = props.filters.eq['validFrom'] ?? null;
    const min = props.filters.eq['validTo'] ?? null;

    if (max === null || min === null) {
      return false
    }

    if (min === max) {
      return false;
    }

    return !(min > max);
  }, [props.filters]);

  return (
    <FilterDialog disabled={isRangeFiltersError} close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterInput
        addFilter={props.addFilter}
        field='name'
        filters={props.filters}
        label="Наименование тарифа/акции"
        placeholder="Укажите наименование тарифа/акции"
        inputProps={{
          autoFocus: true
        }}
      />

      <Divider />

      <FilterSelect
        items={props.partners.map(partner => ({key: partner.key, label: partner.label}))}
        addFilter={props.addFilter}
        field='partnersIds'
        filters={props.filters}
        label="Наименование Партнера"
        placeholder="Укажите наименование Партнера"
        type="in"
      />

      <Divider />

      <FilterDate
        addFilter={props.addFilter}
        field='validFrom'
        filters={props.filters}
        label="По дате начала действия тарифа"
        fieldProps={{
          placeholder: "Дата начала",
          error: isRangeFiltersError,
        }}
      />

      <Divider />

      <FilterDate
        addFilter={props.addFilter}
        field='validTo'
        filters={props.filters}
        label="По дате завершения действия тарифа"
        fieldProps={{
          placeholder: "Дата окончания",
          error: isRangeFiltersError,
        }}
      />

      {isRangeFiltersError && (
        <Grid mt={3} container justifyContent="center">
          <Grid item>
            <Typography variant="body2" color="error" fontWeight="bold">
              Дата окончания фильтрации должна быть позже даты начала
            </Typography>
          </Grid>
        </Grid>
      )}
    </FilterDialog>
  );
};

export default BillingTabFilters;