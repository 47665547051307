import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IShowNewsLoader } from '../../../http/communication/CommunicationLoaders';
import NewsFormShow from '../../../Widgets/Notifications/NewsTabMain/Operations/NewsFormShow';

const NotificationNewsShow = () => {
  const { content } = useLoaderData() as { content: IShowNewsLoader };

  return (
    <Await resolve={content}>
      <NewsFormShow />
    </Await>
  );
};

export default NotificationNewsShow;
