import React from 'react';
import { useAsyncValue } from 'react-router-dom';
import { Card, CardContent, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import BackButton from '../../../UI/Button/BackButton';
import Divider from '../../../UI/Delimiter/Divider';
import { unix } from 'moment/moment';
import { IGetOrderLoader } from '../../../http/shop/ShopLoaders';
import EmptyCell from '../../../UI/Table/EmptyCell';
import { getColorByStatusName, getOrderStatusName } from '../../../enums/OrderStatuses';
import CircleIcon from '@mui/icons-material/Circle';
import ContentContainer from '../../Layout/Content/ContentContainer';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import RemoveIcon from '@mui/icons-material/Remove';

const OrderShowForm = () => {
  const { order, clients, partners } = useAsyncValue() as IGetOrderLoader;

  return (
    <ContentContainer>
      <Grid container flexDirection="column" columns={12}>
        <Grid item alignSelf="left">
          <BackButton />
        </Grid>

        <Grid item xs={8}>
          <Card sx={{ padding: '70px 50px' }}>
            <CardContent>
              <Grid container flexDirection="column">
                <Typography color="grey.700" mb={1}>
                  Номер заказа
                </Typography>
                <Typography variant="h3">{order.showNumber}</Typography>
              </Grid>

              <Divider />

              <Grid container columns={3} justifyContent="space-between">
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Дата:
                  </Typography>
                  <Typography>{unix(order.createdAt).format('DD.MM.YYYY')}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Время:
                  </Typography>
                  <Typography>{unix(order.createdAt).format('HH:mm')}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Место покупки:
                  </Typography>
                  <Typography>{order.terminalName ?? '-'}</Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container columns={3} justifyContent="space-between">
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Стоимость заказа:
                  </Typography>
                  <Typography>{order.orderAmount.toLocaleString()} ₽</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Сумма, оплаченная по карте:
                  </Typography>
                  <Typography>{order.paymentAmount.toLocaleString() + ' ₽'}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Начисленный бонус:
                  </Typography>
                  <Typography>
                    {order.accrualBonus !== 0 ? order.accrualBonus.toLocaleString() + ' баллов' : '0 баллов'}
                  </Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container columns={3} justifyContent="space-between">
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Скидка по сертификату:
                  </Typography>
                  <Typography>
                    {order.transaction.certificateUsedAmount
                      ? `${order.transaction.certificateUsedAmount.toLocaleString()} ₽`
                      : '0 ₽'}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Количество баллов, использованных при покупке:
                  </Typography>
                  <Typography>{order.usedBonus}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Наименование организации Партнера:
                  </Typography>
                  <Typography>{partners.find(partner => partner.key === order.partnerId)?.label || <EmptyCell />}</Typography>
                </Grid>
              </Grid>

              <Divider />

              <Grid container columns={3} justifyContent="space-between">
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Статус заказа:
                  </Typography>
                  <Typography color={getColorByStatusName(order.statusId)}>{getOrderStatusName(order.statusId)}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Email Пользователя:
                  </Typography>
                  <Typography>{clients.find(client => client.id === order.userId)?.email || <EmptyCell />}</Typography>
                </Grid>
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Автоплатеж:
                  </Typography>
                  {order.isRecurrent ? (
                    <DownloadDoneIcon sx={{ fontSize: 16, mr: 1, color: '#0042E7' }} />
                  ) : (
                    <RemoveIcon sx={{ fontSize: 16, mr: 1, color: 'inherit' }} />
                  )}
                </Grid>
              </Grid>

              <Divider />

              <Grid container columns={3} justifyContent="space-between">
                <Grid item xs>
                  <Typography color="grey.700" mb={1}>
                    Состав заказа:
                  </Typography>
                  {order.orderItems ? (
                    <List dense>
                      {order.orderItems.map(orderItem => (
                        <ListItem key={orderItem.id} disablePadding>
                          <ListItemIcon>
                            <CircleIcon
                              sx={[
                                theme => ({
                                  color: theme.palette.text.primary,
                                  fontSize: 7,
                                  mr: 1,
                                }),
                              ]}
                            />
                          </ListItemIcon>
                          <ListItemText>{orderItem.productName}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <EmptyCell />
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ContentContainer>
  );
};

export default OrderShowForm;
