import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IRuleSetting } from '../../../types/app/fraud/RuleSetting';
import FraudMonitoringRuleSettingShowForm
  from '../../../Widgets/FraudMonitoring/RuleSettingsTab/Operations/FraudMonitoringRuleSettingShowForm';

const FraudMonitoringRuleSettingShow = () => {
  const { ruleSetting } = useLoaderData() as { ruleSetting: IRuleSetting };

  return (
    <Await resolve={ruleSetting}>
      <FraudMonitoringRuleSettingShowForm />
    </Await>
  );
};

export default FraudMonitoringRuleSettingShow;
