export class ObjectHelper {
  public static searchValueByDotString(obj: object, is: string | Array<string>): any {
    if (typeof is === 'string') return ObjectHelper.searchValueByDotString(obj, is.split('.'));
    else if (is.length == 1) {
      if (obj === null || is[0] === null || obj[is[0]] === null) {
        return null;
      }

      return obj[is[0]];
    } else if (is.length == 0) return obj;
    else return ObjectHelper.searchValueByDotString(obj[is[0]], is.slice(1));
  }
}
