import React from 'react';
import { Grid, SvgIcon, Typography } from '@mui/material';

interface IEmptyPageProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const EmptyWidget = (props: IEmptyPageProps) => {
  return (
    <Grid container flexDirection="column" width="100%" alignItems="center" mt="10%">
      <Grid item>
        <SvgIcon
          sx={{
            color: '#E0EEFF',
            fontSize: 75,
          }}
        >
          {props.icon}
        </SvgIcon>
      </Grid>
      <Grid item width={250} mt={3}>
        <Typography textAlign="center" variant="subtitle1">
          {props.title}
        </Typography>
      </Grid>
      <Grid item width={338} mt={1}>
        <Typography textAlign="center" fontSize={16} color="grey.700">
          {props.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmptyWidget;
