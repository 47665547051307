import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetUserBankAccountLoader } from '../../../http/account/AccountLoaders';
import UserBankAccountsShow from '../../../Widgets/Banking/UserBankAccountTab/Operations/UserBankAccountsShow';

const BankingUserShow = () => {
  const { bankAccount } = useLoaderData() as IGetUserBankAccountLoader;

  return (
    <Await resolve={bankAccount}>
      <UserBankAccountsShow />
    </Await>
  );
};

export default BankingUserShow;
