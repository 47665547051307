import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const DeleteButton = (props: IconButtonProps) => {
  return (
    <IconButton {...props}>
      <DeleteOutlineIcon sx={{ fontSize: 16 }} color="error" />
    </IconButton>
  );
};

export default DeleteButton;
