import React from 'react';
import styled from '@emotion/styled';
import Button, {ButtonProps} from '@mui/material/Button';

const InfoButton = styled(Button)({
  boxShadow: 'none',
  padding: '15px',
  backgroundColor: 'white',
  color: 'secondary',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,.1)',
    borderColor: 'rgba(0,0,0,.1)',
    boxShadow: 'none',
  },
});

const HeaderInfoButton = (props: ButtonProps) => (<InfoButton {...props} />);

export default HeaderInfoButton;
