export enum RouteList {
  AUTH_LOGIN = '/auth/login',
  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_CHANGE_PASSWORD = '/auth/change-password',

  BANKING = '/banking',
  BANKING_USER_SHOW = '/banking/user/show',
  BANKING_PARTNER_SHOW = '/banking/partner/show',
  BANKING_PARTNER_INVOICES_SHOW = '/banking/partner-invoices/show',
  BILLING = '/billing',
  BILLING_CREATE_TARIFF = '/billing/tariff/create',
  BILLING_SHOW_TARIFF = '/billing/tariff/show',
  BILLING_CREATE_PROMO = '/billing/promo/create',
  BILLING_SHOW_PROMO = '/billing/promo/show',


  CATALOG = '/catalog',
  CATALOG_CREATE = '/catalog/create',
  CATALOG_SHOW = '/catalog/show',
  CATALOG_CATEGORY_SHOW = '/catalog/category/show',
  CATALOG_CATEGORY_CREATE = '/catalog/category/create',

  CLIENTS = '/clients',
  CLIENTS_CREATE = '/clients/create',
  CLIENTS_SHOW = '/clients/show',
  CLIENTS_RANK_SHOW = '/clients-rank/show',
  CLIENTS_RANK_CREATE = '/clients-rank/create',

  DASHBOARD = '/',

  FRAUD_MONITORING = '/fraud-monitoring',
  FRAUD_MONITORING_REPORT_SHOW = '/fraud-monitoring/report/show',
  FRAUD_MONITORING_RULE_SETTING_CREATE = '/fraud-monitoring/rule-setting/create',
  FRAUD_MONITORING_RULE_SETTING_SHOW = '/fraud-monitoring/rule-setting/show',

  LOGS = '/logs',
  LOGS_SHOW = '/logs/show',

  NOTIFICATIONS = '/notifications',
  NOTIFICATIONS_TEMPLATE_CREATE = '/notifications/template/create',
  NOTIFICATIONS_TEMPLATE_SHOW = '/notifications/template/show',
  NOTIFICATIONS_NEWS_SHOW = '/notifications/news/show',
  NOTIFICATIONS_NEWS_CREATE = '/notifications/news/create',
  NOTIFICATIONS_ANTISPAM_CREATE = '/notifications/antispam/create',
  NOTIFICATIONS_ANTISPAM_SHOW = '/notifications/antispam/show',

  ORDERS = '/orders',
  ORDERS_SHOW = '/orders/show',

  PARTNERS = '/partners',
  PARTNERS_PRIMARY_SHOW = '/partners-primary/show',
  PARTNERS_PRIMARY_CREATE = '/partners-primary/create',
  PARTNERS_CREATE = '/partners/create',
  PARTNERS_SHOW = '/partners/show',
  PARTNERS_ROLE_CREATE = '/partners/role/create',
  PARTNERS_ROLE_SHOW = '/partners/role/show',
  PARTNERS_REGISTRATION_APPEALS_SHOW = '/partners/registration-appeals/show',
  PARTNERS_TERMINAL_SHOW = '/partners/terminal/show',

  REPORTS = '/reports',
  REPORTS_SHOW = '/reports/show',
  REPORTS_CREATE = '/reports/create',
  REPORTS_RULES_CREATE = '/reports/rules-create',
  REPORTS_RULES_SHOW = '/reports/rules-show',

  REQUISITES = '/requisites',

  CERTIFICATES = '/certificates',
  SHOW_CERTIFICATE = '/certificates/show',
  CREATE_CERTIFICATE = '/certificates/create',

  TECH_SUPPORT = '/tech-support',
  TECH_SUPPORT_SUBJECT_SHOW = '/tech-support/subject/show',
  TECH_SUPPORT_SUBJECT_CREATE = '/tech-support/subject/create',
  TECH_SUPPORT_USERS_SHOW = '/tech-support/u/show',
  TECH_SUPPORT_PARTNERS_SHOW = '/tech-support/p/show',
  TECH_SUPPORT_OPERATOR_USERS_SHOW = '/tech-support/ou/show',
  TECH_SUPPORT_OPERATOR_PARTNERS_SHOW = '/tech-support/op/show',
  TECH_SUPPORT_TICKET_CREATE = '/tech-support/ticket/create',
  TECH_SUPPORT_ANSWER_QUESTION = '/tech-support/questions/answer',

  USERS = '/users',
  USERS_CREATE = '/users/create',
  USERS_SHOW = '/users/show',
  USERS_ROLE_CREATE = '/users/role/create',
  USERS_ROLE_SHOW = '/users/role/show',
}