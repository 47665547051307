import React from 'react';
import Button, {ButtonProps} from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const AddButton = (props: ButtonProps) => {
  return (
    <Button {...props} endIcon={<AddCircleOutlineIcon />}>
      {props.children}
    </Button>
  );
};

export default AddButton;