import React from 'react';
import {Grid} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const TabLoader = () => {
  return (
    <Grid
      mt={15}
      width="100%"
      justifyContent="center"
      alignItems="center"
      container
    >
      <CircularProgress color="error"/>
    </Grid>
  );
};

export default TabLoader;
