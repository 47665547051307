import React from 'react';
import {Link, LinkProps} from 'react-router-dom';
import { IconButton, IconButtonProps } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface IShowButtonProps {
  buttonProps?: IconButtonProps;
  linkProps: LinkProps;
}

const ShowButton = (props: IShowButtonProps) => {
  return (
    <Link {...props.linkProps}>
      <IconButton {...props.buttonProps}>
        <VisibilityIcon sx={{ fontSize: 16 }} />
      </IconButton>
    </Link>
  );
};

export default ShowButton;