import {CookieHelper} from '../helpers/CookieHelper';

/**
 * Cookie helper
 */

const REACT_APP_COOKIE_TOKEN = (process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_COOKIE_TOKEN : window?._env_?.REACT_APP_COOKIE_TOKEN ?? process.env.REACT_APP_COOKIE_TOKEN);

export class CookieRepository {
  private static token = REACT_APP_COOKIE_TOKEN;

  /**
   * Set new jwt token cookie
   *
   * @param token User jwt token
   */
  public static setToken(token: string): void {
    CookieHelper.set(this.token, token);
  }

  /**
   * Remove jwt token cookie
   */
  public static removeToken(): void {
    CookieHelper.delete(this.token);
  }

  /**
   * Get cookie jwt token
   */
  public static getToken(): string | null {
    return CookieHelper.get(this.token);
  }
}
