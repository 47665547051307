import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IShowCertificateLoader } from '../../../http/account/AccountLoaders';
import CertificateShowCard from '../../../Widgets/Certificates/Operations/CertificateShowCard';

const CertificateShow = () => {
  const { certificate } = useLoaderData() as IShowCertificateLoader;

  return (
    <Await resolve={certificate}>
      <CertificateShowCard />
    </Await>
  );
};

export default CertificateShow;
