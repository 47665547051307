import React, { Dispatch } from 'react';
import FormSelect from '../../../../../../UI/Form/Select/FormSelect';
import { IRule } from '../../../../../../types/app/fraud/Rule';
import { getRuleName } from '../../../../../../utils/fraudUtils';
import { IFraudSettingCreateDataset } from '../../FraudMonitoringRuleSettingCreateForm';

interface IFraudCreateFormStepOneProps {
  rules: IRule[];

  dataset: IFraudSettingCreateDataset;
  dispatchDataset: Dispatch<IFraudSettingCreateDataset>;
}

const FraudCreateFormStepStart = (props: IFraudCreateFormStepOneProps) => {
  return (
    <FormSelect
      singletweaks={{
        unselect: true,
      }}
      onAfterClearAll={() => {
        props.dispatchDataset({
          ...props.dataset, rule: null
        });
      }}
      selectProps={{
        value: props.dataset.rule?.id ?? '',
        onChange: e => props.dispatchDataset({
          ...props.dataset, rule: props.rules.find(rule => rule.id === +e.target.value),
        }),
      }}
      title="Выберите правило"
      typography="h2"
      items={props.rules.map(rule => ({
        key: rule.id,
        label: getRuleName(rule.name),
      }))}
    />
  );
};

export default FraudCreateFormStepStart;
