import React from 'react';
import FormGridAlone from '../../../UI/Form/Grid/FormGridAlone';
import {Box, BoxProps, Card, CardProps, GridProps} from '@mui/material';
import FormGridFull from '../../../UI/Form/Grid/FormGridFull';

interface IFormCardProps {
  gridProps?: GridProps,
  boxProps?: BoxProps,
  cardProps?: CardProps,
  children?: React.ReactNode,
  template?: 'full' | 'alone'
}

const FormCard = (props: IFormCardProps) => {
  const content = (
    <Box width="100%" {...props.boxProps}>
      <Card sx={{ padding: '50px 50px'}} {...props.cardProps}>{props.children}</Card>
    </Box>
  );

  if (!props.template || props.template === 'alone') {
    return (
      <FormGridAlone {...props.gridProps}>
        {content}
      </FormGridAlone>
    );
  }

  return (<FormGridFull {...props.gridProps}>{content}</FormGridFull>);
};

export default FormCard;