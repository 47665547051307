export enum Messages {
  REQUIRED = 'Поле обязательно для заполнения',
  EMAIL = 'Введите корректный адрес электронной почты',
  REGEXP = 'Поле не соответствует заданному шаблону',
  SAME = 'Поле должно совпадать со значением поля ',
  MIN = 'Допустимое минимальное количество символов - ',
  GREATER_THAN = 'Значение должно быть больше, чем - ',
  LESS_THAN = 'Значение должно быть меньше, чем - ',
  MAX = 'Допустимое максимальное количество символов - ',
  DIGITS = 'Поле должно содержать как минимум 1 цифру',
  CHARACTERS = 'Поле должно содержать как минимум 1 спецсимвол - !$#%',
  LOWERCASE = 'Поле должно содержать как минимум 1 букву в нижнем регистре',
  UPPERCASE = 'Поле должно содержать как минимум 1 букву в верхнем регистре',
  TEXT = 'Поле не может быть числом',
  TRUE = 'Поле должно быть использовано',
  DATE_FROM_NOW = 'Поле содержит устаревшую дату',
  LATINONLY = 'Поле должно содержать только символы латиницы',
}

