import React from 'react';
import Tabs from '../../../UI/Tab/Tabs';
import TabPanel from '../../../UI/Tab/TabPanel';
import ReportsTabContentMain from '../../../Widgets/Reports/ReportsTab/ReportsTabContentMain';
import AutoReportsTabContentMain from '../../../Widgets/Reports/AutoReportsTab/AutoReportsTabContentMain';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';


const ReportsIndex = () => {
  return (
    <ContentContainer title="Отчеты">
      <Tabs
        items={[
          { title: 'Реестр отчетов', tabIndex: 0 },
          { title: 'Правила формирования автоматических отчетов', tabIndex: 1 },
        ]}
      />

      <TabPanel index={0} width={95}>
        <ReportsTabContentMain/>
      </TabPanel>

      <TabPanel index={1} width={95}>
        <AutoReportsTabContentMain />
      </TabPanel>

    </ContentContainer>
  );
};


export default ReportsIndex;
