import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';
import { IGetRoleLoader } from '../../../http/auth/AuthLoaders';
import RoleShowForm from '../../../Widgets/Common/Tab/RoleTabs/Operations/RoleShowForm';

const PartnerRoleShow = () => {
  const { role } = useLoaderData() as IGetRoleLoader;

  return (
    <Await resolve={role}>
      <RoleShowForm />
    </Await>
  );
};

export default PartnerRoleShow;
