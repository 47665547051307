import DashboardLayout from '../../layout/DashboardLayout';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { RouteList } from '../../http/RouteList';
import { useAuthMiddleware } from '../../hooks/useAuthMiddleware';
import GuardLoader from '../../Widgets/Common/Loader/GuardLoader';
import PageLoader from '../../Widgets/Common/Loader/PageLoader';

function Index() {
  const { isAuthorized, isUserLoaded } = useAuthMiddleware();

  if (isUserLoaded && !isAuthorized) {
    return <PageLoader />;
  }

  if (!isAuthorized) {
    return <Navigate to={RouteList.AUTH_LOGIN} />;
  }

  if (isAuthorized) {
    return <DashboardLayout />;
  }

  return <GuardLoader />;
}

export default Index;
