import React, {useContext, useEffect, useState} from 'react';
import MainForm from '../../../../Common/Form/MainForm';
import {RouteList} from '../../../../../http/RouteList';
import {Box, List, ListItemText, Typography} from '@mui/material';
import Divider from '../../../../../UI/Delimiter/Divider';
import FormActions from '../../../../../UI/Form/FormActions';
import {IRuleSetting} from '../../../../../types/app/fraud/RuleSetting';
import {FormContext} from '../../../../../store/context/form/FormContext';
import FormSwitch from '../../../../../UI/Form/FormSwitch';
import {useNotifications} from '../../../../../hooks/useNotifications';
import {messages} from '../../../../../notifications/messages';
import {IPartner} from '../../../../../types/app/partner/Partner';
import PageLoader from '../../../../Common/Loader/PageLoader';
import {PartnerService} from '../../../../../http/partner/PartnerService';
import {IPartnerTerminal} from '../../../../../types/app/partner/PartnerTerminal';
import {isUndefined} from 'lodash';
import EmptyCell from '../../../../../UI/Table/EmptyCell';
import CircleIcon from '@mui/icons-material/Circle';
import {getRuleName} from '../../../../../utils/fraudUtils';

interface IFraudFormProps {
  children: React.ReactNode;
  settings: IRuleSetting;
}

const FraudUpdateForm = (props: IFraudFormProps) => {
  const {setConfig, config} = useContext(FormContext);

  const {success, error} = useNotifications();

  const [partner, setPartner] = useState<IPartner>();
  const [partnerTerminals, setPartnerTerminals] = useState<IPartnerTerminal[]>();

  useEffect(() => {
    setConfig({
      readonly: true,
      data: [{field: 'rule_setting_id', value: props.settings.id}],
      action: RouteList.FRAUD_MONITORING_RULE_SETTING_SHOW + '/' + props.settings.id,

      afterSubmitHandler: formResponse => {

        formResponse?.status && setConfig({...config, readonly: true})

        formResponse?.status
          ? success(messages.success.fraudSettingUpdated)
          : error(messages.errors.fraudSettingUpdateError)
      }
    });

    if (props.settings.partnerId) {
      PartnerService.getPartnerById({id: props.settings.partnerId}).then(response => setPartner(response));
      PartnerService.getPartnerTerminals({partner_id: props.settings.partnerId}).then(response =>
        setPartnerTerminals(
          response.filter(terminal => {
            return props.settings.ruleSettingPartnerTerminals.some(item => item.partnerTerminalId === terminal.id);
          })
        )
      );
    }
  }, [props.settings]);

  if (props.settings.partnerId && (isUndefined(partner) || isUndefined(partnerTerminals))) {
    return <PageLoader top={40}/>;
  }

  return (
    <MainForm back={RouteList.FRAUD_MONITORING}>
      <Box>
        <Typography variant="body2" color="grey.600" mb={1}>
          Наименование правила:
        </Typography>
        <Typography variant="h1">{getRuleName(props.settings.rule.name)}</Typography>
      </Box>
      <Divider/>
      {props.settings.rule.name !== 'referral_fraud' &&
          <>
            <Box>
              <Typography variant="body2" color="grey.600" mb={1}>
                Партнер:
              </Typography>
              <Typography variant="subtitle2">{partner?.label ?? <EmptyCell/>}</Typography>
            </Box>

            <Divider/>

            <Box>
              <Typography variant="body2" color="grey.600" mb={1}>
                Терминальные точки:
              </Typography>
              {!partner ? (
                <EmptyCell/>
              ) : (
                <List>
                  {partnerTerminals.length === 0 ? (
                    <EmptyCell/>
                  ) : (
                    <>
                      {partnerTerminals.map(terminal => (
                        <ListItemText key={terminal.id}>
                          <Typography variant="body1">
                            <CircleIcon sx={{fontSize: 8, mr: 1}}/>
                            {terminal.name}
                          </Typography>
                        </ListItemText>
                      ))}
                    </>
                  )}
                </List>
              )}
            </Box>

            <Divider/>
          </>
      }

      {props.children}

      <Divider/>

      <FormSwitch title="Действующая настройка"
                  switchProps={{
                    defaultChecked: props.settings.isActive,
                    name: 'is_active'}}
      />
      <FormActions />
    </MainForm>
  );
};

export default FraudUpdateForm;
