import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import PromoCreateForm from '../../../Widgets/Billing/BillingTab/Operations/Promo/PromoCreateForm';
import { IPromoCreateLoader } from '../../../http/billing/BillingLoaders';

const PromoCreate = () => {
  const { content } = useLoaderData() as { content: Promise<IPromoCreateLoader> };

  return (
    <Await resolve={content}>
      <PromoCreateForm />
    </Await>
  );
};

export default PromoCreate;