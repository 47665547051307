import React from 'react';
import {Box, Button, Grid} from '@mui/material';
import FilterGroupButtons from '../../../UI/Button/FilterGroupButtons';
import {IFilterable} from '../../../types/app/common/Filterable';
import {RouteList} from '../../../http/RouteList';
import {useNavigate} from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PerPage from '../../../UI/Table/PerPage';

const AutoReportsTabDataTweaks = (props: IFilterable) => {
  const route = useNavigate();

  return (
    <Box mb={3} width="100%">
      <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Grid item>
          <Button onClick={() => route(RouteList.REPORTS_RULES_CREATE)} endIcon={<AccessTimeIcon/>}>
            Запланировать отчет
          </Button>
        </Grid>
        <Grid item>
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>
        <Grid item>
          <PerPage/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AutoReportsTabDataTweaks;
