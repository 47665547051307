import { ISelectable } from "../types/app/common/Selectable"

export enum CertificateTypeCodes {
  NOMINAL,
  PRODUCT
}

export namespace CertificateTypeCodes {
  export const asSelectable = (): ISelectable[] => {
    return [
      {key: CertificateTypeCodes.NOMINAL, label: nameByCode(CertificateTypeCodes.NOMINAL)},
      {key: CertificateTypeCodes.PRODUCT, label: nameByCode(CertificateTypeCodes.PRODUCT)},
    ]
  }
}

export const nameByCode = (typeCode: CertificateTypeCodes): string => {
  if (typeCode === CertificateTypeCodes.NOMINAL) {
    return 'Номинальный'
  }

  if (typeCode === CertificateTypeCodes.PRODUCT) {
    return 'Товарный'
  }

  return 'Не известно'
}


