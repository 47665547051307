import {RouteList} from './RouteList';
import {createBrowserRouter} from 'react-router-dom';
import DashboardIndex from '../Pages/Private/Dashboard/DashboardIndex';
import React from 'react';
import RequisiteIndex from '../Pages/Private/Requisites/RequisiteIndex';
import {AccountLoaders} from './account/AccountLoaders';
import {AccountActions} from './account/AccountActions';
import {ClientLoaders} from './client/ClientLoaders';
import {ClientActions} from './client/ClientActions';
import {BillingLoaders} from './billing/BillingLoaders';
import {BillingActions} from './billing/BillingActions';
import UserCreate from '../Pages/Private/Users/UserCreate';
import {AuthActions} from './auth/AuthActions';
import Login from '../Pages/Public/Login';
import BillingIndex from '../Pages/Private/Billing/BillingIndex';
import UserIndex from '../Pages/Private/Users/UserIndex';
import {AuthLoaders} from './auth/AuthLoaders';
import App from '../App';
import ClientIndex from '../Pages/Private/Clients/ClientIndex';
import PrivateIndex from '../Pages/Private/Index';
import PublicIndex from '../Pages/Public/Index';
import {MixedLoaders} from './mixed/MixedLoaders';
import UserShow from '../Pages/Private/Users/UserShow';
import ClientCreate from '../Pages/Private/Clients/ClientCreate';
import ClientShow from '../Pages/Private/Clients/ClientShow';
import ClientRankShow from '../Pages/Private/Clients/ClientRankShow';
import TariffCreate from '../Pages/Private/Billing/TariffCreate';
import TariffShow from '../Pages/Private/Billing/TariffShow';
import CertificateShow from '../Pages/Private/Certificates/CertificateShow';
import {ShopLoaders} from './shop/ShopLoaders';
import BankingIndex from '../Pages/Private/Banking/BankingIndex';
import OrderShow from '../Pages/Private/Orders/OrderShow';
import BankingUserShow from '../Pages/Private/Banking/BankingUserShow';
import {CatalogLoaders} from './catalog/CatalogLoaders';
import {CatalogActions} from './catalog/CatalogActions';
import CatalogCategoryCreate from '../Pages/Private/Catalog/CatalogCategoryCreate';
import CatalogCategoryShow from '../Pages/Private/Catalog/CatalogCategoryShow';
import CatalogIndex from '../Pages/Private/Catalog/CatalogIndex';
import CatalogShow from '../Pages/Private/Catalog/CatalogShow';
import NotificationIndex from '../Pages/Private/Notifications/NotificationIndex';
import NotificationTemplateShow from '../Pages/Private/Notifications/NotificationTemplateShow';
import {CommunicationActions} from './communication/CommunicationActions';
import {CommunicationLoaders} from './communication/CommunicationLoaders';
import FraudMonitoringIndex from '../Pages/Private/FraudMonitoring/FraudMonitoringIndex';
import UserRoleCreate from '../Pages/Private/Users/UserRoleCreate';
import PartnerRoleCreate from '../Pages/Private/Partners/PartnerRoleCreate';
import UserRoleShow from '../Pages/Private/Users/UserRoleShow';
import PartnerRoleShow from '../Pages/Private/Partners/PartnerRoleShow';
import ClientRankCreate from '../Widgets/Clients/ClientRanksTab/Operations/ClientRankCreate';
import ReportsIndex from '../Pages/Private/Reports/ReportsIndex';
import {ReportLoaders} from './reports/ReportLoaders';
import ReportsShow from '../Pages/Private/Reports/ReportsShow';
import {ReportsActions} from './reports/ReportsActions';
import ReportCreateIndex from '../Pages/Private/Reports/ReportCreateIndex';
import ReportRulesShowIndex from '../Pages/Private/Reports/ReportRulesShowIndex';
import ReportRulesCreateIndex from '../Pages/Private/Reports/ReportRulesCreateIndex';
import CatalogCreate from '../Pages/Private/Catalog/CatalogCreate';
import FraudMonitoringReportShow from '../Pages/Private/FraudMonitoring/FraudMonitoringReportShow';
import FraudMonitoringRuleSettingShow from '../Pages/Private/FraudMonitoring/FraudMonitoringRuleSettingShow';
import {FraudActions} from './fraud/FraudActions';
import PromoCreate from '../Pages/Private/Billing/PromoCreate';
import FraudMonitoringRuleSettingCreate from '../Pages/Private/FraudMonitoring/FraudMonitoringRuleSettingCreate';
import {FraudLoaders} from './fraud/FraudLoaders';
import PromoShow from '../Pages/Private/Billing/PromoShow';
import NotificationAntispamCreate from '../Pages/Private/Notifications/NotificationAntispamCreate';
import NotificationAntispamShow from '../Pages/Private/Notifications/NotificationAntispamShow';
import NotificationNewsCreate from '../Pages/Private/Notifications/NotificationNewsCreate';
import NotificationNewsShow from '../Pages/Private/Notifications/NotificationNewsShow';
import ChangePassword from '../Pages/Public/ChangePassword/ChangePassword';
import ForgotPassword from '../Pages/Public/ForgotPassword/ForgotPassword';
import OrdersIndex from '../Widgets/Orders/OrdersIndex';
import ErrorBoundary from './errorBoundary/ErrorBoundary';
import NotificationTemplateCreate from '../Pages/Private/Notifications/NotificationTemplateCreate';
import CertificateTabContentMain from '../Widgets/Certificates/CertificateTabContentMain';
import CertificateCreate from '../Pages/Private/Certificates/CertificateCreate';
import {CertificateLoaders} from './certificates/CertificateLoaders';
import BankingPartnerShow from '../Pages/Private/Banking/BankingPartnerShow';

export const routes = createBrowserRouter([
  {
    element: <App />,
    loader: AuthLoaders.authenticateLoader,
    errorElement: <ErrorBoundary />,
    shouldRevalidate: () => false,
    children: [
      {
        element: <PrivateIndex />,
        path: RouteList.DASHBOARD,
        children: [
          {
            index: true,
            element: <DashboardIndex />,
            loader: MixedLoaders.getDashboardInfo,
          },
          {
            path: RouteList.ORDERS,
            element: <OrdersIndex />,
          },
          {
            path: RouteList.ORDERS_SHOW + '/:id',
            element: <OrderShow />,
            loader: ShopLoaders.getOrder,
          },
          {
            path: RouteList.REPORTS,
            element: <ReportsIndex />,
          },
          {
            path: RouteList.REPORTS_SHOW + '/:id',
            element: <ReportsShow />,
            loader: ReportLoaders.getReport,
          },
          {
            path: RouteList.REPORTS_CREATE,
            element: <ReportCreateIndex />,
            loader: ReportLoaders.getDataForNewReport,
            action: ReportsActions.createReportAction
          },
          {
            path: RouteList.REPORTS_RULES_SHOW + '/:id',
            element: <ReportRulesShowIndex />,
            loader: ReportLoaders.getReportRule,
          },
          {
            path: RouteList.REPORTS_RULES_CREATE,
            element: <ReportRulesCreateIndex />,
            loader: ReportLoaders.getDataForNewReport,
            action: ReportsActions.createReportRulesAction
          },
          {
            path: RouteList.REQUISITES,
            element: <RequisiteIndex />,
            loader: AccountLoaders.getRequisitesLoader,
            action: AccountActions.setPropertyRouteAction,
          },
          {
            path: RouteList.BANKING,
            element: <BankingIndex />,
          },
          {
            path: RouteList.BANKING_USER_SHOW + '/:id',
            element: <BankingUserShow />,
            loader: AccountLoaders.showUserBankAccountLoader,
          },
          {
            path: RouteList.CLIENTS,
            element: <ClientIndex />,
          },
          {
            path: RouteList.CLIENTS_CREATE,
            element: <ClientCreate />,
            action: AuthActions.createUserAction,
          },
          {
            path: RouteList.CLIENTS_SHOW + '/:id',
            element: <ClientShow />,
            loader: ClientLoaders.getClient,
            action: ClientActions.updateClient,
          },
          {
            path: RouteList.CLIENTS_RANK_SHOW + '/:id',
            element: <ClientRankShow />,
            loader: ClientLoaders.getClientRank,
            action: ClientActions.updateClientRank,
          },
          {
            path: RouteList.CLIENTS_RANK_CREATE,
            element: <ClientRankCreate/>,
            action: ClientActions.addClientRank,
          },
          {
            path: RouteList.BILLING,
            element: <BillingIndex />,
            action: BillingActions.storeSettings,
          },
          {
            path: RouteList.BILLING_CREATE_TARIFF,
            element: <TariffCreate />,
            loader: BillingLoaders.createTariffLoader,
            action: BillingActions.createTariffRouteAction,
          },
          {
            path: RouteList.BILLING_CREATE_PROMO,
            element: <PromoCreate />,
            loader: BillingLoaders.createPromoLoader,
            action: BillingActions.createPromoRouteAction,
          },
          {
            path: RouteList.BILLING_SHOW_PROMO + '/:id',
            element: <PromoShow />,
            loader: BillingLoaders.showPromoLoader,
            action: BillingActions.updatePromoRouteAction,
          },
          {
            path: RouteList.BILLING_SHOW_TARIFF + '/:id',
            element: <TariffShow />,
            loader: BillingLoaders.showTariffLoader,
            action: BillingActions.updateTariffRouteAction,
          },
          {
            path: RouteList.CERTIFICATES,
            element: <CertificateTabContentMain />,
          },
          {
            path: RouteList.CREATE_CERTIFICATE,
            element: <CertificateCreate />,
            loader: CertificateLoaders.getCertificateData,
            action: AccountActions.createCertificatesAction,
            shouldRevalidate: () => false,
          },
          {
            path: RouteList.SHOW_CERTIFICATE + '/:id',
            element: <CertificateShow />,
            loader: AccountLoaders.showCertificateLoader,
            action: AccountActions.certificateChangeUser,
          },
          {
            path: RouteList.USERS,
            element: <UserIndex />,
          },
          {
            path: RouteList.USERS_CREATE,
            element: <UserCreate />,
            loader: AuthLoaders.getRolesLoader,
            action: AuthActions.createUserAction,
          },
          {
            path: RouteList.USERS_SHOW + '/:email',
            element: <UserShow />,
            loader: AuthLoaders.getUserWithRolesLoader,
            action: AuthActions.updateUserAction,
          },
          {
            path: RouteList.USERS_ROLE_CREATE,
            element: <UserRoleCreate />,
            loader: AuthLoaders.getOperatorSectionsLoader,
            action: AuthActions.createOperatorRoleAction,
          },
          {
            path: RouteList.USERS_ROLE_SHOW + '/:id',
            element: <UserRoleShow />,
            loader: AuthLoaders.getRoleLoader,
            action: AuthActions.updateOperatorRoleAction,
          },
          {
            path: RouteList.PARTNERS_ROLE_CREATE,
            element: <PartnerRoleCreate />,
            loader: AuthLoaders.getPartnerSectionsLoader,
            action: AuthActions.createPartnerRoleAction,
          },
          {
            path: RouteList.PARTNERS_ROLE_SHOW + '/:id',
            element: <PartnerRoleShow />,
            loader: AuthLoaders.getRoleLoader,
            action: AuthActions.updatePartnerRoleAction,
          },
          {
            path: RouteList.FRAUD_MONITORING,
            element: <FraudMonitoringIndex />,
          },
          {
            path: RouteList.FRAUD_MONITORING_REPORT_SHOW + '/:id',
            element: <FraudMonitoringReportShow />,
          },
          {
            path: RouteList.FRAUD_MONITORING_RULE_SETTING_CREATE,
            element: <FraudMonitoringRuleSettingCreate />,
            action: FraudActions.createSettings,
            loader: FraudLoaders.getRuleSettings,
          },
          {
            path: RouteList.FRAUD_MONITORING_RULE_SETTING_SHOW + '/:id',
            element: <FraudMonitoringRuleSettingShow />,
            action: FraudActions.updateSetting,
            loader: FraudLoaders.getRuleSetting,
          },
          {
            path: RouteList.NOTIFICATIONS,
            element: <NotificationIndex />,
          },
          {
            path: RouteList.NOTIFICATIONS_TEMPLATE_CREATE,
            element: <NotificationTemplateCreate />,
            action: CommunicationActions.createTemplate,
          },

          {
            path: RouteList.NOTIFICATIONS_TEMPLATE_SHOW + '/:id',
            element: <NotificationTemplateShow />,
            action: CommunicationActions.updateTemplate,
            loader: CommunicationLoaders.getTemplateLoader,
          },
          {
            path: RouteList.NOTIFICATIONS_ANTISPAM_CREATE,
            element: <NotificationAntispamCreate />,
            action: CommunicationActions.createAntispam,
            loader: CommunicationLoaders.getDataForNewAntispamRuleLoader,
          },
          {
            path: RouteList.NOTIFICATIONS_ANTISPAM_SHOW + '/:id',
            element: <NotificationAntispamShow />,
            action: CommunicationActions.updateAntispam,
            loader: CommunicationLoaders.getAntispamLoader,
          },
          {
            path: RouteList.NOTIFICATIONS_NEWS_CREATE,
            element: <NotificationNewsCreate />,
            loader: CommunicationLoaders.createNewsLoader,
            action: CommunicationActions.createNews,
          },
          {
            path: RouteList.NOTIFICATIONS_NEWS_SHOW + '/:id',
            element: <NotificationNewsShow />,
            loader: CommunicationLoaders.showNewsLoader,
            action: CommunicationActions.updateNews,
          },
          {
            path: RouteList.CATALOG,
            element: <CatalogIndex />,
            shouldRevalidate: () => false,
          },
          {
            path: RouteList.CATALOG_CREATE,
            element: <CatalogCreate />,
            loader: CatalogLoaders.getDataForNewProductLoader,
            action: CatalogActions.createProduct,
          },
          {
            path: RouteList.CATALOG_SHOW + '/:id',
            element: <CatalogShow />,
            loader: CatalogLoaders.getProductLoader,
            action: CatalogActions.updateProductAction,
          },
          {
            path: RouteList.CATALOG_CATEGORY_SHOW + '/:id',
            element: <CatalogCategoryShow />,
            loader: CatalogLoaders.getCategoriesLoader,
            action: CatalogActions.updateCategoryAction,
          },
          {
            path: RouteList.CATALOG_CATEGORY_CREATE,
            element: <CatalogCategoryCreate />,
            action: CatalogActions.createCategoryAction,
          },
        ],
      },
      {
        element: <PublicIndex />,
        children: [
          {
            path: RouteList.AUTH_LOGIN,
            element: <Login />,
            action: AuthActions.loginAction,
          },
          {
            path: RouteList.AUTH_FORGOT_PASSWORD,
            element: <ForgotPassword />,
            action: AuthActions.forgotPasswordAction,
          },
          {
            path: RouteList.AUTH_CHANGE_PASSWORD,
            element: <ChangePassword />,
            action: AuthActions.changePasswordAction,
          },
        ],
      },
    ],
  },
]);
