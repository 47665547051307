import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { IFraudSettingCreateDataset } from '../../../FraudMonitoringRuleSettingCreateForm';
import { Alert, Box, Grid } from '@mui/material';
import FormSwitch from '../../../../../../../UI/Form/FormSwitch';
import { IPartner } from '../../../../../../../types/app/partner/Partner';
import { PartnerService } from '../../../../../../../http/partner/PartnerService';
import CircularProgress from '@mui/material/CircularProgress';
import { FraudRuleReportIds } from '../../../../../../../enums/FraudRules';
import { IPartnerTerminal } from '../../../../../../../types/app/partner/PartnerTerminal';
import Divider from '../../../../../../../UI/Delimiter/Divider';
import FormSelect from '../../../../../../../UI/Form/Select/FormSelect';
import { ISelectable } from '../../../../../../../types/app/common/Selectable';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface IFraudCreateFormStepFinishProps {
  dataset: IFraudSettingCreateDataset;
  dispatchDataset: Dispatch<IFraudSettingCreateDataset>;
}

const CommonFraudCreateFormStepFinish = (props: IFraudCreateFormStepFinishProps) => {
  const [partners, setPartners] = useState<IPartner[]>(null);
  const [terminals, setTerminals] = useState<IPartnerTerminal[]>(null);

  useEffect(() => {
    props.dispatchDataset({
      ...props.dataset,
      partner: null,
      terminals: {},
    });

    PartnerService.getPartners().then(response => setPartners(response));
    PartnerService.getPartnerTerminals().then(response => setTerminals(response));
  }, []);

  const partnerTerminals = useMemo((): ISelectable[] => {
    if (!terminals) {
      return null;
    }

    if (!props.dataset.partner) {
      return [];
    }

    return terminals
      .filter(terminal => terminal.partnerId === props.dataset.partner.key)
      .map(terminal => ({ key: terminal.id, label: terminal.name }));
  }, [props.dataset.partner, terminals]);

  if (!partners || !partnerTerminals) {
    return (
      <Grid minHeight={200} container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box minHeight={200}>
      <FormSelect
        singletweaks={{
          unselect: true,
        }}
        onAfterClearAll={() => {
          props.dispatchDataset({
            ...props.dataset,
            partner: null,
            terminals: {},
          });
        }}
        title="Партнер"
        items={partners}
        selectProps={{
          value: props.dataset.partner?.key ?? '',
          onChange: e => {
            props.dispatchDataset({
              ...props.dataset,
              partner: partners.find(partner => partner.key === +e.target.value),
              terminals: {},
            });
          },
        }}
      />

      <Divider />

      <Box>
        {partnerTerminals.length !== 0 ? (
          <FormSelect
            selectProps={{
              multiple: true,
              disabled: props.dataset.rule.id === FraudRuleReportIds.SALES_FRAUD && props.dataset.partner === null,
              value: props.dataset.terminals[props.dataset.partner.key],
              onChange: e => {
                props.dispatchDataset({
                  ...props.dataset,
                  terminals: props.dataset.partner ? { [props.dataset.partner.key]: e.target.value as number[] } : {},
                });
              },
            }}
            title="Терминальные точки"
            items={partnerTerminals}
          />
        ) : (
          <Alert
            sx={{ padding: '5px' }}
            color={props.dataset.partner === null ? 'warning' : 'error'}
            icon={<WarningAmberIcon sx={{ fontSize: 20 }} />}
          >
            {props.dataset.partner !== null
              ? 'Для данного партнера терминальных точек не обнаружено'
              : 'Для выбора терминальных точек опеределите партнера'}
          </Alert>
        )}

        <Divider />
      </Box>

      <FormSwitch
        title="Активировать новую настройку сейчас"
        switchProps={{
          checked: props.dataset.isActive,
          onChange: () => {
            props.dispatchDataset({
              ...props.dataset,
              isActive: !props.dataset.isActive,
            })
          }
        }}
      />
    </Box>
  );
};

export default CommonFraudCreateFormStepFinish;
