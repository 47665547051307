import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useAsyncValue, useNavigate} from 'react-router-dom';
import MainForm from '../../Common/Form/MainForm';
import {RouteList} from '../../../http/RouteList';
import FormSelect from '../../../UI/Form/Select/FormSelect';
import Divider from '../../../UI/Delimiter/Divider';
import {Box, Typography} from '@mui/material';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import FormDatePicker from '../../../UI/Pickers/FormDatePicker';
import FormSwitch from '../../../UI/Form/FormSwitch';
import {FormContext, IFormConfig, TFieldRule} from '../../../store/context/form/FormContext';
import FormActions from '../../../UI/Form/FormActions';
import {messages} from '../../../notifications/messages';
import FormErrorText from '../../../UI/Form/FormErrorText';
import {useNotifications} from '../../../hooks/useNotifications';
import {CertificateTypeCodes} from '../../../enums/CertificateTypes';
import {CertificateCategories} from '../../../enums/CertificateCategories';
import {IGetCertificateLoaderData} from '../../../http/certificates/CertificateLoaders';
import {ProductStatuses} from '../../../enums/ProductStatuses';

const CertificateCreateForm = () => {
  const {categories, products} = useAsyncValue() as IGetCertificateLoaderData;
  const {setConfig, errorWatcher, softReset} = useContext(FormContext);
  const message = useNotifications();

  const route = useNavigate();

  const [certificateType, setCertificateType] = useState<CertificateTypeCodes>(CertificateTypeCodes.PRODUCT);
  const [certificateCategory, setCertificateCategory] = useState<CertificateCategories>(CertificateCategories.BEARER);
  const [nominalAmount, setNominalAmount] = useState<number | string>('');

  const [productError, setProductError] = useState<string>('');

  const isProductsChecked = useRef(false);

  const getRules = useCallback(() => {
    const rules: TFieldRule = {};

    if (certificateCategory === CertificateCategories.USER) {
      rules['user_email'] = ['required', 'email'];
    }

    if (certificateType === CertificateTypeCodes.NOMINAL) {
      rules['amount'] = ['required', 'greaterThan:0'];
    }

    if (certificateType === CertificateTypeCodes.PRODUCT) {
      rules['products'] = ['required'];
    }

    return rules;
  }, [certificateType, certificateCategory]);

  const formConfig: IFormConfig = {
    action: RouteList.CREATE_CERTIFICATE,
    data: [
      {field: 'productData', value: JSON.stringify(products)},
      {field: 'categoryData', value: JSON.stringify(categories)},
    ],
    rules: getRules(),
    beforeValidationHandler: () => {
      if (certificateType === CertificateTypeCodes.PRODUCT) {
        if (!isProductsChecked.current) {
          setProductError(messages.errors.certificateProductError);

          return false;
        }
      }

      return true;
    },
    afterSubmitHandler: (formResponse) => {

      if (formResponse.status) {
        message.success(messages.success.certificatesCreated);
        route(RouteList.SHOW_CERTIFICATE + '/' + formResponse.payload);
      }
      else {
        softReset();
        message.error(messages.errors.generalError);
      }
    }
  };

  useEffect(() => {
    setConfig(formConfig);
  }, []);

  useEffect(() => {
    isProductsChecked.current = false;
    softReset();
    setProductError('');

    setConfig(formConfig);
  }, [certificateType, certificateCategory]);

  return (
    <MainForm back={RouteList.CERTIFICATES}>
      <Typography mb={5} variant="h1">
        Эмиссия сертификата
      </Typography>

      <FormSelect
        title="Тип сертификата"
        items={CertificateCategories.asSelectable()}
        selectProps={{
          sx: {
            width: '50%',
          },
          value: certificateCategory,
          onChange: (e) => setCertificateCategory(e.target.value as CertificateCategories)
        }}
      />

      <Divider/>

      {certificateCategory === CertificateCategories.USER &&
        <Box>
          <FormOutlinedInput
            title="Email Пользователя"
            inputProps={{
              sx: {
                width: '50%',
              },
              name: 'user_email',
            }}
          />

          <Divider/>
        </Box>
      }

      <FormSelect
        title="Вид сертификата"
        items={CertificateTypeCodes.asSelectable()}
        selectProps={{
          sx: {
            width: '50%',
          },
          name: 'type',
          value: certificateType,
          onChange: (e) => setCertificateType(e.target.value as CertificateTypeCodes)
        }}
      />

      <Divider/>

      {certificateType === CertificateTypeCodes.NOMINAL &&
        <Box>
          <FormOutlinedInput
            title="Номинальная сумма сертификата (только цифры)"
            inputProps={{
              sx: {
                width: '50%',
              },
              name: 'amount',
              value: nominalAmount,
              onChange: e => {
                const value = e.target.value;

                if (value === '' || /^\d+(\.\d{0,2})?$/.test(value)) {
                  setNominalAmount(Number(value));
                }
              },
              type: 'text',
            }}
          />

          <Divider/>
        </Box>
      }

      <Box width="50%">
        <FormDatePicker
          title="Срок годности"
          fieldProps={{
            name: 'expires_date',
          }}
          datepickerProps={{
            disablePast: true,
          }}
          nullable
        />
      </Box>

      <Divider/>

      <FormSwitch
        title="Возможность доплаты по товару/услуге баллами"
        switchProps={{
          name: 'is_mix',
        }}
      />

      <Divider/>

      {certificateType === CertificateTypeCodes.NOMINAL &&
        <>
          <FormSwitch
            title="Возможность частичного использования сертификата"
            switchProps={{
              name: 'is_part',
            }}
          />

          <Divider/>
        </>
      }

      {certificateType === CertificateTypeCodes.NOMINAL &&
        <>
          <FormSelect
            multipletweaks={{
              selectAll: true,
              unselectAll: true,
            }}
            title="Категории товаров/услуг, которые можно погасить сертификатом"
            items={categories}
            selectProps={{
              multiple: true,
              name: 'categories',
            }}
          />

          <Divider/>
        </>
      }

      <FormSelect
        multipletweaks={{
          selectAll: certificateType === CertificateTypeCodes.NOMINAL,
          unselectAll: certificateType === CertificateTypeCodes.NOMINAL,
        }}
        singletweaks={{
          unselect: certificateType === CertificateTypeCodes.PRODUCT,
        }}
        title={certificateType === CertificateTypeCodes.NOMINAL ? 'Товары, которые можно погасить сертификатом' : 'Товар, который можно погасить сертификатом'}
        items={
          products
            .filter((product) => product.productStatusId === ProductStatuses.PUBLISHED && product.byCertificate)
            .map((product) => ({key: product.id, label: product.name}))
        }
        beforeOnchange={(e) => {
          isProductsChecked.current = !!e.target.value;

          setProductError('');

          errorWatcher('products', e.target.value);
        }}
        onAfterClearAll={() => {
          isProductsChecked.current = false;

          errorWatcher('products', '');
        }}
        selectProps={{
          error: !!productError,
          name: 'products',
          multiple: certificateType === CertificateTypeCodes.NOMINAL,
        }}
      />

      {!!productError && <FormErrorText error={productError}/>}

      <Divider/>

      <FormActions/>

    </MainForm>
  );
};

export default CertificateCreateForm;