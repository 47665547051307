import React, { useState } from 'react';
import { useAsyncValue, useNavigate } from 'react-router-dom';
import { ITariffShowLoader } from '../../../../../http/billing/BillingLoaders';
import { RouteList } from '../../../../../http/RouteList';
import {Alert, AlertTitle, Box, Card, Grid, IconButton, Typography} from '@mui/material';
import { TariffTypes } from '../../../../../enums/TariffTypes';
import Divider from '../../../../../UI/Delimiter/Divider';
import FormSelect from '../../../../../UI/Form/Select/FormSelect';
import FormOutlinedInput from '../../../../../UI/Form/FormOutlinedInput';
import FormTimePicker from '../../../../../UI/Pickers/FormTimePicker';
import FormSwitch from '../../../../../UI/Form/FormSwitch';
import FormDatePicker from '../../../../../UI/Pickers/FormDatePicker';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import BackButton from '../../../../../UI/Button/BackButton';
import { useFormik } from 'formik';
import { DateHelper } from '../../../../../helpers/DateHelper';
import { themeConfig } from '../../../../../layout/assets/config/themeConfig';
import { BillingActions } from '../../../../../http/billing/BillingActions';
import { useNotifications } from '../../../../../hooks/useNotifications';
import { messages } from '../../../../../notifications/messages';
import CloseIcon from '@mui/icons-material/Close';

export interface CreateTariffFormValues {
  name: string;
  bonus: boolean;
  exclusivity: boolean;
  active: boolean;
  tariff_type: TariffTypes;
  amount: string;
  percent: string;
  products_ids: number[];
  categories_ids: number[];
  partners_ids: number[];
  priority: string;
  valid_from: string;
  valid_to: string;
  valid_from_hour: string;
  valid_to_hour: string;
  comment: string;
  lifetime_days: string;
}

const TariffCreateForm = () => {
  const message = useNotifications();
  const { products, categories, partners } = useAsyncValue() as ITariffShowLoader;

  const [type, setType] = useState<TariffTypes>(TariffTypes.AMOUNT);
  const [percentOrAmount, setPercentOrAmount] = useState<number | string>(0);
  const [lifetimeDays, setLifetimeDays] = useState<number>(0);

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [percentOrAmountInfo, setPercentOrAmountInfo] = useState<string | null>(null);

  const route = useNavigate();

  const dateIsLessThanCurrentError = () => {
    message.error(messages.errors.wrongDateInThePastChosen);
  };

  const endDateIsLessThanStartError = () => {
    message.error(messages.errors.wrongEndDateChosen);
  };

  const initialValues: CreateTariffFormValues = {
    name: '',
    bonus: false,
    exclusivity: false,
    active: true,
    tariff_type: TariffTypes.AMOUNT,
    amount: '',
    percent: '',
    products_ids: [],
    categories_ids: [],
    partners_ids: [],
    priority: '',
    valid_from: '',
    valid_to: '',
    valid_from_hour: '',
    valid_to_hour: '',
    comment: '',
    lifetime_days: '',
  };

  const onSubmit = async (values: CreateTariffFormValues) => {
    try {
      const response = await BillingActions.createTariffRouteAction({ request: values });

      if (response.error) {
        return message.error(response.error);
      }

      message.success(messages.success.tariffCreated);

      route(RouteList.BILLING_SHOW_TARIFF + '/' + response.payload);
    } catch {
      message.error(messages.errors.generalError);
    }
  };

  const validate = values => {
    let errors: Partial<CreateTariffFormValues> = {};

    if (!values.name) {
      errors.name = 'Поле обязательно для заполнения';
    }

    if (!values.comment) {
      errors.comment = 'Поле обязательно для заполнения';
    }

    return errors;
  };

  const formik = useFormik<CreateTariffFormValues>({
    initialValues,
    onSubmit,
    validate,
  });
  const getBonusOrAmountForm = () => {
    let title = '';
    let name = '';
    let placeholder = '';

    switch (type) {
      case TariffTypes.AMOUNT:
        title = 'Размер бонуса';
        name = 'amount';
        placeholder = 'Введите размер бонуса';
        break;
      case TariffTypes.PERCENT:
        title = 'Размер скидки (процент)';
        name = 'percent';
        placeholder = 'Введите размер скидки (процент)';
        break;
      case TariffTypes.CASHBACK:
        title = 'Размер вознаграждения (процент)';
        name = 'percent';
        placeholder = 'Введите размер вознаграждения (процент)';
        break;
      default:
        break;
    }

    return (
      <>
        <FormOutlinedInput
          title={title}
          inputProps={{
            name,
            placeholder,
            value: percentOrAmount,
            onBlur: e => {
              const value = e.target.value;

              if (value.endsWith('.')) {
                setPercentOrAmount(value.substring(0, value.length - 1))
              }
            },
            onChange: e => {
              formik.handleChange(e);

              setPercentOrAmountInfo(null);

              const value = e.target.value;

              if (value === '') {
                setPercentOrAmount(0);

                return;
              }

              if (value.startsWith('0')) {
                const newValue = +value.slice(-1);

                if (!isNaN(newValue)) {
                  setPercentOrAmount(newValue);

                  return;
                }

                setPercentOrAmount(0);

                return;
              }

              if (type !== TariffTypes.AMOUNT) {
                const regexp = /^\d{1,}(\.\d{0,2})?$/;

                if (value.match(regexp)) {
                  setPercentOrAmount(value);
                } else {
                  setPercentOrAmountInfo('Поле может содержать только числа. Для выделения дробных частей используйте точку, не более 2 символов.');
                }
              } else {
                const amount = +value;

                if (!isNaN(amount)) {
                  setPercentOrAmount(Number(value));
                }
              }
            },
            sx: {
              width: '50%',
            },
          }}
        />
        {percentOrAmountInfo && <Alert severity="info" onClose={() => setPercentOrAmountInfo(null)}>
          <AlertTitle>Подсказка!</AlertTitle>
          {percentOrAmountInfo}
        </Alert>}
      </>
    );
  };

  return (
    <Box sx={{ width: '60%' }}>
      <BackButton />
      <Card sx={{ padding: 5 }}>
        <form>
          <Typography variant="h1" mb={5}>
            Добавление тарифа
          </Typography>

          <FormOutlinedInput
            title="Наименование тарифа"
            inputProps={{
              error: !!formik.touched.name && !!formik.errors.name,
              name: 'name',
              placeholder: 'Введите наименование тарифа',
              ...formik.getFieldProps('name'),
            }}
          />
          {formik.touched.name && formik.errors.name && (
            <Typography color={themeConfig.palette.error.main} variant="body2">
              {formik.errors.name}
            </Typography>
          )}
          <Divider />

          <Typography marginBottom={1} variant="body2" color="grey.700">
            Настройки тарифа:
          </Typography>

          <FormSwitch
            title="Исключительность"
            switchProps={{
              name: 'exclusivity',
              ...formik.getFieldProps('exclusivity'),
            }}
          />
          <FormSwitch
            title="Действующий"
            switchProps={{
              name: 'active',
              defaultChecked: true,
              ...formik.getFieldProps('active'),
            }}
          />

          <Divider />

          <FormSelect
            items={[
              { key: TariffTypes.AMOUNT, label: 'Бонус' },
              { key: TariffTypes.PERCENT, label: 'Скидка' },
              { key: TariffTypes.CASHBACK, label: 'Вознаграждение за покупку' },
            ]}
            title="Тип тарифа"
            selectProps={{
              name: 'tariff_type',
              value: type,
              onChange: e => {
                formik.handleChange(e);
                formik.setFieldValue('amount', 0);
                formik.setFieldValue('percent', 0);
                setType(e.target.value as TariffTypes);
                setPercentOrAmount(0);
              },
              sx: {
                width: '50%',
              },
            }}
          />

          <Divider />

          {getBonusOrAmountForm()}

          <Divider />

          {type === TariffTypes.AMOUNT || type === TariffTypes.CASHBACK ? (
            <>
              <FormOutlinedInput
                title="Срок действия баллов"
                inputProps={{
                  name: 'lifetime_days',
                  value: lifetimeDays,
                  onChange: e => {
                    formik.handleChange(e);
                    setLifetimeDays(+e.target.value);
                  },
                  sx: {
                    width: '50%',
                  },
                }}
                rule={e => !isNaN(Number(e.target.value))}
              />
              <Divider />
            </>
          ) : null}

          <FormSelect
            title="Товары, к которым применяется тариф"
            items={products.map(product => ({ key: product.id, label: product.name }))}
            selectProps={{
              name: 'products_ids',
              multiple: true,
              ...formik.getFieldProps('products_ids'),
            }}
            multipletweaks={{
              selectAll: true,
              unselectAll: true,
              selectAllItemsAction: () =>
                formik.setFieldValue(
                  'products_ids',
                  products.map(product => product.id)
                ),
              removeAllItemsAction: () => formik.setFieldValue('products_ids', []),
            }}
          />

          <Divider />

          <FormSelect
            title="Категории, к которым применяется тариф"
            items={categories.map(category => ({ key: category.key, label: category.label }))}
            selectProps={{
              name: 'categories_ids',
              multiple: true,
              ...formik.getFieldProps('categories_ids'),
            }}
            multipletweaks={{
              selectAll: true,
              unselectAll: true,
              selectAllItemsAction: () =>
                formik.setFieldValue(
                  'categories_ids',
                  categories.map(category => category.key)
                ),
              removeAllItemsAction: () => formik.setFieldValue('categories_ids', []),
            }}
          />

          <Divider />

          <FormSelect
            title="Партнеры, к которым применяется тариф"
            items={partners.map(partner => ({ key: partner.key, label: partner.label }))}
            selectProps={{
              name: 'partners_ids',
              multiple: true,
              ...formik.getFieldProps('partners_ids'),
            }}
            multipletweaks={{
              selectAll: true,
              unselectAll: true,
              selectAllItemsAction: () =>
                formik.setFieldValue(
                  'products_ids',
                  partners.map(partner => partner.key)
                ),
              removeAllItemsAction: () => formik.setFieldValue('products_ids', []),
            }}
          />

          <Divider />

          <FormOutlinedInput
            title="Приоритет"
            inputProps={{
              name: 'priority',
              placeholder: 'Только цифры',
              value: formik.values.priority,
              onChange: e => {
                formik.handleChange(e);
              },
              sx: {
                width: '50%',
              },
            }}
            rule={e => !isNaN(Number(e.target.value))}
          />

          <Divider />

          <Grid container justifyContent="space-between" columns={2} spacing={1}>
            <Grid item xs={1} position="relative">
              <Typography marginBottom={1} variant="body2" color="grey.700">
                Начало действия тарифа:
              </Typography>

              <FormDatePicker
                datepickerProps={{
                  name: 'valid_from',
                  disablePast: true,
                  value: formik.values.valid_from,
                  onChange: e => {
                    const date = {
                      target: {
                        name: 'valid_from',
                        value: moment(e._d).format('YYYY-MM-DD'),
                      },
                      type: 'change',
                    };

                    if (e.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                      dateIsLessThanCurrentError();
                    } else if (moment(dateTo).format('YYYY-MM-DD') < e.format('YYYY-MM-DD')) {
                      endDateIsLessThanStartError();
                    } else {
                      formik.handleChange(date);
                      setDateFrom(e);
                    }
                  },
                }}
              />

              {formik.values.valid_from && formik.values.valid_from !=='' && (
                <Box position="absolute" top={35} right={35}>
                  <IconButton
                    onClick={() => {
                      formik.setFieldValue('valid_from', null)
                      formik.setFieldValue('valid_to', null)
                    }}
                    color="error"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Grid>

            <Grid item xs={1}  position="relative">
              <Typography marginBottom={1} variant="body2" color="grey.700">
                Завершение действия тарифа:
              </Typography>

              <FormDatePicker
                datepickerProps={{
                  disabled: !formik.values.valid_from || formik.values.valid_from === '',
                  name: 'valid_to',
                  value: formik.values.valid_to,
                  disablePast: true,
                  minDate: formik.values.valid_from,
                  onChange: e => {
                    const date = {
                      target: {
                        name: 'valid_to',
                        value: moment(e._d).format('YYYY-MM-DD'),
                      },
                      type: 'change',
                    };

                    if (e.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
                      dateIsLessThanCurrentError();
                    } else if (moment(dateFrom).format('YYYY-MM-DD') > e.format('YYYY-MM-DD')) {
                      endDateIsLessThanStartError();
                    } else {
                      formik.handleChange(date);
                      setDateTo(e);
                    }
                  },
                }}
              />

              {formik.values.valid_to && formik.values.valid_to !=='' && (
                <Box position="absolute" top={35} right={35}>
                  <IconButton
                    onClick={() => {
                      formik.setFieldValue('valid_to', null)
                    }}
                    color="error"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Grid>
          </Grid>

          <Divider />

          <Grid container justifyContent="space-between" columns={2} spacing={1}>
            <Grid item xs={1}>
              <Typography marginBottom={1} variant="body2" color="grey.700">
                Начало интервала действия тарифа:
              </Typography>

              <FormTimePicker
                fieldProps={{
                  name: 'valid_from_hour',
                  value: formik.values.valid_from_hour,
                }}
                timepickerProps={{
                  onChange: e => {
                    const modifiedEvent = {
                      target: {
                        name: 'valid_from_hour',
                        value: DateHelper.getPickerValue(e),
                      },
                      type: 'change',
                    };
                    formik.handleChange(modifiedEvent);
                  },

                  views: ['hours'],
                }}
              />
            </Grid>

            <Grid item xs={1}>
              <Typography marginBottom={1} variant="body2" color="grey.700">
                Завершение интервала действия тарифа:
              </Typography>

              <FormTimePicker
                fieldProps={{
                  name: 'valid_to_hour',
                  value: formik.values.valid_to_hour,
                }}
                timepickerProps={{
                  onChange: e => {
                    const modifiedEvent = {
                      target: {
                        name: 'valid_to_hour',
                        value: DateHelper.getPickerValue(e),
                      },
                      type: 'change',
                    };
                    formik.handleChange(modifiedEvent);
                  },

                  views: ['hours'],
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <Grid container flexDirection="column">
            <Grid item xs>
              <Typography variant="body2" color="grey.700" mb={1}>
                Комментарий:
              </Typography>
            </Grid>
            <Grid item xs>
              <FormOutlinedInput
                inputProps={{
                  error: !!formik.touched.comment && !!formik.errors.comment,
                  multiline: true,
                  rows: 4,
                  name: 'comment',
                  value: formik.values.comment,
                  onChange: formik.handleChange,
                }}
              />
            </Grid>
          </Grid>
          {formik.touched.comment && formik.errors.comment && (
            <Typography color={themeConfig.palette.error.main} variant="body2">
              {formik.errors.comment}
            </Typography>
          )}

          <LoadingButton form="router-form" type={'submit'} sx={{ mt: 5 }} onClick={formik.submitForm}>
            Сохранить
          </LoadingButton>
        </form>
      </Card>
    </Box>
  );
};

export default TariffCreateForm;
