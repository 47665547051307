import React, {Dispatch} from 'react';
import {IFraudSettingCreateDataset} from '../../../FraudMonitoringRuleSettingCreateForm';
import {FraudRuleReportIds} from '../../../../../../../enums/FraudRules';
import BigTransactionFraudCreateFormStepFinish from './BigTransactionFraudCreateFormStepFinish';
import CommonFraudCreateFormStepFinish from './CommonFraudCreateFormStepFinish';
import ReferralFraudCreateFormStepFinish from './ReferralFraudCreateFormStepFinish';

interface IFraudCreateFormStepFinishProps {
  dataset: IFraudSettingCreateDataset;
  dispatchDataset: Dispatch<IFraudSettingCreateDataset>;
}

const FraudCreateFormStepFinish = (props: IFraudCreateFormStepFinishProps) => {
  switch (props.dataset.rule.id) {
    case FraudRuleReportIds.BIG_TRANSACTION_FRAUD:
      return <BigTransactionFraudCreateFormStepFinish {...props} />;

    case FraudRuleReportIds.REFERRAL_FRAUD:
      return <ReferralFraudCreateFormStepFinish {...props} />;

    default:
      return <CommonFraudCreateFormStepFinish {...props} />;
  }
};

export default FraudCreateFormStepFinish;
