import React, { createContext, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useSubmit } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Validator } from './Validator';
import { Messages } from './enum/Messages';
import { Fields } from './enum/Fields';
import { IFormResponse } from './types/FormResponse';
import { IStorageFileData } from '../../rtk/reducers/storageSlice';
import { useNotifications } from '../../../hooks/useNotifications';
import { messages } from '../../../notifications/messages';
import moment from 'moment';

type TValidationMethod = string;
type TValidationErrorMessage = string;

export type TFieldRule = {
  [field: string]: TValidationMethod[];
};
export type TValidationError = {
  [field: string]: TValidationErrorMessage;
};
export type TDataValue = string | number | IStorageFileData[];
export type TFormData = {
  field: string;
  value: TDataValue;
};

export interface IFormConfig {
  rules?: TFieldRule | null;
  action?: string | null;
  readonly?: boolean;
  data?: TFormData[];
  beforeSubmitHandler?: (
    formData: FormData,
    config: IFormConfig,
    setConfig: React.Dispatch<React.SetStateAction<IFormConfig>>
  ) => void;
  afterSubmitHandler?: (
    formResponse: IFormResponse,
    config: IFormConfig,
    setConfig: React.Dispatch<React.SetStateAction<IFormConfig>>
  ) => void;
  beforeValidationHandler?: () => boolean,
}

export interface IFormContext {
  hardReset: () => void;
  softReset: () => void;
  config: IFormConfig;
  setConfig: React.Dispatch<React.SetStateAction<IFormConfig>>;
  isValid: boolean;
  errors: TValidationError;
  onSubmit: (form: HTMLFormElement) => void;
  errorWatcher: (field: string, newFieldValue: string) => void;
  isShowEditIcon?: boolean;
}

const initConfig: IFormConfig = {
  readonly: null,
  rules: null,
  action: null,
  data: [],
  beforeSubmitHandler: () => null,
  afterSubmitHandler: () => null,
  beforeValidationHandler: () => null,
};
export const formContextDefaultValue: IFormContext = {
  hardReset: () => {},
  softReset: () => {},
  config: initConfig,
  setConfig: () => {},
  isValid: true,
  errors: {},
  onSubmit: () => {},
  errorWatcher: () => null,
};
export const FormContext = createContext<IFormContext>(formContextDefaultValue);

export const FormContextProvider = ({ children }: { children: React.ReactNode }) => {
  const routerSubmit = useSubmit();

  /**
   * Recorded form data.
   */
  const [data, setData] = useState<FormData>(new FormData());

  /**
   * Pressing the submit button.
   */
  const [isPushed, setIsPushed] = useState<boolean>(false);

  /**
   * Validation error's.
   */
  const [errors, setErrors] = useState<TValidationError>({});

  /**
   * Is form data valid.
   */
  const [isValid, setIsValid] = useState<boolean>(true);

  /**
   * Form context configuration.
   */
  const [config, setConfig] = useState<IFormConfig>(initConfig);

  const { error } = useNotifications();

  useLayoutEffect(() => hardReset(), [window.location.href]);
  useEffect(() => softReset(), [config.readonly]);

  /**
   * Local form errors for optimize the performance of the re-render.
   */
  const formErrors: TValidationError = {};

  /**
   * The object for validating the current form.
   * Contains a general method and special validator wrappers.
   * Methods are accepted depending on the specified config.
   */
  const validation = {
    validate(formData: FormData) {
      for (const attrName in config.rules) {
        config.rules[attrName].forEach(method => {
          let params;

          const methodMatcher = method.match(/regex|min|max|greaterThan|lessThan/);

          if (methodMatcher) {
            params = method.replace(methodMatcher[0] + ':', '');
            method = methodMatcher[0];
          }

          if (method.startsWith('same')) {
            const field = method.replace('same:', '');

            params = { field: field, value: formData.get(field) as string };
            method = 'same';
          }

          const callable = mapper[method];

          if (!formErrors[attrName]) {
            const value = formData.get(attrName);

            if (!method.startsWith('required') || !method.startsWith('same')) {
              if (isFieldNotRequired(attrName, value as string)) {
                return;
              }
            }

            callable(attrName, value, params ?? null);
          }
        });
      }

      let isBeforeValidationValid = true;

      if (config.beforeValidationHandler) {
        isBeforeValidationValid = config.beforeValidationHandler();
      }

      if (!isEmpty(formErrors) || !isBeforeValidationValid) {
        setErrors(formErrors);
        setIsValid(false);
      } else {
        setIsValid(true);
        setErrors({});
      }
    },
    required(key: string, value: string) {
      if (Validator.required(key, value)) {
        return;
      }

      formErrors[key] = Messages.REQUIRED;
    },
    email(key: string, value: string) {
      if (Validator.email(key, value)) {
        return;
      }

      formErrors[key] = Messages.EMAIL;
    },
    regex(key: string, value: string, exp: string) {
      if (Validator.regex(key, value, exp)) {
        return;
      }

      formErrors[key] = Messages.REGEXP;
    },
    same(key: string, value: string, sameAs: { field: string; value: string }) {
      if (Validator.same(key, value, sameAs.value)) {
        return;
      }

      formErrors[key] = Messages.SAME + '"' + Fields[sameAs.field] + '"';
    },
    min(key: string, value: string, length: string) {
      if (Validator.min(key, value.replace(/[+)(._-]/g, ''), Number(length))) {
        return;
      }

      formErrors[key] = Messages.MIN + length;
    },
    lessThan(key: string, value: string, length: string) {
      if (Validator.lessThan(key, value.replace(/[+)(._-]/g, ''), Number(length))) {
        return;
      }

      formErrors[key] = Messages.LESS_THAN + length;
    },
    max(key: string, value: string, length: string) {
      if (Validator.max(key, value.replace(/[+)(._-]/g, ''), Number(length))) {
        return;
      }

      formErrors[key] = Messages.MAX + length;
    },
    greaterThan(key: string, value: string, length: string) {
      if (Validator.greaterThan(key, value.replace(/[+)(._-]/g, ''), Number(length))) {
        return;
      }

      formErrors[key] = Messages.GREATER_THAN + length;
    },
    digits(key: string, value: string) {
      if (Validator.digits(key, value)) {
        return;
      }

      formErrors[key] = Messages.DIGITS;
    },
    characters(key: string, value: string) {
      if (Validator.characters(key, value)) {
        return;
      }

      formErrors[key] = Messages.CHARACTERS;
    },
    lowercase(key: string, value: string) {
      if (Validator.lowercase(key, value)) {
        return;
      }

      formErrors[key] = Messages.LOWERCASE;
    },
    uppercase(key: string, value: string) {
      if (Validator.uppercase(key, value)) {
        return;
      }

      formErrors[key] = Messages.UPPERCASE;
    },
    text(key: string, value: string) {
      if (Validator.isNaN(value)) {
        return;
      }

      formErrors[key] = Messages.TEXT;
    },
    true(key: string, value: string) {
      if (Validator.isTruth(value)) {
        return;
      }

      formErrors[key] = Messages.TRUE
    },
    dateFromNow(key: string, value: string) {
      if (Validator.greaterThan(key, moment(value).unix(), moment(new Date()).unix())) {
        return;
      }

      formErrors[key] = Messages.DATE_FROM_NOW;
    },
    latinonly(key: string, value: string){
      if(Validator.latinonly(key, value)) {
        return;        
      }

      formErrors[key] = Messages.LATINONLY
    }
  };

  /**
   * Is current field is not required
   */
  const isFieldNotRequired = useCallback(
    (key: string, value: string) => {
      return value === '' && config.rules[key].indexOf('required') === -1;
    },
    [config.rules]
  );

  /**
   * Processing the form submission. Fires when the button is pressed.
   * Before sending data to the server, the specified validation rules are
   * checked in accordance with the configuration.
   *
   * @param form HTMLFormElement
   */
  const onSubmit = async (form: HTMLFormElement) => {
    const formData = new FormData(form);

    setIsPushed(true);
    setData(formData);

    validation.validate(formData);

    if (isEmpty(formErrors) && isValid) {
      if (config.beforeSubmitHandler) {
        config.beforeSubmitHandler(formData, config, setConfig);
      }

      if (config.data) {
        config.data.forEach(dataItem => {
          if (typeof dataItem.value === 'string' || typeof dataItem.value === 'number') {
            formData.set(dataItem.field, dataItem.value as string);
          } else {
            if (dataItem.value.length !== 0) {
              formData.append(dataItem.field, JSON.stringify(dataItem.value));
            }
          }
        });
      }

      routerSubmit(formData, {
        method: 'post',
        action: config.action ?? undefined,
      });
    } else {
      error(messages.errors.formValidationErrors);
    }
  };

  /**
   * Form context rules - validation rule callback's mapper.
   */
  const mapper: { [k: string]: Function } = {
    required: validation.required,
    email: validation.email,
    regex: validation.regex,
    same: validation.same,
    min: validation.min,
    max: validation.max,
    digits: validation.digits,
    characters: validation.characters,
    lowercase: validation.lowercase,
    uppercase: validation.uppercase,
    greaterThan: validation.greaterThan,
    lessThan: validation.lessThan,
    text: validation.text,
    true: validation.true,
    dateFromNow: validation.dateFromNow,
    latinonly: validation.latinonly,
  };

  /**
   * Hard reset form context.
   */
  const hardReset = () => {
    setIsValid(true);
    setErrors({});
    setIsPushed(false);
    setConfig(initConfig);
    setData(new FormData());
  };

  /**
   * Soft reset form context.
   */
  const softReset = () => {
    setIsValid(true);
    setErrors({});
    setIsPushed(false);
  };

  /**
   *  Change input handler - watcher.
   *
   * @param field
   * @param newFieldValue
   */
  const errorWatcher = (field: string, newFieldValue: string) => {
    if (!isPushed) {
      return;
    }

    const newFormData = Object.assign(data, {});

    newFormData.set(field, newFieldValue);

    validation.validate(newFormData);

    setData(newFormData);

    if ((field in formErrors) && config.beforeValidationHandler) {
      config.beforeValidationHandler();
    }
  };

  const defaultValue: IFormContext = {
    hardReset,
    softReset,
    config,
    errors,
    isValid,
    onSubmit,
    setConfig,
    setData,
    errorWatcher,
  } as IFormContext;

  return <FormContext.Provider value={defaultValue}>{children}</FormContext.Provider>;
};
