import { JsonRpcService } from '../JsonRpcService';
import { IResponse } from '../../types/response/common/Response';
import { IOrder } from '../../types/app/shop/Order';
import { ShopFactory } from './ShopFactory';
import {TOrder, TOrdersData} from '../../types/response/shop/OrdersData';
import { TOrderStatusesData } from '../../types/response/shop/OrderStatusesData';
import { IOrderStatus } from '../../types/app/shop/OrderStatus';
import { IGetOrderRequest } from '../../types/request/shop/GetOrderRequest';
import { TOrderData } from '../../types/response/shop/OrderData';
import { IOrderDetail } from '../../types/app/shop/OrderDetail';
import {IPaginatedResponse} from '../../types/response/common/PaginatedResponse';
import {IPaginated} from '../../types/app/common/Paginated';

export class ShopService {
  private static serviceName: string = 'shop';

  /**
   * Get orders from response
   */
  public static async getOrders(request?): Promise<IPaginated<IOrder[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TOrder[]>>(
      ShopService.serviceName,
      'get_orders',
      request
      );

    const items = ShopFactory.getOrdersFromResponseData(response.result.data.items)

    return {items, ...response.result.data.pagination};
  }

  /**
   * Get order by id
   *
   * @param {IGetOrderRequest} request
   */
  public static async getOrder(request: IGetOrderRequest): Promise<IOrderDetail> {
    const response = await JsonRpcService.request<IResponse<TOrderData>>(this.serviceName, 'get_order', request);

    return ShopFactory.getOrderItemFromResponseData(response.result.data);
  }

  /**
   * Get order statuses
   */
  public static async getOrderStatuses(): Promise<IOrderStatus[]> {
    const response = await JsonRpcService.request<IResponse<TOrderStatusesData>>(this.serviceName, 'get_order_statuses');

    return response.result.data.map(orderData => ({
      id: orderData.id,
      name: orderData.name,
      localName: orderData.local_name,
    }));
  }

  public static async cancelOrdersByProduct(request: {product_id: number}): Promise<void> {
    await JsonRpcService.request<IResponse<TOrdersData>>(this.serviceName, 'cancel_orders_by_product', request);
  }
}
