import React, {useMemo} from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider as MuiDivider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import { useAppSelector } from '../../../hooks/hooks';

interface IFilterDialogProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
  apply: () => void;
  disabled?: boolean;
}

const FilterDialog = (props: IFilterDialogProps) => {
  const filters = useAppSelector(state => state.serverConfig.filters);

  const isRangeFiltersValid = useMemo(() => {
    if (!filters) {
      return true;
    }
    
    if (!filters.to || !filters.from) {
      return true;
    }

    for (const key in filters.to) {
      const max = filters.to[key];
      const min = filters.from[key];

      if (min !== null && max !== null && min > max) {
        return false;
      }
    }

    return true;
  }, [filters]);

  const keyHandler = e => {
    if (e.key === 'Enter') {
      props.apply();
      props.close();
    }

    if (e.key === 'Escape') {
      props.close();
    }
  };

  return (
    <Dialog open={props.open} maxWidth="md" onKeyUp={keyHandler}>
      <Box padding="60px 100px" minWidth="850px">
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={props.close}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle position="relative">Фильтры</DialogTitle>

        <DialogContent>
          <Box mb={2}>
            <MuiDivider />
          </Box>

          {props.children}

          <DialogActions sx={{ padding: 0, mt: 3 }}>
            <Button
              disabled={!isRangeFiltersValid || props.disabled}
              onClick={() => {
                props.apply();
                props.close();
              }}
            >
              Применить
            </Button>
          </DialogActions>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default FilterDialog;
