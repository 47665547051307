import React from 'react';
import { Badge, Grid, SvgIcon } from '@mui/material';
import { ReactComponent as BellIcon } from '../../../icons/bell.svg';
import HeaderInfoButton from '../../../UI/Button/HeaderInfoButton';
import { useAppSelector } from '../../../hooks/hooks';
import { RouteList } from '../../../http/RouteList';
import { useUrlManager } from '../../../hooks/useUrlManager';

const HeaderInfo = () => {
  const user = useAppSelector(state => state.auth.user);
  const { tabHashingToUrl, go } = useUrlManager();

  const goToNotificationsTab = () => {
    tabHashingToUrl({ page: window.location.pathname, tabIndex: 2 });
    go(RouteList.NOTIFICATIONS);
  };

  if (!user.notification) {
    return <></>;
  }

  return (
    <Grid container justifyContent="flex-start">
      <Badge
        showZero
        sx={{
          '& > span': {
            color: 'white',
          },
        }}
        badgeContent={user.notification.unreadNoticeCount}
        color={user.notification.unreadNoticeCount === 0 ? 'secondary' : 'success'}
      >
        <HeaderInfoButton onClick={goToNotificationsTab}>
          <SvgIcon sx={{ fontSize: '22px' }} component={BellIcon} />
        </HeaderInfoButton>
      </Badge>
    </Grid>
  );
};

export default HeaderInfo;