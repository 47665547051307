import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetUserLoader } from '../../../http/auth/AuthLoaders';
import AccountShowForm from '../../../Widgets/Common/Tab/AccountTabs/Operations/AccountShowForm';

const UserShow = () => {
  const { userData } = useLoaderData() as IGetUserLoader;

  return (
    <Await resolve={userData}>
      <AccountShowForm />
    </Await>
  );
};

export default UserShow;
