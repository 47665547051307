import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';

import ReportRulesShowForm from '../../../Widgets/Reports/ReportsTab/Operations/ReportRulesShowForm';
import {IGetDataForReportRuleLoader} from '../../../http/reports/ReportLoaders';

const ReportRulesShowIndex = () => {
  const {report} = useLoaderData() as { report: Promise<IGetDataForReportRuleLoader> };

  return (
    <Await resolve={report}>
      <ReportRulesShowForm/>
    </Await>
  );
};

export default ReportRulesShowIndex;
