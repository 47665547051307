import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';
import {IGetDataForNewReportLoader} from '../../../http/reports/ReportLoaders';
import ReportCreateForm from '../../../Widgets/Reports/ReportsTab/Operations/ReportCreateForm';

const ReportCreateIndex = () => {
  const { content } = useLoaderData() as {content: Promise<IGetDataForNewReportLoader>};

  return (
    <Await resolve={content}>
      <ReportCreateForm />
    </Await>
      );
};

export default ReportCreateIndex;
