import React, {useEffect, useState} from 'react';
import {IRuleReport, IRuleReportDataReferral} from '../../../../types/app/fraud/RuleReport';
import {Box, Card, Grid, Typography} from '@mui/material';
import BackButton from '../../../../UI/Button/BackButton';
import Divider from '../../../../UI/Delimiter/Divider';
import {useFraudDataset} from '../hooks/useFraudDataset';
import TypographyLoader from '../../../Common/Loader/TypographyLoader';
import EmptyCell from '../../../../UI/Table/EmptyCell';
import {FraudSettingConditionNameEnum, FraudSettingConditionNames} from '../../../../enums/FraudRules';
import {TPersonal} from '../../../../types/app/client/Personal';
import {IIdentificationVirtualCard} from '../../../../types/app/account/Identification';
import {IClient} from '../../../../types/app/client/Client';
import moment, {unix} from 'moment';
import {getRuleName} from '../../../../utils/fraudUtils';

const ReferralFraudShowCard = (props: IRuleReport) => {
  const [personalData, setPersonalData] = useState<TPersonal>();
  const [card, setCard] = useState<IIdentificationVirtualCard>();
  const [client, setClient] = useState<IClient>();

  const ruleData = props.data as IRuleReportDataReferral;
  const {clients} = useFraudDataset({
    userIds: [props.userId],
  });

  useEffect(() => {
    if (clients) {
      setPersonalData(clients[0]?.personalData ?? null);
      setCard(clients[0]?.card ?? null);
      setClient(clients[0]);
    }
  }, [clients]);
  return (
    <Box>
      <BackButton />

      <Card sx={{padding: '70px 50px', width: '85%'}}>
        <Box>
          <Typography mb={1} variant="body2" color="grey.600">
            Наименование правила:
          </Typography>
          <Typography variant="h2">{getRuleName(props.ruleSetting.rule.name)}</Typography>
        </Box>

        <Divider/>

        <Grid container columns={4} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              {FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.REFERRALS_COUNT_MAX)}:
            </Typography>
            <Typography>{ruleData.referralsCountMax ?? <EmptyCell/>}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Фактическое количество рефералов:
            </Typography>
            <Typography>{ruleData.referralsCount ?? <EmptyCell/>}</Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Период отслеживания:
            </Typography>
            {ruleData.startPeriodDate && ruleData.endPeriodDate
              ? <Grid container display="flex">
                <Typography>{moment.unix(ruleData.startPeriodDate).format('DD.MM.YYYY')}</Typography>
                <Typography mr={1} ml={1}>-</Typography>
                <Typography>{moment.unix(ruleData.endPeriodDate).format('DD.MM.YYYY')}</Typography>
              </Grid>
              : <EmptyCell/>
            }

          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Дата и время срабатывания правила:
            </Typography>
            <Typography>{unix(props.createdAt).format('DD.MM.YYYY HH:mm')}</Typography>
          </Grid>
        </Grid>

        <Divider/>

        <Grid container columns={4} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              ФИО Пользователя:
            </Typography>
            <TypographyLoader object={personalData} index="fullName"/>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Телефон Пользователя:
            </Typography>
            <TypographyLoader object={personalData} index="phone"/>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Email Пользователя:
            </Typography>
            <TypographyLoader object={client} index="email"/>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Номер виртуальной карты Пользователя:
            </Typography>
            <TypographyLoader object={card} index="number"/>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default ReferralFraudShowCard;