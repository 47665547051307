import { defer } from 'react-router-dom';
import { AccountService } from './AccountService';
import { ServerObjectNames } from '../../enums/ServerObjectNames';
import { IPropertyRequest } from '../../types/request/account/PropertyRequest';
import { IRequisites } from '../../types/app/account/Requisites';
import { AccountProperties } from '../../enums/AccountProperties';
import { ICertificate } from '../../types/app/account/Certificate';
import { IPartnerBankAccount } from '../../types/app/account/PartnerBankAccount';
import { IUserBankAccount } from '../../types/app/account/UserBankAccount';
import { IInvoicesBankAccount } from '../../types/app/account/InvoicesBankAccount';
import { UserTypes } from '../../enums/UserTypes';

export interface IGetRequisitesLoader {
  requisites: IRequisites;
}

export interface IShowCertificateLoader {
  certificate: ICertificate;
}

export interface IGetUserBankAccountLoader {
  bankAccount: IUserBankAccount;
}

export interface IGetPartnerBankAccountLoader {
  bankAccount: IPartnerBankAccount;
}

export interface IShowPartnerBankInvoicesLoader {
  bankAccount: IInvoicesBankAccount;
}

export class AccountLoaders {
  /**
   * Get user bank account
   *
   * @param params
   */
  public static async showUserBankAccountLoader({ params }) {
    return defer({
      bankAccount: AccountService.getBalancesByExtId({ user_ext_id: params.id, balance_user_type: UserTypes.USER }),
    });
  }

  /**
   * Get partner bank account
   *
   * @param params
   */
  public static async showPartnerBankAccountLoader({ params }) {
    return defer({
      bankAccount: AccountService.getBalancesByExtId({ user_ext_id: params.id, balance_user_type: UserTypes.PARTNER }),
    });
  }

  /**
   * Get partner bank invoices
   *
   * @param params
   */
  public static async showPartnerBankInvoicesLoader({ params }) {
    return defer({
      bankAccount: AccountService.invoicesGetOne({ id: params.id }),
    });
  }

  /**
   * Get certificate
   *
   * @param params
   */
  public static async showCertificateLoader({ params }) {
    return defer({
      certificate: AccountService.getCertificate({ id_or_code: params.id }),
    });
  }

  /**
   * Get operator requisites
   */
  public static async getRequisitesLoader() {
    const request: IPropertyRequest = {
      property: AccountProperties.REQUISITES,
      obj_name: ServerObjectNames.COMMON,
    };

    return defer({
      requisites: AccountService.getProperty(request),
    });
  }
}
