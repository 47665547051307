import * as React from 'react';
import {Box} from '@mui/material';
import {ReactComponent as Icon} from '../../../icons/referrals/user-icon.svg'

type StructureReferralsNodeProps = {
  name: string
}

const StructureReferralsNode = ({ name }: StructureReferralsNodeProps) => {  
  return (
    <Box
      className="node"
    >
      <Icon width={54}/>
      <Box>{name}</Box>
    </Box>
  );
};

export default StructureReferralsNode;
