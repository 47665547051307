import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { AccountService } from '../../../http/account/AccountService';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import UserBankAccountsTabContentData from './UserBankAccountsTabContentData';
import TabLoader from '../../Common/Loader/TabLoader';
import TabTitle from '../../../UI/Tab/TabTitle';
import UserBankAccountsTabFilters from './UserBankAccountsTabFilters';
import UserBankAccountsTabDataTweaks from './UserBankAccountsTabDataTweaks';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import { IClientBankAccount } from '../../../types/app/account/ClientBankAccount';
import { unix } from 'moment';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilters: IFilters = {
  like: {
    searchByEmail: '',
    byUserId: '',
  },
  from: {
    createdAt: null,
    updatedAt: null,
    pointsBlock: null,
    points: null,
  },
  to: {
    createdAt: null,
    updatedAt: null,
    pointsBlock: null,
    points: null,
  },
};

const UserBankAccountsTabContentMain = () => {
  const {
    items,
    setFilters,
    getPaginatable,
    getDialogFilterable,
    getFilterable,
    setServerMethod,
    setMutator,
  } = useTweakTool<IClientBankAccount>();

  useEffect(() => {
    setMutator(params => {
        if (params.created_at_end) {
          params.created_at_end = unix(params.created_at_end).format('YYYY-MM-DD');
        }

        if (params.created_at_start) {
          params.created_at_start = unix(params.created_at_start).format('YYYY-MM-DD');
        }

        if (params.updated_at_start) {
          params.updated_at_start = unix(params.updated_at_start).format('YYYY-MM-DD');
        }

        if (params.updated_at_end) {
          params.updated_at_end = unix(params.updated_at_end).format('YYYY-MM-DD');
        }

        if (params.by_user_id) {
          params.by_user_id = +params.by_user_id;
        }

        return params;
    });
    setServerMethod(AccountService.getClientBankAccounts);
    setFilters(defaultFilters);
  }, []);

  if (!items) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="Управление счетами Пользователей" />

      <UserBankAccountsTabFilters {...getDialogFilterable} />

      <UserBankAccountsTabDataTweaks {...getFilterable} />

      <Divider />

      <UserBankAccountsTabContentData {...getPaginatable} />
    </Box>
  );
};

export default UserBankAccountsTabContentMain;
