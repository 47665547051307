export enum ReportPeriodTypes {
  DAY = 'day',
  WEEK = 'week',
  MONTH= 'month',
  YEAR= 'year',
}

export enum ReportPeriodTypeNames {
  DAY = 'день',
  WEEK = 'неделя',
  MONTH= 'месяц',
  YEAR= 'год',
}

export const getReportPeriodName = (periodType: ReportPeriodTypes): ReportPeriodTypeNames =>{
  switch (periodType) {
    case ReportPeriodTypes.DAY: return ReportPeriodTypeNames.DAY
    case ReportPeriodTypes.WEEK: return ReportPeriodTypeNames.WEEK
    case ReportPeriodTypes.MONTH: return ReportPeriodTypeNames.MONTH
    case ReportPeriodTypes.YEAR: return ReportPeriodTypeNames.YEAR

    default: return
  }
}

export const getPeriodTypeId = (type: ReportPeriodTypes): number => {
  switch (type) {
    case ReportPeriodTypes.DAY: return 0
    case ReportPeriodTypes.WEEK: return 1
    case ReportPeriodTypes.MONTH: return 2
    case ReportPeriodTypes.YEAR: return 3

    default: return
  }
}

export const getPeriodType = (periodValueId: number): ReportPeriodTypes => {
  switch (periodValueId) {
    case 0: return ReportPeriodTypes.DAY
    case 1: return ReportPeriodTypes.WEEK
    case 2: return ReportPeriodTypes.MONTH
    case 3: return ReportPeriodTypes.YEAR

    default: return
  }
}

export const getReportPeriodValueName = (periodValueId: number): string => {
  switch (periodValueId) {
    case 0: return 'дней'
    case 1: return 'недель'
    case 2: return 'месяцев'
    case 3: return 'лет'

    default: return
  }
}


export const getReportPeriodTypeNames = () => {

  return [
    {
      key: 0,
      label: ReportPeriodTypeNames.DAY
    },
    {
      key: 1,
      label: ReportPeriodTypeNames.WEEK
    },
    {
      key: 2,
      label: ReportPeriodTypeNames.MONTH
    },
    {
      key: 3,
      label: ReportPeriodTypeNames.YEAR
    },
  ]
}