export class InternalError extends Error {
  message = 'Произошла ошибка удаленного сервера, перезагрузите страницу или попробуйте позднее.';
}

export class MessageNotFound extends Error {
  message = 'Сообщение не найдено.';
}

export class StoreTemplateFailed extends Error {
  message = 'Не удалось сохранить новый шаблон. Ошибка удаленного сервера.'
}

export class WebNotificationsMarkAsReadAllBeforeThisError extends Error {
  message = "Неверный адрес электронной почты для повторной отправки сертификата."
}
