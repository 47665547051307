import React, {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import PerPage from '../../../UI/Table/PerPage';
import FilterGroupButtons from '../../../UI/Button/FilterGroupButtons';
import {IFilterable} from '../../../types/app/common/Filterable';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useNavigate} from 'react-router-dom';
import {RouteList} from '../../../http/RouteList';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import {AccountService} from "../../../http/account/AccountService";
import {useNotifications} from '../../../hooks/useNotifications';
import {messages} from '../../../notifications/messages';
import CircularProgress from '@mui/material/CircularProgress';
import {FileHelper} from '../../../helpers/FileHelper';
import CloseIcon from '@mui/icons-material/Close';

const ClientsTabDataTweaks = (props: IFilterable) => {
  const route = useNavigate();
  const {error, success, warning} = useNotifications();

  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [warnings, setWarnings] = useState<string[]>([])

  const onUploadBalances = async () => {
    setIsDownloading(true)

    try {
      window.location.href = await AccountService.downloadBalances()
    }
    catch {
      error(messages.errors.errorUploadingBalance)
    }
    finally {
      setIsDownloading(false)
    }
  }

  const onDownloadBalances = async () => {
    setIsUploading(true)

    try {
      openFileDialog()
    }
    catch (e) {
      console.error(e)
      error(messages.errors.errorDownloadingBalance)
    }
    finally {
      setIsUploading(false)
    }
  }

  function openFileDialog() {
    const $input = document.createElement('input')

    $input.type = 'file'
    $input.accept = ".csv, text/csv"
    $input.onchange = async () => {
      setIsUploading(true)

      const file = $input.files.item(0)
      const fileData = await FileHelper.readFile(file) as string

      try {
        const response = await AccountService.uploadBalances(FileHelper.base64RequestFormat(fileData))

        if (response.status) {
          if (response.warnings) {
            setWarnings(response.warnings)
            warning(messages.warnings.warningUploadingBalance)
          }
          else {
            success(messages.success.successUploadingBalance)
          }
        }
        else {
          throw Error()
        }
      }
      catch {
        error(messages.errors.errorDownloadingBalance)
      }
      finally {
        setIsUploading(false)
      }
    }

    $input.click()
  }

  const onCloseDialog = () => {
    setWarnings([])
  }

  return (
    <Box mb={3}>
      <Dialog open={warnings.length !== 0} maxWidth="md">
        <Box padding="5rem 3rem" minWidth="850px">
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={onCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle position="relative">Отчет о загрузке</DialogTitle>

          <DialogContent>
            <Box mb={2}>
              <MuiDivider />
            </Box>

            <Box>{warnings.map(warning => <Typography lineHeight={2}>{warning}</Typography>)}</Box>
          </DialogContent>
        </Box>
      </Dialog>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container gap={1}>
            <Button onClick={() => route(RouteList.CLIENTS_CREATE)} endIcon={<AccountCircleIcon />}>
              Добавить Пользователя
            </Button>

            <Button
              onClick={onUploadBalances}
              endIcon={isDownloading ? <CircularProgress size={20} /> : <UploadIcon />}
              disabled={isDownloading}
            >
              Выгрузить баланс
            </Button>

            <Button
              onClick={onDownloadBalances}
              endIcon={isUploading ? <CircularProgress size={20} /> : <DownloadIcon />}
              disabled={isUploading}
            >
              Загрузить баланс
            </Button>
          </Grid>
        </Grid>
        <Grid item >
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>
        <Grid item >
          <PerPage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientsTabDataTweaks;
