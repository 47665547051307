const REACT_APP_COOKIE_DOMAIN = (process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_COOKIE_DOMAIN : window?._env_?.REACT_APP_COOKIE_DOMAIN ?? process.env.REACT_APP_COOKIE_DOMAIN);

export class CookieHelper {
  /**
   * Get cookie by key
   *
   * @param key Cookie key
   */
  public static get(key: string): any {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);

    if (parts.length === 2) {
      const value = parts.pop()?.split(';').shift();

      try {
        return JSON.parse(value!);
      } catch (e) {
        return value;
      }
    }

    return null;
  }

  /**
   * Set new cookie
   *
   * @param key Cookie key
   * @param value Jsonable data
   * @param maxAge Cookie ttl
   */
  public static set(key: string, value: any, maxAge = 3600) {
    if (typeof value !== 'string') {
      value = JSON.stringify(value);
    }

    document.cookie = `${key}=${value};domain=${REACT_APP_COOKIE_DOMAIN};path=/;max-age=${maxAge}`;
  }

  /**
   * Delete cookie by key
   *
   * @param key Cookie key
   */
  public static delete(key: string) {
    this.set(key, '', 0);
  }
}
