import { FormDataHelper } from '../../helpers/FormDataHelper';
import { StorageService } from '../storage/StorageService';
import { StorageTypes } from '../../enums/StorageTypes';
import { now } from 'moment';
import { IStorageFileData } from '../../store/rtk/reducers/storageSlice';
import { ICreateTemplateRequest } from '../../types/request/communication/CreateTemplateRequest';
import { v4 as uuid } from 'uuid';
import { CommunicationService } from './CommunicationService';
import { IFormResponse } from '../../store/context/form/types/FormResponse';
import { FileHelper } from '../../helpers/FileHelper';
import { IUpdateTemplateRequest } from '../../types/request/communication/UpdateTemplateRequest';
import { UrlHelper } from '../../helpers/UrlHelper';
import { ICreateAntispamRequest } from '../../types/request/communication/CreateAntispamRequest';
import { Switchable } from '../../types/app/common/Switchable';
import { ICreateNewsRequest } from '../../types/request/communication/CreateNewsRequest';
import { PeriodicityType } from '../../enums/PeriodicityType';
import { IUpdateNewsRequest } from '../../types/request/communication/UpdateNewsRequest';
import { ICreateAntispamFormData } from "../../types/request/communication/CreateAntispamFormData";
import { IStorageUploadRequest } from '../../types/request/storage/StorageUploadRequest';

export class CommunicationActions {
  public static async updateTemplate({ request }): Promise<IFormResponse> {
    const formData = FormDataHelper.toObject<{
      id: string;
      name: string;
      header: string;
      category: string;
      short: string;
      long: string;
      file?: string;
      icon?: string;
    }>(await request.formData());

    let url;

    if (UrlHelper.isValidUrl(formData.icon)) {
      url = formData.icon;
    } else {
      const file: IStorageFileData[] = JSON.parse(formData.file);

      try {
        const response = await StorageService.upload({
          type: StorageTypes.IMAGES,
          id: now().toString(),
          filename: file[0].fileName,
          data: FileHelper.base64RequestFormat(file[0].data),
        });

        url = response.url;
      }
      catch (e) {
        return {
          status: false,
          error: e.message,
        }
      }
    }

    const updateRequest: IUpdateTemplateRequest = {
      id: Number(formData.id),
      name: formData.name,
      web: {
        icon: url,
        category: formData.category,
        header: formData.header,
        long: formData.long,
        short: formData.short,
      },
    };

    try {
      await CommunicationService.updateTemplate(updateRequest);

      return { status: true };
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      }
    }
  }

  public static async createTemplate({ request }): Promise<IFormResponse> {
    const formData = FormDataHelper.toObject<{
      header: string;
      category: string;
      short: string;
      long: string;
      file: string;
    }>(await request.formData());

    const file: IStorageFileData[] = JSON.parse(formData.file);

    try {
      const response = await StorageService.upload({
        type: StorageTypes.IMAGES,
        id: now().toString(),
        filename: file[0].fileName,
        data: FileHelper.base64RequestFormat(file[0].data),
      });

      const createRequest: ICreateTemplateRequest = {
        name: uuid(),
        web: {
          icon: response.url,
          category: formData.category,
          header: formData.header,
          long: formData.long,
          short: formData.short,
        },
      };

      try {
        const templateId = await CommunicationService.createTemplate(createRequest);

        return {
          status: true,
          payload: templateId,
        };
      }
      catch (e) {
        return {
          status: false,
          error: e.message,
        }
      }
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      }
    }
  }

  public static createAntispam = async ({ request }): Promise<IFormResponse> => {
    const formData = FormDataHelper.toObject<ICreateAntispamFormData>(await request.formData());

    const groups = formData.groups.split(',').map(group => +group);

    const createRequest: ICreateAntispamRequest = {
      periodicity: formData.periodicity,
      is_active: false,
      groups: groups,
      quantity: formData.quantity,
    };

    const categoryId = await CommunicationService.createAntispamRule(createRequest);

    return {
      status: true,
      payload: categoryId.id,
    };
  };

  public static updateAntispam = async ({ request, params }): Promise<IFormResponse> => {
    const { id } = params;
    const formData = FormDataHelper.toObject<ICreateAntispamFormData>(await request.formData());

    const groups = formData.groups.split(',').map(group => +group)

    const createRequest: ICreateAntispamRequest = {
      periodicity: formData.periodicity,
      is_active: false,
      groups: groups,
      quantity: formData.quantity,
      id: id,
    };

    const categoryId = await CommunicationService.updateAntispamRule(createRequest);

    return {
      status: true,
      payload: categoryId,
    };
  };

  public static updateNews = async ({ request }) => {
    const formData = FormDataHelper.toObject<{
      id: string,
      title: string;
      description: string;
      group_id: string;
      profile_id: string;
      delivery_method: string;
      start: string;
      end: string;
      is_single: Switchable;
      is_periodic: Switchable;
      periodicity: PeriodicityType | null;
      is_active: Switchable;
      quantity: string;
      file?: string;
      file_name?: string;
      icon?: string;
    }>(await request.formData());

    const updateRequest: IUpdateNewsRequest = {
      id: +formData.id,
      title: formData.title,
      description: formData.description !== '' ? formData.description : null,
      start: formData.start,
      delivery_method: ["email", "web"],
      is_active: !!formData.is_active,
      periodicity: formData.periodicity ? formData.periodicity : null
    };

    if (formData.group_id) {
      updateRequest.group_id = formData.group_id.split(',').map(item => +item);
    } else {
      updateRequest.group_id = [];
    }

    if (formData.profile_id) {
      updateRequest.profile_id = formData.profile_id.split(',');
    } else {
      updateRequest.profile_id = [];
    }

    if (formData.end) {
      updateRequest.end = formData.end;
    } else {
      updateRequest.end = null;
    }

    if (formData.periodicity) {       
      updateRequest.quantity = +formData.quantity;
    }

    if (formData.icon) {       
      updateRequest.icon = formData.icon;
    }

    if (formData.file) {
      const encoded = JSON.parse(formData.file) as IStorageFileData[];

      const storageRequests: IStorageUploadRequest = {
        type: StorageTypes.IMAGES,
        id: Math.random().toString(),
        filename: encoded[0].fileName,
        data: FileHelper.base64RequestFormat(encoded[0].data),
      };

      const uploadedFile = await StorageService.upload(storageRequests);

      updateRequest.icon = uploadedFile.url;

      delete formData.file;
    }

    return { status: true, payload: await CommunicationService.updateNews(updateRequest) };
  };

  public static createNews = async ({ request }) => {        
    const formData = FormDataHelper.toObject<{
      title: string;
      description: string;
      group_id: string;
      profile_id: string;
      delivery_method: string;
      start: string;
      end: string;
      is_single: Switchable;
      is_periodic: Switchable;
      periodicity: PeriodicityType | null;
      quantity: string;
      file?: string;
      file_name?: string;
      icon?: string;
    }>(await request.formData());

    const createRequest: ICreateNewsRequest = {
      title: formData.title,
      description: formData.description !== '' ? formData.description : null,
      start: formData.start,
      delivery_method: ["email", "web"],
      is_active: true,
      periodicity: formData.periodicity ? formData.periodicity : null
    };

    if (formData.group_id) {
      createRequest.group_id = formData.group_id.split(',').map(item => +item);
    }

    if (formData.profile_id) {
      createRequest.profile_id = formData.profile_id.split(',');
    }

    if (formData.end) {
      createRequest.end = formData.end;
    }

    if (formData.periodicity) {
      createRequest.quantity = +formData.quantity;
    }

    if (formData.file) {
      const storageRequests: IStorageUploadRequest = {
        type: StorageTypes.IMAGES,
        id: Math.random().toString(),
        filename: formData.file_name,
        data: FileHelper.base64RequestFormat(formData.file),
      };

      const uploadedFile = await StorageService.upload(storageRequests);

      createRequest.icon = uploadedFile.url;

      delete formData.file;
    }

    try {
      const news = await CommunicationService.createNews(createRequest);

      return { status: true, payload: news.id };
    } 
    catch (e) {
      return { status: false, error: e.message }
    }
  };
}
