import {UserTypes} from '../enums/UserTypes';

/**
 * Get min password length by select type
 *
 * @param userType
 */
export const getMinPasswordLengthByUserType = (userType: UserTypes): number => {
  if (userType === UserTypes.PARTNER) {
    return 8;
  }

  if (userType === UserTypes.OPERATOR) {
    return 10;
  }

  return 6;
};

/**
 * Get password rules by select type
 *
 * @param {UserTypes} userType
 */
export const getPasswordRules = (userType: UserTypes) => {
  return [
    'required',
    'min:' + getMinPasswordLengthByUserType(userType),
    'digits',
    'characters',
    'lowercase',
    'uppercase'
  ];
};

/**
 * Get user role name by type
 *
 * @param {UserTypes} userType
 */
export const getNameByUserType = (userType: UserTypes): string => {
  switch (userType) {
    case UserTypes.OPERATOR:
      return 'Оператор';
    case UserTypes.PARTNER:
      return 'Партнер';

    default:
      return 'Пользователь';
  }
};

/**
 * Get user type by name value
 *
 * @param {string} value
 */
export const getUserTypeByFromDataValue = (value: string): string => {
  switch (value) {
    case '1':
      return UserTypes.PARTNER;
    case '2':
      return UserTypes.OPERATOR;

    default:
      return UserTypes.USER;
  }
};

/**
 * Get avatar letters
 */
export const getAvatarLettersByString = (value: string): string => {
  return value.substring(0, 2);
}


