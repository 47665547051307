import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import AutoReportsTabContentData from './AutoReportsTabContentData';
import AutoReportsTabFilters from './AutoReportsTabFilters';
import AutoReportsTabDataTweaks from './AutoReportsTabDataTweaks';
import TabLoader from '../../Common/Loader/TabLoader';
import { ReportService } from '../../../http/reports/ReportService';
import { IReportRulesItem } from '../../../types/app/reports/IGetReportRulesData';
import { getReportTypeNames, IReportTypes } from '../../../enums/ReportTypes';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilterState: IFilters = {
  like: {
    email: '',
    partnerCompanyName: '',
  },
  from: {
    createdAt: null,
    createdAtTime: null,
    orderAmount: null,
    paymentAmount: null,
  },
  to: {
    createdAt: null,
    createdAtTime: null,
    orderAmount: null,
    paymentAmount: null,
  },
  eq: {
    statusId: null,
  },
};

const AutoReportsTabContentMain = () => {
  const { getPaginatable, getDialogFilterable, getFilterable, items, setItems, setFilters } = useTweakTool<IReportRulesItem>();

  const [partnersInfo, setPartnersInfo] = useState<Map<number, { key: number; label: string }>>(null);
  const [reportTypes, setReportTypes] = useState<IReportTypes[]>(null);
  const [rerender, setRerender] = useState<boolean>(false);

  useEffect(() => {
    setRerender(false);
    ReportService.getReportTypes().then(types => setReportTypes(getReportTypeNames(types)));

    ReportService.getReportRules().then(reportRules => {
      const partners = new Map<number, { key: number; label: string }>();
      reportRules.forEach(
        report => report.userId && partners.set(report.partner.key, { key: report.partner.key, label: report.partner.label })
      );
      setPartnersInfo(partners);
      setItems(reportRules);
    });
    setFilters(defaultFilterState);
  }, [rerender]);

  if (!items) {
    return <TabLoader />;
  }

  return (
    <Box width="100%">
      <AutoReportsTabFilters {...getDialogFilterable} partners={partnersInfo} reportTypes={reportTypes} />

      <AutoReportsTabDataTweaks {...getFilterable} />

      <Divider />

      <AutoReportsTabContentData {...getPaginatable} setRerender={() => setRerender(true)} />
    </Box>
  );
};

export default AutoReportsTabContentMain;
