import { defer } from 'react-router-dom';
import { FraudService } from './FraudService';

export class FraudLoaders {
  public static getRuleSetting({ params }) {
    return defer({
      ruleSetting: FraudService.getRuleSetting({ id: params.id }),
    });
  }

  public static getRuleSettings() {
    return defer({
      rules: FraudService.getRules(),
    });
  }
}