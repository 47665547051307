import React from 'react';
import { Box, Divider as MuiDivider } from '@mui/material';

const Divider = () => {
  return (
    <Box mt={2} mb={2}>
      <MuiDivider />
    </Box>
  );
};

export default Divider;
