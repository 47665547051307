import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '../../../UI/Table/TableContainer';
import { IPaginatable } from '../../../types/app/common/Paginatable';
import ShowButton from '../../../UI/Button/ShowButton';
import { RouteList } from '../../../http/RouteList';
import { IClientBankAccount } from '../../../types/app/account/ClientBankAccount';

const UserBankAccountsTabContentData = (props: IPaginatable<IClientBankAccount>) => {
  return (
    <TableContainer boxprops={{ style: {top: '-16px'}}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={350}>Идентификатор</TableCell>
            <TableCell width={350}>Email</TableCell>
            <TableCell width={350}>Баланс на балльном счете</TableCell>
            <TableCell width={350}>Баланс на балльном блокировочном счете</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(account => (
            <TableRow key={account.userId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{account.userId}</TableCell>
              <TableCell>{account.email}</TableCell>
              <TableCell>{account.pointBalance.toLocaleString()}</TableCell>
              <TableCell>{account.blockPointBalance.toLocaleString()}</TableCell>
              <TableCell>
                <ShowButton linkProps={{ to: RouteList.BANKING_USER_SHOW + '/' + account.userId }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserBankAccountsTabContentData;
