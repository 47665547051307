import {defer} from 'react-router-dom';
import {ICategory} from '../../types/app/catalog/Category';
import {IProduct} from '../../types/app/catalog/Product';
import {CatalogService} from '../catalog/CatalogService';


export interface IGetCertificateLoaderData {
  categories: ICategory[],
  products: IProduct[],
}

export interface IGetCertificateDataLoader {
  data: IGetCertificateLoaderData,
}

export class CertificateLoaders {

  public static async getCertificateData() {
    return defer({
      data: Promise.all([
        CatalogService.getCategories(),
        CatalogService.getFilteredProducts({
          perPage: 999,
        }),
      ]).then(response => {
        return {
          categories: response[0],
          products: response[1].items,
        }
      })
    })
  }
}