import React from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import { IDialogFilterable } from '../../../types/app/common/DialogFilterable';
import FilterInput from '../../Common/Filter/FilterInput';
import {defaultFilterState} from './TemplateTabMain';
import Divider from '../../../UI/Delimiter/Divider';
import FilterSwitch from '../../Common/Filter/FilterSwitch';
import {getNameByTemplateType, TemplateTypes} from '../../../enums/TemplateTypes';

const TemplateTabFilters = (props: IDialogFilterable<typeof defaultFilterState>) => {
  return (<FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
    <FilterInput
      label='Заголовок'
      addFilter={props.addFilter}
      field='header'
      filters={props.filters}
      placeholder='Укажите заголовок'
    />

    <Divider />

    <FilterInput
      label='Текст'
      addFilter={props.addFilter}
      field='short'
      filters={props.filters}
      placeholder='Поиск по тексту шаблона'
    />

    <Divider />

    <FilterInput
      label='Вид рассылки'
      addFilter={props.addFilter}
      field='category'
      filters={props.filters}
      placeholder='Введите вид рассылки'
    />

    <Divider />

    <FilterSwitch
      addFilter={props.addFilter}
      filters={props.filters}
      field="type"
      items={[
        { label: getNameByTemplateType(TemplateTypes.SYSTEM), key: TemplateTypes.SYSTEM },
        { label: getNameByTemplateType(TemplateTypes.CUSTOM), key: TemplateTypes.CUSTOM },
      ]}
      label="Тип шаблона"
    />
  </FilterDialog>);
};

export default TemplateTabFilters;
