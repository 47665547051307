import {Await, useLoaderData} from "react-router-dom";
import {IGetAntispamLoader} from "../../../http/communication/CommunicationLoaders";
import React from "react";
import NotificationAntispamShowForm from "../../../Widgets/Notifications/TemplateTab/Operations/NotificationAntispamShowForm";

const NotificationAntispamShow = () => {
  const { content } = useLoaderData() as  { content: IGetAntispamLoader }

  return (
    <Await resolve={content}>
      <NotificationAntispamShowForm />
    </Await>
  );
};

export default NotificationAntispamShow;