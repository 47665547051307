import { FormDataHelper } from '../../helpers/FormDataHelper';
import { IFormResponse } from '../../store/context/form/types/FormResponse';
import { ReportService } from './ReportService';
import { IRefreshReportRequest } from '../../types/request/reports/RefreshReportRequest';
import { ReportTypes } from '../../enums/ReportTypes';
import { ReportRulesCreateRequest } from '../../types/request/reports/ReportRulesCreateRequest';
import { getPeriodType } from '../../enums/ReportPeriodTypes';
import { CheckEmailsRequest } from '../../types/request/reports/CheckEmailsRequest';

export class ReportsActions {
  public static async createReportAction({ request }): Promise<IFormResponse> {
    const formData = FormDataHelper.toObject<{
      end_date: string;
      format: string;
      partner: string;
      start_date: string;
      type: ReportTypes;
    }>(await request.formData());

    const refreshRequest: IRefreshReportRequest = {
      type: formData.type,
      format: +formData.format === 1 ? 'xlsx' : 'pdf',
      partner_id: +formData.partner,
    };

    if (formData.type === ReportTypes.TURNOVER_POINTS || formData.type === ReportTypes.SUMMARY) {
      refreshRequest.start_date = formData.start_date;
      refreshRequest.end_date = formData.end_date;
    }

    try {
      const reportId: number = await ReportService.refreshReport(refreshRequest);

      return {
        status: true,
        payload: reportId,
      };
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  }

  public static async createReportRulesAction({ request }): Promise<IFormResponse<number>> {
    const formData = FormDataHelper.toObject<{
      email: string;
      emails: string;
      format: string;
      partner: string;
      startDate: string;
      report_type: string;
      period: string;
      periodValue: string;
    }>(await request.formData());

    const checkEmailsRequest: CheckEmailsRequest = {
      emails: formData.emails.split(','),
      report_type: formData.report_type as ReportTypes,
      period_type: getPeriodType(+formData.period),
    };

    try {
      const response = await ReportService.checkEmailsBeforeCreatingReportRules(checkEmailsRequest);

      const createReportRulesRequestData: ReportRulesCreateRequest = {
        report_type: formData.report_type as ReportTypes,
        period_value: +formData.periodValue,
        period_type: getPeriodType(+formData.period),
        format: +formData.format === 0 ? 'xlsx' : 'pdf',
        start_at: formData.startDate,
        emails: formData.emails.split(','),
        is_active: true,
        user_id: +formData.partner,
      };

      response && (await ReportService.createReportRules(createReportRulesRequestData));

      return {
        status: true,
      };
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  }
}
