import React from 'react';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';
import Tabs from '../../../UI/Tab/Tabs';
import TabPanel from '../../../UI/Tab/TabPanel';
import OperationHistoryTabContentMain
  from '../../../Widgets/Banking/OperationHistoryTab/OperationHistoryTabContentMain';
import UserBankAccountsTabContentMain from '../../../Widgets/Banking/UserBankAccountTab/UserBankAccountsTabContentMain';

const BankingIndex = () => {
  return (
    <ContentContainer title="Управление счетами">
      <Tabs
        items={[
          { title: 'История операций', tabIndex: 0 },
          { title: 'Счета Пользователей', tabIndex: 1 },
        ]}
      />

      <TabPanel index={0} minheight={900} width={100}>
        <OperationHistoryTabContentMain />
      </TabPanel>

     <TabPanel index={1} minheight={900} width={100}>
       <UserBankAccountsTabContentMain />
      </TabPanel>

    </ContentContainer>
  );
};

export default BankingIndex;