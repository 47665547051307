import {IFilterable} from "../../../types/app/common/Filterable";
import {useNavigate} from "react-router-dom";
import {Box, Button, Grid} from "@mui/material";
import {RouteList} from "../../../http/RouteList";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FilterGroupButtons from "../../../UI/Button/FilterGroupButtons";
import PerPage from "../../../UI/Table/PerPage";
import React from "react";

const AntispamTabDataTweaks = (props: IFilterable) => {
  const route = useNavigate();

  return (
    <Box mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Button onClick={() => route(RouteList.NOTIFICATIONS_ANTISPAM_CREATE)} endIcon={<AddCircleOutlineIcon />}>
            Добавить настройку
          </Button>
        </Grid>
        <Grid item>
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>
        <Grid item>
          <PerPage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AntispamTabDataTweaks;