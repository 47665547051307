import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { ICreateNewsLoader } from '../../../http/communication/CommunicationLoaders';
import NewsFormCreate from '../../../Widgets/Notifications/NewsTabMain/Operations/NewsFormCreate';

const NotificationNewsCreate = () => {
  const { content } = useLoaderData() as { content: ICreateNewsLoader };

  return (
    <Await resolve={content}>
      <NewsFormCreate />
    </Await>
  );
};

export default NotificationNewsCreate;
