import React, { Dispatch } from 'react';
import { Alert, Box, LinearProgress, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';

interface SettingsTabFormInputProps {
  value: string;
  title: string;
  name: string;
  dispatch: Dispatch<string>;
  rule?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => boolean;
}

const SettingsTabFormInput = (props: SettingsTabFormInputProps) => {
  if (props.value === null) {
    return (
      <Box padding={3}>
        <LinearProgress />
      </Box>
    );
  }

  // if props is not found
  if (props.value === undefined) {
    return (
      <Box mb={1}>
        <Typography marginBottom={1} variant="body2" color="grey.600">
          {props.title}:
        </Typography>
        <Alert sx={{ padding: '5px' }} color="error" icon={<WarningAmberIcon sx={{ fontSize: 20 }} />}>
          Настройка недоступна
        </Alert>
      </Box>
    );
  }

  return (
    <FormOutlinedInput
      rule={e => {
        const isNumber = !isNaN(Number(e.target.value));
        let isCustomRuleValid = true;

        if (props.rule !== undefined) {
          isCustomRuleValid = props.rule(e);
        }

        return isNumber && isCustomRuleValid;
      }}
      inputProps={{
        sx: { bgcolor: '#fff' },
        value: props.value,
        name: props.name,
        onChange: e => props.dispatch(Number(e.target.value).toString())
      }}
      title={props.title}
    />
  );
};

export default SettingsTabFormInput;
