import React, {useEffect} from 'react';
import {Box} from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import TabLoader from '../../Common/Loader/TabLoader';
import {useTweakTool} from '../../../hooks/table/useTweakTool';
import CatalogTabContentData from './CatalogTabContentData';
import CatalogTabDataTweaks from './CatalogTabDataTweaks';
import {IProduct} from '../../../types/app/catalog/Product';
import {CatalogService} from '../../../http/catalog/CatalogService';
import CatalogTabFilters from './CatalogTabFilters';
import {IFilters} from '../../../store/rtk/reducers/serverPaginationSlice';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilterState: IFilters = {
  like: {
    id: '',
  },
  from: {
    price: null,
  },
  to: {
    price: null,
  },
};

export interface IProductExtended extends IProduct {
  partnerLabel: string;
}

const CatalogTabContentMain = () => {
  const {
    getPaginatable,
    getDialogFilterable,
    getFilterable,
    items,
    setFilters,
    setServerMethod,
    setMutator
  } = useTweakTool<IProductExtended>();

  useEffect(() => {
    setMutator(params => {
      if (params.ext_product_id) {
        params.ext_product_id = [params.ext_product_id.toString()]
      }

      if (params.id) {
        params.id = [Number(params.id)]
      }

      if (params.partner_id) {
        params.partner_id = [params.partner_id]
      }

      if (params.price_start) {
        params.price_from = params.price_start
        delete params.price_start
      }

      if (params.price_end) {
        params.price_to = params.price_end
        delete params.price_end
      }

      return params;
    });

    setFilters(defaultFilterState);

    setServerMethod(CatalogService.getFilteredProducts)
  }, []);

  if (!items) {
    return <TabLoader />;
  }

  return (
    <Box width="100%">
      <TabTitle title="Товары/услуги" />

      <CatalogTabFilters {...getDialogFilterable} />

      <CatalogTabDataTweaks {...getFilterable} />

      <Divider />

      <CatalogTabContentData {...getPaginatable} />
    </Box>
  );
};

export default CatalogTabContentMain;
