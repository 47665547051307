import {JsonRpcService} from '../JsonRpcService';
import {IPropertyRequest} from '../../types/request/account/PropertyRequest';
import {IPropertyResponse} from '../../types/response/account/GetPropertyResponse';
import {IGetBalanceRequest} from '../../types/request/account/GetBalanceRequest';
import {IGetBalanceResponse} from '../../types/response/account/GetBalanceResponse';
import {
  FamilyFallowError,
  GeneralError,
  GetAllPropertiesForPartnerError,
  InternalError,
  OperationAccountNotFoundError,
  OperationInsufficientFundsError,
} from './AccountErrors';
import {IGetBalancesResponse} from '../../types/response/account/GetBalancesResponse';
import {IBalances} from '../../types/app/common/Balances';
import {TGetOperatorDashboardData} from '../../types/response/account/GetOperatorDashboardData';
import {IResponse} from '../../types/response/common/Response';
import {IDashboardInfo} from '../../types/app/account/DashboardInfo';
import {IPropertyAllRequest} from '../../types/request/account/PropertyAllRequest';
import {TCertificate} from '../../types/response/account/GetCertificatesResponse';
import {AccountFactory} from './AccountFactory';
import {ICertificate} from '../../types/app/account/Certificate';
import {IGetCertificateResponse} from '../../types/response/account/GetCertificateResponse';
import {TGetFamiliesData} from '../../types/response/account/GetFamiliesData';
import {IFamilyParent} from '../../types/app/account/Family';
import {IChangeFamilyUsePointsRequest} from '../../types/request/account/ChangeFamilyUsePoints';
import {IChangeFamilyParentRequest} from '../../types/request/account/ChangeFamilyParent';
import {
  IGetTransactionsResponse,
  TTransactionDataWithPagination
} from '../../types/response/account/GetTransactionsResponse';
import {ITransaction, ITransactionWithPagination} from '../../types/app/account/Transaction';
import {UserTypes} from '../../enums/UserTypes';
import {IUserBankAccount} from '../../types/app/account/UserBankAccount';
import {IGetBankUserAccountsData} from '../../types/response/account/GetBankUserAccountsData';
import {IGetBankPartnerAccountsData} from '../../types/response/account/GetBankPartnerAccountsData';
import {IPartnerBankAccount, IPartnerBankAccountServerPagination} from '../../types/app/account/PartnerBankAccount';
import {IOperationPayIORequest} from '../../types/request/account/OperationPayIORequest';
import {IOperationTransfer} from '../../types/request/account/OperationTransfer';
import {IOperationPartnerIORequest} from '../../types/request/account/OperationPartnerIORequest';
import {IChangeCertificateStatusResponse} from '../../types/response/account/ChangeCertificateStatusResponse';
import {CertificateStatuses} from '../../enums/CertificateStatuses';
import {IAccountsBalances} from '../../types/app/common/IAccountsBalances';
import {GetIAccountsBalancesResponse} from '../../types/response/account/GetIAccountsBalancesResponse';
import {IChangeCertificateUser} from '../../types/request/account/ChangeCertificateUser';
import {IGetAllPropertiesResponse} from '../../types/response/account/GetAllPropertiesResponse';
import {IAccountSettings} from '../../types/app/account/Settings';
import {IGetBankClientAccountsData} from '../../types/response/account/GetClientUserAccountsData';
import {IPaginatedResponse} from '../../types/response/common/PaginatedResponse';
import {IPaginated} from '../../types/app/common/Paginated';
import {IClientBankAccount} from '../../types/app/account/ClientBankAccount';
import {IGetClientBankAccountsRequest} from '../../types/request/account/GetClientBankAccountsRequest';
import {IGetPartnerBankAccountsRequest} from '../../types/request/account/GetPartnerBankAccountsRequest';
import {IGetBankPartnerAccountsDataServerPagination} from '../../types/response/account/GetPartnerUserAccountsData';
import {IBalance} from '../../types/app/account/Balance';
import {GetBankingHistoryRequest} from '../../types/request/account/GetBankingHistoryRequest';
import {IGetInvoicesBankResponse} from '../../types/response/account/GetInvoicesBankResponse';
import {IInvoicesBankAccount} from '../../types/app/account/InvoicesBankAccount';
import {ICreateCertificateRequest} from '../../types/request/certificates/CreateCertificateRequest';
import {IFormResponse} from '../../store/context/form/types/FormResponse';
import {GetCreateCertificateResponse} from '../../types/response/account/GetCreateCertificateResponse';
import { IGetBalancesByExtIdRequest } from '../../types/request/account/GetBalancesByExtIdRequest';
import { IGetUserBalancesByExtIdData } from '../../types/response/account/GetUserBalancesByExtIdData';
import { IGetPartnerBalancesByExtIdData } from '../../types/response/account/GetPartnerBalancesByExtIdData';

export class AccountService {
  private static serviceName: string = 'account';

  public static async unfollowFamily(request: { user_id: number }): Promise<void> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'unfollow_operator_family', request);

    if (response.error) {
      throw new FamilyFallowError();
    }
  }

  public static async changeFamilyUsePoints(request: IChangeFamilyUsePointsRequest): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'change_family_use_points', request);
  }

  public static async changeFamilyParent(request: IChangeFamilyParentRequest): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'change_family_operator', request);
  }

  public static async getAllProperties(request: IPropertyAllRequest): Promise<IAccountSettings> {
    const response = await JsonRpcService.request<IGetAllPropertiesResponse>(this.serviceName, 'property_get_all', request);

    return AccountFactory.getAccountAllPropertiesFromResponse(response.result.properties);
  }


  public static async getAllPropertiesForPartner(request: IPropertyAllRequest): Promise<IAccountSettings> {
    const response = await JsonRpcService.request<IGetAllPropertiesResponse>(this.serviceName, 'property_get_all_merged_with_defaults_for_partner', request);

    if (response.error) {
      throw new GetAllPropertiesForPartnerError();
    }

    return AccountFactory.getAccountAllPropertiesFromResponse(response.result.properties);
  }

  public static async getCertificate(request: { id_or_code: string }) {
    const response = await JsonRpcService.request<IGetCertificateResponse>(this.serviceName, 'certificate_get', request);

    return AccountFactory.getCertificateFromResponse(response.result.certificate);
  }

  public static async getFilteredCertificates(request): Promise<IPaginated<ICertificate[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TCertificate[]>>(
      AccountService.serviceName,
      'certificate_get_filtered_list',
      request,
    )

    // const partners = await PartnerService.getPartners();

    const items = response.result.data.items.map(cert => AccountFactory.getCertificateFromResponse(cert))

    return {items, ...response.result.data.pagination}
  }

  public static async changeCertificateStatus(request: { id_or_code: string; status: CertificateStatuses }): Promise<boolean> {
    const response = await JsonRpcService.request<IChangeCertificateStatusResponse>(
      this.serviceName,
      'certificate_change_status',
      request
    );

    return response.result.status;
  }

  public static async deactivateCertificate(request: { certificate_id: string, user_type?: UserTypes, user_id?: number, partner_id: number}): Promise<boolean> {
    const response = await JsonRpcService.request<IChangeCertificateStatusResponse>(
      this.serviceName,
      'certificate_deactivate',
      request
    );

    return response.result.status;
  }

  public static async getProperty(request: IPropertyRequest): Promise<string | null> {
    const response = await JsonRpcService.request<IPropertyResponse>(this.serviceName, 'property_get', {
      obj_name: request.obj_name,
      property: request.property,
    });

    if (response.error) {
      return undefined;
    }

    if (response.result.value === null) {
      return '0';
    }

    return response.result.value;
  }

  public static async setProperty(request: IPropertyRequest): Promise<{ status: boolean }> {
    const response = await JsonRpcService.request<IPropertyResponse>(this.serviceName, 'property_set', {
      obj_name: request.obj_name,
      obj_id: request.obj_id,
      property: request.property,
      value: request.value,
    });

    if (!response || response.error) {
      throw new InternalError();
    }

    return { status: response.result.status };
  }

  public static async getBalances(): Promise<IBalances> {
    const response = await JsonRpcService.request<IGetBalancesResponse>(this.serviceName, 'get_all_balances');

    const balances = response.result.balances;

    return {
      account: Number(balances.account),
      certificate: Number(balances.certificate),
      income: Number(balances.income),
      incompletePoint: Number(balances.incomplete_point),
      point: Number(balances.point),
      rub: Number(balances.rub),
    };
  }

  public static async getOperatorDashboardData(): Promise<IDashboardInfo> {
    const response = await JsonRpcService.request<IResponse<TGetOperatorDashboardData>>(
      this.serviceName,
      'get_operator_dashboard_data'
    );

    const data = response.result.data;

    function formatNumberWithTwoDecimals(value: number) {
      if (!isNaN(value)) {

        return value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
      } else {

        return value;
      }
    }

    return {
      monthlyPointsDeposit: data.monthly_points_deposit,
      monthlyPointsWithdraw: data.monthly_points_withdraw,
      todayPointsDeposit: data.today_points_deposit,
      todayPointsWithdraw: data.today_points_withdraw,
      totalPointsDeposit: data.total_points_deposit,
      totalRublsDeposit: data.total_rubls_deposit,
      weeklyPointsDeposit: data.weekly_points_deposit,
      weeklyPointsWithdraw: data.weekly_points_withdraw,
      monthlyRublsDepositByPartners: formatNumberWithTwoDecimals(data.monthly_rubls_deposit_by_partners),
      monthlyRublsWithdrawByPartners: formatNumberWithTwoDecimals(data.monthly_rubls_withdraw_by_partners),
      todayRublsDepositByPartners: formatNumberWithTwoDecimals(data.today_rubls_deposit_by_partners),
      todayRublsWithdrawByPartners: formatNumberWithTwoDecimals(data.today_rubls_withdraw_by_partners),
      totalPointsEarnByPartners: data.total_points_earn_by_partners,
      totalRublsDepositByPartners: formatNumberWithTwoDecimals(data.total_rubls_deposit_by_partners),
      weeklyRublsDepositByPartners: formatNumberWithTwoDecimals(data.weekly_rubls_deposit_by_partners),
      weeklyRublsWithdrawByPartners: formatNumberWithTwoDecimals(data.weekly_rubls_withdraw_by_partners)
    };
  }

  public static async getBalance(getBalanceRequest: IGetBalanceRequest): Promise<IBalance> {
    const response = await JsonRpcService.request<IGetBalanceResponse>(this.serviceName, 'get_balance', getBalanceRequest);

    if (response.error) {
      // The API does not match the swagger description. So, errors are not processed yet
      throw new InternalError();
    }

    return {
      balance: +response.result.balance,
      convertRate: +response.result.convert_rate,
      maxPointsQuoteForOrder: +response.result.max_points_quote_for_order,
    }
  }

  public static async getFamilies(): Promise<IFamilyParent[]> {
    const response = await JsonRpcService.request<IResponse<TGetFamiliesData>>(this.serviceName, 'get_all_families');

    return response.result.data.map(item => AccountFactory.getFamilyParentFromResponse(item));
  }

  public static async getTransactions(): Promise<ITransaction[]> { //DEPRECATED method
    const response = await JsonRpcService.request<IGetTransactionsResponse>(
      this.serviceName,
      'get_list_transactions_for_operator',
      {
        limit: 1000,
      }
    );

    return response.result.data.transactions.map(item => AccountFactory.getTransactionFromResponse(item));
  }

  public static async getTransactionsPaginated(request?: GetBankingHistoryRequest): Promise<IPaginated<ITransactionWithPagination[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TTransactionDataWithPagination[]>>(
      AccountService.serviceName,
      'get_list_operations_operator',
      request
    );

    const items = response.result.data.items.map(item => AccountFactory.getPaginatedTransactionFromResponse(item))

    return {items, ...response.result.data.pagination};
  }

  public static async getClientBankAccounts(request?: IGetClientBankAccountsRequest): Promise<IPaginated<IClientBankAccount[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<IGetBankClientAccountsData[]>>(
        AccountService.serviceName,
      'get_clients_list_balances',
      request
    );
    const items = response.result.data.items.map(item => AccountFactory.getClientBankAccountFromResponse(item));

    return { items, ...response.result.data.pagination };
  }

  public static async getPartnersBankAccounts(request?: IGetPartnerBankAccountsRequest): Promise<IPaginated<IPartnerBankAccountServerPagination[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<IGetBankPartnerAccountsDataServerPagination[]>>(
      AccountService.serviceName,
      'get_partners_list_balances',
      request
    );
    const items = response.result.data.items.map(item => AccountFactory.getPartnerBankAccountFromResponseServerPagination(item));

    return { items, ...response.result.data.pagination };
  }

  public static async getUserBankAccounts(): Promise<IUserBankAccount[]> {
    const response = await JsonRpcService.request<IResponse<IGetBankUserAccountsData[]>>(this.serviceName, 'get_list_balances', {
      balances_user_type: UserTypes.USER,
    });

    return response.result.data.map(item => AccountFactory.getUserBankAccountFromResponse(item));
  }

  public static async getUserBankAccount(request: { userId: string | number }): Promise<IUserBankAccount | undefined> {
    const response = await JsonRpcService.request<IResponse<IGetBankUserAccountsData[]>>(this.serviceName, 'get_list_balances', {
      balances_user_type: UserTypes.USER,
    });

    const accounts = response.result.data.map(item => AccountFactory.getUserBankAccountFromResponse(item));

    return accounts.find(account => account.userId === Number(request.userId));
  }

  public static async getPartnerBankAccounts(): Promise<IPartnerBankAccount[]> {
    const response = await JsonRpcService.request<IResponse<IGetBankPartnerAccountsData[]>>(
      this.serviceName,
      'get_list_balances',
      {
        balances_user_type: UserTypes.PARTNER,
      }
    );

    return response.result.data.map(item => AccountFactory.getPartnerBankAccountFromResponse(item));
  }

  public static async getPartnerBankAccount(request: { userId: string | number }): Promise<IPartnerBankAccount | undefined> {
    const response = await JsonRpcService.request<IResponse<IGetBankPartnerAccountsData[]>>(
      this.serviceName,
      'get_list_balances',
      {
        balances_user_type: UserTypes.PARTNER,
      }
    );

    const accounts = response.result.data.map(item => AccountFactory.getPartnerBankAccountFromResponse(item));

    return accounts.find(account => account.userId === Number(request.userId));
  }

  public static async operationPayIn(request: IOperationPayIORequest): Promise<IBalances> {
    const response = await JsonRpcService.request<IGetBalancesResponse>(this.serviceName, 'operation_payin', request);

    if (response.error) {
      throw new OperationAccountNotFoundError();
    }

    return AccountFactory.getBalanceFromResponce(response.result.balances);
  }

  public static async operationPayOut(request: IOperationPayIORequest): Promise<IBalances> {
    const response = await JsonRpcService.request<IGetBalancesResponse>(this.serviceName, 'operation_payout', request);

    if (response.error && (response.error.data.check_balance || response.error.data.limit_error)) {
      throw new OperationInsufficientFundsError();
    }

    if (response.error) {
      throw new OperationAccountNotFoundError();
    }

    return AccountFactory.getBalanceFromResponce(response.result.balances);
  }

  public static async operationTransfer(request: IOperationTransfer): Promise<IBalances> {
    const response = await JsonRpcService.request<IGetBalancesResponse>(this.serviceName, 'operation_transfer', request);

    if (response.error && response.error.data.system_check_error) {
      throw new OperationInsufficientFundsError();
    }

    if (response.error) {
      throw new OperationAccountNotFoundError();
    }

    return AccountFactory.getBalanceFromResponce(response.result.balances);
  }

  public static async operationAccountsPayIn(request: IOperationPayIORequest): Promise<IAccountsBalances> {
    const response = await JsonRpcService.request<GetIAccountsBalancesResponse>(this.serviceName, 'operation_payin', request);

    if (response.error) {
      throw new OperationAccountNotFoundError();
    }

    return AccountFactory.getAccountBalanceFromResponse(response.result.balances);
  }

  public static async operationAccountsPayOut(request: IOperationPayIORequest): Promise<IAccountsBalances> {
    const response = await JsonRpcService.request<GetIAccountsBalancesResponse>(this.serviceName, 'operation_payout', request);

    if (response.error) {
      throw new OperationAccountNotFoundError();
    }

    return AccountFactory.getAccountBalanceFromResponse(response.result.balances);
  }

  public static async operationAccountsPartnerPayIn(request: IOperationPartnerIORequest): Promise<IAccountsBalances> {
    const response = await JsonRpcService.request<GetIAccountsBalancesResponse>(
      this.serviceName,
      'operation_partner_payin',
      request
    );

    if (response.error) {
      throw new OperationAccountNotFoundError();
    }

    return AccountFactory.getAccountBalanceFromResponse(response.result.balances);
  }

  public static async operationAccountsPartnerPayOut(request: IOperationPartnerIORequest): Promise<IAccountsBalances> {
    const response = await JsonRpcService.request<GetIAccountsBalancesResponse>(
      this.serviceName,
      'operation_partner_payout',
      request
    );

    if (response.error) {
      throw new OperationAccountNotFoundError();
    }

    return AccountFactory.getAccountBalanceFromResponse(response.result.balances);
  }

  public static async certificateChangeUser(request: IChangeCertificateUser): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'certificate_change_user', request);

    if (response.error) {
      throw new OperationAccountNotFoundError();
    }

    return response.result.status;
  }

  public static async toggleFamilyProgram(): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'toggle_family');

    if (response.error) {
      throw new GeneralError();
    }

    return response.result.status;
  }

  public static async invoicesGet(request): Promise<IPaginated<IInvoicesBankAccount[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<IGetInvoicesBankResponse[]>>(
      AccountService.serviceName, 
      'invoices_get', 
      request
    );

    const items = response.result.data.items.map(item => AccountFactory.getInvoicesFromResponse(item))

    return { items, ...response.result.data.pagination}
  }

  public static async invoicesGetOne(request: {id: number}): Promise<IInvoicesBankAccount> {
    const response = await JsonRpcService.request<IResponse<IGetInvoicesBankResponse>>(
      this.serviceName,
      'invoices_get_one',
      request
    )

    return AccountFactory.getInvoicesFromResponse(response.result.data)
  }

  public static async createCertificate(request: ICreateCertificateRequest): Promise<IFormResponse> {
    const response = await JsonRpcService.request<GetCreateCertificateResponse>(
      this.serviceName,
      'certificate_create',
      request,
    );

    if (response.result) {
      return {
        status: true,
        payload: response.result.certificate_ids[0]
      };
    }
    else {
      return {
        status: false,
      };
    }
  }
  
  public static async getBalancesByExtId(request: IGetBalancesByExtIdRequest): Promise<any> {
    const response = await JsonRpcService.request<IResponse<(IGetUserBalancesByExtIdData | IGetPartnerBalancesByExtIdData)>>(this.serviceName, 'get_balances_by_ext_id', request);

    if (response.result.data) {
      if (request.balance_user_type === UserTypes.USER) {
        return AccountFactory.getUserBalanceByExtIdResponse(response.result.data);
      } else if (request.balance_user_type === UserTypes.PARTNER) {
        return AccountFactory.getPartnerBalanceByExtIdResponse(response.result.data as IGetPartnerBalancesByExtIdData);
      }
    }
  }

  public static async downloadBalances(): Promise<string> {
    const response = await JsonRpcService.request<IResponse<string>>(this.serviceName, 'balances_download')

    return response.result.data
  }

  public static async uploadBalances(base64: string): Promise<{status: boolean, warnings: string[]}> {
    const response = await JsonRpcService.request<{result: {status: boolean, warnings: string[]}}>(this.serviceName, 'balances_upload', {
      file_data: base64
    })

    return response.result
  }
}
