import React from 'react';
import {useAsyncValue} from 'react-router-dom';
import {IShowPromoLoader} from '../../../../../http/billing/BillingLoaders';
import PromoCommon from './PromoCommon';

const PromoShowForm = () => {
  const content = useAsyncValue() as IShowPromoLoader;

  return <PromoCommon {...content} />;
};

export default PromoShowForm;
