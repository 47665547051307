import React from 'react';
import {FormHelperText, Grid} from "@mui/material";

const FormErrorText = ({error}: { error: string }) => {
  return (
    <Grid container justifyContent="left" alignItems="center">
      <FormHelperText
        sx={{
          padding: 1,
          fontSize: 14,
          fontFamily: 'Lato-SemiBold',
      }}
        error
      >
        {error}
      </FormHelperText>
    </Grid>
  );
};

export default FormErrorText;