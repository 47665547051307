import { JsonRpcService } from '../JsonRpcService';
import { StructureProperties, StructurePropertyNames } from '../../enums/StructureProperties';
import { IGetStructureResponse, IPropertyResponse, TStructure } from '../../types/response/structure/GetPropertyResponse';
import { IResponse } from '../../types/response/common/Response';
import { IChangeParentRequest } from '../../types/request/structure/ChangeParentRequest';
import {MovingImpossibleError, StructureGetError, ValidationError} from './StructureErrors';

export class StructureService {
  private static serviceName = 'structure';

  public static async getStructure (request: { user_id: number }): Promise<TStructure[]> {
    const response = await JsonRpcService.request<IGetStructureResponse>(
      this.serviceName,
      'get',
      request
    );

    if (response.error) {
      throw new StructureGetError();
    }

    return response.result.structure;
  };

  public static async changeParentGroup(request: IChangeParentRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'change_parent_group', request);

    if (response.error) {
      throw new ValidationError();
    }

    if (response.result.error) {
      if (response.result.error === 'Parent it is downline. Moving impossible') {
        throw new MovingImpossibleError()
      }

      throw new StructureGetError();
    }

    return response.result.status
  }

  public static async isReferralSystemEnabled(): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<{ is_enabled: boolean }>>(
      this.serviceName,
      'referral_system_is_enabled'
    );

    return response.result.data.is_enabled;
  }

  public static async toggleReferralSystem(request: { is_enabled: boolean }): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'referral_system_change_enabled', request);
  }

  public static async setBonusAccrualForReferral(request: { value: string }): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'property_set', {
      property: StructureProperties.COMMON,
      obj_id: 1,
      obj_name: StructurePropertyNames.BONUS_ACCRUAL_FOR_REFERRAL,
      value: request.value,
    });
  }

  public static async getBonusAccrualForReferral(): Promise<string> {
    const response = await JsonRpcService.request<IPropertyResponse>(this.serviceName, 'property_get', {
      property: StructureProperties.COMMON,
      obj_id: 1,
      obj_name: StructurePropertyNames.BONUS_ACCRUAL_FOR_REFERRAL,
    });

    return response.result.value;
  }

  public static async getCountReferrals(request: {user_ids: number[]}): Promise<{ [userId: number]: { total: number; direct: number } }> {
    const response = await JsonRpcService.request<IResponse<{ [userId: number]: { total: number; direct: number } }>>(
      this.serviceName,
      'get_count_referrals',
      request
    );
    
    return response.result.data;
  }
}
