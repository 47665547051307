import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './reducers/authSlice';
import historyReducer from './reducers/historySlice';
import tweakToolsSettingReducer from './reducers/tweakToolsSettingSlice';
import storageReducer from './reducers/storageSlice';
import serverConfigReducer from './reducers/serverPaginationSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    history: historyReducer,
    tweakTools: tweakToolsSettingReducer,
    storage: storageReducer,
    serverConfig: serverConfigReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const getAuthUser = (state: RootState) => state.auth.user;
export const getUserNotification = (state: RootState) => state.auth.user.notification;
