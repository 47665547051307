import { createTheme } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';

export const themeConfig = createTheme({
  components: {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& td': {
            fontSize: 16,
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          padding: '20px',
          zIndex: 10,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          '& > input': {
            padding: '12px 10px',
            textIndent: 8,
          },
          '& > .MuiSelect-outlined': {
            textIndent: 16,
          },
          '& > .MuiSelect-outlined > div': {
            paddingLeft: 12,
          },
          '& > input:disabled': {
            backgroundColor: '#eeefef',
            borderRadius: 16,
          },
          '& > input:disabled:hover': {
            cursor: 'not-allowed',
          },
          '& > .Mui-disabled:hover': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: 0,
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgb(11, 31, 51)',
          opacity: .4
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '12px 24px 12px 5px',
          borderRadius: 8,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          maxHeight: 400,
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& span': {
            fontSize: '16px',
            lineHeight: '25px',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: '#CBDDF7',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '10px 18px',
          textTransform: 'none',
          ':disabled': {
            backgroundColor: '#F9FCFF',
          },
        },
        outlinedPrimary: {
          borderColor: '#CBDDF7',
        },
      },
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '10px 18px',
          textTransform: 'none',
        },
      },
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            color: '#6D7985',
            fontSize: 16,
            lineHeight: '16px',
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: { background: 'white', height: '100%', zIndex: 1, borderRadius: '8px 8px 0 0' },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          minHeight: 500,
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          wordBreak: 'break-word',
        },
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: 20,
          lineHeight: '24px',
          color: '#0B1F33',
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardError: {
          border: '2px solid #E8C5BE',
          backgroundColor: '#FFF5F4',
          color: '#6D7985',
        },
        standardSuccess: {
          border: '2px solid #D3F4D8',
          backgroundColor: '#F2FFF4',
          color: '#6D7985',
        },
        standardWarning: {
          border: '2px solid #F8EBC4',
          backgroundColor: '#FDF6DD',
          color: '#6D7985',
        },
        standardInfo: {
          border: '2px solid #CBECFD',
          backgroundColor: '#EBF8FF',
          color: '#6D7985',
        },
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 0,
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  palette: {
    background: {
      default: '#F4F9FF',
    },
    primary: {
      main: '#0042E7',
    },
    secondary: {
      main: '#b6bcc2',
    },
    warning: {
      main: '#dfb128',
    },
    info: {
      main: '#1997D6',
    },
    success: {
      main: '#00AA1B',
    },
    error: {
      main: '#B03A3A',
    },
    grey: {
      300: '#CED2D6',
      400: '#B6BCC2',
      500: '#9DA5AD',
      600: '#848E98',
      700: '#6D7985',
      800: '#556371',
      900: '#3C4C5C',
      A700: '#0B1F33',
    },
    text: {
      primary: '#0B1F33',
      secondary: '#556371',
    },
  },
  typography: {
    fontFamily: 'Lato-Regular',
    fontSize: 18,
    subtitle1: {
      fontFamily: 'Lato-SemiBold',
      fontSize: 20,
      lineHeight: 1.2
    },
    subtitle2: {
      fontFamily: 'Lato-SemiBold',
      fontSize: 18,
      lineHeight: 1.1
    },
    h1: {
      fontFamily: 'Lato-SemiBold',
      fontSize: 38,
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: 'Lato-SemiBold',
      fontSize: 32,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: 'Lato-SemiBold',
      fontSize: 28,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: 'Lato-SemiBold',
      fontSize: 24,
      lineHeight: 1.2,
    },
    body1: {
      fontFamily: 'Lato-Regular',
      fontSize: 16,
      lineHeight: 1.2,
    },
    body2: {
      fontFamily: 'Lato-Regular',
      fontSize: 14,
      lineHeight: 1.2,
    },
    button: {
      fontFamily: 'Lato-Regular',
      fontSize: 14,
      lineHeight: 1.2,
    },
  },
});
