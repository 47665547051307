export enum MaritalStatuses {
  SINGLE = 'single',
  MARRIED = 'married',
}

export enum MaritalStatusesType {
  SINGLE,
  MARRIED,
}


/**
 * Get marital type
 *
 * @param {string|null} statusString
 * @private
 */
export const getMaritalStatusType = (statusString: string | null): number => {
  if (statusString === null) {
    return MaritalStatusesType.SINGLE;
  }

  return statusString === MaritalStatuses.MARRIED ? MaritalStatusesType.MARRIED : MaritalStatusesType.SINGLE;
}