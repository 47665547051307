import React, {useEffect, useState} from 'react';
import {IRuleReport, IRuleReportDataMoneyBack} from '../../../../types/app/fraud/RuleReport';
import {Box, Card, Grid, Typography} from '@mui/material';
import BackButton from '../../../../UI/Button/BackButton';
import Divider from '../../../../UI/Delimiter/Divider';
import {getDateTime, getRuleName} from '../../../../utils/fraudUtils';
import TypographyLoader from '../../../Common/Loader/TypographyLoader';
import {ClientWithCard, useFraudDataset} from '../hooks/useFraudDataset';
import EmptyCell from '../../../../UI/Table/EmptyCell';
import {FraudSettingConditionNameEnum, FraudSettingConditionNames} from '../../../../enums/FraudRules';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {unix} from 'moment';

const MoneyBackFraudShowCard = (props: IRuleReport) => {
  const ruleData = props.data as IRuleReportDataMoneyBack;

  const { partner, clients } = useFraudDataset({
    partnerId: ruleData.partnerId,
    userIds: ruleData.transactions.map(transaction => transaction.userId),
  });

  const [clientMap, setClientMap] = useState<Map<number, ClientWithCard>>(null);

  useEffect(() => {
    if (clients) {
      const map = new Map<number, ClientWithCard>();

      clients.forEach(client => map.set(client.id, client));

      setClientMap(map);
    }
  }, [clients]);

  if (!clientMap) {
    return <></>;
  }

  return (
    <Box>
      <BackButton />

      <Card sx={{ padding: '70px 50px', width: '85%' }}>
        <Box>
          <Typography mb={1} variant="body2" color="grey.600">
            Наименование правила:
          </Typography>
          <Typography variant="h2">{getRuleName(props.ruleSetting.rule.name)}</Typography>
        </Box>

        <Divider />

        <Grid container columns={4} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              {FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.MONEY_BACK_SUM_MAX)}:
            </Typography>
            <Typography>{ruleData.moneyBackSumMax ?? <EmptyCell />}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Фактическая сумма возврата (общая):
            </Typography>
            <Typography>{ruleData.moneyBackSum ?? <EmptyCell />}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Дата и время срабатывания правила:
            </Typography>
            <Typography>{getDateTime(ruleData.orderDate)}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
             Период (дней):
            </Typography>
            <Typography>{ruleData.moneyBackPeriodDaysCount}</Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid container columns={4} alignItems="flex-start" mb={3} bgcolor="#F9FCFF" borderRadius={2} padding={5}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={100}>Идентификатор</TableCell>
                <TableCell width={250}>Сумма возврата</TableCell>
                <TableCell width={250}>Дата/время возврата</TableCell>
                <TableCell width={250}>ФИО Пользователя</TableCell>
                <TableCell width={200}>Телефон Пользователя</TableCell>
                <TableCell width={100}>Email Пользователя</TableCell>
                <TableCell width={100}>Номер виртуальной карты Пользователя</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ruleData.transactions.map(transaction => (
                <TableRow key={transaction.showNumber}>
                  <TableCell>{transaction.showNumber}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>
                    {transaction.createdAt ? unix(transaction.createdAt).format('DD.MM.YYYY HH:mm') : <EmptyCell />}
                  </TableCell>
                  <TableCell>{clientMap.get(transaction.userId)?.personalData?.fullName ?? <EmptyCell />}</TableCell>
                  <TableCell>{clientMap.get(transaction.userId)?.personalData?.phone ?? <EmptyCell />}</TableCell>
                  <TableCell>{clientMap.get(transaction.userId)?.email ?? <EmptyCell />}</TableCell>
                  <TableCell>{clientMap.get(transaction.userId)?.card?.number ?? <EmptyCell />}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        <Grid container columns={4} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Наименование организации Партнера:
            </Typography>
            <TypographyLoader object={partner} index="label" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Телефон Партнера:
            </Typography>
            <TypographyLoader object={partner} index="phone" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Email Партнера:
            </Typography>
            <TypographyLoader object={partner} index="email" />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default MoneyBackFraudShowCard;
