import {JsonRpcService} from '../JsonRpcService';
import {
  TGetDashboardOperatorTotalStatistics
} from '../../types/response/report/GetDashboardOperatorTotalStatisticsData';
import {IResponse} from '../../types/response/common/Response';
import {IDashboardInfo} from '../../types/app/report/DashboardInfo';
import {IReportGetRequest} from '../../types/request/reports/ReportGetRequest';
import {TGetReportData, TGetReportsData} from '../../types/response/report/TGetReportData';
import {IReportData} from '../../types/app/reports/IReportData';
import {ReportsFactory} from './ReportsFactory';
import {EmailCheckError, InternalError} from './ReportErrors';
import {SendReportToEmailRequest} from '../../types/request/reports/SendReportToEmailRequest';
import {TReportTypes} from '../../types/response/report/TGetReportTypesData';
import {PartnerService} from '../partner/PartnerService';
import {IRefreshReportRequest} from '../../types/request/reports/RefreshReportRequest';
import {TGetReportRulesData, TReportRulesItem} from '../../types/response/report/TGetReportRulesData';
import { IReportRulesItem} from '../../types/app/reports/IGetReportRulesData';
import {ReportRulesCreateRequest} from '../../types/request/reports/ReportRulesCreateRequest';
import {CheckEmailsRequest} from '../../types/request/reports/CheckEmailsRequest';


export class ReportService {
  private static serviceName = 'report';

  /**
   * Get general operator statistics
   */
  public static async getDashboardOperatorTotalStatistics(): Promise<IDashboardInfo> {
    const response = await JsonRpcService.request<IResponse<TGetDashboardOperatorTotalStatistics>>(
      this.serviceName,
      'dashboard_operator_total_statistics',
    );

    const responseStatistics = response.result.data.total_statistics;

    return {
      averageAmount: +responseStatistics.average_amount.toFixed(2),
      averageOrderItemsCount: +responseStatistics.average_order_items_count.toFixed(2),
      operatorTotalTurnover: responseStatistics.operator_total_turnover,
      ordersCount: responseStatistics.orders_count,
      partnerTotalTurnover: responseStatistics.partner_total_turnover,
      partnersCount: responseStatistics.partners_count,
      pointBalance: responseStatistics.point_balance,
      usersCount: responseStatistics.users_count,
      partnerRegistrationApplicationsCount: responseStatistics.partner_registration_applications_count,
    }
  }

  public static async getReports(): Promise<IReportData[]> {
    const response = await JsonRpcService.request<IResponse<TGetReportsData>>(
      this.serviceName,
      'report_get_list'
    );
    const partners = await PartnerService.getPartners()

    return ReportsFactory.getReportsDataFromResponse(response.result.data.reports, partners)
  }

  public static async getReport(request: IReportGetRequest): Promise<IReportData> {
    const response = await JsonRpcService.request<IResponse<TGetReportData>>(
      this.serviceName,
      'report_get',
      request
    );
    const partners = await PartnerService.getPartners()

    return ReportsFactory.getReportDataFromResponse(response.result.data.report, partners)
  }

  public static async sendReportToEmail(request: SendReportToEmailRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(
      this.serviceName,
      'report_send_by_emails',
      request
    );
    if (response.error) {
      throw new InternalError();
    }

    return response.result.status;
  }

  public static async getReportTypes(): Promise<TReportTypes[]> {
    const response = await JsonRpcService.request<IResponse<TReportTypes[]>>(
      this.serviceName,
      'report_type_get_list',
    );
    if (response.result.error) {
      throw new InternalError();
    }

    return response.result.data;
  }

  public static async deleteReport(request: {id: number}): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(
      this.serviceName,
      'report_delete',
      request
    );
    if (response.result.error) {
      throw new InternalError();
    }

    return response.result.status;
  }


  public static async refreshReport(request: IRefreshReportRequest): Promise<number> {
    const response = await JsonRpcService.request<IResponse<{report_id: number}>>(
      this.serviceName,
      'report_refresh',
      request,
    );

    if (response.error) {
      throw new InternalError();
    }

    return response.result.data.report_id;
  }

  public static async getReportRules(): Promise<IReportRulesItem[]> {
    const response = await JsonRpcService.request<IResponse<TGetReportRulesData>>(
      this.serviceName,
      'report_email_get_list',
    );

    const partners = await PartnerService.getPartners()

    if (response.error) {
      throw new InternalError();
    }

    return ReportsFactory.getReportRulesDataFromResponse(response.result.data.report_emails, partners);
  }

  public static async getReportRule(request: {id: number}): Promise<IReportRulesItem> {
    const response = await JsonRpcService.request<IResponse<TReportRulesItem>>(
      this.serviceName,
      'report_email_show',
      request
    );

    const partners = await PartnerService.getPartners()

    if (response.error) {
      throw new InternalError();
    }

    return ReportsFactory.getReportRule(response.result.data, partners);
  }

  public static async deleteReportRule(request: {id: number}): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(
      this.serviceName,
      'report_email_delete',
      request
    );
    if (response.result.error) {
      throw new InternalError();
    }

    return response.result.status;
  }

  public static async createReportRules(request: ReportRulesCreateRequest): Promise<number> {
    const response = await JsonRpcService.request<IResponse<{report_email_id: number}>>(
      this.serviceName,
      'report_email_create',
      request,
    );

    if (response.error) {
      throw new InternalError();
    }

    return response.result.data.report_email_id;
  }

  public static async checkEmailsBeforeCreatingReportRules(request: CheckEmailsRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse>(
      this.serviceName,
      'report_email_check',
      request,
    );

    if (response.error) {
      const errorDescription = JSON.parse(response.error.data.body)
      throw new EmailCheckError(errorDescription.error.emails_are_already_exists);
    }

    return response.result.status;
  }

}