import { useEffect, useState } from 'react';
import { IPartner } from '../../../../types/app/partner/Partner';
import { IIdentificationVirtualCard } from '../../../../types/app/account/Identification';
import { IClient } from '../../../../types/app/client/Client';
import { PartnerService } from '../../../../http/partner/PartnerService';
import { ClientService } from '../../../../http/client/ClientService';

interface IUseFraudDatasetProps {
  partnerId?: number;
  userIds?: number[];
}

export interface ClientWithCard extends IClient {
  card?: IIdentificationVirtualCard,
}

export const useFraudDataset = (props: IUseFraudDatasetProps) => {
  const [partner, setPartner] = useState<IPartner | null>();
  const [clients, setClients] = useState<ClientWithCard[]>();

  useEffect(() => {
    if (props.userIds) {
      const fetchClientsWithCards = async () => {
        const clients = await ClientService.getClients({ ids: props.userIds });
        const clientsWithCard: ClientWithCard[] = [];

        for (const client of clients) {
          const cards = await ClientService.getIdentifiersVirtualCard({ client_id: client.id });
          const clientWithCard: ClientWithCard = { ...client, card: cards.length > 0 ? cards[0] : null };
          clientsWithCard.push(clientWithCard);
        }

        setClients(clientsWithCard);
      };

      fetchClientsWithCards();
    }

    if (props.partnerId) {
      PartnerService.getPartnerById({ id: props.partnerId }).then(response => setPartner(response));
    }
  }, []);

  return {
    partner,
    clients,
  };
};
