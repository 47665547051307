import React from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import {IDialogFilterable} from '../../../types/app/common/DialogFilterable';
import {defaultFilterState} from './AutoReportsTabContentMain';
import {IReportTypes} from '../../../enums/ReportTypes';
import Divider from '../../../UI/Delimiter/Divider';
import FilterInput from '../../Common/Filter/FilterInput';
import FilterSelect from '../../Common/Filter/FilterSelect';
import {getReportPeriodTypeNames} from '../../../enums/ReportPeriodTypes';

interface IReportRulesTabFiltersProps extends IDialogFilterable<typeof defaultFilterState> {
  partners:  Map <number, { key: number, label: string }>
  reportTypes: IReportTypes[]
}


const AutoReportsTabFilters = (props: IReportRulesTabFiltersProps) => {

  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterSelect
        label="Партнер"
        filters={props.filters}
        addFilter={props.addFilter}
        items={Array.from(props.partners.values())}
        field="userId"
        placeholder="Выберете Партнера"
      />

      <Divider />

      <FilterSelect
        label="Тип отчета"
        filters={props.filters}
        addFilter={props.addFilter}
        items={props.reportTypes}
        field="typeId"
        placeholder="Выберете тип отчета"
      />

      <Divider />


      <FilterSelect
        label="Периодичность"
        filters={props.filters}
        addFilter={props.addFilter}
        items={getReportPeriodTypeNames()}
        field="periodTypeId"
        placeholder="Выберете периодичность"
      />

      <Divider />

      <FilterInput
        label="Email для отправки"
        addFilter={props.addFilter}
        field="partnerEmail"
        filters={props.filters}
        placeholder="Укажите Email"
      />

    </FilterDialog>
  );
};

export default AutoReportsTabFilters;
