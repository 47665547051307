import {defer} from 'react-router-dom';
import {ReportService} from './ReportService';
import {IPartner} from '../../types/app/partner/Partner';
import {IReportData} from '../../types/app/reports/IReportData';
import {PartnerService} from '../partner/PartnerService';
import {TReportTypes} from '../../types/response/report/TGetReportTypesData';
import {IReportRulesItem} from '../../types/app/reports/IGetReportRulesData';

export interface IGetReportLoader {
  report: IReportData;
}

export interface IGetDataForNewReportLoader {
  partners: IPartner[];
  reportTypes: TReportTypes[];
}

export interface IGetDataForReportRuleLoader {
  report: IReportRulesItem;
}

export class ReportLoaders {

  public static getDataForNewReport = () => {

    return defer({
      content: Promise.all([
        PartnerService.getPartners(),
        ReportService.getReportTypes()
      ]).then(data => ({
          partners: data[0],
          reportTypes: data[1]
        } as IGetDataForNewReportLoader)
      ),
    });
  };

  public static getReport = ({params}) => {

    return defer({
      report: ReportService.getReport({id: Number(params.id)}),
    });
  };

  public static getReportRule = ({params}) => {

    return defer({
      report: ReportService.getReportRule({id: +params.id})
    });
  };

}