import React, {useEffect, useState} from 'react';
import {IOrder} from '../../types/app/shop/Order';
import {useTweakTool} from '../../hooks/table/useTweakTool';
import OrdersTabContentData from './OrdersTabContentData';
import OrdersTabFilters from './OrdersTabFilters';
import OrdersTabDataTweaks from './OrdersTabDataTweaks';
import FormCard from '../Common/Form/FormCard';
import {IFilters} from '../../store/rtk/reducers/serverPaginationSlice';
import TabLoader from '../Common/Loader/TabLoader';
import {ShopService} from '../../http/shop/ShopService';
import {ClientService} from '../../http/client/ClientService';
import {PartnerService} from '../../http/partner/PartnerService';
import {IClient} from '../../types/app/client/Client';
import {IPartner} from '../../types/app/partner/Partner';
import {unix} from 'moment/moment';
import {isString} from 'lodash';
import Divider from '../../UI/Delimiter/Divider';

export const defaultFilterState: IFilters = {
  from: {
    createdDate: null,
    createdTime: null,
    amount: null,
    paid: null,
  },
  to: {
    createdDate: null,
    createdTime: null,
    amount: null,
    paid: null,
  },
  eqArray: {
    statusId: [],
    clientIds: [],
    partnerIds: []
  },
};

const OrdersIndex = () => {

  const [clients, setClients] = useState<Map<number, IClient>>(null)
  const [partners, setPartners]= useState<Map<number, IPartner>>(null)

  const {
    items,
    setFilters,
    getPaginatable,
    getFilterable,
    getDialogFilterable,
    setServerMethod,
    setMutator
  } = useTweakTool<IOrder>();

  useEffect(() => {
    setMutator(params => {

      if (params.created_date_end) {
        params.created_date_end = unix(params.created_date_end).format('YYYY-MM-DD');
      }

      if (params.created_date_start) {
        params.created_date_start = unix(params.created_date_start).format('YYYY-MM-DD');
      }

      if (params.created_time_end) {
        params.created_time_end = unix(params.created_time_end).format('HH:mm');
      }

      if (params.created_time_start) {
        params.created_time_start = unix(params.created_time_start).format('HH:mm');
      }

      if (params.client_ids) {
        if (isString(params.client_ids)) {
          params.client_ids = params.client_ids.split(',').map(el => +el);
        }
      }
      if (params.partner_ids) {
        if (isString(params.partner_ids)) {
          params.partner_ids = params.partner_ids.split(',').map(el => +el);
        }
      }
      if (params.status_id && isString(params.status_id)) {
        params.status_id = params.status_id.split(',')
      }

      return params;
    });

    setServerMethod(ShopService.getOrders)
    ClientService.getClients().then(clients => {
      const clientsMap = new Map<number, IClient>
      clients.forEach(client => clientsMap.set(client.id, client))
      setClients(clientsMap)
    })
    PartnerService.getPartners().then(partners => {
      const partnersMap= new Map<number, IPartner>
      partners.forEach(partner => partnersMap.set(partner.key, partner))
      setPartners(partnersMap)
    })
    setFilters(defaultFilterState)
  }, []);


  if (!items || !clients || !partners) {
    return <TabLoader/>;
  }

  return (
    <FormCard template="full" gridProps={{title: 'Заказы Пользователей'}}>
      <OrdersTabFilters {...getDialogFilterable} />

      <OrdersTabDataTweaks {...getFilterable} />

      <Divider />

      <OrdersTabContentData {...getPaginatable} clients={clients} partners={partners}/>
    </FormCard>
  );
};

export default OrdersIndex;
