import React from 'react';
import { Grid } from '@mui/material';
import TableContainer from '../../../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { encode } from 'js-base64';
import {IUserListItem, IUserListItemPaginated} from '../../../../types/app/auth/UserListItem';
import Confirm from '../../Modal/Confirm';
import { useAccountDeleteConfirm } from '../../../../hooks/useAccountDeleteConfirm';
import { getNameByUserType } from '../../../../utils/authUtils';
import { IPaginatable } from '../../../../types/app/common/Paginatable';
import { RouteList } from '../../../../http/RouteList';
import ShowButton from '../../../../UI/Button/ShowButton';
import DeleteButton from '../../../../UI/Button/DeleteButton';
import { UserTypes } from '../../../../enums/UserTypes';
import {IRole} from '../../../../types/app/auth/Role';
import {useTweakTool} from '../../../../hooks/table/useTweakTool';
import {useAppSelector} from '../../../../hooks/hooks';
import {getAuthUser} from '../../../../store/rtk/store';

interface IAccountTabContentDataProps extends IPaginatable<IUserListItemPaginated> {
  roleMap: Map<number, IRole>;
  type: UserTypes.PARTNER | UserTypes.OPERATOR;
}

const AccountTabContentData = (props: IAccountTabContentDataProps) => {
  const { request } = useTweakTool<IUserListItem>();
  const { showConfirm, closeConfirm, isLoading, openConfirm, deleteAccountHandler } = useAccountDeleteConfirm(
    request,
    props.items
  );

  const currentAccount = useAppSelector(getAuthUser)
  
  const showRoute = props.type === UserTypes.OPERATOR ? RouteList.USERS_SHOW : RouteList.PARTNERS_SHOW;

  return (
    <TableContainer boxprops={{ style: {top: '-16px'}}}>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteAccountHandler}
        text={`Вы действительно хотите удалить аккаунт? Данное действие нельзя будет отменить.`}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={100}>№</TableCell>
            <TableCell width={250}>ФИО сотрудника</TableCell>
            <TableCell width={250}>Email</TableCell>
            <TableCell width={250}>Тип</TableCell>
            <TableCell width={200}>Роль</TableCell>
            <TableCell width={100}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((account, index) => (
            <TableRow
              key={account.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                transition: 'all .5s ease-out',
              }}
            >
              <TableCell>{props.pageIndex + index + 1}</TableCell>
              <TableCell>{account.name}</TableCell>
              <TableCell>{account.email}</TableCell>
              <TableCell>{getNameByUserType(account.type)}</TableCell>
              <TableCell>{props.roleMap.get(account.roleId)?.name ?? ''}</TableCell>
              <TableCell>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <ShowButton
                      buttonProps={{ disabled: isLoading }}
                      linkProps={{ to: showRoute + '/' + encode(account.email) }}
                    />
                  </Grid>
                  {account.id !== 1 && account.email !== currentAccount.email &&
                    <Grid item>
                      <DeleteButton disabled={isLoading} onClick={() => openConfirm(account)} />
                    </Grid>
                  }
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountTabContentData;
