export enum UserTypes {
  USER = 'user',
  PARTNER = 'partner',
  OPERATOR = 'operator',
  CLIENT = 'client',
}

export enum UserTypeValues {
  USER,
  PARTNER,
  OPERATOR,
}

export enum SystemUserIds {
  OPERATOR,
}

export const getUserValueByType = (type: UserTypes): UserTypeValues => {
  switch (type) {
    case UserTypes.OPERATOR:
      return UserTypeValues.OPERATOR;
    case UserTypes.PARTNER:
      return UserTypeValues.PARTNER;

    default: return UserTypeValues.USER;
  }
}

/**
 * Get UserTypes
 *
 * @param value
 */
export const getUserTypeByValue = (value: UserTypeValues): UserTypes => {
  switch (value) {
    case UserTypeValues.OPERATOR:
      return UserTypes.OPERATOR;
    case UserTypeValues.PARTNER:
      return UserTypes.PARTNER;

    default: return UserTypes.USER;
  }
}