import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useAsyncValue, useNavigate} from 'react-router-dom';
import MainForm from '../../../Common/Form/MainForm';
import {Box, Grid, IconButton, Typography} from '@mui/material';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import {ICreateNewsLoader} from '../../../../http/communication/CommunicationLoaders';
import Divider from '../../../../UI/Delimiter/Divider';
import FormSwitch from '../../../../UI/Form/FormSwitch';
import FormActions from '../../../../UI/Form/FormActions';
import FormDateTimePicker from '../../../../UI/Pickers/FormDateTimePicker';
import moment, {Moment} from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import {RouteList} from '../../../../http/RouteList';
import {PeriodicityType} from '../../../../enums/PeriodicityType';
import {FormContext, IFormConfig, TFieldRule} from '../../../../store/context/form/FormContext';
import FormErrorText from '../../../../UI/Form/FormErrorText';
import {useNotifications} from '../../../../hooks/useNotifications';
import {messages} from '../../../../notifications/messages';
import {getGroupTypeTranslation} from '../../../../enums/Groups';
import {snackConfig} from '../../../../layout/assets/config/snackConfig';
import {useSnackbar} from 'notistack';
import FormImage from '../../../../UI/Form/FormImage';
import {useActions, useAppSelector} from '../../../../hooks/hooks';

const NewsFormCreate = () => {
  const {groups, profiles} = useAsyncValue() as ICreateNewsLoader;

  const [isGroupSelected, setIsGroupSelected] = useState<boolean>(false);
  const [isProfilesSelected, setIsProfilesSelected] = useState<boolean>(false);
  const [isSingle, setIsSingle] = useState<boolean>(true);
  const [isPeriodic, setIsPeriodic] = useState<boolean>(false);

  const [start, setStart] = useState<Moment | null>(null);
  const [end, setEnd] = useState<Moment | null>(null);

  const {success} = useNotifications();
  const {enqueueSnackbar} = useSnackbar();

  const route = useNavigate();

  const {setConfig, errors, softReset} = useContext(FormContext);
  const {setFiles} = useActions();
  const {files} = useAppSelector(state => state.storage);

  const dateChecker = () => {
    if (start?.format('YYYY-MM-DD HH:mm:ss') < moment().format('YYYY-MM-DD HH:mm:ss')) {
      enqueueSnackbar('Нельзя выбрать прошедшую дату или прошедшее время', {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });

      return false;
    }

    if ((start && end) && (start.format('YYYY-MM-DD HH:mm:ss') === end.format('YYYY-MM-DD HH:mm:ss'))) {
      enqueueSnackbar('Дата завершения должна быть позднее даты начала', {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });

      return false;
    }

    return true;
  };

  const getRules = useCallback<() => TFieldRule>(() => {
    const rules: TFieldRule = {
      title: ['required'],
      description: ['required'],
    };

    if (!isGroupSelected && !isProfilesSelected) {
      rules['profile_id'] = ['required'];
      rules['group_id'] = ['required'];
    }

    if (isPeriodic) {
      rules['quantity'] = ['required'];
    }

    return rules;
  }, [isGroupSelected, isProfilesSelected, isPeriodic, isSingle]);

  const formConfig: IFormConfig = {
    action: RouteList.NOTIFICATIONS_NEWS_CREATE,
    readonly: false,
    data: [
      {field: 'file', value: files[0] ? files[0].data : ''},
      {field: 'file_name', value: files[0] ? files[0]?.fileName : ''},
    ],
    rules: getRules(),
    afterSubmitHandler: (formResponse, config, setConfig) => {
      setFiles([]);
      success(messages.success.newsCreated);
      route(RouteList.NOTIFICATIONS_NEWS_SHOW + '/' + formResponse.payload);
      setConfig({...config, readonly: false});
    },
  };

  useEffect(() => {
    softReset();
    setConfig(formConfig);
    setFiles(files);
  }, [isGroupSelected, isProfilesSelected, isPeriodic, isSingle]);

  useEffect(() => {
    setFiles([]);
  }, []);

  return (
    <MainForm back={RouteList.NOTIFICATIONS}>
      <Typography mb={5} variant="h1">
        Создание рассылки
      </Typography>

      <FormOutlinedInput title="Заголовок рассылки" inputProps={{name: 'title'}}/>

      <Divider/>

      <Typography marginBottom={1} variant="body2" color="grey.700">Изображение:</Typography>

      <FormImage name="icon"/>

      <Divider/>

      <FormOutlinedInput title="Текст рассылки" inputProps={{name: 'description', rows: 5, multiline: true}}/>

      <Divider/>

      <Box>
        <FormSelect
          selectProps={{
            disabled: isProfilesSelected,
            error: !!errors['group_id'],
            name: 'group_id',
            multiple: true,
          }}
          onSelectedChange={selectedItems => {
            if (selectedItems.length && !isGroupSelected) setIsGroupSelected(true);
            if (!selectedItems.length && isGroupSelected) setIsGroupSelected(false);
          }}
          title="Список групп"
          items={groups.map(group => ({key: group.id, label: getGroupTypeTranslation(group.name)}))}
        />
        {errors['group_id'] && (
          <FormErrorText error="Необходимо выбрать группы Пользователей или указать Пользователей для рассылки"/>
        )}
      </Box>

      <Divider/>

      <Box>
        <FormSelect
          title="Список Пользователей"
          onSelectedChange={selectedItems => {
            if (selectedItems.length && !isProfilesSelected) setIsProfilesSelected(true);
            if (!selectedItems.length && isProfilesSelected) setIsProfilesSelected(false);
          }}
          items={[...profiles.map(profile => ({key: profile.id, label: profile.email}))]}
          selectProps={{
            disabled: isGroupSelected,
            error: !!errors['profile_id'],
            multiple: true,
            name: 'profile_id',
          }}
        />
        {errors['profile_id'] && (
          <FormErrorText error="Необходимо выбрать группы Пользователей или указать Пользователей для рассылки"/>
        )}
      </Box>

      <Divider/>

      <Grid container columns={2} spacing={1}>
        <input type="hidden" name="start" value={start?.format('YYYY-MM-DD HH:mm:ss') ?? ''}/>
        <Grid item xs={1} position="relative">
          <FormDateTimePicker
            title="Дата начала"
            datetimepickerProps={{
              disablePast: true,
              value: start,
              onChange: (moment: Moment) => {
                setStart(moment);
                setEnd(null);
              },
            }}
          />
        </Grid>
        <Grid item xs={1} position="relative">
          <input type="hidden" name="end" value={end?.format('YYYY-MM-DD HH:mm:ss') ?? ''}/>
          <FormDateTimePicker
            title="Дата завершения"
            datetimepickerProps={{
              disablePast: true,
              disabled: start === null,
              minDate: start,
              minTime: start,
              value: end,
              onChange: (moment: Moment) => setEnd(moment),
            }}
          />

          {end && (
            <Box position="absolute" top={35} right={35}>
              <IconButton
                onClick={() => setEnd(null)}
                color="error"
              >
                <CloseIcon fontSize="small"/>
              </IconButton>
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider/>

      <Grid container spacing={3} mt={3} mb={3} alignItems="center" columns={3}>
        <Grid item xs={1}>
          <FormSwitch
            title="Разовая рассылка"
            switchProps={{
              checked: isSingle,
              onChange: () => {
                setIsSingle(!isSingle);
                setIsPeriodic(isSingle);
              },
              name: 'is_single',
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <FormSwitch
            title="Периодическая рассылка"
            switchProps={{
              checked: isPeriodic,
              onChange: () => {
                setIsPeriodic(!isPeriodic);
                setIsSingle(isPeriodic);
              },
              name: 'is_periodic',
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container columns={2} spacing={1}>
        <Grid item xs={1}>
          <FormSelect
            title="Периодичность"
            items={PeriodicityType.asSelectable()}
            selectProps={{
              disabled: isSingle,
              defaultValue: PeriodicityType.asSelectable()[0].key,
              fullWidth: true,
              name: 'periodicity',
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <FormOutlinedInput
            title="Количество"
            rule={e => +e.target.value >= 1 && !isNaN(+e.target.value)}
            inputProps={{
              disabled: isSingle,
              defaultValue: 1,
              fullWidth: true,
              name: 'quantity',
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <FormActions beforeSubmit={dateChecker} title="Отправить"/>
    </MainForm>
  );
};

export default NewsFormCreate;
