import React, {useContext, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import {useAsyncValue} from 'react-router-dom';
import {IGetProductLoader} from '../../../../http/catalog/CatalogLoaders';
import {FormContext} from '../../../../store/context/form/FormContext';
import {RouteList} from '../../../../http/RouteList';
import {snackConfig} from '../../../../layout/assets/config/snackConfig';
import MainForm from '../../../Common/Form/MainForm';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../UI/Delimiter/Divider';
import {Grid, Typography} from '@mui/material';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import FormSwitch from '../../../../UI/Form/FormSwitch';
import FormImage from '../../../../UI/Form/FormImage';
import FormActions from '../../../../UI/Form/FormActions';
import {ProductStatuses} from '../../../../enums/ProductStatuses';
import {useActions} from '../../../../hooks/hooks';

const CatalogShowForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { product, categories, productStatuses, deliveryTypes, statusHistory } = useAsyncValue() as IGetProductLoader;
  const { setConfig, config, softReset } = useContext(FormContext);
  const { setFiles } = useActions();

  const [isRecurrent, setIsRecurrent] = useState<boolean>(product.isRecurrent);
  const [isCertificate, setIsCertificate] = useState<boolean>(product.byCertificate);

  const [productStatus, setProductStatus] = useState<ProductStatuses>(product.productStatusId);

  const onSelectChangeHandler = (value: string) => {
    setProductStatus(Number(value));
  };

  const formConfig = {
    action: RouteList.CATALOG_SHOW + '/' + product.id,
    readonly: true,
    rules: {
      name: ['required'],
      price: ['required', 'digits'],
      delivery_type: ['required'],
      description: ['max:1500'],
      short_description: ['max:1000'],
    },
    afterSubmitHandler: (_, config, setConfig) => {
      enqueueSnackbar('Товар обновлен. Изменения сохранены.', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      setConfig({ ...config, readonly: true });

      setFiles([]);
    },
  };

  const ruleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (isNaN(value)) {
      return false;
    }

    return value >= 1;
  };

  useEffect(() => {
    if (!config.readonly) {
      const rules = { ...formConfig.rules };

      if (isRecurrent) {
        rules['recurrent_period'] = ['required', 'min:1'];
      } else {
        delete rules['recurrent_period'];
      }

      softReset();

      setConfig({
        ...formConfig,
        readonly: false,
        rules,
      });
    }
  }, [config.readonly, isRecurrent]);

  useEffect(() => {
    setConfig({
      ...formConfig,
      readonly: true,
    });
    setFiles([]);
  }, []);

  return (
    <MainForm back={RouteList.CATALOG}>
      <FormOutlinedInput
        title="Наименование товара/услуги"
        typography="h2"
        inputProps={{
          defaultValue: product.name,
          name: 'name',
        }}
      />

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormOutlinedInput
            title="Стоимость товара/услуги"
            inputProps={{
              name: 'price',
              defaultValue: Number(product.price),
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid item xs>
        <FormSelect
          items={productStatuses.map(status => {
            return {
              key: status.id,
              label: status.description,
            };
          })}
          title="Статус товара"
          selectProps={{
            name: 'product_status_id',
            value: productStatus,
            onChange: event => {
              onSelectChangeHandler(event.target.value.toString());
            },
          }}
        />
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormSelect
            items={categories}
            title="Категория товара/услуги"
            selectProps={{ name: 'category_id', defaultValue: product.categoryId }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormSelect
            items={deliveryTypes}
            title="Вид доставки"
            selectProps={{ name: 'delivery_type', defaultValue: product.deliveryType }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormOutlinedInput
            title="Полное описание"
            inputProps={{
              name: 'description',
              defaultValue: product.description,
              multiline: true,
              minRows: 8,
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormOutlinedInput
            title="Короткое описание"
            inputProps={{
              name: 'short_description',
              defaultValue: product.shortDescription,
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container flexDirection="row" alignItems="center">
        <Grid item xs={7}>
          <FormSwitch
            title="Возможность подключения автоплатежа"
            switchProps={{
              name: 'is_recurrent',
              defaultChecked: product.isRecurrent,
              onChange: () => {
                setIsRecurrent(!isRecurrent);
              },
            }}
          />
        </Grid>
        {isRecurrent && (
          <Grid item xs={3}>
            <FormOutlinedInput
              title="Период автоплатежа, дней"
              rule={ruleHandler}
              inputProps={{
                name: 'recurrent_period',
                defaultValue: product.recurrentPeriod !== 0 ? product.recurrentPeriod : 1,
              }}
            />
          </Grid>
        )}
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormSwitch
            title="Возможность оплатить сертификатом"
            switchProps={{
              name: 'by_certificate',
              defaultChecked: Boolean(product.byCertificate),
              onChange: () => {
                setIsCertificate(!isCertificate);
              },
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1} maxWidth={100}>
        <Grid item xs>
          <FormImage name="main_image" src={product.mainImage} />
        </Grid>
      </Grid>

      <Divider />
      <Typography variant="body2" color="grey.600" mb={1}>
        Изображения товара для мобильного приложения:
      </Typography>
      <Grid container columns={3} spacing={1} maxWidth={100}>
        <Grid item xs>
          <FormImage name="mobile_image" formKey="mobile_image_file" src={product.mobileImage} />
        </Grid>
      </Grid>

      <FormActions />
    </MainForm>
  );
};

export default CatalogShowForm;
