import React from 'react';
import {Button, Card, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { RouteList } from '../../../http/RouteList';

const ChangePasswordConfirmation = () => {
  const link = useNavigate();

  return (
    <Grid container columns={12} justifyContent="center">
      <Grid item xs sm={10} md={7} lg={7} xl={5}>
        <Grid container bgcolor="white" width="100%" borderRadius={4} component={Card} padding={5} pb={10}>
          <Grid item xs margin="auto" sm={8} md={7} lg={6} pt={5} minWidth={200}>
            <Grid container justifyContent="center" flexDirection="column" alignItems="center">
              <Grid item>
                <CheckCircleOutlineIcon color="success" sx={{fontSize: 75}}/>
              </Grid>

              <Grid item>
                <Typography textAlign="center" variant="subtitle2">
                  Пароль был успешно изменен
                </Typography>
              </Grid>

              <Grid item>
                <Button
                  sx={{borderRadius: '16px', padding: '19px 32px', marginTop: 2}}
                  onClick={() => link(RouteList.AUTH_LOGIN)}
                >
                  <Typography
                    color="#CBDDF7"
                    fontFamily="Lato-ExtraBold"
                  >
                    Ко входу в систему
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordConfirmation;
