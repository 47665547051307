import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useAsyncValue} from 'react-router-dom';
import MainForm from '../../../Common/Form/MainForm';
import {Box, Grid, IconButton, Typography} from '@mui/material';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import {IShowNewsLoader} from '../../../../http/communication/CommunicationLoaders';
import Divider from '../../../../UI/Delimiter/Divider';
import FormSwitch from '../../../../UI/Form/FormSwitch';
import FormActions from '../../../../UI/Form/FormActions';
import FormDateTimePicker from '../../../../UI/Pickers/FormDateTimePicker';
import {Moment, unix} from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import {RouteList} from '../../../../http/RouteList';
import {PeriodicityType} from '../../../../enums/PeriodicityType';
import {FormContext, IFormConfig, TFieldRule} from '../../../../store/context/form/FormContext';
import FormErrorText from '../../../../UI/Form/FormErrorText';
import {useNotifications} from '../../../../hooks/useNotifications';
import {messages} from '../../../../notifications/messages';
import {getGroupIds, getProfileIds, isHasGroups, isHasProfiles} from '../../../../utils/communicationUtils';
import {INews} from '../../../../types/app/communication/News';
import {useSnackbar} from 'notistack';
import moment from 'moment/moment';
import {snackConfig} from '../../../../layout/assets/config/snackConfig';
import {getGroupTypeTranslation} from '../../../../enums/Groups';
import FormImage from '../../../../UI/Form/FormImage';
import {useActions, useAppSelector} from '../../../../hooks/hooks';

const NewsFormShow = () => {
  const { groups, profiles, newsItem } = useAsyncValue() as IShowNewsLoader;

  const [item, setItem] = useState<INews>(newsItem);

  const [isGroupSelected, setIsGroupSelected] = useState<boolean>(isHasGroups(item));
  const [isProfilesSelected, setIsProfilesSelected] = useState<boolean>(isHasProfiles(item));
  const [isSingle, setIsSingle] = useState<boolean>(item.periodicity === null);
  const [isPeriodic, setIsPeriodic] = useState<boolean>(item.periodicity !== null);

  const [start, setStart] = useState<Moment | null>(unix(item.start));
  const [end, setEnd] = useState<Moment | null>(item.end ? unix(item.end) : null);

  const { success } = useNotifications();
  const { enqueueSnackbar } = useSnackbar();

  const { setConfig, errors, softReset, config } = useContext(FormContext);
  const { setFiles } = useActions();
  const { files } = useAppSelector(state => state.storage);

  const dateChecker = () => {
    if (start?.format('YYYY-MM-DD HH:mm:ss') < moment().format('YYYY-MM-DD HH:mm:ss')) {
      enqueueSnackbar('Нельзя выбрать прошедшую дату или прошедшее время', {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });

      return false
    }

    if ((start && end) && (start.format('YYYY-MM-DD HH:mm:ss') === end.format('YYYY-MM-DD HH:mm:ss'))) {
      enqueueSnackbar('Дата завершения должна быть позднее даты начала', {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });

      return false
    }

    return true
  }
  
  const getRules = useCallback<() => TFieldRule>(() => {
    const rules: TFieldRule = {
      title: ['required'],
      description: ['required'],
    };

    if (isPeriodic) {
      rules['quantity'] = ['required'];
    }

    if (!isGroupSelected && !isProfilesSelected) {
      rules['profile_id'] = ['required'];
      rules['group_id'] = ['required'];
    }

    return rules;
  }, [isGroupSelected, isProfilesSelected, isPeriodic, isSingle]);


  const formConfig: IFormConfig = {
    data: [
      { field: 'id', value: item.id },
    ],
    action: RouteList.NOTIFICATIONS_NEWS_SHOW + '/' + item.id,
    rules: getRules(),
    readonly: true,
    afterSubmitHandler: (formResponse, config, setConfig) => {
      success(messages.success.newsUpdated);
      setItem(formResponse.payload as INews);

      setConfig({...config, readonly: true});
      setFiles([]);
    },
  };

  useEffect(() => {
    softReset();
    setFiles([]);
    if (config.readonly === false) {
      setConfig({ ...formConfig, readonly: false });
    }
  }, [isGroupSelected, isProfilesSelected, isPeriodic, isSingle]);

  useEffect(() => {
    setConfig(formConfig);
    setItem(newsItem);
    setFiles(files);
  }, []);

  return (
    <MainForm isHiddenEditAndCloseButtons={!newsItem.isActive} back={RouteList.NOTIFICATIONS}>
      {!newsItem.isActive && (
        <>
          <FormOutlinedInput
            typography="h1"
            title="Уникальный идентификатор уведомления"
            inputProps={{ defaultValue: newsItem.id }}
          />
          <Divider />
        </>
      )}

      <FormOutlinedInput typography="h1" title="Заголовок рассылки" inputProps={{ name: 'title', defaultValue: item.title }} />

      <Divider />

      <Typography marginBottom={1} variant="body2" color="grey.700">
        Изображение:
      </Typography>

      <FormImage name="icon" src={newsItem.params ? newsItem.params.icon : null} />

      <Divider />

      <FormOutlinedInput
        title="Текст рассылки"
        inputProps={{
          name: 'description',
          rows: 5,
          multiline: true,
          defaultValue: item.description,
        }}
      />

      <Divider />

      <Box>
        <FormSelect
          selectProps={{
            error: !!errors['group_id'],
            name: 'group_id',
            multiple: true,
            defaultValue: getGroupIds(item),
          }}
          onSelectedChange={selectedItems => {
            if (selectedItems.length && !isGroupSelected) setIsGroupSelected(true);
            if (!selectedItems.length && isGroupSelected) setIsGroupSelected(false);
          }}
          title="Список групп"
          items={groups.map(group => ({ key: group.id, label: getGroupTypeTranslation(group.name) }))}
        />
        {errors['group_id'] && (
          <FormErrorText error="Необходимо выбрать группы Пользователей или указать Пользователей для рассылки" />
        )}
      </Box>

      <Divider />

      <Box>
        <FormSelect
          title="Список Пользователей"
          onSelectedChange={selectedItems => {
            if (selectedItems.length && !isProfilesSelected) setIsProfilesSelected(true);
            if (!selectedItems.length && isProfilesSelected) setIsProfilesSelected(false);
          }}
          items={[...profiles.map(profile => ({ key: profile.id, label: profile.email }))]}
          selectProps={{
            error: !!errors['profile_id'],
            multiple: true,
            name: 'profile_id',
            defaultValue: getProfileIds(item),
          }}
        />
        {errors['profile_id'] && (
          <FormErrorText error="Необходимо выбрать группы Пользователей или указать Пользователей для рассылки" />
        )}
      </Box>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs={1}>
          <FormOutlinedInput
            title="Отправлено на email"
            inputProps={{
              disabled: true,
              defaultValue: item.mailCreatedCount,
              fullWidth: true,
              name: 'mail_created_count',
            }}
          />
        </Grid>

        <Grid item xs={1}>
          <FormOutlinedInput
            title="Отправлено в ЛК"
            inputProps={{
              disabled: true,
              defaultValue: item.webCreatedCount,
              fullWidth: true,
              name: 'web_created_count',
            }}
          />
        </Grid>

      <Grid item xs={1}>
          <FormOutlinedInput
            title="Прочитано в ЛК"
            inputProps={{
              disabled: true,
              defaultValue: item.webMarkedCount,
              fullWidth: true,
              name: 'web_marked_count',
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <input type="hidden" name="start" value={start?.format('YYYY-MM-DD HH:mm:ss') ?? ''} />
        <Grid item xs={1} position="relative">
          <FormDateTimePicker
            title="Дата начала"
            datetimepickerProps={{
              disablePast: true,
              value: start,
              onChange: (moment: Moment) => setStart(moment),
            }}
          />
        </Grid>
        <Grid item xs={1} position="relative">
          <input type="hidden" name="end" value={end?.format('YYYY-MM-DD HH:mm:ss') ?? ''} />
          <FormDateTimePicker
            nullable
            showDeleteButton={false}
            title="Дата завершения"
            datetimepickerProps={{
              disablePast: true,
              disabled: start === null,
              value: end,
              minDate: start,
              minTime: start,
              onChange: moment => setEnd(moment),
            }}
          />

          {end && !config.readonly && (
            <Box position="absolute" top={35} right={35}>
              <IconButton
                onClick={() => {
                  setEnd(null);
                }}
                color="error"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Grid>

        <Grid item xs={1}></Grid>
      </Grid>

      <Divider />

      <Grid container spacing={3} mt={3} mb={3} alignItems="center" columns={3}>
        <Grid item xs={1}>
          <FormSwitch
            title="Разовая рассылка"
            switchProps={{
              checked: isSingle,
              onChange: () => {
                setIsSingle(!isSingle);
                setIsPeriodic(isSingle);
              },
              name: 'is_single',
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <FormSwitch
            title="Периодическая рассылка"
            switchProps={{
              checked: isPeriodic,
              onChange: () => {
                setIsPeriodic(!isPeriodic);
                setIsSingle(isPeriodic);
              },
              name: 'is_periodic',
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs={1}>
          <FormSelect
            title="Периодичность"
            items={PeriodicityType.asSelectable()}
            selectProps={{
              disabled: isSingle,
              defaultValue: item.periodicity ?? '',
              fullWidth: true,
              name: 'periodicity',
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <FormOutlinedInput
            title="Количество"
            rule={e => +e.target.value >= 1 && !isNaN(+e.target.value)}
            inputProps={{
              disabled: isSingle,
              defaultValue: !isSingle ? item.quantity : '',
              fullWidth: true,
              name: 'quantity',
            }}
          />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>

      <Divider />

      <FormSwitch
        title={item.isActive ? 'Включена' : 'Выключена'}
        switchProps={{
          defaultChecked: item.isActive,
          name: 'is_active',
        }}
      />

      <Divider />

      <FormActions beforeSubmit={dateChecker} title="Отправить" />
    </MainForm>
  );
};

export default NewsFormShow;
