import React, { useEffect } from 'react';
import { AuthService } from '../../../../http/auth/AuthService';
import { useTweakTool } from '../../../../hooks/table/useTweakTool';
import { IRole } from '../../../../types/app/auth/Role';
import TabLoader from '../../Loader/TabLoader';
import { Box } from '@mui/material';
import TabTitle from '../../../../UI/Tab/TabTitle';
import RolesTabContentData from './RolesTabContentData';
import { UserTypes } from '../../../../enums/UserTypes';
import RolesTabDataTweaks from './RolesTabDataTweaks';
import RolesTabFilters from './RolesTabFilters';
import { IFilters } from '../../../../store/rtk/reducers/serverPaginationSlice';

export const defaultFilterState: IFilters = {
  like: {
    role: '',
  },
  eq: {
    type: null,
  },
};

export interface IRolesTabContentMainProps {
  type: UserTypes;
}

const RolesTabContentMain = (props: IRolesTabContentMainProps) => {
  const { setItems, items, setFilters, getPaginatable, getFilterable, getDialogFilterable } = useTweakTool<IRole>();

  useEffect(() => {
    switch (props.type) {
      case UserTypes.OPERATOR:
        AuthService.getOperatorRoles().then(roles => setItems(roles));

        break;

      case UserTypes.PARTNER:
        AuthService.getPartnerRoles().then(roles => setItems(roles));

        break;

      default:
        throw new Error('User type is not supported');
    }

    setFilters(defaultFilterState);
  }, []);

  if (!items) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="Управление ролями" />

      <RolesTabDataTweaks type={props.type} {...getFilterable} />

      <RolesTabFilters {...getDialogFilterable} />

      <RolesTabContentData type={props.type} dispatch={setItems} {...getPaginatable} />
    </Box>
  );
};

export default RolesTabContentMain;
