import React from 'react';
import {Box, Button, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {RouteList} from '../../../http/RouteList';
import AddItemIcon from '@mui/icons-material/AddCircleOutline';

const ClientsTabDataTweaks = () => {
  const route = useNavigate();

  return (
    <Box mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Button onClick={() => route(RouteList.CLIENTS_RANK_CREATE)} endIcon={<AddItemIcon />}>
            Добавить статус
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientsTabDataTweaks;
