import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { decode, encode } from 'js-base64';
import { useEffect, useState } from 'react';
import { StringHelper } from '../helpers/StringHelper';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PER_PAGE, DEFAULT_PER_PAGE_ROWS } from '../store/rtk/reducers/serverPaginationSlice';
import {IPaginatedParams} from '../types/app/common/Paginated';

export const URL_HASH_TAB_KEY = 'tab';
export const URL_SEARCH_PAGE_KEY = 'page';
export const URL_SEARCH_PER_PAGE_KEY = 'per-page';
export const DEFAULT_TAB_INDEX = 0;

export const useUrlManager = () => {
  const location = useLocation();
  const route = useNavigate();

  const [searchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(getTabIndexByUrl());

  useEffect(() => {
    setTabIndex(getTabIndexByUrl());
  }, [location.hash]);

  function go(pathname = window.location.pathname,  withQuery = true) {
    route(getUrl(pathname,  withQuery), {relative: 'path'});
  }

  function goByParams(params: {}) {
    const search = new URLSearchParams();

    for (const paramKey in params) {
      search.set(StringHelper.fromCamelCaseToKebabCase(paramKey), params[paramKey]);
    }

    const page = searchParams.get(URL_SEARCH_PAGE_KEY);

    if (page) {
      search.set(URL_SEARCH_PAGE_KEY, page);
    }

    const perPage = searchParams.get(URL_SEARCH_PER_PAGE_KEY);

    if (perPage) {
      search.set(URL_SEARCH_PER_PAGE_KEY, perPage);
    }

    route(window.location.pathname + '?' + search.toString() + getHash());
  }

  function deleteParamsFromUrl(params: string[]) {
    params.forEach(param => searchParams.delete(StringHelper.fromCamelCaseToKebabCase(param)));
    go();
  }

  function getUrl(pathname = window.location.pathname,  withQuery = true) {
    const search = withQuery ? '?' + searchParams.toString() : '';

    return pathname + search + getHash();
  }

  function getHash() {
    return location.hash.startsWith('#') ? location.hash : '#' + location.hash;
  }

  function perPageToUrl(perPage: number) {
    searchParams.set(URL_SEARCH_PER_PAGE_KEY, perPage.toString());
    go();
  }

  function pageToUrl(pageNumber: number) {
    searchParams.set(URL_SEARCH_PAGE_KEY, pageNumber.toString());
    go();
  }

  function tabHashingToUrl(tab: { page: string; tabIndex: number }) {
    location.hash = encode(URL_HASH_TAB_KEY + '-' + tab.tabIndex);
    go(window.location.pathname, false);
  }

  function getPerPageByUrl() {
    const perPage = parseInt(searchParams.get(URL_SEARCH_PER_PAGE_KEY));

    if (isNaN(perPage)) {
      return DEFAULT_PER_PAGE;
    }

    if (!DEFAULT_PER_PAGE_ROWS.includes(perPage)) {
      return DEFAULT_PER_PAGE;
    }

    return perPage;
  }

  function getPageByUrl() {
    const page = parseInt(searchParams.get(URL_SEARCH_PAGE_KEY));

    return !isNaN(page) ? page : DEFAULT_PAGE_NUMBER;
  }

  function getTabIndexByUrl() {
    const url = new URL(window.location.href);

    if (!url.hash) {
      return DEFAULT_TAB_INDEX;
    }

    const decodedHash = decode(url.hash);

    if (decodedHash.search('tab') === -1) {
      return DEFAULT_TAB_INDEX;
    }

    const match = decodedHash.match(URL_HASH_TAB_KEY + '-\\d');

    if (!match) {
      return DEFAULT_TAB_INDEX;
    }

    const urlTabIndex = parseInt(match[0].replace(URL_HASH_TAB_KEY + '-', ''));

    return !isNaN(urlTabIndex) ? urlTabIndex : DEFAULT_TAB_INDEX;
  }

  function getParamByKey(key: string) {
    return searchParams.get(StringHelper.fromCamelCaseToKebabCase(key));
  }

  function hasParamByKey(key: string) {
    return searchParams.has(StringHelper.fromCamelCaseToKebabCase(key))
  }

  function toRequestFormat(paramKey) {
    return StringHelper.fromKebabCaseToSnakeCase(
      StringHelper.fromCamelCaseToSnakeCase(paramKey)
    );
  }

  function prepareForRequest(params) {
    const result = {};

    for (const paramKey in params) {
      result[
        toRequestFormat(paramKey)
          .replace('_list', '')
        ] = params[paramKey];
    }

    return result;
  }

  function getParamsWithoutPagination() {
    const params = getParams();

    if (params[URL_SEARCH_PER_PAGE_KEY] || params[URL_SEARCH_PAGE_KEY]) {
      delete params[URL_SEARCH_PER_PAGE_KEY];
      delete params[URL_SEARCH_PAGE_KEY];
    }

    return prepareForRequest(params);
  }

  function getParams() {
    const url = new URL(window.location.href);
    const search = new URLSearchParams(url.search);
    return Object.fromEntries(Array.from(search.entries()));
  }

  const getParamsPagination = (): Omit<IPaginatedParams, 'count'> => {
    const params = getParams();

    return {
      page: params[URL_SEARCH_PAGE_KEY] ? parseInt(params[URL_SEARCH_PAGE_KEY]) : DEFAULT_PAGE_NUMBER,
      perPage: params[URL_SEARCH_PER_PAGE_KEY] ? parseInt(params[URL_SEARCH_PER_PAGE_KEY]) : DEFAULT_PER_PAGE,
    };
  };

  return {
    tabIndex,
    setTabIndex,

    toRequestFormat,

    go,

    deleteParamsFromUrl,

    hasParamByKey,

    tabHashingToUrl,
    pageToUrl,
    perPageToUrl,
    goByParams,

    prepareForRequest,

    getPerPageByUrl,
    getPageByUrl,
    getParamByKey,
    getParamsWithoutPagination,
    getParamsPagination,
    getUrl,
  };
};
