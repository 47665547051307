import { defer } from 'react-router-dom';
import { PartnerService } from '../partner/PartnerService';
import { IPartner } from '../../types/app/partner/Partner';
import { CatalogService } from './CatalogService';
import { ICategory } from '../../types/app/catalog/Category';
import { IProductStatus } from '../../types/app/catalog/ProductStatus';
import { IProductDetail } from '../../types/app/catalog/ProductDetail';
import {ISelectable} from '../../types/app/common/Selectable';
import {IStatusHistoryDetail} from '../../types/app/catalog/StatusHistoryDetail';

export interface IGetProductLoader {
  product: IProductDetail;
  categories: ICategory[];
  partners: IPartner[];
  productStatuses: IProductStatus[];
  deliveryTypes: ISelectable[];
  statusHistory: IStatusHistoryDetail[]
}

export interface IGetDataForNewProductLoader {
  categories: ICategory[];
  partners: IPartner[];
  deliveryTypes: ISelectable[];
}

export interface IGetCategoriesLoader {
  categories: ICategory[];
}

export class CatalogLoaders {
  public static getProductLoader = async ({ params }) => {
    const request = {
      product_id: Number(params.id),
    };

    return defer({
      content: Promise.all([
        CatalogService.getProduct(request),
        CatalogService.getCategories(),
        PartnerService.getPartners(),
        CatalogService.getProductStatuses(),
        CatalogService.getDeliveryTypes(),
        CatalogService.getProductStatusHistory(request)
      ]).then(
        data =>
          ({
            product: data[0],
            categories: data[1],
            partners: data[2],
            productStatuses: data[3],
            deliveryTypes: data[4],
            statusHistory: data[5],
          } as IGetProductLoader)
      ),
    });
  };

  public static getCategoriesLoader = async () => {
    return defer({
      categories: CatalogService.getCategories(),
    });
  };

  public static getDataForNewProductLoader = async () => {
    return defer({
      content: Promise.all([
        CatalogService.getCategories(),
        PartnerService.getPartners(),
        CatalogService.getDeliveryTypes(),
      ]).then(
        data =>
          ({
            categories: data[0],
            partners: data[1],
            deliveryTypes: data[2],
          } as IGetDataForNewProductLoader)
      ),
    });
  };
}
