import React from 'react';
import { IPaginatable } from '../../../types/app/common/Paginatable';
import { IRuleReport } from '../../../types/app/fraud/RuleReport';
import TableContainer from '../../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { unix } from 'moment';
import EmptyCell from '../../../UI/Table/EmptyCell';
import ShowButton from '../../../UI/Button/ShowButton';
import { RouteList } from '../../../http/RouteList';
import {getRuleName} from '../../../utils/fraudUtils';

const RuleReportTabContentData = (props: IPaginatable<IRuleReport>) => {

  return (
    <TableContainer boxprops={{ style: {top: '-16px'}}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={200}> Наименование правила </TableCell>
            <TableCell width={100}> Дата оповещения </TableCell>
            <TableCell width={100}> Время оповещения </TableCell>
            <TableCell width={30}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(report => (
            <TableRow key={report.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '100%' }}>
              <TableCell>{getRuleName(report.ruleSetting?.rule?.name) ?? <EmptyCell/>}</TableCell>
              <TableCell>{report.sentAt ? unix(report.sentAt).format('DD.MM.YYYY') : <EmptyCell />}</TableCell>
              <TableCell>{report.sentAt ? unix(report.sentAt).format('HH:mm') : <EmptyCell />}</TableCell>
              <TableCell>
                <ShowButton linkProps={{ to: RouteList.FRAUD_MONITORING_REPORT_SHOW + '/' + report.id, state: report }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RuleReportTabContentData;
