import React, {useState} from 'react';
import {Box, Button, Grid} from '@mui/material';
import PerPage from '../../../UI/Table/PerPage';
import FilterGroupButtons from '../../../UI/Button/FilterGroupButtons';
import {IFilterable} from '../../../types/app/common/Filterable';
import AddButton from '../../../UI/Button/AddButton';
import {RouteList} from '../../../http/RouteList';
import {useNavigate} from 'react-router-dom';
import LoadItemIcon from '@mui/icons-material/UploadFile';
import CatalogModal from './CatalogModal';

const CatalogTabDataTweaks = (props: IFilterable) => {
  const route = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Box width="100%">
      <CatalogModal
        open={showModal}
        close={()=> setShowModal(false)}
        title='Загрузка товаров'
      />

      <Grid container display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">

        <Grid display="flex" flexDirection="row" container alignItems="center" justifyContent="flex-start" spacing={1}
              maxWidth={360}>
          <Grid item>
            <AddButton onClick={() => route(RouteList.CATALOG_CREATE)}>Добавить товар</AddButton>
          </Grid>
          <Grid item>
            <Button endIcon={<LoadItemIcon/>} onClick={()=>setShowModal(true)}>
              Загрузить товары
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>
        <Grid item>
          <PerPage/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CatalogTabDataTweaks;
