import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetClientRankLoader } from '../../../http/client/ClientLoaders';
import ClientRanksShowForm from '../../../Widgets/Clients/ClientRanksTab/Operations/ClientRanksShowForm';

const ClientRankShow = () => {
  const { clientRank } = useLoaderData() as IGetClientRankLoader;

  return (
    <Await resolve={clientRank}>
      <ClientRanksShowForm />
    </Await>
  );
};

export default ClientRankShow;

