import React, { useContext, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import FormSelect from '../../../../../UI/Form/Select/FormSelect';
import Divider from '../../../../../UI/Delimiter/Divider';
import { LoadingButton } from '@mui/lab';
import { IRole } from '../../../../../types/app/auth/Role';
import { AuthService } from '../../../../../http/auth/AuthService';
import { snackConfig } from '../../../../../layout/assets/config/snackConfig';
import { ILoggedUser } from '../../../../../types/app/auth/LoggedUser';
import { useSnackbar } from 'notistack';
import { FormContext } from '../../../../../store/context/form/FormContext';
import { SystemUserRoles } from '../../../../../enums/SystemUserRoles';

interface IAccountShowFormMixinProps {
  userData: {
    user: ILoggedUser;
    roles: IRole[];
  };
}

const AccountShowFormMixin = ({ userData }: IAccountShowFormMixinProps) => {
  const [sendingEmail, setSendingEmail] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { config } = useContext(FormContext);

  const roles = useMemo<IRole[]>(() => userData.roles.filter(role => role.types.includes(userData.user.type)), [userData]);
  const isSelectForRoleHidden = useMemo<boolean>(() => roles.length === 1, [roles]);

  const restorePassword = async () => {
    setSendingEmail(true);

    try {
      await AuthService.forgotPassword({ email: userData.user.email });

      enqueueSnackbar('На адрес электронной почты было отправлено письмо для восстановления пароля.', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });
    } finally {
      setSendingEmail(false);
    }
  };

  const isRoleExists = useMemo(() => {
    return roles.some(role => role.id === userData.user.roleId);
  }, [roles, userData]);

  if (isSelectForRoleHidden) {
    return (
      <Box>
        <Typography variant="body2" color="grey.600" mb={1}>
          Доступная роль:
        </Typography>
        <Typography>{roles[0].name}</Typography>
        <input type="hidden" name="role_id" value={SystemUserRoles.MANAGER} />
      </Box>
    );
  }

  return (
    <Box>
      {userData.user.id !== 1 && 
        <>
          <FormSelect
            title="Роль"
            items={roles.map(role => ({ label: role.name, key: role.id }))}
            selectProps={{
              name: 'role_id',
              defaultValue: (isRoleExists || config.readonly) ? userData.user.roleId : roles[0].id,
            }}
          />
          <Divider />
        </>
      } 

      {config.readonly && (
        <Box>
          <Grid container flexDirection="column">
            <Grid item mb={1}>
              <Typography variant="body2" color="grey.600">
                Логин:
              </Typography>
            </Grid>

            <Grid item mb={3}>
              <Typography variant="body2">{userData.user.email}</Typography>
            </Grid>

            <Grid item mb={1}>
              <LoadingButton
                loading={sendingEmail}
                onClick={restorePassword}
                sx={{ backgroundColor: '#ECF5FF' }}
                variant="outlined"
              >
                Восстановить/изменить пароль
              </LoadingButton>
            </Grid>
          </Grid>
          {userData.user.id !== 1 && <Divider />}
        </Box>
      )}
    </Box>
  );
};

export default AccountShowFormMixin;
