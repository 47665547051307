import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {IPaginatable} from '../../../types/app/common/Paginatable';
import {TableHelper} from '../../../helpers/TableHelper';
import {ITransactionWithPagination} from '../../../types/app/account/Transaction';
import {getColorByType} from '../../../enums/TransactionTypes';
import EmptyCell from '../../../UI/Table/EmptyCell';
import {DateHelper} from '../../../helpers/DateHelper';
import TableContainer from '../../../UI/Table/TableContainer';
import {OperationTypes} from '../../../enums/OperationTypes';

const OperationHistoryTabContentData = (props: IPaginatable<ITransactionWithPagination>) => {
  return (
    <TableContainer boxprops={{ style: {top: '-16px'}}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={75}>№</TableCell>
            <TableCell width={150}>Дата</TableCell>
            <TableCell width={90}>Время</TableCell>
            <TableCell width={150}>ID транзакции</TableCell>
            <TableCell width={230}>Тип операции</TableCell>
            <TableCell width={200}>От кого</TableCell>
            <TableCell width={200}>Кому</TableCell>
            <TableCell width={200}>Сумма операции</TableCell>
            <TableCell width={200}>₽/Баллы</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((transaction, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{TableHelper.viewIndex(props.pageIndex, index)}</TableCell>
              <TableCell>{DateHelper.dateToReadFormat(transaction.createdDate)}</TableCell>
              <TableCell>{DateHelper.timeToReadFormat(transaction.createdTime)}</TableCell>
              <TableCell>{transaction.transactionId}</TableCell>
              <TableCell sx={{ color: getColorByType(transaction.typeId) }}>{transaction.description}</TableCell>
              <TableCell>{transaction.userFrom
                ? transaction.userFrom
                : transaction.userFromType === 'operator' ? 'Оператор' : <EmptyCell/>
              }
            </TableCell>
              <TableCell>{transaction.userTo
                ? transaction.userTo
                : transaction.userToType === 'operator' ? 'Оператор' : <EmptyCell/>
              }</TableCell>
              <TableCell>{transaction.amount.toLocaleString()}</TableCell>
              <TableCell>{transaction.amountType === OperationTypes.RUBLS ? '₽': 'Баллы'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OperationHistoryTabContentData;
