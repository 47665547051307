import {TReportTypes} from '../types/response/report/TGetReportTypesData';

export interface IReportTypes {
  key: ReportTypes,
  label: ReportTypeName
}

export enum ReportTypes {
  TURNOVER_POINTS = 'turnover_points',
  TERMINAL_RECEIPTS = 'terminal_receipts',
  PRODUCT_TOP = 'product_top',
  SUMMARY = 'summary',
}


export enum ReportTypeName {
  TURNOVER_POINTS = 'Оборот и баланс баллов',
  TERMINAL_RECEIPTS = 'Статистика чеков по торговым точкам',
  PRODUCT_TOP = 'Топ товаров по обороту',
  SUMMARY = 'Сводный отчет',
}


export const getReportTypeName = (title: ReportTypes): ReportTypeName =>{
  switch (title) {
    case ReportTypes.TURNOVER_POINTS: return ReportTypeName.TURNOVER_POINTS
    case ReportTypes.TERMINAL_RECEIPTS: return ReportTypeName.TERMINAL_RECEIPTS
    case ReportTypes.PRODUCT_TOP: return ReportTypeName.PRODUCT_TOP
    case ReportTypes.SUMMARY: return ReportTypeName.SUMMARY

    default: return
  }
}

export const getTypeId = (type: ReportTypes): number => {
  switch (type) {
    case ReportTypes.TURNOVER_POINTS: return 1
    case ReportTypes.TERMINAL_RECEIPTS: return 2
    case ReportTypes.PRODUCT_TOP: return 3
    case ReportTypes.SUMMARY: return 4

    default: return
  }
}

export const getReportTypeById = (id: number): ReportTypes =>{
  switch (id) {
    case 1: return ReportTypes.TURNOVER_POINTS
    case 2: return ReportTypes.TERMINAL_RECEIPTS
    case 3: return ReportTypes.PRODUCT_TOP
    case 4: return ReportTypes.SUMMARY

    default: return
  }
}

export const getReportTypeNames = (reportTypes: TReportTypes[]): IReportTypes[] => {
  const typesMap = new Map<ReportTypes, ReportTypes>()

  reportTypes.forEach((type) => typesMap.set(type.slug, type.name))

  return [
    {
      key: ReportTypes.TURNOVER_POINTS,
      label: getReportTypeName(typesMap.get(ReportTypes.TURNOVER_POINTS))
    },
    {
      key: ReportTypes.TERMINAL_RECEIPTS,
      label: getReportTypeName(typesMap.get(ReportTypes.TERMINAL_RECEIPTS))
    },
    {
      key: ReportTypes.PRODUCT_TOP,
      label: getReportTypeName(typesMap.get(ReportTypes.PRODUCT_TOP))
    },
    {
      key: ReportTypes.SUMMARY,
      label: getReportTypeName(typesMap.get(ReportTypes.SUMMARY))
    },
  ]
}