import {IStorageFileData} from '../store/rtk/reducers/storageSlice';
import axios from 'axios';
import fileDownload from 'js-file-download';

export class   FileHelper {
  /**
   * Read file content as base64 with promise action
   *
   * @param {File} file
   */
  public static async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();

      fr.onload = () => {
        const data = fr.result as string;

        resolve(data);
      }

      fr.onerror = reject;

      fr.readAsDataURL(file);
    })
  }

  /**
   * Get storage file size (mb)
   *
   * @param {IStorageFileData} files
   */
  public static getFileSizeMb = (files: IStorageFileData[]): number => {
    const size = files.reduce(((mb, item) => mb + item.size), 0);

    const toMb = (size / Math.pow(1024, 2)).toFixed(2);

    return Number(toMb);
  }

  /**
   * Format base64 for backend request
   *
   * @param base64
   */
  public static base64RequestFormat = (base64: string): string => {
    return base64.replace(/^data.+,/, '')
  }

  public static download = async (url: string, filename: string) => {
    const response = await axios.get(url, { responseType: 'blob'});

    fileDownload(response.data, filename)
  }
}