import moment from 'moment/moment';
import {TGetUserListData, TGetUserListDataPaginated} from '../../types/response/auth/GetUserListData';
import {IUserListItem, IUserListItemPaginated} from '../../types/app/auth/UserListItem';
import { UserTypes } from '../../enums/UserTypes';
import { TCreatedUser } from '../../types/app/auth/User';
import { TUser } from '../../types/response/auth/UserResponse';
import { IRole } from '../../types/app/auth/Role';
import { ISiteSection } from '../../types/app/auth/SiteSection';
import { keys } from 'lodash';
import { IGetSiteSectionsData } from '../../types/response/auth/GetSiteSectionsData';
import { TRole } from '../../types/response/auth/GetRoleResponse';
import { isSystem, UserRoleTypes } from '../../enums/SystemUserRoles';

export class AuthFactory {
  public static getRoleFromResponse(dataRole: TRole, name: string): IRole {
    return {
      id: dataRole.id,
      name: name,
      types: dataRole.types,
      description: dataRole.description,
      sections: [],
      type: isSystem(dataRole.id) ? UserRoleTypes.SYSTEM : UserRoleTypes.CUSTOM,
    };
  }

  public static getAccountUsersFromResponse(dataType: TGetUserListData): IUserListItem {
    return {
      id: dataType.id,
      name: dataType.name,
      email: dataType.email,
      typeId: dataType.type_id,
      type: dataType.type as UserTypes,
      roleId: dataType.role_id,
      role: dataType.role,
      externalId: dataType.external_id,
      internalId: dataType.internal_id,
      createdAt: dataType.created_at ? moment(dataType.created_at).unix() : null,
      updatedAt: dataType.updated_at ? moment(dataType.updated_at).unix() : null,
      emailVerifiedAt: dataType.email_verified_at ? moment(dataType.email_verified_at).unix() : null,
    };
  }

  public static getAccountUsersFromPaginatedResponse(dataType: TGetUserListDataPaginated): IUserListItemPaginated {
    return {
      id: dataType.id,
      name: dataType.name,
      email: dataType.email,
      typeId: dataType.type_id,
      type: dataType.type as UserTypes,
      roleId: dataType.role_id,
      role: dataType.role,
      externalId: dataType.external_id,
      internalId: dataType.internal_id,
      createdAt: dataType.created_at ? moment(dataType.created_at).unix() : null,
      updatedAt: dataType.updated_at ? moment(dataType.updated_at).unix() : null,
      emailVerifiedAt: dataType.email_verified_at ? moment(dataType.email_verified_at).unix() : null,
    };
  }

  public static getCreatedUserFromResponse(data: TUser): TCreatedUser {
    return {
      email: data.email,
      id: data.id,
      internalId: data.internal_id,
      name: data.name,
      roleId: data.role_id,
      type: data.type,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      updatedAt: data.created_at ? moment(data.updated_at).unix() : null,
    };
  }

  public static getSectionFromResponse = (data: IGetSiteSectionsData): ISiteSection => {
    return {
      description: data.description,
      id: data.id,
      methods: keys(data.methods).map(key => +key),
      roles: keys(data.roles).map(key => +key),
      sectionName: data.section_name,
      site: data.site,
    };
  };

  public static fillRolesBySections(roles: IRole[], sections: ISiteSection[]) {
    sections.forEach(section => {
      roles.forEach(role => {
        if (section.roles.includes(role.id)) {
          if (!role.sections) {
            role.sections = [];
          }

          role.sections.push(section);
        }
      });
    });
  }
}
