import React, {useContext, useState} from 'react';
import {Box, FormControl, InputLabel, OutlinedInput, OutlinedInputProps, Typography} from '@mui/material';
import FormErrorText from './FormErrorText';
import {Variant} from '@mui/material/styles/createTypography';
import {v4 as uuid4} from 'uuid';
import {FormContext} from '../../store/context/form/FormContext';
import InputMask from 'react-input-mask';
import RemoveIcon from '@mui/icons-material/Remove';

export interface FormOutlinedInputProps {
  title?: string,
  typography?: Variant,
  mask?: string | Array<(string | RegExp)>;
  inputProps?: OutlinedInputProps;
  rule?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => boolean;
}

const Masked = ({props, id}: { props: FormOutlinedInputProps, id: string }) => {
  const {errors, errorWatcher} = useContext(FormContext);
  const [value, setValue] = useState<string>('');
  const maskedProps = {};

  const onChangeHandler = props.inputProps?.onChange;

  const onChange = (event) => {
    if (!props.inputProps) {
      return;
    }

    if (props.inputProps.name) {
      errorWatcher(props.inputProps.name, event.target.value);
    }

    if (onChangeHandler) {
      setValue(event.target.value);

      onChangeHandler(event);
    }
  };

  if (props.inputProps?.defaultValue !== undefined || props.inputProps?.value !== undefined) {
    if (value) {
      maskedProps['value'] = value;
    }
    else {
      if (props.inputProps.value) {
        maskedProps['value'] = props.inputProps.value;

      }
      else {
        maskedProps['defaultValue'] = props.inputProps.defaultValue;

      }
    }

    delete props.inputProps?.value;
    delete props.inputProps?.defaultValue;
  }

  return (<InputMask
    mask={props.mask}
    onChange={onChange}
    {...maskedProps}
  >
    <OutlinedInput
      autoComplete="on"
      id={id}
      error={!!errors[props.inputProps?.name]}
      {...props.inputProps}
    />
  </InputMask>);
};

export const Input = ({props, id}: { props: FormOutlinedInputProps, id: string }) => {
  const {errors, errorWatcher} = useContext(FormContext);

  const onChangeHandler = props.inputProps?.onChange;

  return (<OutlinedInput
    autoComplete="on"
    id={id}
    error={!!errors[props.inputProps?.name]}

    sx={{
      '& input': {
        borderRadius: '16px !important',
      }
    }}

    {...props.inputProps}

    onChange={(event) => {
      if (!props.inputProps) {
        return;
      }

      let isValid = true;

      if (props.rule !== undefined) {
        isValid = props.rule(event);
      }

      if (!isValid) {
        event.target.value = event.target.value.slice(0, -1);

        return;
      }

      if (props.inputProps.name) {
        errorWatcher(props.inputProps.name, event.target.value);
      }

      if (onChangeHandler) {
        onChangeHandler(event);
      }
    }}
  />);
};

const FormOutlinedInput = (props: FormOutlinedInputProps) => {
  const {errors, config} = useContext(FormContext);
  const inputId = uuid4();

  return (
    <Box mb={1} position="relative">
      {props.title &&
          <Typography marginBottom={1} variant="body2" color="grey.700">
            {props.title}:
          </Typography>
      }
      {config.readonly
        ?
        <Box>
          {props.inputProps?.label &&
              <Typography mb={1} variant="body1" color="grey.500">
                {props.inputProps.label}
              </Typography>
          }
          <Typography
            variant={props.typography ?? 'body1'}>{getReadonlyText(props.inputProps)}
          </Typography>
        </Box>
        :
        <FormControl fullWidth>
          {props.inputProps?.label &&
              <Box mb={1}>
                <InputLabel htmlFor={inputId}>
                  {props.inputProps.label}
                </InputLabel>
              </Box>
          }

          {props.mask ? <Masked id={inputId} props={props}/> : <Input id={inputId} props={props}/>}

          {props.inputProps?.name &&
              <Box>
                {errors[props.inputProps.name] && <FormErrorText error={errors[props.inputProps.name]}/>}
              </Box>
          }
        </FormControl>
      }
    </Box>
  );
};

const getReadonlyText = (props: OutlinedInputProps): string | React.ReactNode => {
  let text = String(props?.defaultValue);

  if (text === 'undefined' || text === 'null') {
    text = String(props?.value);
  }

  if (text === 'null' || text === 'undefined' || text === '') {
    return <RemoveIcon sx={{ fontSize: 20 }} />;
  }

  return text;
}

export default FormOutlinedInput;