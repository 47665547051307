import React, {useContext, useEffect, useState} from 'react';
import {useAsyncValue} from 'react-router-dom';
import MainForm from '../../../Common/Form/MainForm';
import {RouteList} from '../../../../http/RouteList';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../UI/Delimiter/Divider';
import FormImage from '../../../../UI/Form/FormImage';
import {MimeTypes} from '../../../../enums/MimeTypes';
import FormActions from '../../../../UI/Form/FormActions';
import {FormContext, IFormConfig} from '../../../../store/context/form/FormContext';
import {useSnackbar} from 'notistack';
import {snackConfig} from '../../../../layout/assets/config/snackConfig';
import {ITemplate} from '../../../../types/app/communication/Template';
import {useActions} from '../../../../hooks/hooks';
import {Grid, OutlinedInput} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import {useNotifications} from '../../../../hooks/useNotifications';
import {messages} from '../../../../notifications/messages';
import {TemplateTypes} from '../../../../enums/TemplateTypes';
import {CommunicationService} from '../../../../http/communication/CommunicationService';

const NotificationTemplateShowForm = () => {
  const template = useAsyncValue() as ITemplate;
  const { setFiles } = useActions();
  const [isSending, setIsSending] = useState(false)
  const [email, setEmail] = useState<string>('')

  const {success, error} = useNotifications()

  const sendMessage = async () => {
    setIsSending(true)

    try {
      const response = await CommunicationService.sendEmegrgencyNotificationToEmail({
        template_id: template.id,
        email: email
      })
      response && success(messages.success.testNotificationSent)
      setEmail('')
    } catch (e) {
      error(messages.errors.generalError)

    } finally {
      setIsSending(false)
    }
  }

  const { enqueueSnackbar } = useSnackbar();

  const { setConfig } = useContext(FormContext);

  const formConfig: IFormConfig = {
    readonly: true,
    action: RouteList.NOTIFICATIONS_TEMPLATE_SHOW + '/' + template.id,
    data: [
      { field: 'id', value: template.id },
      { field: 'name', value: template.name },
    ],
    rules: {
      header: ['required'],
      category: ['required'],
      short: ['required'],
      long: ['required'],
      icon: ['required'],
    },
    afterSubmitHandler: (formResponse, config, setConfig) => {
      enqueueSnackbar('Шаблон для обращений обновлен. Изменения сохранены.', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      setConfig({ ...config, readonly: true });
      setFiles([]);
    },
  };

  useEffect(() => {
    setConfig(formConfig);
    setFiles([]);

    // TODO Need bux fixes
    setTimeout(() => {
      setConfig(formConfig);
    }, 50);
  }, []);

  return (
    <MainForm back={RouteList.NOTIFICATIONS}>
      <FormOutlinedInput
        title="Заголовок шаблона"
        typography="h1"
        inputProps={{
          autoFocus: true,
          name: 'header',
          defaultValue: template.web.header,
        }}
      />

      <Divider />

      <FormImage src={template.web.icon} name="icon" accept={MimeTypes.PNG} title="Изображение (допускается только png)" />

      <Divider />

      <FormOutlinedInput
        title="Категория"
        inputProps={{
          name: 'category',
          defaultValue: template.web.category,
        }}
      />

      <Divider />

      <FormOutlinedInput
        title="Текст уведомления (сокращенный)"
        inputProps={{
          name: 'short',
          defaultValue: template.web.short,
          minRows: 2,
          multiline: true,
        }}
      />

      <Divider />

      <FormOutlinedInput
        title="Текст уведомления (полный)"
        inputProps={{
          name: 'long',
          defaultValue: template.web.long,
          minRows: 5,
          multiline: true,
        }}
      />

      <Divider />

      {template.type !== TemplateTypes.SYSTEM &&
          <Grid container display='flex' justifyContent="space-between" alignItems='center' mt={4}>
            <OutlinedInput
                placeholder={'Email для отправки'}
                fullWidth={true}
                sx={{width: '50%'}}
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <Grid item>
              <LoadingButton endIcon={<SendIcon />} loading={isSending} onClick={sendMessage} disabled={!email}>
                Отправить тестовое оповещение
              </LoadingButton>
            </Grid>
          </Grid>
      }

      <FormActions />
    </MainForm>
  );
};

export default NotificationTemplateShowForm;
