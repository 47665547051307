import React from 'react';
import ContentContainer from '../Layout/Content/ContentContainer';
import MainForm from '../Common/Form/MainForm';
import FormOutlinedInput from '../../UI/Form/FormOutlinedInput';
import { useAsyncValue } from 'react-router-dom';
import Divider from '../../UI/Delimiter/Divider';
import { Grid } from '@mui/material';
import FormActions from '../../UI/Form/FormActions';
import { AccountFactory } from '../../http/account/AccountFactory';

const RequisitesForm = () => {
  const requisitesJsonString = useAsyncValue() as string;
  const requisites = AccountFactory.getRequisitesFromResponse(requisitesJsonString);

  return (
    <ContentContainer title="Реквизиты Оператора">
      <MainForm buttonVariant="v2">
        <FormOutlinedInput
          title="Наименование организации Оператора"
          inputProps={{
            name: 'operator_name',
            defaultValue: requisites.operatorName,
          }}
        />

        <Divider />

        <FormOutlinedInput
          title="Юридический адрес"
          inputProps={{
            name: 'legal_address',
            defaultValue: requisites.legalAddress,
          }}
        />

        <Divider />

        <Grid container columns={3} spacing={1} justifyContent="space-between">
          <Grid item xs={1}>
            <FormOutlinedInput
              title="ИНН"
              mask={'9999-99999-9'}
              inputProps={{
                name: 'inn',
                defaultValue: requisites.inn,
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <FormOutlinedInput
              title="КПП"
              mask={'9999-99-999'}
              inputProps={{
                name: 'kpp',
                defaultValue: requisites.kpp,
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <FormOutlinedInput
              title="Наименование банка"
              inputProps={{
                name: 'bank_name',
                defaultValue: requisites.bankName,
              }}
            />
          </Grid>
        </Grid>

        <Divider />

        <Grid container columns={3} spacing={1} justifyContent="space-between">
          <Grid item xs={1}>
            <FormOutlinedInput
              title="Бик банка"
              mask={'99-99-99-999'}
              inputProps={{
                name: 'bank_bik',
                defaultValue: requisites.bankBik,
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <FormOutlinedInput
              title="Счет банка"
              mask={'999.99.999.9.9999.9999999'}
              inputProps={{
                name: 'bank_account',
                defaultValue: requisites.bankAccount,
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <FormOutlinedInput
              title="Счет оператора в банке"
              mask={'999.99.999.9.9999.9999999'}
              inputProps={{
                name: 'bank_operator_account',
                defaultValue: requisites.bankOperatorAccount,
              }}
            />
          </Grid>
        </Grid>

        <Divider />

        <FormActions />
      </MainForm>
    </ContentContainer>
  );
};

export default RequisitesForm;