import React from 'react';
import FilterDialog from '../../../Common/Filter/FilterDialog';
import {IDialogFilterable} from '../../../../types/app/common/DialogFilterable';
import {defaultFilterState} from '../RuleSettingsTabMain';
import FilterSwitch from '../../../Common/Filter/FilterSwitch';
import FilterInput from '../../../Common/Filter/FilterInput';
import Divider from '../../../../UI/Delimiter/Divider';

const RuleSettingsTabFilters = (props: IDialogFilterable<typeof defaultFilterState>) => {
  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterInput
        label="Наименование правила"
        addFilter={props.addFilter}
        field="ruleName"
        filters={props.filters}
        placeholder="Укажите правило"
      />

      <Divider />

      <FilterInput
        label="Партнер"
        addFilter={props.addFilter}
        field="companyName"
        filters={props.filters}
        placeholder="Укажите название компании"
      />

      <Divider />

      <FilterSwitch
        label="Активность"
        addFilter={props.addFilter}
        field="isActive"
        filters={props.filters}
        items={[
          {key: 0, label: 'Неактивные правила'},
          {key: 1, label: 'Активные правила'},
        ]}
      />
    </FilterDialog>
  );
};

export default RuleSettingsTabFilters;