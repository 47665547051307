import React from 'react';
import { Box, Pagination as MuiPagination, PaginationProps } from '@mui/material';
import { useAppSelector } from '../../hooks/hooks';
import { useUrlManager } from '../../hooks/useUrlManager';
import { useTweakTool } from '../../hooks/table/useTweakTool';

const Pagination = (props: PaginationProps) => {
  const { pageToUrl, getPageByUrl } = useUrlManager();
  const { request, isLoading } = useTweakTool();

  const serverConfig = useAppSelector(state => state.serverConfig);

  if (serverConfig.count <= 1) {
    return <></>;
  }

  return (
    <Box paddingBottom={10}>
      <Box position="absolute" mt={2} bottom={15} width="100%">
        <Box display="flex" justifyContent="center">
          <MuiPagination
            disabled={isLoading}
            color="primary"
            shape="rounded"
            variant="outlined"
            onChange={(event, page) => {
              pageToUrl(page);

              if (serverConfig.method) {
                request({ page });
              }
            }}
            page={getPageByUrl()}
            count={serverConfig.count}
            {...props}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Pagination;
