import React, {Dispatch} from 'react';
import {Box, Button, FormHelperText, Grid, Typography} from '@mui/material';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import {LoadingButton} from '@mui/lab';
import FormDatePicker from '../../../UI/Pickers/FormDatePicker';
import {OperationTypes} from '../../../enums/OperationTypes';
import {RemittanceTypes} from '../../../enums/RemittanceTypes';
import moment from 'moment/moment';

interface IBankAccountFormMixin {
  amount: string,
  commission?: number,
  amountWithCommission?: number,
  error: string,
  description: string,
  isLoading: boolean,
  send: () => void,
  setOpenPopup?: (value: boolean) => void,
  errorWatcher: (value: number) => void,
  dispatchAmount: Dispatch<string>
  dispatchDescription: Dispatch<string>,
  dispatchExpDate?: Dispatch<string>,
  selectedBalanceType?: OperationTypes
  selectedType?: RemittanceTypes
  expDate?: string
}

const BankAccountFormMixin = (props: IBankAccountFormMixin) => {

    const handleOnChange = (e) => {
      if (props.selectedBalanceType === OperationTypes.RUB || props.selectedBalanceType === OperationTypes.BLOCK_RUB) {

        if(e.target.value.includes('.')) {
          let value

          const parts = e.target.value.split(".");
          if (parts.length > 1) {
            const decimals = parts[1].substring(0, 2);
            value = `${parts[0]}.${decimals}`;
          }

          props.errorWatcher(Number(value));
          props.dispatchAmount(value);
        } else {
          props.errorWatcher(Number(e.target.value));
          props.dispatchAmount(e.target.value);
        }
      }
      else {
        if (/^\d+$/.test(e.target.value)) {
          props.errorWatcher(Number(e.target.value));
          props.dispatchAmount(e.target.value);
        }
      }
    };


  return (
    <>
      <Box mb={2}>
        <Typography variant="body2" color="grey.600" mb={1}>
          Сумма:
        </Typography>
        <FormOutlinedInput
          inputProps={{
            placeholder: 'Введите значение',
            value: props.amount,
            error: !!props.error,
            onChange: e => handleOnChange(e)
          }}
          rule={e => !isNaN(Number(e.target.value))}
        />
        {!!props.error && (
          <FormHelperText
            sx={[
              theme => ({
                ml: 2,
                color: theme.palette.error.main,
                fontWeight: 700,
              }),
            ]}
          >
            {props.error}
          </FormHelperText>
        )}
      </Box>
      {
        props.commission !== undefined ? (
          <>
            <Box mb={2}>
              <Typography variant="body2" color="grey.600" mb={1}>
                Комиссия:
              </Typography>
              <Typography variant="body2" color="grey.600" mb={1}>
                {(props.commission * 100).toFixed(2) + '%'}
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="body2" color="grey.600" mb={1}>
                Сумма с учетом комиссии:
              </Typography>
              <Typography variant="body2" color="grey.600" mb={1}>
                {props.amountWithCommission.toFixed(2)}
              </Typography>
            </Box>
          </>

        ) : null
      }

      {props.selectedBalanceType === OperationTypes.POINT && props.selectedType === RemittanceTypes.IN && <Grid item>
        <FormDatePicker
            datepickerProps={{
              value: props.expDate,
              name: 'exp_date_points',
              onChange: e => {
                const date = {
                  target: {
                    name: 'exp_date_points',
                    value: moment(e._d).format('YYYY-MM-DD')
                  },
                  type: 'change'
                };

                props.dispatchExpDate(date.target.value);
              },
            }}
            title="Дата сгорания баллов"
            fieldProps={{
              name: 'exp_date_points',
            }}
        />
      </Grid>}

      <Box>
        <Typography variant="body2" color="grey.600" mb={1}>
          Описание:
        </Typography>

        <FormOutlinedInput
          inputProps={{
            multiline: true,
            value: props.description,
            onChange: e => props.dispatchDescription(e.target.value),
            minRows: 5,
          }}
        />
      </Box>

      <Grid container justifyContent="end" gap={2} pt={2}>
        <Button onClick={() => props.setOpenPopup(false)}>
          Отменить
        </Button>
        <LoadingButton
          disabled={
            !!props.error
            || +props.amount === 0
            || (props.selectedBalanceType === OperationTypes.POINT && props.selectedType === RemittanceTypes.IN && !props.expDate)
          }
          loading={props.isLoading}
          onClick={props.send}>
          Применить
        </LoadingButton>
      </Grid>
    </>
  );
};

export default BankAccountFormMixin;
