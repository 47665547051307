import moment from 'moment/moment';
import {TListTariffData} from '../../types/response/billing/ListTariffData';
import {ITariff} from '../../types/app/billing/bonuses/Tariff';
import {TGetTariffData} from '../../types/response/billing/GetTariffData';
import {ITariffDetail} from '../../types/app/billing/TariffDetail';
import {TPromo, TPromoCategory, TPromoProduct, TPromoUser} from '../../types/response/billing/ListPromoData';
import {
  IPromo,
  IPromoCategory,
  IPromoCertificateParams,
  IPromoProduct,
  IPromoUser,
} from '../../types/app/billing/bonuses/Promo';
import {CertificateTypeCodes} from '../../enums/CertificateTypes';

export class BillingFactory {
  /**
   * Get tariff list objects from response data
   *
   * @param {TListTariffData} listTariffDataTypes
   */
  public static getTariffsFromListTariffResponseData(listTariffDataTypes: TListTariffData[]): ITariff[] {
    return listTariffDataTypes.map(tariff => {
      const from = tariff.valid_from ? moment(tariff.valid_from).format('LL') : null;
      const to = tariff.valid_to ? moment(tariff.valid_to).format('LL') : null;

      return {
        id: tariff.id,
        name: tariff.name,
        active: tariff.active === 1,
        percent: Number.parseFloat(tariff.percent),
        amount: tariff.amount,
        priority: tariff.priority,
        validFrom: from ? moment(from, 'LL').unix() : null,
        validTo: to ? moment(to, 'LL').unix() : null,
        partnersIds: tariff.partners_ids,
        type: 'tariff',
        tariffType: tariff.tariff_type,
      };
    });
  }

  /**
   * Get tariff detail object from response data
   *
   * @param {TGetTariffData} getTariffDataType
   */
  public static getTariffFromGetTariffResponseData(getTariffDataType: TGetTariffData): ITariffDetail {
    return {
      active: getTariffDataType.active,
      amount: getTariffDataType.amount,
      bonusId: getTariffDataType.bonus_id,
      categoriesIds: getTariffDataType.categories_ids,
      createdAt: getTariffDataType.created_at ? moment(getTariffDataType.created_at).unix() : null,
      delayTime: getTariffDataType.delay_time,
      exclusivity: getTariffDataType.exclusivity,
      id: getTariffDataType.id,
      name: getTariffDataType.name,
      partnersIds: getTariffDataType.partners_ids,
      percent: Number(getTariffDataType.percent),
      priority: getTariffDataType.priority,
      productsIds: getTariffDataType.products_ids,
      updatedAt: getTariffDataType.updated_at ? moment(getTariffDataType.updated_at).unix() : null,
      validFrom: getTariffDataType.valid_from ? moment(getTariffDataType.valid_from).unix() : null,
      validTo: getTariffDataType.valid_to ? moment(getTariffDataType.valid_to).unix() : null,
      minProductQuantity: getTariffDataType.min_product_quantity,
      validFromHour: getTariffDataType.valid_from_hour || getTariffDataType.valid_from_hour === 0 ? moment(0).hour(
        getTariffDataType.valid_from_hour).unix() : null,
      validToHour: getTariffDataType.valid_to_hour || getTariffDataType.valid_to_hour === 0 ? moment(0).hour(
        getTariffDataType.valid_to_hour).unix() : null,
      comment: getTariffDataType.comment,
      lifetimeDays: getTariffDataType.lifetime_days,
      tariffType: getTariffDataType.tariff_type,
    };
  }

  public static getPromoFromResponse(promoData: TPromo, dateFormat: string = 'YYYY-MM-DD'): IPromo {
    const from = promoData.valid_from ? moment(promoData.valid_from).format(dateFormat) : null;
    const to = promoData.valid_to ? moment(promoData.valid_to).format(dateFormat) : null;

    return {
      active: promoData.active === 1 || promoData.active === true,
      addPoints: promoData.add_points === 1,
      certificateParams: BillingFactory.getCertificateParams(promoData.certificate_params),
      createdAt: promoData.created_at ? moment(promoData.created_at).unix() : null,
      description: promoData.description,
      id: promoData.id,
      isCategoriesRequired: promoData.is_categories_required === 1,
      isMinimalAmountRequired: promoData.is_minimal_amount_required === 1,
      isProductsRequired: promoData.is_products_required === 1,
      isUsersRequired: promoData.is_users_required === 1,
      issueCertificate: promoData.issue_certificate === 1,
      maxAllowedParticipants: promoData.max_allowed_participants,
      multiplyPoints: promoData.multiply_points === 1,
      name: promoData.name,
      participantsCounter: promoData.participants_counter,
      partnersIds: [promoData.partner_id],
      pointsAdditionalAmount: promoData.points_additional_amount ? Number(promoData.points_additional_amount) : null,
      pointsLifetimeDays: promoData.points_lifetime_days,
      pointsMultiplier: promoData.points_multiplier ? Number(promoData.points_multiplier) : null,
      promoCategories: promoData.promo_categories ? promoData.promo_categories.map(
        category => this.getPromoCategory(category)) : [],
      promoProducts: promoData.promo_products ? promoData.promo_products.map(
        product => this.getPromoProduct(product)) : [],
      promoUsers: promoData.promo_users ? promoData.promo_users.map(user => this.getPromoUser(user)) : [],
      requiredMinimalAmount: +promoData.required_minimal_amount,
      updatedAt: promoData.updated_at ? moment(promoData.updated_at).unix() : null,
      validFrom: from ? moment(from, dateFormat).unix() : null,
      validFromHour: promoData.valid_from_hour ? moment(promoData.valid_from_hour, 'HH:mm:ss').unix() : null,
      validTo: to ? moment(to, dateFormat).unix() : null,
      validToHour: promoData.valid_to_hour ? moment(promoData.valid_to_hour, 'HH:mm:ss').unix() : null,
      type: 'promo',
    };
  }

  private static getCertificateParams(json: string | null): IPromoCertificateParams | null {
    if (!json) {
      return null;
    }

    const decoded: {
      type: string;
      amount?: string;
      is_mix: boolean;
      is_part: boolean;
      quantity: string;
      userEmail?: string;
      expires_date?: string;
      products?: { [key: number]: string }
    } = JSON.parse(json);

    return {
      isMix: decoded.is_mix,
      isPart: decoded.is_part,
      quantity: +decoded.quantity,
      type: +decoded.type as CertificateTypeCodes,
      expiresDate: decoded.expires_date ? moment(decoded.expires_date, 'YYYY-MM-DD').unix() : null,
      amount: decoded.amount ? Number(decoded.amount) : null,
      userEmail: decoded.userEmail ?? null,
      products: decoded.products ?? null
    };
  }

  private static getPromoCategory(categoryData: TPromoCategory): IPromoCategory {
    return {
      categoryId: categoryData.category_id,
      createdAt: categoryData.created_at ? moment(categoryData.created_at).unix() : null,
      requiredQuantity: categoryData.required_quantity,
      updatedAt: categoryData.updated_at ? moment(categoryData.updated_at).unix() : null,
    };
  }

  private static getPromoProduct(productData: TPromoProduct): IPromoProduct {
    return {
      createdAt: productData.created_at ? moment(productData.created_at).unix() : null,
      productId: productData.product_id,
      requiredQuantity: productData.required_quantity,
      updatedAt: productData.updated_at ? moment(productData.updated_at).unix() : null,
    };
  }

  private static getPromoUser(userData: TPromoUser): IPromoUser {
    return {
      createdAt: userData.created_at ? moment(userData.created_at).unix() : null,
      email: userData.email,
      updatedAt: userData.updated_at ? moment(userData.updated_at).unix() : null,
    };
  }
}
