export enum TariffTypes {
  AMOUNT,
  PERCENT,
  CASHBACK,
}

export const getTariffTypeLabel = (tariffType: number) => {
  switch (tariffType) {
    case TariffTypes.AMOUNT:
      return 'Бонус';
    case TariffTypes.PERCENT:
      return 'Скидка';
    case TariffTypes.CASHBACK:
      return 'Вознаграждение за покупку';
    default:
     return 'Бонус';
  }
}
