import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CookieRepository} from '../../../repositories/CookieRepository';
import {IAuthUser} from '../../../types/app/auth/AuthUser';
import { IReadMark } from '../../../types/app/common/Notification';

interface IAuthSliceState {
  user: IAuthUser | null;
  error: string | null;
}

const initialState: IAuthSliceState = {
  user: null,
  error: null,
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    login(state, payload: PayloadAction<IAuthUser>) {
      state.user = payload.payload;
    },
    logout(state) {
      state.user = null;

      CookieRepository.removeToken();
    },
    setUser(state, action) {
      state.user.balances = action.payload
    },
    updateLastNoticeByReadMark(state: IAuthSliceState, payload: PayloadAction<IReadMark[]>) {
      const notices = [...state.user.notification.lastNotices];

      payload.payload.forEach((readMark) => {
        notices.forEach((notice) => {
          if (notice.id === readMark.noticeId) {
            notice.read = true;
          }
        })
      })

      state.user.notification.lastNotices = notices;
    },
    updateLastNoticeAsRead(state: IAuthSliceState, payload: PayloadAction<IReadMark[]>) {
      const notices = state.user.notification.lastNotices.map(notice => ({...notice, read: true}));
      state.user.notification.lastNotices = notices;
    },
    incrementUnreadNoticeCount(state: IAuthSliceState) {
      state.user.notification.unreadNoticeCount = state.user.notification.unreadNoticeCount !== 0
        ? --state.user.notification.unreadNoticeCount
        : 0;
    },
    clearUnreadNoticeCount(state: IAuthSliceState) {
      state.user.notification.unreadNoticeCount = 0;
    },
  },
});

export const { setUser} = authSlice.actions

export default authSlice.reducer;
