import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';
import CertificateCreateForm from '../../../Widgets/Certificates/Operations/CertificateCreateForm';
import {IGetCertificateDataLoader} from '../../../http/certificates/CertificateLoaders';

const CertificateCreate = () => {
  const {data} = useLoaderData() as IGetCertificateDataLoader;

  return (
    <Await resolve={data}>
      <CertificateCreateForm/>
    </Await>
  );
};

export default CertificateCreate;