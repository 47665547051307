import React from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import FilterInput from '../../Common/Filter/FilterInput';
import { IDialogFilterable } from '../../../types/app/common/DialogFilterable';
import { defaultFilters } from './NewsTabMain';
import Divider from '../../../UI/Delimiter/Divider';
import FilterSelect from '../../Common/Filter/FilterSelect';
import { INews } from '../../../types/app/communication/News';
import { ISelectable } from '../../../types/app/common/Selectable';
import FilterDate from '../../../UI/Filter/FilterDate';
import FilterTime from '../../../UI/Filter/FilterTime';

export interface INewsTabFilters extends IDialogFilterable<typeof defaultFilters> {
  items: INews[];
}

const NewsTabFilters = (props: INewsTabFilters) => {
  const templateCategoriesAsSelectable = (): ISelectable[] => {
    const asSelectableMap = new Map<string, ISelectable>();

    props.items.forEach(item => {
      if (item.template) {
        if (!asSelectableMap.has(item.template.web.category)) {
          asSelectableMap.set(item.template.web.category, { key: item.template.web.category, label: item.template.web.category });
        }
      }
    });

    return Array.from(asSelectableMap.values());
  };

  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterInput
        label="Заголовок"
        addFilter={props.addFilter}
        field="title"
        filters={props.filters}
        placeholder="Укажите заголовок"
      />

      <Divider />

      <FilterInput
        label="Текст"
        addFilter={props.addFilter}
        field="description"
        filters={props.filters}
        placeholder="Укажите текст"
      />

      <Divider />

      <FilterSelect
        label="Вид рассылки"
        filters={props.filters}
        addFilter={props.addFilter}
        items={templateCategoriesAsSelectable()}
        field="category"
        placeholder="Укажите вид рассылки"
      />

      <Divider />

      <FilterDate
        addFilter={props.addFilter}
        field='startWithoutTime'
        filters={props.filters}
        label="Дата отправки"
        fieldProps={{
          placeholder: "Дата отправки"
        }}
      />

      <Divider />

      <FilterTime
        addFilter={props.addFilter}
        field='startWithoutDate'
        filters={props.filters}
        label="Время отправки"
        placeholder="Время отправки"
      />
    </FilterDialog>
  );
};

export default NewsTabFilters;
