import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import MainForm from '../../../../Common/Form/MainForm';
import {RouteList} from '../../../../../http/RouteList';
import {Alert, AlertTitle, Box, Grid, IconButton, Typography} from '@mui/material';
import FormOutlinedInput from '../../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../../UI/Delimiter/Divider';
import FormSwitch from '../../../../../UI/Form/FormSwitch';
import Info from '../../../../../UI/Informers/Info';
import FormTimePicker from '../../../../../UI/Pickers/FormTimePicker';
import FormActions from '../../../../../UI/Form/FormActions';
import {useNavigate} from 'react-router-dom';
import FormSelect from '../../../../../UI/Form/Select/FormSelect';
import {FormContext, IFormConfig, TFieldRule} from '../../../../../store/context/form/FormContext';
import BuyCategoriesMapper from '../../Common/BuyCategoriesMapper';
import PromoPoints from '../../Common/PromoPoints';
import PromoCertificate from '../../Common/PromoCertificate';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {CertificateCategories} from '../../../../../enums/CertificateCategories';
import moment, {Moment} from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import FormErrorText from '../../../../../UI/Form/FormErrorText';
import {useNotifications} from '../../../../../hooks/useNotifications';
import {messages} from '../../../../../notifications/messages';
import {CertificateTypeCodes} from '../../../../../enums/CertificateTypes';
import {IPromo} from '../../../../../types/app/billing/bonuses/Promo';
import {IProduct} from '../../../../../types/app/catalog/Product';
import {ICategory} from '../../../../../types/app/catalog/Category';
import {IPartner} from '../../../../../types/app/partner/Partner';
import {
  getCertificateInitCategoryByPromo,
  getCertificateInitTypeByPromo,
  getFlushRecalculatable,
  getMomentFromPromoDatetime,
  getPromoCategoriesInitialByPromo,
  getPromoProductsInitialByPromo,
  getPromoStatusColor,
  getPromoStatusName,
} from '../../../../../utils/billingUtils';
import {isNumber} from 'lodash';
import {IRecalculatable} from '../../../../../types/app/common/Recalculatable';
import FormDatePicker from '../../../../../UI/Pickers/FormDatePicker';
import BuyProductsMapper from '../../Common/BuyProductsMapper';
import {CatalogService} from '../../../../../http/catalog/CatalogService';
import {getProductItems} from '../../../../../enums/ProductStatuses';
import {IPaginated} from '../../../../../types/app/common/Paginated';

interface IPromoCommon {
  products: Pick<IProduct, 'name' | 'id' | 'byCertificate'>[];
  categories: ICategory[];
  partners: IPartner[];

  promo?: IPromo;
}

const PromoCommon = (props: IPromoCommon) => {
  const [promo, setPromo] = useState<IPromo | null>(props.promo ?? null);

  const {setConfig, errorWatcher, errors, softReset, config} = useContext(FormContext);
  const route = useNavigate();

  const [isMultiplyPoints, setIsMultiplyPoints] = useState<boolean>(promo?.multiplyPoints ?? false);
  const [isAdditionalPoints, setIsAdditionalPoints] = useState<boolean>(promo?.addPoints ?? false);
  const [isIssueCertificate, setIsIssueCertificate] = useState<boolean>(promo?.issueCertificate ?? false);

  const [certificateType, setCertificateType] = useState<CertificateTypeCodes>(getCertificateInitTypeByPromo(promo));
  const [certificateCategory, setCertificateCategory] = useState<CertificateCategories>(
    getCertificateInitCategoryByPromo(promo));

  const [validFrom, setValidFrom] = useState<Moment>(getMomentFromPromoDatetime(promo?.validFrom));
  const [validTo, setValidTo] = useState<Moment>(getMomentFromPromoDatetime(promo?.validTo));

  const [validFromHour, setValidFromHour] = useState<Moment>(getMomentFromPromoDatetime(promo?.validFromHour));
  const [validToHour, setValidToHour] = useState<Moment>(getMomentFromPromoDatetime(promo?.validToHour));

  const [requiredMinimalAmount, setRequiredMinimalAmount] = useState<number | string>(
    promo?.requiredMinimalAmount ?? '');

  const [isProductsTouch, setIsProductTouch] = useState<boolean>(promo?.isProductsRequired ?? false);
  const [isCategoriesTouch, setIsCategoriesTouch] = useState<boolean>(promo?.isCategoriesRequired ?? false);

  const [partnerId, setPartnerId] = useState<number>(promo?.partnersIds[0] ?? undefined);
  const [selectedPartnerProductId, setSelectedPartnerProductId] = useState<number>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [partnerProductsItems, setPartnerProductItems] = useState([]);
  const [partnerProductsItemsMap, setPartnerProductItemsMap] = useState<Map<number, string>>(undefined);

  const [buyCategories, setBuyCategories] = useState<IRecalculatable[]>(
    getPromoCategoriesInitialByPromo(props.promo) ?? getFlushRecalculatable()
  );
  const [buyProducts, setBuyProducts] = useState<IRecalculatable[]>(
    getPromoProductsInitialByPromo(props.promo) ?? getFlushRecalculatable()
  );

  const {success} = useNotifications();

  const mechanicState = useMemo(
    () => isIssueCertificate || isAdditionalPoints || isMultiplyPoints,
    [isIssueCertificate, isAdditionalPoints, isMultiplyPoints]
  );

  const fetchPartnerProducts = async () => {
    setIsLoading(true);
    const productsMap: Map<number, string> = new Map();

    try {
      let products: IPaginated<IProduct[]>;

      if (promo && config.readonly) {
        products = await CatalogService.getFilteredProducts(
          {partner_id: promo.partnersIds, status_id: 2, limit: 1000});
      }
      else {
        products = await CatalogService.getFilteredProducts(
          {partner_id: [partnerId], status_id: 2, limit: 1000});
      }

      products.items.forEach(item => productsMap.set(item.id, item.name));
      setPartnerProductItemsMap(productsMap);
      setPartnerProductItems(getProductItems(products.items));

      setIsLoading(false);
    }
    catch (e) {
      throw Error;
    }
  };

  const getRules = useCallback(() => {
    const rules: TFieldRule = {
      partner_id: ['required'],
      name: ['required'],
      setting_state: ['true'],
      description: ['required'],
      valid_from: ['required'],
      valid_to: ['required'],
    };

    if (isMultiplyPoints || isAdditionalPoints) {
      rules['points_lifetime_days'] = ['required'];
    }

    if (isMultiplyPoints) {
      rules['points_multiplier'] = ['required'];
    }

    if (isAdditionalPoints) {
      rules['points_additional_amount'] = ['required'];
    }

    if (isIssueCertificate) {
      if (certificateCategory === CertificateCategories.USER) {
        rules['user_email'] = ['required', 'email'];
      }

      if (certificateType === CertificateTypeCodes.PRODUCT) {
        rules['product_ids'] = ['required'];
        rules['productId_for_certificate'] = ['required'];
      }
    }

    return rules;
  }, [
    isIssueCertificate,
    isAdditionalPoints,
    isMultiplyPoints,
    certificateType,
    certificateCategory,
    validFromHour,
    requiredMinimalAmount,
    buyProducts,
    buyCategories,
  ]);

  const formConfig: IFormConfig = {
    readonly: !!promo,
    action: promo ? RouteList.BILLING_SHOW_PROMO + '/' + promo.id : RouteList.BILLING_CREATE_PROMO,

    afterSubmitHandler: (formResponse, config, setConfig) => {
      success(promo ? messages.success.promoUpdated : messages.success.promoCreated);

      if (isNumber(formResponse?.payload)) {
        route(RouteList.BILLING_SHOW_PROMO + '/' + formResponse.payload);
      }
      else {
        setPromo(formResponse.payload as IPromo);

        setConfig({
          ...config,
          readonly: true,
        });
      }
    },
  };


  useEffect(() => {
    setConfig(formConfig);
  }, []);

  useEffect(() => {
    if (config.readonly === false) {
      softReset();
      setConfig({...formConfig, rules: getRules(), readonly: false});
    }
  }, [
    isIssueCertificate,
    isAdditionalPoints,
    isMultiplyPoints,
    certificateType,
    certificateCategory,
    config.readonly,
    validFromHour,
    validToHour,
    buyProducts,
    buyCategories,
    validFrom,
    validTo,
    promo
  ]);

  useEffect(() => {
    if (promo) {
      setValidFrom(getMomentFromPromoDatetime(promo.validFrom));
      setValidTo(getMomentFromPromoDatetime(promo.validTo));
    }
  }, [promo]);

  return (
    <MainForm back={RouteList.BILLING}>
      {!mechanicState && (
        <Alert
          color="warning"
          sx={{mb: 5, position: 'absolute', top: 0, right: 0, left: 0}}
          icon={<WarningAmberIcon sx={{fontSize: 20}}/>}
        >
          <AlertTitle>Не выбрана механика для акции!</AlertTitle>
          Для формирования Акции должна использоваться хотя бы одна механика.
        </Alert>
      )}

      {!promo && (
        <Typography variant="h1" mb={5} mt={3}>
          Добавление акции
        </Typography>
      )}

      {promo && (
        <Box position="absolute" top={0} right={65} zIndex={100}>
          <Typography
            fontWeight={700}
            sx={{textDecoration: 'underline'}}
            variant="body2"
            mb={5}
            mt={3}
            color={getPromoStatusColor(props.promo)}
          >
            Статус акции: {getPromoStatusName(props.promo)}
          </Typography>
        </Box>
      )}

      <Box mt={promo ? 0 : 3}>
        <FormOutlinedInput
          title="Наименование акции"
          typography={promo ? 'h1' : 'body1'}
          inputProps={{
            name: 'name',
            defaultValue: promo?.name ?? '',
          }}
        />
      </Box>

      {promo && (
        <Box>
          <Divider/>

          <FormSwitch title="Активная акция" switchProps={{name: 'active', defaultChecked: promo.active}}/>
        </Box>
      )}

      <Divider/>

      <FormOutlinedInput
        title="Основание"
        inputProps={{
          multiline: true,
          rows: 5,
          name: 'description',
          defaultValue: promo?.description ?? '',
        }}
      />

      <Divider/>

      <Box>
        <Typography variant="body2" color="grey.600" mb={1}>
          Вид механики:
        </Typography>

        <Grid container alignItems="center">
          <FormSwitch
            error={!!errors['setting_state']}
            title="Увеличенное количество баллов"
            switchProps={{
              checked: isMultiplyPoints,
              onChange: () => {
                setIsMultiplyPoints(!isMultiplyPoints);

                setIsAdditionalPoints(false);
                setIsIssueCertificate(false);

                errorWatcher('setting_state', String(mechanicState));
              },
            }}
          />
          <Box ml={1}>
            <Info title="Кратное умножение баллов при выполнении условий акции"/>
          </Box>
        </Grid>

        <Grid container alignItems="center">
          <FormSwitch
            error={!!errors['setting_state']}
            title="Фиксированное начисление"
            switchProps={{
              checked: isAdditionalPoints,
              onChange: () => {
                setIsAdditionalPoints(!isAdditionalPoints);

                setIsMultiplyPoints(false);
                setIsIssueCertificate(false);

                errorWatcher('setting_state', String(mechanicState));
              },
            }}
          />
          <Box ml={1}>
            <Info title="Фиксированная сумма баллов при выполнении условий акции"/>
          </Box>
        </Grid>

        <Box mb={1}>
          <FormSwitch
            error={!!errors['setting_state']}
            title="Выдача сертификата Пользователю"
            switchProps={{
              checked: isIssueCertificate,
              onChange: () => {
                setIsIssueCertificate(!isIssueCertificate);

                setIsMultiplyPoints(false);
                setIsAdditionalPoints(false);

                errorWatcher('setting_state', String(mechanicState));
              },
            }}
          />
        </Box>

        <input type="hidden" name="setting_state" value={String(mechanicState)}/>
      </Box>

      {errors['setting_state'] && <FormErrorText error="Должна быть выбрана хотя бы одна механика"/>}

      <Divider/>

      <Grid container columns={2} spacing={1}>
        <Grid item xs={1} position="relative">
          {config.readonly
            ? <>
              <Box>
                <Typography mb={1} variant="body2" color="grey.500">
                  Начало действия акции:
                </Typography>

                <Typography variant="body1">
                  {moment(validFrom).format('DD.MM.YYYY')}
                </Typography>
              </Box>
            </>
            : <FormDatePicker
              datepickerProps={{
                value: validFrom,
                onChange: (date: Moment) => {
                  setValidFrom(date);
                  setValidTo(null);
                  errorWatcher('valid_from', validFrom?.toISOString() ?? null);
                },
                minDate: moment(),
              }}
              title="Начало действия акции"
            />
          }

          {validFrom && !config.readonly && (
            <Box position="absolute" top={35} right={35}>
              <IconButton
                onClick={() => {
                  setValidFrom(null);
                  setValidTo(null);
                }}
                color="error"
              >
                <CloseIcon fontSize="small"/>
              </IconButton>
            </Box>
          )}
          <input type="hidden" name="valid_from" value={validFrom?.format('YYYY-MM-DD') ?? ''}/>
        </Grid>
        <Grid item xs={1} position="relative">
          {config.readonly
            ? <>
              <Box>
                <Typography mb={1} variant="body2" color="grey.500">
                  Завершение действия акции:
                </Typography>

                <Typography variant="body1">
                  {moment(validTo).format('DD.MM.YYYY')}
                </Typography>
              </Box>
            </>
            : <FormDatePicker
              datepickerProps={{
                disablePast: true,
                value: validTo,
                disabled: validFrom === null,
                minDate: validFrom ? validFrom : moment(),
                onChange: (date: Moment) => {
                  setValidTo(date);
                  errorWatcher('valid_to', validFrom?.toISOString() ?? null);
                },
              }}
              title="Завершение действия акции"
            />
          }

          {errors['valid_to'] && <FormErrorText error={errors['valid_to']}/>}
          {validTo && !config.readonly && (
            <Box position="absolute" top={35} right={35}>
              <IconButton onClick={() => setValidTo(null)} color="error">
                <CloseIcon fontSize="small"/>
              </IconButton>
            </Box>
          )}
          <input type="hidden" name="valid_to" value={validTo?.format('YYYY-MM-DD') ?? ''}/>
        </Grid>
      </Grid>

      <Divider/>

      <Grid container columns={2} spacing={1}>
        <Grid item xs={1} position="relative">
          <FormTimePicker
            timepickerProps={{
              value: validFromHour,
              onChange: (moment: Moment) => {
                setValidFromHour(moment);
                setValidToHour(null);

                errorWatcher('valid_from_hour', validFromHour?.toISOString() ?? null);
              },

              views: ['hours']
            }}
            fieldProps={{
              error: !!errors['valid_from_hour'],
            }}
            title="Часы проведения акции (от)"
          />
          {errors['valid_from_hour'] && <FormErrorText error={errors['valid_from_hour']}/>}
          {validFromHour && !config.readonly && (
            <Box position="absolute" top={35} right={35}>
              <IconButton
                onClick={() => {
                  setValidFromHour(null);
                  setValidToHour(null);
                }}
                color="error"
              >
                <CloseIcon fontSize="small"/>
              </IconButton>
            </Box>
          )}
          <input type="hidden" name="valid_from_hour" value={validFromHour?.format('HH:mm') ?? ''}/>
        </Grid>

        <Grid item xs={1} position="relative">
          <FormTimePicker
            timepickerProps={{
              value: validToHour,
              onChange: (moment: Moment) => {
                setValidToHour(moment);

                errorWatcher('valid_to_hour', validToHour?.toISOString() ?? null);
              },

              views: ['hours']
            }}
            fieldProps={{
              error: !!errors['valid_to_hour'],
            }}
            title="Часы проведения акции (до)"
          />
          {errors['valid_to_hour'] && <FormErrorText error={errors['valid_to_hour']}/>}
          {validToHour && !config.readonly && (
            <Box position="absolute" top={35} right={35}>
              <IconButton onClick={() => setValidToHour(null)} color="error">
                <CloseIcon fontSize="small"/>
              </IconButton>
            </Box>
          )}
          <input type="hidden" name="valid_to_hour" value={validToHour?.format('HH:mm') ?? ''}/>
        </Grid>
      </Grid>

      <Divider/>

      <FormSelect
        title="Партнер-организатор акции"
        items={props.partners}
        singletweaks={{
          unselect: true,
        }}
        onBeforeClearAll={() => {
          setPartnerId(undefined);
          setSelectedPartnerProductId(undefined);
          setPartnerProductItems(undefined);
        }
        }
        selectProps={{
          name: 'partner_id',
          defaultValue: promo?.partnersIds[0],
          onChange: (e) => {
            setPartnerId(e.target.value as number);
          },
        }}
      />

      {(isMultiplyPoints || isAdditionalPoints) && (
        <PromoPoints promo={promo} mode={isMultiplyPoints ? 'multiple' : 'additional'}/>
      )}

      {isIssueCertificate && (
        <PromoCertificate
          promo={promo}
          certificateCategory={certificateCategory}
          certificateType={certificateType}
          dispatchCertificateCategory={setCertificateCategory}
          dispatchCertificateType={setCertificateType}
          partnerId={partnerId}
          isIssueCertificate={isIssueCertificate}
          setSelectedPartnerProductId={setSelectedPartnerProductId}
          partnerProductId={selectedPartnerProductId}
          fetchPartnerProducts={fetchPartnerProducts}
          partnerProductsItems={partnerProductsItems}
          isLoading={isLoading}
          selectedPartnerProductId={selectedPartnerProductId}
          partnerProductsItemsMap={partnerProductsItemsMap}
        />
      )}

      <Box bgcolor="#F9FCFF" p={5} mt={3} mb={3} borderRadius={4}>
        <Typography variant="subtitle1">Условия для отработки механики:</Typography>

        <BuyProductsMapper
          promo={promo}
          isCategoriesTouch={isCategoriesTouch}
          isProductsTouch={isProductsTouch}
          dispatchProductsTouch={setIsProductTouch}
          products={props.products}
          certificateType={certificateType}
          buyProducts={buyProducts}
          dispatchBuyProducts={setBuyProducts}
        />

        <Divider/>

        <BuyCategoriesMapper
          promo={promo}
          isProductsTouch={isProductsTouch}
          isCategoriesTouch={isCategoriesTouch}
          dispatchCategoriesTouch={setIsCategoriesTouch}
          categories={props.categories}
          certificateType={certificateType}
          buyCategories={buyCategories}
          dispatchBuyCategories={setBuyCategories}
        />

        <Divider/>

        <Grid container columns={2}>
          <Grid item xs={1}>
            <FormOutlinedInput
              rule={e => !isNaN(+e.target.value)}
              inputProps={{
                name: 'required_minimal_amount',
                value: requiredMinimalAmount,
                onChange: e => {
                  const value = +e.target.value;

                  if (value === 0) {
                    setRequiredMinimalAmount('');

                    return;
                  }

                  setRequiredMinimalAmount(value);
                  // errorWatcher('required_minimal_amount', value === 0 ? '' : value.toString());
                },
              }}
              title="Минимальная сумма чека"
            />
          </Grid>
        </Grid>
      </Box>

      <FormActions/>
    </MainForm>
  );
};
export default PromoCommon;
