import React from 'react';
import Tabs from '../../../UI/Tab/Tabs';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';
import TabPanel from '../../../UI/Tab/TabPanel';
import AccountTabContentMain from '../../../Widgets/Common/Tab/AccountTabs/AccountTabContentMain';
import { UserTypes } from '../../../enums/UserTypes';
import RolesTabContentMain from '../../../Widgets/Common/Tab/RoleTabs/RolesTabContentMain';

const UserIndex = () => {
  return (
    <ContentContainer title="Управление учетными записями">
      <Tabs
        items={[
          { title: 'Операторы', tabIndex: 0 },
          { title: 'Управление ролями', tabIndex: 1 },
        ]}
      />

      <TabPanel index={0}>
        <AccountTabContentMain type={UserTypes.OPERATOR} />
      </TabPanel>

      <TabPanel index={1}>
        <RolesTabContentMain type={UserTypes.OPERATOR} />
      </TabPanel>
    </ContentContainer>
  );
};

export default UserIndex;