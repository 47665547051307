import React from 'react';
import {Box, Grid, styled} from '@mui/material';
import {Outlet} from 'react-router-dom';
import {SnackbarProvider as NotistackSnackbarProvider} from 'notistack';

export const SnackbarProvider = styled(NotistackSnackbarProvider)({
  '.SnackbarItem-message': {
    width: 400,
    fontSize: 16,
    lineHeight: '25px',
  },
  // '&.SnackbarItem-variantWarning': {
  //   color: 'rgb(102, 86, 42)',
  //   backgroundColor: 'rgb(255, 251, 240)',
  // },
  // '&.SnackbarItem-variantError': {
  //   color: '#721c24',
  //   backgroundColor: '#f8d7da',
  // },
});

const AuthLayout = () => {
  return (
    <Box height="100vh">
      <Grid height="100%" container spacing={10} justifyContent="space-around" columns={12}>
        <Box
          position="absolute"
          left="15%"
          sx={{
            top: {
              xs: 5,
              sm: 25,
              md: 50,
            },
            left: {
              xs: '15px',
              md: '15%',
            }
          }}>
          <Grid container columns={12} spacing={2} justifyContent="start" alignItems="center">
            <Grid item xs={8} sm={12} md={12} lg={12}>
              <Grid container justifyContent="start" height="70px">

              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid
          item
          height="100vh"
          xs={12}
          sx={{
            mt: {
              xs: 10,
            }
          }}
        >
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          >
            <Box
              alignItems="center"
              padding={2}
              sx={{
                mt: {
                  sx: '3%',
                  sm: '6%',
                  md: '8%',
                  lg: '4%',
                }
              }}
            >
              <Outlet/>
            </Box>
          </SnackbarProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthLayout;