import React, { Dispatch, useContext } from 'react';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import { FormContext } from '../../store/context/form/FormContext';
import CloseIcon from '@mui/icons-material/Close';
import { Moment } from 'moment';

interface IFormPickerInputProps {
  params: TextFieldProps;
  fieldProps: TextFieldProps;
  value: number | null | Moment;
  dispatch: Dispatch<number | null>;
  nullable: boolean;
  showDeleteButton?: boolean;
}

const FormPickerTextField = (props: IFormPickerInputProps) => {
  const { errors } = useContext(FormContext);
  const showButton = props.showDeleteButton ?? true;

  return (
    <Box position="relative">
      <TextField
        {...props.params}
        fullWidth
        label={props.fieldProps?.label ?? ''}
        error={!!errors[props.fieldProps?.name]}
        {...props.fieldProps}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '16px !important',
            '& input': {
              borderRadius: '16px !important',
              paddingRight: '35px',
            },
          },
          ...props.fieldProps?.sx
        }}
        inputProps={{
          ...props.params.inputProps,
          placeholder: props.fieldProps?.placeholder,
        }}
      />
      {(props.value && props.nullable && showButton) && (
        <Box position="absolute" top={3} right={35}>
          <IconButton sx={{ display: props.fieldProps?.disabled && 'none' }} onClick={() => props.dispatch(null)} color="error">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default FormPickerTextField;
