import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import { FraudService } from '../../../http/fraud/FraudService';
import { IRuleSetting } from '../../../types/app/fraud/RuleSetting';
import RuleSettingsTabContentData from './RuleSettingsTabContentData';
import TabLoader from '../../Common/Loader/TabLoader';
import RuleSettingsTabDataTweaks from './RuleSettingsTabDataTweaks';
import Divider from '../../../UI/Delimiter/Divider';
import { IPartner } from '../../../types/app/partner/Partner';
import {PartnerService} from '../../../http/partner/PartnerService';
import {IFilters} from '../../../store/rtk/reducers/serverPaginationSlice';
import RuleSettingsTabFilters from './Operations/RuleSettingsTabFilters';

export const defaultFilterState: IFilters = {
  eq: {
    isActive: null,
  },
  like: {
    companyName: '',
    ruleName: '',
  }
}

const RuleSettingsTabMain = () => {
  const { items, setFilters, getPaginatable, setServerMethod, getDialogFilterable, getFilterable } = useTweakTool<IRuleSetting>();

  const [partnerMap, setPartnerMap] = useState<Map<number, IPartner>>(null);

  useEffect(() => {
    setServerMethod(FraudService.getFilteredRuleSettings);
    setFilters(defaultFilterState);

    PartnerService.getPartners().then(response => {
      const map = new Map<number, IPartner>();

      response.forEach(partner => map.set(partner.key, partner));

      setPartnerMap(map);
    })
  }, []);

  if (!items || !partnerMap) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="Управление правилами" />

      <RuleSettingsTabDataTweaks {...getFilterable} />
      <RuleSettingsTabFilters {...getDialogFilterable} />

      <Divider />

      <RuleSettingsTabContentData partnerMap={partnerMap} {...getPaginatable} />
    </Box>
  );
};

export default RuleSettingsTabMain;
