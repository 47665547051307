import React from 'react';
import MuiTabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { v4 as uuid } from 'uuid';
import Tab from '@mui/material/Tab';
import { useUrlManager } from '../../hooks/useUrlManager';
import { useActions } from '../../hooks/hooks';

interface ITabContainerProps {
  items: { tabIndex: number; title: string }[];
}

const Tabs = ({ items }: ITabContainerProps) => {
  const { tabHashingToUrl, tabIndex } = useUrlManager();
  const { clear } = useActions();

  return (
    <MuiTabs
      sx={{
        marginLeft: 7,
      }}
      value={tabIndex}
      onChange={(event, value) => {
        clear();
        tabHashingToUrl({ page: window.location.pathname, tabIndex: value });
      }}
    >
      {items.map(item => (
        <Tab
          key={uuid()}
          sx={{
            '&:hover > p': {
              borderColor: 'primary.main',
            },
          }}
          label={
            <Typography
              paddingBottom="8px"
              borderBottom="2px solid #CBDDF7"
              variant="body1"
              sx={[
                theme => ({
                  borderColor: item.tabIndex === tabIndex ? theme.palette.primary.main : '#CBDDF7',
                  color: item.tabIndex === tabIndex ? theme.palette.grey.A700 : 'primary.main',
                }),
              ]}
            >
              {item.title}
            </Typography>
          }
        />
      ))}
    </MuiTabs>
  );
};

export default Tabs;
