import React, {useContext, useEffect, useState} from 'react';
import {useAsyncValue, useNavigate} from 'react-router-dom';
import {Grid, Typography} from '@mui/material';
import {RouteList} from '../../../../http/RouteList';
import Divider from '../../../../UI/Delimiter/Divider';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import {FormContext} from '../../../../store/context/form/FormContext';
import MainForm from '../../../Common/Form/MainForm';
import FormActions from '../../../../UI/Form/FormActions';
import FormDatePicker from '../../../../UI/Pickers/FormDatePicker';
import {IGetDataForNewReportLoader} from '../../../../http/reports/ReportLoaders';
import {getReportTypeNames} from '../../../../enums/ReportTypes';
import {getReportPeriodTypeNames, getReportPeriodValueName} from '../../../../enums/ReportPeriodTypes';
import {useNotifications} from '../../../../hooks/useNotifications';
import {messages} from '../../../../notifications/messages';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';

const ReportRulesCreateForm = () => {

  const {partners, reportTypes} = useAsyncValue() as IGetDataForNewReportLoader;
  const {setConfig} = useContext(FormContext);
  const link = useNavigate();
  const [emails, setEmails] = useState<string[]>(['']);
  const message = useNotifications();
  const [periodTypeId, setPeriodTypeId] = useState(0)

  const handleChange = (index: number, value: string) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const handleRemoveEmail = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  useEffect(() => {
    setConfig({
      action: RouteList.REPORTS_RULES_CREATE,
      readonly: false,
      rules: {
        partner: ['required'],
        format: ['required'],
        startDate: ['required'],
        email: ['required'],
        periodValue: ['required', 'digits', 'greaterThan: 0']
      },
      afterSubmitHandler: formResponse => {
        if (formResponse.status) {
          message.success(messages.success.reportRulesCreateOperation);
        }
        else {
          message.error(messages.errors.generalError);
        }

        link(-1);
      },
    });
  }, []);

  return (
    <MainForm back={RouteList.REPORTS}>

      <Typography variant="h1" mb={5}>
        Формирование отчета
      </Typography>

      <Grid container>
        <Grid item xs={5}>
          <FormSelect
            title="Партнер"
            items={partners}
            selectProps={{
              defaultValue: partners[0].key,
              name: 'partner',
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={5}>
          <FormSelect
            title="Тип отчета"
            items={getReportTypeNames(reportTypes)}
            selectProps={{
              defaultValue: 0,
              name: 'report_type',
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={5}>
          <FormDatePicker title="Дата старта отправки отчета" fieldProps={{name: 'startDate'}}/>
        </Grid>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={5}>
          <FormSelect
            title="Периодичность"
            items={getReportPeriodTypeNames()}
            selectProps={{
              onChange: e => setPeriodTypeId(e.target.value as number),
              value: periodTypeId,
              name: 'period',
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container width="100%" spacing={1}>
        <Grid item xs={5}>
          <FormOutlinedInput
            title={`Количество ${getReportPeriodValueName(periodTypeId)}`}
            inputProps={{
              name: 'periodValue',
              defaultValue: 1
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container display="flex" flexDirection="column" alignItems="flex-start" paddingTop={1} width="100%">
        <Grid item xs={5}>
          <Typography variant="body2" color="grey.700">
            Email для отправки отчета:
          </Typography>
        </Grid>


        {emails.map((email, index) => (
          <Grid container display="flex" key={index} item paddingTop={2}>
            <Grid item>
              <FormOutlinedInput
                inputProps={{
                  sx: {width: '300px'},
                  value: email,
                  type: 'email',
                  placeholder: 'Введите email',
                  name: 'email',
                  onChange: (e) => handleChange(index, e.target.value)
                }}
              />
            </Grid>
            <Grid item>
              {index > 0 && (
                <Grid item display="inline-grid">
                  <Typography sx={{color: 'red', cursor: 'pointer'}} onClick={() => handleRemoveEmail(index)}
                              paddingTop={2} paddingLeft={2}>
                    X
                  </Typography>
                </Grid>
              )}
            </Grid>


            <input type="hidden" value={emails} name="emails"/>
          </Grid>
        ))}

        <input type="hidden" value={emails} name="emails"/>

        <Typography paddingTop={2} variant="body2" sx={{color: 'blue', cursor: 'pointer'}} onClick={handleAddEmail}>
          Добавить дополнительный Email
        </Typography>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={2}>
          <FormSelect
            items={[
              {
                key: 0,
                label: 'XLSX',
              },
              {
                key: 1,
                label: 'PDF',
              }
            ]}
            title="Формат"
            selectProps={{name: 'format', defaultValue: 0}}
          />
        </Grid>
      </Grid>

      <Divider/>

        <FormActions title="Запланировать отправку"/>

    </MainForm>
  );
};

export default ReportRulesCreateForm;
