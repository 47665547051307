import React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const TabTitle = ({ title }: { title: string }) => {
  return (
    <Box marginBottom={3}>
      <Typography variant="h3" component="div">
        {title}
      </Typography>
    </Box>
  );
};

export default TabTitle;
