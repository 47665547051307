import React from 'react';
import { Grid } from '@mui/material';
import PerPage from '../../../UI/Table/PerPage';
import AddButton from '../../../UI/Button/AddButton';
import { useNavigate } from 'react-router-dom';
import { RouteList } from '../../../http/RouteList';
import FilterGroupButtons from '../../../UI/Button/FilterGroupButtons';
import {IFilterable} from '../../../types/app/common/Filterable';

const RuleSettingsTabDataTweaks = (props: IFilterable) => {
  const route = useNavigate();

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <AddButton onClick={() => route(RouteList.FRAUD_MONITORING_RULE_SETTING_CREATE)}>Добавить правило</AddButton>
      </Grid>
      <Grid item>
        <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
      </Grid>
      <Grid item>
        <PerPage />
      </Grid>
    </Grid>
  );
};

export default RuleSettingsTabDataTweaks;
