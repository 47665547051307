import React, {useEffect, useState} from 'react';
import { AccountService } from '../../http/account/AccountService';
import { useTweakTool } from '../../hooks/table/useTweakTool';
import TabLoader from '../Common/Loader/TabLoader';
import { ICertificate } from '../../types/app/account/Certificate';
import CertificateTabContentData from './CertificateTabContentData';
import CertificateTabDataTweaks from './CertificateTabDataTweaks';
import CertificateTabFilters from './CertificateTabFilters';
import { IFilters } from '../../store/rtk/reducers/serverPaginationSlice';
import { unix } from 'moment';
import Divider from '../../UI/Delimiter/Divider';
import FormCard from '../Common/Form/FormCard';

export const defaultFilterState: IFilters = {
  like: {
    email: '',
  },
  eq: {
    type: null,
    status: null,
  },
  from: {
    expires_date: null,
    amount: null,
  },
  to: {
    expires_date: null,
    amount: null,
  },
};

const CertificateTabContentMain = () => {
  const { items, setFilters, getPaginatable, getFilterable, getDialogFilterable, setServerMethod, setMutator } = useTweakTool<ICertificate>();

  useEffect(() => {
    setMutator(param => {
      if (param.expires_date_start) {
        param.expires_date_start = unix(param.expires_date_start).format('YYYY-MM-DD')
      }

      if (param.expires_date_end) {
        param.expires_date_end = unix(param.expires_date_end).format('YYYY-MM-DD')
      }

      return param;
    })


    setServerMethod(AccountService.getFilteredCertificates);
    setFilters(defaultFilterState);
  }, []);

  if (!items) {
    return <TabLoader />;
  }

  return (
    <FormCard template="full" gridProps={{title: 'Сертификаты'}}>

      <CertificateTabDataTweaks {...getFilterable} />

      <CertificateTabFilters {...getDialogFilterable} />

      <Divider />

      <CertificateTabContentData {...getPaginatable} />
    </FormCard>
  );
};

export default CertificateTabContentMain;
