import React, { useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ISelectable } from '../types/app/common/Selectable';
import { v4 as uuid4 } from 'uuid';
import {isNumber} from 'lodash';

const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export interface ISearchSelectProps {
  items: ISelectable[];
  itemDisabled?: string[];
  placeholder?: string;
  selectProps?: SelectProps;
  fieldProps?: TextFieldProps;
}

export default function SearchSelect(props: ISearchSelectProps) {
  const selectId = uuid4();
  const [searchText, setSearchText] = useState<string>('');

  const [isOverload, setIsOverload] = useState<boolean>(false);

  const getOptions = () => {
    let viewItems = [...props.items];

    if (props.selectProps?.value || isNumber(props.selectProps?.value)) {
      const selected = viewItems.find(item => String(item.key) === String(props.selectProps.value));

      delete viewItems[viewItems.findIndex(item => String(item.key) === String(props.selectProps.value))];

      viewItems.unshift(selected);
    }

    return viewItems.filter(option => containsText(option.label, searchText));
  };

  const options = useMemo(() => {
    const optns = getOptions();

    if (optns.length > 100) {
      setIsOverload(true);

      return optns.splice(0, 10);
    }

    return optns;
  }, [searchText, props.selectProps?.value])

  const isItemDisabled = (option: ISelectable) => {
    if (props.selectProps?.value === '') {
      return false;
    }

    if (Number(props.selectProps?.value) === Number(option.key)) {
      return true;
    }

    if (!props.itemDisabled) {
      return false;
    }

    return props.itemDisabled.indexOf(option.key as string) > -1;
  };

  return (
    <FormControl fullWidth>
      {props.selectProps?.label && (
        <Box mb={1}>
          <InputLabel sx={{ ml: 1.5, opacity: 1 }} htmlFor={selectId}>{props.selectProps.label}</InputLabel>
        </Box>
      )}
      <Select
        MenuProps={{ autoFocus: false }}
        onClose={() => setSearchText('')}
        renderValue={() => {
          let val;

          if (props.selectProps?.value || isNumber(props.selectProps?.value)) {
            val = String(props.selectProps?.value);
          }

          if (props.selectProps?.defaultValue || isNumber(props.selectProps?.defaultValue)) {
            val = String(props.selectProps?.defaultValue);
          }

          if (val) {
            return props.items.find(item => String(item.key) === val).label;
          }

          return undefined;
        }}
        {...props.selectProps}

        sx={{
          ...props.selectProps?.sx,
        }}
      >
        <ListSubheader sx={{ padding: 2 }}>
          <TextField
            placeholder={props.placeholder}
            size="small"
            autoFocus
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            {...props.fieldProps}
            onChange={e => {
              setSearchText(e.target.value);
              props.selectProps.value = "";

              if (props.fieldProps?.onChange) {
                props.fieldProps.onChange(e);
              }
            }}
            onKeyDown={e => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {options.length === 0 ? (
          <Box padding={2}>
            <Typography variant="body1">Значений не найдено...</Typography>
          </Box>
        ) : (
          options.map(option => (
            <MenuItem disabled={isItemDisabled(option)} key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))
        )}
        {isOverload && options.length !== 0 && <MenuItem disabled>...</MenuItem>}
      </Select>
    </FormControl>
  );
}
