import { useAsyncValue, useNavigate, useParams } from 'react-router-dom';
import { ICategory } from '../../../../types/app/catalog/Category';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormContext } from '../../../../store/context/form/FormContext';
import { useSnackbar } from 'notistack';
import { RouteList } from '../../../../http/RouteList';
import { snackConfig } from '../../../../layout/assets/config/snackConfig';
import { CatalogService } from '../../../../http/catalog/CatalogService';
import MainForm from '../../../Common/Form/MainForm';
import Confirm from '../../../Common/Modal/Confirm';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import FormActions from '../../../../UI/Form/FormActions';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { DeleteOutlineOutlined } from '@mui/icons-material';

export const CategoryShowForm = () => {
  const categories = useAsyncValue() as ICategory[];

  const { setConfig } = useContext(FormContext);
  const { enqueueSnackbar } = useSnackbar();

  const route = useNavigate();
  const { id } = useParams();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const category = useMemo<ICategory>(() => categories.find(cat => cat.key === +id), [id]);

  useEffect(() => {
    setConfig({
      action: RouteList.CATALOG_CATEGORY_SHOW + '/' + id,
      readonly: true,
      rules: {
        name: ['required'],
      },
      afterSubmitHandler: (formResponse, config, setConfig) => {
        enqueueSnackbar('Товар обновлен. Изменения сохранены.', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        });

        setConfig({...config, readonly: true});
      },
    });
  }, []);

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const deleteCategoryHandler = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await CatalogService.deleteCategory({ category_id: +id });

      enqueueSnackbar('Выбранная категория была удалена', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      route(-1);
    } catch {
      enqueueSnackbar('Выбранную категорию не удалось удалить', {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });
    } finally {
      closeConfirm();
      setIsLoading(false);
    }
  };

  return (
    <MainForm back={RouteList.CATALOG}>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteCategoryHandler}
        text={`Вы действительно хотите удалить категорию ${category.label}? 
        Все товары данной категории также будут удалены.
        Данное действие нельзя будет отменить.`}
      />

      <FormOutlinedInput
        title="Наименование категории"
        typography="h2"
        inputProps={{
          defaultValue: category.label,
          name: 'name',
        }}
      />

      <FormActions>
        <Grid container>
          <Button
            disabled={isLoading}
            onClick={openConfirm}
            variant="outlined"
            color="error"
            startIcon={<DeleteOutlineOutlined />}
          >
            Удалить категорию
          </Button>
        </Grid>
      </FormActions>
    </MainForm>
  );
};
