import React, { useState } from 'react';
import { IRuleSetting } from '../../../../types/app/fraud/RuleSetting';
import FraudUpdateForm from './Common/FraudUpdateForm';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import { getConditionDefaultValue } from '../../../../utils/fraudUtils';
import { FraudSettingConditionNameEnum, FraudSettingConditionNames } from '../../../../enums/FraudRules';
import Divider from '../../../../UI/Delimiter/Divider';

const SalesFraudForm = (props: IRuleSetting) => {
  const [ordersCountMax, setOrdersCountMax] = useState<number>(
    getConditionDefaultValue(FraudSettingConditionNameEnum.ORDERS_COUNT_MAX, props.ruleSettingConditions)
  );

  const [ordersPeriodDaysCount, setOrdersPeriodDaysCount] = useState<number>(
    getConditionDefaultValue(FraudSettingConditionNameEnum.ORDERS_PERIOD_DAYS_COUNT, props.ruleSettingConditions)
  );

  return (
    <FraudUpdateForm settings={props}>
      <FormOutlinedInput
        inputProps={{
          name: FraudSettingConditionNameEnum.ORDERS_COUNT_MAX,
          value: ordersCountMax,
          onChange: e => setOrdersCountMax(+e.target.value),
        }}
        rule={e => !isNaN(+e.target.value)}
        title={FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.ORDERS_COUNT_MAX)}
      />

      <Divider />

      <FormOutlinedInput
        inputProps={{
          name: FraudSettingConditionNameEnum.ORDERS_PERIOD_DAYS_COUNT,
          value: ordersPeriodDaysCount,
          onChange: e => setOrdersPeriodDaysCount(+e.target.value),
        }}
        rule={e => !isNaN(+e.target.value) && +e.target.value <= 100}
        title={FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.ORDERS_PERIOD_DAYS_COUNT)}
      />
    </FraudUpdateForm>
  );
};

export default SalesFraudForm;
