export enum CardTypes {
  ARBITRARY_CARD = 'arbitrary',
  BANK_CARD = 'bankCard',
  TRANSPORT_CARD = 'transportCard',
}

export enum CardNames {
  ARBITRARY_CARD = 'Внешняя карта',
  BANK_CARD = 'Банковская карта',
  TRANSPORT_CARD = 'Транспортная карта',
}


/**
 * Get card name by type
 *
 * @param type
 */
export const getBankNameByType = (type: CardTypes) => {
  switch (type) {
    case CardTypes.ARBITRARY_CARD: return CardNames.ARBITRARY_CARD;
    case CardTypes.TRANSPORT_CARD: return CardNames.TRANSPORT_CARD;
    default: return CardNames.BANK_CARD;
  }
}