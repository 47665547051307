import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Card, Grid, IconButton, InputAdornment, Typography} from '@mui/material';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import RegistrationPasswordRequirements from '../Registration/RegistrationPasswordRequirements';
import {ReactComponent as LockIcon} from '../../../icons/input-lock.svg';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChangePasswordConfirmation from './ChangePasswordConfirmation';
import { FormContext, IFormConfig } from '../../../store/context/form/FormContext';
import Form from '../../../UI/Form/Form';
import { RouteList } from '../../../http/RouteList';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import { SearchKeys } from '../../../enums/SearchKeys';
import { LoadingButton } from '@mui/lab';

const ChangePassword = () => {
  const [isShowChangePasswordConfirm, setIsShowChangePasswordConfirm] = useState<boolean>(false);
  const [isShowPasswordRequirements, setIsShowPasswordRequirements] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');


  const link = useNavigate();
  const { isValid, setConfig, onSubmit} = useContext(FormContext);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>();

  const [showPassword, setShowPassword] = useState<boolean>();
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>();

  const send = (e: React.MouseEvent) => {
    onSubmit((e.target as HTMLButtonElement).form);
  }

  const formConfig: IFormConfig = {
    rules: {
      password: ['required', 'latinonly', 'min:10', 'digits', 'characters', 'lowercase', 'uppercase'],
      password_confirmation: ['required', 'same:password'],
    },
    action: RouteList.AUTH_CHANGE_PASSWORD,
    afterSubmitHandler: (formResponse) => {
      setIsLoading(false);

      if (formResponse.status) {
        setIsShowChangePasswordConfirm(true);
      } else {
        link(RouteList.AUTH_LOGIN);
      }
    },
    beforeSubmitHandler: (formData: FormData) => {
      setIsLoading(true);

      formData.set(SearchKeys.TOKEN, searchParams.get(SearchKeys.TOKEN));
      formData.set(SearchKeys.EMAIL, searchParams.get(SearchKeys.EMAIL));
    }
  };

  useEffect(() => {
    if (searchParams.get(SearchKeys.EMAIL) === null || searchParams.get(SearchKeys.TOKEN) === null) {
      link(RouteList.AUTH_LOGIN);
    }

    setConfig(formConfig);    
  }, []);


  if (isShowChangePasswordConfirm) {
    return (<ChangePasswordConfirmation/>);
  }

  return (
    <Grid
      container
      columns={12}
      justifyContent="center"
    >
      <Grid
        item
        xs
        sm={10}
        md={7}
        lg={7}
        xl={5}
      >
        <Grid
          container
          bgcolor="white"
          width="100%"
          borderRadius={4}
          component={Card}
          padding={5}
          pb={15}
          position="relative"
          maxWidth={720}
          margin="auto"
        >
          <Grid
            item
            xs
            margin="auto"
            sm={8}
            md={7}
            lg={5}
            pt={15}
            minWidth={200}
          >
            <Box>
              <RegistrationPasswordRequirements
                isShow={isShowPasswordRequirements}
                password={password}
              />
              <Form>
                <Box marginBottom={5}>
                  <Typography textAlign="center" variant="h1">
                    Восстановление пароля
                  </Typography>
                </Box>
                <Grid container flexDirection="column" justifyContent="center" alignItems="center">
                  <Grid item width="100%">
                    <FormOutlinedInput
                      inputProps={{
                        sx: {
                          '& > input': {
                            padding: '15px 10px',
                          },
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{padding: '0 10px'}}
                            position="start"
                          >
                            <LockIcon/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOffIcon sx={{fontSize: 20}}/> :
                              <VisibilityIcon sx={{fontSize: 20}}/>}
                          </IconButton>
                        ),
                        type: showPassword ? 'text' : 'password',
                        placeholder: 'Новый пароль',
                        onFocus: () => setIsShowPasswordRequirements(true),
                        onBlur: () => setIsShowPasswordRequirements(false),
                        onChange: (e) => setPassword(e.target.value),
                        name: 'password',
                        autoComplete: 'on'
                      }}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <FormOutlinedInput
                      inputProps={{
                        sx: {
                          '& > input': {
                            padding: '15px 10px',
                          },
                        },
                        startAdornment: (
                          <InputAdornment
                            sx={{padding: '0 10px'}}
                            position="start"
                          >
                            <LockIcon/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword ? <VisibilityOffIcon sx={{fontSize: 20}}/> :
                              <VisibilityIcon sx={{fontSize: 20}}/>}
                          </IconButton>
                        ),
                        type: showConfirmPassword ? 'text' : 'password',
                        placeholder: 'Подтверждение пароля',
                        name: 'password_confirmation',
                        autoComplete: 'on'
                      }}
                    />
                  </Grid>
                </Grid>

                <LoadingButton
                  form="router-form"
                  sx={{ borderRadius: '16px', padding: '19px 32px', marginTop: 2 }}
                  loading={isLoading}
                  disabled={!isValid}
                  onClick={send}
                  fullWidth
                >
                  <Typography variant="subtitle2">
                    Сохранить
                  </Typography>
                </LoadingButton>
              </Form>
            </Box>
          </Grid>
          <Grid container justifyContent="center" mt={2}>
            <Grid item>
              <Grid container justifyContent="space-between" columns={2} spacing={3}>
                <Grid item xs>
                  <Link to={RouteList.AUTH_LOGIN}>
                    <Button variant="text">
                      Ко входу в систему
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};


export default ChangePassword;