import React, {useContext, useEffect, useState} from 'react';
import {useAsyncValue} from 'react-router-dom';
import {ITariffShowLoader} from '../../../../../http/billing/BillingLoaders';
import MainForm from '../../../../Common/Form/MainForm';
import {RouteList} from '../../../../../http/RouteList';
import {Grid, Typography} from '@mui/material';
import {TariffTypes} from '../../../../../enums/TariffTypes';
import Divider from '../../../../../UI/Delimiter/Divider';
import FormSelect from '../../../../../UI/Form/Select/FormSelect';
import FormOutlinedInput from '../../../../../UI/Form/FormOutlinedInput';
import FormTimePicker from '../../../../../UI/Pickers/FormTimePicker';
import FormSwitch from '../../../../../UI/Form/FormSwitch';
import FormActions from '../../../../../UI/Form/FormActions';
import {FormContext} from '../../../../../store/context/form/FormContext';
import {useSnackbar} from 'notistack';
import {snackConfig} from '../../../../../layout/assets/config/snackConfig';
import TariffCommonInputs from './TariffCommon';
import FormDatePicker from '../../../../../UI/Pickers/FormDatePicker';
import { isArray } from 'lodash';

const TariffShowForm = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {products, categories, partners, tariff} = useAsyncValue() as ITariffShowLoader;

  const {setConfig} = useContext(FormContext);

  const [type, setType] = useState<TariffTypes>(tariff.tariffType);
  const [priority, setPriority] = useState<number>(tariff.priority);
  const [lifetimeDays, setLifetimeDays] = useState<number>(tariff.lifetimeDays ?? 0);
  const [comment, setComment] = useState<string>(tariff.comment);
  const [percentOrAmount, setPercentOrAmount] = useState<number|string>(
    type === TariffTypes.AMOUNT ? tariff.amount : tariff.percent);
  const [name, setName] = useState<string>(tariff.name);

  const getRules = () => {

    if (tariff.active !== 1) {
      return {
        name: ['required'],
      };
    }
    else return {};
  };

  useEffect(() => {
    tariff.active
      ? setConfig({
      readonly: true,
      action: RouteList.BILLING_SHOW_TARIFF + '/' + tariff.id,
      rules: getRules(),
      data: [
        {field: 'id', value: tariff.id},
        {field: 'name', value: tariff.name},
        {field: 'lifetime_days', value: lifetimeDays},
        {field: 'amount', value: percentOrAmount},
        {field: 'percent', value: percentOrAmount},
        {field: 'comment', value: comment},
        {field: 'tariff_type', value: type},
        {field: 'exclusivity', value: tariff.exclusivity === 1 ? 'on' : 'off'},
        {field: 'priority', value: priority},
        {field: 'products_ids', value: isArray(tariff.productsIds) ? tariff.productsIds.join(',') : ''},
        {field: 'categories_ids', value: isArray(tariff.categoriesIds) ? tariff.categoriesIds.join(',') : ''},
        {field: 'partners_ids', value: isArray(tariff.partnersIds) ? tariff.partnersIds.join(',') : ''},
      ],
      afterSubmitHandler: (formResponse, config, setConfig) => {
        enqueueSnackbar('Тариф обновлен. Изменения сохранены.', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        });

        setConfig({...config, readonly: true});
      },
    })
      : setConfig({
        readonly: true,
        action: RouteList.BILLING_SHOW_TARIFF + '/' + tariff.id,
        rules: getRules(),
        data: [
          {field: 'id', value: tariff.id}
        ],
        afterSubmitHandler: (formResponse, config, setConfig) => {
          enqueueSnackbar('Тариф обновлен. Изменения сохранены.', {
            variant: 'success',
            autoHideDuration: snackConfig.duration,
          });

          setConfig({...config, readonly: true});
        },
      })
  }, [tariff]);

  return (
    <MainForm back={RouteList.BILLING}>
      <FormOutlinedInput
        typography="h1"
        inputProps={{
          disabled: tariff.active === 1,
          value: name,
          onChange: (event) => {
            setName((event.target.value));
          },
          name: 'name',
          placeholder: 'Введите наименование тарифа',
        }}
      />

      <Divider/>

      <Typography marginBottom={1} variant="body2" color="grey.700">
        Настройки тарифа:
      </Typography>

      <FormSwitch
        title="Исключительность"
        switchProps={{
          name: 'exclusivity',
          defaultChecked: tariff.exclusivity === 1,
          disabled: tariff.active === 1
        }}/>
      <FormSwitch
        title="Действующий"
        switchProps={{
          name: 'active',
          defaultChecked: tariff.active === 1,
        }}
      />

      <Divider/>

      <FormSelect
        items={[
          {key: TariffTypes.AMOUNT, label: 'Бонус'},
          {key: TariffTypes.PERCENT, label: 'Скидка'},
          {key: TariffTypes.CASHBACK, label: 'Вознаграждение за покупку'},
        ]}
        title="Тип тарифа"
        selectProps={{
          disabled: tariff.active === 1,
          name: 'tariff_type',
          defaultValue: type,
          onChange: e => {
            setType(e.target.value as TariffTypes);
            setPercentOrAmount(0);
          },
          sx: {
            width: '50%',
          },
        }}
      />

      <Divider/>

      <TariffCommonInputs
        type={type}
        dispatchPercentOrAmount={setPercentOrAmount}
        percentOrAmount={percentOrAmount}
        isDisabled={tariff.active === 1}
        tariff={tariff}
        lifetimeDays={lifetimeDays}
        setLifetimeDays={setLifetimeDays}
      />

      <FormSelect
        title="Товары, к которым применяется тариф"
        items={products.map(product => ({key: product.id, label: product.name}))}
        selectProps={{
          disabled: tariff.active === 1,
          name: 'products_ids',
          defaultValue: tariff.productsIds,
          multiple: true,
        }}
        multipletweaks={{
          disabled: tariff.active === 1,
          selectAll: tariff.active !== 1,
          unselectAll: tariff.active !== 1
        }}
      />

      <Divider/>

      <FormSelect
        title="Категории, к которым применяется тариф"
        items={categories.map(category => ({key: category.key, label: category.label}))}
        selectProps={{
          disabled: tariff.active === 1,
          name: 'categories_ids',
          defaultValue: tariff.categoriesIds,
          multiple: true,
        }}
        multipletweaks={{
          disabled: tariff.active === 1,
          selectAll: tariff.active !== 1,
          unselectAll: tariff.active !== 1
        }}
      />

      <Divider/>

      <FormSelect
        title="Партнеры, к которым применяется тариф"
        items={partners.map(partner => ({key: partner.key, label: partner.label}))}
        selectProps={{
          name: 'partners_ids',
          disabled: tariff.active === 1,
          defaultValue: tariff.partnersIds,
          multiple: true,
        }}
        multipletweaks={{
          disabled: tariff.active === 1,
          selectAll: tariff.active !== 1,
          unselectAll: tariff.active !== 1
        }}
      />

      <Divider/>

      <FormOutlinedInput
        title="Приоритет"
        inputProps={{
          name: 'priority',
          placeholder: 'Только цифры',
          disabled: tariff.active === 1,
          value: priority,
          onChange: e => setPriority(Number(e.target.value)),
          sx: {
            width: '50%',
          },
        }}
        rule={e => !isNaN(Number(e.target.value))}
      />

      <Divider/>

      <Grid container justifyContent="space-between" columns={2} spacing={1}>
        <Grid item xs={1}>
          <Typography marginBottom={1} variant="body2" color="grey.700">
            Начало действия тарифа:
          </Typography>

          <FormDatePicker
            nullable
            datepickerProps={{
              disabled: tariff.active === 1
            }}
            fieldProps={{
              name: 'valid_from',
              value: tariff.validFrom,
              disabled: tariff.active === 1
            }}
          />
        </Grid>

        <Grid item xs={1}>
          <Typography marginBottom={1} variant="body2" color="grey.700">
            Завершение действия тарифа:
          </Typography>

          <FormDatePicker
            nullable
            datepickerProps={{
              disabled: tariff.active === 1
            }}
            fieldProps={{
              name: 'valid_to',
              value: tariff.validTo,
              disabled: tariff.active === 1
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container justifyContent="space-between" columns={2} spacing={1}>
        <Grid item xs={1}>
          <Typography marginBottom={1} variant="body2" color="grey.700">
            Начало интервала действия тарифа:
          </Typography>

          <FormTimePicker
            nullable
            timepickerProps={{
              views: ['hours'],
              disabled: tariff.active === 1
            }}
            fieldProps={{
              name: 'valid_from_hour',
              value: tariff.validFromHour,
              disabled: tariff.active === 1
            }}
          />
        </Grid>

        <Grid item xs={1}>
          <Typography marginBottom={1} variant="body2" color="grey.700">
            Завершение интервала действия тарифа:
          </Typography>

          <FormTimePicker
            nullable
            timepickerProps={{
              views: ['hours'],
              disabled: tariff.active === 1
            }}
            fieldProps={{
              name: 'valid_to_hour',
              value: tariff.validToHour,
              disabled: tariff.active === 1
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container flexDirection="column">
        <Grid item xs>
          <Typography variant="body2" color="grey.700" mb={1}>
            Комментарий:
          </Typography>
        </Grid>
        <Grid item xs>
          <FormOutlinedInput
            inputProps={{
              disabled: tariff.active === 1,
              multiline: true,
              rows: 4,
              name: 'comment',
              onChange: e => setComment(e.target.value),
              value: comment,
              defaultValue: tariff.comment
            }}
          />
        </Grid>
      </Grid>

      <FormActions/>
    </MainForm>
  );
};

export default TariffShowForm;
