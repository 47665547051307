import React from 'react';
import {Avatar, Box, Button, Grid, SvgIcon, Typography} from '@mui/material';
import {useActions, useAppSelector} from '../../../hooks/hooks';
import {ReactComponent as UserLogo} from '../../../icons/user.svg';
import {useNavigate} from 'react-router-dom';
import {RouteList} from '../../../http/RouteList';

const HeaderAvatarButton = () => {
  const user = useAppSelector(state => state.auth.user);
  const {logout} = useActions();
  const route = useNavigate();

  return (
    <Grid
      onClick={() => route(RouteList.REQUISITES)}
      className="avatar"
      sx={{
        boxSizing: 'border-box',
        position: 'absolute',
        borderRadius: '24px',
        border: '3px solid transparent',
        padding: 1,
        top: '-20px',
        cursor: 'pointer',
        '&:hover': {
          borderColor: '#CBDDF7',
          boxShadow: '0px 2px 6px rgba(0, 66, 231, 0.1)',
          backgroundColor: 'white',
        },
        '&:hover .hidden': {display: 'flex'},
        paddingBottom: '37px',
      }}
      container
      columns={8}
      alignItems="center"
    >
      <Grid item xs={2}>
        <Avatar
          sx={{
            backgroundColor: 'transparent',
            width: '60px',
            height: '60px',
          }}
        >
          <SvgIcon
            viewBox="0 0 60 60"
            component={UserLogo}
            sx={{fontSize: 60}}
          />
        </Avatar>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <Typography
            component="p"
            variant="body1"
            color="text.primary"
            sx={{wordWrap: 'break-word'}}
          >
            {user.name ?? user.email}
          </Typography>
        </Box>
      </Grid>
      <Button
        onClick={() => logout()}
        className="hidden"
        sx={{position: 'absolute', bottom: 0, left: '25%', display: 'none'}}
        variant="text"
        color="error"
      >
        <Typography fontSize={12} variant="body2">
          Выйти из профиля
        </Typography>
      </Button>
    </Grid>
  );
};

export default HeaderAvatarButton;