import React from 'react';
import {Grid, GridProps, Typography} from '@mui/material';

export interface IFormGridProps extends GridProps{
  title?: string;
}

const FormGridAlone = (props: IFormGridProps) => {
  return (
    <Grid container columns={12} gap={1} justifyContent="start">
      <Grid position="relative" item xs={7} width="100%" height="100%">
        {props.title && <Typography mb={3} variant="h1">{props.title}</Typography>}
        <Grid width="100%" container justifyContent="center" alignItems="center" position='relative' {...props}>
          {props.children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormGridAlone;
