import React, {Dispatch, useCallback, useContext, useEffect, useState} from 'react';
import {FormContext} from '../../store/context/form/FormContext';
import {Box, Button, Grid, Typography} from '@mui/material';
import Image from 'mui-image';
import {useActions, useAppSelector} from '../../hooks/hooks';
import {IStorageFileData} from '../../store/rtk/reducers/storageSlice';
import {FileHelper} from '../../helpers/FileHelper';
import CircularProgress from '@mui/material/CircularProgress';
import ReplayIcon from '@mui/icons-material/Replay';
import {useSnackbar} from 'notistack';
import {snackConfig} from '../../layout/assets/config/snackConfig';
import FormErrorText from './FormErrorText';
import {ReactComponent as BlankImage} from '../../icons/img/blank.svg';
import {ReactComponent as UploadImage} from '../../icons/img/upload.svg';
import {ReactComponent as RefreshImage} from '../../icons/img/refresh.svg';
import {MimeTypes} from '../../enums/MimeTypes';

interface IFormImageProps {
  name?: string;
  // Для первого загружаемого изображения в форме используется ключ 'file'. Если в форме больше 1 изображения, используется props.formKey
  // Сделано для обратной совместимости.
  formKey?: string;
  src?: string;
  max?: number;
  title?: string;
  accept?: MimeTypes;
  height?: number;
  width?: number;
}

interface IFormImageWrapperProps {
  children: React.ReactNode;
  height: number;
  width: number;
  name?: string;
  formKey?: string;
  src?: string;
  max?: number;
  title: string | undefined;
  accept?: MimeTypes | undefined;
}

interface IFormImageContentProps {
  height: number;
  width: number;
  src?: string;
}

interface IFormFileProps {
  setIsLoading: Dispatch<boolean>;
  children: React.ReactNode;
  height: number;
  width: number;
  name?: string;
  formKey?: string;
  src?: string;
  max?: number;
  title: string | undefined;
  accept: MimeTypes | undefined;
}

const FormImageWrapper = (props: IFormImageWrapperProps) => {
  const {config} = useContext(FormContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (isLoading) {
    return (
      <Box
        position="relative"
        borderRadius={2}
        border="1px solid #E0EEFF"
        width={props.width}
        height={props.height}
      >
        <Box position="absolute" top={0} left={0} right={0} bottom={0}>
          <Grid container justifyContent="center">
            <CircularProgress sx={{mt: 5}} size={30}/>
          </Grid>
        </Box>
      </Box>
    );
  }

  if (config.readonly) {
    return <>{props.children}</>;
  }

  return (
    <FormImageFile
      accept={props.accept}
      max={props.max}
      src={props.src}
      name={props.name}
      formKey={props.formKey}
      title={props.title}
      children={props.children}
      setIsLoading={setIsLoading}
      height={props.height}
      width={props.width}
    />
  );
};

const FormImageFile = (props: IFormFileProps) => {
  const {config, setConfig, errors, errorWatcher} = useContext(FormContext);
  const {setFiles} = useActions();
  const {files} = useAppSelector(state => state.storage);
  const currentFiles = props.name ? files.filter(file => file.formName === props.name) : files;

  const {enqueueSnackbar} = useSnackbar();

  const accept = props.accept ?? 'image/*';

  useEffect(() => {
    if (currentFiles.length) {
      setConfig({ ...config, data: [...(config.data ?? []), { field: props.formKey ?? 'file', value: currentFiles}]});
    }
    else {
      setConfig({ ...config, data: [...(config.data ?? []), { field: props.formKey ?? 'file', value: []}]});
    }

    if (props.name) {
      errorWatcher(props.name, (currentFiles.length || props.src) ? 'uploaded' : '');
    }
  }, [currentFiles.length]);

  const loadFile = async (fileList: FileList) => {
    props.setIsLoading(true);

    const file = fileList[0];

    if (file.size > 1024 * 1024 * props.max) {
      props.setIsLoading(false);

      enqueueSnackbar('Загружаемое изображение не может быть больше ' + props.max + 'MB', {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });

      return;
    }

    const fileData = await FileHelper.readFile(file);
    const storageFileData: IStorageFileData = {
      fileName: file.name,
      data: fileData as string,
      type: file.type as MimeTypes,
      size: file.size,
    };

    if (props.name) {
      storageFileData.formName = props.name;
    }

    setFiles([...files, storageFileData ]);

    setTimeout(() => {
      props.setIsLoading(false);
    }, 500);
  };

  const revertImage = () => {
    setFiles(files.filter(file => file.formName !== props.name));
  };

  const getValue = useCallback(() => {
    if (props.src) {
      // Hook need's for required validation
      if (currentFiles.length) {
        return 'uploaded';
      }

      return props.src;
    }

    return currentFiles.length ? 'uploaded' : '';
  }, [currentFiles.length]);

 
  return (
    <Box position="relative">
      {props.title && (
        <Typography variant="body2" color="grey.600" mb={1}>
          {props.title}:
        </Typography>
      )}
      <Button
        variant="text"
        sx={[
          theme => ({
            padding: 0,
            width: 'auto',
            height: 'auto',
            borderColor: errors[props.name] ? theme.palette.error.main : 'rgba(164,164,164,0.5)',
          }),
        ]}
        component="label"
      >
        {currentFiles.length ?
          <Image style={{ borderRadius: 16 }} width={props.width} height={props.height} src={currentFiles[0].data}/> : props.children}
        <input readOnly type="hidden" name={props.name} value={getValue()}/>
        <input onChange={e => loadFile(e.target.files)} hidden accept={accept} type="file"/>
      </Button>
      <Box>
        {currentFiles.length !== 0 && (
          <Button sx={{mt: 1}} variant="outlined" onClick={revertImage} color="error"
                  endIcon={<ReplayIcon fontSize="small"/>}>
            Отменить
          </Button>
        )}
      </Box>
      <Box>{errors[props.name] && <FormErrorText error={errors[props.name]}/>}</Box>
    </Box>
  );
};

const FormImageContent = (props: IFormImageContentProps) => {
  const {config} = useContext(FormContext);

  if (props.src) {
    return (
      <Grid item width={props.width} position="relative">
        {!config.readonly && (
          <Box position="absolute" top={0} left={0} right={0} bottom={0} zIndex={10}>
            <Grid container justifyContent="center" alignItems="center" height="100%">
              <RefreshImage/>
            </Grid>
          </Box>
        )}
        <Image style={{borderRadius: 16, padding: 5}} height={props.height} duration={0} src={props.src}/>
      </Grid>
    );
  }

  return (
    <Box position="relative">
      <Box position="absolute" top={0} left={0} right={0} bottom={0}>
        <Grid container justifyContent="center" alignItems="center" height="100%">
          <UploadImage/>
        </Grid>
      </Box>
      <BlankImage/>
    </Box>
  );
};

const FormImage = (props: IFormImageProps) => {
  const { config } = useContext(FormContext);

  const height = props.height ?? 125;
  const width = props.width ?? 125;

  return (
    <FormImageWrapper
      accept={props.accept}
      title={props.title}
      max={props.max}
      src={props.src}
      name={props.name}
      formKey={props.formKey}
      height={height}
      width={width}
    >
      <Grid
        container
        justifyContent={config.readonly ? 'left' : 'center'}
      >
        <FormImageContent width={width} height={height} src={props.src}/>
      </Grid>
    </FormImageWrapper>
  );
};

export default FormImage;
