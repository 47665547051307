import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Image from 'mui-image';
import { useSnackbar } from 'notistack';
import { unix } from 'moment/moment';
import { snackConfig } from '../../../../layout/assets/config/snackConfig';
import { ILastNotice } from '../../../../types/app/common/Notification';
import { useActions } from '../../../../hooks/hooks';
import { CommunicationService } from '../../../../http/communication/CommunicationService';
import BackButton from '../../../../UI/Button/BackButton';

interface NotificationShowProps {
  notices: ILastNotice[];
  notice: ILastNotice;
  setCurrentNotice: React.Dispatch<React.SetStateAction<ILastNotice>>;
  setLastNotices: React.Dispatch<React.SetStateAction<ILastNotice[]>>;
}

const NotificationShow = (props: NotificationShowProps) => {
  const {enqueueSnackbar} = useSnackbar();

  const {incrementUnreadNoticeCount, updateLastNoticeByReadMark} = useActions();

  useEffect(() => {

    if (!props.notice.read) {
      CommunicationService
        .webNotificationsMarkAsRead({ids: [props.notice.id]})
        .then((readMark) => {
          updateLastNoticeByReadMark(readMark);
          incrementUnreadNoticeCount();
        })
        .catch(() => {
          enqueueSnackbar(
            'Профиль пользователя не обнаружен. Статус уведомления изменить не удалось.',
            {
              variant: 'error',
              autoHideDuration: snackConfig.duration,
            });
        });
    }
  }, []);

  return (
    <Box>
      <BackButton onClick={() => props.setCurrentNotice(null)} />
      <Box>
        <Box mb={2}>
          <Typography variant="h1">{props.notice.data.header}</Typography>
        </Box>

        <Grid
          container
          spacing={2}
          alignItems="center"
          mb={3}
        >
          <Grid item>
            <Image duration={0} width={15} src={props.notice.data.icon}/>
          </Grid>
          <Grid item>
            <Typography color="primary" variant="body2">
              {props.notice.data.category}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="primary" sx={{color: 'grey.600'}}>
              {unix(props.notice.createdAt).format('DD.MM.YYYY')}
            </Typography>
          </Grid>
        </Grid>

        <Box>
          <Typography fontSize={18} lineHeight={2} variant="body1" component='pre'>
            <span
              style={{width: '100%',  wordWrap: 'break-word', overflow: 'hidden', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: props.notice.data.long }}>
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationShow;