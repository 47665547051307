import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';
import {IGetProductLoader} from '../../../http/catalog/CatalogLoaders';
import CatalogShowForm from '../../../Widgets/Catalog/CatalogTab/Operations/CatalogShowForm';

const CatalogShow = () => {
  const { content } = useLoaderData() as { content: Promise<IGetProductLoader> };

  return (
    <Await resolve={content}>
      <CatalogShowForm />
    </Await>
  );
};

export default CatalogShow;
