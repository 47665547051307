import React, { Suspense, useEffect } from 'react';
import { Box } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import PageLoader from '../../Common/Loader/PageLoader';
import Header from '../Header/Header';
import { RouteList } from '../../../http/RouteList';
import { useGuards } from '../../../hooks/useGuards';
import { useActions } from '../../../hooks/hooks';

const ContentRouter = () => {
  const { logout } = useActions();
  const { routeGuard } = useGuards();

  useEffect(() => {
    if (!routeGuard.isSomeRoutesAvailable()) {
      logout();
    }
  }, []);

  if (!routeGuard.isSomeRoutesAvailable()) {
    return <Navigate to={RouteList.AUTH_LOGIN} state={{ roleIsNotAvailable: true }} />;
  }

  if (!routeGuard.isRouteAvailable()) {
    return <Navigate to={routeGuard.getRedirectRoute()} />;
  }

  return (
    <Box position="relative">
      <Box height="100%" width="100%" padding="0 30px 30px 45px">
        <Header />
        <Suspense fallback={<PageLoader top={40} />}>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
};

export default ContentRouter;
