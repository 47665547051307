import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import RuleReportTabContentData from './RuleReportTabContentData';
import { FraudService } from '../../../http/fraud/FraudService';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import { IRuleReport } from '../../../types/app/fraud/RuleReport';
import TabLoader from '../../Common/Loader/TabLoader';
import RuleReportTabDataTweaks from './RuleReportTabDataTweaks';
import Divider from '../../../UI/Delimiter/Divider';
import RuleReportTabFilters from './RuleReportTabFilters';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import { unix } from 'moment';

export const defaultFilters: IFilters = {
  eq: {
    rule_id: null,
  },
  from: {
    created_date: null,
    created_time: null,
  },
  to: {
    created_date: null,
    created_time: null,
  },
  comparison: {
    id: 'ruleSetting.rule.id',
  },
};

const RuleReportTabMain = () => {
  const { items, getPaginatable, getFilterable, getDialogFilterable, setFilters, setServerMethod, setMutator } = useTweakTool<IRuleReport>();

  useEffect(() => {
    setMutator(param => {
      if (param.created_date_start) {
        param.created_date_start = unix(param.created_date_start).format('YYYY-MM-DD')
      }

      if (param.created_date_end) {
        param.created_date_end = unix(param.created_date_end).format('YYYY-MM-DD')
      }

      if (param.created_time_start) {
        param.created_time_start = unix(param.created_time_start).format('HH:mm')
      }

      if (param.created_time_end) {
        param.created_time_end = unix(param.created_time_end).format('HH:mm')
      }

      return param;
    })
    setFilters(defaultFilters);
    setServerMethod(FraudService.getFilteredRuleReports);
  }, []);

  if (!items) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="Оповещения" />

      <RuleReportTabDataTweaks {...getFilterable} />

      <RuleReportTabFilters {...getDialogFilterable} />

      <Divider />

      <RuleReportTabContentData {...getPaginatable} />
    </Box>
  );
};

export default RuleReportTabMain;
