import React from 'react';
import { Button, Typography } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import { ButtonProps } from '@mui/material/Button';
import { useNavigate, useNavigation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { NavigationStates } from '../../enums/NavigationStates';
import { useActions } from '../../hooks/hooks';

const BackButton = (props: ButtonProps) => {
  const navigation = useNavigation();
  const route = useNavigate();
  const { clear } = useActions();

  return (
    <Button
      variant="text"
      startIcon={navigation.state === NavigationStates.IDLE ? <UndoIcon /> : <CloseIcon color="error" />}
      sx={{ mb: 1 }}
      onClick={() => {
        clear();
        route(-1);
      }}
      {...props}
    >
      <Typography color="grey.600">Назад</Typography>
    </Button>
  );
};

export default BackButton;
