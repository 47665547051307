import React, {useState} from 'react';
import {useAsyncValue} from 'react-router-dom';
import {IUserBankAccountBalance} from '../../../../types/app/account/UserBankAccount';
import {Box, Button, Grid, Typography} from '@mui/material';
import Divider from '../../../../UI/Delimiter/Divider';
import FormCard from '../../../Common/Form/FormCard';
import BackButton from '../../../../UI/Button/BackButton';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import {UserTypes} from '../../../../enums/UserTypes';
import {AccountService} from '../../../../http/account/AccountService';
import {useSnackbar} from 'notistack';
import {snackConfig} from '../../../../layout/assets/config/snackConfig';
import {OperationTypes} from '../../../../enums/OperationTypes';
import {IOperationPayIORequest} from '../../../../types/request/account/OperationPayIORequest';
import {RemittanceTypes} from '../../../../enums/RemittanceTypes';
import BankingOperationsPopup from '../../../Common/Banking/BankingOperationsPopup';
import BankAccountFormMixin from '../../Common/BankAccountFormMixin';

const UserBankAccountsShow = () => {
  const account = useAsyncValue() as IUserBankAccountBalance;

  const [userBankAccount, setUserBankAccount] = useState<IUserBankAccountBalance>(account);

  const [amount, setAmount] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const [selectedBalanceType, setSelectedBalanceType] = useState<OperationTypes>(OperationTypes.POINT);

  const [selectedType, setSelectedType] = useState<RemittanceTypes>(RemittanceTypes.IN);

  const [expDate, setExpDate] = useState<string>(null);

  const [error, setError] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const send = async () => {
    setIsLoading(true);

    try {
      const request: IOperationPayIORequest = {
        user_id: userBankAccount.userId,
        user_type: UserTypes.USER,
        type: selectedBalanceType,
        amount: +amount,
        description: description,
        exp_date_points: expDate,
      };

      if (selectedType === 'in') {
        !expDate && delete request.exp_date_points
        await AccountService.operationAccountsPayIn(request).then(res => {
          setUserBankAccount(prevState => ({
            ...prevState,
            point: res.point,
            blockPoint: res.blockPoint,
          }));
        });
      } else {
        delete request.exp_date_points
        await AccountService.operationAccountsPayOut(request).then(res => {
          setUserBankAccount(prevState => ({
            ...prevState,
            point: res.point,
            blockPoint: res.blockPoint,
          }));
        });
      }

      enqueueSnackbar('Баланс обновлен', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });
    } finally {
      setAmount('');
      setDescription('');
      setIsLoading(false);
      setOpenPopup(false);
      setExpDate(null)
    }
  };

  const errorWatcher = (value: number): boolean => {
    if (selectedType === RemittanceTypes.IN) {
      return;
    }

    switch (selectedBalanceType) {
      case OperationTypes.POINT:
        if (userBankAccount.point < value) {
          setError('Баланс баллов аккаунта меньше указанного значения');

          return;
        }

        break;
      case OperationTypes.BLOCK_POINT:
        if (userBankAccount.blockPoint < value) {
          setError('Баланс блокировочных баллов аккаунта меньше указанного значения');

          return;
        }

        break;
    }

    setError('');
  };

  return (
    <Grid container>
      <BackButton />
      <FormCard>
        <Grid container flexDirection="column">
          <Typography variant="body2" color="grey.600" mb={1}>
            Email Пользователя:
          </Typography>
          <Typography variant="h1">{userBankAccount.email}</Typography>
        </Grid>

        <Divider />

        <Grid container flexDirection="column">
          <Typography variant="body2" color="grey.600" mb={1}>
            Идентификатор:
          </Typography>
          <Typography>{userBankAccount.userId}</Typography>
        </Grid>

        <Divider />

        <Grid container columns={2}>
          <Grid item xs>
            <Grid container flexDirection="column">
              <Typography variant="body2" color="grey.600" mb={1}>
                Баланс на балльном счете:
              </Typography>
              <Typography>{userBankAccount.point}</Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container flexDirection="column">
              <Typography variant="body2" color="grey.600" mb={1}>
                Баланс на балльном блокировочном счете:
              </Typography>
              <Typography>{userBankAccount.blockPoint}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Box sx={{ textAlign: 'center' }} mt={5}>
          <Button onClick={() => setOpenPopup(true)}>Изменить баланс</Button>
        </Box>

        <BankingOperationsPopup title={'Изменение баланса'} openPopup={openPopup} setOpenPopup={setOpenPopup}>
          <>
            <Box mb={2}>
              <Typography variant="body2" color="grey.600" mb={1}>
                Счет:
              </Typography>
              <FormSelect
                selectProps={{
                  value: selectedBalanceType,
                  onChange: e => {
                    setAmount('');
                    setError('');
                    setSelectedBalanceType(e.target.value as OperationTypes);
                  },
                }}
                items={[
                  { key: OperationTypes.POINT, label: 'Балльный счет' },
                  { key: OperationTypes.BLOCK_POINT, label: 'Балльный блокировочный счет' },
                ]}
              />
            </Box>

            <Box mb={2}>
              <Typography variant="body2" color="grey.600" mb={1}>
                Вид операции:
              </Typography>
              <FormSelect
                selectProps={{
                  value: selectedType,
                  onChange: e => {
                    setAmount('');
                    setError('');
                    setSelectedType(e.target.value as RemittanceTypes);
                  },
                }}
                items={[
                  { key: RemittanceTypes.IN, label: 'Пополнение' },
                  { key: RemittanceTypes.OUT, label: 'Списание' },
                ]}
              />
            </Box>

            <BankAccountFormMixin
              amount={amount}
              description={description}
              dispatchAmount={setAmount}
              dispatchDescription={setDescription}
              error={error}
              errorWatcher={errorWatcher}
              isLoading={isLoading}
              send={send}
              setOpenPopup={setOpenPopup}
              selectedBalanceType={selectedBalanceType}
              selectedType={selectedType}
              expDate={expDate}
              dispatchExpDate={setExpDate}
            />
          </>
        </BankingOperationsPopup>
      </FormCard>
    </Grid>
  );
};

export default UserBankAccountsShow;
