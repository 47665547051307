import { Dispatch, SetStateAction, useEffect } from 'react';
import { ObjectHelper } from '../../helpers/ObjectHelper';
import { isArray, isEmpty } from 'lodash';
import { useUrlManager } from '../useUrlManager';
import { useSearchParams } from 'react-router-dom';
import { StringHelper } from '../../helpers/StringHelper';
import { IFilters } from '../../store/rtk/reducers/serverPaginationSlice';
import { useActions, useAppSelector } from '../hooks';

export type TFilter = 'eq' | 'like' | 'from' | 'to' | 'in' | 'eqArray' | 'inArray';

export interface IFiltersResponse<T, F> {
  apply: (items: T[]) => { items: T[]; params: {} };
  count: number;
  getCount: () => number;
  setCount: (number: number) => void;
  filters: F;
  addFilter: (filter: IFilter) => void;
  setFilters: Dispatch<SetStateAction<F | null>>;
  getFilterKeys: () => string[];
  getFilterParamsByDefault: () => { [key: string]: any };
  resetFilters: () => void;
}

export interface IFilter {
  type: TFilter;
  name: string;
  value: any;
}

export const useFilters = <T, F extends IFilters = null>(): IFiltersResponse<T, F> => {
  const { filters, method, defaultFilters, filtersCount } = useAppSelector(state => state.serverConfig);
  const { goByParams, getParamByKey, deleteParamsFromUrl, hasParamByKey, prepareForRequest } = useUrlManager();
  const { setFilters, setDefaultFilters, setFiltersCount } = useActions();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (filters && defaultFilters === null) {
      setDefaultFilters(filters as F);
    }
  }, [filters]);

  useEffect(() => {
    if (filters) {
      syncWithUrl();
    }
  }, [defaultFilters]);

  function syncWithUrl(): void {
    if (!searchParams.toString()) {
      return;
    }

    if (!filters) {
      return;
    }

    const filterClone = structuredClone(filters);

    for (const filterType in filterClone) {
      for (const filterField in filterClone[filterType]) {
        const key = getFilterUrlKeyByType(filterType, filterField);
        const urlFilterValue = getParamByKey(key);

        if (urlFilterValue !== null) {
          filterClone[filterType][filterField] = key.includes('List')
            ? urlFilterValue.split(',').map(item => parseInt(item))
            : urlFilterValue;
        }
      }
    }

    setFilters(filterClone as F);
    setFiltersCount(getCount(filterClone));
  }

  function getFilterUrlKeyByType(filterType: string, filterField: string): string {
    let urlKey = filterField;

    switch (filterType) {
      case 'from':
        urlKey += 'Start';
        break;
      case 'to':
        urlKey += 'End';
        break;
      case 'eqArray':
      case 'inArray':
        urlKey += 'List';
        break;
    }

    return urlKey;
  }

  /**
   * Get filter value
   *
   * @param item
   * @param filterKey
   */
  const getValue = (item, filterKey) => {
    if (!filters.comparison) {
      return item[filterKey];
    }

    if (filterKey in filters.comparison) {
      return ObjectHelper.searchValueByDotString(item, filters.comparison[filterKey]);
    }

    return item[filterKey];
  };

  /**
   * Get filters count
   */
  const getCount = (params = null): number => {
    let count = 0;
    const fields = [];

    const toLoop = params ?? filters;

    for (const filter in toLoop) {
      if (filter === 'comparison') {
        continue;
      }

      const fieldFilters = toLoop[filter];

      for (const fieldFilter in fieldFilters) {
        const value = fieldFilters[fieldFilter];

        if (isArray(value) && value.length > 0) {
          fields.push(fieldFilter);
          count++;
        } else if (value !== null && value !== '' && !fields.includes(fieldFilter) && !isArray(value)) {
          fields.push(fieldFilter);
          count++;
        }
      }
    }

    return count;
  };

  /**
   * Apply filters
   */
  const apply = (itemsToFilter: []): { items: T[]; params: {} } => {
    let result = [...itemsToFilter];
    const params = {};

    for (const filterType in filters) {
      const filterFields = filters[filterType];

      switch (filterType as TFilter) {
        case 'eq':
          for (const field in filterFields) {
            const filterValue = filterFields[field];

            if (filterValue !== null && filterValue !== '') {
              params[field as string] = filterValue;

              if (!method) {
                result = result.filter(item => {
                  return String(getValue(item, field)) === String(filterValue).trim();
                });
              }
            }
          }

          break;

        case 'like':
          for (const field in filterFields) {
            const filterValue = filterFields[field];

            if (filterValue !== null && filterValue !== '') {
              params[field as string] = filterValue;

              if (!method) {
                result = result.filter(item => {
                  if (new RegExp((filterValue as string).trim(), 'ig').test(getValue(item, field))) {
                    return item;
                  }

                  return false;
                });
              }
            }
          }

          break;

        case 'from':
          for (const field in filterFields) {
            const filterValue = filterFields[field];

            if (filterValue !== null && filterValue !== '') {
              params[field + '-start'] = filterValue;

              if (!method) {
                result = result.filter((item, index) => {
                  if (getValue(item, field) === null) {
                    return false;
                  }

                  return Number(field === 'index' ? index : getValue(item, field)) >= Number(filterValue);
                });
              }
            }
          }

          break;

        case 'to':
          for (const field in filterFields) {
            const filterValue = filterFields[field];

            if (filterValue !== null && filterValue !== '') {
              params[field + '-end'] = filterValue;

              if (!method) {
                result = result.filter((item, index) => {
                  if (getValue(item, field) === null) {
                    return false;
                  }

                  return Number(field === 'index' ? index : getValue(item, field) <= Number(filterValue));
                });
              }
            }
          }

          break;

        case 'in':
          for (const field in filterFields) {
            const filterValue = filterFields[field];

            if (filterValue !== null && filterValue !== '') {
              params[field as string] = filterValue;

              if (!method) {
                result = result.filter(item => {
                  const arrayList = item[field] as [];

                  if (Array.isArray(arrayList)) {
                    return arrayList.some(listItem => String(listItem) === String(filterValue).trim());
                  }

                  return false;
                });
              }
            }
          }

          break;

        case 'eqArray':
          for (const field in filterFields) {
            const filterValue = filterFields[field];

            if (filterValue !== null && isArray(filterValue) && filterValue.length > 0) {
              params[(field as string) + '-list'] = filterValue;

              if (!method) {
                result = result.filter(item => filterValue.includes(Number(getValue(item, field))));
              }
            }
          }

          break;
        case 'inArray':
          for (const field in filterFields) {
            const filterValue = filterFields[field];

            if (filterValue !== null && isArray(filterValue) && filterValue.length > 0) {
              params[(field as string) + '-list'] = filterValue;

              if (!method) {
                result = result.filter(item => {
                  const arrayList = item[field] as [];

                  if (Array.isArray(arrayList)) {
                    return filterValue.some((filterItem: never) => arrayList.includes(filterItem));
                  }

                  return false;
                });
              }
            }
          }

          break;
      }
    }

    if (isEmpty(params)) {
      deleteParamsFromUrl(getFilterKeys());
    } else {
      goByParams(params);
    }

    return {
      items: result,
      params: !isEmpty(params) ? prepareForRequest(params) : null,
    };
  };

  function getFilterKeys(): string[] {
    const result = [];

    for (const filterType in filters) {
      for (const field in filters[filterType]) {
        result.push(getFilterUrlKeyByType(filterType, field));
      }
    }

    return result;
  }

  const getFilterParamsByDefault = (): { [key: string]: any } => {
    const result: { [key: string]: any } = {};

    for (const filterType in defaultFilters) {
      // @ts-ignore
      for (const field in filters[filterType]) {
        const filterKey = getFilterUrlKeyByType(filterType, field);

        if (hasParamByKey(filterKey)) {
          result[StringHelper.fromCamelCaseToSnakeCase(filterKey)] = getParamByKey(filterKey);
        }
      }
    }

    return result;
  };

  /**
   * Add new filter
   *
   * @param {IFilter} filter
   */
  const addFilter = (filter: IFilter): void => {
    if (filters === null) {
      throw new Error('Filters not found. Need initialize default state');
    }

    setFilters({
      ...filters,
      [filter.type]: {
        ...filters[filter.type],
        [filter.name]: filter.value,
      },
    });
  };

  function resetFilters() {
    deleteParamsFromUrl(getFilterKeys());
    setFilters(defaultFilters);
    setFiltersCount(0);
  }

  return {
    count: filtersCount,
    getCount,
    setCount: setFiltersCount,
    apply,
    filters: filters as F,
    addFilter,
    setFilters,
    getFilterKeys,
    getFilterParamsByDefault,
    resetFilters,
  };
};
