import {defer} from 'react-router-dom';
import {CommunicationService} from './CommunicationService';
import {ITemplate} from '../../types/app/communication/Template';
import {IAntispamTemplate} from '../../types/app/communication/AntispamTemplate';
import {IGroupsTemplate} from '../../types/app/communication/GroupsTemplate';
import {IProfile} from '../../types/app/communication/Profile';
import {INews} from '../../types/app/communication/News';
import {IQuestion} from '../../types/app/communication/IQuestion';
import {MessageNotFound} from './CommunicationErrors';

export interface IGetTemplateLoader {
  template: ITemplate;
}

export interface ICreateNewsLoader {
  groups: IGroupsTemplate[];
  profiles: IProfile[];
}

export interface IShowNewsLoader extends ICreateNewsLoader {
  newsItem: INews,
}

export interface IGetDataForNewAntispamRuleLoader {
  groups: IGroupsTemplate[];
  periodicityTypes: string[];
}

export interface IGetAntispamLoader {
  template: IAntispamTemplate;
  groups: IGroupsTemplate[];
  periodicityTypes: string[];
}

export interface IGetQuestionAnswerLoader {
  question: IQuestion,
}

export class CommunicationLoaders {
  public static async getTemplateLoader({ params }) {
    return defer({
      template: CommunicationService.getTemplate({ id: params.id }),
    });
  }

  public static async createNewsLoader() {
    return defer({
      content: Promise.all([
        CommunicationService.getAllGroups(),
        CommunicationService.getProfiles(),
      ]).then(
        data =>
          ({
            groups: data[0],
            profiles: data[1],
          } as ICreateNewsLoader)
      ),
    });
  }

  public static async showNewsLoader({ params }) {
    return defer({
      content: Promise.all([
        CommunicationService.getAllGroups(),
        CommunicationService.getProfiles(),
        CommunicationService.getNews({id: +params.id})
      ]).then(
        data =>
          ({
            groups: data[0],
            profiles: data[1],
            newsItem: data[2][0],
          } as IShowNewsLoader)
      ),
    });
  }

  public static async getAntispamLoader({ params }) {
    return defer({
      content: Promise.all([
        CommunicationService.getAntispamRule({ id: params.id }),
        CommunicationService.getAllGroups(),
        CommunicationService.getAntispamRuleGetPeriodicityTypes(),
      ]).then(
        data =>
          ({
            template: data[0],
            groups: data[1],
            periodicityTypes: data[2],
          } as IGetAntispamLoader)
      ),
    });
  }

  public static async getDataForNewAntispamRuleLoader() {
    return defer({
      content: Promise.all([
        CommunicationService.getAllGroups(),
        CommunicationService.getAntispamRuleGetPeriodicityTypes(),
      ]).then(
        data =>
          ({
            groups: data[0],
            periodicityTypes: data[1],
          } as IGetDataForNewAntispamRuleLoader)
      ),
    });
  }

  public static getQuestionAnswerLoader({ params }) {
    return defer({
      question: new Promise((res, rej) => {
        CommunicationService.getQuestion({ id: params.id })
          .then(questions => {
            const question = questions.find(quest => +quest.id === +params.id);
            
            if (!question) {
              rej(new MessageNotFound());
            }
          
            res(question);
          });
      }),
    })
  }
}
