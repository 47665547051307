import React from 'react';
import { Box, Grid } from '@mui/material';
import FilterGroupButtons from '../../../UI/Button/FilterGroupButtons';
import PerPage from '../../../UI/Table/PerPage';
import { IFilterable } from '../../../types/app/common/Filterable';

const OperationHistoryTabDataTweaks = (props: IFilterable) => {
  return (
    <Box mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>

        <Grid item>
          <PerPage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OperationHistoryTabDataTweaks;
