import {
  InternalError,
  RoleCannotBeDeleted,
  RoleIsAlreadyExists,
  UserIsAlreadyExists,
  UserNotFoundError,
  UserWasNotRemoved,
  ValidationErrors,
  EmailOrPasswordIsWrongError,
} from './AuthErrors';
import {AuthFactory} from './AuthFactory';
import {CookieRepository} from '../../repositories/CookieRepository';
import {HttpCodes} from '../../enums/HttpCodes';
import {JsonRpcService} from '../JsonRpcService';
import {IResponse} from '../../types/response/common/Response';
import {IUpdateUserRequest} from '../../types/request/auth/UpdateUserRequest';
import {UserResponse} from '../../types/response/auth/UserResponse';
import {TUpdatedUser} from '../../types/app/auth/User';
import {ICreateUserRequest} from '../../types/request/auth/CreateUserRequest';
import {IGetUserByEmailRequest} from '../../types/request/auth/GetUserByEmailRequest';
import {IGetUserByEmailResponse} from '../../types/response/auth/GetUserByEmailResponse';
import {IUserListItem, IUserListItemPaginated} from '../../types/app/auth/UserListItem';
import {TGetUserListData, TGetUserListDataPaginated} from '../../types/response/auth/GetUserListData';
import {IRole} from '../../types/app/auth/Role';
import {IGetRoleRequest} from '../../types/request/auth/GetRoleRequest';
import {ICredentials} from '../../types/app/auth/Credentials';
import {IAuthResponse} from '../../types/response/auth/AuthResponse';
import {IGetRoleResponse, TRole, TUpdateRoleResponse} from '../../types/response/auth/GetRoleResponse';
import {IGetRolesResponse} from '../../types/response/auth/GetAllRolesResponse';
import {IRegistrationRequest} from '../../types/request/auth/RegistrationRequest';
import {UserTypes, UserTypeValues} from '../../enums/UserTypes';
import {IGetSiteSectionsData} from '../../types/response/auth/GetSiteSectionsData';
import {ISiteSection} from '../../types/app/auth/SiteSection';
import {SiteTypes} from '../../enums/SiteTypes';
import {IGetSiteSectionsRequest} from '../../types/request/auth/GetSiteSectionsRequest';
import {ISetSiteSectionsForRole} from '../../types/request/auth/SetSiteSectionsForRoleRequest';
import {ICreateRoleRequest} from '../../types/request/auth/CreateRoleRequest';
import {IAddRoleResponse} from '../../types/response/auth/AddRoleResponse';
import {IDeleteRoleRequest} from '../../types/request/auth/DeleteRoleRequest';
import {IUpdateRoleRequest} from '../../types/request/auth/UpdateRoleRequest';
import {ILoggedUser} from '../../types/app/auth/LoggedUser';
import {IAuthUser} from '../../types/app/auth/AuthUser';
import {AccountService} from '../account/AccountService';
import {CommunicationService} from '../communication/CommunicationService';
import {RouteList} from '../RouteList';
import {IChangePasswordRequest} from '../../types/request/auth/ChangePasswordRequest';
import {ICheckSiteSection} from '../../types/request/auth/CheckSiteSection';
import {ICreateUserResponse} from '../../types/response/auth/CreateUserResponse';
import {INotification} from '../../types/app/common/Notification';
import {IPaginatedResponse} from '../../types/response/common/PaginatedResponse';
import {IPaginated} from '../../types/app/common/Paginated';
import {IGetUsersRequest} from '../../types/request/auth/GetUsersRequest';

/**
 * Auth user service
 */
export class AuthService {
  private static serviceName: string = 'auth';

  public static async deleteUser(request: { id: number }): Promise<void> {
    const response = await JsonRpcService.request<IResponse<[]>>(AuthService.serviceName, 'remove_user', request);

    if (!response.result.status) {
      throw new UserWasNotRemoved();
    }
  }

  public static async updateUser(request: IUpdateUserRequest): Promise<TUpdatedUser> {
    const response = await JsonRpcService.request<UserResponse>(AuthService.serviceName, 'update_user', request);

    if (response.error) {
      if (response.error.message === 'Invalid params') {
        throw new ValidationErrors();
      }

      throw new InternalError();
    }

    return AuthFactory.getCreatedUserFromResponse(response.result.user);
  }

  public static async createUser(request: ICreateUserRequest): Promise<IUserListItem> {
    const response = await JsonRpcService.request<ICreateUserResponse>(AuthService.serviceName, 'create_user', request);

    if (response.error) {
      if (response.error.message === 'Invalid params') {
        throw new ValidationErrors();
      }

      if (response.error.data?.error === 'User is exist') {
        throw new UserIsAlreadyExists();
      }

      throw new InternalError();
    }

    return AuthFactory.getAccountUsersFromResponse(response.result.user);
  }

  public static async getUserByEmail(request: IGetUserByEmailRequest, isCurrentUser?: boolean): Promise<IAuthUser | ILoggedUser> {
    const response = await JsonRpcService.request<IGetUserByEmailResponse>(AuthService.serviceName, 'get_user_by_email', request);

    if (response.error && response.error.code === HttpCodes.NOT_FOUND) {
      throw new UserNotFoundError();
    }

    const loggedUser = {
      id: response.result.user.id,
      name: response.result.user.name,
      email: response.result.user.email,
      internalId: response.result.user.internal_id,
      type: response.result.user.type,
      roleId: response.result.user.role_id,
    };

    if (!isCurrentUser) {
      return loggedUser;
    }

    return await this.toAuth(loggedUser);
  }

  public static async getUsers(request?: { type: UserTypes }): Promise<IUserListItem[]> {
    const response = await JsonRpcService.request<IResponse<TGetUserListData[]>>(this.serviceName, 'get_users_list', request);

    return response.result.data.map(user => AuthFactory.getAccountUsersFromResponse(user));
  }

  public static async getUsersPaginated(request?: IGetUsersRequest): Promise<IPaginated<IUserListItemPaginated[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TGetUserListDataPaginated[]>>(
      AuthService.serviceName,
      'get_users_filtered_list',
      request
    );

    const items = response.result.data.items.map(item => AuthFactory.getAccountUsersFromPaginatedResponse(item))

    return { items, ...response.result.data.pagination };
  }

  public static async forgotPassword(request: { email: string }): Promise<IResponse> {

    return await JsonRpcService.request<IResponse>(this.serviceName, 'forgot_password', request);
  }

  public static async changePassword(request: IChangePasswordRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<null>>(
      this.serviceName,
      'reset_password',
      request,
    );

    if (response.error) {
      throw new InternalError();
    }

    return response.result.status;
  }

  public static async changeUserName(request: { name: string, id: string | number }): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<null>>(
      this.serviceName,
      'change_user_name',
      request,
    );

    if (response.error) {
      throw new InternalError();
    }

    return response.result.status;
  }

  public static async getOperatorRoles(): Promise<IRole[]> {
    const roles = await this.getRoles();

    return roles
      .filter(role => role.types.includes(UserTypeValues.OPERATOR))
      .map(role => {
        role.sections = role.sections.filter(section => section.site === SiteTypes.OPERATOR);

        return role;
      });
  }

  public static async getPartnerRoles(): Promise<IRole[]> {
    const roles = await this.getRoles();

    return roles
      .filter(role => role.types.includes(UserTypeValues.PARTNER))
      .map(role => {
        role.sections = role.sections.filter(section => section.site === SiteTypes.PARTNER);

        return role;
      });
  }

  public static async getRoles(): Promise<IRole[]> {
    const response = await JsonRpcService.request<IGetRolesResponse>(this.serviceName, 'get_all_roles');

    const result: IRole[] = [];
    const roles = response.result.roles;

    const sections = await this.getSiteSections();

    for (const role in roles) {
      result.push(AuthFactory.getRoleFromResponse(roles[role] as TRole, role));
    }

    AuthFactory.fillRolesBySections(result, sections);

    return result;
  }

  public static async deleteRole(request: IDeleteRoleRequest): Promise<void> {
    const response = await JsonRpcService.request<IResponse<null>>(this.serviceName, 'remove_role', request);

    if (response.error) {
      throw new RoleCannotBeDeleted();
    }
  }

  public static async getSiteSections(request?: IGetSiteSectionsRequest): Promise<ISiteSection[]> {
    const response = await JsonRpcService.request<IResponse<IGetSiteSectionsData[]>>(
      this.serviceName,
      'get_site_sections',
      request
    );

    return response.result.data.map(res => AuthFactory.getSectionFromResponse(res));
  }

  public static async setSiteSectionsByRole(request: ISetSiteSectionsForRole): Promise<void> {
    const response = await JsonRpcService.request<IResponse>(this.serviceName, 'set_site_sections_for_role', request);

    if (response.error) {
      throw new InternalError();
    }
  }

  public static async createRole(request: ICreateRoleRequest): Promise<number> {
    const response = await JsonRpcService.request<IAddRoleResponse>(this.serviceName, 'add_role', request);

    if (response.error) {
      throw new RoleIsAlreadyExists();
    }

    return response.result.role_id;
  }

  public static async updateRole(request: IUpdateRoleRequest): Promise<void> {
    const response = await JsonRpcService.request<TUpdateRoleResponse>(this.serviceName, 'update_role', request);

    if (response.error) {
      throw new RoleIsAlreadyExists();
    }
  }

  public static async getRole(request: IGetRoleRequest): Promise<IRole> {
    const response = await JsonRpcService.request<IGetRoleResponse>(this.serviceName, 'get_role', request);

    const sections = await this.getSiteSections();
    const role = AuthFactory.getRoleFromResponse(response.result.role, response.result.role.name);

    AuthFactory.fillRolesBySections([role], sections);

    return role;
  }

  public static async login(credentials?: ICredentials): Promise<ILoggedUser | null> {
    let method;
    let params;

    if (credentials) {
      method = 'login_operator';
      params = credentials;
    } else {
      const token = CookieRepository.getToken();

      if (!token) {
        return null;
      }

      method = 'refresh';
      params = {};
    }

    const response = await JsonRpcService.request<IAuthResponse>(AuthService.serviceName, method, params);

    if (!response) {
      throw new InternalError();
    }

    if (response.error) {
      if (response.error.code === HttpCodes.UNAUTHORIZED) {
        throw new EmailOrPasswordIsWrongError();
      }

      throw new InternalError();
    }

    if (!response.result.status) {
      CookieRepository.removeToken();

      throw new EmailOrPasswordIsWrongError();
    }

    const token = response.result.auth.token;

    CookieRepository.setToken(token);

    return {
      id: response.result.user.internal_id,
      name: response.result.user.name,
      roleId: response.result.user.role_id,
      type: response.result.user.type,
      email: response.result.user.email,
      internalId: response.result.user.internal_id,
    };
  }

  public static async registration(registrationRequest: IRegistrationRequest): Promise<ILoggedUser> {
    const response = await JsonRpcService.request<IAuthResponse>(this.serviceName, 'registration', registrationRequest);

    if (response.error) {
      if (response.error.code === HttpCodes.NOT_IMPLEMENTED) {
        throw new UserIsAlreadyExists();
      }

      if (response.error.data) {
        throw new ValidationErrors();
      }

      throw new InternalError();
    }

    const user = response.result.user;

    return {
      id: user.id,
      name: user.name,
      email: user.email,
      internalId: user.internal_id,
      type: user.type,
      roleId: user.role_id,
    };
  }

  private static cachedNotification: INotification | null = null;
  public static async toAuth(user: ILoggedUser): Promise<IAuthUser> {

    const role = await AuthService.getRole({ id_or_name: user.roleId.toString() });

    role.sections = role.sections.filter(section => section.site === SiteTypes.OPERATOR);

    const isBalanceAvailable = role.sections.some(section => section.sectionName === RouteList.BILLING);
    const isNotificationAvailable = role.sections.some(section => section.sectionName === RouteList.NOTIFICATIONS);

    let balances = null;
    let notification = null;

    if (isBalanceAvailable) {
      balances = await AccountService.getBalances();
    }

    // Проверяем наличие кэшированного результата запроса
    if (isNotificationAvailable && !AuthService.cachedNotification) {
      AuthService.cachedNotification = await CommunicationService.webNotificationsGetLast();
    }

    // Используем кэшированный результат или null
    notification = AuthService.cachedNotification || null;

    return {
      ...user,
      balances,
      notification,
      role,
    };
  }

  public static async checkSiteSection(request: ICheckSiteSection): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<null>>(
      this.serviceName,
      'check_site_sections',
      request ,
    );

    if (response.error) {
      throw new InternalError();
    }

    return response.result.status;
  }
}
