import React from 'react';
import { IPaginatable } from '../../../types/app/common/Paginatable';
import { IRuleSetting, IRuleSettingCondition } from '../../../types/app/fraud/RuleSetting';
import TableContainer from '../../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box, Typography } from '@mui/material';
import ShowButton from '../../../UI/Button/ShowButton';
import {RouteList} from '../../../http/RouteList';
import {FraudSettingConditionNameEnum, FraudSettingConditionNames} from '../../../enums/FraudRules';
import {IPartner} from '../../../types/app/partner/Partner';
import EmptyCell from '../../../UI/Table/EmptyCell';
import {getRuleName} from '../../../utils/fraudUtils';

interface IRuleSettingsTabContentDataProps extends IPaginatable<IRuleSetting>{
  partnerMap: Map<number, IPartner>,
}

const RuleSettingsTabContentData = (props: IRuleSettingsTabContentDataProps) => {
  const getConditionName = (ruleSettingCondition: IRuleSettingCondition, box = true) => {
    return (
      <>
        {FraudSettingConditionNames.toView(ruleSettingCondition.ruleCondition.name as FraudSettingConditionNameEnum)}
        <br />
        {box && <Box mt={3} mb={3} />}
      </>
    );
  };

  const getConditionVal = (ruleSettingCondition: IRuleSettingCondition, box = true) => {
    return (
      <>
        {ruleSettingCondition.value}
        <br />
        {box && <Box mt={3} mb={3} />}
      </>
    );
  };

  return (
    <TableContainer boxprops={{ style: {top: '-16px'}}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={120}> Наименование правила </TableCell>
            <TableCell width={350}> Наименование условий </TableCell>
            <TableCell width={100}> Значений условий </TableCell>
            <TableCell width={150}> Партнер </TableCell>
            <TableCell width={150}> Статус </TableCell>
            <TableCell width={30}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(setting => (
            <TableRow key={setting.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '100%' }}>
              <TableCell>{getRuleName(setting.rule.name)}</TableCell>
              <TableCell valign="top">
                {setting.ruleSettingConditions.map((item, index) =>
                  (<Box key={item.id}>{getConditionName(item, index + 1 !== setting.ruleSettingConditions.length)}</Box>)
                )}
              </TableCell>
              <TableCell valign="top">
                {setting.ruleSettingConditions.map((item, index) =>
                  (<Box key={item.id}>{getConditionVal(item, index + 1 !== setting.ruleSettingConditions.length)}</Box>)
                )}
              </TableCell>

              <TableCell>
                {props.partnerMap.get(setting.partnerId)?.label ?? <EmptyCell />}
              </TableCell>

              <TableCell>
                <Typography color={setting.isActive ? 'success.main'  : 'error' }>
                  {setting.isActive ? 'Активный'  : 'Неактивный' }
                </Typography>
              </TableCell>

              <TableCell>
                <ShowButton linkProps={{ to: RouteList.FRAUD_MONITORING_RULE_SETTING_SHOW + '/' + setting.id, state: setting }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RuleSettingsTabContentData;
