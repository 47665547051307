import React from 'react';
import {IDialogFilterable} from '../../types/app/common/DialogFilterable';
import FilterDialog from '../Common/Filter/FilterDialog';
import FilterInputRange from '../Common/Filter/FilterInputRange';
import {defaultFilterState} from './CertificateTabContentMain';
import Divider from '../../UI/Delimiter/Divider';
import FilterDateRange from '../../UI/Filter/FilterDateRange';
import {CertificateTypeCodes} from '../../enums/CertificateTypes';
import FilterInput from '../Common/Filter/FilterInput';
import FilterSelect from '../Common/Filter/FilterSelect';
import {CertificateStatus, CertificateStatusCodes} from '../../enums/CertificateStatuses';

const CertificateTabFilters = (props: IDialogFilterable<typeof defaultFilterState>) => {
  const currentDate = new Date().toLocaleDateString();

  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>

      <FilterDateRange
        label="Срок годности"
        addFilter={props.addFilter}
        field="expires_date"
        filters={props.filters}
        placeholderFrom={currentDate}
        placeholderTo={currentDate}
      />

      <Divider />

      <FilterInputRange
        addFilter={props.addFilter}
        field="amount"
        filters={props.filters}
        label="Номинал"
        placeholderFrom="1"
        placeholderTo="100"
      />

      <Divider />

      <FilterSelect
        label="Вид"
        filters={props.filters}
        addFilter={props.addFilter}
        items={CertificateTypeCodes.asSelectable()}
        field="type"
        placeholder="Укажите вид сертификата"
      />

      <Divider/>

      <FilterSelect
        label="Статус"
        filters={props.filters}
        addFilter={props.addFilter}
        items={CertificateStatus.asSelectable()}
        field="status"
        placeholder="Укажите статус сертификата"
      />

    </FilterDialog>
  );
};

export default CertificateTabFilters;