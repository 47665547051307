import React, {useContext} from 'react';
import {Box, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {FormContext} from '../../store/context/form/FormContext';

const FormCloseButton = () => {
  const {setConfig, config} = useContext(FormContext);

  const readOnlyHandler = () => {
    setConfig({...config, readonly: true});
  }

  return (
    <Box position="absolute" zIndex={100} right={15} top={15}>
      <IconButton onClick={readOnlyHandler}>
        <CloseIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </Box>
  );
};

export default FormCloseButton;