import React from 'react';
import {Box, Button, Grid, Pagination, Typography} from '@mui/material';
import Image from 'mui-image';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {unix} from 'moment/moment';
import {ILastNotice} from '../../../types/app/common/Notification';

interface NotificationGridProps {
  notices: ILastNotice[];
  page: number;
  count: number;
  setPage: (page: number) => void;
  show: React.Dispatch<React.SetStateAction<ILastNotice>>;
}

const NotificationsContentData = (props: NotificationGridProps) => {
  if (props.notices.length === 0) {
    return (<Box sx={{minHeight: 545, height: '100%'}}>
      <Grid
        container
        columns={12}
        mt={25}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item mt="5px" mr="5px">
          <ErrorOutlineIcon color="error"/>
        </Grid>
        <Grid item>
          <Typography variant="h4">
            Уведомлений не найдено
          </Typography>
        </Grid>
      </Grid>
    </Box>);
  }

  return (
    <Box>
      {props.notices.map((lastNotice, index) =>
        <Box key={lastNotice.id}>
          <Button
            onClick={() => props.show(lastNotice)}
            fullWidth
            variant="text"
            sx={{
              borderBottom: props.notices.length - 1 !== index ? '1px solid #CED2D6' : 'none',
              borderRadius: 0,
            }}
          >
            <Grid
              container
              padding={3}
              alignItems="center"
              justifyContent="space-around"
              columns={12}
              gap={1}
              sx={{
                opacity: lastNotice.read ? .5 : 1,
              }}
            >
              <Grid item xs={1}>
                <Image duration={0} width={22} height={22} src={lastNotice.data.icon}/>
              </Grid>
              <Grid item xs={3}>
                <Typography textAlign="center" color="grey.600">
                  {unix(lastNotice.createdAt).format('DD.MM.YY HH:mm')}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography textAlign="left" color="grey.A700">
                  {lastNotice.data.short}
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </Box>
      )}

      <Box
        mt={3}
        mb={5}
        display="flex"
        justifyContent="center"
      >
        {props.count > 1 && (
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            onChange={(event, page) => props.setPage(page)}
            page={props.page}
            count={props.count}
          />
        )}
      </Box>
    </Box>
  );
};

export default NotificationsContentData;