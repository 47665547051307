import {FormDataHelper} from '../../helpers/FormDataHelper';
import {IFormResponse} from '../../store/context/form/types/FormResponse';
import {DeliveryTypes} from '../../enums/DeliveryTypes';
import {IStorageFileData} from '../../store/rtk/reducers/storageSlice';
import {CatalogService} from './CatalogService';
import {IUpdateCategoryRequest} from '../../types/request/catalog/UpdateCategoryRequest';
import {ICreateCategoryRequest} from '../../types/request/catalog/CreateCategoryRequest';
import {ShopService} from '../shop/ShopService';
import {getProductDescriptionByStatusId, ProductStatuses} from '../../enums/ProductStatuses';
import {FileHelper} from "../../helpers/FileHelper";
import {ICreateProductRequest} from "../../types/request/catalog/CreateProductRequest";

export class CatalogActions {
  public static updateProductAction = async ({request, params}): Promise<IFormResponse> => {
    const {id} = params;
    const formData: FormData = await request.formData();
    const updateRequest = FormDataHelper.toObject<{
      product_id: number;
      category_id: number;
      name: string;
      description?: string;
      short_description?: string;
      price: number;
      is_recurrent?: 'on' | 'off' | boolean;
      recurrent_period?: number;
      by_certificate?: boolean;
      file_name?: string;
      file_data?: string;
      mobile_image_name?: string;
      mobile_image_data?: string;
      delivery_type: DeliveryTypes;
      file?: string;
      mobile_image_file?: string;
      product_status_id?: number;
      comment?: string
    }>(formData);

    if (updateRequest.file) {
      const encoded = JSON.parse(updateRequest.file) as IStorageFileData[];

      updateRequest.file_data = encoded[0].data.replace(/^data.+,/, '');
      updateRequest.file_name = encoded[0].fileName;
    }

    if (updateRequest.mobile_image_file) {
      const encoded = JSON.parse(updateRequest.mobile_image_file) as IStorageFileData[];

      updateRequest.mobile_image_data = encoded[0].data.replace(/^data.+,/, '');
      updateRequest.mobile_image_name = encoded[0].fileName;
    }

    updateRequest.product_id = Number(id);
    updateRequest.price = Number(updateRequest.price);
    updateRequest.category_id = Number(updateRequest.category_id);
    updateRequest.delivery_type = updateRequest.delivery_type ? updateRequest.delivery_type : DeliveryTypes.UNNECESSARY;
    updateRequest.is_recurrent = Boolean(updateRequest.is_recurrent);
    updateRequest.recurrent_period = Number(updateRequest.recurrent_period);
    updateRequest.product_status_id = Number(updateRequest.product_status_id);
    updateRequest.by_certificate = Boolean(updateRequest.by_certificate);


    await CatalogService.updateProduct(updateRequest);
    await CatalogService.changeProductStatus({
      product_id: params.id,
      product_status_id: Number(updateRequest.product_status_id),
      comment: updateRequest.comment
        ? (`${getProductDescriptionByStatusId(updateRequest.product_status_id)}: ${updateRequest.comment}`)
        : getProductDescriptionByStatusId(updateRequest.product_status_id)
    });
    updateRequest.product_status_id !== ProductStatuses.PUBLISHED && await ShopService.cancelOrdersByProduct(
      {product_id: id});

    return {status: true};
  };

  public static updateCategoryAction = async ({request, params}): Promise<IFormResponse> => {
    const {id} = params;

    const formData: FormData = await request.formData();
    const updateRequest = FormDataHelper.toObject<IUpdateCategoryRequest>(formData);

    updateRequest.category_id = Number(id);

    await CatalogService.updateCategory(updateRequest);

    return {status: true};
  };

  public static createCategoryAction = async ({request}): Promise<IFormResponse> => {
    const formData: FormData = await request.formData();
    const createRequest = FormDataHelper.toObject<ICreateCategoryRequest>(formData);

    const categoryId = await CatalogService.createCategory(createRequest);

    return {
      status: true,
      payload: categoryId,
    };
  };

  public static async createProduct({ request }): Promise<IFormResponse> {
    const formData = FormDataHelper.toObject<{
      category_id: string;
      delivery_type: DeliveryTypes | string;
      description: string;
      file?: string;
      mobile_image_file?: string;
      name: string;
      price: string;
      short_description: string;
      is_recurrent?: string;
      recurrent_period?: string;
      by_certificate: boolean;
    }>(await request.formData());

    const createProductRequest: ICreateProductRequest = {
      category_id: +formData.category_id,
      name: formData.name,
      price: +formData.price,
      delivery_type: formData.delivery_type,
      description: formData.description,
      short_description: formData.short_description,
      is_recurrent: formData.is_recurrent === 'on',
      recurrent_period: +formData.recurrent_period,
    };

    if (formData.by_certificate) {
      createProductRequest.by_certificate = true;
    }

    if (formData.file) {
      const fileData = JSON.parse(formData.file)[0] as IStorageFileData;

      createProductRequest.file_name = fileData.fileName;
      createProductRequest.file_data = FileHelper.base64RequestFormat(fileData.data);
    }

    if (formData.mobile_image_file) {
      const encoded = JSON.parse(formData.mobile_image_file) as IStorageFileData[];

      createProductRequest.mobile_image_data = encoded[0].data.replace(/^data.+,/, '');
      createProductRequest.mobile_image_name = encoded[0].fileName;
    }

    return {
      status: true,
      payload: await CatalogService.createProduct(createProductRequest),
    };
  }
}
