import { JsonRpcService } from '../JsonRpcService';
import { IResponse } from '../../types/response/common/Response';
import { FraudFactory } from './FraudFactory';
import { TRuleReport } from '../../types/response/fraud/GetRuleReportsData';
import { IRuleReport } from '../../types/app/fraud/RuleReport';
import { TRuleSetting } from '../../types/response/fraud/GetRuleSettingsData';
import { IUpdateRuleSettingRequest } from '../../types/request/fraud/UpdateRuleSettingRequest';
import { IGetRuleSettingRequest } from '../../types/request/fraud/GetRuleSettingRequest';
import { IRuleSetting } from '../../types/app/fraud/RuleSetting';
import { TGetRuleData } from '../../types/response/fraud/GetRuleData';
import { IRule } from '../../types/app/fraud/Rule';
import { ICreateRuleSettingRequest } from '../../types/request/fraud/CreateRuleSettingRequest';
import { IPaginated } from '../../types/app/common/Paginated';
import { IPaginatedResponse } from '../../types/response/common/PaginatedResponse';

export class FraudService {
  private static serviceName = 'fraud';

  public static async getRules(): Promise<IRule[]> {
    const response = await JsonRpcService.request<IResponse<TGetRuleData>>(this.serviceName, 'rule_get_list');

    return response.result.data.rules.map(rule => FraudFactory.getRuleFromResponse(rule));
  }

  public static async getFilteredRuleSettings(request): Promise<IPaginated<IRuleSetting[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TRuleSetting[]>>(
      FraudService.serviceName, 
      'rule_setting_get_filtered_list',
      request
    );

    const items = response.result.data.items.map(setting => FraudFactory.getRuleSettingFromResponse(setting));

    return { items, ...response.result.data.pagination };
  }

  public static async createSetting(request: ICreateRuleSettingRequest): Promise<number> {
    const response = await JsonRpcService.request<IResponse<{ rule_setting_id: number }>>(
      this.serviceName,
      'rule_setting_create',
      request
    );

    return response.result.data.rule_setting_id;
  }

  public static async updateSetting(request: IUpdateRuleSettingRequest): Promise<void> {
    await JsonRpcService.request(this.serviceName, 'rule_setting_update', request);
  }

  public static async getFilteredRuleReports(request): Promise<IPaginated<IRuleReport[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TRuleReport[]>>(
      FraudService.serviceName,
      'report_get_filtered_list',
      {
        ...request,
        order_fileld: 'created_at',
        order_type: 'asc'
      }
    )

    const items = response.result.data.items.map(report => FraudFactory.getRuleReportFromResponse(report));

    return { items, ...response.result.data.pagination }
  }

  public static async getRuleSetting(request: IGetRuleSettingRequest): Promise<IRuleSetting> {
    const response = await JsonRpcService.request<IResponse<TRuleSetting>>(this.serviceName, 'rule_setting_show', request);

    return FraudFactory.getRuleSettingFromResponse(response.result.data);
  }
}
