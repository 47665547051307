import React, { useEffect } from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import { IDialogFilterable } from '../../../types/app/common/DialogFilterable';
import Divider from '../../../UI/Delimiter/Divider';
import FilterInput from '../../Common/Filter/FilterInput';
import { defaultFilters } from './UserBankAccountsTabContentMain';
import FilterDateRange from '../../../UI/Filter/FilterDateRange';
import moment from 'moment/moment';
import FilterInputRange from '../../Common/Filter/FilterInputRange';
import { useActions } from '../../../hooks/hooks';

const UserBankAccountsTabFilters = (props: IDialogFilterable<typeof defaultFilters>) => {
  const { setErrorsByField } = useActions();

  useEffect(() => {
    if (props.filters && props.filters.like.searchByEmail !== '' && props.filters.like.searchByEmail.length < 3) {
      setErrorsByField({
        searchByEmail: 'Введите не менее 3 символов',
      });
    } else {
      setErrorsByField(null);
    }
  }, [props.filters]);

  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterInput
        label="Email Пользователя"
        addFilter={props.addFilter}
        field="searchByEmail"
        filters={props.filters}
        placeholder="Введите не менее 3 символов"
      />

      <Divider />

      <FilterInput
        label="Идентификатор Пользователя"
        addFilter={props.addFilter}
        field="byUserId"
        filters={props.filters}
        placeholder="Укажите идентификатор"
        rule={e => !isNaN(Number(e.target.value))}
      />

      <Divider />

      <FilterDateRange
        addFilter={props.addFilter}
        field="createdAt"
        filters={props.filters}
        label="Дата регистрации"
        placeholderTo={moment().add(1, 'd').format('DD.MM.YYYY')}
        placeholderFrom={moment().format('DD.MM.YYYY')}
      />

      <Divider />

      <FilterInputRange
        addFilter={props.addFilter}
        field="points"
        filters={props.filters}
        label="Баланс балльного счета"
        placeholderFrom="0"
        placeholderTo="300 000"
      />

      <Divider />

      <FilterInputRange
        addFilter={props.addFilter}
        field="pointsBlock"
        filters={props.filters}
        label="Баланс балльного блокировочного счета"
        placeholderFrom="0"
        placeholderTo="300 000"
      />
    </FilterDialog>
  );
};

export default UserBankAccountsTabFilters;
