import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PerPage from '../../../../UI/Table/PerPage';
import FilterGroupButtons from '../../../../UI/Button/FilterGroupButtons';
import { IFilterable } from '../../../../types/app/common/Filterable';
import { RouteList } from '../../../../http/RouteList';
import { UserTypes } from '../../../../enums/UserTypes';

interface IUsersTabDataTweaks extends IFilterable {
  type: UserTypes.PARTNER | UserTypes.OPERATOR;
}

const AccountTabDataTweaks = (props: IUsersTabDataTweaks) => {
  const route = props.type === UserTypes.OPERATOR ? RouteList.USERS_CREATE : RouteList.PARTNERS_CREATE;

  return (
    <Box mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Link to={route} state={{ type: props.type }}>
            <Button endIcon={<AddCircleOutlineIcon />}>Добавить сотрудника</Button>
          </Link>
        </Grid>

        <Grid item>
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>

        <Grid item>
          <PerPage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountTabDataTweaks;
