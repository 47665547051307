import React, {useContext, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import {useAsyncValue, useNavigate} from 'react-router-dom';
import {RouteList} from '../../../http/RouteList';
import MainForm from '../../../Widgets/Common/Form/MainForm';
import {IGetDataForNewProductLoader} from '../../../http/catalog/CatalogLoaders';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import Divider from '../../../UI/Delimiter/Divider';
import {Grid, Typography} from '@mui/material';
import FormSelect from '../../../UI/Form/Select/FormSelect';
import FormImage from '../../../UI/Form/FormImage';
import FormSwitch from '../../../UI/Form/FormSwitch';
import FormActions from '../../../UI/Form/FormActions';
import {FormContext} from '../../../store/context/form/FormContext';
import {snackConfig} from '../../../layout/assets/config/snackConfig';
import {useActions} from '../../../hooks/hooks';

const CatalogProductCreate = () => {
  const [isRecurrent, setIsRecurrent] = useState<boolean>(false);
  const { setFiles } = useActions();
  const { setConfig, softReset } = useContext(FormContext);
  const link = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formConfig = {
    action: RouteList.CATALOG_CREATE,
    rules: {
      name: ['required'],
      price: ['required', 'digits'],
      delivery_type: ['required'],
      description: ['max:1500'],
      short_description: ['max:1000'],
    },
    afterSubmitHandler: (_, config, setConfig) => {
      setFiles([]);
      enqueueSnackbar('Товар добавлен.', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      setConfig({ ...config, readonly: false });

      link(RouteList.CATALOG);
    },
  };

  const { categories, partners, deliveryTypes } = useAsyncValue() as IGetDataForNewProductLoader;

  useEffect(() => {
    setFiles([]);
  }, []);

  useEffect(() => {
    const rules = { ...formConfig.rules };

    if (isRecurrent) {
      rules['recurrent_period'] = ['required', 'min:1'];
    } else {
      delete rules['recurrent_period'];
    }

    formConfig.rules = rules;
    setConfig(formConfig);
    softReset();
  }, [isRecurrent]);

  const ruleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);

    if (isNaN(value)) {
      return false;
    }

    return value >= 1;
  };

  return (
    <MainForm back={RouteList.CATALOG}>
      <Typography variant="h1" mb={5}>
        Добавление товара/услуги
      </Typography>

      <FormOutlinedInput
        title="Наименование товара/услуги"
        typography="h2"
        inputProps={{
          name: 'name',
        }}
      />

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormOutlinedInput
            title="Стоимость товара/услуги"
            inputProps={{
              name: 'price',
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormSelect
            items={categories}
            title="Категория товара/услуги"
            selectProps={{ name: 'category_id', defaultValue: categories[0].key }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormSelect
            items={deliveryTypes}
            title="Вид доставки"
            selectProps={{ name: 'delivery_type', defaultValue: deliveryTypes[0].key }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormOutlinedInput
            title="Полное описание"
            inputProps={{
              name: 'description',
              multiline: true,
              minRows: 8,
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormOutlinedInput
            title="Короткое описание"
            inputProps={{
              name: 'short_description',
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container flexDirection="row" alignItems="center">
        <Grid item xs={7}>
          <FormSwitch
            title="Возможность подключения автоплатежа"
            switchProps={{
              name: 'is_recurrent',
              onChange: () => {
                setIsRecurrent(!isRecurrent);
              },
            }}
          />
        </Grid>
        {isRecurrent && (
          <Grid item xs={3}>
            <FormOutlinedInput
              title="Период автоплатежа, дней"
              rule={ruleHandler}
              inputProps={{
                name: 'recurrent_period',
                defaultValue: 1,
              }}
            />
          </Grid>
        )}
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1}>
        <Grid item xs>
          <FormSwitch
            title="Возможность оплатить сертификатом"
            switchProps={{
              name: 'by_certificate',
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1} maxWidth={100}>
        <Grid item xs>
          <FormImage name="main_image" />
        </Grid>
      </Grid>

      <Divider />
      <Typography variant="body2" color="grey.600" mb={1}>
        Изображения товара для мобильного приложения:
      </Typography>
      <Grid container columns={3} spacing={1} maxWidth={100}>
        <Grid item xs>
          <FormImage name="mobile_image" formKey="mobile_image_file" />
        </Grid>
      </Grid>

      <FormActions title="Добавить товар" />
    </MainForm>
  );
};

export default CatalogProductCreate