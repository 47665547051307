import React, { ChangeEvent, Dispatch, useEffect, useMemo, useState } from 'react';
import { IFraudSettingCreateDataset } from '../../../FraudMonitoringRuleSettingCreateForm';
import { Alert, Box, Grid, SelectChangeEvent } from '@mui/material';
import FormSwitch from '../../../../../../../UI/Form/FormSwitch';
import { IPartner } from '../../../../../../../types/app/partner/Partner';
import { PartnerService } from '../../../../../../../http/partner/PartnerService';
import CircularProgress from '@mui/material/CircularProgress';
import { FraudRuleReportIds } from '../../../../../../../enums/FraudRules';
import { IPartnerTerminal } from '../../../../../../../types/app/partner/PartnerTerminal';
import Divider from '../../../../../../../UI/Delimiter/Divider';
import FormSelect from '../../../../../../../UI/Form/Select/FormSelect';
import { ISelectable } from '../../../../../../../types/app/common/Selectable';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface IFraudCreateFormStepFinishProps {
  dataset: IFraudSettingCreateDataset;
  dispatchDataset: Dispatch<IFraudSettingCreateDataset>;
}

const BigTransactionFraudCreateFormStepFinish = (props: IFraudCreateFormStepFinishProps) => {
  const [partners, setPartners] = useState<IPartner[]>(null);
  const [terminals, setTerminals] = useState<IPartnerTerminal[]>(null);

  useEffect(() => {
    props.dispatchDataset({
      ...props.dataset,
      partners: [],
      terminals: {},
    });

    PartnerService.getPartners().then(response => setPartners(response));
    PartnerService.getPartnerTerminals().then(response => setTerminals(response));
  }, []);

  const partnerTerminals = useMemo((): ISelectable[] => {
    if (!terminals) {
      return null;
    }

    if (props.dataset.partners.length === 0) {
      return [];
    }

    return terminals
      .filter(terminal => props.dataset.partners.find(partnerId => partnerId === terminal.partnerId))
      .map(terminal => ({ key: terminal.id, label: terminal.name }));
  }, [props.dataset.partners, terminals]);

  if (!partners || !partnerTerminals) {
    return (
      <Grid minHeight={200} container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  const handleChangeTerminals = (e: SelectChangeEvent<unknown>) => {
    const terminalsMap: Record<string, number[]> = {};

    (e.target.value as number[]).forEach(terminalId => {
      const terminal = terminals.find(terminal => terminal.id === terminalId);

      if (terminal.partnerId in terminalsMap) {
        terminalsMap[terminal.partnerId].push(terminalId);
      } else {
        terminalsMap[terminal.partnerId] = [terminalId];
      }
    })

    props.dispatchDataset({
      ...props.dataset,
      terminals: terminalsMap,
  });
    
  } 
  
  return (
    <Box minHeight={200}>
      <FormSelect
        multipletweaks={{
          selectAll: true,
          unselectAll: true,
        }}
        onSelectedChange={selectedItems => {
          props.dispatchDataset({
            ...props.dataset,
            partners: (selectedItems as number[]),
          });
        }}
        onAfterClearAll={() => {
          props.dispatchDataset({
            ...props.dataset,
            partners: [],
            terminals: {},
          });
        }}
        title="Партнеры"
        items={partners}
        selectProps={{
          value: props.dataset.partners.join(',') ?? '',
          multiple: true,
          onChange: e => {              
            props.dispatchDataset({
              ...props.dataset,
              partners: (e.target.value as number[]),
            });
          },
        }}
      />

      <Divider />

      <Box>
        {partnerTerminals.length !== 0 ? (
          <FormSelect
            multipletweaks={{
              selectAll: true,
              unselectAll: true,
            }}
            selectProps={{
              multiple: true,
              disabled: props.dataset.rule.id === FraudRuleReportIds.SALES_FRAUD && props.dataset.partners.length === 0,
              value: Object.values(props.dataset.terminals).flat(),
              onChange: handleChangeTerminals,
            }}
            title="Терминальные точки"
            items={partnerTerminals}
          />
        ) : (
          <Alert
            sx={{ padding: '5px' }}
              color={props.dataset.partners.length > 0 ? 'warning' : 'error'}
            icon={<WarningAmberIcon sx={{ fontSize: 20 }} />}
          >
            {props.dataset.partners.length > 0
              ? 'Для данного партнера терминальных точек не обнаружено'
              : 'Для выбора терминальных точек опеределите партнера'}
          </Alert>
        )}

        <Divider />
      </Box>

      <FormSwitch
        title="Активировать новую настройку сейчас"
        switchProps={{
          checked: props.dataset.isActive,
          onChange: () => {
            props.dispatchDataset({
              ...props.dataset,
              isActive: !props.dataset.isActive,
            })
          }
        }}
      />
    </Box>
  );
};

export default BigTransactionFraudCreateFormStepFinish;
