import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import NewsTabDataTweaks from './NewsTabDataTweaks';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import { INews } from '../../../types/app/communication/News';
import { CommunicationService } from '../../../http/communication/CommunicationService';
import TabLoader from '../../Common/Loader/TabLoader';
import NewsTabData from './NewsTabData';
import NewsTabFilters from './NewsTabFilters';
import { IGroupsTemplate } from '../../../types/app/communication/GroupsTemplate';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilters: IFilters = {
  like: {
    title: '',
  },
  eq: {
    category: null,
    startWithoutTime: null,
    startWithoutDate: null,
    isActive: null,
  },
  in: {
    deliveryMethod: null,
  },
  comparison: {
    category: 'template.web.category',
  },
};

interface INewsTabMainProps {
  active: boolean
}

const NewsTabMain = (props: INewsTabMainProps) => {
  const { setItems, items, getFilterable, getPaginatable, getDialogFilterable, setFilters, allItems } = useTweakTool<INews>();

  const [groups, setGroups] = useState<IGroupsTemplate[]>(null);

  useEffect(() => {
    CommunicationService.getNews({ is_active: props.active }).then(response => setItems(response));
    CommunicationService.getAllGroups().then(response => setGroups(response));
    setFilters(defaultFilters);
  }, []);

  if (!items || !groups || !allItems) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="Рассылки" />

      <NewsTabFilters {...getDialogFilterable} items={allItems} />

      <NewsTabDataTweaks active={props.active} {...getFilterable} />

      <Divider />

      <NewsTabData
        active={props.active}
        groups={groups}
        allItems={allItems}
        dispatch={setItems}
        {...getPaginatable}
      />
    </Box>
  );
};

export default NewsTabMain;
