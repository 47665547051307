import {useState} from 'react';
import {IUserListItem} from '../types/app/auth/UserListItem';
import {AuthService} from '../http/auth/AuthService';
import {snackConfig} from '../layout/assets/config/snackConfig';
import {useSnackbar} from 'notistack';
import {ILoggedUser} from '../types/app/auth/LoggedUser';

export const useAccountDeleteConfirm = (handler, users = null) => {
  const {enqueueSnackbar} = useSnackbar();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [user, setUser] = useState<IUserListItem | ILoggedUser>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openConfirm = (accountUser: IUserListItem | ILoggedUser) => {
    setUser(accountUser);
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const deleteAccountHandler = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await AuthService.deleteUser({id: user.id});

      if (users !== null) {
        handler(users.filter(stateUser => user.id !== stateUser.id));
      }
      else {
        handler();
      }

      enqueueSnackbar('Выбранный аккаунт был удален', {
        variant: 'success',
        autoHideDuration: snackConfig.duration
      });
    }
    catch {
      enqueueSnackbar('Выбранный аккаунт не удалось удалить', {
        variant: 'error',
        autoHideDuration: snackConfig.duration
      });
    }
    finally {
      setIsLoading(false);
      setUser(null);
      closeConfirm();
    }
  };

  return {
    deleteAccountHandler,
    showConfirm,
    closeConfirm,
    openConfirm,
    isLoading,
  };
};