import React, { useState } from 'react';
import { IRuleSetting } from '../../../../types/app/fraud/RuleSetting';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import { FraudSettingConditionNameEnum, FraudSettingConditionNames } from '../../../../enums/FraudRules';
import FraudUpdateForm from './Common/FraudUpdateForm';
import { getConditionDefaultValue } from '../../../../utils/fraudUtils';

const BigTransactionFraudForm = (props: IRuleSetting) => {
  const [ordersAmountMax, setOrdersAmountMax] = useState<number>(
    getConditionDefaultValue(FraudSettingConditionNameEnum.ORDER_AMOUNT_MAX, props.ruleSettingConditions)
  );

  return (
    <FraudUpdateForm settings={props}>
      <FormOutlinedInput
        inputProps={{
          name: FraudSettingConditionNameEnum.ORDER_AMOUNT_MAX,
          value: ordersAmountMax,
          onChange: e => setOrdersAmountMax(+e.target.value),
        }}
        rule={e => !isNaN(+e.target.value)}
        title={FraudSettingConditionNames.toView(FraudSettingConditionNameEnum.ORDER_AMOUNT_MAX)}
      />
    </FraudUpdateForm>
  );
};

export default BigTransactionFraudForm ;