import React, {useContext} from 'react';
import {Box, FormControlLabel, FormGroup, Grid, SwitchProps, Typography,} from '@mui/material';
import {FormContext, IFormContext} from '../../store/context/form/FormContext';
import IOSSwitch from '../Switch/IOSSwitch';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import RemoveIcon from '@mui/icons-material/Remove';

interface IFormSwitchProps extends SwitchProps {
  title?: string;
  switchProps?: SwitchProps;
  error?: boolean;
}

const FormSwitch = (props: IFormSwitchProps) => {
  const {config} = useContext(FormContext) as IFormContext;

  return (
    <Box mb={1} position="relative" pl={1}>
      {config.readonly
        ?
        <Grid
          container
          alignItems="center"
        >
          <Grid item>
            {(props?.switchProps?.checked === true || props?.switchProps?.defaultChecked === true)
              ? <DownloadDoneIcon sx={{fontSize: 16, mr: 1, color: '#0042E7'}}/> :
              <RemoveIcon sx={{fontSize: 16, mr: 1, color: 'inherit'}}/>
            }
          </Grid>

          <Grid item>
            <Typography
              color={(props?.switchProps?.checked === true || props?.switchProps?.defaultChecked === true) ? '#0042E7' : 'inherit' }
              variant="body1"
            >
              {props.title}
            </Typography>
          </Grid>
        </Grid>
        :
        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch {...props.switchProps}/>}
            label={
            <Typography
              sx={[(theme) => ({
                color: props.error ? theme.palette.error.main : 'inherit',
                '&:hover': {
                  color: theme.palette.primary.main,
                }})]}>{props.title}
          </Typography>}
          />
        </FormGroup>
      }
    </Box>
  );
};

export default FormSwitch;
