import React from 'react';
import { Box, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useActions } from '../../../hooks/hooks';
import { encode } from 'js-base64';

export interface ISideBarItem {
  Icon: JSX.Element;
  title: string | JSX.Element;
  path: string;
  childrenPaths?: string[];
}

const SideBarItem = ({ Icon, title, path, childrenPaths = [] }: ISideBarItem) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clear } = useActions();

  const isActive = () => {
    return path === pathname || childrenPaths.some(childrenPath => new RegExp(childrenPath).test(pathname));
  };

  const SvgIconWithActiveProp = styled(SvgIcon)(({ theme }) => ({
    width: 25,
    '& path': {
      fill: isActive() ? theme.palette.primary.main : '',
    },
  }));

  return (
    <ListItemButton
      sx={[
        theme => ({
          backgroundColor: isActive() ? theme.palette.background.default : '',
        }),
        {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          paddingRight: 0,
        },
      ]}

      onClick={() => {
        if (!window.location.hash && path !== pathname) {
          clear();
          navigate(path);
        }

        if (window.location.hash && window.location.hash !== ('#' + encode('tab-' + 0))) {
          clear();
          navigate(path);
        }

        if(pathname === path) {

          return;
        }

        if(pathname !== path) {
          clear();
          navigate(path)
        }

        return
      }}
    >
      <ListItemIcon>
        <SvgIconWithActiveProp>{Icon}</SvgIconWithActiveProp>
      </ListItemIcon>
      <ListItemText>
        <Box
          sx={[
            theme => ({
              borderRight: isActive() ? '3px solid ' + theme.palette.primary.main : '',
              paddingRight: 12,
            }),
          ]}
        >
          <Typography
            sx={[
              theme => ({
                color: isActive() ? theme.palette.grey.A700 : theme.palette.grey[700],
              }),
              {
                padding: 0.5,
              },
            ]}
            component="div"
            fontFamily="Lato-Regular"
            variant="subtitle2"
          >
            {title}
          </Typography>
        </Box>
      </ListItemText>
    </ListItemButton>
  );
};

export default SideBarItem;
