import {useOutletContext} from 'react-router-dom';
import {IAuthUser} from '../types/app/auth/AuthUser';
import {CookieRepository} from '../repositories/CookieRepository';
import {useMemo} from 'react';
import {useAppSelector} from './hooks';

export const useAuthMiddleware = () => {
  const context = useOutletContext() as IAuthUser;
  const user = useAppSelector(status => status.auth.user);

  const isAuthorized = useMemo(() => user && !!CookieRepository.getToken(), [user, context]);
  const isUserLoaded = useMemo(() => !!context, [context]);

  return {
    isAuthorized,
    isUserLoaded,
  }
}