import React from 'react';
import {Box, Card, Typography} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Validator } from '../../../store/context/form/Validator';

const RegistrationPasswordRequirements = ({isShow, password} : {isShow: boolean, password: string}) => {
  const passwordRequirements = [
    {key: 'latinonly', value : 'только символы латиницы'},
    {key: 'min', value : '10 и более символов'},
    {key: 'digits', value : 'цифры'},
    {key: 'characters', value : 'спецсимволы "!$#%'},
    {key: 'lowercase', value : 'буквы с нижним регистром'},
    {key: 'uppercase', value : 'буквы с верхним регистром'},
  ];

  if (!isShow) {
    return <></>;
  }

  const checkPasswordRequirements = (method: string) => {
    switch (method) {
      case 'latinonly': return Validator.latinonly('password', password);
      case 'min': return Validator.min('password', password, 10);
      case 'digits': return Validator.digits('password', password);
      case 'characters': return Validator.characters('password', password);
      case 'lowercase': return Validator.lowercase('password', password);
      case 'uppercase': return Validator.uppercase('password', password);

      default: return false;
    }
  }

  return (
    <Box zIndex={10} position="absolute" top={0} right={0}>
      <Card sx={{padding: 5}}>
        <Typography variant="subtitle1" sx={{mb: 1, mt: 1}}>Пароль должен содержать:</Typography>
        {passwordRequirements.map(requirement => (
          <Typography key={requirement.key}>
            <FiberManualRecordIcon
              color={checkPasswordRequirements(requirement.key) ? 'success' : 'error'}
              sx={{fontSize: 6, mb: '2px', mr: '2px'}}
            />
            {requirement.value}
          </Typography>
        ))}
      </Card>
    </Box>
  );
}

export default RegistrationPasswordRequirements;