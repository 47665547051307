import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetOrderLoader } from '../../../http/shop/ShopLoaders';
import OrderShowForm from '../../../Widgets/Orders/Operations/OrderShowForm';

const OrderShow = () => {
  const { content } = useLoaderData() as { content: Promise<IGetOrderLoader> };

  return (
    <Await resolve={content}>
      <OrderShowForm />
    </Await>
  );
};

export default OrderShow;
