export enum TemplateTypes {
  CUSTOM = 'custom',
  SYSTEM = 'system',
  EMERGENCY = 'emergency',
}

export enum TemplateSystems {
  CLEAR = 'clear',
  NEW_REFERRAL_REGISTERED = 'new_referral_registered',
  POINTS_WERE_DEBITED = 'points_were_debited',
  POINTS_WERE_CREDITED = 'points_were_credited',
  NEW_SUPPORT_MESSAGE = 'new_support_message',
  REPORT_READY_FOR_PARTNER = 'report_ready_for_partner',
  PROFILE_CHANGED = 'profile_changed',
  REPORT_READY_FOR_OPERATOR = 'report_ready_for_operator',
  FRAUD_DETECTED = 'fraud_detected',
}

export const isSystemTemplate = (type: TemplateTypes) => {
  return type === TemplateTypes.SYSTEM;
}

export const getNameByTemplateType = (type: TemplateTypes) => {
  if (type === TemplateTypes.SYSTEM) {
    return 'Системный';
  }

  return 'Аварийное оповещение';
}

export const getColorByTemplateType = (type: TemplateTypes) => {
  if (type === TemplateTypes.SYSTEM) {
    return '#B03A3A';
  }

  return '#0042E7';
}