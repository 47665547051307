import React, {useContext, useEffect} from 'react';
import {Grid} from '@mui/material';
import {RouteList} from '../../../../http/RouteList';
import Divider from '../../../../UI/Delimiter/Divider';
import {FormContext} from '../../../../store/context/form/FormContext';
import MainForm from '../../../Common/Form/MainForm';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import {useNotifications} from '../../../../hooks/useNotifications';
import {messages} from '../../../../notifications/messages';
import {IReportRulesItem} from '../../../../types/app/reports/IGetReportRulesData';
import {useAsyncValue} from 'react-router-dom';
import {getReportTypeName} from '../../../../enums/ReportTypes';
import {getPeriodTypeId, getReportPeriodName, getReportPeriodValueName} from '../../../../enums/ReportPeriodTypes';
import moment from 'moment';


const ReportRulesShowForm = () => {

  const report = useAsyncValue() as IReportRulesItem;
  const {setConfig} = useContext(FormContext);
  const message = useNotifications();

  useEffect(() => {
    setConfig({
      action: RouteList.REPORTS_SHOW + '/' + report.id,
      readonly: true,
      rules: {
        format: ['required'],
      },
      afterSubmitHandler: formResponse => {
        if (formResponse.status) {
          message.success(messages.success.reportSendOperation);
        }
      },
    });
  }, []);


  return (
    <MainForm back={RouteList.REPORTS} isHiddenEditAndCloseButtons={true}>

      <Grid container>
        <Grid item xs={6}>
          <FormOutlinedInput
            title="Партнер"
            inputProps={{
              name: 'report_id',
              defaultValue: report.partnerName
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={6}>
          <FormOutlinedInput
            title="Тип отчета"
            inputProps={{
              defaultValue: getReportTypeName(report.reportType),
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container>
        <Grid item xs={6}>
          <FormOutlinedInput
            title="Периодичность"
            inputProps={{
              defaultValue: getReportPeriodName(report.periodType),
            }}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container width="100%" spacing={1}>
        <Grid item xs={5}>
          <FormOutlinedInput
            title={`Количество ${getReportPeriodValueName(getPeriodTypeId(report.periodType))}`}
            inputProps={{
              name: 'periodValue',
              value: report.periodValue
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={6}>
          <FormOutlinedInput
            title="Дата старта отправки отчета"
            inputProps={{
              defaultValue: report.startAt ? moment.unix(report.startAt).format('DD.MM.YYYY') : '-',
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={6}>
          <FormOutlinedInput
            title="Email для отправки отчета"
            inputProps={{
              defaultValue: report.email,
            }}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container>
        <Grid item xs={6}>
          <FormOutlinedInput
            title="Тип отчета"
            inputProps={{
              defaultValue: report.format,
            }}
          />
        </Grid>
      </Grid>

    </MainForm>
  );
};

export default ReportRulesShowForm;