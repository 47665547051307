import React, { ForwardedRef, useContext } from 'react';
import Form from '../../../UI/Form/Form';
import { FormContext } from '../../../store/context/form/FormContext';
import { Box, BoxProps, Grid } from '@mui/material';
import BackButton from '../../../UI/Button/BackButton';
import { useLocation, useNavigation } from 'react-router-dom';
import FormCloseButton from '../../../UI/Button/FormCloseButton';
import FormEditButton from '../../../UI/Button/FormEditButton';
import FormEditButtonV2 from '../../../UI/Button/FormEditButtonV2';
import FormCard from './FormCard';
import CircularProgress from '@mui/material/CircularProgress';
import { NavigationStates } from '../../../enums/NavigationStates';

interface IMainFormProps {
  back?: string;
  children?: React.ReactNode[] | React.ReactNode;
  buttonVariant?: 'v1' | 'v2';
  boxProps?: BoxProps;
  isHiddenEditAndCloseButtons?: boolean;
}

const MainForm = React.forwardRef((props: IMainFormProps, ref: ForwardedRef<HTMLFormElement>) => {
  const { config } = useContext(FormContext);
  const { pathname } = useLocation();

  const navigation = useNavigation();

  const variant = props.buttonVariant ?? 'v1';

  return (
    <Form ref={ref} redirect={props.back}>
      <Box {...props.boxProps}>
        {props.back && <BackButton />}
        <FormCard>
          {navigation.state !== NavigationStates.IDLE && <Loader />}
          {!config.readonly && !props.isHiddenEditAndCloseButtons && !pathname.endsWith('create') && <FormCloseButton />}
          {config.readonly && !props.isHiddenEditAndCloseButtons && <EditButton variant={variant} />}
          {props.children}
        </FormCard>
      </Box>
    </Form>
  );
});

const Loader = () => {
  return (
    <Box position="absolute" top={0} right={0} left={0} bottom={0} bgcolor="white" zIndex={1000}>
      <Grid container justifyContent="center" alignItems="center" mt={10}>
        <CircularProgress color="error" />
      </Grid>
    </Box>
  );
};

const EditButton = (props: { variant: 'v1' | 'v2' }) => {
  if (props.variant === 'v1') {
    return <FormEditButton />;
  }

  return <FormEditButtonV2 />;
};

export default MainForm;
