import {TReportItem} from '../../types/response/report/TGetReportData';
import {IReportData} from '../../types/app/reports/IReportData';
import moment from 'moment/moment';
import {IPartner} from '../../types/app/partner/Partner';
import {getTypeId} from '../../enums/ReportTypes';
import {TReportRulesItem} from '../../types/response/report/TGetReportRulesData';
import {IReportRulesItem} from '../../types/app/reports/IGetReportRulesData';
import {getPeriodTypeId} from '../../enums/ReportPeriodTypes';


export class ReportsFactory {

  public static getReportDataFromResponse(data: TReportItem, partners: IPartner[]): IReportData {
    const partnerMap = new Map<number, IPartner>();
    partners.forEach(partner => partnerMap.set(partner.key, partner));

    return {
      id: data.id,
      userId: data.user_id,
      type: data.type,
      typeId: getTypeId(data.type),
      format: data.format,
      status: data.status,
      completedAt: data.completed_at ? moment(data.completed_at).unix() : null,
      startDate: data.start_date ? moment(data.start_date).unix() : null,
      endDate: data.end_date ? moment(data.end_date).unix() : null,
      url: data.url,
      generationType: data.generation_type,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      partnerName: data.user_id ? partnerMap.get(data.user_id).label : '-',
      partnerEmail: data.user_id ? partnerMap.get(data.user_id).email : '-',
      sentToEmails: data.sent_to_emails && data.sent_to_emails.map(email => email)
    };
  }

  public static getReportsDataFromResponse(data: TReportItem[], partners: IPartner[]): IReportData[] {

    const partnersMap = new Map<number, IPartner>();
    partners.forEach(partner => partnersMap.set(partner.key, partner));

    return data.map(item => {
      return {
        id: item.id,
        userId: item.user_id,
        type: item.type,
        typeId: getTypeId(item.type),
        format: item.format,
        status: item.status,
        completedAt: item.completed_at ? moment(item.completed_at).unix() : null,
        startDate: moment(item.start_date).unix(),
        endDate: moment(item.end_date).unix(),
        url: item.url,
        generationType: item.generation_type,
        createdAt: moment(item.created_at).unix(),
        partner: partnersMap.get(item.user_id),
        partnerName: partnersMap.get(item.user_id) ? partnersMap.get(item.user_id).label : '-',
        partnerEmail: partnersMap.get(item.user_id) ? partnersMap.get(item.user_id).email : '-',
      };
    });
  }

  public static getReportRulesDataFromResponse(data: TReportRulesItem[], partners: IPartner[]): IReportRulesItem[] {

    const partnersMap = new Map<number, IPartner>();
    partners.forEach(partner => partnersMap.set(partner.key, partner));

    return data.map(item => {
      return {
        id: item.id,
        reportType: item.report_type,
        typeId: getTypeId(item.report_type),
        email: item.email,
        periodValue: item.period_value,
        periodType: item.period_type,
        periodTypeId: getPeriodTypeId(item.period_type),
        format: item.format,
        isActive: item.is_active,
        startAt: moment(item.start_at).unix(),
        userId: item.user_id,
        partner: partnersMap.get(item.user_id),
        partnerEmail: item.user_id ? partnersMap.get(item.user_id).email : '-',
        partnerName: item.user_id ? partnersMap.get(item.user_id).label : '-'
      };
    });
  }

  public static getReportRule(data: TReportRulesItem, partners: IPartner[]): IReportRulesItem {

    const partnersMap = new Map<number, IPartner>();
    partners.forEach(partner => partnersMap.set(partner.key, partner));

      return {
        id: data.id,
        reportType: data.report_type,
        email: data.email,
        periodValue: data.period_value,
        periodType: data.period_type,
        format: data.format,
        isActive: data.is_active,
        startAt: moment(data.start_at).unix(),
        userId: data.user_id,
        partner: partnersMap.get(data.user_id),
        partnerEmail: data.user_id ? partnersMap.get(data.user_id).email : '-',
        partnerName: data.user_id ? partnersMap.get(data.user_id).label : '-'
      };

  }
}
