import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';
import { IShowPromoLoader } from '../../../http/billing/BillingLoaders';
import PromoShowForm from '../../../Widgets/Billing/BillingTab/Operations/Promo/PromoShowForm';

const PromoShow = () => {
  const { content } = useLoaderData() as { content: IShowPromoLoader };

  return (
    <Await resolve={content}>
      <PromoShowForm />
    </Await>
  );
};

export default PromoShow;
