import React, { Dispatch } from 'react';
import { IRule } from '../../../../../../types/app/fraud/Rule';
import FraudCreateFormStepStart from './FraudCreateFormStepStart';
import FraudCreateFormStepConditions from './FraudCreateFormStepConditions';
import FraudCreateFormStepFinish from './FraudCreateFormStepFinish';
import {IFraudSettingCreateDataset, STEPS} from '../../FraudMonitoringRuleSettingCreateForm';

interface IFraudCreateFormStepHandlerProps {
  step: number;
  rules: IRule[];
  steps: string[];
  dispatchSteps: Dispatch<string[]>;
  initSteps: string[];
  dataset: IFraudSettingCreateDataset;
  dispatchDataset: Dispatch<IFraudSettingCreateDataset>;
}

const FraudCreateFormStepSwitcher = (props: IFraudCreateFormStepHandlerProps) => {
  if (props.step === STEPS.STEP_START) {
    return <FraudCreateFormStepStart dataset={props.dataset} dispatchDataset={props.dispatchDataset} rules={props.rules} />;
  }

  if (props.step === STEPS.STEP_CONDITIONS) {
    return <FraudCreateFormStepConditions dataset={props.dataset} dispatchDataset={props.dispatchDataset} />;
  }

  return <FraudCreateFormStepFinish dataset={props.dataset} dispatchDataset={props.dispatchDataset} />;
};

export default FraudCreateFormStepSwitcher;
