import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useActions, useAppSelector } from '../hooks';
import { useUrlManager } from '../useUrlManager';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PER_PAGE } from '../../store/rtk/reducers/serverPaginationSlice';

export const usePagination = <T>() => {
  const { pageToUrl, getPerPageByUrl, getPageByUrl } = useUrlManager();
  const { setServerItems, setCount: setServerCount } = useActions();
  const location = useLocation();

  const serverConfig = useAppSelector(state => state.serverConfig);

  const [items, setItems] = useState<T[] | null>(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(getPageByUrl());
  const [perPage, setPerPage] = useState(getPerPageByUrl());

  const [paginated, setPaginated] = useState<T[] | null>(null);

  const index: number = (page - 1) * perPage;
  const offset: number = index + perPage;

  useEffect(() => {
    setCount(serverConfig.count);
  }, [serverConfig.count]);

  useEffect(() => {
    if (Array.isArray(items)) {
      if (!serverConfig.method) {
        const result = items.slice(index, offset);

        setServerItems(result);
        setPaginated(result);
        setServerCount(Math.ceil(items.length / perPage));

        if (location.search && items.length !== 0 && result.length === 0) {
          pageToUrl(DEFAULT_PAGE_NUMBER);
        }
      } else {
        setServerItems(serverConfig.items);
        setPaginated(serverConfig.items);
      }
    }
  }, [page, perPage, items, serverConfig.method]);

  useEffect(() => {
    setPage(location.search ? getPageByUrl() : DEFAULT_PAGE_NUMBER);
    setPerPage(location.search ? getPerPageByUrl() : DEFAULT_PER_PAGE);
  }, [location.search]);

  return {
    page,
    perPage,
    index,
    setPage,
    setPerPage,
    setItems,
    setCount: setServerCount,
    items: paginated,
    count,
    setResult: setServerItems,
  };
};
