import React, {useState} from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '../../../UI/Table/TableContainer';
import {IPaginatable} from '../../../types/app/common/Paginatable';
import {IReportData} from '../../../types/app/reports/IReportData';
import ShowButton from '../../../UI/Button/ShowButton';
import {RouteList} from '../../../http/RouteList';
import EmptyCell from '../../../UI/Table/EmptyCell';
import {getReportTypeName} from '../../../enums/ReportTypes';
import moment from 'moment/moment';
import Confirm from '../../Common/Modal/Confirm';
import {Grid} from '@mui/material';
import DeleteButton from '../../../UI/Button/DeleteButton';
import {ReportService} from '../../../http/reports/ReportService';
import {useNotifications} from '../../../hooks/useNotifications';
import {messages} from '../../../notifications/messages';
import {getReportStatusName} from '../../../enums/ReportStatyses';

interface ReportTabContentProps extends IPaginatable<IReportData> {
  setRerender: ()=>void
}

const ReportsTabContentData = (props: ReportTabContentProps) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportId, setReportId] = useState<number>(null)
  const message = useNotifications();

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const deleteReportHandler = async (): Promise<void> => {
    setIsLoading(true);

    try {
      await ReportService.deleteReport({id: reportId});
      props.setRerender()
      message.success(messages.success.reportDeleteOperation);
    }
    catch {
      message.error(messages.errors.generalError);
    }
    finally {
      setReportId(null)
      setIsLoading(false);
      closeConfirm();
    }
  };

  return (
    <TableContainer>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteReportHandler}
        text={`Вы действительно хотите удалить отчет? Данное действие нельзя будет отменить.`}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={300}> Тип отчёта </TableCell>
            <TableCell width={350}> Email для отправки отчёта </TableCell>
            <TableCell width={200}> Партнер </TableCell>
            <TableCell width={200}> Статус </TableCell>
            <TableCell width={200}> Дата отправки отчета </TableCell>
            <TableCell width={100}> Формат отчёта </TableCell>
            <TableCell width={100}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(report => (
            <TableRow key={report.id} sx={{'&:last-child td, &:last-child th': {border: 0}, width: '100%'}}>
              <TableCell>{getReportTypeName(report.type)}</TableCell>
              <TableCell>{report.partnerEmail}</TableCell>
              <TableCell>{report.partnerName}</TableCell>
              <TableCell>{getReportStatusName(report.status) ?? <EmptyCell/>}</TableCell>
              {report.createdAt
                ? <TableCell>{moment.unix(report.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
                : <TableCell><EmptyCell/> </TableCell>
              }
              <TableCell>{report.format}</TableCell>
              <TableCell>
                <Grid container display='flex' columns={2} spacing={2} justifyContent="flex-end">
                  <Grid item xs={1}>
                    <ShowButton linkProps={{to: RouteList.REPORTS_SHOW + '/' + report.id}}/>
                  </Grid>
                  <Grid item xs={1} >
                    <DeleteButton disabled={isLoading} onClick={() => {
                      setReportId(report.id)
                      setShowConfirm(true)
                    }}/>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportsTabContentData;