import React, {useState} from 'react';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../UI/Delimiter/Divider';
import { Box, Grid, Typography } from '@mui/material';
import {IPromo} from '../../../../types/app/billing/bonuses/Promo';
import {getPointsByPromo} from '../../../../utils/billingUtils';

interface IPromoPointsProps {
  mode: 'multiple' | 'additional'
  promo: IPromo | null,
}

const PromoPoints = (props: IPromoPointsProps) => {
  const [points, setPoints] = useState<number | string>(getPointsByPromo(props.promo));
  const [pointsLifetimeDays, setPointsLifetimeDays] = useState<number | string>(props.promo?.pointsLifetimeDays ?? 1);

  return (
    <>
      <Box bgcolor="#F9FCFF" p={5} mt={3} mb={3} borderRadius={4}>
        <Typography variant="subtitle2">
          Настройка механики: {props.mode === 'multiple' ? 'увеличенное количество баллов' : 'фиксированное начисление'}
        </Typography>

        <Divider />

        <Grid container columns={2}>
          <Grid item xs={1}>
            <FormOutlinedInput
              rule={e => !isNaN(+e.target.value)}
              title={props.mode === 'multiple' ? 'Множитель вознаграждения за покупку' : 'Количество получаемых баллов'}
              inputProps={{
                name: props.mode === 'multiple' ? 'points_multiplier' : 'points_additional_amount',
                value: points,
                onChange: (e) => {
                  const value = +e.target.value;

                  if (value === 0) {
                    setPoints('');

                    return;
                  }

                  setPoints(+e.target.value)
                },
              }}
            />
          </Grid>
        </Grid>

        <Divider />

        <Grid container columns={2}>
          <Grid item xs={1}>
            <FormOutlinedInput
              inputProps={{
                name: 'points_lifetime_days',
                value: pointsLifetimeDays,
                onChange: (e) => {
                  const value = +e.target.value;

                  if (value === 0) {
                    setPointsLifetimeDays('');

                    return;
                  }

                  setPointsLifetimeDays(+e.target.value)
                },
              }}
              rule={e => !isNaN(+e.target.value)}
              title="Срок действия баллов, начисляемых в рамках данной акции (дней)"
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />
    </>
  );
};

export default PromoPoints;
