export class EmailOrPasswordIsWrongError extends Error {
  message = 'Электронная почта или пароль указаны неверно';
}

export class UserNotFoundError extends Error {
  message = 'Пользователь не обнаружен';
}

export class PhoneAlreadyTakenError extends Error {
  message = 'Указанный номер телефона уже зарегистрирован в системе.';
}

export class UserIsAlreadyExists extends Error {
  message = 'Пользователь с данным адресом электронной почты уже зарегистрирован';
}

export class ValidationErrors extends Error {
  message = 'Ошибки валидации параметров в запросе. Удаленный сервер отклонил запрос.';
}

export class InternalError extends Error {
  message = 'Произошла ошибка удаленного сервера, изменения не были сохранены.';
}

export class UserWasNotRemoved extends Error {
  message = 'Не удалось удалить Пользователя. Ошибка удаленного сервиса.';
}

export class RoleIsAlreadyExists extends Error {
  message = 'Данная роль уже существует.';
}

export class RoleCannotBeDeleted extends Error {
  message = 'Удаление роли невозможно. С ней связаны учетные записи.';
}

export class RestorePasswordThrottle extends Error {
  message = 'Превышено допустимое количество попыток для восстановления пароля. Пожалуйста попробуйте позднее.';
}
