import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';
import {IGetRoleLoader} from '../../../http/auth/AuthLoaders';
import RoleShowForm from '../../../Widgets/Common/Tab/RoleTabs/Operations/RoleShowForm';

const UserRoleShow = () => {
  const { role } = useLoaderData() as IGetRoleLoader;

  return (
    <Await resolve={role}>
      <RoleShowForm />
    </Await>
  );
};

export default UserRoleShow;