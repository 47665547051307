import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Box, Divider, Grid, Typography} from '@mui/material';
import {AccountService} from '../../../http/account/AccountService';
import {AccountObjects, AccountProperties} from '../../../enums/AccountProperties';
import SettingsTabFormInput from './SettingsTabFormInput';
import SettingsTabFormPercentageInput from './SettingsTabFormPercentageInput';
import FormActions from '../../../UI/Form/FormActions';
import Form from '../../../UI/Form/Form';
import {FormContext} from '../../../store/context/form/FormContext';
import {RouteList} from '../../../http/RouteList';
import {useSnackbar} from 'notistack';
import {snackConfig} from '../../../layout/assets/config/snackConfig';

const SettingsTabForm = () => {
  const [convertRate, setConvertRate] = useState<string | undefined>(null);

  const [lifetimeDaysForAllReturnedPoints, setLifetimeDaysForAllReturnedPoints] = useState<string | undefined>(null);
  const [maxPointsQuoteForOrder, setMaxPointsQuoteForOrder] = useState<string>(null);

  const { setConfig, hardReset } = useContext(FormContext);
  const { enqueueSnackbar } = useSnackbar();

  const isLoaded = useMemo(() => {
    return lifetimeDaysForAllReturnedPoints !== null && maxPointsQuoteForOrder !== null;
  }, [lifetimeDaysForAllReturnedPoints, maxPointsQuoteForOrder,]);

  useEffect(() => {
    setConfig({
      action: RouteList.BILLING,
      beforeSubmitHandler: (formData: FormData) => {
        formData.set('max_points_quote_for_order', (Number(parseFloat(formData.get('max_points_quote_for_order') as string)) / 100).toString())
      },
      afterSubmitHandler: () => {
        enqueueSnackbar('Изменения успешно сохранены', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        });
      },
    })

    AccountService.getProperty({
      property: AccountProperties.CONVERT_RATE,
      obj_name: AccountObjects.COMMON,
    }).then(convertRate => setConvertRate(convertRate));
    AccountService.getProperty({
      property: AccountProperties.LIFETIME_DAYS_FOR_ALL_RETURNED_POINTS,
      obj_name: AccountObjects.COMMON,
    }).then(ttl => setLifetimeDaysForAllReturnedPoints(ttl));

    AccountService.getProperty({
      obj_name: AccountObjects.COMMON,
      property: AccountProperties.MAX_POINTS_QUOTE_FOR_ORDER,
    }).then(maxPoints => {
      setMaxPointsQuoteForOrder(maxPoints);
    })

    return () => {
      hardReset();
    };
  }, []);

  return (
    <Form>
      <Box bgcolor="#F9FCFF" borderRadius={2} padding="50px">
        <Typography variant="subtitle1">Системные параметры:</Typography>

        <Grid container columns={2} spacing={5}  mt={3} alignItems="center">
          <Grid item xs={1}>
            <SettingsTabFormPercentageInput
              name="max_points_quote_for_order"
              dispatch={setMaxPointsQuoteForOrder}
              title="Максимальный процент использования баллов Пользователем"
              value={maxPointsQuoteForOrder}
            />
          </Grid>
          <Grid item xs={1}>
            <SettingsTabFormInput
              name="life_time_days_for_all_returned_points"
              dispatch={setLifetimeDaysForAllReturnedPoints}
              title="Период сгорания баллов возвращенных Пользователю (дней)"
              value={lifetimeDaysForAllReturnedPoints}
            />
          </Grid>
        </Grid>

        <Box mt={3} mb={3}>
          <Divider />
        </Box>

         <Grid container columns={2} spacing={5}  alignItems="center">
          <Grid item xs={1}>
            <Typography marginBottom={1} variant="body2" color="grey.600">
              Курс балла к рублю:
            </Typography>
            <Typography marginBottom={1}>
              {convertRate}
            </Typography>
          </Grid>
        </Grid>

      </Box>

      <FormActions loading={!isLoaded} title="Сохранить изменения" />
    </Form>
  );
};

export default SettingsTabForm;
