import React, {useMemo} from 'react';
import {Grid, Typography} from '@mui/material';
import {useAppSelector} from '../../../hooks/hooks';

const HeaderBalances = () => {
  const user = useAppSelector(state => state.auth.user);
  const isShowAccount = useMemo(() => user.role.sections.some(section => section.sectionName === '/show-account'), [user])

  if(!user.balances){
    return (<></>);
  }

  return (
    <Grid container alignItems="center" columns={5} spacing={3}>
      {isShowAccount &&
          <>
            <Grid item xs="auto">
              <Grid container flexDirection="row" spacing={1}>
                <Grid item>
                  <Typography variant="body1" component="div">
                    Баллы:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight="bold" variant="body1">{user.balances.point.toLocaleString()}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <Grid container flexDirection="row" spacing={1}>
                <Grid item>
                  <Typography variant="body1" component="div">
                    ₽:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontWeight="bold" variant="body1">{user.balances.rub.toLocaleString()}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
      }
    </Grid>
  );
};

export default HeaderBalances;