import { useAppSelector } from './hooks';
import { ISideBarItem } from '../Widgets/Layout/Sidebar/SideBarItem';
import { SideBarMap } from '../Widgets/Layout/Sidebar/SideBarMap';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { RouteList } from '../http/RouteList';

export const useGuards = () => {
  const user = useAppSelector(state => state.auth.user);

  const { pathname } = useLocation();

  const [availableMenu, setAvailableMenu] = useState<ISideBarItem[]>(null);

  useEffect(() => {
    setAvailableMenu(sidebarGuard.getAvailableItems(true));
  }, [user]);

  const sidebarGuard = {
    getAvailableItems: (refresh?: boolean) => {
      if (availableMenu && !refresh) {
        return availableMenu;
      }

      const availableItems: ISideBarItem[] = [];

      SideBarMap.forEach(item => {
        user.role.sections.forEach(section => {
          if (section.sectionName === item.path) availableItems.push(item);
        });
      });

      setAvailableMenu(availableItems);

      return availableItems;
    },
  };

  const routeGuard = {
    getRedirectRoute: (): string => {
      if (!routeGuard.isSomeRoutesAvailable) {
        throw new Error('Routes for redirect not found');
      }

      return sidebarGuard.getAvailableItems()[0].path;
    },
    isSomeRoutesAvailable: useCallback(() => {
      return !!sidebarGuard.getAvailableItems().length;
    }, [sidebarGuard]),
    isRouteAvailable: (path?: string): boolean => {
      const rootPath = RouteList.DASHBOARD;
      const sections = user.role.sections;
      const rootIndex = sections.findIndex(section => rootPath === section.sectionName);

      const route = path ?? pathname;

      // For the Guard to work, it is necessary to exclude the root route
      if (pathname === rootPath && rootIndex !== -1) {
        return true;
      }

      // We are trying to find the current section from an authorized user,
      // excluding the root route
      const section = sections.find(section => {
        if (section.sectionName === rootPath) {
          return;
        }

        return new RegExp(section.sectionName).test(route);
      });

      if (!section) {
        return false;
      }

      // checking that child routes are available
      return !!SideBarMap.find(section => new RegExp(section.path).test(route));
    },
  };

  return {
    sidebarGuard,
    routeGuard,
  };
};
