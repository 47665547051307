export enum GroupsTypesNames {
  PARTNER = 'partner',
  OPERATOR = 'operator',
  CLIENT = 'client'
}

enum GroupsTypesTranslation {
  PARTNER = 'Партнер',
  OPERATOR = 'Оператор',
  CLIENT = 'Пользователь'
}

export const getGroupTypeTranslation = (groupType: string) => {
  switch (groupType) {
    case GroupsTypesNames.PARTNER: return GroupsTypesTranslation.PARTNER
    case GroupsTypesNames.OPERATOR: return GroupsTypesTranslation.OPERATOR
    case GroupsTypesNames.CLIENT: return GroupsTypesTranslation.CLIENT
  }
}
