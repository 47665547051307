import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import ReportsTabContentData from './ReportsTabContentData';
import ReportsTabDataTweaks from './ReportsTabDataTweaks';
import ReportsTabFilters from './ReportsTabFilters';
import TabLoader from '../../Common/Loader/TabLoader';
import { ReportService } from '../../../http/reports/ReportService';
import { IReportData } from '../../../types/app/reports/IReportData';
import { IPartner } from '../../../types/app/partner/Partner';
import { getReportTypeNames, IReportTypes } from '../../../enums/ReportTypes';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilterState: IFilters = {
  like: {
    email: '',
    partnerCompanyName: '',
  },
  from: {
    createdAt: null,
    createdAtTime: null,
    orderAmount: null,
    paymentAmount: null,
  },
  to: {
    createdAt: null,
    createdAtTime: null,
    orderAmount: null,
    paymentAmount: null,
  },
  eq: {
    statusId: null,
  },
};

const ReportsTabContentMain = () => {
  const { getPaginatable, getDialogFilterable, getFilterable, items, setItems, setFilters } = useTweakTool<IReportData>();

  const [partnersInfo, setPartnersInfo] = useState<Map<number, IPartner>>(null);
  const [reportTypes, setReportTypes] = useState<IReportTypes[]>(null);
  const [rerender, setRerender] = useState<boolean>(false);

  useEffect(() => {
    setRerender(false);
    ReportService.getReportTypes().then(types => setReportTypes(getReportTypeNames(types)));

    ReportService.getReports().then(reports => {
      const partners = new Map<number, IPartner>();
      reports.forEach(report => report.userId && partners.set(report.partner.key, report.partner));
      setPartnersInfo(partners);
      setItems(reports);
    });
    setFilters(defaultFilterState);
  }, [rerender]);

  if (!items || !partnersInfo || !reportTypes) {
    return <TabLoader />;
  }

  return (
    <Box width="100%">
      <ReportsTabFilters {...getDialogFilterable} partners={partnersInfo} reportTypes={reportTypes} />

      <ReportsTabDataTweaks {...getFilterable} />

      <Divider />

      <ReportsTabContentData {...getPaginatable} setRerender={() => setRerender(true)} />
    </Box>
  );
};

export default ReportsTabContentMain;
