import React from 'react';
import RoleCreateForm from '../../../Widgets/Common/Tab/RoleTabs/Operations/RoleCreateForm';
import {Await, useLoaderData} from 'react-router-dom';
import {IGetSiteSectionsLoader} from '../../../http/auth/AuthLoaders';

const PartnerRoleCreate = () => {
  const {sections} = useLoaderData() as IGetSiteSectionsLoader;

  return (
    <Await resolve={sections}>
      <RoleCreateForm />
    </Await>
  );
};

export default PartnerRoleCreate;