import React from 'react';
import {
  Box, BoxProps,
  Grid,
  LinearProgress,
  TableContainer as MuiTableContainer,
  TableContainerProps,
  Typography
} from '@mui/material';
import Pagination from './Pagination';
import { useTweakTool } from '../../hooks/table/useTweakTool';
import {useAppSelector} from '../../hooks/hooks';

interface ITableContainerProps extends TableContainerProps {
  boxprops?: BoxProps,
}

const TableContainerContent = (props: BoxProps) => {
  const {isLoading} = useTweakTool();

  return (
    <Box>
      <Box position="relative">
        {isLoading && (
          <Box bgcolor="rgba(255,255,255)" zIndex={100} position="absolute" left={0} right={0} top={0} bottom={0} {...props}>
            <Grid item width="100%">
              <LinearProgress color="error"/>
            </Grid>
          </Box>
        )}
        {props.children}
      </Box>
      <Pagination/>
    </Box>
  );
};

const TableContainer = (props: ITableContainerProps) => {
  const {items} = useAppSelector(state => state.serverConfig);

  if (!items?.length) {
    return (
      <TableContainerContent {...props.boxprops}>
        <Grid container justifyContent="center" alignItems="center" pb={25}>
          <Grid item mt={15}>
            {items !== null &&
                <Typography variant="subtitle1" color="grey.600">
                  По вашему запросу ничего не найдено...
                </Typography>
            }
          </Grid>
        </Grid>
      </TableContainerContent>
    );
  }

  return (
    <TableContainerContent {...props.boxprops}>
      <MuiTableContainer {...props}>{props.children}</MuiTableContainer>
    </TableContainerContent>
  );
};

export default TableContainer;
