import {ISelectable} from '../types/app/common/Selectable';

export enum OrderStatuses {
  NEW = 1,
  PENDING_PAYMENT,
  PAID,
  CANCELLED,
  FAILED,
  EXPIRED,
  REFUND,
  PARTIAL_REFUND,
  COMPLETED,
  CLOSED,
  FAILED_TO_COMPLETE,
}

export enum OrderStatusNames {
  CANCELLED = 'cancelled',
  PAID = 'paid',
  NEW = 'new',
  PENDING_PAYMENT = 'pending payment',
  FAILED = 'failed',
  EXPIRED = 'expired',
  REFUND = 'refund',
  PARTIAL_REFUND = 'partial refund',
  COMPLETED = 'completed',
  CLOSED = 'closed',
  FAILED_TO_COMPLETE = 'failed to complete',
}

/**
 * Get row color by status
 *
 * @param {OrderStatuses} status
 */
export const getColorByStatusName = (status: OrderStatuses): string => {
  switch (status) {
    case OrderStatuses.CANCELLED: return '#B03A3A';
    case OrderStatuses.NEW: return '#0042E7';
    case OrderStatuses.PAID: return '#00AA1B';
    case OrderStatuses.PENDING_PAYMENT: return '#FFCE45';

    case OrderStatuses.EXPIRED: return '#820852';
    case OrderStatuses.REFUND: return '#199ab0';
    case OrderStatuses.PARTIAL_REFUND: return '#216672';
    case OrderStatuses.COMPLETED: return '#128218';
    case OrderStatuses.CLOSED: return '#5dc75c';
    case OrderStatuses.FAILED_TO_COMPLETE: return '#970000';

    // FAILED
    default: return '#FF0000'
  }
}

export const getOrderStatusesAsSelectable = (): ISelectable[] => {
  return [
    {key: OrderStatuses.NEW, label: getOrderStatusName(OrderStatuses.NEW)},
    {key: OrderStatuses.PENDING_PAYMENT, label: getOrderStatusName(OrderStatuses.PENDING_PAYMENT)},
    {key: OrderStatuses.PAID, label: getOrderStatusName(OrderStatuses.PAID)},
    {key: OrderStatuses.CANCELLED, label: getOrderStatusName(OrderStatuses.CANCELLED)},
    {key: OrderStatuses.FAILED, label: getOrderStatusName(OrderStatuses.FAILED)},
    {key: OrderStatuses.EXPIRED, label: getOrderStatusName(OrderStatuses.EXPIRED)},
    {key: OrderStatuses.REFUND, label: getOrderStatusName(OrderStatuses.REFUND)},
    {key: OrderStatuses.PARTIAL_REFUND, label: getOrderStatusName(OrderStatuses.PARTIAL_REFUND)},
    {key: OrderStatuses.COMPLETED, label: getOrderStatusName(OrderStatuses.COMPLETED)},
    {key: OrderStatuses.CLOSED, label: getOrderStatusName(OrderStatuses.CLOSED)},
    {key: OrderStatuses.FAILED_TO_COMPLETE, label: getOrderStatusName(OrderStatuses.FAILED_TO_COMPLETE)},
  ]
}

/**
 * Get order status description
 *
 * @param {OrderStatuses} status
 */
export const getOrderStatusName = (status: OrderStatuses): string => {
  switch (status) {
    case OrderStatuses.CANCELLED: return 'Отменен';
    case OrderStatuses.NEW: return 'Новый';
    case OrderStatuses.PAID: return 'Оплачен';
    case OrderStatuses.PENDING_PAYMENT: return 'Ожидает оплаты';

    case OrderStatuses.EXPIRED: return 'Истек срок ожидания оплаты';
    case OrderStatuses.REFUND: return 'Возврат';
    case OrderStatuses.PARTIAL_REFUND: return 'Частичный возврат';
    case OrderStatuses.COMPLETED: return 'Выполнен';
    case OrderStatuses.CLOSED: return 'Закрыт';
    case OrderStatuses.FAILED_TO_COMPLETE: return 'Не удалось завершить';

    // FAILED
    default: return 'Ошибка'
  }
}