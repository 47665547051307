import React from 'react';
import { Box, Zoom } from '@mui/material';
import LightTooltip from '../Tooltips/LightTooltip';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';

const Info = ({ title }: { title: string }) => {
  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <LightTooltip TransitionComponent={Zoom} title={title} arrow>
        <InfoIcon />
      </LightTooltip>
    </Box>
  );
};

export default Info;
