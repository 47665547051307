import React from 'react';
import { Badge, Button, Grid } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useTweakTool } from '../../hooks/table/useTweakTool';

interface IFilterGroupButtonsProps {
  onClick: () => void;
  reset: () => void;
}

const FilterGroupButtons = (props: IFilterGroupButtonsProps) => {
  const { isLoading, filtersCount } = useTweakTool();

  return (
    <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
      <Grid item>
        <Badge badgeContent={filtersCount} color="primary">
          <Button
            disabled={isLoading}
            onClick={e => {
              props.onClick();
              e.currentTarget.blur();
            }}
            variant="outlined"
            endIcon={<FilterListIcon />}
          >
            Фильтры
          </Button>
        </Badge>
      </Grid>
      <Grid item>
        <Button disabled={isLoading} onClick={props.reset} variant="outlined" endIcon={<RestartAltIcon />}>
          Сбросить фильтры
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterGroupButtons;
