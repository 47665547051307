import { useAsyncValue, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IGetUserLoader } from '../../../../../http/auth/AuthLoaders';
import React, { useContext, useEffect } from 'react';
import { FormContext, IFormConfig } from '../../../../../store/context/form/FormContext';
import { useSnackbar } from 'notistack';
import { useAccountDeleteConfirm } from '../../../../../hooks/useAccountDeleteConfirm';
import { RouteList } from '../../../../../http/RouteList';
import { usePagination } from '../../../../../hooks/table/usePagination';
import { snackConfig } from '../../../../../layout/assets/config/snackConfig';
import Confirm from '../../../Modal/Confirm';
import { Box, Button } from '@mui/material';
import MainForm from '../../../Form/MainForm';
import FormOutlinedInput from '../../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../../UI/Delimiter/Divider';
import { UserTypeValues } from '../../../../../enums/UserTypes';
import FormActions from '../../../../../UI/Form/FormActions';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountShowFormMixin from '../Common/AccountShowFormMixin';

const AccountShowForm = () => {
  const route = useNavigate();

  const { userData } = useAsyncValue() as IGetUserLoader;

  const { email } = useParams();
  const { config, setConfig } = useContext(FormContext);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const { showConfirm, closeConfirm, isLoading, openConfirm, deleteAccountHandler } = useAccountDeleteConfirm(() => route(-1));

  const { setPage } = usePagination();

  const accountConfig: IFormConfig = {
    readonly: true,
    action: RouteList.USERS_SHOW + '/' + email,
    data: [{ field: 'id', value: userData.user.id }],
    afterSubmitHandler: (formResponse, config, setConfig) => {
      setPage(1);

      enqueueSnackbar('Аккаунт был обновлен', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      setConfig({ ...config, readonly: true });
    },
  };

  useEffect(() => {
    setConfig(accountConfig);
  }, []);

  return (
    <Box position="relative">
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteAccountHandler}
        text={`Вы действительно хотите удалить аккаунт ? Данное действие нельзя будет отменить.`}
      />
      <MainForm back={pathname.includes(RouteList.USERS_SHOW) ? RouteList.USERS : RouteList.PARTNERS}>
        <FormOutlinedInput
          title="ФИО сотрудника"
          typography="h2"
          inputProps={{
            defaultValue: userData.user.name,
            name: 'name',
          }}
        />

        <Divider />

        {userData.user.type !== UserTypeValues.USER && <AccountShowFormMixin userData={userData} />}

        <FormActions>
          {(config.readonly && userData.user.id !== 1) && (
            <Button onClick={() => openConfirm(userData.user)} variant="outlined" color="error" endIcon={<DeleteIcon />}>
              Удалить учетную запись
            </Button>
          )}
        </FormActions>
      </MainForm>
    </Box>
  );
};

export default AccountShowForm;
