import React from 'react';
import { IFilter } from '../../hooks/table/useFilters';
import {Grid, TextField, TextFieldProps} from '@mui/material';
import moment from 'moment';
import { TimePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment/moment';
import { DateHelper } from '../../helpers/DateHelper';
import {IFilters} from '../../store/rtk/reducers/serverPaginationSlice';

interface IFilterInputProps {
  addFilter: (filter: IFilter) => void;
  field: string;
  filters: IFilters;
  label: string;
  placeholderFrom?: string;
  placeholderTo?: string;
  fieldFromProps?: TextFieldProps,
  fieldToProps?: TextFieldProps,
}

const FilterTimeRange = (props: IFilterInputProps) => {
  if (!props.filters) {
    throw new Error('Filters is not initialized');
  }

  return (
    <Grid alignItems="center" container justifyContent="space-between" columns={12} spacing={1}>
      <Grid item xs={4} position="relative">
        {props.label}
      </Grid>
      <Grid item xs={8}>
        <Grid container flexDirection="row" spacing={2}>
          <Grid item xs>
            <TimePicker
              onChange={(date: Moment) => {
                if (date !== null && !date.isValid()) {
                  return;
                }

                props.addFilter({
                  type: 'from',
                  name: props.field,
                  value: date
                    ? moment({
                      date: moment(0).date(),
                      hours: date.hours(),
                      minutes: date.minutes(),
                    }).unix()
                    : null,
                });
              }}
              value={DateHelper.getMomentFromValue(props.filters.from[props.field])}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    },
                    '& input': {
                      padding: '8px !important',
                      paddingLeft: '16px !important',
                    },
                    '& input::placeholder': {
                      fontSize: 14,
                    },
                  }}
                  {...props.fieldFromProps}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholderFrom ?? '',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs>
            <TimePicker
              onChange={(date: Moment) => {
                if (date !== null && !date.isValid()) {
                  return;
                }

                props.addFilter({
                  type: 'to',
                  name: props.field,
                  value: date
                    ? moment({
                      date: moment(0).date(),
                      hours: date.hours(),
                      minutes: date.minutes(),
                    }).unix()
                    : null,
                });
              }}
              value={DateHelper.getMomentFromValue(props.filters.to[props.field])}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    },
                    '& input': {
                      padding: '8px !important',
                      paddingLeft: '16px !important',
                    },
                    '& input::placeholder': {
                      fontSize: 14,
                    },
                  }}
                  {...props.fieldToProps}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholderTo ?? '',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterTimeRange;
