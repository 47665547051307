import React, { Dispatch, useState } from 'react';
import FilterDialog from '../Common/Filter/FilterDialog';
import { FormControlLabel, FormGroup, Grid } from '@mui/material';
import IOSSwitch from '../../UI/Switch/IOSSwitch';
import Divider from '../../UI/Delimiter/Divider';
import {defaultViewState} from '../../Pages/Private/Dashboard/DashboardIndex';

interface DashboardFiltersRowProps {
  label: string;
  items: { key: string; label: string }[];
  view: typeof defaultViewState;
  setView: Dispatch<typeof defaultViewState>;
}

const DashboardFiltersRow = (props: DashboardFiltersRowProps) => {
  return (
    <Grid container justifyContent="space-between" columns={12} alignItems="center" spacing={3}>
      <Grid item xs={6}>
        {props.label}
      </Grid>
      <Grid item xs={6} width="100%">
        {props.items.map(item => (
          <Grid key={item.key} item xs={1} mt={1}>
            <FormGroup>
              <FormControlLabel
                sx={{ minWidth: '300px' }}
                checked={props.view[item.key]}
                onChange={() => {
                  props.setView({ ...props.view, [item.key]: !props.view[item.key] });
                }}
                control={<IOSSwitch />}
                label={item.label}
              />
            </FormGroup>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

interface IDashboardFiltersProps {
  view: typeof defaultViewState;
  setView: Dispatch<typeof defaultViewState>;
  open: boolean;
  setOpen: Dispatch<boolean>;
}

const DashboardFilters = (props: IDashboardFiltersProps) => {
  const [localView, setLocalView] = useState<typeof props.view>({ ...props.view });

  return (
    <FilterDialog apply={() => props.setView(localView)} close={() => props.setOpen(false)} open={props.open}>
      <DashboardFiltersRow
        view={localView}
        setView={setLocalView}
        label="Количество"
        items={[
          { key: 'usersCount', label: 'Пользователей' },
        ]}
      />

      <Divider />

      <DashboardFiltersRow
        view={localView}
        setView={setLocalView}
        label="Чеки за все время"
        items={[
          { key: 'ordersCount', label: 'Всего чеков' },
          { key: 'averageAmount', label: 'Средний чек' },
        ]}
      />
    </FilterDialog>
  );
};

export default DashboardFilters;
