import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetClientLoader } from '../../../http/client/ClientLoaders';
import ClientShowForm from '../../../Widgets/Clients/ClientsTab/Operations/ClientsShowForm';

const ClientShow = () => {
  const { client } = useLoaderData() as IGetClientLoader;
  return (
    <Await resolve={client}>
      <ClientShowForm />
    </Await>
  );
};

export default ClientShow;
