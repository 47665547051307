import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import {ButtonProps} from '@mui/material/Button';
import AddTaskIcon from '@mui/icons-material/AddTask';

const FormSelectAllTweak = (props?: ButtonProps) => {
  return (
    <Tooltip title="Выбрать все">
      <IconButton {...props}>
        <AddTaskIcon color="primary" sx={{ fontSize: 20 }} />
      </IconButton>
    </Tooltip>
  );
};

export default FormSelectAllTweak;
