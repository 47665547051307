export enum MimeTypes {
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  SVG = 'image/svg+xml',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',
  RAR = 'application/x-rar-compressed'
}