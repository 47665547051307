import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaginated } from '../../../types/app/common/Paginated';

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PER_PAGE = 10;
export const DEFAULT_PER_PAGE_ROWS = [10, 25, 50, 75, 100];

export interface IFilters {
  like?: { [field: string]: any };
  eq?: { [field: string]: any };
  eqArray?: { [field: string]: any };
  inArray?: { [field: string]: any };
  from?: { [field: string]: number | null };
  to?: { [field: string]: number | null };
  in?: { [field: string]: any };
  comparison?: { [field: string]: string };
}

export interface IServerPaginationConfig<T> {
  isLoading: boolean;
  isInitialize: boolean;
  method: (request?: object) => Promise<IPaginated<T[]>>;
  items: T[];
  count: number;
  defaultFilters: IFilters;
  filters: IFilters;
  filtersCount: number;
  handlers: {
    mutator: (params: object) => {};
  };
  errorsByField: {[key: string]: string},
}

const initialState: IServerPaginationConfig<any> = {
  isLoading: false,
  isInitialize: false,
  method: null,
  items: null,
  count: 0,
  defaultFilters: null,
  filters: null,
  filtersCount: 0,
  handlers: {
    mutator: null,
  },
  errorsByField: null,
};

export const serverPaginationSlice = createSlice({
  initialState,
  name: 'serverConfigSlice',
  reducers: {
    init<T>(state: IServerPaginationConfig<T>) {
      state.isInitialize = true;
    },
    setIsLoading<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<boolean>) {
      state.isLoading = payload.payload;
    },
    setMutator<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<(params: object) => {}>) {
      state.handlers.mutator = payload.payload;
    },
    setDefaultFilters<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<IFilters>) {
      state.defaultFilters = payload.payload;
    },
    setFilters<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<IFilters>) {
      state.filters = payload.payload;
    },
    setFiltersCount<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<number>) {
      state.filtersCount = payload.payload;
    },
    setCount<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<number>) {
      state.count = payload.payload;
    },
    setServerItems<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<T[]>) {
      state.items = payload.payload;
    },
    setServerMethod<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<Function>) {
      state.method = payload.payload as (request?: object) => Promise<IPaginated<T[]>>;
    },
    setErrorsByField<T>(state: IServerPaginationConfig<T>, payload: PayloadAction<{[key: string] : string}>) {
      state.errorsByField = payload.payload;
    },
    clear: () => initialState,
  },
});

export default serverPaginationSlice.reducer;
