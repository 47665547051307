import React, {Dispatch, useEffect, useMemo, useState} from 'react';
import {IFraudSettingCreateDataset} from '../../../FraudMonitoringRuleSettingCreateForm';
import {Box, Grid} from '@mui/material';
import FormSwitch from '../../../../../../../UI/Form/FormSwitch';
import {IPartner} from '../../../../../../../types/app/partner/Partner';
import {PartnerService} from '../../../../../../../http/partner/PartnerService';
import CircularProgress from '@mui/material/CircularProgress';
import {IPartnerTerminal} from '../../../../../../../types/app/partner/PartnerTerminal';
import {ISelectable} from '../../../../../../../types/app/common/Selectable';

interface IFraudCreateFormStepFinishProps {
  dataset: IFraudSettingCreateDataset;
  dispatchDataset: Dispatch<IFraudSettingCreateDataset>;
}

const ReferralFraudCreateFormStepFinish = (props: IFraudCreateFormStepFinishProps) => {
  const [partners, setPartners] = useState<IPartner[]>(null);
  const [terminals, setTerminals] = useState<IPartnerTerminal[]>(null);

  useEffect(() => {
    props.dispatchDataset({
      ...props.dataset,
      partners: [],
      terminals: {},
    });

    PartnerService.getPartners().then(response => setPartners(response));
    PartnerService.getPartnerTerminals().then(response => setTerminals(response));
  }, []);

  const partnerTerminals = useMemo((): ISelectable[] => {
    if (!terminals) {
      return null;
    }

    if (props.dataset.partners.length === 0) {
      return [];
    }

    return terminals
      .filter(terminal => props.dataset.partners.find(partnerId => partnerId === terminal.partnerId))
      .map(terminal => ({ key: terminal.id, label: terminal.name }));
  }, [props.dataset.partners, terminals]);

  if (!partners || !partnerTerminals) {
    return (
      <Grid minHeight={200} container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }
  
  return (
    <Box minHeight={200}>
      <FormSwitch
        title="Активировать новую настройку сейчас"
        switchProps={{
          checked: props.dataset.isActive,
          onChange: () => {
            props.dispatchDataset({
              ...props.dataset,
              isActive: !props.dataset.isActive,
            })
          }
        }}
      />
    </Box>
  );
};

export default ReferralFraudCreateFormStepFinish;
