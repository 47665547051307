import {ITariff} from '../types/app/billing/bonuses/Tariff';
import {IPartner} from '../types/app/partner/Partner';
import React, {Dispatch} from 'react';
import {IRecalculatable} from '../types/app/common/Recalculatable';
import {IPromo} from '../types/app/billing/bonuses/Promo';
import EmptyCell from '../UI/Table/EmptyCell';
import {CertificateTypeCodes} from '../enums/CertificateTypes';
import {CertificateCategories} from '../enums/CertificateCategories';
import moment, {Moment} from 'moment';
import {getTariffTypeLabel} from '../enums/TariffTypes';

export const getType = (tariffOrPromo: ITariff | IPromo): string => {
  if (isPromo(tariffOrPromo)) {
    return 'Акция';
  }

  return  getTariffTypeLabel(tariffOrPromo.tariffType);
};

export const getPartner = (tariffOrPromo: ITariff | IPromo, partners: IPartner[]): string | React.ReactNode => {
  if (!isTariff(tariffOrPromo)) {
    return partners.find(partner => partner.key === tariffOrPromo.partnersIds[0])?.label ?? <EmptyCell />;
  }

  const ids = tariffOrPromo.partnersIds;
  const result = [];

  partners.forEach(partner => {
    if (ids.includes(partner.key)) {
      result.push(partner.label);
    }
  });

  if (!result.length) {
    return <EmptyCell />;
  }

  return result.join(', ').trim();
};

export const add = (state: IRecalculatable[], dispatch: Dispatch<IRecalculatable[]>) => {
  const clone = [...state];

  clone.push({
    index: '',
    qty: '',
  });

  dispatch(clone);
};

export const getMomentFromPromoDatetime = (timestamps: number | null): Moment | null => {
  if (!timestamps) {
    return null;
  }

  return moment.unix(timestamps);
}

export const getPromoProductsInitialByPromo = (promo: IPromo | null): null | IRecalculatable[] => {
  if (!promo?.isProductsRequired) {
    return null;
  }

  return promo.promoProducts.map(product => ({
    index: product.productId.toString(),
    qty: product.requiredQuantity.toString(),
  }))
}

export const getPromoCategoriesInitialByPromo = (promo: IPromo | null): null | IRecalculatable[] => {
  if (!promo?.isCategoriesRequired) {
    return null;
  }

  return promo.promoCategories.map(category => ({
    index: category.categoryId.toString(),
    qty: category.requiredQuantity.toString(),
  }))
}

export const getPointsByPromo = (promo: IPromo | null): number => {
  if (!promo) {
    return 1;
  }

  return (promo.pointsAdditionalAmount || promo.pointsMultiplier) ?? 1;
}

export const getCertificateInitCategoryByPromo = (promo: IPromo | null): CertificateCategories => {
  if (!promo) {
    return CertificateCategories.USER;
  }

  if (!promo.issueCertificate) {
    return CertificateCategories.USER;
  }

  if (promo.certificateParams.userEmail) {
    return CertificateCategories.USER;
  }

  return CertificateCategories.BEARER;
}

export const getCertificateInitTypeByPromo = (promo: IPromo | null): CertificateTypeCodes => {
  if (!promo) {
    return CertificateTypeCodes.NOMINAL;
  }

  if (!promo.issueCertificate) {
    return CertificateTypeCodes.NOMINAL;
  }

  return promo.certificateParams.type;
}

export const remove = (index: number, state: IRecalculatable[], dispatch: Dispatch<IRecalculatable[]>) => {
  const clone = [...state];

  clone.splice(index, 1);

  dispatch(clone);
};

export const isMapperEmpty = (mapper:  IRecalculatable[]): boolean => {
  return mapper.find(item => item.index !== '') === undefined;
}

export const isPromo = (object: IPromo | ITariff): object is IPromo => {
  return (object as IPromo).type === 'promo';
}

export const isTariff = (object: IPromo | ITariff): object is ITariff => {
  return (object as ITariff).type === 'tariff';
}

export const getFlushRecalculatable = (): IRecalculatable[] => {
  return [
    {
      index: '',
      qty: '',
    },
  ];
}

export const getPromoStatusName = (promo: IPromo): string => {
  const currentDate = moment(new Date()).unix();

  if (!promo.active) {
    return 'Отменена';
  }

  if (currentDate < promo.validFrom) {
    return 'Новая';
  }

  if (currentDate >= promo.validFrom) {
    return 'Действует';
  }

  if (currentDate < promo.validTo) {
    return 'Отменена';
  }

  return 'Завершена';
}

export const getPromoStatusColor = (promo: IPromo): string => {
  const currentDate = moment(new Date()).unix();

  if (!promo.active) {
    return '#B03A3A';
  }

  if (currentDate < promo.validFrom) {
    return '#1997D6';
  }

  if (currentDate >= promo.validFrom) {
    return '#00AA1B';
  }

  if (currentDate < promo.validTo) {
    return '#B03A3A';
  }

  return '#dfb128';
}