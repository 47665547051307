import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import TabLoader from '../../Common/Loader/TabLoader';
import { ClientService } from '../../../http/client/ClientService';
import ClientsTabContentData from './ClientsTabContentData';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import { IClientRank } from '../../../types/app/client/ClientRank';
import ClientsTabDataTweaks from './ClientsTabDataTweaks';
import ClientsTabFilters from './ClientTabFilters';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import { IClient } from '../../../types/app/client/Client';
import { unix } from 'moment/moment';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilterState: IFilters = {
  like: {
    ids: '',
    clientRankId: '',
    searchByEmail: '',
    searchByName: '',
  },
  from: {
    createdAt: null,
    updatedAt: null,
  },
  to: {
    createdAt: null,
    updatedAt: null,
  },
  eq: {
    clientRankId: '',
  },
};

const ClientsTabContentMain = () => {
  const { getPaginatable, getDialogFilterable, getFilterable, items, setFilters, setServerMethod, request, setMutator } =
    useTweakTool<IClient, typeof defaultFilterState>();

  const [ranks, setRanks] = useState<IClientRank[]>(null);

  useEffect(() => {
    setMutator(params => {
      if (params.ids) {
        params.ids = params.ids.split().map(Number)
      }

      if (params.created_at_start) {
        params.created_at_start = unix(params.created_at_start).format('YYYY-MM-DD');
      }

      if (params.created_at_end) {
        params.created_at_end = unix(params.created_at_end).format('YYYY-MM-DD');
      }

      if (params.updated_at_start) {
        params.updated_at_start = unix(params.updated_at_start).format('YYYY-MM-DD');
      }

      if (params.updated_at_end) {
        params.updated_at_end = unix(params.updated_at_end).format('YYYY-MM-DD');
      }

      return params;
    });
    setServerMethod(ClientService.getClientsData);
    ClientService.getClientRanks().then(ranks => setRanks(ranks));
    setFilters(defaultFilterState);
  }, []);

  if (!items || !ranks) {
    return <TabLoader />;
  }

  return (
    <Box>
      <TabTitle title="Профили Пользователей" />

      <ClientsTabFilters ranks={ranks} {...getDialogFilterable} />

      <ClientsTabDataTweaks {...getFilterable} />

      <Divider />

      <ClientsTabContentData ranks={ranks} {...getPaginatable} request={request} />
    </Box>
  );
};

export default ClientsTabContentMain;
