import React from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import {IDialogFilterable} from '../../../types/app/common/DialogFilterable';
import {defaultFilterState} from './AntispamTabMain';
import Divider from '../../../UI/Delimiter/Divider';
import {IGroupsTemplate} from '../../../types/app/communication/GroupsTemplate';
import FilterMultiCheckbox from "../../Common/Filter/FilterMultiCheckbox";
import {FilterMultiCheckboxes} from "../../../enums/FilterMultiCheckboxes";
import {getGroupTypeTranslation} from "../../../enums/Groups";

interface IAntispamTabFiltersProps extends IDialogFilterable<typeof defaultFilterState>{
  groups: IGroupsTemplate[]
}

const AntispamTabFilters = (props: IAntispamTabFiltersProps) => {
  return(
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      {props.groups && (
        <FilterMultiCheckbox
          label='Для кого'
          field='groups'
          items={props.groups.map(group => ({ key: group.id, label: getGroupTypeTranslation(group.name) }))}
          addFilter={props.addFilter}
          filters={props.filters}
          type={FilterMultiCheckboxes.IN_ARRAY}
        />
      )}
      <Divider />
  </FilterDialog>
  );
};

export default AntispamTabFilters;
