import React, {useState, useEffect, useContext} from 'react';
import {Box, Button, Card, Grid, InputAdornment, Typography} from '@mui/material';
import Form from '../../../UI/Form/Form';
import { FormContext, IFormConfig } from '../../../store/context/form/FormContext';
import { RouteList } from '../../../http/RouteList';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import FormFullButton from '../../../UI/Button/FormFullButton';
import {ReactComponent as UserIcon} from '../../../icons/input-user.svg';
import {Link} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {snackConfig} from '../../../layout/assets/config/snackConfig';
import { IFormResponse } from '../../../store/context/form/types/FormResponse';

const ForgotPassword = () => {
  const { setConfig } = useContext(FormContext);
  const {enqueueSnackbar} = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formConfig: IFormConfig = {
    rules: {
      email: ['required', 'email'],
    },
    action: RouteList.AUTH_FORGOT_PASSWORD,
    beforeSubmitHandler: () => setIsLoading(true),
    afterSubmitHandler: (formResponse: IFormResponse) => {
      setIsLoading(false);

      if (formResponse.status) {
        enqueueSnackbar(
          'На указанный адрес электронной почты было направлено письмо для восстановления пароля.',
          {
            variant: 'info',
            autoHideDuration: snackConfig.duration,
          });
      }
    },
  };

  useEffect(() => {
    setConfig(formConfig);
  }, []);

  return (
    <Grid
      container
      columns={12}
      justifyContent="center"
    >
      <Grid
        item
        xs
        sm={8}
        md={7}
        lg={5}
        xl={4}
      >
        <Grid
          container
          bgcolor="white"
          width="100%"
          borderRadius={4}
          component={Card}
          padding={5}
          pb={5}
          maxWidth={720}
          margin="auto"
        >
          <Grid
            item
            xs
            margin="auto"
            sm={8}
            pt={5}
            minWidth={200}
          >
            <Box>
              <Form
                redirect={RouteList.DASHBOARD}
              >
                <Grid container justifyContent="center">
                  <Box marginBottom={5}>
                    <Typography textAlign="center" variant="h1">
                      Восстановление&nbsp;пароля

                    </Typography>
                  </Box>
                </Grid>
                <Grid container flexDirection="column">
                  <Grid item width="100%">
                    <FormOutlinedInput
                      inputProps={{
                        sx: {
                          '& > input': {
                            padding: '15px 10px',
                          },
                        },
                        startAdornment: (
                          <InputAdornment sx={{padding: '0 10px'}} position="start">
                            <UserIcon/>
                          </InputAdornment>
                        ),
                        type: 'text',
                        placeholder: 'Введите логин',
                        name: 'email',
                        autoComplete: 'on',
                      }}
                    />
                  </Grid>
                </Grid>

                <FormFullButton loading={isLoading} label="Восстановить"/>
              </Form>
            </Box>

            <Grid container justifyContent="center" mt={2}>
              <Grid item>
                <Grid container justifyContent="space-between" columns={2} spacing={3}>
                  <Grid item xs>
                    <Link to={RouteList.AUTH_LOGIN}>
                      <Button variant="text">
                        Вход в систему
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;