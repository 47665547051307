import {ISelectable} from '../types/app/common/Selectable';

export enum CertificateCategories {
  USER,
  BEARER,
}

export namespace CertificateCategories {
  export const asSelectable = (): ISelectable[] => {
    return [
      {key: CertificateCategories.USER, label: 'Персонализированный'},
      {key: CertificateCategories.BEARER, label: 'На предъявителя'}
    ]
  }
}