import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import ClientRanksTabContentData from './ClientRanksTabContentData';
import {IClientRank} from '../../../types/app/client/ClientRank';
import {ClientService} from '../../../http/client/ClientService';
import TabLoader from '../../Common/Loader/TabLoader';
import {BillingService} from '../../../http/billing/BillingService';
import {IClientRankSetting} from '../../../types/app/billing/ClientRankSetting';
import RanksTabDataTweaks from './RanksTabDataTweaks';
import Divider from '../../../UI/Delimiter/Divider';

const ClientRanksTabContentMain = () => {
  const [clientRanks, setClientRanks] = useState<IClientRank[]>(null);
  const [clientRankSettings, setClientRankSettings] = useState<IClientRankSetting[]>(null);

  useEffect(() => {
    ClientService
      .getClientRanks()
      .then(ranks => {
        setClientRanks(ranks);
      })

    BillingService
      .getClientRankSettings()
      .then(settings => {
        setClientRankSettings(settings);
      })

}, []);

if (!clientRanks || !clientRankSettings) {
  return <TabLoader/>;
}

return (
  <Box>
    <TabTitle title="Статусная модель"/>

    <RanksTabDataTweaks/>

    <Divider />

    <ClientRanksTabContentData settings={clientRankSettings} items={clientRanks}/>
  </Box>
);
}
;

export default ClientRanksTabContentMain;
