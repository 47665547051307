import { defer } from 'react-router-dom';
import { ClientService } from './ClientService';
import {IClientDetail} from '../../types/app/client/ClientDetail';
import {IGetClientRankRequest} from '../../types/request/client/GetClientRankRequest';
import {IClientRank} from '../../types/app/client/ClientRank';
import {IGetClientRequest} from '../../types/request/account/GetClientRequest';

export interface IGetClientLoader {
  client: IClientDetail;
}

export interface IGetClientRankLoader {
  clientRank: IClientRank;
}

export class ClientLoaders {

  /**
   * Client rank show loader
   *
   * @param params
   */
  public static getClientRank = async ({ params }) => {
    const request: IGetClientRankRequest = {
      client_rank_id: Number(params.id),
    }

    return defer({
      clientRank: ClientService.getClientRank(request),
    })
  }

  /**
   * Client loader
   *
   * @param params
   */
  public static getClient = async ({ params }) => {
    const request: IGetClientRequest = {
      id: params.id,
    };

    return defer({
      client: ClientService.fetchClient(request),
    });
  };
}
