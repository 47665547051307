import React, { useState } from 'react';
import { IPaginatable } from '../../../types/app/common/Paginatable';
import TableContainer from '../../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ShowButton from '../../../UI/Button/ShowButton';
import { getColorByTemplateType, getNameByTemplateType, isSystemTemplate } from '../../../enums/TemplateTypes';
import { Grid } from '@mui/material';
import { Image } from 'mui-image';
import { RouteList } from '../../../http/RouteList';
import DeleteButton from '../../../UI/Button/DeleteButton';
import Confirm from '../../Common/Modal/Confirm';
import { CommunicationService } from '../../../http/communication/CommunicationService';
import { useSnackbar } from 'notistack';
import { snackConfig } from '../../../layout/assets/config/snackConfig';
import { ITemplate } from '../../../types/app/communication/Template';

interface ITemplateTabDataProps extends IPaginatable<ITemplate> {
  dispatch: Function;
}

const TemplateTabData = (props: ITemplateTabDataProps) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templateToDelete, setTemplateToDelete] = useState<ITemplate>(null);

  const { enqueueSnackbar } = useSnackbar();

  const deleteTemplateHandler = async () => {
    setIsLoading(true);

    await CommunicationService.deleteTemplate({
      id: templateToDelete.id,
    });

    props.dispatch(props.items.filter(item => item.id !== templateToDelete.id));

    setIsLoading(false);
    closeConfirm();

    enqueueSnackbar('Шаблон для обращений был удален.', {
      variant: 'info',
      autoHideDuration: snackConfig.duration,
    })
  };

  const openConfirm = (template: ITemplate) => {
    setTemplateToDelete(template);
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
    setTemplateToDelete(null);
  };

  return (
    <>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteTemplateHandler}
        text='Вы действительно хотите удалить шаблон? Данное действие нельзя будет отменить.'
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={100}>Изображение</TableCell>
              <TableCell width={150}>Заголовок</TableCell>
              <TableCell width={400}>Текст</TableCell>
              <TableCell width={200}>Категория</TableCell>
              <TableCell width={150}>Тип шаблона</TableCell>
              <TableCell width={150}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map(template => (
              <TableRow key={template.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {
                    <Grid container justifyContent="center">
                      <Image width={22} duration={0} src={template.web.icon} />
                    </Grid>
                  }
                </TableCell>
                <TableCell>{template.web.header}</TableCell>
                <TableCell>{template.web.short}</TableCell>
                <TableCell>{template.web.category}</TableCell>
                <TableCell
                  sx={{
                    color: getColorByTemplateType(template.type),
                  }}
                >
                  {getNameByTemplateType(template.type)}
                </TableCell>
                <TableCell>
                  {!isSystemTemplate(template.type) && (
                    <Grid container justifyContent="flex-end" flexDirection="row">
                      <Grid item>
                        <ShowButton linkProps={{ to: RouteList.NOTIFICATIONS_TEMPLATE_SHOW + '/' + template.id }} />
                      </Grid>
                      <Grid item>
                        <DeleteButton onClick={() => openConfirm(template)} />
                      </Grid>
                    </Grid>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TemplateTabData;
