import React from 'react';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import { IFilter } from '../../../hooks/table/useFilters';
import {Box, Grid, OutlinedInputProps} from '@mui/material';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import {useAppSelector} from '../../../hooks/hooks';
import FormErrorText from '../../../UI/Form/FormErrorText';

interface IFilterInputProps {
  placeholder?: string;
  addFilter: (filter: IFilter) => void;
  field: string;
  filters: IFilters;
  label: string;
  rule?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => boolean;
  inputProps?: OutlinedInputProps;
  type?: 'like' | 'eq';
}

const FilterInput = (props: IFilterInputProps) => {
  const errorsByField = useAppSelector(state => state.serverConfig.errorsByField);

  if (!props.filters) {
    throw new Error('Filters is not initialized');
  }

  const type = props.type ?? 'like';

  return (
    <Grid alignItems="center" container justifyContent="space-between" columns={12} spacing={1}>
      <Grid item xs={4} position="relative">
        {props.label}
      </Grid>
      <Grid item xs={8}>
        <FormOutlinedInput
          inputProps={{
            error: !!(errorsByField && errorsByField[props.field]),
            fullWidth: true,
            onChange: e => {
              let isValid = true;

              if (props.rule !== undefined) {
                isValid = props.rule(e);
              }

              if (!isValid) {
                return;
              }

              props.addFilter({ type, name: props.field, value: e.target.value });
            },
            value: props.filters[type][props.field],
            placeholder: props.placeholder,
            sx: {
              borderRadius: 2,
              '& input': {
                padding: '8px',
                paddingLeft: '16px',
              },
              '& input::placeholder': {
                fontSize: 14,
              },
            },
            ...props.inputProps,
          }}
        />
        <Box>
          {(errorsByField && errorsByField[props.field]) && <FormErrorText error={errorsByField[props.field]}/>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default FilterInput;
