import React from 'react'
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RouteList } from '../RouteList';

const ErrorBoundary = () => {
  const navigate = useNavigate()

  return(
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <Typography variant="h1" mb={6}>Извините, что-то пошло не так.</Typography>
      <Box display="flex" gap={4}>
        <Button onClick={() => navigate(window.location.pathname, { replace: true })}>Обновить</Button>
        <Button variant="outlined" onClick={() => navigate(RouteList.DASHBOARD)}>На главную</Button>
      </Box>
    </Box>
  );
}

export default ErrorBoundary;