import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { IClientRank } from '../../../types/app/client/ClientRank';
import ShowButton from '../../../UI/Button/ShowButton';
import { Image } from 'mui-image';
import CircleIcon from '@mui/icons-material/Circle';
import { getClientSettingPercentByRank } from '../../../utils/clientUtils';
import { RouteList } from '../../../http/RouteList';
import { IClientRankSetting } from '../../../types/app/billing/ClientRankSetting';

interface IClientRanksTabContentDataProps {
  items: IClientRank[];
  settings: IClientRankSetting[];
}

const ClientRanksTabContentData = (props: IClientRanksTabContentDataProps) => {

  return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Наименование статуса</TableCell>
            <TableCell>Поощрения для данного статуса</TableCell>
            <TableCell>Критерий статуса</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(rank => (
            <TableRow key={rank.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>
                <Image duration={0} height={50} width={32} src={rank.icon ? rank.icon : ''} />
              </TableCell>
              <TableCell>{rank.name}</TableCell>
              <TableCell>Скидка {getClientSettingPercentByRank(rank, props.settings)} %</TableCell>
              <TableCell>
                <List>
                  {rank.minExpenses !== 0 && (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <CircleIcon sx={{ color: 'grey.A700', fontSize: 7 }} />
                      </ListItemIcon>
                      <ListItemText sx={{ ml: 1 }}>Минимальный оборот денежных средств - {rank.minExpenses} ₽</ListItemText>
                    </ListItem>
                  )}

                  {rank.minReferrals !== 0 && (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <CircleIcon sx={{ color: 'grey.A700', fontSize: 7 }} />
                      </ListItemIcon>
                      <ListItemText sx={{ ml: 1 }}>Минимальное количество рефералов - {rank.minReferrals}</ListItemText>
                    </ListItem>
                  )}

                  {rank.minDirectReferrals !== 0 && (
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <CircleIcon sx={{ color: 'grey.A700', fontSize: 7 }} />
                      </ListItemIcon>
                      <ListItemText sx={{ ml: 1 }}>
                        Минимальное количество прямых рефералов - {rank.minDirectReferrals}
                      </ListItemText>
                    </ListItem>
                  )}
                </List>
              </TableCell>
              <TableCell>
                <ShowButton linkProps={{to: RouteList.CLIENTS_RANK_SHOW + '/' + rank.id}} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
};

export default ClientRanksTabContentData;
