import React from 'react';
import { useAsyncValue } from 'react-router-dom';
import { FraudRuleReportIds } from '../../../../enums/FraudRules';
import BigTransactionFraudForm from './BigTransactionFraudForm';
import ReferralFraudForm from './ReferralFraudForm';
import SalesFraudForm from './SalesFraudForm';
import { IRuleSetting } from '../../../../types/app/fraud/RuleSetting';
import MoneyBackFraudForm from './MoneyBackFraudForm';

const FraudMonitoringRuleSettingShowForm = () => {
  const ruleSetting = useAsyncValue() as IRuleSetting;

  switch (ruleSetting.rule.id) {
    case FraudRuleReportIds.BIG_TRANSACTION_FRAUD:
      return <BigTransactionFraudForm {...ruleSetting} />;
    case FraudRuleReportIds.MONEY_BACK_FRAUD:
      return <MoneyBackFraudForm {...ruleSetting} />;
    case FraudRuleReportIds.REFERRAL_FRAUD:
      return <ReferralFraudForm {...ruleSetting} />;
    default:
      return <SalesFraudForm {...ruleSetting} />;
  }
};

export default FraudMonitoringRuleSettingShowForm;
