import moment from 'moment';
import {IOrder} from '../../types/app/shop/Order';
import { TOrder } from '../../types/response/shop/OrdersData';
import {TOrderData, TOrderItem, TOrderTransaction} from '../../types/response/shop/OrderData';
import {IOrderItem, IOrderTransaction, IOrderDetail} from '../../types/app/shop/OrderDetail';

export class ShopFactory {
  /**
   * Get orders from response data
   *
   * @param {TOrder[]} ordersData
   */
  public static getOrdersFromResponseData(ordersData: TOrder[]): IOrder[] {
    return ordersData.map(orderItemData => ({
      id: orderItemData.id,
      showNumber: orderItemData.show_number,
      storageId: orderItemData.storage_id,
      userId: orderItemData.user_id,
      partnerId: orderItemData.partner_id,
      statusId: orderItemData.status_id,
      statusName: orderItemData.status_name,
      amount: orderItemData.amount,
      usedBonus: orderItemData.used_bonus === '1',
      discountAmount: Number(orderItemData.discount_amount),
      accrualBonus: Number(orderItemData.accrual_bonus),
      orderAmount: Number(orderItemData.order_amount),
      paymentAmount: Number(orderItemData.payment_amount),
      receiverName: orderItemData.receiver_name,
      mobile: orderItemData.mobile,
      zipCode: orderItemData.zip_code,
      countryId: orderItemData.country_id,
      isRecurrent: orderItemData.is_recurrent === 1,
      transactionId: orderItemData.transaction_id,
      recurrentOrderId: orderItemData.recurrent_order_id,
      recurrentPaidAt: orderItemData.recurrent_paid_at ? moment(orderItemData.recurrent_paid_at).unix() : null,
      recurrentPaidAtDate: orderItemData.recurrent_paid_at_date ? moment(orderItemData.recurrent_paid_at_date, 'YYYY-MM-DD').unix() : null,
      recurrentPaidAtTime: orderItemData.recurrent_paid_at_time ? moment(orderItemData.recurrent_paid_at_time, 'HH:mm:ss').unix() : null,
      paidAt: orderItemData.paid_at ? moment(orderItemData.paid_at).unix() : null,
      paidAtDate: orderItemData.paid_at_date ? moment(orderItemData.paid_at_date).unix() : null,
      paidAtTime: orderItemData.paid_at_time ? moment(orderItemData.paid_at_time, 'HH:mm:ss').unix() : null,
      createdAt: orderItemData.created_at ? moment(orderItemData.created_at).unix() : null,
      createdAtDate: orderItemData.created_at ? moment(orderItemData.created_at_date, 'YYYY-MM-DD').unix() : null,
      createdAtTime: moment({
        date: moment(0).date(),
        hours: moment(orderItemData.created_at).hours(),
        minutes: moment(orderItemData.created_at).minutes(),
      }).unix(),
      updatedAt: orderItemData.updated_at ? moment(orderItemData.updated_at).unix() : null,
      updatedAtDate: orderItemData.updated_at_date ? moment(orderItemData.updated_at_date, 'YYYY-MM-DD').unix() : null,
      updatedAtTime: orderItemData.updated_at_time ? moment(orderItemData.updated_at_time, 'HH:mm:ss').unix() : null,
      address: orderItemData.address
    }));
  }

  /**
   * Get orders from response data
   *
   * @param {TOrderData} data
   */
  public static getOrderItemFromResponseData(data: TOrderData): IOrderDetail {
    return {
      discountAmount: data.discount_amount,
      id: data.id,
      showNumber: data.show_number,
      storageId: data.storage_id,
      userId: data.user_id,
      partnerId: data.partner_id,
      statusId: data.status_id,
      statusName: data.status_name,
      amount: Number(data.amount),
      usedBonus: Number(data.used_bonus),
      receiverName: data.receiver_name,
      mobile: data.mobile,
      zipCode: data.zip_code,
      countryId: data.country_id,
      isRecurrent: data.is_recurrent === 1,
      transaction: this.getOrderTransactionFromData(data.transaction),
      transactionId: data.transaction_id,
      recurrentOrderId: data.recurrent_order_id,
      recurrentPaidAt: data.recurrent_paid_at ? moment(data.recurrent_paid_at).unix() : null,
      paidAt: data.paid_at ? moment(data.paid_at).unix() : null,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
      certificateId: data.certificate_id,
      certificateUsedAmount: Number(data.certificate_used_amount),
      address: data.address,
      orderItems: data.order_items.map(orderItemData => this.getOrderItemFromData(orderItemData)),
      shipments: data.shipments.map(shipmentItem => ({
        createdAt: shipmentItem.created_at ? moment(shipmentItem.created_at).unix() : null,
        deliveryType: shipmentItem.delivery_type,
        description: shipmentItem.description,
        fileUrl: shipmentItem.file_url,
        id: shipmentItem.id,
        orderId: shipmentItem.order_id,
        status: shipmentItem.status,
        updated_At: shipmentItem.updated_at ? moment(shipmentItem.updated_at).unix() : null,
      })),
      orderAmount: data.order_amount,
      paymentAmount: data.payment_amount,
      accrualBonus: data.accrual_bonus,
      terminalName: data.terminal_name
    };
  }

  /**
   * Get transaction for getOrderItemFromResponseData
   *
   * @param {TOrderTransaction} transactionData
   * @private
   */
  private static getOrderTransactionFromData = (transactionData: TOrderTransaction): IOrderTransaction => {
    return {
      amount: Number(transactionData.amount),
      certificateUsedAmount: Number(transactionData.certificate_used_amount),
      id: transactionData.id,
      description: transactionData.description,
      createdAt: transactionData.created_at ? moment(transactionData.created_at).unix() : null,
      updatedAt: transactionData.updated_at ? moment(transactionData.updated_at).unix() : null,
      userId: transactionData.user_id,
      place: transactionData.place,
      orderAmount: Number(transactionData.order_amount),
      discount: Number(transactionData.discount),
      bonusAmount: Number(transactionData.bonus_amount),
      usedPoints: Number(transactionData.used_points),
      certificateId: transactionData.certificate_id,
      partnerId: transactionData.partner_id,
      objName: transactionData.obj_name,
      objId: transactionData.obj_id,
      transactionTypeId: transactionData.transaction_type_id,
      orderId: transactionData.order_id,
      statusId: transactionData.status_id,
      paymentAmount: Number(transactionData.payment_amount),
      pointsLifetime: transactionData.points_lifetime,
      pointRate: Number(transactionData.point_rate),
      rubRate: Number(transactionData.rub_rate),
      userFromId: transactionData.user_id,
      orderNumber: transactionData.order_number
    }
  }

  /**
   * Get order item for getOrderItemFromResponseData
   *
   * @param {TOrderItem} orderItemData
   * @private
   */
  private static getOrderItemFromData = (orderItemData: TOrderItem): IOrderItem => {
    return {
      id: orderItemData.id,
      orderId: orderItemData.order_id,
      productId: orderItemData.product_id,
      variationId: orderItemData.variation_id,
      categoryId: orderItemData.category_id,
      partnerId: orderItemData.partner_id,
      productName: orderItemData.product_name,
      isRecurrent: orderItemData.is_recurrent === 1,
      recurrentPeriod: orderItemData.recurrent_period,
      price: Number(orderItemData.price),
      usedBonus: Number(orderItemData.used_bonus),
      discountAmount: Number(orderItemData.discount_amount),
      accrualBonus: Number(orderItemData.accrual_bonus),
      quantity: orderItemData.quantity,
      createdAt: orderItemData.created_at ? moment(orderItemData.created_at).unix() : null,
      updatedAt: orderItemData.updated_at ? moment(orderItemData.updated_at).unix() : null,
      certificateId: orderItemData.certificate_id,
      certificateUsedAmount: Number(orderItemData.certificate_used_amount),
      delivery_type: orderItemData.delivery_type,
      shipment_id: orderItemData.shipment_id,
      status_id: orderItemData.status_id
    }
  }
}
