import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { ISelectable } from '../../../types/app/common/Selectable';
import { IFilter } from '../../../hooks/table/useFilters';
import ClearIcon from '@mui/icons-material/Clear';
import SearchSelect from '../../../UI/SearchSelect';
import { isNumber } from 'lodash';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';

interface FilterSelectProps {
  label: string;
  items: ISelectable[];
  addFilter: (filter: IFilter) => void;
  field: string;
  filters: IFilters;
  placeholder?: string;
  type?: 'eq' | 'in';
}

const FilterSelect = (props: FilterSelectProps) => {
  const type = props.type ?? 'eq';
  const reset = () => props.addFilter({ type: type, name: props.field, value: '' });

  return (
    <Grid container justifyContent="space-between" columns={12} alignItems="center" spacing={1}>
      <Grid item xs={3} position="relative">
        {props.label}
      </Grid>
      <Grid item xs={8} position="relative">
        {(props.filters[type][props.field] || isNumber(props.filters[type][props.field])) && (
          <IconButton onClick={reset} sx={{ position: 'absolute', top: 17, right: 25, zIndex: 100 }}>
            <ClearIcon color="error" sx={{ fontSize: 20 }} />
          </IconButton>
        )}
        <SearchSelect
          items={props.items}
          placeholder={props.placeholder}
          fieldProps={{
            onChange: () => reset(),
          }}
          selectProps={{
            fullWidth: true,
            value: props.filters[type][props.field] ?? '',
            label: <Typography sx={{ opacity: 0.42, fontSize: 14, mt: '2px' }} children={props.label} />,
            onChange: e => props.addFilter({ type: type, name: props.field, value: e.target.value }),
            sx: {
              borderRadius: 2,
              '& .MuiSelect-outlined': {
                padding: '8px',
              },
              '& input': {
                paddingLeft: '16px !important',
              },
              '& input::placeholder': {
                fontSize: 14,
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FilterSelect;
