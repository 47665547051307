import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginLayout from '../../layout/LoginLayout';
import { RouteList } from '../../http/RouteList';
import { useAppSelector } from '../../hooks/hooks';

function Index() {
  const user = useAppSelector(state => state.auth.user);

  if (user) {
    return <Navigate to={RouteList.DASHBOARD} />;
  }

  return <LoginLayout />;
}

export default Index;
