import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useNotifications} from '../../../hooks/useNotifications';
import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import {messages} from '../../../notifications/messages';
import {MimeTypes} from '../../../enums/MimeTypes';
import LoadItemIcon from '@mui/icons-material/UploadFile';
import {IStorageUploadRequest} from '../../../types/request/storage/StorageUploadRequest';
import {StorageTypes} from '../../../enums/StorageTypes';
import {FileHelper} from '../../../helpers/FileHelper';
import {StorageService} from '../../../http/storage/StorageService';
import ArchiveIcon from '@mui/icons-material/Archive';
import {UploadProductRequest} from '../../../types/request/catalog/UploadProductRequest';
import {CatalogService} from '../../../http/catalog/CatalogService';

interface CatalogModalProps {
  open: boolean;
  close: () => void;
  title: string;
}

const CatalogModal = (props: CatalogModalProps) => {

  const initialFileUploadRequest = {
    products_url: '',
    products_images_url: '',
    products_mobile_images_url: ''
  }

  const fileRef = useRef<HTMLInputElement>(null);
  const imagesRef = useRef<HTMLInputElement>(null);
  const mobileRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [isImagesLoading, setIsImagesFileLoading] = useState<boolean>(false);
  const [isMobileFileLoading, setIsMobileFileLoading] = useState<boolean>(false);

  const [fileName, setFileName] = useState<string>('');
  const [archiveName, setArchiveName] = useState<string>('');
  const [archiveMobileName, setArchiveMobileName] = useState<string>('');

  const [uploadFilesRequest, setUploadFilesRequest] = useState<UploadProductRequest>(initialFileUploadRequest);

  const {error, success} = useNotifications();

  const onChangeHandler = async (e: ChangeEvent<HTMLInputElement>, fileType: string) => {

    if (e.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(e.target.files[0]);

      reader.onloadend = async () => {

        switch (fileType) {
          case 'file':
            const uploadRequest: IStorageUploadRequest = {
              type: StorageTypes.IMPORTS,
              id: Math.random().toString(),
              filename: e.target.files[0].name,
              data: FileHelper.base64RequestFormat(reader.result as string),
            };

            try {
              setIsFileLoading(true);
              const fromStorageFileData = await StorageService.upload(uploadRequest);

              setUploadFilesRequest({
                ...uploadFilesRequest,
                products_url: fromStorageFileData.url
              })

              success(messages.success.catalogProductUpload);
            }
            catch (e) {
              error(messages.errors.generalError);
            }
            finally {
              setIsFileLoading(false);
              setFileName(e.target.files[0].name);
              e.target.value = null;
            }
            break;

          case 'images':

            const uploadImagesRequest: IStorageUploadRequest = {
              type: StorageTypes.IMPORTS,
              id: Math.random().toString(),
              filename: e.target.files[0].name,
              data: FileHelper.base64RequestFormat(reader.result as string),
            };

            try {
              setIsImagesFileLoading(true);

              const fromStorageFileData = await StorageService.upload(uploadImagesRequest);

              setUploadFilesRequest({
                ...uploadFilesRequest,
              products_images_url: fromStorageFileData.url
              })

              success(messages.success.catalogProductUpload);
            }
            catch (e) {
              error(messages.errors.generalError);
            }
            finally {
              setIsImagesFileLoading(false);
              setArchiveName(e.target.files[0].name);
              e.target.value = null;
            }
            break;

          case 'mobile':

            const uploadMobileRequest: IStorageUploadRequest = {
              type: StorageTypes.IMPORTS,
              id: Math.random().toString(),
              filename: e.target.files[0].name,
              data: FileHelper.base64RequestFormat(reader.result as string),
            };

            try {
              setIsMobileFileLoading(true);
              const fromStorageFileData = await StorageService.upload(uploadMobileRequest);

              setUploadFilesRequest({
                ...uploadFilesRequest,
                products_mobile_images_url: fromStorageFileData.url
              })

              success(messages.success.catalogProductUpload);
            }
            catch (e) {
              error(messages.errors.generalError);
            }
            finally {
              setIsMobileFileLoading(false);
              setArchiveMobileName(e.target.files[0].name);
              e.target.value = null;
            }
            break;
        }
      };
    }

  };

  const onCloseHandler = () => {
    setFileName('')
    setArchiveName('')
    setArchiveMobileName('')
    props.close();
  };

  const makeOperation = async (): Promise<void> => {
    setIsLoading(true);

    if(!uploadFilesRequest.products_mobile_images_url){
      delete uploadFilesRequest.products_mobile_images_url
    }
    if(!uploadFilesRequest.products_images_url) {
      delete uploadFilesRequest.products_images_url
    }

    try {
      await CatalogService.uploadProductItem(uploadFilesRequest);

      success(messages.success.catalogProductUpload);

      setFileName('')
      setArchiveName('')
      setArchiveMobileName('')
      props.close();
    }
    catch (e) {
      error(e.message);
    }
    finally {
      setIsLoading(false);
    }
  };

useEffect(()=>{
  setUploadFilesRequest(initialFileUploadRequest)
}, [props.open])

  return (

    <Dialog
      open={props.open}
      onKeyUp={e => {
        if (e.key === 'Escape') {
          onCloseHandler();
        }
      }}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          onCloseHandler();
        }
      }}
    >

      <Box padding="25px 25px 35px 25px" display="flex" flexDirection="column" alignItems="start">
        <Box position="absolute" zIndex={100} right={15} top={15}>
          <IconButton onClick={onCloseHandler}>
            <CloseIcon sx={{fontSize: 18}}/>
          </IconButton>
        </Box>

        <DialogTitle>
          <Typography component="span" variant="subtitle1" mb={2}>
            {props.title}
          </Typography>
        </DialogTitle>

        <DialogContent>

          <Grid container display="flex" flexDirection="column" columns={1}>
            <Grid item>
              <Typography variant='body1'>
                Загрузить файл xlsx со списком товаров
              </Typography>
            </Grid>
            <Grid item display="flex" mt={1}>
              <Button
                disabled={isFileLoading}
                endIcon={<LoadItemIcon/>}
                onClick={() => fileRef && fileRef.current && fileRef.current.click()
              }>
                Загрузить XLSX
                <input ref={fileRef} type="file" accept={MimeTypes.XLSX} style={{display: 'none'}}
                       onChange={(e) => onChangeHandler(e, 'file')}/>
              </Button>

              {isFileLoading && <Typography ml={2} mt={1}>Загрузка...</Typography>}
              {fileName && <Typography ml={2} mt={1}>{fileName}</Typography>}
            </Grid>


            <Grid item  mt={4}>
              <Typography variant='body1'>
                Загрузить zip-архив с изображениями
              </Typography>
            </Grid>
            <Grid item display="flex" mt={1}>
              <Button
                disabled={isImagesLoading}
                endIcon={<ArchiveIcon/>}
                onClick={() => imagesRef && imagesRef.current && imagesRef.current.click()}
              >
                Загрузить архив
                <input ref={imagesRef}
                       type="file"
                       accept={MimeTypes.ZIP}
                       style={{display: 'none'}}
                       onChange={(e) => onChangeHandler(e, 'images')}/>
              </Button>

              {isImagesLoading && <Typography ml={2} mt={1}>Загрузка...</Typography>}
              {archiveName && <Typography ml={2} mt={1}>{archiveName}</Typography>}
            </Grid>

            <Grid item  mt={4}>
              <Typography variant='body1'>
                Загрузить zip-архив с изображениями для мобильного
              </Typography>
            </Grid>
            <Grid item display="flex"  mt={1}>
              <Button
                disabled={isMobileFileLoading}
                endIcon={<ArchiveIcon/>}
                onClick={() => mobileRef && mobileRef.current && mobileRef.current.click()
              }>
                Загрузить архив
                <input ref={mobileRef}
                       type="file"
                       accept={MimeTypes.ZIP}
                       style={{display: 'none'}}
                       onChange={(e) => onChangeHandler(e, 'mobile')}/>
              </Button>

              {isMobileFileLoading && <Typography ml={2} mt={1}>Загрузка...</Typography>}
              {archiveMobileName && <Typography ml={2} mt={1}>{archiveMobileName}</Typography>}
            </Grid>


            <Grid item>
              <Grid container justifyContent="flex-end">
                <Grid item mt={4}>
                  <LoadingButton
                    autoFocus
                    disabled={uploadFilesRequest.products_url === ''}
                    loading={isLoading}
                    onClick={makeOperation}
                  >
                    <Typography variant="body2">Применить</Typography>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default CatalogModal;
