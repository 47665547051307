import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import PerPage from '../../../UI/Table/PerPage';
import AddItemIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import { RouteList } from '../../../http/RouteList';
import Divider from '../../../UI/Delimiter/Divider';

const CategoriesTabDataTweaks = () => {
  const route = useNavigate();

  return (
    <Box mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Button onClick={() => route(RouteList.CATALOG_CATEGORY_CREATE)} endIcon={<AddItemIcon />}>
            Добавить категорию
          </Button>
        </Grid>
        <Grid item>
          <PerPage />
        </Grid>
      </Grid>

      <Divider />

    </Box>
  );
};

export default CategoriesTabDataTweaks;
