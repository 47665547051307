import React from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import { IDialogFilterable } from '../../../types/app/common/DialogFilterable';
import { defaultFilterState } from './CatalogTabContentMain';
import FilterInput from '../../Common/Filter/FilterInput';
import FilterInputRange from '../../Common/Filter/FilterInputRange';
import FilterSelect from '../../Common/Filter/FilterSelect';
import { IPartner } from '../../../types/app/partner/Partner';
import Divider from '../../../UI/Delimiter/Divider';

const CatalogTabFilters = (props: IDialogFilterable<typeof defaultFilterState>) => {
  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>

      <FilterInput
        label="Идентификатор товара"
        addFilter={props.addFilter}
        field="id"
        filters={props.filters}
        placeholder="Укажите идентификатор товара"
        rule={e => !isNaN(Number(e.target.value))}
      />

      <Divider />

      <FilterInputRange
        label='Стоимость'
        addFilter={props.addFilter}
        field='price'
        filters={props.filters}
        placeholderFrom='0 ₽'
        placeholderTo='300 000 ₽'
      />

    </FilterDialog>
  );
};

export default CatalogTabFilters;
