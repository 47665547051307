import { Moment } from 'moment/moment';
import moment from 'moment';
import { FormTimePickerProps } from '../UI/Pickers/FormTimePicker';
import { FormDatePickerProps } from '../UI/Pickers/FormDatePicker';
import {FormDateTimePickerProps} from '../UI/Pickers/FormDateTimePicker';

export class DateHelper {
  static DATE_FORMAT = 'YYYY-MM-DD';

  static DATE_TIME_READ_FORMAT = 'DD.MM.YYYY HH:mm';
  static DATE_READ_FORMAT = 'DD.MM.YYYY';
  static TIME_READ_FORMAT = 'HH:mm';

  public static getPickerValue(date: Moment | null): null | number {
    if (date === null || !date.isValid()) {
      return null;
    }

    return date.unix();
  }

  public static toValidDate(value: number): string {
    return moment.unix(value).format(DateHelper.DATE_FORMAT);
  }

  public static dateTimeToReadFormat(value: number): string {
    return moment.unix(value).format(DateHelper.DATE_TIME_READ_FORMAT);
  }

  public static dateToReadFormat(value: number): string {
    return moment.unix(value).format(DateHelper.DATE_READ_FORMAT);
  }

  public static timeToReadFormat(value: number): string {
    return moment.unix(value).format(DateHelper.TIME_READ_FORMAT);
  }

  public static getDateTimePickerValueByProps(props: FormDateTimePickerProps): number {
    const value: Moment | null = props.datetimepickerProps?.value || props.datetimepickerProps?.defaultValue;

    if (!value) {
      return this.getPickerInitValueByProps(props);
    }

    return value.unix();
  }

  public static getTimePickerValueByProps(props: FormTimePickerProps): number {
    const value: Moment | null = props.timepickerProps?.value || props.timepickerProps?.defaultValue;

    if (!value) {
      return this.getPickerInitValueByProps(props);
    }

    return value instanceof moment ? value.unix() : null;
  }

  public static getPickerInitValueByProps = (props: FormTimePickerProps | FormDatePickerProps | FormDateTimePickerProps): number => {
    const fieldValue: number | null = props.fieldProps
      ? ((props.fieldProps.value as number) || (props.fieldProps.defaultValue as number))
      : null;

    if (fieldValue !== null) {
      return fieldValue;
    }

    if (props.nullable) {
      return null;
    }

    return moment().unix();
  };

  public static getMomentFromValue = (value: number | null | undefined): Moment | null => {
    if (value === null || value === undefined) {
      return null;
    }

    return moment.unix(value);
  };
}
