import React, { useState } from 'react';
import { ISelectable } from '../../../types/app/common/Selectable';
import { Checkbox, Grid, Typography } from '@mui/material';
import { IFilter } from '../../../hooks/table/useFilters';
import { FilterMultiCheckboxes } from '../../../enums/FilterMultiCheckboxes';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import {isArray, isString} from 'lodash';

interface CheckboxGroupProps {
  items: ISelectable[];
  label: string;
  field: string;
  addFilter: (filter: IFilter) => void;
  filters: IFilters;
  type?: FilterMultiCheckboxes.EQ_ARRAY | FilterMultiCheckboxes.IN_ARRAY;
}

interface CheckboxProps {
  value: number[];
  onChange: (keys: number[]) => void;
  statusKey: number;
  checked: boolean;
}

const MultiCheckbox = ({ value, onChange, statusKey, checked }: CheckboxProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onChange([...value, statusKey]);
    } else {
      onChange(value.filter((val: number) => val !== statusKey));
    }
  };

  return <Checkbox onChange={handleChange} checked={checked} size="small" />;
};

const FilterMultiCheckbox = ({ items, field, addFilter, label, filters, type }: CheckboxGroupProps) => {
  const currentType = type ?? FilterMultiCheckboxes.EQ_ARRAY;

  const getCheckBoxOptions = () => {

    if(isArray(filters[currentType][field])) {

      return filters[currentType][field]
    }

    if(isString(filters[currentType][field])) {

      return filters[currentType][field].split(',').map(el => +el)
    }

    return []
  }

  const [selectedValueKeys, setSelectedValueKeys] = useState<number[] | null>(getCheckBoxOptions());
  const handleCheckboxChange = (keys: number[]) => {
    setSelectedValueKeys(keys);
    addFilter({ name: field, value: keys, type: currentType });
  };

  return (
    <Grid container justifyContent="space-between" columns={12} alignItems="center">
      <Grid item xs={2}>
        {label}
      </Grid>
      <Grid item xs={8} sx={{ columnCount: 2 }}>
        <Grid container columns={1}>
          {items.map((item: ISelectable, index: number) => (
            <Grid key={index} item display="flex" flexDirection="row" alignItems="center">
              <MultiCheckbox
                statusKey={Number(item.key)}
                value={selectedValueKeys}
                onChange={handleCheckboxChange}
                checked={filters[currentType][field] && filters[currentType][field].includes(Number(item.key))}
              />
              <Grid item xs={2}>
                <Typography>{item.label}</Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FilterMultiCheckbox;
