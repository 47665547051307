import React, { useContext, useState } from 'react';
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { v4 as uuid4 } from 'uuid';
import { FormContext, IFormContext } from '../../../store/context/form/FormContext';
import { getSelectNameByValue, IFormSelectSingle } from './FormSelect';
import CloseIcon from '@mui/icons-material/Close';
import FormErrorText from '../FormErrorText';

const FormSelectSingle = React.forwardRef((props: IFormSelectSingle, ref) => {
  const { config, errors, errorWatcher } = useContext(FormContext) as IFormContext;
  const selectId = uuid4();

  const [selected, setSelected] = useState<string | number>(getSelected());

  function getSelected(): number | string {
    if (props.selectProps?.defaultValue !== undefined) {
      return props.selectProps.defaultValue as number | string;
    }

    if (props.selectProps?.value !== undefined) {
      return props.selectProps.value as number | string;
    }

    return '';
  }

  return (
    <Box mb={1} position="relative">
      <Grid container justifyContent="space-between" columns={12} alignItems="center">
        <Grid item xs={10}>
          {props.title && (
            <Typography marginBottom={1} variant="body2" color="grey.700">
              {props.title}:
            </Typography>
          )}
        </Grid>
      </Grid>
      {config.readonly ? (
        <Box>
          {props.selectProps?.label && (
            <Typography mb={1} variant="body1" color="grey.500">
              {props.selectProps.label}
            </Typography>
          )}
          <Typography variant={props.typography ?? 'body1'}>
            {getSelectNameByValue(
              props.items,
              (props.selectProps?.value as string) || (props.selectProps?.defaultValue as string)
            )}
          </Typography>
        </Box>
      ) : (
        <FormControl fullWidth>
          {props.selectProps?.label && (
            <Box mb={1}>
              <InputLabel sx={{ ml: 1 }} htmlFor={selectId}>
                {props.selectProps.label}
              </InputLabel>
            </Box>
          )}

          {props?.singletweaks?.unselect && selected && (
            <Box position="absolute" top={3} right={35} zIndex={100}>
              <IconButton
                onClick={() => {
                  if (props.onBeforeClearAll) {
                    props.onBeforeClearAll(selected);
                  }

                  setSelected('');

                  if (props.onAfterClearAll) {
                    props.onAfterClearAll();
                  }
                }}
                color="error"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          )}

          <Select
            fullWidth
            error={!!errors[props.selectProps?.name]}
            id={selectId}
            input={<OutlinedInput ref={ref} />}
            {...props.selectProps}
            value={selected}
            onChange={(e, node) => {
              if (props.beforeOnchange) {
                props.beforeOnchange(e);
              }

              if (props.selectProps?.name) {
                errorWatcher(props.selectProps.name, e.target.value as string);
              }

              setSelected(e.target.value as number | string);

              if (props.selectProps?.onChange) {
                props.selectProps.onChange(e, node);
              }
            }}
          >
            {props.items.map(item => (
              <MenuItem disabled={item.disabled !== undefined ? item.disabled : false} key={item.key} value={item.key}>
                <Typography sx={{ mt: '1px' }} color={item.color ?? ''}>
                  {item.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>

          {props.selectProps?.name && (
            <Box>{errors[props.selectProps.name] && <FormErrorText error={errors[props.selectProps.name]} />}</Box>
          )}
        </FormControl>
      )}
    </Box>
  );
});

export default FormSelectSingle;
