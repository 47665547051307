import React from 'react';
import {Box, BoxProps, LinearProgress} from '@mui/material';

const PageLoader = (props: BoxProps) => {
  return (
    <Box
      classes={{
        root: {
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgba(0,0,30,0.4)',
        },
      }}
      sx={{
        backdropFilter: 'blur(10px)',
      }}
      position="absolute"
      zIndex={1000}
      top={0}
      bottom={0}
      left={0}
      right={0}
      {...props}
    >
      <LinearProgress color="error" />
    </Box>
  );
};

export default PageLoader;
