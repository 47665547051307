import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';
import {IGetDataForNewReportLoader} from '../../../http/reports/ReportLoaders';
import ReportRulesCreateForm from '../../../Widgets/Reports/ReportsTab/Operations/ReportRulesCreateForm';

const ReportRulesCreateIndex = () => {
  const { content } = useLoaderData() as {content: Promise<IGetDataForNewReportLoader>};

  return (
    <Await resolve={content}>
      <ReportRulesCreateForm />
    </Await>
      );
};

export default ReportRulesCreateIndex;
