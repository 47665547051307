import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ITariff } from '../../../types/app/billing/bonuses/Tariff';
import { IPartner } from '../../../types/app/partner/Partner';
import TabLoader from '../../Common/Loader/TabLoader';
import BillingTabContentData from './BillingTabContentData';
import { BillingService } from '../../../http/billing/BillingService';
import { useTweakTool } from '../../../hooks/table/useTweakTool';
import { PartnerService } from '../../../http/partner/PartnerService';
import { Box } from '@mui/material';
import BillingTabDataTweaks from './BillingTabDataTweaks';
import BillingTabFilters from './BillingTabFilters';
import TabTitle from '../../../UI/Tab/TabTitle';
import { IPromo } from '../../../types/app/billing/bonuses/Promo';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilterState: IFilters = {
  like: {
    name: '',
  },
  in: {
    partnersIds: null,
  },
  eq: {
    validFrom: null,
    validTo: null,
  },
};

const BillingTabContentMain = () => {
  const [tariffsWithPromos, setTariffsWithPromos] = useState<(ITariff | IPromo)[] | null>(null);

  const { items, setItems, setFilters, getFilterable, getPaginatable, getDialogFilterable, reset } = useTweakTool<
    ITariff | IPromo
  >();
  const [partners, setPartners] = useState<IPartner[]>(null);

  const [onlyActualFilter, setOnlyActualFilter] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    setFilters(defaultFilterState);

    Promise.all([BillingService.getTariffs(), BillingService.getPromos()]).then(response => {
      setTariffsWithPromos([...response[0], ...response[1]]);
    });

    PartnerService.getPartners().then(partners => setPartners(partners));
  }, []);

  useEffect(() => {
    if (tariffsWithPromos === null) {
      return;
    }

    if (onlyActualFilter === null) {
      setItems(tariffsWithPromos);
    }

    if (onlyActualFilter === false) {
      setItems(tariffsWithPromos.filter(item => item.active === false));
    }

    if (onlyActualFilter === true) {
      setItems(tariffsWithPromos.filter(item => item.active === true));
    }
  }, [onlyActualFilter, tariffsWithPromos]);

  if (items === null || partners === null) {
    return <TabLoader />;
  }

  const resetAll = () => {
    reset();
    setOnlyActualFilter(null);
  };

  return (
    <Box>
      <TabTitle title="Биллинг" />

      <BillingTabFilters partners={partners} {...getDialogFilterable} />

      <BillingTabDataTweaks
        actual={onlyActualFilter}
        dispatchActual={setOnlyActualFilter}
        {...{ ...getFilterable, reset: resetAll }}
      />

      <Divider />

      <BillingTabContentData partners={partners} {...getPaginatable} />
    </Box>
  );
};

export default BillingTabContentMain;
