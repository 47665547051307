export enum AntispamPeriodicityStatuses {
  YEAR = 'year',
  MOUTH = 'month',
  WEEK = 'week',
  DAY = 'day'
}

export enum AntispamPeriodicityNames {
  YEAR = 'Год',
  MOUTH = 'Месяц',
  WEEK = 'Неделя',
  DAY = 'День'
}

export const getAntispamPeriodicityName = (status: string) => {
  switch (status) {
    case AntispamPeriodicityStatuses.YEAR: return AntispamPeriodicityNames.YEAR
    case AntispamPeriodicityStatuses.MOUTH: return AntispamPeriodicityNames.MOUTH
    case AntispamPeriodicityStatuses.WEEK: return AntispamPeriodicityNames.WEEK
    case AntispamPeriodicityStatuses.DAY: return AntispamPeriodicityNames.DAY
  }
}