import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetCategoriesLoader } from '../../../http/catalog/CatalogLoaders';
import { CategoryShowForm } from '../../../Widgets/Catalog/CategoryTab/Operations/CategoryShowForm';

const CatalogCategoryShow = () => {
  const { categories } = useLoaderData() as IGetCategoriesLoader;

  return (
    <Await resolve={categories}>
      <CategoryShowForm />
    </Await>
  );
};

export default CatalogCategoryShow;
