import React, { useContext, useEffect } from 'react';
import MainForm from '../../../Widgets/Common/Form/MainForm';
import { RouteList } from '../../../http/RouteList';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import FormActions from '../../../UI/Form/FormActions';
import { FormContext } from '../../../store/context/form/FormContext';
import { useSnackbar } from 'notistack';
import { snackConfig } from '../../../layout/assets/config/snackConfig';
import { Typography } from '@mui/material';
import {IFormResponse} from '../../../store/context/form/types/FormResponse';
import {useNavigate} from 'react-router-dom';

const CatalogCategoryCreate = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { setConfig } = useContext(FormContext);

  const route = useNavigate();

  useEffect(() => {
    setConfig({
      action: RouteList.CATALOG_CATEGORY_CREATE,
      rules: {
        name: ['required'],
      },
      afterSubmitHandler: (formResponse: IFormResponse<number>) => {
        enqueueSnackbar('Новая категория успешно создана', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        });

        route(RouteList.CATALOG_CATEGORY_SHOW + '/' + formResponse.payload);
      },
    });
  }, []);

  return (
    <MainForm back={RouteList.CATALOG}>
      <Typography variant="h1" mb={5}>
        Добавление категории
      </Typography>

      <FormOutlinedInput
        title="Наименование категории"
        typography="h2"
        inputProps={{
          name: 'name',
        }}
      />

      <FormActions />
    </MainForm>
  );
};

export default CatalogCategoryCreate;
