import React, { Dispatch, useContext, useEffect, useMemo } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import { IProduct } from '../../../../types/app/catalog/Product';
import { add, getFlushRecalculatable, isMapperEmpty, remove } from '../../../../utils/billingUtils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IRecalculatable } from '../../../../types/app/common/Recalculatable';
import LightTooltip from '../../../../UI/Tooltips/LightTooltip';
import FormErrorText from '../../../../UI/Form/FormErrorText';
import { FormContext } from '../../../../store/context/form/FormContext';
import { CertificateTypeCodes } from '../../../../enums/CertificateTypes';
import { IPromo } from '../../../../types/app/billing/bonuses/Promo';

const ADD_LIMIT = 10;
const REMOVE_LIMIT = 1;

interface IBuyProductsMapperProps {
  products: Pick<IProduct, 'name' | 'id' | 'byCertificate'>[];
  certificateType: CertificateTypeCodes;
  isProductsTouch: boolean;
  isCategoriesTouch: boolean;
  dispatchProductsTouch: Dispatch<boolean>;
  promo: IPromo | null;
  buyProducts: IRecalculatable[],
  dispatchBuyProducts: Dispatch<IRecalculatable[]>
}

const BuyProductsMapper = (props: IBuyProductsMapperProps) => {
  const { config, setConfig } = useContext(FormContext);
  const { errors, errorWatcher } = useContext(FormContext);

  const isDisabledAdd = useMemo(() => {
    if (props.isCategoriesTouch) {
      return true;
    }

    if (props.certificateType === CertificateTypeCodes.PRODUCT) {
      return true;
    }

    return props.buyProducts.length === ADD_LIMIT || props.buyProducts.length === props.products.length;
  }, [props.buyProducts, props.certificateType, props.isCategoriesTouch]);

  const isDisabledRemove = useMemo(() => props.buyProducts.length === REMOVE_LIMIT, [props.buyProducts]);

  useEffect(() => {
    if (props.certificateType === CertificateTypeCodes.PRODUCT) {
      props.dispatchBuyProducts([props.buyProducts[0]]);
      errorWatcher('product_ids', '');
    }
  }, [props.certificateType]);

  useEffect(() => {
    if (props.buyProducts.length > 1 || props.buyProducts[0].index !== '') {
      props.dispatchProductsTouch(true);
    } else {
      props.dispatchProductsTouch(false);
    }

    const rules = {...config.rules};

    if (!isMapperEmpty(props.buyProducts)) {
      rules['required_minimal_amount'] = ['required'];
    }
    else {
      delete rules['required_minimal_amount'];
    }

    setConfig({
      ...config,
      rules,
    });
  }, [props.buyProducts]);

  return (
    <>
      {props.buyProducts.map((item, index) => (
        <Grid key={index} container columns={12} spacing={1} mt={1} justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            <FormSelect
              onBeforeClearAll={selectedId => {
                if (props.buyProducts.length === 1) {
                  props.dispatchBuyProducts(getFlushRecalculatable());
                  errorWatcher('product_ids', '');
                } else {
                  const newState = [...props.buyProducts];
                  const index = props.buyProducts.findIndex(item => item.index === selectedId);

                  newState[index].index = '';
                  newState[index].qty = '';

                  props.dispatchBuyProducts(newState);
                }
              }}
              singletweaks={{
                unselect: true,
              }}
              title="Покупка товаров"
              items={props.products.map(product => ({
                key: product.id,
                label: product.name,
                disabled: props.buyProducts.some(item => +item.index === product.id),
              }))}
              selectProps={{
                error: !!errors['product_ids'],
                value: item.index,
                disabled: props.isCategoriesTouch,
                onChange: e => {
                  const state = [...props.buyProducts];

                  state[index].index = e.target.value as string;
                  state[index].qty = '1';

                  if (index === 0) {
                    errorWatcher('product_ids', e.target.value as string);
                  }

                  props.dispatchBuyProducts(state);
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormOutlinedInput
              title="Количество"
              inputProps={{
                type: 'number',
                inputProps: {
                  min: 1,
                },
                disabled: !props.buyProducts[index].index,
                value: item.qty,
                onChange: e => {
                  let value = e.target.value;
                  const state = [...props.buyProducts];

                  if (+value === 0 && props.buyProducts[index].index) {
                    value = '1';
                  }

                  state[index].qty = value;

                  props.dispatchBuyProducts(state);
                },
              }}
            />
          </Grid>

          {!config.readonly && (
            <Grid item xs={2} mt={2}>
              <Box display="flex">
                <LightTooltip title="Добавить товар">
                  <Box>
                    <IconButton disabled={isDisabledAdd} onClick={() => add(props.buyProducts, props.dispatchBuyProducts)}>
                      <AddCircleIcon color={isDisabledAdd ? 'disabled' : 'primary'} />
                    </IconButton>
                  </Box>
                </LightTooltip>
                <LightTooltip title="Удалить товар">
                  <Box>
                    <IconButton disabled={isDisabledRemove} onClick={() => remove(index, props.buyProducts, props.dispatchBuyProducts)}>
                      <RemoveCircleIcon color={props.buyProducts.length === REMOVE_LIMIT ? 'disabled' : 'error'} />
                    </IconButton>
                  </Box>
                </LightTooltip>
              </Box>
            </Grid>
          )}

          {errors['product_ids'] && <FormErrorText error="Для товарного сертификата необходимо выбрать хотя бы один товар" />}
        </Grid>
      ))}

      <input
        type="hidden"
        name="product_ids"
        value={props.buyProducts
          .filter(item => item.index !== '')
          .map(item => item.index)
          .join(',')}
      />
      <input
        type="hidden"
        name="product_counts"
        value={props.buyProducts
          .filter(item => item.index !== '')
          .map(item => item.qty)
          .join(',')}
      />
    </>
  );
};

export default BuyProductsMapper;
