import {IRole} from '../types/app/auth/Role';

export enum SystemUserRoles {
  ADMIN = 1,
  MANAGER = 2,
  MARKETER = 3,
}

export enum UserRoleTypes {
  SYSTEM,
  CUSTOM,
}

export const isSystem = (roleId: number) => {
  return isAdmin(roleId) || isManager(roleId) || isMarketer(roleId);
}

export const isAdmin = (roleId: number) => {
  return roleId === SystemUserRoles.ADMIN;
}

export const isManager = (roleId: number) => {
  return roleId === SystemUserRoles.MANAGER;
}

export const isMarketer = (roleId: number) => {
  return roleId === SystemUserRoles.MARKETER;
}

export const getRoleColor = (role: IRole) => {
  if (isSystem(role.id)) {
    return '#B03A3A';
  }

  return '#0042E7';
}