import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Confirm from '../Common/Modal/Confirm';
import TableContainer from '../../UI/Table/TableContainer';
import {IPaginatable} from '../../types/app/common/Paginatable';
import {ICertificate} from '../../types/app/account/Certificate';
import {unix} from 'moment';
import {CertificateStatuses, getCertificateNameByStatus} from '../../enums/CertificateStatuses';
import ShowButton from '../../UI/Button/ShowButton';
import DeleteButton from '../../UI/Button/DeleteButton';
import {RouteList} from '../../http/RouteList';
import {IntlHelper} from '../../helpers/intlHelper';
import EmptyCell from '../../UI/Table/EmptyCell';
import {CertificateTypeCodes} from '../../enums/CertificateTypes';
import {AccountService} from '../../http/account/AccountService';
import {messages} from '../../notifications/messages';
import {useNotifications} from '../../hooks/useNotifications';
import {useTweakTool} from '../../hooks/table/useTweakTool';
import {uniq} from 'lodash';
import {ClientService} from '../../http/client/ClientService';
import {IClient} from '../../types/app/client/Client';

const CertificateTabContentData = (props: IPaginatable<ICertificate>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [certificateId, setCertificateId] = useState<number>(null);
  const [partnerId, setPartnerId] = useState<number>(null);
  const message = useNotifications();
  const {request} = useTweakTool();
  const [clients, setClients] = useState<IClient[]>([]);

  const openConfirm = (certificateId: number, partnerId: number) => {
    setPartnerId(partnerId);
    setCertificateId(certificateId);
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
    setCertificateId(null);
  };

  const deactivateCertificateHandler = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await AccountService.deactivateCertificate({certificate_id: String(certificateId), partner_id: partnerId});
      response
        ? message.success(messages.success.certificateDeactivated)
        : message.error(messages.errors.deleteCertificateError);
    }
    catch {
      message.error(messages.errors.generalError);
    }
    finally {
      request();
      setCertificateId(null);
      setIsLoading(false);
      closeConfirm();
    }
  };

  const getClientEmail = (userId: number) => {
    return clients.find(client => client.id === userId)?.email ?? (<EmptyCell/>);
  }

  useEffect(() => {
    ClientService.getClients({ ids: uniq(props.items.map(item => item.userId).filter(item => item)) })
      .then(response => setClients(response))
  }, [props.items]);

  return (
    <TableContainer boxprops={{style: {top: '-16px'}}}>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deactivateCertificateHandler}
        text={'Вы действительно хотите деактивировать сертификат? Данное действие нельзя будет отменить.'}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={150}>Номер</TableCell>
            <TableCell width={220}>Вид</TableCell>
            <TableCell width={150}>Номинал</TableCell>
            <TableCell width={250}>Срок годности</TableCell>
            <TableCell width={280}>Email</TableCell>
            <TableCell width={200}>Статус</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((certificate, index) => (
            <TableRow key={certificate.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell>{certificate.id}</TableCell>
              <TableCell>{CertificateTypeCodes.NOMINAL === certificate.type ? 'Номинальный' : 'Товарный'}</TableCell>
              <TableCell>{certificate.amount ? IntlHelper.RUB(certificate.amount) : <EmptyCell/>}</TableCell>
              {certificate.expiresDate ? (
                <TableCell>
                  с&nbsp;
                  {unix(certificate.createdAt).format('DD.MM.YY')}
                  &nbsp;до&nbsp;
                  {unix(certificate.expiresDate).format('DD.MM.YY')}
                </TableCell>
              ) : (
                <TableCell>
                  <EmptyCell/>
                </TableCell>
              )}
              <TableCell>{certificate.userId ? getClientEmail(certificate.userId) : <EmptyCell />}</TableCell>
              <TableCell>{getCertificateNameByStatus(certificate.status)}</TableCell>
              <TableCell>
                <Grid container display="flex" columns={2} spacing={2} justifyContent="flex-end">
                  <Grid item xs={1}>
                    <ShowButton linkProps={{to: RouteList.SHOW_CERTIFICATE + '/' + certificate.id}}/>
                  </Grid>
                  <Grid item xs={1}>
                    {certificate.status === CertificateStatuses.RELEASE && (
                      <DeleteButton onClick={() => {
                        openConfirm(certificate.id, certificate.partnerId);
                      }}
                      />
                    )}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CertificateTabContentData;
