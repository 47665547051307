import {ISelectable} from '../types/app/common/Selectable';

export enum PeriodicityType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export namespace PeriodicityType {
  export const asSelectable = (): ISelectable[] => {
    return [
      {key: PeriodicityType.DAY, label: 'Ежедневно'},
      {key: PeriodicityType.WEEK, label: 'Еженедельно'},
      {key: PeriodicityType.MONTH, label: 'Ежемесячно'},
      {key: PeriodicityType.YEAR, label: 'Ежегодно'},
    ]
  }
}