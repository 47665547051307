import EmptyCell from '../UI/Table/EmptyCell';
import { unix } from 'moment/moment';
import React from 'react';
import {IRuleSettingCondition} from '../types/app/fraud/RuleSetting';
import {StringHelper} from '../helpers/StringHelper';

export const getPeriod = (startPeriodDate: number | null, endPeriodDate: number | null): string | React.ReactNode => {
  if (startPeriodDate === null && endPeriodDate === null) {
    return <EmptyCell />;
  }

  const startPeriodFormatted = startPeriodDate ? unix(startPeriodDate).format('DD.MM.YYYY') : 'Не задан';
  const endPeriodFormatted = endPeriodDate ? unix(endPeriodDate).format('DD.MM.YYYY') : 'Не задан';

  return startPeriodFormatted + '-' + endPeriodFormatted;
};

export const getDateTime = (timestamp: number | null): string | React.ReactNode => {
  if (timestamp === null) {
    return <EmptyCell />;
  }

  const date = unix(timestamp).format('DD.MM.YYYY');
  const time = unix(timestamp).format('HH:mm');

  return date + ', ' + time;
}

export const getConditionDefaultValue = (conditionName: string, conditions: IRuleSettingCondition[]) => {
  const item = conditions.find(settingCondition => settingCondition.ruleCondition.name === conditionName);

  if (!item) {
    return null;
  }

  return item.value;
};

export const formatLabel = (label: string): string => {
  if (StringHelper.isCyrillic(label)) {
    return label;
  }

  return StringHelper.fromSnakeToPascalCase(label);
}

export const getRuleName = (ruleName: string): string => {

  const name = StringHelper.fromSnakeToPascalCase(ruleName);

  if (name === 'MoneyBackFraud' || name === 'BigTransactionFraud') {
    return name.replace('Fraud', '');
  }

  return name;
}
