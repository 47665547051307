import React from 'react';
import { FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useUrlManager } from '../../hooks/useUrlManager';
import { DEFAULT_PER_PAGE_ROWS } from '../../store/rtk/reducers/serverPaginationSlice';
import { useAppSelector } from '../../hooks/hooks';
import { useTweakTool } from '../../hooks/table/useTweakTool';

const PerPage = () => {
  const { isLoading } = useTweakTool();
  const { perPageToUrl, getPerPageByUrl } = useUrlManager();

  const { request } = useTweakTool();
  const serverConfig = useAppSelector(state => state.serverConfig);

  if (!serverConfig.items?.length) {
    return <></>;
  }

  return (
    <FormControl>
      <Grid container justifyContent="space-between" gap={2} alignItems="center">
        <Typography variant="body2" color="grey.700" component="span">
          Показать строк
        </Typography>
        <Select
          disabled={isLoading}
          sx={{
            borderRadius: '8px',
            width: 88,
            height: 35,
          }}
          onChange={event => {
            const perPage = event.target.value as number;

            perPageToUrl(perPage);

            if (serverConfig.method) {
              request({ perPage });
            }
          }}
          value={getPerPageByUrl()}
        >
          {DEFAULT_PER_PAGE_ROWS.map(row => (
            <MenuItem key={Math.random()} value={row}>
              {row}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </FormControl>
  );
};

export default PerPage;
