import React, {useContext, useEffect, useState} from 'react';
import {useAsyncValue} from 'react-router-dom';
import {Box, Card, Grid, Link, OutlinedInput, Typography} from '@mui/material';
import {RouteList} from '../../../../http/RouteList';
import Divider from '../../../../UI/Delimiter/Divider';
import {FormContext} from '../../../../store/context/form/FormContext';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {useNotifications} from '../../../../hooks/useNotifications';
import {messages} from '../../../../notifications/messages';
import {IReportData} from '../../../../types/app/reports/IReportData';
import {getReportTypeName} from '../../../../enums/ReportTypes';
import {ReportService} from '../../../../http/reports/ReportService';
import moment from 'moment';
import {SendReportToEmailRequest} from '../../../../types/request/reports/SendReportToEmailRequest';
import {getReportStatusName, ReportStatuses} from '../../../../enums/ReportStatyses';
import BackButton from '../../../../UI/Button/BackButton';
import {themeConfig} from '../../../../layout/assets/config/themeConfig';

const ReportShowForm = () => {
    const report = useAsyncValue() as IReportData;
    const {setConfig } = useContext(FormContext);
    const message = useNotifications()

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [emails, setEmails] = useState<string[]>(['']);

  const handleChange = (index: number, value: string) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const handleRemoveEmail = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

    const sendToEmailHandler = async () => {
      setIsLoading(true);

      const sendReportToEmailRequestData: SendReportToEmailRequest = {
        report_id: report.id,
        emails: emails.length === 1
          ? {email: emails[0] }
          : emails
      };

      try {
        await ReportService.sendReportToEmail(sendReportToEmailRequestData)

        setEmails([''])

        message.success(messages.success.reportSendOperation)
      } catch  {
        message.error(messages.errors.generalError)
      }
      finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      setConfig({
        action: RouteList.REPORTS_SHOW + '/' + report.id,
        readonly: true,
        rules: {
          format: ['required'],
        },
        afterSubmitHandler: formResponse => {
          if (formResponse.status) {
            message.success(messages.success.reportSendOperation);
          }
        },
      });
    }, []);

    return (<Box>

        <BackButton />

        <Card sx={{ padding: '70px 50px', width: '60%'}}>

          <Grid container>
            <Grid item xs={6}>
              <FormOutlinedInput
                title="Партнер"
                inputProps={{
                  name: 'report_id',
                  defaultValue: report.partnerName
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <Grid container>
            <Grid item xs={6}>
              <FormOutlinedInput
                title="Тип отчета"
                inputProps={{
                  defaultValue: getReportTypeName(report.type),
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <Grid container>
            <Grid item xs={6}>
              <FormOutlinedInput
                title="Статус"
                inputProps={{
                  defaultValue: getReportStatusName(report.status),
                }}
              />
            </Grid>
          </Grid>

          {report.startDate && report.endDate &&
              <>
                <Divider />

                <Grid container>
                  <Grid item xs={6}>
                    <FormOutlinedInput
                        title="Период формирования"
                        inputProps={{
                          defaultValue: `c ${report.startDate ? moment.unix(report.startDate).format('DD.MM.YYYY') : '-'} 
                по ${report.endDate ? moment.unix(report.endDate).format('DD.MM.YYYY') : '-'}`,
                        }}
                    />
                  </Grid>
                </Grid></>
          }

          <Divider />

          <Grid container>
            <Grid item xs={6}>
              <FormOutlinedInput
                title="Тип отчета"
                inputProps={{
                  defaultValue: report.format,
                }}
              />
            </Grid>
          </Grid>

          <Divider />

          <Grid container columns={12} display="flex" flexDirection="column" alignItems="flex-start" paddingTop={1}>
            <Grid item xs={5}>
              <Typography variant="body2" color="grey.700">
                Email для отправки отчета:
              </Typography>
            </Grid>

            <Grid item>
              {emails.map((email, index) => (
                <Grid container display="flex" key={index} item paddingTop={2} width='100%'>
                  <Grid item >
                    <OutlinedInput
                      sx={{width: '300px'}}
                      value={email}
                      type='email'
                      placeholder='Введите email'
                      name='email'
                      onChange={ (e) => handleChange(index, e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    {index > 0 && (
                      <Grid item display="inline-grid">
                        <Typography sx={{color: 'red', cursor: 'pointer'}} onClick={() => handleRemoveEmail(index)}
                                    paddingTop={2} paddingLeft={2}>
                          X
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  {/*<input type="hidden" value={emails} name="emails"/>*/}
                </Grid>
              ))}
            </Grid>

            <Typography paddingTop={2} variant="body2" sx={{color: 'blue', cursor: 'pointer'}} onClick={handleAddEmail}>
              Добавить дополнительный Email
            </Typography>

          </Grid>

          <Divider />

          <Grid container display='flex' justifyContent='space-between'>
            <Grid item>
              {!isLoading && report.url && report.status === ReportStatuses.COMPLETED && (
                <Link
                  sx={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textDecoration: 'none',
                    gap: '3px',
                    bgcolor: themeConfig.palette.primary.main,
                    color: 'white',
                    padding: '8px 12px',
                    borderRadius: '5px',
                    fontSize: '14px',
                  }}
                  href={report.url}
                  download
                >
                  <FileDownloadOutlinedIcon
                    sx={{
                      fontSize: 20,
                    }}
                  />
                  <span>Скачать</span>
                </Link>
              )}
            </Grid>
            <Grid item>
              <Button
                disabled={isLoading
                  || !report.partnerEmail
                  || !report.url
                  || report.status !== ReportStatuses.COMPLETED
                  || emails[0] === ''}
                onClick={sendToEmailHandler}
                variant="contained"
                color="primary"
                startIcon={<FileDownloadOutlinedIcon fontSize='small'/>}
              >
                Отправить на Email
              </Button>
            </Grid>

          </Grid>


        </Card>
    </Box>

    );
};

export default ReportShowForm;
