import { IUploadedFile } from '../../types/app/storage/UploadedFile';
import { IResponse } from '../../types/response/common/Response';
import { JsonRpcService } from '../JsonRpcService';
import { IStorageUploadRequest } from '../../types/request/storage/StorageUploadRequest';
import { UploadFailed } from './StorageErrors';

export class StorageService {
  private static serviceName = 'storage';

  /**
   * Upload file
   *
   * @param {IStorageUploadRequest} request
   */
  public static async upload(request: IStorageUploadRequest): Promise<IUploadedFile> {
    const response = await JsonRpcService.request<IResponse<IUploadedFile>>(this.serviceName, 'upload', request);

    if (response.error) {
      throw new UploadFailed();
    }

    return response?.result?.data;
  }
}
