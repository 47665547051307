import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, FormGroup, Grid, IconButton, Typography, Divider as MuiDivider, Alert } from '@mui/material';
import TabTitle from '../../../UI/Tab/TabTitle';
import Divider from '../../../UI/Delimiter/Divider';
import TabLoader from '../../Common/Loader/TabLoader';
import { LoadingButton } from '@mui/lab';
import { StructureService } from '../../../http/structure/StructureService';
import { useSnackbar } from 'notistack';
import { snackConfig } from '../../../layout/assets/config/snackConfig';
import { ClientService } from '../../../http/client/ClientService';
import { IClient } from '../../../types/app/client/Client';
import IOSSwitch from '../../../UI/Switch/IOSSwitch';
import StructureReferralTree from './StructureReferralTree';
import SearchSelect from '../../../UI/SearchSelect';
import CloseIcon from '@mui/icons-material/Close';

const StructureTabContentMain = () => {
  const [clients, setClients] = useState<IClient[]>(null);
  const [fromUserEmail, setFromUserEmail] = useState<string>('');
  const [toUserEmail, setToUserEmail] = useState<string>('');
  const [currentUser, setCurrentUser] = useState<{ id: number; name: string } | null>();
  const [childUsers, setChildUsers] = useState<number[]>(null);
  const [isReferralEnabled, setIsReferralEnabled] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChangeParentGroupLoading, setIsChangeParentGroupLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const usersMap = new Map<string, number>();

  if (clients) {
    clients.forEach(client => usersMap.set(client.email, client.clientRankId));
  }

  useEffect(() => {
    ClientService.getClients().then(clients => setClients(clients));
    StructureService.isReferralSystemEnabled().then(result => setIsReferralEnabled(result));
  }, []);

  const changeParentGroup = async () => {
    if (usersMap.get(fromUserEmail) && usersMap.get(toUserEmail)) {
      const userId = clients.find(client => client.email === fromUserEmail).id;
      const newParentId = clients.find(client => client.email === toUserEmail).id;

      if (userId === newParentId) {
        enqueueSnackbar('Емейлы не могут быть одинаковыми.', {
          variant: 'error',
          autoHideDuration: snackConfig.duration,
        });

        return;
      }

      setIsChangeParentGroupLoading(true);

      try {
        await StructureService.changeParentGroup({
          user_ids: childUsers.filter(childId => childId !== newParentId),
          new_parent_id: newParentId,
        });

        enqueueSnackbar('Перестановка реферальных структур для Пользователей сохранены', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        });

        getCurrentUser(toUserEmail);
        setFromUserEmail(toUserEmail);
        setToUserEmail('');
      } catch (e) {
        enqueueSnackbar(e.message, {
          variant: 'error',
          autoHideDuration: snackConfig.duration,
        });
      } finally {
        setIsChangeParentGroupLoading(false);
      }
    } else {
      enqueueSnackbar('Ошибка. Проверьте корректность введенных данных.', {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });
    }
  };

  const toggleReferral = async () => {
    const currentValue = !isReferralEnabled;

    setIsLoading(true);

    await StructureService.toggleReferralSystem({ is_enabled: currentValue });

    setTimeout(() => {
      setIsLoading(false);
      setIsReferralEnabled(currentValue);

      enqueueSnackbar('Реферальные настройки ' + (currentValue ? 'включены' : 'отключены'), {
        variant: currentValue ? 'success' : 'info',
        autoHideDuration: snackConfig.duration,
      });
    }, 500);
  };

  const getCurrentUser = (email: string) => {
    const client = clients.find(client => client.email === email);

    if (toUserEmail) setToUserEmail('');

    if (!client) {
      enqueueSnackbar('Пользователь не найден', {
        variant: 'error',
        autoHideDuration: snackConfig.duration,
      });

      return undefined;
    }

    return setCurrentUser({
      id: client.id,
      name: client.personalData.name ? client.personalData.name : client.email,
    });
  };

  if (!clients || isReferralEnabled === null) {
    return <TabLoader />;
  }

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center" marginBottom={3}>
        <Typography variant="h3">Реферальные настройки</Typography>

        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch />}
            label={<Typography variant="body2">Подключить реферальную структуру</Typography>}
            checked={isReferralEnabled}
            disabled={isLoading}
            onChange={toggleReferral}
          />
        </FormGroup>
      </Grid>

      <Divider />

      <Typography variant="body2" color="grey.600" mb={3}>
        Перестановки реферальных структур:
      </Typography>

      <Grid bgcolor="#F9FCFF" borderRadius={2} padding={3} mb={3}>
        {fromUserEmail === '' &&
            <Alert color="info" sx={{mb: 3}}>
              Для начала процесса выберите от какого пользователя осуществить перестановку.
            </Alert>
        }
        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
          <Grid item xs>
            <Typography variant="body2" color="grey.600">
              От Пользователя:
            </Typography>

            <Divider />

            <Box position="relative">
              <SearchSelect
                items={clients.map(client => ({ key: client.email, label: client.email }))}
                selectProps={{
                  autoFocus: true,
                  value: fromUserEmail,
                  onChange: e => {
                    setFromUserEmail(e.target.value as string);
                    getCurrentUser(e.target.value as string);
                  },
                }}
              />
              {fromUserEmail && (
                <Box position="absolute" top={0} right={25}>
                  <IconButton
                    onClick={() => {
                      setFromUserEmail('');
                      setCurrentUser(null);
                    }}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="body2" color="grey.600">
              К Пользователю:
            </Typography>

            <Divider />

            <Box position="relative">
              <SearchSelect
                items={clients.map(client => ({ key: client.email, label: client.email }))}
                selectProps={{
                  disabled: fromUserEmail === '',
                  value: toUserEmail,
                  onChange: e => setToUserEmail(e.target.value as string),
                }}
              />
              {toUserEmail && (
                <Box position="absolute" top={0} right={25}>
                  <IconButton onClick={() => setToUserEmail('')}>
                    <CloseIcon color="error" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid padding={3} container justifyContent="right">
          <Grid item xs={1}>
            <LoadingButton
              sx={{
                mt: 1,
              }}
              onClick={changeParentGroup}
              loading={isChangeParentGroupLoading}
              disabled={fromUserEmail === '' || toUserEmail === ''}
              variant="outlined"
            >
              Применить
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>

      <TabTitle title="Структура" />

      <MuiDivider />

      <StructureReferralTree currentUser={currentUser} setChildUsers={setChildUsers} />
    </Box>
  );
};

export default StructureTabContentMain;
