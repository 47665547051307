export enum ReportStatuses {
  NEW = 'new',
  IN_PROCESS = 'in_process',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export const getReportStatusName = (status: ReportStatuses): string => {
  switch (status) {
    case ReportStatuses.COMPLETED: return 'Сформирован'
    case ReportStatuses.IN_PROCESS: return 'Формируется'

    default: return
  }
}