import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RegistrationPasswordRequirements from '../Form/PasswordRequirementsCard';
import { getMinPasswordLengthByUserType } from '../../../utils/authUtils';
import { UserTypes } from '../../../enums/UserTypes';

interface ICredentialsProps {
  userType: UserTypes,
}

const Credentials = (props: ICredentialsProps) => {
  const [isShowPasswordRequirements, setIsShowPasswordRequirements] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');

  return (
    <Box>
      <RegistrationPasswordRequirements
        isShow={isShowPasswordRequirements}
        password={password}
        min={getMinPasswordLengthByUserType(props.userType)}
      />

      <Grid container spacing={2} columns={3}>
        <Grid item xs={1}>
          <FormOutlinedInput
            title="Логин"
            typography="h2"
            inputProps={{
              name: 'email',
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <FormOutlinedInput
            title="Пароль"
            typography="h2"
            inputProps={{
              onFocus: () => setIsShowPasswordRequirements(true),
              onBlur: () => setIsShowPasswordRequirements(false),
              name: 'password',
              type: showPassword ? 'text' : 'password',
              value: password,
              onChange: e => setPassword(e.target.value),
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOffIcon sx={{ fontSize: 20 }} /> : <VisibilityIcon sx={{ fontSize: 20 }} />}
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Credentials;