import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import { ReactComponent as LogoIcon } from '../../../icons/logo-large.svg';
import CircularProgress from '@mui/material/CircularProgress';

const GuardLoader = () => {
  return (
    <Box position="fixed" top={0} right={0} left={0} bottom={0} zIndex={100} bgcolor="#F4F9FF">
      <Grid width="100%" height="100vh" justifyContent="center" alignItems="center" container overflow="hidden">
        <Grid item>
          <Grid container justifyContent="center" alignItems="center" flexDirection="column">
            <Grid item mb={1}>
              <LogoIcon />
            </Grid>
            <Grid item>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="body2">
                    Обработка правил маршрутизации, пожалуйста подождите...
                  </Typography>
                </Grid>
                &nbsp;
                &nbsp;
                <Grid item>
                  <CircularProgress size={25} color="error" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GuardLoader;
