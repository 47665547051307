import React from 'react';
import { IDialogFilterable } from '../../../types/app/common/DialogFilterable';
import { defaultFilters } from './RuleReportTabMain';
import FilterDialog from '../../Common/Filter/FilterDialog';
import FilterSelect from '../../Common/Filter/FilterSelect';
import { FraudRuleReportIds } from '../../../enums/FraudRules';
import Divider from '../../../UI/Delimiter/Divider';
import FilterDateRange from '../../../UI/Filter/FilterDateRange';
import moment from 'moment';
import FilterTimeRange from '../../../UI/Filter/FilterTimeRange';

const RuleReportTabFilters = (props: IDialogFilterable<typeof defaultFilters>) => {
  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterSelect
        label="Название правила"
        filters={props.filters}
        addFilter={props.addFilter}
        items={FraudRuleReportIds.asSelectable()}
        field="rule_id"
        placeholder="Выберите правило"
      />

      <Divider />

      <FilterDateRange
        label="Дата оповещения"
        addFilter={props.addFilter}
        field="created_date"
        filters={props.filters}
        placeholderFrom={moment().format('DD.MM.YYYY')}
        placeholderTo={moment().add(1, 'day').format('DD.MM.YYYY')}
      />

      <Divider />

      <FilterTimeRange
        label="Время оповещения"
        addFilter={props.addFilter}
        field="created_time"
        filters={props.filters}
        placeholderTo={moment().add(1, 'h').format('HH:mm')}
        placeholderFrom={moment().format('HH:mm')}
      />
    </FilterDialog>
  );
};

export default RuleReportTabFilters;