import moment from 'moment';
import { TRuleReport, TRuleReportData } from '../../types/response/fraud/GetRuleReportsData';
import { IRuleReport, IRuleReportData } from '../../types/app/fraud/RuleReport';
import {
  TRule,
  TRuleCondition,
  TRuleSetting,
  TRuleSettingCondition,
  TRuleSettingPartnerTerminals,
} from '../../types/response/fraud/GetRuleSettingsData';
import { IRuleSetting, IRuleSettingCondition, IRuleSettingPartnerTerminals } from '../../types/app/fraud/RuleSetting';
import { IRule } from '../../types/app/fraud/Rule';
import { IRuleCondition } from '../../types/app/fraud/RuleCondition';
import {FraudSettingConditionNameEnum} from '../../enums/FraudRules';

export class FraudFactory {
  public static getRuleFromResponse(ruleData: TRule): IRule {
    return {
      id: ruleData.id,
      name: ruleData.name,
      description: ruleData.description,
      createdAt: ruleData.created_at ? moment(ruleData.created_at).unix() : null,
      updatedAt: ruleData.updated_at ? moment(ruleData.updated_at).unix() : null,
      conditions: ruleData.conditions.map(condition => this.getRuleCondition(condition)),
    }
  }

  public static getRuleSettingFromResponse(ruleSettingData: TRuleSetting): IRuleSetting {
    return {
      id: ruleSettingData.id,
      ruleId: ruleSettingData.rule_id,
      partnerId: ruleSettingData.partner_id,
      isActive: ruleSettingData.is_active,
      createdAt: ruleSettingData.created_at ? moment(ruleSettingData.created_at).unix() : null,
      updatedAt: ruleSettingData.updated_at ? moment(ruleSettingData.updated_at).unix() : null,
      ruleSettingConditions: ruleSettingData.rule_setting_conditions.map(ruleSettingData =>
        this.getRuleSettingCondition(ruleSettingData)
      ),
      ruleSettingPartnerTerminals: ruleSettingData.rule_setting_partner_terminals.map(terminal =>
        this.getRuleSettingPartnerTerminal(terminal)
      ),
      rule: {
        id: ruleSettingData.rule.id,
        name: ruleSettingData.rule.name,
        description: ruleSettingData.rule.description,
        createdAt: ruleSettingData.rule.created_at ? moment(ruleSettingData.rule.created_at).unix() : null,
        updatedAt: ruleSettingData.rule.updated_at ? moment(ruleSettingData.rule.updated_at).unix() : null,
      },
    };
  }

  public static getRuleReportFromResponse(ruleReportData: TRuleReport): IRuleReport {
    return {
      createdAt: ruleReportData.created_at ? moment(ruleReportData.created_at).unix() : null,
      data: this.getRuleData(ruleReportData.data),
      id: ruleReportData.id,
      ruleSettingId: ruleReportData.rule_setting_id,
      sentAt: ruleReportData.sent_at ? moment(ruleReportData.sent_at).unix() : null,
      sentAtTime: ruleReportData.sent_at
        ? moment({
            date: moment(0).date(),
            hours: moment(ruleReportData.sent_at).hours(),
            minutes: moment(ruleReportData.sent_at).minutes(),
          }).unix()
        : null,
      updatedAt: ruleReportData.updated_at ? moment(ruleReportData.updated_at).unix() : null,
      userId: ruleReportData.user_id,
      ruleSetting: {
        id: ruleReportData.rule_setting.id,
        ruleId: ruleReportData.rule_setting.rule_id,
        partnerId: ruleReportData.rule_setting.partner_id,
        isActive: ruleReportData.rule_setting.is_active,
        createdAt: ruleReportData.rule_setting.created_at ? moment(ruleReportData.rule_setting.created_at).unix() : null,
        updatedAt: ruleReportData.rule_setting.updated_at ? moment(ruleReportData.rule_setting.updated_at).unix() : null,
        rule: {
          id: ruleReportData.rule_setting.rule.id,
          name: ruleReportData.rule_setting.rule.name,
          description: ruleReportData.rule_setting.rule.description,
          createdAt: ruleReportData.rule_setting.rule.created_at
            ? moment(ruleReportData.rule_setting.rule.created_at).unix()
            : null,
          updatedAt: ruleReportData.rule_setting.rule.updated_at
            ? moment(ruleReportData.rule_setting.rule.updated_at).unix()
            : null,
        },
      },
    };
  }

  private static getRuleCondition(ruleConditionData: TRuleCondition): IRuleCondition {
    return {
      createdAt: ruleConditionData.created_at ? moment(ruleConditionData.created_at).unix() : null,
      description: ruleConditionData.description,
      id: ruleConditionData.id,
      name: ruleConditionData.name,
      updatedAt: ruleConditionData.updated_at ? moment(ruleConditionData.updated_at).unix() : null,
    };
  }

  private static getRuleSettingPartnerTerminal(data: TRuleSettingPartnerTerminals): IRuleSettingPartnerTerminals {
    return {
      id: data.id,
      partnerTerminalId: data.partner_terminal_id,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
    };
  }

  private static getRuleSettingCondition(data: TRuleSettingCondition): IRuleSettingCondition {
    return {
      id: data.id,
      value: +data.value,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
      ruleCondition: {
        id: data.rule_condition.id,
        name: data.rule_condition.name,
        description: data.rule_condition.description,
        createdAt: data.rule_condition.created_at ? moment(data.rule_condition.created_at).unix() : null,
        updatedAt: data.rule_condition.updated_at ? moment(data.rule_condition.updated_at).unix() : null,
      },
    };
  }

  private static getRuleData(data: TRuleReportData): IRuleReportData {
    if (FraudSettingConditionNameEnum.MONEY_BACK_SUM_MAX in data) {
      return {
        moneyBackSumMax: data.money_back_sum_max,
        moneyBackSum: data.money_back_sum,
        moneyBackPeriodDaysCount: data.money_back_period_days_count,
        orderDate: data.order_date ? moment(data.order_date).unix() : null,
        partnerId: data.partner_id,
        ruleId: data.rule_id,
        startPeriodDate: data.start_period_date ? moment(data.start_period_date).unix() : null,
        endPeriodDate: data.end_period_date ? moment(data.end_period_date).unix() : null,
        transactions: data.transactions.map(transaction => ({
          showNumber: transaction.show_number,
          userId: transaction.user_id,
          amount: transaction.amount,
          createdAt: transaction.created_at ? moment(transaction.created_at).unix() : null,
        }))
      };
    }

    if (FraudSettingConditionNameEnum.REFERRALS_COUNT_MAX in data) {
      return {
        referralsCountMax: data.referrals_count_max,
        referralsCount: data.referrals_count,
        referralsWithoutOrdersCountMax: data.referrals_without_orders_count_max,
        referralsWithoutOrdersCount: data.referrals_without_orders_count,
        referralsPeriodDaysCount: data.referrals_period_days_count,
        parentUserId: data.parent_user_id,
        ruleId: data.rule_id,
        startPeriodDate: data.start_period_date ? moment(data.start_period_date).unix() : null,
        endPeriodDate: data.end_period_date ? moment(data.end_period_date).unix() : null,
      };
    }

    if (FraudSettingConditionNameEnum.ORDERS_COUNT_MAX in data) {
      return {
        ordersCountMax: data.orders_count_max,
        ordersCount: data.orders_count,
        ordersPeriodDaysCount: data.orders_period_days_count,
        partnerId: data.partner_id,
        partnerTerminalId: data.partner_terminal_id,
        userId: data.user_id,
        ruleId: data.rule_id,
        startPeriodDate: data.start_period_date ? moment(data.start_period_date).unix() : null,
        endPeriodDate: data.end_period_date ? moment(data.end_period_date).unix() : null,
      };
    }

    return {
      ordersAmountMax: data.orders_amount_max,
      ordersAmount: data.orders_amount,
      orderId: data.order_id,
      orderDate: data.order_date ? moment(data.order_date).unix() : null,
      partnerId: data.partner_id,
      partnerTerminalId: data.partner_terminal_id,
      userId: data.user_id,
      ruleId: data.rule_id,
      startPeriodDate: data.start_period_date ? moment(data.start_period_date).unix() : null,
      endPeriodDate: data.end_period_date ? moment(data.end_period_date).unix() : null,
    };
  }
}
