import React from 'react';
import { IFilterable } from '../../../../types/app/common/Filterable';
import { Box, Grid } from '@mui/material';
import FilterGroupButtons from '../../../../UI/Button/FilterGroupButtons';
import PerPage from '../../../../UI/Table/PerPage';
import Divider from '../../../../UI/Delimiter/Divider';
import AddButton from '../../../../UI/Button/AddButton';
import { useNavigate } from 'react-router-dom';
import { RouteList } from '../../../../http/RouteList';
import { UserTypes } from '../../../../enums/UserTypes';

interface IRolesTabDataTweaksProps extends IFilterable {
  type: UserTypes;
}

const RolesTabDataTweaks = (props: IRolesTabDataTweaksProps) => {
  const route = useNavigate();

  return (
    <Box>
      <Grid container justifyContent="space-between">
        <Grid item>
          <AddButton
            onClick={() => route(props.type === UserTypes.PARTNER ? RouteList.PARTNERS_ROLE_CREATE : RouteList.USERS_ROLE_CREATE)}
          >
            Добавить роль
          </AddButton>
        </Grid>
        <Grid item>
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>
        <Grid item>
          <PerPage />
        </Grid>
      </Grid>

      <Divider />
    </Box>
  );
};

export default RolesTabDataTweaks;
