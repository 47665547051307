import moment from 'moment';
import { TPartnerData } from '../../types/response/partner/PartnerData';
import {IPartner, TPartnerId} from '../../types/app/partner/Partner';
import { ContractNames, Contracts } from '../../enums/Contracts';
import { IRegistrationAppeal } from '../../types/app/partner/PartnerRegistrationAppeal';
import { TRegistrationAppealData } from '../../types/response/partner/RegistrationAppealData';
import { TPartnerSettings } from '../../types/response/partner/PartnerGetResponse';
import { IPartnerSettings } from '../../types/app/partner/PartnerSettings';
import {TPartnerTerminal} from '../../types/response/partner/GetPartnerTerminalListData';
import {IPartnerTerminal} from '../../types/app/partner/PartnerTerminal';
import {IGetPartnerIdsByCompanyName, TGetPartnerIdsByCompanyName} from '../../types/response/partner/GetPartnerData';

export class PartnerFactory {
  public static getPartnersFromResponseData(data: TPartnerData[]): IPartner[] {
    return data.map(data => PartnerFactory.getPartnerFromResponseData(data));
  }

  public static partnersArrayToMap(partners: IPartner[]): Map<TPartnerId, IPartner>  {
    const map = new Map<TPartnerId, IPartner>();

    partners.forEach(partner => {
      map.set(partner.key, partner);
    })

    return map;
  }

  public static getPartnerFromResponseData(data: TPartnerData): IPartner {
    return {
      key: data.id,
      label: data.company_name,
      directionBusiness: data.direction_business,
      goods: data.goods,
      legalAddress: data.legal_address,
      actualAddress: data.actual_address,
      inn: data.inn,
      kpp: data.kpp,
      ogrn: data.ogrn,
      bankName: data.bank_name,
      checkingAccount: data.checking_account,
      correspondentAccount: data.correspondent_account,
      bik: data.bik,
      directorFullName: data.director_full_name,
      directorPosition: data.director_position,
      phone: data.phone,
      phoneCommunication: data.phone_communication,
      email: data.email,
      contractNumber: data.contract_number ? Number(data.contract_number) : null,
      contractType: data.contract_type === ContractNames.PREPAID ? Contracts.PREPAID : Contracts.POSTPAID,
      contractDateAt: data.contract_date_at ? moment(data.contract_date_at).unix() : null,
      contractFinishAt: data.contract_finish_at ? moment(data.contract_finish_at).unix() : null,
      reportingPeriod: data.reporting_period,
      emailNotify: data.email_notify,
      isPartnerTerminals: data.is_partner_terminals,
      isPartnerOfflineShop: data.is_partner_offline_shop,
      isPartnerShowcase: data.is_partner_showcase,
      isActive: data.is_active,
      isAccrualPointsForAllTerminals: data.is_accrual_points_for_all_terminals,
      operatorComment: data.operator_comment ?? '',
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
      attachments: data.attachments,
    };
  }

  public static getRegistrationAppealsFromResponseData(data: TRegistrationAppealData): IRegistrationAppeal {
    const momentCreatedAt = moment(data.created_at);

    return {
      id: data.id,
      companyName: data.company_name,
      directionBusiness: data.direction_business,
      goods: data.goods,
      legalAddress: data.legal_address,
      inn: data.inn,
      ogrn: data.ogrn,
      bankName: data.bank_name,
      checkingAccount: data.checking_account,
      correspondentAccount: data.correspondent_account,
      bik: data.bik,
      phone: data.phone,
      phoneCommunication: data.phone_communication,
      email: data.email,
      contractType: data.contract_type === ContractNames.PREPAID ? Contracts.PREPAID : Contracts.POSTPAID,
      partnerComment: data.partner_comment,
      operatorComment: data.operator_comment,
      partnerRegistrationApplicationStatusId: data.partner_registration_application_status_id,
      createdAtDate: momentCreatedAt.unix(),
      createdAtTime: moment({
        date: moment(0).date(),
        hours: momentCreatedAt.hours(),
        minutes: momentCreatedAt.minutes(),
      }).unix(),
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
    };
  }

  public static getPartnerSettingsFromResponse(data: TPartnerSettings): IPartnerSettings {
    return {
      commissionInput: Number(data.commission_input),
      commissionOutput: Number(data.commission_output),
      creditLimitMax: data.credit_limit_max,
      creditLimitNotify: data.credit_limit_notify,
      lowBalanceNotify: data.low_balance_notify,
      ratePointToRub: Number(data.rate_point_to_rub),
      rateRubToPoint: Number(data.rate_rub_to_point),
      unitCertLockAmount: Number(data.unit_cert_lock_amount),
    };
  }

  public static getPartnerTerminalFromResponse(data: TPartnerTerminal): IPartnerTerminal {
    return {
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      geolocation: data.geolocation,
      id: data.id,
      isActive: data.is_active,
      isLoyaltyPossible: data.is_loyalty_possible,
      name: data.name,
      partnerId: data.partner_id,
      terminalExtId: data.terminal_ext_id,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
    }
  }

  public static partnersByCompanyName(data: TGetPartnerIdsByCompanyName): IGetPartnerIdsByCompanyName  {

    return {
      items: data.items.map(item => {
        return {
          actualAddress: item.actual_address,
          bankName: item.bank_name,
          bik: item.bik,
          checkingAccount: item.checking_account,
          companyName: item.company_name,
          contractDateAt: item.contract_date_at,
          contractFinishAt: item.contract_finish_at,
          contractNumber: item.contract_number,
          contractType: item.contract_type,
          correspondentAccount: item.correspondent_account,
          createdAt: item.created_at,
          directionBusiness: item.direction_business,
          directorFullName: item.director_full_name,
          directorPosition: item.director_position,
          email: item.email,
          emailNotify: item.email_notify,
          goods: item.goods,
          id: item.id,
          inn: item.inn,
          isAccrualPointsForAllTerminals: item.is_accrual_points_for_all_terminals,
          isActive: item.is_active,
          isPartner_offline_shop: item.is_partner_offline_shop,
          isPartner_showcase: item.is_partner_showcase,
          isPartner_terminals: item.is_partner_terminals,
          kpp: item.kpp,
          legalAddress: item.legal_address,
          ogrn: item.ogrn,
          operatorComment: item.operator_comment,
          phone: item.phone,
          phoneCommunication: item.phone_communication,
          reportingPeriod: item.reporting_period,
          updatedAt: item.updated_at,
        }
      }),
      limitExceeded: data.limit_exceeded
    }
  }
}
