import React from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import {IDialogFilterable} from '../../../types/app/common/DialogFilterable';
import {defaultFilterState} from './ReportsTabContentMain';
import FilterSelect from '../../Common/Filter/FilterSelect';
import Divider from '../../../UI/Delimiter/Divider';
import FilterDateRange from '../../../UI/Filter/FilterDateRange';
import moment from 'moment/moment';
import FilterInput from '../../Common/Filter/FilterInput';
import {IPartner} from '../../../types/app/partner/Partner';
import {IReportTypes} from '../../../enums/ReportTypes';

interface IReportsTabFiltersProps extends IDialogFilterable<typeof defaultFilterState> {
    partners:  Map <number, IPartner>
    reportTypes: IReportTypes[]
}
const ReportsTabFilters = (props: IReportsTabFiltersProps) => {

  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterSelect
        label="Партнер"
        filters={props.filters}
        addFilter={props.addFilter}
        items={Array.from(props.partners.values())}
        field="userId"
        placeholder="Выберете Партнера"
      />

      <Divider />

      <FilterSelect
        label="Тип отчета"
        filters={props.filters}
        addFilter={props.addFilter}
        items={props.reportTypes}
        field="typeId"
        placeholder="Выберете тип отчета"
      />

      <Divider />

      <FilterDateRange
        addFilter={props.addFilter}
        field="createdAt"
        filters={props.filters}
        label="Дата старта отправки отчета"
        placeholderTo={moment().format('DD.MM.YYYY')}
        placeholderFrom={moment().format('DD.MM.YYYY')}
      />

      <Divider />

      <FilterInput
        label="Email для отправки"
        addFilter={props.addFilter}
        field="partnerEmail"
        filters={props.filters}
        placeholder="Укажите Email"
      />

    </FilterDialog>
  );
};

export default ReportsTabFilters;
