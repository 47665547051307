import { JsonRpcService } from '../JsonRpcService';
import {TProductData} from '../../types/response/catalog/ProductData';
import { IResponse } from '../../types/response/common/Response';
import { TCategoryData } from '../../types/response/catalog/CategoryData';
import { ICategory } from '../../types/app/catalog/Category';
import { CatalogFactory } from './CatalogFactory';
import { IProduct } from '../../types/app/catalog/Product';
import { UpdateProductResponseData } from '../../types/response/catalog/UpdateProductResponseData';
import { TProductDetailData } from '../../types/response/catalog/ProductDetailData';
import { IGetProductRequest } from '../../types/request/catalog/GetProductRequest';
import { IProductDetail } from '../../types/app/catalog/ProductDetail';
import { IGetCategoryRequest } from '../../types/request/catalog/GetCategoryRequest';
import { IUpdateProductRequest } from '../../types/request/catalog/UpdateProductRequest';
import { IUpdateCategoryRequest } from '../../types/request/catalog/UpdateCategoryRequest';
import { ICreateCategoryRequest } from '../../types/request/catalog/CreateCategoryRequest';
import { IChangeProductStatusRequest } from '../../types/request/catalog/ChangeProductStatusRequest';
import { IDeleteProductRequest } from '../../types/request/catalog/DeleteProductRequest';
import { IProductStatus } from '../../types/app/catalog/ProductStatus';
import {ISelectable} from '../../types/app/common/Selectable';
import {IStatusHistoryDetail, TStatusHistoryDetail} from '../../types/app/catalog/StatusHistoryDetail';
import {ICreateProductRequest} from "../../types/request/catalog/CreateProductRequest";
import {UploadProductRequest} from '../../types/request/catalog/UploadProductRequest';
import {InternalError} from '../reports/ReportErrors';
import { IPaginated } from '../../types/app/common/Paginated';
import { IPaginatedResponse } from '../../types/response/common/PaginatedResponse';
import {IGetFilteredProductsRequest} from '../../types/request/catalog/GetFilteredProductsRequest';

export class CatalogService {
  private static serviceName: string = 'catalog';

  public static async getCategories(): Promise<ICategory[]> {
    const response = await JsonRpcService.request<IResponse<TCategoryData[]>>(CatalogService.serviceName, 'categories');

    return response.result.data.map(categoryData => CatalogFactory.getCategoryFromResponse(categoryData));
  }

  public static async getProducts(): Promise<IProduct[]> {
    const response = await JsonRpcService.request<IResponse<TProductData[]>>(CatalogService.serviceName, 'products');

    return response.result.data.map(productData => CatalogFactory.getProductFromResponse(productData));
  }

  public static async getFilteredProducts(request?): Promise<IPaginated<IProduct[]>> {
    const response = await JsonRpcService.request<IPaginatedResponse<TProductData[]>>(
      CatalogService.serviceName, 
      'filtered_products',
      request
    );

    const items = response.result.data.items.map(productData => CatalogFactory.getProductFromResponse(productData));

    return { items, ...response.result.data.pagination }
  }

  public static async getFilteredProductsLight(request: IGetFilteredProductsRequest): Promise<Pick<IProduct, 'name' | 'id' | 'byCertificate'>[]> {
    const response = await JsonRpcService.request<IResponse<Pick<TProductData, 'id' | 'name' | 'by_certificate'>[]>>(
      CatalogService.serviceName,
      'filtered_products',
      request
    );

    return response.result.data.map(productDataLight => CatalogFactory.getProductFromResponseLight(productDataLight))
  }

  public static async getProduct(request: IGetProductRequest): Promise<IProductDetail> {
    const response = await JsonRpcService.request<IResponse<TProductDetailData>>(this.serviceName, 'get_product', request);

    return CatalogFactory.getProductDetailFromResponseData(response.result.data);
  }

  public static async getProductStatuses(): Promise<IProductStatus[]> {
    const response = await JsonRpcService.request<IResponse<IProductStatus[]>>(this.serviceName, 'get_product_statuses');

    return response.result.data;
  }

  public static async updateProduct(request: IUpdateProductRequest): Promise<void> {
    await JsonRpcService.request<IResponse<UpdateProductResponseData>>(this.serviceName, 'update_product', request);
  }

  public static async deleteProduct(request: IDeleteProductRequest): Promise<void> {
    await JsonRpcService.request<IResponse<[]>>(this.serviceName, 'delete_product', request);
  }

  public static async updateCategory(request: IUpdateCategoryRequest): Promise<void> {
    await JsonRpcService.request<IResponse>(this.serviceName, 'update_category', request);
  }

  public static async deleteCategory(request: IGetCategoryRequest): Promise<boolean> {
    const response = await JsonRpcService.request<IResponse<[]>>(this.serviceName, 'delete_category', request);

    if (response.result.error) {
      return false;
    }

    return response.result.status;
  }
  
  public static async createCategory(request: ICreateCategoryRequest): Promise<number> {
    const response = await JsonRpcService.request<IResponse<{id: number}>>(this.serviceName, 'add_category', request);

    return response.result.data.id;
  }

  public static async changeProductStatus(request: IChangeProductStatusRequest): Promise<void> {
    await JsonRpcService.request<IResponse>(this.serviceName, 'change_product_status', request);
  }

  public static async getDeliveryTypes(): Promise<ISelectable[]> {
    const response = await JsonRpcService.request<IResponse<{ [deliveryType: string]: string }>>(
      this.serviceName,
      'get_product_delivery_types'
    );

    const data = response.result.data;
    let result = [];

    for (const deliveryType in data) {
      result.push({
        key: deliveryType,
        label: data[deliveryType]
      } as ISelectable)
    }

    return result;
  }

  public static async getProductStatusHistory(request: IDeleteProductRequest): Promise<IStatusHistoryDetail[]> {
    const response = await JsonRpcService.request<IResponse<TStatusHistoryDetail[]>>(this.serviceName, 'get_product_statuses_history', request);

    return CatalogFactory.getProductStatusHistoryDetails(response.result.data);
  }

  public static async createProduct(request: ICreateProductRequest): Promise<number> {
    const response = await JsonRpcService.request<IResponse<{id: number}>>(
      this.serviceName,
      'add_product',
      request,
    );

    return response.result.data.id;
  }

  public static async uploadProductItem(request: UploadProductRequest): Promise<void>  {
    const response = await JsonRpcService.request<IResponse>(
      this.serviceName,
      'import_products',
      request
    );

    if (!response.result.status) {
      throw new InternalError();
    }
  }

}
