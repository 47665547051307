import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetRolesLoader } from '../../../http/auth/AuthLoaders';
import { AccountCreateForm } from '../../../Widgets/Common/Tab/AccountTabs/Operations/AccountCreateForm';

const UserCreate = () => {
  const { roles } = useLoaderData() as IGetRolesLoader;

  return (
    <Await resolve={roles}>
      <AccountCreateForm />
    </Await>
  );
};

export default UserCreate;
