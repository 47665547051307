import {FormDataHelper} from '../../helpers/FormDataHelper';
import {ClientService} from './ClientService';
import {GenderSymbols, GenderTypes} from '../../enums/Genders';
import {MaritalStatuses, MaritalStatusesType} from '../../enums/MaritalStatuses';
import {IUpdateClientRequest} from '../../types/request/client/UpdateClientRequest';
import {UpdateClientRankRequest} from '../../types/request/client/UpdateClientRankRequest';
import {StorageService} from '../storage/StorageService';
import {IStorageUploadRequest} from '../../types/request/storage/StorageUploadRequest';
import {StorageTypes} from '../../enums/StorageTypes';
import {IStorageFileData} from '../../store/rtk/reducers/storageSlice';
import {FileHelper} from '../../helpers/FileHelper';
import {IFormResponse} from '../../store/context/form/types/FormResponse';
import {AddClientRankRequest} from '../../types/request/client/AddClientRankRequest';
import {BillingService} from '../billing/BillingService';
import {AuthService} from '../auth/AuthService';
import {AddRankSettingsRequest} from '../../types/request/billing/AddRankSettingsRequest';
import { StringHelper } from '../../helpers/StringHelper';

export class ClientActions {
  /**
   * Update client rank
   *
   * @param request
   * @param params
   */
  public static updateClientRank = async ({request, params}) => {
    const formData: FormData = await request.formData();
    const updateRequest = FormDataHelper.toObject<UpdateClientRankRequest>(formData);

    if (updateRequest.file) {
      const encoded = JSON.parse(updateRequest.file) as IStorageFileData[];

      const uploadedRequest: IStorageUploadRequest = {
        type: StorageTypes.CLIENT_RANKS,
        id: Math.random().toString(),
        filename: encoded[0].fileName,
        data: FileHelper.base64RequestFormat(encoded[0].data),
      };
      const uploadedFile = await StorageService.upload(uploadedRequest);

      updateRequest.icon = uploadedFile.url;

      delete updateRequest.file;
    }

    updateRequest.client_rank_id = Number(params.id);
    updateRequest.order = Number(updateRequest.order);
    updateRequest.min_expenses = updateRequest.min_expenses
      ? Number(updateRequest.min_expenses)
      : 0;
    updateRequest.min_referrals = updateRequest.min_referrals
      ? Number(updateRequest.min_referrals)
      : 0;
    updateRequest.min_direct_referrals = updateRequest.min_direct_referrals
      ? Number(updateRequest.min_direct_referrals)
      : 0;
    updateRequest.snils_is_set = 0;

    const updateRankSettingRequest = {
      percent: Number(updateRequest.percent),
      bonus_amount: Number(updateRequest.bonus_amount),
      bonus_name: `Бонус ${updateRequest.name}`,
      lifetime_days: Number(updateRequest.lifetime_days),
      payment_percent: updateRequest.payment_percent,
      referral_amount: updateRequest.referral_amount,
      payment_lifetime: 1000000,
      referral_lifetime: 1000000,
    };

    try {
      const updateClientRankResponse = await ClientService.updateClientRank(updateRequest);
      await BillingService.updateClientRankSettings({...updateRankSettingRequest, rank_id: updateClientRankResponse.id});

      return {status: true};
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  public static addClientRank = async ({request}) => {
    const formData: FormData = await request.formData();

    const addRequest = FormDataHelper.toObject<AddClientRankRequest>(formData);

    if (addRequest.file) {
      const encoded = JSON.parse(addRequest.file) as IStorageFileData[];

      const uploadedRequest: IStorageUploadRequest = {
        type: StorageTypes.CLIENT_RANKS,
        id: Math.random().toString(),
        filename: encoded[0].fileName,
        data: FileHelper.base64RequestFormat(encoded[0].data),
      };
      const uploadedFile = await StorageService.upload(uploadedRequest);

      addRequest.icon = uploadedFile.url;

      delete addRequest.file;
    }

    const addRankRequest = {
      name: addRequest.name,
      order: Number(addRequest.order),
      icon: addRequest.icon,
      snils_is_set: 0,
      min_expenses: addRequest.min_expenses ? Number(addRequest.min_expenses) : 0,
      min_direct_referrals: addRequest.min_direct_referrals ? Number(addRequest.min_direct_referrals) : 0,
      min_referrals: addRequest.min_referrals ? Number(addRequest.min_referrals) : 0,
      description: addRequest.description,
    };

    const addRankSettingRequest: Omit<AddRankSettingsRequest, 'rank_id'> = {
      percent: Number(addRequest.percent),
      bonus_amount: Number(addRequest.bonus_amount),
      bonus_name: `Бонус ${addRankRequest.name}`,
      lifetime_days: Number(addRequest.lifetime_days),
      payment_percent: addRequest.payment_percent,
      referral_amount: addRequest.referral_amount,
      payment_lifetime: 1000000,
      referral_lifetime: 1000000,
    };

    try {
      const addClientRankResponse = await ClientService.addClientRank(addRankRequest);
      await BillingService.addRankSettings({...addRankSettingRequest, rank_id: addClientRankResponse.id});

      return {
        status: true,
        payload: addClientRankResponse.id
      };
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  /**
   * Update client form action
   *
   * @param request
   * @param params
   */
  public static updateClient = async ({request, params}): Promise<IFormResponse> => {

    const {id} = params;

    const formData: FormData = await request.formData();

    const updateRequest = FormDataHelper.toObject<IUpdateClientRequest>(formData);

    updateRequest.id = Number(id);

    updateRequest.gender = updateRequest.gender === String(GenderTypes.F) ? GenderSymbols.F : GenderSymbols.M;

    updateRequest.enable_email_notice = updateRequest.enable_email_notice === 'on';
    updateRequest.enable_web_notice = updateRequest.enable_web_notice === 'on';
    updateRequest.marital_status =
    updateRequest.marital_status === String(
      MaritalStatusesType.MARRIED) ? MaritalStatuses.MARRIED : MaritalStatuses.SINGLE;
    
    updateRequest.phone = StringHelper.normalizePhoneNumber(updateRequest.phone);

    try {
      const res = await ClientService.updateClient(updateRequest);
      await AuthService.changeUserName({name: updateRequest.middle_name
          ? (updateRequest.surname + ' ' + updateRequest.name + ' ' + updateRequest.middle_name)
          : (updateRequest.surname + ' ' + updateRequest.name),
        id: res.externalId
      })
      return {status: true};
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };
}