import React from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import { IDialogFilterable } from '../../../types/app/common/DialogFilterable';
import FilterInput from '../../Common/Filter/FilterInput';
import Divider from '../../../UI/Delimiter/Divider';
import { defaultFilterState } from './ClientsTabContentMain';
import FilterDateRange from '../../../UI/Filter/FilterDateRange';
import moment from 'moment';
import {IClientRank} from '../../../types/app/client/ClientRank';
import FilterSelect from '../../Common/Filter/FilterSelect';

interface IPartnerTabFiltersProps extends IDialogFilterable<typeof defaultFilterState> {
  ranks: IClientRank[],
}

const ClientsTabFilters = (props: IPartnerTabFiltersProps) => {
  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterInput
        inputProps={{ autoFocus: true }}
        label="Идентификатор"
        addFilter={props.addFilter}
        field="ids"
        filters={props.filters}
        placeholder="Укажите Идентификатор Пользователя"
        rule={e => !isNaN(Number(e.target.value))}
      />

      <Divider />

      <FilterInput
        label="Email"
        addFilter={props.addFilter}
        field="searchByEmail"
        filters={props.filters}
        placeholder="Введите адрес электронной почты"
      />

      <Divider />

      <FilterInput
        label="ФИО"
        addFilter={props.addFilter}
        field="searchByName"
        filters={props.filters}
        placeholder="Укажите ФИО Пользователя"
      />

      <Divider />

      <FilterDateRange
        addFilter={props.addFilter}
        field="createdAt"
        filters={props.filters}
        label="Дата регистрации"
        placeholderTo={moment().format('DD.MM.YYYY')}
        placeholderFrom={moment().format('DD.MM.YYYY')}
      />

      <Divider />

      <FilterDateRange
        addFilter={props.addFilter}
        field="updatedAt"
        filters={props.filters}
        label="Дата последней авторизации"
        placeholderTo={moment().format('DD.MM.YYYY')}
        placeholderFrom={moment().format('DD.MM.YYYY')}
      />

      <Divider />

      <FilterSelect
        label="Статус"
        filters={props.filters}
        addFilter={props.addFilter}
        items={props.ranks.map(rank => ({key: rank.id, label: rank.name}))}
        field="clientRankId"
        placeholder="Статус"
      />
    </FilterDialog>
  );
};

export default ClientsTabFilters;
