import React from 'react';
import {Button, Grid, IconButton} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

export enum NotificationFiltersType {
  ALL,
  NEW,
  READ,
}

export enum NotificationSort {
  ASC = 'asc',
  DESC = 'desc',
}

interface NotificationsFiltersProps {
  filter: NotificationFiltersType,
  setFilter: (filter: NotificationFiltersType) => void,
  setSort: (sort: NotificationSort) => void,
  sort: NotificationSort,
}

const NotificationsFilters = (props: NotificationsFiltersProps) => {
  return (
    <>
      {['Все', 'Новые', 'Прочитанные'].map((item, index) => (
        <Grid key={Math.random()} item>
          <Button
            onClick={() => props.setFilter(index)}
            sx={[(theme) => ({color: props.filter === index ? theme.palette.primary.main : theme.palette.grey['600']})]}
            variant="text"
          >
            {item}
          </Button>
        </Grid>
      ))}
      <IconButton
        onClick={
          () => props.setSort(props.sort === NotificationSort.ASC ? NotificationSort.DESC : NotificationSort.ASC)
        }
      >
        <FilterListIcon
          color="primary"
          sx={{
            transition: 'all .25s',
            transform: props.sort === NotificationSort.ASC ? 'rotate(180deg)' : ''
          }}
          />
      </IconButton>
    </>
  );
};

export default NotificationsFilters;