import React from 'react';
import FilterDialog from '../../Common/Filter/FilterDialog';
import { IDialogFilterable } from '../../../types/app/common/DialogFilterable';
import FilterDateRange from '../../../UI/Filter/FilterDateRange';
import moment from 'moment/moment';
import { defaultFilters } from './OperationHistoryTabContentMain';
import Divider from '../../../UI/Delimiter/Divider';
import FilterTimeRange from '../../../UI/Filter/FilterTimeRange';
import { DateHelper } from '../../../helpers/DateHelper';
import FilterInput from '../../Common/Filter/FilterInput';
import FilterInputRange from '../../Common/Filter/FilterInputRange';
import { TransactionTypeNames, TransactionTypes } from '../../../enums/TransactionTypes';
import FilterSelect from '../../Common/Filter/FilterSelect';

const OperationHistoryTabFilters = (props: IDialogFilterable<typeof defaultFilters>) => {
  const date = moment();

  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterDateRange
        fieldFromProps={{
          autoFocus: true,
        }}
        addFilter={props.addFilter}
        field="createdDate"
        filters={props.filters}
        label="Дата"
        placeholderTo={date.format('DD.MM.YYYY')}
        placeholderFrom={date.format('DD.MM.YYYY')}
      />

      <Divider />

      <FilterTimeRange
        label="Время"
        addFilter={props.addFilter}
        field="createdTime"
        filters={props.filters}
        placeholderTo={DateHelper.timeToReadFormat(date.unix())}
        placeholderFrom={DateHelper.timeToReadFormat(date.unix())}
      />

      <Divider />

      <FilterInput
        label="От кого"
        addFilter={props.addFilter}
        field="userFrom"
        filters={props.filters}
        placeholder="Укажите email отправителя"
      />

      <Divider />

      <FilterInput
        label="ID транзакции"
        addFilter={props.addFilter}
        field="transactionId"
        filters={props.filters}
        placeholder="Укажите идентификатор"
        rule={e => !isNaN(Number(e.target.value))}
      />

      <Divider />

      <FilterInput
        label="Кому"
        addFilter={props.addFilter}
        field="userTo"
        filters={props.filters}
        placeholder="Укажите email получателя"
      />

      <Divider />

      <FilterInputRange
        label="Номинал операции"
        addFilter={props.addFilter}
        field="amount"
        filters={props.filters}
        placeholderFrom="₽/Баллы"
        placeholderTo="₽/Баллы"
      />

      <Divider />

      <FilterSelect
        addFilter={props.addFilter}
        field="typeId"
        filters={props.filters}
        label="Тип операции"
        placeholder="Выберите тип"
        items={[
          { key: TransactionTypes.BUY.toString(), label: TransactionTypeNames.BUY },
          { key: TransactionTypes.BONUS_ACCRUAL.toString(), label: TransactionTypeNames.BONUS_ACCRUAL },
          { key: TransactionTypes.DEPOSIT.toString(), label: TransactionTypeNames.DEPOSIT },
          { key: TransactionTypes.WITHDRAWAL.toString(), label: TransactionTypeNames.WITHDRAWAL },
          { key: TransactionTypes.CONVERT.toString(), label: TransactionTypeNames.CONVERT },
          { key: TransactionTypes.BONUS_RETURN.toString(), label: TransactionTypeNames.BONUS_RETURN },
          { key: TransactionTypes.BONUS_TRANSFER.toString(), label: TransactionTypeNames.BONUS_TRANSFER },
          { key: TransactionTypes.CORRECTION.toString(), label: TransactionTypeNames.CORRECTION },
        ]}
      />
    </FilterDialog>
  );
};

export default OperationHistoryTabFilters;
