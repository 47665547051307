export class StringHelper {
  /**
   * Get random color by string
   *
   * @param {string} string
   */
  public static stringToColor(string: string): string {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }

  public static parse(value: any) {
    if (value === null) {
      return '';
    }

    if (typeof value === 'number') {
      return value + '';
    }

    return value;
  }

  public static fromKebabCaseToSnakeCase(string: string) {
    return string.replaceAll('-', '_')
  }

  public static fromCamelCaseToSnakeCase(string: string) {
    return string.replace( /([A-Z])/g, " $1" ).split(' ').join('_').toLowerCase();
  }

  public static fromCamelCaseToKebabCase(string: string) {
    return string.replace( /([A-Z])/g, " $1" ).split(' ').join('-').toLowerCase();
  }

  public static fromSnakeToPascalCase(string: string) {

    if(string) {
      return string
        .split('_')
        .map(str => {
          return this.ucFirst(str.split('/').map(this.ucFirst).join('/'));
        })
        .join('');
    }

    else return null
  }

  public static ucFirst(str: string): string {
    return str[0].toUpperCase() + str.slice(1);
  }

  public static isCyrillic(str: string): boolean {
    return /^[\u0400-\u04FF]+$/.test(str);
  }

  public static normalizePhoneNumber = (phoneString: string): string => {
    return phoneString.replace(/\D/g, '');
  }

  public static formatPhoneNumber = (phoneString: string): string => {
    if (phoneString.startsWith('7') && phoneString.length === 11) {
      const formatter = phoneString.match(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/);
  
      return '+' + formatter[1] +
        '(' + formatter[2] + ')' +
        '-' + formatter[3] + '-' + formatter[4] + '-' + formatter[5];
    }
  
    if (phoneString.length !== 11) {
      return phoneString;
    }
  
    const prefix = '+7';
    const formatter = phoneString.substring(1).match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
  
    return prefix + ' (' + formatter[1] + ')' + ' ' + formatter[2] + ' ' + formatter[3] + ' ' + formatter[4];
  };

}
