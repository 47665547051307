import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RouteList } from '../../../http/RouteList';

interface IHistorySliceState {
  [key: string]: { tabIndex: number };
}

const initialState: IHistorySliceState = {
  [RouteList.USERS]: {
    tabIndex: 0,
  },
  [RouteList.CLIENTS]: {
    tabIndex: 0,
  },
  [RouteList.PARTNERS]: {
    tabIndex: 0,
  },
  [RouteList.BILLING]: {
    tabIndex: 0,
  },
  [RouteList.BANKING]: {
    tabIndex: 0,
  },
  [RouteList.CATALOG]: {
    tabIndex: 0,
  },
  [RouteList.TECH_SUPPORT]: {
    tabIndex: 0,
  },
  [RouteList.NOTIFICATIONS]: {
    tabIndex: 0,
  },
  [RouteList.FRAUD_MONITORING]: {
    tabIndex: 0,
  },
  [RouteList.REPORTS]: {
    tabIndex: 0,
  },
  [RouteList.LOGS]: {
    tabIndex: 0,
  },
};

export const historySlice = createSlice({
  initialState,
  name: 'historySlice',
  reducers: {
    setTab(state: IHistorySliceState, payload: PayloadAction<{ page: string; tabIndex: number }>) {
      state[payload.payload.page].tabIndex = payload.payload.tabIndex;
    },
  },
});

export default historySlice.reducer;
