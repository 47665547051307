import { ReportService } from '../reports/ReportService';
import { AccountService } from '../account/AccountService';

export class MixedLoaders {
  /**
   * Get dashboard info loader
   */
  public static async getDashboardInfo() {
    try {
      const reportInfo = await ReportService.getDashboardOperatorTotalStatistics();
      const accountInfo = await AccountService.getOperatorDashboardData();

      return {...reportInfo, ...accountInfo};
    }
    catch {
      return null;
    }
  }
}