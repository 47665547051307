import React, {useEffect, useState} from 'react';
import TabTitle from '../../../UI/Tab/TabTitle';
import {Box} from '@mui/material';
import {useTweakTool} from '../../../hooks/table/useTweakTool';
import {CommunicationService} from '../../../http/communication/CommunicationService';
import TabLoader from '../../Common/Loader/TabLoader';
import AntispamTabData from './AntispamTabData';
import AntispamTabDataTweaks from './AntispamTabDataTweaks';
import AntispamTabFilters from './AntispamTabFilters';
import {IAntispamTemplate} from '../../../types/app/communication/AntispamTemplate';
import {IGroupsTemplate} from '../../../types/app/communication/GroupsTemplate';
import {IFilters} from '../../../store/rtk/reducers/serverPaginationSlice';
import Divider from '../../../UI/Delimiter/Divider';

export const defaultFilterState: IFilters = {
  inArray: {
    groups: [],
  },
}

const AntispamTabMain = () => {
  const {
    setItems,
    setFilters,
    items,
    getPaginatable,
    getFilterable,
    getDialogFilterable
  } = useTweakTool<IAntispamTemplate>();

  const [groups, setGroups] = useState<IGroupsTemplate[]>()
  const [periodicityTypes, setPeriodicityTypes] = useState<string[]>([])

  useEffect(() => {
    CommunicationService.getAllAntispamRule().then(template => setItems(template))
    CommunicationService.getAllGroups().then(res => setGroups(res))
    CommunicationService.getAntispamRuleGetPeriodicityTypes().then(res => setPeriodicityTypes(res))

    setFilters(defaultFilterState);
  }, [])

  if (items === null) {
    return (<TabLoader />);
  }

  return (
    <Box>
      <TabTitle title="Антиспам" />

      <AntispamTabFilters groups={groups} {...getDialogFilterable} />

      <AntispamTabDataTweaks {...getFilterable} />

      <Divider />

      <AntispamTabData groups={groups} periodicityTypes={periodicityTypes} {...getPaginatable} dispatch={setItems} />
    </Box>
  );
}

export default AntispamTabMain;