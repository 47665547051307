import React, { useContext, useEffect, useRef, useState } from 'react';
import MainForm from '../../../Widgets/Common/Form/MainForm';
import { RouteList } from '../../../http/RouteList';
import RegistrationPasswordRequirements from '../../../Widgets/Common/Form/PasswordRequirementsCard';
import {Grid, IconButton, Typography} from '@mui/material';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import FormActions from '../../../UI/Form/FormActions';
import { FormContext } from '../../../store/context/form/FormContext';
import { useSnackbar } from 'notistack';
import { snackConfig } from '../../../layout/assets/config/snackConfig';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Divider from '../../../UI/Delimiter/Divider';
import { useNavigate } from 'react-router-dom';
import { IFormResponse } from '../../../store/context/form/types/FormResponse';
import { UserTypes } from '../../../enums/UserTypes';
import FormDatePicker from '../../../UI/Pickers/FormDatePicker';
import moment, {Moment} from 'moment';

const ClientCreate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string | Moment>(null);
  const [isShowPasswordRequirements, setIsShowPasswordRequirements] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { setConfig, softReset } = useContext(FormContext);
  const route = useNavigate();

  const ref = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setConfig({
      action: RouteList.CLIENTS_CREATE,
      readonly: false,
      data: [
        { field: 'type', value: UserTypes.USER, },
        { field: 'birthdate', value: birthdate as string },
      ],
      rules: {
        email: ['email', 'required'],
        password: ['required', 'min:6', 'digits', 'characters', 'lowercase', 'uppercase'],
        parent_email: ['email'],
        name: ['required'],
        surname: ['required'],
        birthdate: ['required'],
      },
      afterSubmitHandler: (formResponse: IFormResponse<{ param: string }>) => {
        enqueueSnackbar(
          'Новый Пользователь успешно зарегистрирован.',
          {
            variant: 'success',
            autoHideDuration: snackConfig.duration,
          }
        );

        route(RouteList.CLIENTS_SHOW + '/' + formResponse.payload.param);

        ref?.current.reset();
        setPassword('');

        softReset();
      },
    });
  }, [birthdate]);

  return (
    <MainForm ref={ref} back={RouteList.CLIENTS}>
      <RegistrationPasswordRequirements isShow={isShowPasswordRequirements} password={password} min={6} />

      <Typography variant="h1" mb={5}>
        Регистрация Пользователя
      </Typography>

      <Typography variant="body2" color="grey.600" mb={1}>
        Фамилия:
      </Typography>

      <FormOutlinedInput
        inputProps={{
          name: 'surname',
          sx: {
            backgroundColor: '#F9FCFF',
            width: '65%',
            '& fieldset': {
              borderColor: '#CBDDF7',
            },
          },
        }}
      />

      <Divider />

      <Typography variant="body2" color="grey.600" mb={1}>
        Имя:
      </Typography>

      <FormOutlinedInput
        inputProps={{
          name: 'name',
          sx: {
            backgroundColor: '#F9FCFF',
            width: '65%',
            '& fieldset': {
              borderColor: '#CBDDF7',
            },
          },
        }}
      />

      <Divider />

      <Typography variant="body2" color="grey.600" mb={1}>
        Отчество:
      </Typography>

      <FormOutlinedInput
        inputProps={{
          name: 'middle_name',
          sx: {
            backgroundColor: '#F9FCFF',
            width: '65%',
            '& fieldset': {
              borderColor: '#CBDDF7',
            },
          },
        }}
      />

      <Divider />

        <FormDatePicker
          maxDate={moment()}
          datepickerProps={{
            value: birthdate,
            name: 'birthdate',
            onChange: e => {
              softReset()
              if(!e){
                setBirthdate(null)
              } else {
                const date = {
                  target: {
                    name: 'birthdate',
                    value: moment(e._d).format('YYYY-MM-DD')
                  },
                  type: 'change'
                };

                setBirthdate(date.target.value);
              }
            },
          }}
          title="Дата рождения"
          fieldProps={{
            placeholder: 'ДД.ММ.ГГГГ',
            name: 'birthdate',
            sx: {
              backgroundColor: '#F9FCFF',
              width: '65%',
              '& fieldset': {
                borderColor: '#CBDDF7',
              },
            },
          }}
        />


      <Divider />

      <Typography variant="body2" color="grey.600" mb={1}>
        Email:
      </Typography>

      <FormOutlinedInput
        inputProps={{
          name: 'email',
          sx: {
            backgroundColor: '#F9FCFF',
            width: '65%',
            '& fieldset': {
              borderColor: '#CBDDF7',
            },
          },
        }}
      />

      <Divider />

      <Typography variant="body2" color="grey.600" mb={1}>
        Пароль:
      </Typography>

      <FormOutlinedInput
        inputProps={{
          name: 'password',
          type: showPassword ? 'text' : 'password',
          value: password,
          onFocus: () => setIsShowPasswordRequirements(true),
          onBlur: () => setIsShowPasswordRequirements(false),
          onChange: e => setPassword(e.target.value),
          endAdornment: (
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOffIcon sx={{ fontSize: 20 }} /> : <VisibilityIcon sx={{ fontSize: 20 }} />}
            </IconButton>
          ),
          sx: {
            backgroundColor: '#F9FCFF',
            width: '65%',
            '& fieldset': {
              borderColor: '#CBDDF7',
            },
          },
        }}
      />

      <Divider />

      <Typography variant="body2" color="grey.600" mb={1}>
        Email пригласившего Пользователя:
      </Typography>

      <FormOutlinedInput
        inputProps={{
          name: 'parent_email',
          sx: {
            backgroundColor: '#F9FCFF',
            width: '65%',
            '& fieldset': {
              borderColor: '#CBDDF7',
            },
          },
        }}
      />

      <Divider />

      <FormActions />
    </MainForm>
  );
};

export default ClientCreate;
