import React from 'react';
import Tabs from '../../../UI/Tab/Tabs';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';
import TabPanel from '../../../UI/Tab/TabPanel';
import SettingsTabContentMain from '../../../Widgets/Billing/SettingsTab/SettingsTabContentMain';
import BillingTabContentMain from '../../../Widgets/Billing/BillingTab/BillingTabContentMain';
import CertificateTabContentMain from '../../../Widgets/Certificates/CertificateTabContentMain';

const BillingIndex = () => {
  return (
    <ContentContainer title="Настройки ядра Лояльности">
      <Tabs
        items={[
          { title: 'Общие настройки', tabIndex: 0 },
          { title: 'Биллинг', tabIndex: 1 },
          // { title: 'Сертификаты', tabIndex: 2 },
        ]}
      />

      <TabPanel index={0} minheight={650}>
        <SettingsTabContentMain />
      </TabPanel>

      <TabPanel index={1} width={100}>
        <BillingTabContentMain />
      </TabPanel>

      {/*<TabPanel index={2} width={100}>*/}
      {/*  <CertificateTabContentMain />*/}
      {/*</TabPanel>*/}
    </ContentContainer>
  );
};

export default BillingIndex;
