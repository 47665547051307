import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MimeTypes} from '../../../enums/MimeTypes';

export interface IStorageFileData {
  fileName: string,
  data: string,
  type: MimeTypes,
  size: number,
  formName?: string,
}

interface IStorageSliceState {
  files: IStorageFileData[];
  isFileLoading: boolean;
}

const initialState: IStorageSliceState = {
  files: [],
  isFileLoading: false,
};

export const storageSlice = createSlice({
  name: 'storageSlice',
  initialState,
  reducers: {
    setFileLoading(state: IStorageSliceState, action: PayloadAction<boolean>) {
      state.isFileLoading = action.payload
    },
    setFiles(state: IStorageSliceState, action: PayloadAction<IStorageFileData[]>) {
      state.files = action.payload;
    },
    addFile(state: IStorageSliceState, action: PayloadAction<IStorageFileData>) {
      state.files.push(action.payload);
    },
    removeFile(state: IStorageSliceState, action: PayloadAction<number>) {
      if (state.files.length === 1) {
        state.files = [];
      }
      else {
        state.files.splice(action.payload, 1);
      }
    },
    flushFiles(state: IStorageSliceState) {
      state.files.splice(0, state.files.length);
    },
  }
});

export default storageSlice.reducer;