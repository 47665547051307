import React, {useEffect, useState} from 'react';
import { IFilter } from '../../hooks/table/useFilters';
import {Grid, TextField, TextFieldProps, Typography} from '@mui/material';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment/moment';
import {IFilters} from '../../store/rtk/reducers/serverPaginationSlice';

interface IFilterDateRangeProps {
  addFilter: (filter: IFilter) => void;
  field: string;
  filters: IFilters;
  label: string;
  placeholderTo?: string;
  placeholderFrom?: string;
  fieldFromProps?: TextFieldProps;
  fieldToProps?: TextFieldProps;
}

const getValue = (value: number | null) => {
  if (value === null) {
    return null;
  }

  return moment.unix(value).format('YYYY-MM-DD');
};

const FilterDateRange = (props: IFilterDateRangeProps) => {
  const [minValue, setMinValue] = useState<number | string>(props.filters.from[props.field] ?? null);
  const [maxValue, setMaxValue] = useState<number | string>(props.filters.to[props.field] ?? null);

  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (minValue !== null && maxValue !== null && minValue > maxValue) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [minValue, maxValue]);

  if (!props.filters) {
    throw new Error('Filters is not initialized');
  }

  return (
    <Grid alignItems="center" container justifyContent="space-between" columns={12} spacing={1}>
      <Grid item xs={4} position="relative">
        {props.label}
      </Grid>
      <Grid item xs={8}>
        <Grid container flexDirection="row" spacing={2}>
          <Grid item xs>
            <DesktopDatePicker
              onChange={(date: Moment) => {
                if (date !== null && !date.isValid()) {
                  return;
                }

                const value = date ? date.unix() : null;

                props.addFilter({ type: 'from', name: props.field, value });
                setMinValue(value);
              }}
              value={getValue(props.filters.from[props.field])}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    },
                    '& input': {
                      padding: '8px !important',
                      paddingLeft: '16px !important',
                    },
                    '& input::placeholder': {
                      fontSize: 14,
                    },
                  }}
                  error={isError}
                  {...props.fieldFromProps}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholderFrom ?? '',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs>
            <DesktopDatePicker
              onChange={(date: Moment) => {
                if (date !== null && !date.isValid()) {
                  return;
                }

                const value = date ? date.add('23', 'hours').add('59', 'minutes').unix() : null;

                props.addFilter({
                  type: 'to',
                  name: props.field,
                  value,
                });
                setMaxValue(value);
              }}
              value={getValue(props.filters.to[props.field])}
              renderInput={params => (
                <TextField
                  {...params}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                    },
                    '& input': {
                      padding: '8px !important',
                      paddingLeft: '16px !important',
                    },
                    '& input::placeholder': {
                      fontSize: 14,
                    },
                  }}
                  error={isError}
                  {...props.fieldToProps}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholderTo ?? '',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        {isError && (
          <Grid mt={1} container justifyContent="center">
            <Grid item>
              <Typography variant="body2" color="error" fontWeight="bold">
                Дата окончания фильтрации должна быть позже даты начала
              </Typography>
            </Grid>
          </Grid>
        )}

      </Grid>
    </Grid>
  );
};

export default FilterDateRange;
