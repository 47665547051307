import React, { Dispatch, useContext, useEffect, useMemo } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import { add, getFlushRecalculatable, isMapperEmpty, remove } from '../../../../utils/billingUtils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ICategory } from '../../../../types/app/catalog/Category';
import { IRecalculatable } from '../../../../types/app/common/Recalculatable';
import LightTooltip from '../../../../UI/Tooltips/LightTooltip';
import { CertificateTypeCodes } from '../../../../enums/CertificateTypes';
import { IPromo } from '../../../../types/app/billing/bonuses/Promo';
import { FormContext } from '../../../../store/context/form/FormContext';

const ADD_LIMIT = 10;
const REMOVE_LIMIT = 1;

interface IBuyCategoriesMapperProps {
  categories: ICategory[];
  isCategoriesTouch: boolean;
  isProductsTouch: boolean;
  dispatchCategoriesTouch: Dispatch<boolean>;
  certificateType: CertificateTypeCodes;
  promo: IPromo | null;
  buyCategories: IRecalculatable[],
  dispatchBuyCategories: Dispatch<IRecalculatable[]>
}

const BuyCategoriesMapper = (props: IBuyCategoriesMapperProps) => {
  const { config, setConfig } = useContext(FormContext);

  const isDisabledAdd = useMemo(() => {
    if (props.certificateType === CertificateTypeCodes.PRODUCT) {
      return true;
    }

    if (props.isProductsTouch) {
      return true;
    }

    return props.buyCategories.length === ADD_LIMIT || props.buyCategories.length === props.categories.length;
  }, [props.buyCategories, props.certificateType, props.isProductsTouch]);
  const isDisabledRemove = useMemo(() => props.buyCategories.length === REMOVE_LIMIT, [props.buyCategories]);

  useEffect(() => {
    if (props.buyCategories.length > 1 || props.buyCategories[0].index !== '') {
      props.dispatchCategoriesTouch(true);
    } else {
      props.dispatchCategoriesTouch(false);
    }

    const rules = {...config.rules};

    if (!isMapperEmpty(props.buyCategories)) {
      rules['required_minimal_amount'] = ['required'];
    }
    else {
      delete rules['required_minimal_amount'];
    }

    setConfig({
      ...config,
      rules,
    });
  }, [props.buyCategories]);

  return (
    <>
      {props.buyCategories.map((item, index) => (
        <Grid key={index} container columns={12} spacing={1} mt={1} justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            <FormSelect
              singletweaks={{
                unselect: true,
              }}
              title="Покупка из категории товаров"
              items={props.categories.map(category => ({
                ...category,
                disabled: props.buyCategories.some(item => +item.index === category.key),
              }))}
              onBeforeClearAll={selectedId => {
                if (props.buyCategories.length === 1) {
                  props.dispatchBuyCategories(getFlushRecalculatable());
                } else {
                  const index = props.buyCategories.findIndex(item => item.index === selectedId);
                  const newState = [...props.buyCategories];

                  newState[index].index = '';
                  newState[index].qty = '';

                  props.dispatchBuyCategories(newState);
                }
              }}
              selectProps={{
                value: item.index,
                disabled: props.isProductsTouch || props.certificateType === CertificateTypeCodes.PRODUCT,
                onChange: e => {
                  const state = [...props.buyCategories];

                  state[index].index = e.target.value as string;
                  state[index].qty = '1';

                  props.dispatchBuyCategories(state);
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormOutlinedInput
              title="Количество"
              inputProps={{
                type: 'number',
                inputProps: {
                  min: 1,
                },
                disabled: !props.buyCategories[index].index,
                value: item.qty,
                onChange: e => {
                  let value = e.target.value;
                  const state = [...props.buyCategories];

                  if (+value === 0 && props.buyCategories[index].index) {
                    value = '1';
                  }

                  state[index].qty = value;

                  props.dispatchBuyCategories(state);
                },
              }}
            />
          </Grid>

          {!config.readonly && (
            <Grid item xs={2} mt={2}>
              <Box display="flex">
                <LightTooltip title="Добавить категорию">
                  <Box>
                    <IconButton disabled={isDisabledAdd} onClick={() => add(props.buyCategories, props.dispatchBuyCategories)}>
                      <AddCircleIcon color={isDisabledAdd ? 'disabled' : 'primary'} />
                    </IconButton>
                  </Box>
                </LightTooltip>

                <LightTooltip title="Удалить категорию">
                  <Box>
                    <IconButton disabled={isDisabledRemove} onClick={() => remove(index, props.buyCategories, props.dispatchBuyCategories)}>
                      <RemoveCircleIcon color={isDisabledRemove ? 'disabled' : 'error'} />
                    </IconButton>
                  </Box>
                </LightTooltip>
              </Box>
            </Grid>
          )}
        </Grid>
      ))}

      <input
        type="hidden"
        name="category_ids"
        value={props.buyCategories
          .filter(item => item.index !== '')
          .map(item => item.index)
          .join(',')}
      />
      <input
        type="hidden"
        name="category_counts"
        value={props.buyCategories
          .filter(item => item.index !== '')
          .map(item => item.qty)
          .join(',')}
      />
    </>
  );
};

export default BuyCategoriesMapper;
