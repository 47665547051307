import * as React from 'react';
import { Box, List } from '@mui/material';
import SideBarItem from './SideBarItem';
import { v4 as uuid } from 'uuid';
import { useGuards } from '../../../hooks/useGuards';

const SideBar = (): React.ReactElement => {
	const { sidebarGuard } = useGuards();

	return (
		<Box
			position="fixed"
			bottom={0}
			left={0}
			top={0}
			bgcolor="white"
			padding="46px 0 6px"
			minHeight="100%"
			overflow="auto"
			width={312}
		>
			<Box>
				<Box padding="30px">
					<img
						style={{
							objectFit: 'contain',
							width: '100%',
							height: '100%',
						}}
						src="images/logo.png"
						alt="логотип"
					/>
				</Box>

				<List sx={{ paddingLeft: "30px" }}>
					{sidebarGuard.getAvailableItems().map((tabBarItem) => (
						<SideBarItem key={uuid()} {...tabBarItem} />
					))}
				</List>
			</Box>
		</Box>
	);
};

export default SideBar;
