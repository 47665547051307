import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FilterGroupButtons from '../../../UI/Button/FilterGroupButtons';
import PerPage from '../../../UI/Table/PerPage';
import { IFilterable } from '../../../types/app/common/Filterable';
import { useNavigate } from 'react-router-dom';
import { RouteList } from '../../../http/RouteList';

interface INewsTabDataTweaksProps extends IFilterable {
  active: boolean
}

const NewsTabDataTweaks = (props: INewsTabDataTweaksProps) => {
  const route = useNavigate();

  return (
    <Box mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        {props.active &&
            <Grid item>
              <Button onClick={() => route(RouteList.NOTIFICATIONS_NEWS_CREATE)} endIcon={<AddCircleOutlineIcon/>}>
                Создать рассылку
              </Button>
            </Grid>
        }
        <Grid item>
          <FilterGroupButtons reset={props.reset} onClick={props.openFilters} />
        </Grid>
        <Grid item>
          <PerPage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewsTabDataTweaks;
