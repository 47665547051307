import { defer } from 'react-router-dom';
import { ClientService } from '../client/ClientService';
import { PartnerService } from '../partner/PartnerService';
import { ShopService } from './ShopService';
import { IGetOrderRequest } from '../../types/request/shop/GetOrderRequest';
import { IOrderExtended } from '../../types/app/shop/Order';
import { IClient } from '../../types/app/client/Client';
import { IPartner } from '../../types/app/partner/Partner';
import { IOrderDetail } from '../../types/app/shop/OrderDetail';

export interface IGetOrderLoader {
  order: IOrderDetail;
  clients: IClient[];
  partners: IPartner[];
}

export interface IOrderLoader {
  orders: IOrderExtended[];
}

export class ShopLoaders {
  /**
   * Order loader
   *
   * @param params
   */
  public static getOrder = async ({ params }) => {
    const request: IGetOrderRequest = {
      order_id: params.id,
    };

    return defer({
      content: Promise.all([
        ShopService.getOrder(request),
        ClientService.getClients(),
        PartnerService.getPartners(),
      ]).then(data => ({
        order: data[0],
        clients: data[1],
        partners: data[2],
      })),
    });
  };
}
