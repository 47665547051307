export class UrlHelper {
  public static isValidUrl(urlForValidate: string) {
    let url;

    try {
      url = new URL(urlForValidate);
    }
    catch {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }
}
