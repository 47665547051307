import React, { useEffect, useState } from 'react';
import { IFilter } from '../../../hooks/table/useFilters';
import { Grid, OutlinedInputProps, Typography } from '@mui/material';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';

interface IFilterInputRangeProps {
  label: string;
  placeholderFrom?: string;
  placeholderTo?: string;
  addFilter: (filter: IFilter) => void;
  field: string;
  filters: IFilters;
  inputFromProps?: OutlinedInputProps;
  inputToProps?: OutlinedInputProps;
}

const FilterInputRange = (props: IFilterInputRangeProps) => {
  const [minValue, setMinValue] = useState<number | string>(props.filters.from[props.field] ?? '');
  const [maxValue, setMaxValue] = useState<number | string>(props.filters.to[props.field] ?? '');

  const [isError, setIsError] = useState<boolean>(false);

  if (!props.filters) {
    throw new Error('Filters is not initialized');
  }

  useEffect(() => {
    if (minValue !== '' && maxValue !== '' && minValue > maxValue) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [minValue, maxValue]);

  return (
    <Grid alignItems="center" container justifyContent="space-between" columns={12} spacing={1}>
      <Grid item xs={4} position="relative">
        {props.label}
      </Grid>
      <Grid item xs={8}>
        <Grid container flexDirection="row" spacing={2}>
          <Grid item xs>
            <FormOutlinedInput
              inputProps={{
                error: isError,
                fullWidth: true,
                onChange: e => {
                  if (e.target.value === '') {
                    setMinValue('');
                    props.addFilter({ type: 'from', name: props.field, value: null });

                    return;
                  }

                  const value = Number(e.target.value);

                  if (isNaN(value)) {
                    return;
                  }

                  setMinValue(value);
                  props.addFilter({ type: 'from', name: props.field, value });
                },
                value: minValue,
                placeholder: props.placeholderFrom,
                sx: {
                  borderRadius: 2,
                  '& input': {
                    padding: '8px',
                    paddingLeft: '16px',
                  },
                  '& input::placeholder': {
                    fontSize: 14,
                  },
                },
                ...props.inputFromProps,
              }}
            />
          </Grid>
          <Grid item xs>
            <FormOutlinedInput
              inputProps={{
                error: isError,
                fullWidth: true,
                onChange: e => {
                  if (e.target.value === '') {
                    setMaxValue('');
                    props.addFilter({ type: 'to', name: props.field, value: null });

                    return;
                  }

                  const value = Number(e.target.value);

                  if (isNaN(value)) {
                    return;
                  }

                  props.addFilter({ type: 'to', name: props.field, value });
                  setMaxValue(value);
                },
                value: maxValue,
                placeholder: props.placeholderTo,
                sx: {
                  borderRadius: 2,
                  '& input': {
                    padding: '8px',
                    paddingLeft: '16px',
                  },
                  '& input::placeholder': {
                    fontSize: 14,
                  },
                },
                ...props.inputToProps,
              }}
            />
          </Grid>
        </Grid>

        {isError && (
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="body2" color="error" fontWeight="bold">
                Минимальное значение не может превышать максимальное
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FilterInputRange;
