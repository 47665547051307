import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {ReactComponent as ErrorIcon} from '../../../icons/alert/alert-error-icon.svg';
import {LoadingButton} from '@mui/lab';

interface ConfirmProps {
  open: boolean;
  loading: boolean;
  submit: (event) => void;
  close: (event) => void;
  text: string | JSX.Element;
}

const Confirm = (props: ConfirmProps) => {
  return (
    <Dialog open={props.open} onKeyUp={(e) => {
      if (e.key === 'Escape') {
        props.close(e);
      }
    }}>
      <Box padding="0 25px 15px 35px">
        <DialogTitle sx={{pb: 1, position: 'relative'}}>
          <Box position="absolute" left="-10px" top="20px">
            <ErrorIcon/>
          </Box>
          <Typography component="span" variant="subtitle1">
            Внимание
          </Typography>
        </DialogTitle>

        <DialogContent>
          {props.text}
        </DialogContent>

        <DialogActions>
          <Button
            disabled={props.loading}
            color="error"
            onClick={props.close}
          >
            <Typography variant="body2">
              Отменить
            </Typography>
          </Button>
          <LoadingButton
            autoFocus
            loading={props.loading}
            onClick={props.submit}
          >
            <Typography variant="body2">
              Подтвердить
            </Typography>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default Confirm;