import { useAsyncValue, useLocation, useNavigate } from 'react-router-dom';
import { IRole } from '../../../../../types/app/auth/Role';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormContext, IFormConfig } from '../../../../../store/context/form/FormContext';
import { useSnackbar } from 'notistack';
import { IPartner } from '../../../../../types/app/partner/Partner';
import { getUserValueByType, UserTypes } from '../../../../../enums/UserTypes';
import { snackConfig } from '../../../../../layout/assets/config/snackConfig';
import { PartnerService } from '../../../../../http/partner/PartnerService';
import { RouteList } from '../../../../../http/RouteList';
import { getPasswordRules } from '../../../../../utils/authUtils';
import MainForm from '../../../Form/MainForm';
import { Box, Typography } from '@mui/material';
import FormOutlinedInput from '../../../../../UI/Form/FormOutlinedInput';
import FormSelect from '../../../../../UI/Form/Select/FormSelect';
import FormActions from '../../../../../UI/Form/FormActions';
import { SystemUserRoles } from '../../../../../enums/SystemUserRoles';
import Credentials from '../../../User/Credentials';
import Divider from '../../../../../UI/Delimiter/Divider';
import PageLoader from '../../../Loader/PageLoader';
import { IFormResponse } from '../../../../../store/context/form/types/FormResponse';
import { encode } from 'js-base64';

export const AccountCreateForm = () => {
  const roles = useAsyncValue() as IRole[];

  const { setConfig } = useContext(FormContext);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [partners, setPartners] = useState<IPartner[]>(null);

  const isPartner = useMemo<boolean>(() => pathname.includes(RouteList.PARTNERS_CREATE), []);
  const selectedRoles = useMemo<IRole[]>(() => getRoles(), [roles]);
  const isSelectForRoleHidden = useMemo<boolean>(() => selectedRoles.length === 1, [selectedRoles]);

  const route = useNavigate();

  function getRoles() {
    return roles.filter(role => {
      if (isPartner) {
        return role.types.includes(getUserValueByType(UserTypes.PARTNER)) && role.id !== SystemUserRoles.ADMIN;
      }

      return role.types.includes(getUserValueByType(UserTypes.OPERATOR));
    });
  }

  function getType() {
    return isPartner ? UserTypes.PARTNER : UserTypes.OPERATOR;
  }

  const accountConfig: IFormConfig = {
    readonly: false,
    action: isPartner ? RouteList.PARTNERS_CREATE : RouteList.USERS_CREATE,
    rules: {
      name: ['required'],
      surname: ['required'],
      email: ['required', 'email'],
      password: getPasswordRules(getType()),
    },
    data: [
      { field: 'type', value: getType() },
    ],
    afterSubmitHandler: (formResponse: IFormResponse<{ param: string }>) => {
      enqueueSnackbar('Учетная запись успешно зарегистрирована', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      const encodedEmail = encode(formResponse.payload.param);

      route(isPartner ? RouteList.PARTNERS_SHOW + '/' + encodedEmail : RouteList.USERS_SHOW + '/' + encodedEmail);
    },
  };

  useEffect(() => {
    if (isPartner) {
      PartnerService.getPartners().then(partners => setPartners(partners));
    }

    setConfig(accountConfig);
  }, []);

  if (isPartner && !partners) {
    return <PageLoader top={40} />;
  }

  return (
    <MainForm back={isPartner ? RouteList.PARTNERS : RouteList.USERS}>
      <Typography variant="h1" mb={5}>
        Добавление сотрудника
      </Typography>

      <FormOutlinedInput
        title="Фамилия"
        typography="h2"
        inputProps={{
          name: 'surname',
        }}
      />

      <Divider />

      <FormOutlinedInput
        title="Имя"
        typography="h2"
        inputProps={{
          name: 'name',
        }}
      />

      <Divider />

      <FormOutlinedInput
        title="Отчество"
        typography="h2"
        inputProps={{
          name: 'middle_name',
        }}
      />

      <Divider />

      <Credentials userType={getType()} />

      {isPartner && (
        <FormSelect
          title="Партнер"
          items={partners}
          selectProps={{
            name: 'partner_id',
            defaultValue: partners[0].key,
          }}
        />
      )}

      <Divider />

      {isSelectForRoleHidden ? (
        <Box>
          <Typography variant="body2" color="grey.600" mb={1}>
            Доступная роль:
          </Typography>
          <Typography>{selectedRoles[0].name}</Typography>
          <input type="hidden" name="role_id" value={SystemUserRoles.MANAGER} />
        </Box>
      ) : (
        <Box>
          <FormSelect
            title="Роль"
            items={selectedRoles.map(role => ({ key: role.id, label: role.name }))}
            selectProps={{
              name: 'role_id',
              defaultValue: selectedRoles[0].id,
            }}
          />
        </Box>
      )}

      <FormActions />
    </MainForm>
  );
};
