import React, {ReactChild} from 'react';
import {Box, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IBankingOperationsPopup {
  openPopup: boolean,
  setOpenPopup: (value: boolean) => void,
  children: ReactChild,
  title: string
}

const BankingOperationsPopup = ({openPopup, setOpenPopup, children, title}: IBankingOperationsPopup) => (
  <Dialog open={openPopup} maxWidth="md">
    <Box padding="60px 100px" minWidth="850px">
      <Box position={"absolute"} top={0} right={0}>
        <IconButton onClick={() => setOpenPopup(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle position="relative">{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Box>
  </Dialog>
);

export default BankingOperationsPopup;