export class TariffNotFound extends Error {
  message = 'Запрашиваемого тарифа не существует.';
}

export class TariffIsAlreadyExists extends Error {
  message = 'Тариф уже существует.';
}

export class ValidationErrors extends Error {
  message = 'Ошибки валидации параметров запроса.';
}

export class InternalError extends Error {
  message = 'Произошла ошибка удаленного сервера, перезагрузите страницу или попробуйте позднее.';
}

export class PromoUpdateError extends Error {
  message = 'Акцию не удалось обновить.';
}

export class PromoCreateError extends Error {
  message = 'Акцию не удалось создать.';
}