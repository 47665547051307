import React from 'react';
import { IFilter } from '../../hooks/table/useFilters';
import { Grid, TextField, TextFieldProps } from '@mui/material';
import moment from 'moment';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateHelper } from '../../helpers/DateHelper';
import { IFilters } from '../../store/rtk/reducers/serverPaginationSlice';

interface IFilterDateProps {
  addFilter: (filter: IFilter) => void;
  field: string;
  filters: IFilters;
  label: string;
  fieldProps?: TextFieldProps;
}

const FilterDate = (props: IFilterDateProps) => {
  if (!props.filters) {
    throw new Error('Filters is not initialized');
  }

  return (
    <Grid alignItems="center" container justifyContent="space-between" columns={12} spacing={1}>
      <Grid item xs={4}>
        {props.label}
      </Grid>
      <Grid item xs={4}>
        <DesktopDatePicker
          onChange={date => {
            if (date !== null && !date.isValid()) {
              return;
            }

            props.addFilter({ type: 'eq', name: props.field, value: date ? moment(date).unix() : null });
          }}
          value={DateHelper.getMomentFromValue(props.filters.eq[props.field])}
          renderInput={params => (
            <TextField
              {...params}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                },
                '& input': {
                  padding: '8px !important',
                  paddingLeft: '16px !important',
                },
                '& input::placeholder': {
                  fontSize: 14,
                },
              }}
              {...props.fieldProps}
              inputProps={{
                ...params.inputProps,
                placeholder: props.fieldProps?.placeholder,
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default FilterDate;
