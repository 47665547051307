import {useAsyncValue} from 'react-router-dom';
import {ICertificate} from '../../../types/app/account/Certificate';
import {Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from '@mui/material';
import {RouteList} from '../../../http/RouteList';
import Divider from '../../../UI/Delimiter/Divider';
import {unix} from 'moment/moment';
import RemoveIcon from '@mui/icons-material/Remove';
import {getCertificateNameByStatus} from '../../../enums/CertificateStatuses';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import React, { useContext, useEffect, useState } from 'react';
import {CertificateTypeCodes} from '../../../enums/CertificateTypes';
import {IntlHelper} from '../../../helpers/intlHelper';
import EmptyCell from '../../../UI/Table/EmptyCell';
import CircleIcon from '@mui/icons-material/Circle';
import MainForm from '../../Common/Form/MainForm';
import { FormContext } from '../../../store/context/form/FormContext';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import FormActions from '../../../UI/Form/FormActions';
import { useSnackbar } from 'notistack';
import { snackConfig } from '../../../layout/assets/config/snackConfig';

const CertificateShowCard = () => {
  const certificate = useAsyncValue() as ICertificate;
  const [newUserId, setNewUserId] = useState<number | null>(certificate.userId);

  const { enqueueSnackbar } = useSnackbar();

  const { setConfig } = useContext(FormContext);

  useEffect(() => {
    setConfig({
      readonly: true,
      action: RouteList.SHOW_CERTIFICATE + '/' + certificate.id,
      data: [
        {field: 'id_or_code', value: certificate.id},
      ],
      afterSubmitHandler(formResponse, config, setConfig) {
        enqueueSnackbar('Сертификат обновлен. Изменения сохранены.', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        })

        setConfig({...config, readonly: true});
      },
    })
  }, []);

  return (
    <MainForm back={RouteList.BILLING} isHiddenEditAndCloseButtons={!certificate.userId}>
      <Box>
        <Typography variant="body2" color="grey.600">
          Номер сертификата:
        </Typography>

        <Typography variant="h1">{certificate.id}</Typography>
      </Box>

      <Divider />

      <Grid container columns={3} spacing={1} justifyContent="space-between">
        <Grid item xs={1}>
          <Typography variant="body2" color="grey.600">
            Вид:
          </Typography>

          <Typography mt={1}>{certificate.type === CertificateTypeCodes.NOMINAL ? 'Номинальный' : 'Товарный'}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2" color="grey.600">
            Номинал:
          </Typography>

          <Typography mt={1}>{certificate.amount ? IntlHelper.RUB(certificate.amount) : <EmptyCell />}</Typography>
        </Grid>

        <Grid item xs={1}>
          {CertificateTypeCodes.NOMINAL === certificate.type && (
            <>
              <Typography variant="body2" color="grey.600">
                Остаток по сертификату:
              </Typography>
              <Typography mt={1}>{IntlHelper.RUB(certificate.balance)}</Typography>
            </>
          )}
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} spacing={1} justifyContent="space-between">
        <Grid item xs={1}>
          <Typography variant="body2" color="grey.600">
            Тип:
          </Typography>

          <Typography mt={1}>{certificate.userId ? 'Персонализированный' : 'На предъявителя'}</Typography>
        </Grid>

        <Grid item xs={1}>
          <FormOutlinedInput
            title="Идентификатор Пользователя"
            inputProps={{
              name: 'new_user_id',
              value: newUserId,
              onChange: e => setNewUserId(Number(e.target.value)),
            }}
            rule={e => !isNaN(Number(e.target.value))}
          />
        </Grid>

        <Grid item xs={1}>
          <Typography variant="body2" color="grey.600">
            Срок годности:
          </Typography>

          {certificate.expiresDate ? (
            <Typography mt={1}>
              с&nbsp;
              {unix(certificate.createdAt).format('DD.MM.YY')}
              &nbsp;до&nbsp;
              {unix(certificate.expiresDate).format('DD.MM.YY')}
            </Typography>
          ) : (
            <EmptyCell />
          )}
        </Grid>
      </Grid>
      <Divider />

      <Grid container columns={3} spacing={1} justifyContent="space-between">
        <Grid item xs={1}>
          <Typography variant="body2" color="grey.600">
            Статус:
          </Typography>

          <Typography mt={1}>{getCertificateNameByStatus(certificate.status)}</Typography>
        </Grid>
        <Grid item xs={1}>

        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} justifyContent="space-between">
        <Grid item xs>
          <Typography color="grey.700" mb={1}>
            Перечень идентификаторов доступных категорий из витрины при
            использовании сертификата:
          </Typography>
          {certificate.categories.length ? (
            <List dense>
              {certificate.categories.map(category => (
                <ListItem key={category.categoryId} disablePadding>
                  <ListItemIcon>
                    <CircleIcon
                      sx={[
                        theme => ({
                          color: theme.palette.text.primary,
                          fontSize: 7,
                          mr: 1,
                        }),
                      ]}
                    />
                  </ListItemIcon>
                  <ListItemText>{category.categoryId}</ListItemText>
                </ListItem>
              ))}
            </List>
          ) : (
            <EmptyCell />
          )}
        </Grid>
      </Grid>

      <Divider />

      <Grid container columns={3} justifyContent="space-between">
        <Grid item xs>
          <Typography color="grey.700" mb={1}>
            Перечень идентификаторов доступных товаров из витрины при использовании
            сертификата:
          </Typography>
          {certificate.products.length ? (
            <List dense>
              {certificate.products.map(product => (
                <ListItem key={product.productId} disablePadding>
                  <ListItemIcon>
                    <CircleIcon
                      sx={[
                        theme => ({
                          color: theme.palette.text.primary,
                          fontSize: 7,
                          mr: 1,
                        }),
                      ]}
                    />
                  </ListItemIcon>
                  <ListItemText>{product.productId}</ListItemText>
                </ListItem>
              ))}
            </List>
          ) : (
            <EmptyCell />
          )}
        </Grid>
      </Grid>

      <Divider />

      <Grid container alignItems="center">
        <Grid container alignItems="center">
          <Grid item>
            {certificate.isMix ? (
              <DownloadDoneIcon sx={{ fontSize: 16, mr: 1, color: '#0042E7' }} />
            ) : (
              <RemoveIcon sx={{ fontSize: 16, mr: 1, color: 'inherit' }} />
            )}
          </Grid>

          <Grid item xs>
            <Typography color={certificate.isMix ? '#0042E7' : 'inherit'} variant="body1">
              Возможность доплаты по товару/услуге баллами
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center" mt={1}>
          <Grid item>
            {certificate.isPart ? (
              <DownloadDoneIcon sx={{ fontSize: 16, mr: 1, color: '#0042E7' }} />
            ) : (
              <RemoveIcon sx={{ fontSize: 16, mr: 1, color: 'inherit' }} />
            )}
          </Grid>

          <Grid item xs>
            <Typography color={certificate.isPart ? '#0042E7' : 'inherit'} variant="body1">
              Возможность частичного использования сертификата
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <FormActions />
    </MainForm>
  );
};

export default CertificateShowCard;
