import {FormDataHelper} from '../../helpers/FormDataHelper';
import {IFormResponse} from '../../store/context/form/types/FormResponse';
import {IAuthUser} from '../../types/app/auth/AuthUser';
import {UserTypes, UserTypeValues} from '../../enums/UserTypes';
import {ContractNames} from '../../enums/Contracts';
import {TUpdatedUser} from '../../types/app/auth/User';
import {IUpdateUserRequest} from '../../types/request/auth/UpdateUserRequest';
import {AuthService} from './AuthService';
import {ICreateUserRequest} from '../../types/request/auth/CreateUserRequest';
import {IRegistrationRequest} from '../../types/request/auth/RegistrationRequest';
import {encode} from 'js-base64';
import {ICreateRoleRequest} from '../../types/request/auth/CreateRoleRequest';
import {SiteTypes} from '../../enums/SiteTypes';
import {IUpdateRoleRequest} from '../../types/request/auth/UpdateRoleRequest';
import {isAdmin, isManager, isMarketer} from '../../enums/SystemUserRoles';
import {ILoggedUser} from '../../types/app/auth/LoggedUser';
import {ICredentials} from '../../types/app/auth/Credentials';
import {MainService} from '../main/MainService';
import { IChangePasswordRequest } from '../../types/request/auth/ChangePasswordRequest';
import { AccountObjects, AccountProperties } from '../../enums/AccountProperties';
import { AccountService } from '../account/AccountService';

export class AuthActions {
  public static updateUserAction = async ({ request }: { request: Request }): Promise<IFormResponse<TUpdatedUser>> => {
    const data = await request.formData();
    const updateRequest = FormDataHelper.toObject<IUpdateUserRequest>(data);

    updateRequest.id = Number(updateRequest.id);
    updateRequest.role_id = Number(updateRequest.role_id);

    try {
      const updatedUser = await AuthService.updateUser(updateRequest);

      await MainService.cacheClear();

      return {
        status: true,
        payload: updatedUser,
      };
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  public static createUserAction = async ({ request }: { request: Request }): Promise<IFormResponse> => {
    const data = await request.formData();
    const formData = FormDataHelper.toObject<{
      name: string;
      surname: string
      middle_name: string
      email: string;
      password: string;
      type: string;
      partner_id: string;
      role_id?: string;
      company_name?: string;
      legal_address?: string;
      actual_address?: string;
      inn?: string;
      kpp?: string;
      ogrn?: string;
      bank_name?: string;
      checking_account?: string;
      correspondent_account?: string;
      bik?: string;
      director_full_name?: string;
      director_position?: string;
      phone?: string;
      phone_communication?: string;
      contract_number?: string;
      contract_type?: '0' | '1';
      contract_date_at?: string;
      contract_finish_at?: string;
      reporting_period?: string;
      email_notify?: string;
      is_partner_terminals?: 'on' | 'off' | null;
      is_partner_offline_shop?: 'on' | 'off' | null;
      is_partner_showcase?: 'on' | 'off' | null;
      is_active?: 'on' | 'off' | null;
      is_accrual_points_for_all_terminals?: 'on' | 'off' | null;
      isMainPartner?: string;
      point_balance_credit_limit?: string;
      level_for_low_rub_balance_notification?: string;
      level_for_low_point_balance_notification?: string;
      payin_rate?: string;
      payout_rate?: string;
      parent_email?: string;
      operator_comment?: string
      birthdate?: string | number
    }>(data);

    const createUserRequest: ICreateUserRequest = {
      email: formData.email,
      name: formData.name,
      middle_name: formData.middle_name,
      surname: formData.surname,
      password: formData.password,
      role_id: formData.role_id ? Number(formData.role_id) : null,
      type: formData.type as UserTypes,
      birthdate: formData.birthdate
    };

    if (createUserRequest.type === UserTypes.PARTNER && createUserRequest.role_id === 1) {
      createUserRequest.partner_data = {
        actual_address: formData.actual_address,
        bank_name: formData.bank_name,
        bik: formData.bik.replace(/[_-]/g, ''),
        checking_account: formData.checking_account.replace(/[._]/g, ''),
        company_name: formData.company_name,
        contract_date_at: formData.contract_date_at,
        contract_number: formData.contract_number,
        contract_type: formData.contract_type === '0' ? ContractNames.PREPAID : ContractNames.POSTPAID,
        correspondent_account: formData.correspondent_account.replace(/[._]/g, ''),
        director_full_name: formData.director_full_name,
        director_position: formData.director_position,
        email_notify: formData.email_notify,
        inn: formData.inn.replace(/[_-]/g, ''),
        is_active: formData.is_active === 'on',
        is_partner_offline_shop: formData.is_partner_offline_shop === 'on',
        is_partner_showcase: formData.is_partner_showcase === 'on',
        is_partner_terminals: formData.is_partner_terminals === 'on',
        is_accrual_points_for_all_terminals: formData.is_accrual_points_for_all_terminals === 'on',
        kpp: formData.kpp.replace(/[_-]/g, ''),
        legal_address: formData.legal_address,
        ogrn: formData.ogrn.replace(/[_-]/g, ''),
        phone: formData.phone.replace(/[)(+_-]/g, ''),
        phone_communication: formData.phone_communication.replace(/[)(+_-]/g, ''),
        reporting_period: Number(formData.reporting_period),
        operator_comment: formData.operator_comment
      };

      if(formData.contract_finish_at !== '') {
        createUserRequest.partner_data.contract_finish_at = formData.contract_finish_at
      }

    } else if (createUserRequest.type === UserTypes.PARTNER && createUserRequest.role_id > 1) {
      createUserRequest.partner_id = Number(formData.partner_id);
    }

    if (createUserRequest.type === UserTypes.USER) {
      createUserRequest.parent_email = formData.parent_email || null;
    }

    try {
      const user = await AuthService.createUser(createUserRequest);

      const commonPropertyRequest = {
        obj_name: AccountObjects.PARTNER,
        obj_id: Number(user.internalId),
      }

      if (formData.payin_rate !== null && formData.payin_rate !== undefined && formData.payin_rate !== "") {
        await AccountService.setProperty({
          ...commonPropertyRequest,
          property: AccountProperties.PAYIN_RATE,
          value: String(Number(formData.payin_rate) / 100),
        });
      }

      if (formData.payout_rate !== null && formData.payout_rate !== undefined && formData.payout_rate !== "") {
        await AccountService.setProperty({
          ...commonPropertyRequest,
          property: AccountProperties.PAYOUT_RATE,
          value: String(Number(formData.payout_rate) / 100),
        });
      }

      if (formData.point_balance_credit_limit !== null && formData.point_balance_credit_limit !== undefined && formData.point_balance_credit_limit !== "") {
        await AccountService.setProperty({
          ...commonPropertyRequest,
          property: AccountProperties.POINT_BALANCE_CREDIT_LIMIT,
          value: formData.point_balance_credit_limit,
        });
      }

      if (formData.level_for_low_rub_balance_notification !== null && formData.level_for_low_rub_balance_notification !== undefined && formData.level_for_low_rub_balance_notification !== "") {
        await AccountService.setProperty({
          ...commonPropertyRequest,
          property: AccountProperties.LEVEL_FOR_LOW_RUB_BALANCE_NOTIFICATION,
          value: formData.level_for_low_rub_balance_notification,
        });
      }

      if (formData.level_for_low_point_balance_notification !== null && formData.level_for_low_point_balance_notification !== undefined && formData.level_for_low_point_balance_notification !== "") {
        await AccountService.setProperty({
          ...commonPropertyRequest,
          property: AccountProperties.LEVEL_FOR_LOW_POINT_BALANCE_NOTIFICATION,
          value: formData.level_for_low_point_balance_notification,
        });
      }

      return {
        status: true,
        payload: {
          param: user.internalId || user.email,
        }
      };
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  public static getAuthUserAction = async (loginRequest?: ICredentials): Promise<IAuthUser | null> => {
    const user: ILoggedUser = await AuthService.login(loginRequest);

    if (!user) {
      return null;
    }

    return await AuthService.toAuth(user);
  };

  public static loginAction = async ({ request }: { request: Request }): Promise<IFormResponse<IAuthUser | null>> => {
    const formData = await request.formData();
    const loginRequest = FormDataHelper.toObject<ICredentials>(formData);

    try {
      return {
        status: true,
        payload: await AuthActions.getAuthUserAction(loginRequest),
      };
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  public static registrationAction = async ({ request }: { request: Request }): Promise<IFormResponse> => {
    const formData = await request.formData();
    const registrationRequest = FormDataHelper.toObject<IRegistrationRequest>(formData);

    if (registrationRequest.parent !== '') {
      registrationRequest.parent = encode(registrationRequest.parent);
    }

    try {
      return {
        status: true,
        payload: await AuthService.registration(registrationRequest)
      };
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }
  };

  /**
* Send forgotPassword email
*
* @param request
*/
  public static forgotPasswordAction = async ({ request }: { request: Request }): Promise<IFormResponse> => {
    const formData = await request.formData();
    const forgotPasswordRequest = FormDataHelper.toObject<{ email: string }>(formData);

    try {
      const response = await AuthService.forgotPassword(forgotPasswordRequest);

      return {
        status: response.result.status,
      }
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      }
    }
  }

  public static changePasswordAction = async ({ request }: { request: Request }): Promise<IFormResponse> => {
    const formData = await request.formData();
    const changePasswordRequest = FormDataHelper.toObject<IChangePasswordRequest>(formData);

    try {
      return {
        status: await AuthService.changePassword(changePasswordRequest),
      }
    }
    catch (e) {
      return {
        status: false,
        error: e.message,
      }
    }
  }

  public static async updateOperatorRoleAction({ request }): Promise<IFormResponse> {
    return await AuthActions.updateRole(request, UserTypeValues.OPERATOR);
  }

  public static async updatePartnerRoleAction({ request }): Promise<IFormResponse> {
    return await AuthActions.updateRole(request, UserTypeValues.PARTNER);
  }

  public static async createOperatorRoleAction({ request }): Promise<IFormResponse> {
    return await AuthActions.createRole(request, UserTypeValues.OPERATOR);
  }

  public static async createPartnerRoleAction({ request }): Promise<IFormResponse> {
    return await AuthActions.createRole(request, UserTypeValues.PARTNER);
  }

  private static async updateRole(request, type: UserTypeValues.OPERATOR | UserTypeValues.PARTNER): Promise<IFormResponse> {
    const formData = FormDataHelper.toObject<{
      id: string;
      name: string;
      description: string;
      sections_ids: string;
      email: string;
    }>(await request.formData());

    const roleId = +formData.id;

    const updateRoleRequest: IUpdateRoleRequest = {
      id: roleId,
      name: formData.name,
      types: AuthActions.getTypesForUpdateRole(roleId, type),
      description: formData.description,
    };

    let payload;

    try {
      await AuthService.updateRole(updateRoleRequest);

      await AuthService.setSiteSectionsByRole({
        role_id: +formData.id,
        site: type === UserTypeValues.OPERATOR ? SiteTypes.OPERATOR : SiteTypes.PARTNER,
        sections_ids: formData.sections_ids.length ? formData.sections_ids.split(',') : [],
      });

      await MainService.cacheClear();

      if (formData.email) {
        payload = await AuthService.getUserByEmail({email: formData.email}, true);
      }
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }

    return {
      status: true,
      payload,
    };
  }

  private static async createRole(request, type: UserTypeValues.OPERATOR | UserTypeValues.PARTNER): Promise<IFormResponse> {
    const formData = FormDataHelper.toObject<{
      name: string;
      description: string;
      sections_ids: string;
    }>(await request.formData());

    const createRoleRequest: ICreateRoleRequest = {
      name: formData.name,
      types: [type],
      description: formData.description,
    };

    let roleId;

    try {
      roleId = await AuthService.createRole(createRoleRequest);

      await AuthService.setSiteSectionsByRole({
        role_id: roleId,
        site: type === UserTypeValues.OPERATOR ? SiteTypes.OPERATOR : SiteTypes.PARTNER,
        sections_ids: formData.sections_ids.length ? formData.sections_ids.split(',') : [],
      });
    } catch (e) {
      return {
        status: false,
        error: e.message,
      };
    }

    return {
      status: true,
      payload: {
        roleId,
      }
    };
  }

  private static getTypesForUpdateRole(roleId: number, type: UserTypeValues.OPERATOR | UserTypeValues.PARTNER): number[] {
    // When updating system roles, we do not give a type change
    if (isAdmin(roleId) || isManager(roleId)) {
      return [UserTypeValues.PARTNER, UserTypeValues.OPERATOR];
    }

    // Only for operator
    if (isMarketer(roleId)) {
      return [UserTypeValues.OPERATOR];
    }

    return [type];
  }
}
