export enum StorageTypes {
  AVATAR = 'avatar',
  PRODUCTS = 'products',
  IMPORTS = 'imports',
  EXPORTS = 'exports',
  IMAGES = 'images',
  CLIENT_RANKS = 'client_ranks',
  DOCUMENTS = 'documents',
  PARTNER_CONTRACTS = 'partner_contracts',
  SUPPORT_ATTACHMENTS = 'support_attachments',
}