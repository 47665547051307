import {Await, useLoaderData} from "react-router-dom";
import React from "react";
import {IGetDataForNewAntispamRuleLoader} from "../../../http/communication/CommunicationLoaders";
import NotificationAntispamFormCreate from "./NotificationAntispamFormCreate";

const NotificationAntispamCreate = () => {
  const { content } = useLoaderData() as { content: Promise<IGetDataForNewAntispamRuleLoader> };

  return (
    <Await resolve={content}>
      <NotificationAntispamFormCreate />
    </Await>
  );
};

export default NotificationAntispamCreate;