import {useCallback, useEffect, useState} from 'react';
import {IFilter, useFilters} from './useFilters';
import {TSearchField} from './useSearch';
import {usePagination} from './usePagination';
import {useUrlManager} from '../useUrlManager';
import {isEmpty} from 'lodash';
import {useSearchParams} from 'react-router-dom';
import {DEFAULT_PAGE_NUMBER, IFilters} from '../../store/rtk/reducers/serverPaginationSlice';
import {useActions, useAppSelector} from '../hooks';
import {IPaginatable} from '../../types/app/common/Paginatable';
import {IFilterable} from '../../types/app/common/Filterable';
import {IDialogFilterable} from '../../types/app/common/DialogFilterable';
import {log} from 'util';

export interface IAttributesTweak {
  page?: number;
  perPage?: number;
  filters?: {};
}

interface ITweakTool<T, F extends IFilters, S extends TSearchField> {
  setItems: Function;
  items: T[];
  allItems: T[];
  apply: () => void;
  reset: () => void;
  setServerMethod: Function;
  request: (attributes?: IAttributesTweak) => void;
  setMutator: Function;
  isLoading: boolean;
  page: number;
  index: number;
  count: number;
  setPage: (page: number) => void;
  setRequestParams: Function;
  setPerPage: (perPage: number) => void;
  filtersCount: number;
  setFilters: Function;
  requestParams: object;
  addFilter: (filter: IFilter) => void;
  filters: F | null;
  isFiltersOpen: boolean;
  setShowFilters: (bool: boolean) => void;
  getPaginatable: IPaginatable<T>;
  getFilterable: IFilterable;
  getDialogFilterable: IDialogFilterable<F>;
}

export const useTweakTool = <T = [], F extends IFilters = null, S extends TSearchField = null>(): ITweakTool<T, F, S> => {
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const serverConfig = useAppSelector(state => state.serverConfig);
  const searchParams = useSearchParams();
  const { setServerMethod, setServerItems: setItems, init, setMutator, setIsLoading } = useActions();
  const { getParamsWithoutPagination, getParamsPagination, toRequestFormat, getUrl, pageToUrl, perPageToUrl } = useUrlManager();

  const [allItems, setAllItems] = useState<T[] | null>(null);
  const [requestParams, setRequestParams] = useState<null | object>(null);

  const {
    filters,
    setFilters,
    apply: applyFilters,
    addFilter,
    count: filtersCount,
    setCount: setFiltersCount,
    getCount: getFiltersCount,
    resetFilters,
  } = useFilters<T>();

  const {
    items: paginatedItems,
    setItems: setInitItemsForPaginate,
    page,
    perPage,
    count,
    index,
    setPage,
    setPerPage,
    setCount: setItemsCount,
  } = usePagination<T>();

  useEffect(() => {
    if (serverConfig.method && !serverConfig.isInitialize) {
      init();
      request();
    }
  }, [serverConfig.method]);

  useEffect(() => {
    if (serverConfig.method) {
      setAllItems(serverConfig.items);
    }
  }, [serverConfig.items]);

  useEffect(() => {
    let itemsToPaginate = Array.isArray(allItems) ? [...allItems] : null;

    if (itemsToPaginate && filtersCount) {
      const apply = applyFilters([...itemsToPaginate]);
      itemsToPaginate = apply.items;
    }

    setInitItemsForPaginate(itemsToPaginate);
  }, [allItems, page, perPage, filtersCount]);

  const reset = () => {
    resetFilters();
    setAllItems([...allItems]);

    request({
      filters: {},
    });
  };

  const apply = () => {
    const apply = applyFilters([...allItems]);

    if (!serverConfig.method) {
      setInitItemsForPaginate(apply.items);
    }

    request({ filters: apply.params });
    setFiltersCount(getFiltersCount());
  };

  const request = useCallback(

    (attributes?: IAttributesTweak) => {
      if (serverConfig.method) {
        setIsLoading(true);

        let params = {
          ...getParamsWithoutPagination(),
          ...getParamsPagination(),
        };

        if (attributes?.filters) {
          if (isEmpty(attributes.filters)) {
            params = {
              page: params['page'],
              perPage: params['perPage'],
            };
          } else {
            for (const paramKey in attributes.filters) {
              params[toRequestFormat(paramKey)] = attributes.filters[paramKey];
            }
          }
        }

        if (attributes?.page) {
          params['page'] = attributes.page;
        }

        if (attributes?.perPage) {
          params['perPage'] = attributes.perPage;
        }

        if (serverConfig.handlers.mutator) {
          (params as {}) = serverConfig.handlers.mutator(params);
        }

        let isPageUnacceptable = false;

        if (requestParams) {
          params = {...requestParams, ...params};
        }

        serverConfig
          .method(params)
          .then(response => {
            if (response.count > 0 && params['page'] > response.count) {
              isPageUnacceptable = true;
              pageToUrl(DEFAULT_PAGE_NUMBER);
              perPageToUrl(response.perPage);

              request({filters: attributes?.filters, page: DEFAULT_PAGE_NUMBER, perPage: response.perPage});
            } else {
              setItems(response.items);
              setItemsCount(response.count);
              setPerPage(response.perPage);
            }
          })
          .catch(() => {
            window.location.href = getUrl(window.location.pathname, false);
          })
          .finally(() => {
            if (!isPageUnacceptable) {
              setIsLoading(false);
            }
          });
      }
    },
    [searchParams.length, serverConfig.method]
  );

  return {
    page,
    index,
    count,
    items: paginatedItems,
    allItems,
    filters,
    filtersCount,
    requestParams,

    isFiltersOpen,
    isLoading: serverConfig.isLoading,

    setFilters,
    setShowFilters: setIsFiltersOpen,
    setServerMethod,
    setMutator,
    setPerPage,
    setPage,
    setRequestParams,
    setItems: setAllItems,

    addFilter,
    apply,
    reset,
    request,

    getPaginatable: {
      items: paginatedItems ?? [],
      count,
      setPage,
      page,
      pageIndex: index,
    },
    getDialogFilterable: {
      filters,
      addFilter,
      isFiltersOpen,
      closeFilters: () => setIsFiltersOpen(false),
      applyFilters: apply,
    },
    getFilterable: {
      openFilters: () => setIsFiltersOpen(true),
      reset,
      filtersCount,
    },
  };
};
