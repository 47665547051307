import React, {useEffect, useState} from 'react';
import {IRuleReport, IRuleReportDataBigTransaction} from '../../../../types/app/fraud/RuleReport';
import BackButton from '../../../../UI/Button/BackButton';
import {Box, Card, Grid, Typography} from '@mui/material';
import Divider from '../../../../UI/Delimiter/Divider';
import {getDateTime, getRuleName} from '../../../../utils/fraudUtils';
import {ShopService} from '../../../../http/shop/ShopService';
import TypographyLoader from '../../../Common/Loader/TypographyLoader';
import {IOrderDetail} from '../../../../types/app/shop/OrderDetail';
import {useFraudDataset} from '../hooks/useFraudDataset';
import EmptyCell from '../../../../UI/Table/EmptyCell';
import {IIdentificationVirtualCard} from '../../../../types/app/account/Identification';
import {IClient} from '../../../../types/app/client/Client';

const BigTransactionFraudShowCard = (props: IRuleReport) => {
  const [card, setCard] = useState<IIdentificationVirtualCard>();
  const [clientDetails, setClientDetails] = useState<IClient>();

  const ruleData = props.data as IRuleReportDataBigTransaction;
  const [order, setOrder] = useState<IOrderDetail>(null);
  const { clients, partner } = useFraudDataset({
    partnerId: ruleData.partnerId,
    userIds: [props.userId],
  });

  useEffect(() => {
    if (clients) {
      setClientDetails(clients[0] ?? null)
      setCard(clients[0]?.card ?? null);
    }
  }, [clients]);

  useEffect(() => {
    ShopService.getOrder({
      order_id: ruleData.orderId,
    }).then(response => {
      if (!response) {
        setOrder(undefined);

        return;
      }

      setOrder(response);
    });
  }, []);

  return (
    <Box>
      <BackButton />

      <Card sx={{ padding: '70px 50px', width: '85%' }}>
        <Box>
          <Typography mb={1} variant="body2" color="grey.600">
            Наименование правила:
          </Typography>
          <Typography variant="h2">{getRuleName(props.ruleSetting.rule.name)}</Typography>
        </Box>

        <Divider />

        <Grid container columns={4} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Допустимый размер чека:
            </Typography>
            <Typography>{ruleData.ordersAmountMax ?? <EmptyCell />}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Фактический размер чека:
            </Typography>
            <Typography>{ruleData.ordersAmount ?? <EmptyCell />}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Дата и время срабатывания:
            </Typography>
            <Typography>{getDateTime(props.sentAt)}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Идентификатор транзакции:
            </Typography>
            <TypographyLoader object={order} index="transactionId" />
          </Grid>
        </Grid>

        <Divider />

        <Grid container columns={4} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              ФИО Пользователя:
            </Typography>
            <TypographyLoader object={clientDetails?.personalData} index="fullName" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Телефон Пользователя:
            </Typography>
            <TypographyLoader object={clientDetails?.personalData} index="phone" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Email Пользователя:
            </Typography>
            <TypographyLoader object={clientDetails} index="email" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Номер виртуальной карты Пользователя:
            </Typography>
            <TypographyLoader object={card} index="number" />
          </Grid>
        </Grid>

        <Divider />

        <Grid container columns={4} alignItems="flex-start">
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              ID терминальной точки:
            </Typography>
            <Typography>{ruleData.partnerTerminalId}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Наименование организации Партнера:
            </Typography>
            <TypographyLoader object={partner} index="label" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Телефон Партнера:
            </Typography>
            <TypographyLoader object={partner} index="phone" />
          </Grid>
          <Grid item xs={1}>
            <Typography mb={1} variant="body2" color="grey.600">
              Email Партнера:
            </Typography>
            <TypographyLoader object={partner} index="email" />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default BigTransactionFraudShowCard;