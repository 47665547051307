import React, { useState } from 'react';
import { IPaginatable } from '../../../types/app/common/Paginatable';
import { INews } from '../../../types/app/communication/News';
import TableContainer from '../../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Grid, Typography } from '@mui/material';
import { Image } from 'mui-image';
import ShowButton from '../../../UI/Button/ShowButton';
import DeleteButton from '../../../UI/Button/DeleteButton';
import EmptyCell from '../../../UI/Table/EmptyCell';
import { unix } from 'moment';
import LoopIcon from '@mui/icons-material/Loop';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { RouteList } from '../../../http/RouteList';
import Confirm from '../../Common/Modal/Confirm';
import { CommunicationService } from '../../../http/communication/CommunicationService';
import { getNewsItemType } from '../../../utils/communicationUtils';
import { IGroupsTemplate } from '../../../types/app/communication/GroupsTemplate';

interface INewsTabDataProps extends IPaginatable<INews> {
  dispatch: Function;
  allItems: INews[];
  groups: IGroupsTemplate[];
  active: boolean;
}

const NewsTabData = (props: INewsTabDataProps) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<INews>(null);

  const openConfirm = (newsItem: INews) => {
    setToDelete(newsItem)
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setToDelete(null)
    setShowConfirm(false);
  };

  const deleteHandler = async () => {
    setIsLoading(true);

    await CommunicationService.deactivate({id: toDelete.id});

    props.dispatch(props.allItems.filter(item => item.id !== toDelete.id));

    closeConfirm();
  }
  
  return (
    <>
      <Confirm
        open={showConfirm}
        close={closeConfirm}
        loading={isLoading}
        submit={deleteHandler}
        text='Вы действительно хотите удалить рассылку? Данное действие нельзя будет отменить.'
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {!props.active && <TableCell width={150}>Уникальный идентификатор уведомления</TableCell>}
              <TableCell width={100}>Изображение</TableCell>
              <TableCell width={150}>Заголовок</TableCell>
              <TableCell width={300}>Текст</TableCell>
              <TableCell width={200}>Вид рассылки</TableCell>
              <TableCell width={270}>Тип уведомления</TableCell>
              <TableCell width={250}>Отправлено на email</TableCell>
              <TableCell width={250}>Отправлено в ЛК</TableCell>
              <TableCell width={250}>Прочитано в ЛК</TableCell>
              <TableCell width={150}>Дата отправки</TableCell>
              <TableCell width={150}>Время отправки</TableCell>
              <TableCell width={300}>Режим отправки</TableCell>
              <TableCell width={150}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map(newsItem => (
              <TableRow key={newsItem.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {!props.active && <TableCell>{newsItem.id}</TableCell>}
                <TableCell>
                  {
                    <Grid container justifyContent="center">
                      <Image width={30} duration={0} src={newsItem?.params?.icon ?? ''} />
                    </Grid>
                  }
                </TableCell>
                <TableCell>{newsItem.title}</TableCell>
                <TableCell>{newsItem.description ?? <EmptyCell />}</TableCell>
                <TableCell>{newsItem?.template.web.category ?? <EmptyCell />}</TableCell>
                <TableCell>{getNewsItemType(newsItem, props.groups)}</TableCell>
                <TableCell>{newsItem.mailCreatedCount ?? <EmptyCell />}</TableCell>
                <TableCell>{newsItem.webCreatedCount ?? <EmptyCell />}</TableCell>
                <TableCell>{newsItem.webMarkedCount ?? <EmptyCell />}</TableCell>
                <TableCell>{newsItem.start ? unix(newsItem.start).format('DD.MM.YYYY') : <EmptyCell />}</TableCell>
                <TableCell>{newsItem.start ? unix(newsItem.start).format('HH:mm') : <EmptyCell />}</TableCell>
                <TableCell>
                  {newsItem.periodicity ? (
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <LoopIcon fontSize="small" color="success" />
                      </Grid>
                      <Grid item>
                        <Typography color="success.main">Периодичная</Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <AccessTimeIcon fontSize="small" color="error" />
                      </Grid>
                      <Grid item>
                        <Typography color="error.main">Разовая</Typography>
                      </Grid>
                    </Grid>
                  )}
                </TableCell>
                <TableCell>
                  <Grid container justifyContent="flex-end" flexDirection="row">
                    <Grid item>
                      <ShowButton linkProps={{ to: RouteList.NOTIFICATIONS_NEWS_SHOW + '/' + newsItem.id }} />
                    </Grid>
                    <Grid item>
                      {props.active && <DeleteButton onClick={() => openConfirm(newsItem)} />}
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default NewsTabData;
