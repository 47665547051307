export enum OperationTypes {
  RUB = 'rub',
  RUBLS = 'rubls',
  BLOCK_RUB = 'block_rub',
  POINT = 'point',
  INCOMPLETE_POINT = 'incomplete_point',
  BLOCK_POINT = 'block_point',
  ACCOUNT = 'account',
  CERTIFICATE = 'certificate',
  INCOME = 'income',
}
