export enum GenderTypes {
  F,
  M,
}

export enum GenderSymbols {
  F = 'f',
  M = 'm',
}

/**
 * Get gender type
 *
 * @param {string|null} genderSymbol
 * @private
 */
export const getGenderType = (genderSymbol: string | null): number => {
  if (genderSymbol === null) {
    return GenderTypes.M;
  }

  return genderSymbol === GenderSymbols.F ? GenderTypes.F : GenderTypes.M;
}
