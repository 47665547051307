export const messages = {
  info: {
  },
  success: {
    rankCreate: 'Статус успешно добавлен.',
    rankChange: 'Статус успешно изменен.',
    fundsOperation: 'Операция успешно завершена.',
    reportSendOperation: 'Отчет успешно отправлен.',
    reportCreateOperation: 'Отчет начал формироваться.',
    reportDeleteOperation: 'Отчет успешно удален.',
    clientDeleteOperation: 'Учетная запись успешно удалена.',
    reportRulesCreateOperation: 'Формирование отчета запланировано успешно.',
    fraudSettingUpdated: 'Правило обновлено. Изменения сохранены.',
    promoCreated: 'Вы добавили новую Акцию. Пожалуйста, пройдите в раздел Уведомления и сделайте оповещение для Пользователей о начале Акции.',
    promoUpdated: 'Акция обновлена. Измненения сохранены.',
    emailReset: 'Письмо с инструкциями было направлено на email.',
    fraudSettingCreated: 'Новое правило фрод мониторинга успешно создано.',
    catalogProductUpload: 'Файл успешно загружен.',
    certificateBlocked: 'Сертификат заблокирован.',
    certificateDeactivated: 'Сертификат деактивирован.',
    newsCreated: 'Новая рассылка успешно добавлна.',
    newsUpdated: 'Рассылка успешно обновлена.',
    familyProgramOn: 'Ведения семейных счетов в системе включено.',
    familyProgramOff: 'Ведения семейных счетов в системе выключено.',
    daysLimitSet: 'Минимальное количество дней установлено.',
    tariffCreated: 'Новый тариф создан.',
    testNotificationSent: 'Оповещение успешно отправлено на указанный email.',
    certificatesCreated: 'Выпуск сертификатов успешно завершен.',
    successUploadingBalance: 'Баланс успешно загружен.'
  },
  warnings: {
    warningUploadingBalance: 'Баланс загружен не полностью. Данные о загрузке выведены в отчет.'
  },
  errors: {
    formValidationErrors: 'Проверьте правильность введенных данных.',
    passwordResetAttemptLimit: 'Превышено допустимое количество попыток для восстановления пароля. Пожалуйста попробуйте позднее.',
    passwordResetUserNotFound: 'Пароль для данного Пользователя не был сброшен.',
    generalError: 'Ошибка, попробуйте еще раз.',
    deleteClientError: 'Ошибка, учетная запить не была удалена.',
    deleteCertificateError: 'Ошибка деактивации сертификата.',
    wrongDateInThePastChosen: 'Нельзя выбрать прошедшую дату',
    wrongEndDateChosen: 'Дата завершения действия тарифа не может быть меньше даты начала действия',
    queryError: 'Текущий запрос не может быть обработан.',
    fraudSettingUpdateError: 'Ошибка, правило не обновлено.',
    certificateProductError: 'Эмиссия товарного сертификата невозможна без указанных товаров.',
    createCertificateError: 'Невозможно произвести эмиссию сертификата для данного пользователя.',
    errorDownloadingBalance: 'Во время выгрузки баланса произошла ошибка. Операция отменена.',
    errorUploadingBalance: 'Во время загрузки баланса произошла ошибка. Операция отменена.',
  },
  confirm: {
  }
}
