import React, {useContext, useEffect, useState} from 'react';
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import {getSelectNameByValue, IFormSelectMultiple} from './FormSelect';
import {FormContext, IFormContext} from '../../../store/context/form/FormContext';
import ClearIcon from '@mui/icons-material/Clear';
import {v4 as uuid4} from 'uuid';
import FormSelectAllTweak from './FormSelectAllTweak';
import FormSelectRemoveAllTweak from './FormSelectRemoveAllTweak';
import CircleIcon from '@mui/icons-material/Circle';
import RemoveIcon from '@mui/icons-material/Remove';

const FormSelectMultiple = React.forwardRef((props: IFormSelectMultiple, ref) => {
  const {config} = useContext(FormContext) as IFormContext;
  const selectId = uuid4();

  const [selected, setSelected] = useState<number[]>(getSelected());

  const handleDelete = index => setSelected(selected.filter((selectedIndex: number) => selectedIndex !== index));

  function getSelected(): number[] {
    if (props.selectProps?.defaultValue) {
      return props.selectProps.defaultValue as number[];
    }

    if (props.selectProps?.value) {
      return props.selectProps.value as number[];
    }

    return [];
  }

  useEffect(() => {
    if (props.onSelectedChange) {
      props.onSelectedChange(selected);
    }
  }, [selected])

  return (
    <Box mb={1} position="relative">
      <Grid container justifyContent="space-between" columns={12} alignItems="center">
        <Grid item xs={10}>
          {props.title && (
            <Typography marginBottom={1} variant="body2" color="grey.700">
              {props.title}:
            </Typography>
          )}
        </Grid>
        {!config.readonly && (
          <Grid item xs={2} position="relative">
            <Grid container justifyContent="flex-end" spacing={5} pr={4} position="absolute" top={-25}>
              {props?.multipletweaks?.selectAll && selected.length !== props.items.length && (
                <Grid item xs={1}>
                  <FormSelectAllTweak onClick={() => {
                    props.multipletweaks.selectAllItemsAction && props.multipletweaks.selectAllItemsAction()
                    setSelected(props.items.map(item => Number(item.key)))}
                  }/>
                </Grid>
              )}
              {props?.multipletweaks?.unselectAll && selected.length !== 0 && (
                <Grid item xs={1}>
                  <FormSelectRemoveAllTweak onClick={() => {
                    if (props.onBeforeClearAll) {
                      props.onBeforeClearAll(selected);
                    }

                    setSelected([]);

                    if (props.onAfterClearAll) {
                      props.onAfterClearAll();
                    }
                    props.multipletweaks.removeAllItemsAction && props.multipletweaks.removeAllItemsAction()
                  }}/>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      {config.readonly ? (
        <Box>
          {props.title == null && props.selectProps?.label && (
            <Typography mb={1} variant="body1" color="grey.500">
              {props.selectProps.label}
            </Typography>
          )}
          {selected.length !== 0 ? (
            <List>
              {selected.map(select => (
                <ListItemText key={uuid4()}>
                  <Typography variant="body1">
                    <CircleIcon sx={{fontSize: 8, mr: 1}}/>
                    {getSelectNameByValue(props.items, select)}
                  </Typography>
                </ListItemText>
              ))}
            </List>
          ) : (
            <RemoveIcon sx={{fontSize: 20}}/>
          )}
          <Typography variant={props.typography ?? 'body1'}></Typography>
        </Box>
      ) : (
        <FormControl fullWidth>
          {props.selectProps?.label && (
            <Box mb={1}>
              <InputLabel sx={{ml: 1, pt: 0.3, mb: 1}} htmlFor={selectId}>
                {props.selectProps.label}
              </InputLabel>
            </Box>
          )}
          <Select
            fullWidth
            id={selectId}
            input={<OutlinedInput ref={ref}/>}
            renderValue={(selected: number[]) => {
              if (!Array.isArray(selected)) {
                const selectedItem = props.items.find(item => item.key === selected);

                if (selectedItem) {
                  return selectedItem.label;
                }

                return undefined;
              }

              return (
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                  {selected.map(value => (
                    <Chip
                      deleteIcon={
                        <ClearIcon
                          sx={[
                            theme => ({
                              color: theme.palette.error.main + ' !important',
                            }),
                            {
                              height: 18,
                            },
                          ]}
                          onMouseDown={event => event.stopPropagation()}
                        />
                      }
                      sx={[
                        theme => ({
                          border: '1px solid ' + theme.palette.grey[300],
                          padding: 1,
                        }),
                        {
                          height: 25,
                          bgcolor: '#F9FCFF',
                          backgroundColor: props.multipletweaks?.disabled && '#eeefef',
                          '& .MuiChip-deleteIcon:hover': {
                            cursor: 'pointer',
                            bgcolor: 'rgba(0,0,0, .1)',
                            borderRadius: 8,
                          },
                          '& .MuiChip-deleteIcon': {
                            display: props.multipletweaks?.disabled && 'none'
                          },
                        },
                      ]}
                      onDelete={() => handleDelete(value)}
                      key={value}
                      label={
                        <Typography variant="body2" fontFamily="Lato-SemiBold">
                          {getSelectNameByValue(props.items, value)}
                        </Typography>
                      }
                    />
                  ))}
                </Box>
              );
            }}
            {...props.selectProps}
            onChange={(e, node) => {
              if (props.beforeOnchange) {
                props.beforeOnchange(e);
              }

              setSelected(e.target.value as number[]);

              if (props.selectProps?.onChange) {
                props.selectProps.onChange(e, node);
              }
            }}
            value={selected}
          >
            {props.items.map(item => (
              <MenuItem disabled={item.disabled !== undefined ? item.disabled : false} key={item.key} value={item.key}>
                <Typography sx={{mt: '1px'}} color={item.color ?? ''}>
                  {item.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
});

export default FormSelectMultiple;
