import React from 'react';
import {Box, Button, Grid} from '@mui/material';
import FilterGroupButtons from '../../UI/Button/FilterGroupButtons';
import PerPage from '../../UI/Table/PerPage';
import { IFilterable } from '../../types/app/common/Filterable';
import {RouteList} from '../../http/RouteList';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import {useNavigate} from 'react-router-dom';

const CertificateTabDataTweaks = (props: IFilterable) => {
  const route = useNavigate();

  return (
    <Box mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item >
          <Button onClick={() => route(RouteList.CREATE_CERTIFICATE)} endIcon={<CardGiftcardOutlinedIcon />}>
            Эмиссия сертификата
          </Button>
        </Grid>
        <Grid item>
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>

        <Grid item>
          <PerPage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CertificateTabDataTweaks;