import React, {useContext, useEffect, useState} from 'react';
import {useAsyncValue} from 'react-router-dom';
import MainForm from '../../../Common/Form/MainForm';
import {RouteList} from '../../../../http/RouteList';
import {Typography} from '@mui/material';
import Divider from '../../../../UI/Delimiter/Divider';
import FormActions from '../../../../UI/Form/FormActions';
import {FormContext} from '../../../../store/context/form/FormContext';
import {useSnackbar} from 'notistack';
import {snackConfig} from '../../../../layout/assets/config/snackConfig';
import {getAntispamPeriodicityName} from '../../../../enums/AntispamPeriodicity';
import FormSelect from '../../../../UI/Form/Select/FormSelect';
import {IGetAntispamLoader} from '../../../../http/communication/CommunicationLoaders';
import {getGroupTypeTranslation} from '../../../../enums/Groups';

const NotificationAntispamShowForm = () => {
  const {
    template,
    groups,
    periodicityTypes,
  } = useAsyncValue() as IGetAntispamLoader;

  const [numbers, setNumbers] = useState([])

  const { enqueueSnackbar } = useSnackbar();

  const { setConfig } = useContext(FormContext);

  useEffect(() => {
    setConfig({
      readonly: true,
      action: RouteList.NOTIFICATIONS_ANTISPAM_SHOW + '/' + template.id,
      rules: {
        periodicity: ['required'],
        quantity: ['required'],
        groups: ['required'],
      },
      afterSubmitHandler: (formResponse, config, setConfig) => {
        enqueueSnackbar('Правило обновлено. Изменения сохранены.', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        })

        setConfig({...config, readonly: true});
      },
    });
  }, []);

  useEffect(() => {
    const arr = []
    for (let i = 1; i <= 99; i++) {
      arr.push({key: i, label: i})
    }

    setNumbers(arr)
  }, [])

  return (
    <MainForm back={RouteList.NOTIFICATIONS}>
      <Typography variant="h1" mb={5}>
        Создание шаблона
      </Typography>

      <FormSelect
        title={'Частота отправки'}
        selectProps={{
          defaultValue: template.periodicity,
          name: 'periodicity',
        }}
        items={periodicityTypes.map(type => ({key: type, label: getAntispamPeriodicityName(type)}))}
      />

      <Divider />

      <FormSelect
        title={'Условия'}
        selectProps={{
          defaultValue: template.quantity,
          name: 'quantity',
        }}
        items={numbers}
      />

      <Divider />

      <FormSelect
        title={'Для кого'}
        items={groups.map(group => ({ key: group.id, label: getGroupTypeTranslation(group.name) }))}
        selectProps={{
          defaultValue: template.groups,
          name: 'groups',
          multiple: true,
        }}
        multipletweaks={{
          selectAll: true,
          unselectAll: true,
        }}
      />

      <FormActions />
    </MainForm>
  );
};

export default NotificationAntispamShowForm;
