import React from 'react';
import { FormControlLabel, FormGroup, Grid } from '@mui/material';
import IOSSwitch from '../../../UI/Switch/IOSSwitch';
import { IFilter } from '../../../hooks/table/useFilters';
import {ISelectable} from '../../../types/app/common/Selectable';
import { IFilters } from '../../../store/rtk/reducers/serverPaginationSlice';

interface IFilterSwitchProps {
  label: string;
  field: string;
  items: ISelectable[];
  addFilter: (filter: IFilter) => void;
  filters: IFilters;
}

const FilterSwitch = (props: IFilterSwitchProps) => {
  return (
    <Grid container justifyContent="space-between" columns={12} alignItems="center">
      <Grid item xs={2}>
        {props.label}
      </Grid>
      <Grid item xs={8} pl={2}>
        <Grid container columns={2}>
          {props.items.map(item => (
            <Grid key={item.key} item xs={1} alignSelf="center" paddingBottom="5px">
              <FormGroup>
                <FormControlLabel
                  onChange={() => {
                    let value = item.key;

                    if (props.filters.eq[props.field] === item.key) {
                      value = null;
                    }

                    props.addFilter({ name: props.field, value, type: 'eq' })
                  }}
                  checked={props.filters.eq[props.field] !== null && props.filters.eq[props.field] === item.key}
                  control={<IOSSwitch />}
                  label={item.label}
                />
              </FormGroup>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterSwitch;
