import React from 'react';
import {Box, BoxProps} from '@mui/material';
import Typography from '@mui/material/Typography';

interface IContentContainerProps {
  title?: string,
  children?: React.ReactNode,
  boxProps?: BoxProps,
}

const ContentContainer = (props: IContentContainerProps) => {
  return (
    <Box width="100%">
      {props.title &&
          <Box paddingBottom="40px">
            <Typography variant="h1">{props.title}</Typography>
          </Box>
      }

      {props.children ?? ''}
    </Box>
  );
};

export default ContentContainer;