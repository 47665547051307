import React, { useContext, useLayoutEffect } from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { IGetRequisitesLoader } from '../../../http/account/AccountLoaders';
import RequisitesForm from '../../../Widgets/Requisites/RequisitesForm';
import { FormContext, IFormConfig } from '../../../store/context/form/FormContext';
import { RouteList } from '../../../http/RouteList';
import { snackConfig } from '../../../layout/assets/config/snackConfig';
import { useSnackbar } from 'notistack';

const RequisiteIndex = () => {
  const { requisites } = useLoaderData() as IGetRequisitesLoader;
  const { enqueueSnackbar } = useSnackbar();

  const { setConfig } = useContext(FormContext);

  const formConfig: IFormConfig = {
    action: RouteList.REQUISITES,
    readonly: true,
    afterSubmitHandler(response, config, setConfig) {
      enqueueSnackbar('Изменения успешно сохранены.', {
        variant: 'success',
        autoHideDuration: snackConfig.duration,
      });

      setConfig({ ...config, readonly: true });
    },
  };

  useLayoutEffect(() => setConfig(formConfig), []);

  return (
    <Await resolve={requisites}>
      <RequisitesForm />
    </Await>
  );
};

export default RequisiteIndex;
