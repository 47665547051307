import React, {useEffect, useMemo, useState} from 'react';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {Button, Grid} from '@mui/material';
import {useActions, useAppSelector} from '../../../hooks/hooks';
import {getUserNotification} from '../../../store/rtk/store';
import {ILastNotice, INotification} from '../../../types/app/common/Notification';
import ContentContainer from '../../Layout/Content/ContentContainer';
import NotificationsFilters, {
  NotificationFiltersType,
  NotificationSort
} from './NotificationsFilters';
import {CommunicationService} from '../../../http/communication/CommunicationService';
import {useNotifications} from '../../../hooks/useNotifications';
import EmptyWidget from '../../Common/Empty/GeneralEmptyWidget';
import NotificationShow from './Operations/NotificationShow';
import NotificationsContentData from './NotificationsContentData';
import Divider from '../../../UI/Delimiter/Divider';

const PER_PAGE = 10;

const NotificationTabMain = () => {
  const notification = useAppSelector(getUserNotification) as INotification;
  const { updateLastNoticeAsRead, clearUnreadNoticeCount } = useActions();
  const { error } = useNotifications();

  const [lastNotices, setLastNotices] = useState([...notification.lastNotices]);
  const [filter, setFilter] = useState<NotificationFiltersType>(NotificationFiltersType.ALL);
  const [sort, setSort] = useState<NotificationSort>(NotificationSort.DESC);
  const [page, setPage] = useState<number>(1);

  const [currentNotice, setCurrentNotice] = useState<ILastNotice>(null);

  useEffect(() => {
    setLastNotices(notification.lastNotices);
  }, [notification.lastNotices]);

  useEffect(() => {
    if (notices.slice(index, offset).length === 0 && lastNotices.length !== 0) {
      setPage(1);
    }
  }, [filter]);

  const handleAllAsRead = () => {
    CommunicationService.webNotificationsMarkAsReadAllBeforeThis({ all_notifications_are_already_read_before_this_notification_id: lastNotices[0].id }).then(() => {
      updateLastNoticeAsRead();
      clearUnreadNoticeCount();
    }).catch(e => error(e.message))
  }

  /**
   * Dispatch filter
   *
   * @param {NotificationFiltersType} filterType
   */
  const dispatchFilter = (filterType: NotificationFiltersType): void => {
    setFilter(filterType);
  };

  /**
   * Dispatch page
   *
   * @param {number} page
   */
  const dispatchPage = (page: number): void => {
    setPage(page);
  };

  /**
   * Dispatch sot
   */
  const dispatchSort = (): void => {
    setSort(sort === NotificationSort.ASC ? NotificationSort.DESC : NotificationSort.ASC);
  };

  const notices = useMemo(() => {
    let result: ILastNotice[];

    switch (filter) {
      case NotificationFiltersType.ALL: {
        result = [...notification.lastNotices];

        break;
      }
      case NotificationFiltersType.NEW: {
        result = [...notification.lastNotices].filter(notice => notice.read === false);

        break;
      }
      case NotificationFiltersType.READ: {
        result = [...notification.lastNotices].filter(notice => notice.read === true);

        break;
      }

      default:
        result = [];
    }

    if (sort === NotificationSort.DESC) {
      return result.sort((s1, s2): number => {
        if (s1.createdAt > s2.createdAt) return -1;
        if (s1.createdAt === s2.createdAt) return 0;
        if (s1.createdAt < s2.createdAt) return 1;
      });
    }

    if (sort === NotificationSort.ASC) {
      return result.sort((s1, s2) => {
        if (s1.createdAt < s2.createdAt) return -1;
        if (s1.createdAt === s2.createdAt) return 0;
        if (s1.createdAt > s2.createdAt) return 1;
      });
    }

    return result;
  }, [filter, sort, lastNotices]);

  const index = (page - 1) * PER_PAGE;
  const offset = index + PER_PAGE;

  if (notification.lastNotices.length === 0) {
    return (
      <EmptyWidget
        description="Здесь можно добавить краткое описание с информацией или призывом к действию"
        icon={<NotificationsActiveIcon sx={{ fontSize: 150, color: '#E0EEFF' }} />}
        title="Нет уведомлений"
      />
    );
  }

  if (currentNotice) {
    return (
      <NotificationShow
        notices={lastNotices}
        setLastNotices={setLastNotices}
        setCurrentNotice={setCurrentNotice}
        notice={currentNotice}
      />
    );
  }

  return (
    <ContentContainer title="Уведомления" boxProps={{ width: '80%' }}>

      <Grid container mt={1} mb={3} alignItems={'center'}>
        <NotificationsFilters sort={sort} setSort={dispatchSort} setFilter={dispatchFilter} filter={filter} />
        <Button
          onClick={handleAllAsRead}
          disabled={lastNotices.every(notice => notice.read)}
          sx={[(theme) => ({ color: theme.palette.primary.main, marginLeft: 'auto' })]}
          variant="text"
        >
          Отметить все как прочитанные
        </Button>
      </Grid>
      <Divider />
      <NotificationsContentData
        show={setCurrentNotice}
        notices={notices.slice(index, offset)}
        count={Math.ceil(notices.length / 10)}
        page={page}
        setPage={dispatchPage}
      />
    </ContentContainer>
  );
};

export default NotificationTabMain;
