import React from 'react';
import TableContainer from '../../UI/Table/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ShowButton from '../../UI/Button/ShowButton';
import {RouteList} from '../../http/RouteList';
import moment from 'moment';
import {IPaginatable} from '../../types/app/common/Paginatable';
import {IOrder} from '../../types/app/shop/Order';
import {getColorByStatusName, getOrderStatusName} from '../../enums/OrderStatuses';
import EmptyCell from '../../UI/Table/EmptyCell';
import {IClient} from '../../types/app/client/Client';
import {IPartner} from '../../types/app/partner/Partner';

interface OrderTabContentProps extends IPaginatable<IOrder>{
  clients: Map<number, IClient>
  partners: Map<number, IPartner>
}

const OrdersTabContentData = (props: OrderTabContentProps) => {

  return (
    <TableContainer boxprops={{ style: {top: '-16px'}}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head" width={125}>Дата</TableCell>
            <TableCell variant="head" width={80}>Время</TableCell>
            <TableCell variant="head" width={150}>Номер заказа</TableCell>
            <TableCell variant="head" width={100}>Стоимость заказа</TableCell>
            <TableCell variant="head" width={100}>Сумма, оплаченная по карте</TableCell>
            <TableCell variant="head" width={100}>Начисленный бонус</TableCell>
            <TableCell variant="head" width={235}>Email Пользователя</TableCell>
            <TableCell variant="head" width={200}>Наименование организации Партнера</TableCell>
            <TableCell variant="head" width={100}>Статус</TableCell>
            <TableCell variant="head" width={30}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map(orderItem => (
            <TableRow key={orderItem.id} sx={{'&:last-child td, &:last-child th': {border: 0}, width: '100%'}}>
              <TableCell>{moment.unix(orderItem.createdAt).format('DD.MM.YYYY')}</TableCell>
              <TableCell>{moment.unix(orderItem.createdAt).format('HH:mm')}</TableCell>
              <TableCell>{orderItem.showNumber}</TableCell>
              <TableCell>{Number(orderItem.orderAmount).toLocaleString()} ₽</TableCell>
              <TableCell>
                {
                  orderItem.paymentAmount !== 0
                    ? orderItem.paymentAmount.toLocaleString() + ' ₽'
                    : <EmptyCell />
                }
              </TableCell>
              <TableCell>
                {
                  orderItem.accrualBonus !== 0
                    ? orderItem.accrualBonus.toLocaleString() + ' баллов'
                    : <EmptyCell />
                }
              </TableCell>
              <TableCell>{props.clients.get(orderItem.userId)?.email ?? <EmptyCell />}</TableCell>
              <TableCell>{props.partners.get(orderItem.partnerId)?.label ?? <EmptyCell />}</TableCell>
              <TableCell
                sx={{ color: getColorByStatusName(orderItem.statusId) }}
              >
                {getOrderStatusName(orderItem.statusId)}
              </TableCell>
              <TableCell>
                <ShowButton linkProps={{to: RouteList.ORDERS_SHOW + '/' + orderItem.id}}/>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTabContentData;