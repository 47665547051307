import React from 'react';
import TabPanel from '../../../UI/Tab/TabPanel';
import Tabs from '../../../UI/Tab/Tabs';
import ContentContainer from '../../../Widgets/Layout/Content/ContentContainer';
import ClientsTabContentMain from '../../../Widgets/Clients/ClientsTab/ClientsTabContentMain';
import ClientRanksTabContentMain from '../../../Widgets/Clients/ClientRanksTab/ClientRanksTabContentMain';
import StructureTabContentMain from '../../../Widgets/Clients/StructureTab/StructureTabContentMain';

const ClientIndex = () => {
  return (
    <ContentContainer title="Управление Пользователями">
      <Tabs
        items={[
          {title: 'Профили Пользователей', tabIndex: 0},
          {title: 'Статусная модель', tabIndex: 1},
          {title: 'Реферальные настройки', tabIndex: 2},
          // {title: 'Семейный счет', tabIndex: 3},
        ]}
      />

      <TabPanel index={0} width={100}>
        <ClientsTabContentMain />
      </TabPanel>

      <TabPanel index={1} minheight={600}>
        <ClientRanksTabContentMain />
      </TabPanel>

      <TabPanel index={2} minheight={500}>
        <StructureTabContentMain />
      </TabPanel>

      {/*<TabPanel index={3} minheight={1100} width={100}>*/}
      {/*  <FamilyTabContentMain />*/}
      {/*</TabPanel>*/}
    </ContentContainer>
  );
};

export default ClientIndex;