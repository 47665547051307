import {useSnackbar} from 'notistack';
import {snackConfig} from '../layout/assets/config/snackConfig';

export const useNotifications = () => {
  const {enqueueSnackbar} = useSnackbar();

  const success = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'success',
      autoHideDuration: snackConfig.duration
    })
  }

  const error = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'error',
      autoHideDuration: snackConfig.duration,
    })
  }

  const info = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'info',
      autoHideDuration: snackConfig.duration
    })
  }

  const warning = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'warning',
      autoHideDuration: snackConfig.duration
    })
  }

  return {
    success,
    error,
    info,
    warning,
  }
}
