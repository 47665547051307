export class FormDataHelper {
  /**
   * Transform FormData to data object
   *
   * @param formData
   */
  public static toObject<T>(formData: FormData): T {
    const result = [] as T;

    Array.from(formData.entries()).forEach(entry => {
      result[entry[0]] = entry[1].toString().trim();
    });

    return Object.assign({}, result);
  }
}
