import React, { useContext, useEffect } from 'react';
import { RouteList } from '../../../http/RouteList';
import MainForm from '../../../Widgets/Common/Form/MainForm';
import { Typography } from '@mui/material';
import FormOutlinedInput from '../../../UI/Form/FormOutlinedInput';
import FormImage from '../../../UI/Form/FormImage';
import Divider from '../../../UI/Delimiter/Divider';
import { MimeTypes } from '../../../enums/MimeTypes';
import FormActions from '../../../UI/Form/FormActions';
import { FormContext } from '../../../store/context/form/FormContext';
import { snackConfig } from '../../../layout/assets/config/snackConfig';
import { useSnackbar } from 'notistack';
import {IFormResponse} from '../../../store/context/form/types/FormResponse';
import {useNavigate} from 'react-router-dom';

const NotificationTemplateCreate = () => {
  const { setConfig } = useContext(FormContext);

  const { enqueueSnackbar } = useSnackbar();

  const route = useNavigate();

  useEffect(() => {
    setConfig({
      action: RouteList.NOTIFICATIONS_TEMPLATE_CREATE,
      rules: {
        header: ['required'],
        category: ['required'],
        short: ['required'],
        long: ['required'],
        icon: ['required'],
      },
      afterSubmitHandler: (formResponse: IFormResponse<number>) => {
        enqueueSnackbar('Добавлен новый шаблон для обращений.', {
          variant: 'success',
          autoHideDuration: snackConfig.duration,
        });

        route(RouteList.NOTIFICATIONS_TEMPLATE_SHOW + '/' + formResponse.payload);
      },
    });
  }, []);

  return (
    <MainForm back={RouteList.NOTIFICATIONS}>
      <Typography variant="h1" mb={5}>
        Создание шаблона
      </Typography>

      <FormOutlinedInput
        title="Заголовок шаблона"
        inputProps={{
          autoFocus: true,
          name: 'header',
        }}
      />

      <Divider />

      <FormImage name="icon" accept={MimeTypes.PNG} title="Изображение (допускается только png)" />

      <Divider />

      <FormOutlinedInput
        title="Категория"
        inputProps={{
          name: 'category',
        }}
      />

      <Divider />

      <FormOutlinedInput
        title="Текст уведомления (сокращенный)"
        inputProps={{
          name: 'short',
          minRows: 2,
          multiline: true,
        }}
      />

      <Divider />

      <FormOutlinedInput
        title="Текст уведомления (полный)"
        inputProps={{
          name: 'long',
          minRows: 5,
          multiline: true,
        }}
      />

      <FormActions />
    </MainForm>
  );
};

export default NotificationTemplateCreate;
