import React from 'react';

import {Await, useLoaderData} from 'react-router-dom';
import ReportShowForm from '../../../Widgets/Reports/ReportsTab/Operations/ReportShowForm';
import {IGetReportLoader} from '../../../http/reports/ReportLoaders';

const ReportsShow = () => {
  const {report} = useLoaderData() as { report: Promise<IGetReportLoader> };

  return (
    <Await resolve={report}>
      <ReportShowForm/>
    </Await>
  );
};

export default ReportsShow;
