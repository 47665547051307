import { IRequisites } from '../../types/app/account/Requisites';
import { TCertificate } from '../../types/response/account/GetCertificatesResponse';
import { ICertificate } from '../../types/app/account/Certificate';
import moment from 'moment';
import { TFamilyParent } from '../../types/response/account/GetFamiliesData';
import { IFamilyParent } from '../../types/app/account/Family';
import {TTransactionData, TTransactionDataWithPagination} from '../../types/response/account/GetTransactionsResponse';
import {ITransaction, ITransactionWithPagination} from '../../types/app/account/Transaction';
import { getValueByType } from '../../enums/TransactionTypes';
import { IGetBankUserAccountsData } from '../../types/response/account/GetBankUserAccountsData';
import { IGetBankPartnerAccountsData } from '../../types/response/account/GetBankPartnerAccountsData';
import {IPartnerBankAccountServerPagination, IPartnerBankAccount, IPartnerBankAccountBalance} from '../../types/app/account/PartnerBankAccount';
import {IBalances} from "../../types/app/common/Balances";
import {IAccountsBalances} from "../../types/app/common/IAccountsBalances";
import {IGetBankBalance} from "../../types/response/account/IGetBankBalance";
import {IGetAccountBankBalance} from "../../types/response/account/IGetAccountBankBalance";
import { TAccountSettings } from '../../types/response/account/AccountSettings';
import { IAccountSettings } from '../../types/app/account/Settings';
import {IGetBankClientAccountsData} from '../../types/response/account/GetClientUserAccountsData';
import {IUserBankAccount, IUserBankAccountBalance} from '../../types/app/account/UserBankAccount';
import {IClientBankAccount} from '../../types/app/account/ClientBankAccount';
import {IGetBankPartnerAccountsDataServerPagination} from '../../types/response/account/GetPartnerUserAccountsData';
import {IGetInvoicesBankResponse} from '../../types/response/account/GetInvoicesBankResponse';
import {IInvoicesBankAccount} from '../../types/app/account/InvoicesBankAccount';
import { IGetUserBalancesByExtIdData } from '../../types/response/account/GetUserBalancesByExtIdData';
import { IGetPartnerBalancesByExtIdData } from '../../types/response/account/GetPartnerBalancesByExtIdData';

export class AccountFactory {
  private static getCertificateCategoriesAsArray(categoryResponse: { [categoryId: number]: string } | null) {
    if (categoryResponse === null) {
      return [];
    }

    const result: { categoryId: number; categoryName: string }[] = [];

    for (let catId in categoryResponse) {
      result.push({
        categoryId: Number(catId),
        categoryName: categoryResponse[catId],
      });
    }

    return result;
  }

  private static getCertificateProductsAsArray(productResponse: { [productId: number]: string } | null) {
    if (productResponse === null) {
      return [];
    }

    const result: { productId: number; productName: string }[] = [];

    for (let productId in productResponse) {
      result.push({
        productId: Number(productId),
        productName: productResponse[productId],
      });
    }

    return result;
  }

  public static getTransactionFromResponse(transactionResponse: TTransactionData): ITransaction {
    const time = moment(transactionResponse.time, 'HH:mm');

    return {
      date: moment(transactionResponse.date, 'DD.MM.YYYY').unix(),
      time: moment({
        date: moment(0).date(),
        hours: time.hours(),
        minutes: time.minutes(),
      }).unix(),
      type: getValueByType(transactionResponse.type),
      fromUserExtId: transactionResponse.from_user_ext_id,
      fromUserId: transactionResponse.from_user_id,
      id: transactionResponse.id,
      partnerExtId: transactionResponse.partner_ext_id,
      partnerId: transactionResponse.partner_id,
      partnerName: transactionResponse.partner_name,
      pointsCreditAmount: transactionResponse.points_credit_amount,
      pointsDebitAmount: transactionResponse.points_debit_amount,
      rublsAmount: Math.abs(transactionResponse.rubls_amount),
      userExtId: transactionResponse.user_ext_id,
      userFromName: transactionResponse.user_from_name,
      userId: transactionResponse.user_id,
      userName: transactionResponse.user_name,
    };
  }

  public static getPaginatedTransactionFromResponse(transactionResponse: TTransactionDataWithPagination): ITransactionWithPagination {
    const time = moment(transactionResponse.created_time, 'HH:mm');

    return {
      id: transactionResponse.id,
      transactionId: transactionResponse.transaction_id,
      createdDate: moment(transactionResponse.created_date, 'DD.MM.YYYY').unix(),
      createdTime: moment({
        date: moment(0).date(),
        hours: time.hours(),
        minutes: time.minutes(),
      }).unix(),
      description: transactionResponse.description,
      typeId: transactionResponse.type_id,
      userFromId: transactionResponse.user_from_id,
      userFrom: transactionResponse.user_from,
      userToId: transactionResponse.user_to_id,
      userTo: transactionResponse.user_to,
      credit: +transactionResponse.credit,
      debit: +transactionResponse.debit,
      amount: +transactionResponse.amount,
      amountType: transactionResponse.amount_type,
      userToType: transactionResponse.user_to_type,
      userFromType: transactionResponse.user_from_type
    };
  }

  public static getCertificateFromResponse(certificateResponse: TCertificate): ICertificate {

    return {
      amount: Number(certificateResponse.amount),
      balance: Number(certificateResponse.balance),
      categories: this.getCertificateCategoriesAsArray(certificateResponse.categories),
      code: certificateResponse.code,
      createdAt: certificateResponse.created_at ? moment(certificateResponse.created_at).unix() : null,
      expiresDate: certificateResponse.expires_date ? moment(certificateResponse.expires_date).unix() : null,
      id: certificateResponse.id,
      partners: certificateResponse.partners,
      isMix: certificateResponse.is_mix,
      isPart: certificateResponse.is_part,
      partnerId: certificateResponse.partner_id,
      products: this.getCertificateProductsAsArray(certificateResponse.products),
      status: certificateResponse.status,
      type: certificateResponse.type,
      updatedAt: certificateResponse.updated_at ? moment(certificateResponse.updated_at).unix() : null,
      userId: certificateResponse.user_id,
    };
  }

  public static getRequisitesFromResponse(json: string): IRequisites {
    const parsed = JSON.parse(json) as {
      operator_name: string;
      legal_address: string;
      fact_address: string;
      inn: string;
      kpp: string;
      ogrn: string;
      bank_name: string;
      bank_bik: string;
      bank_account: string;
      bank_operator_account: string;
    };

    return {
      bankAccount: parsed.bank_account,
      bankBik: parsed.bank_bik,
      bankName: parsed.bank_name,
      bankOperatorAccount: parsed.bank_operator_account,
      factAddress: parsed.fact_address,
      inn: parsed.inn,
      kpp: parsed.kpp,
      legalAddress: parsed.legal_address,
      operatorName: parsed.operator_name,
    };
  }

  public static getFamilyParentFromResponse(dataResponse: TFamilyParent): IFamilyParent {
    return {
      balance: dataResponse.balance,
      children: dataResponse.children.map(familyMember => ({
        id: familyMember.id,
        email: familyMember.email,
        lastLoginDate: familyMember.lastLoginDate ? moment(familyMember.lastLoginDate).unix() : null,
        joinDate: moment(familyMember.joinDate).unix(),
        usePoints: familyMember.usePoints,
        isParent: familyMember.isParent,
      })),
      email: dataResponse.email,
      id: dataResponse.id,
      lastLoginDate: dataResponse.lastLoginDate ? moment(dataResponse.lastLoginDate).unix() : null,
    };
  }

  public static getClientBankAccountFromResponse(dataResponse: IGetBankClientAccountsData): IClientBankAccount {
    return {
      pointBalance: +dataResponse.point_balance,
      email: dataResponse.email,
      updatedAt: dataResponse.updated_at ? moment(dataResponse.updated_at).unix() : null,
      createdAt: dataResponse.created_at ? moment(dataResponse.created_at).unix() : null,
      blockPointBalance: +dataResponse.point_block_balance,
      userId: dataResponse.user_id,
    };
  }

  public static getPartnerBankAccountFromResponseServerPagination(dataResponse: IGetBankPartnerAccountsDataServerPagination): IPartnerBankAccountServerPagination {
    return {
      userId: dataResponse.user_id,
      companyName: dataResponse.company_name,
      inn: dataResponse.inn,
      pointBlockBalance: +dataResponse.point_block_balance,
      pointBalance: +dataResponse.point_balance,
      rublsBalance: +dataResponse.rubls_balance,
      rublsBlockBalance: +dataResponse.rubls_block_balance
    };
  }


  public static getUserBankAccountFromResponse(dataResponse: IGetBankUserAccountsData): IUserBankAccount {
    return {
      blockPoint: dataResponse.balances.block_point,
      email: dataResponse.email,
      lastLoginDate: moment(dataResponse.last_login_date).unix(),
      point: dataResponse.balances.point,
      registrationDate: moment(dataResponse.registration_date).unix(),
      userId: dataResponse.user_id,
    };
  }

  public static getPartnerBankAccountFromResponse(dataResponse: IGetBankPartnerAccountsData): IPartnerBankAccount {
    return {
      blockPoint: dataResponse.balances.block_point,
      blockRub: dataResponse.balances.block_rub,
      companyName: dataResponse.company_name,
      inn: dataResponse.inn,
      point: dataResponse.balances.point,
      rub: dataResponse.balances.rub,
      userId: dataResponse.user_id,
    };
  }


  public static getUserBalanceByExtIdResponse(dataResponse: IGetUserBalancesByExtIdData): IUserBankAccountBalance {
    return {
      blockPoint: dataResponse.balances.block_point,
      email: dataResponse.email,
      point: dataResponse.balances.point,
      userId: dataResponse.user_id,
    };
  }

  public static getPartnerBalanceByExtIdResponse(dataResponse: IGetPartnerBalancesByExtIdData): IPartnerBankAccountBalance {
    return {
      userId: dataResponse.user_id,
      email: dataResponse.email ?? '',
      companyName: dataResponse.company_name ?? '',
      inn: dataResponse.inn ?? '',
      blockPoint: dataResponse.balances.block_point ?? 0,
      point: dataResponse.balances.point ?? 0,
      blockRub: dataResponse.balances.block_rub ?? 0,
      rub: dataResponse.balances.rub ?? 0,
    };
  }

  public static getBalanceFromResponce(dataResponse: IGetBankBalance): IBalances {
    return {
      rub: +dataResponse.rub,
      point: +dataResponse.point,
      incompletePoint: +dataResponse.incomplete_point,
      account: +dataResponse.account,
      certificate: +dataResponse.certificate,
      income: +dataResponse.income,
    }
  }

  public static getAccountBalanceFromResponse(dataResponse: IGetAccountBankBalance): IAccountsBalances {
    return {
      rub: +dataResponse.rub,
      blockRub: +dataResponse.block_rub,
      point: +dataResponse.point,
      blockPoint: +dataResponse.block_point,
    }
  }

  public static getAccountAllPropertiesFromResponse(properties: TAccountSettings): IAccountSettings {
    return {
      pointBalanceCreditLimit: properties.point_balance_credit_limit !== undefined ? +properties.point_balance_credit_limit : undefined,
      levelForLowRubBalanceNotification: properties.level_for_low_rub_balance_notification !== undefined ? +properties.level_for_low_rub_balance_notification : undefined,
      levelForLowPointBalanceNotification: properties.level_for_low_point_balance_notification !== undefined ? +properties.level_for_low_point_balance_notification : undefined,
      payinRate: properties.payin_rate !== undefined ? +properties.payin_rate : undefined,
      payoutRate: properties.payout_rate !== undefined ? +properties.payout_rate : undefined,
    };
  }

  public static getInvoicesFromResponse(dataResponse: IGetInvoicesBankResponse): IInvoicesBankAccount {
    const invoice: IInvoicesBankAccount = {
      id: dataResponse.id,
      partnerId: dataResponse.partner_id,
      invoiceType: dataResponse.invoice_type,
      status: dataResponse.status,
      amount: dataResponse.amount,
      createdAt: moment(dataResponse.created_at).format('YYYY-MM-DD'),
      updatedAt: moment(dataResponse.updated_at).format('YYYY-MM-DD'),
      params: null,
      link: null,
    }

    if (dataResponse.link) {
      invoice.link = dataResponse.link
    }

    if (dataResponse.params) {
      invoice.params = {
        bankName: dataResponse.params.bankName,
        bik: dataResponse.params.bik,
        correspondentAccount: dataResponse.params.correspondentAccount,
        invoiceNumber: dataResponse.params.invoiceNumber,
        invoiceDate: {
          date: dataResponse.params.invoiceDate.date,
          timezoneType: dataResponse.params.invoiceDate.timezone_type,
          timezone: dataResponse.params.invoiceDate.timezone
        },
        recipient: {
          companyName: dataResponse.params.recipient.companyName,
          inn: dataResponse.params.recipient.inn,
          kpp: dataResponse.params.recipient.kpp,
          legalAddress: dataResponse.params.recipient.legalAddress,
          checkingAccount: dataResponse.params.recipient.checkingAccount,
        },
        payer: {
          companyName: dataResponse.params.payer.companyName,
          inn: dataResponse.params.payer.inn,
          kpp: dataResponse.params.payer.kpp,
          legalAddress: dataResponse.params.payer.legalAddress,
        },
        amount: dataResponse.params.amount,
      }
    }
        
    return invoice;
  }
}
