import {useAsyncValue, useNavigate} from 'react-router-dom';
import {IClientRank} from '../../../../types/app/client/ClientRank';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {FormContext, IFormConfig, TFieldRule} from '../../../../store/context/form/FormContext';
import {RouteList} from '../../../../http/RouteList';
import MainForm from '../../../Common/Form/MainForm';
import FormOutlinedInput from '../../../../UI/Form/FormOutlinedInput';
import Divider from '../../../../UI/Delimiter/Divider';
import {Grid, Typography} from '@mui/material';
import FormImage from '../../../../UI/Form/FormImage';
import FormActions from '../../../../UI/Form/FormActions';
import FormSwitch from '../../../../UI/Form/FormSwitch';
import {messages} from '../../../../notifications/messages';
import {useNotifications} from '../../../../hooks/useNotifications';
import {useActions} from '../../../../hooks/hooks';

const ClientRanksShowForm = () => {
  const clientRank = useAsyncValue() as IClientRank;
  const {setFiles} = useActions();

  const {config, setConfig} = useContext(FormContext);
  const message = useNotifications();

  const [percent, setPercent] = useState<number>(clientRank.percent);
  const [bonusAmount, setBonusAmount] = useState<number>(clientRank.bonusAmount);
  const [lifetimeDays, setLifetimeDays] = useState<number>(clientRank.lifetimeDays);

  const [minExpenses, setMinExpenses] = useState<number>(clientRank.minExpenses);
  const [minDirectReferrals, setMinDirectReferrals] = useState<number>(clientRank.minDirectReferrals);
  const [minReferrals, setMinReferrals] = useState<number>(clientRank.minReferrals);

  const [isMinExpenses, setIsMinExpenses] = useState<boolean>(clientRank.isMinExpenses);
  const [isMinDirectReferrals, setIsMinDirectReferrals] = useState<boolean>(clientRank.isMinDirectReferrals);
  const [isMinReferrals, setIsMinReferrals] = useState<boolean>(clientRank.isMinReferrals);

  const [paymentPercent, setPaymentPercent] = useState<number>(clientRank.paymentBonus);
  const [referralAmount, setReferralAmount] = useState<number>(clientRank.refferalBonus);

  const getRules = useCallback(() => {
    const rules: TFieldRule = {
      name: ['required'],
      description: ['required'],
      percent: ['required'],
      bonus_amount: ['required'],
      lifetime_days: ['required', 'greaterThan:0'],
    };

    if (isMinExpenses) {
      rules['min_expenses'] = ['required', 'greaterThan:0'];
    }

    if (isMinDirectReferrals) {
      rules['min_direct_referrals'] = ['required', 'greaterThan:0'];
    }

    if (isMinReferrals) {
      rules['min_referrals'] = ['required', 'greaterThan:0'];
    }

    return rules;
  }, [isMinExpenses, isMinDirectReferrals, isMinReferrals]);

  const formConfig: IFormConfig = {
      readonly: true,
      action: RouteList.CLIENTS_RANK_SHOW + '/' + clientRank.id,
      rules: getRules(),
      afterSubmitHandler: () => {
        message.success(messages.success.rankChange);
        setFiles([]);
        setConfig({
          ...formConfig,
          readonly: true,
        })
      },
  }

  useEffect(() => {
    setConfig(formConfig);
  }, []);

  return (
    <MainForm back={RouteList.CLIENTS} boxProps={{width: '110%'}}>
      <FormOutlinedInput
        title="Наименование статуса"
        typography="h2"
        inputProps={{
          name: 'name',
          defaultValue: clientRank.name,
          autoFocus: true,
        }}
      />

      <Divider/>

      <FormOutlinedInput
        title="Описание статуса"
        inputProps={{
          multiline: true,
          minRows: 4,
          name: 'description',
          defaultValue: clientRank.description,
        }}
      />

      <Divider/>
      {
        config.readonly
          ? <Grid>
            <Typography variant="body2" color="grey.600" mb={1}>
              Размер скидки для статуса:
            </Typography>
            <Typography variant="body1" mb={1}>
              Скидка {percent.toLocaleString()} %
            </Typography>
          </Grid>

          : <Grid container columns={12} spacing={2} alignItems="center" mb={1}>
            <Grid item xs={5}>
              <FormOutlinedInput
                title="Размер скидки для статуса, %"
                inputProps={{
                  name: 'percent',
                  value: percent,
                  onChange: e => setPercent(Number(e.target.value)),
                }}
                rule={e => !isNaN(Number(e.target.value))}
              />
            </Grid>
          </Grid>
      }

      <Divider/>

      {
        config.readonly
          ? <Grid>
            <Typography variant="body2" color="grey.600" mb={1}>
              Разовое вознаграждение по достижению статуса, баллы:
            </Typography>
            <Typography variant="body1" mb={1}>
              {bonusAmount}
            </Typography>
          </Grid>

          : <Grid container columns={12} spacing={2} alignItems="center" mb={1}>
            <Grid item xs={5}>
              <FormOutlinedInput
                title="Разовое вознаграждение по достижению статуса, баллы"
                inputProps={{
                  name: 'bonus_amount',
                  value: bonusAmount,
                  onChange: e => setBonusAmount(Number(e.target.value)),
                }}
                rule={e => !isNaN(Number(e.target.value))}
              />
            </Grid>
          </Grid>
      }

      <Divider/>

      <Grid container columns={12} spacing={2} alignItems="center" mb={1}>
        <Grid item xs={7}>
          <FormOutlinedInput
            title="Период сгорания баллов начисленных Пользователю (дней)"
            inputProps={{
              name: 'lifetime_days',
              value: lifetimeDays,
              onChange: e => setLifetimeDays(Number(e.target.value)),
            }}
            rule={e => !isNaN(Number(e.target.value))}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid item xs={7}>
        <FormOutlinedInput
          title="Приоритет"
          inputProps={{
            name: 'order',
            defaultValue: clientRank.order,
          }}
          rule={e => !isNaN(Number(e.target.value))}
        />
      </Grid>

      <Divider/>

      <Grid container columns={12} spacing={2} alignItems="center" mb={1}>
        <Grid item xs={7}>
          <FormOutlinedInput
            title="Процент начисления баллов за оплату покупки рублям"
            inputProps={{
              name: 'payment_percent',
              value: paymentPercent,
              onChange: e => setPaymentPercent(+e.target.value),
            }}
            rule={e => !isNaN(Number(e.target.value))}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Grid container columns={12} spacing={2} alignItems="center" mb={1}>
        <Grid item xs={7}>
          <FormOutlinedInput
            title="Количество начисляемых баллов за привлечение нового пользователя"
            inputProps={{
              name: 'referral_amount',
              value: referralAmount,
              onChange: e => setReferralAmount(+e.target.value),
            }}
            rule={e => !isNaN(Number(e.target.value))}
          />
        </Grid>
      </Grid>

      <Divider/>

      <Typography variant="body2" color="grey.600" mb={1}>
        Критерий статуса:
      </Typography>

      <Grid container columns={12} spacing={2} display="flex" flexDirection="row" alignItems="center">
        <Grid item xs={8}>
          <FormSwitch
            title="Минимальный оборот денежных средств Пользователя"
            switchProps={{
              checked: isMinExpenses,
              onChange: () => {
                setIsMinExpenses(!isMinExpenses);
              },
            }}
          />
        </Grid>
        {isMinExpenses && (
          <Grid item xs={4}>
            <FormOutlinedInput
              title="₽"
              inputProps={{
                name: 'min_expenses',
                value: minExpenses,
                onChange: e => setMinExpenses(Number(e.target.value))
              }}
              rule={e => !isNaN(Number(e.target.value))}
            />
          </Grid>
        )}
      </Grid>

      <Divider/>

      <Grid container columns={12} spacing={2} display="flex" flexDirection="row" alignItems="center">
        <Grid item xs={8}>
          <FormSwitch
            title="Минимальное количество прямых рефералов"
            switchProps={{
              name: 'isMinReferrals',
              checked: isMinDirectReferrals,
              onChange: () => {
                setIsMinDirectReferrals(!isMinDirectReferrals);
              },
            }}
          />
        </Grid>
        {isMinDirectReferrals && (
          <Grid item xs={4}>
            <FormOutlinedInput
              inputProps={{
                name: 'min_direct_referrals',
                value: minDirectReferrals,
                onChange: e => setMinDirectReferrals(Number(e.target.value))
              }}
              rule={e => !isNaN(Number(e.target.value))}
            />
          </Grid>
        )}
      </Grid>

      <Divider/>

      <Grid container columns={12} spacing={2} display="flex" flexDirection="row" alignItems="center">
        <Grid item xs={8}>
          <FormSwitch
            title="Минимальное количество рефералов"
            switchProps={{
              checked: isMinReferrals,
              onChange: () => {
                setIsMinReferrals(!isMinReferrals);
              },
            }}
          />
        </Grid>
        {isMinReferrals && (
          <Grid item xs={4}>
            <FormOutlinedInput
              inputProps={{
                name: 'min_referrals',
                value: minReferrals,
                onChange: e => setMinReferrals(Number(e.target.value))
              }}
              rule={e => !isNaN(Number(e.target.value))}
            />
          </Grid>
        )}
      </Grid>

      <Divider/>

      <Grid container columns={12}>
        <Grid item xs={2}>
          <FormImage height={180} max={1} name="icon" src={clientRank?.icon}/>
        </Grid>
      </Grid>

      <FormActions/>
    </MainForm>
  );
};

export default ClientRanksShowForm;