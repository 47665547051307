import React from 'react';
import {Await, useLoaderData} from 'react-router-dom';
import CatalogProductCreate from './CatalogProductCreate';
import {IGetDataForNewProductLoader} from '../../../http/catalog/CatalogLoaders';

const CatalogCreate = () => {
  const { content } = useLoaderData() as { content: Promise<IGetDataForNewProductLoader> };

  return (
    <Await resolve={content}>
      <CatalogProductCreate />
    </Await>
  );
};

export default CatalogCreate;
