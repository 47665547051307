import { IFormResponse } from '../../store/context/form/types/FormResponse';
import { FormDataHelper } from '../../helpers/FormDataHelper';
import { IUpdateRuleSettingRequest } from '../../types/request/fraud/UpdateRuleSettingRequest';
import { Switchable } from '../../types/app/common/Switchable';
import { FraudSettingConditionNameEnum } from '../../enums/FraudRules';
import { FraudService } from './FraudService';
import { IConditionItem, ICreateRuleSettingRequest } from '../../types/request/fraud/CreateRuleSettingRequest';

export class FraudActions {
  public static async createSettings({ request }): Promise<IFormResponse | IFormResponse[]> {
    const formData = FormDataHelper.toObject<{
      condition_list: string;
      is_active: 'true' | 'false';
      partner_id: string;
      partner_id_list: string;
      partner_terminal_id_list: string;
      rule_name: string;
    }>(await request.formData());

    const createRequest: ICreateRuleSettingRequest = {
      condition_list: FraudActions.getConditionListFromJson(formData.condition_list),
      is_active: formData.is_active === 'true',
      rule_name: formData.rule_name,
    };

    if (formData.partner_id) {
      createRequest.partner_id = +formData.partner_id;

      const terminalsList = JSON.parse(formData.partner_terminal_id_list);

      if (createRequest.partner_id in terminalsList) {
        createRequest.partner_terminal_id_list = terminalsList[createRequest.partner_id];
      }

      return {
        status: true,
        payload: await FraudService.createSetting(createRequest),
      };
    } else if (formData.partner_id_list) {
      const partnerIdList = formData.partner_id_list.split(',').map(item => +item);
      const terminalsList = JSON.parse(formData.partner_terminal_id_list);

      return {
        status: true,
        payload: Promise.all(partnerIdList.map(async partnerId => {
          const request: ICreateRuleSettingRequest = {
            condition_list: createRequest.condition_list,
            is_active: createRequest.is_active,
            rule_name: createRequest.rule_name,
            partner_id: partnerId
          };

          if (partnerId in terminalsList) {
            request.partner_terminal_id_list = terminalsList[partnerId];
          }

          await FraudService.createSetting(request);
        }))
      };
    }

    return {
      status: true,
      payload: await FraudService.createSetting(createRequest),
    };
  }

  public static async updateSetting({ request }): Promise<IFormResponse> {
    const formData = FormDataHelper.toObject<{
      rule_setting_id: string;
      is_active: Switchable;
    }>(await request.formData());

    const updateRequest: IUpdateRuleSettingRequest = {
      rule_setting_id: +formData.rule_setting_id,
      is_active: formData.is_active === Switchable.ON,
      condition_list: FraudActions.getConditionListFromFormData(formData),
    };

    await FraudService.updateSetting(updateRequest);

    return {
      status: true,
    };
  }

  public static getConditionListFromFormData(formData: object): IConditionItem[] {
    const list: IConditionItem[] = [];

    for (const dataKey in formData) {

      if (Object.values(FraudSettingConditionNameEnum).includes(dataKey as FraudSettingConditionNameEnum)) {
        list.push({
          rule_condition_name: dataKey as FraudSettingConditionNameEnum,
          value: formData[dataKey],
        });
      }
    }

    return list;
  }

  private static getConditionListFromJson(jsonString: string): IConditionItem[] {
    const decodedList: { [conditionName: string]: string } = JSON.parse(jsonString);
    const result: IConditionItem[] = [];

    for (const decodedListKey in decodedList) {
      result.push({
        rule_condition_name: decodedListKey as FraudSettingConditionNameEnum,
        value: String(decodedList[decodedListKey]),
      });
    }

    return result;
  }
}
