import React, { Dispatch } from 'react';
import { Badge, Box, Button, Grid } from '@mui/material';
import { RouteList } from '../../../http/RouteList';
import FilterGroupButtons from '../../../UI/Button/FilterGroupButtons';
import PerPage from '../../../UI/Table/PerPage';
import { useNavigate } from 'react-router-dom';
import PercentIcon from '@mui/icons-material/Percent';
import { IFilterable } from '../../../types/app/common/Filterable';
import AddButton from '../../../UI/Button/AddButton';

interface IBillingTabDataTweaksProps extends IFilterable {
  dispatchActual: Dispatch<boolean>;
  actual: boolean | null;
}

const BillingTabDataTweaks = (props: IBillingTabDataTweaksProps) => {
  const route = useNavigate();

  const toggleActual = (action: 'actual' | 'archive') => {
    if (action === 'actual') {
      if (props.actual === true) {
        props.dispatchActual(null);
      } else {
        props.dispatchActual(true);
      }

      return;
    }

    if (props.actual === false) {
      props.dispatchActual(null);
    } else {
      props.dispatchActual(false);
    }
  }

  return (
    <Box mb={3}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container gap={1}>
            <Grid item>
              <Button onClick={() => route(RouteList.BILLING_CREATE_TARIFF)} endIcon={<PercentIcon />}>
                Добавить тариф
              </Button>
            </Grid>
            <Grid item>
              <AddButton onClick={() => route(RouteList.BILLING_CREATE_PROMO)}>
                Добавить акцию
              </AddButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FilterGroupButtons onClick={props.openFilters} reset={props.reset} />
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Badge color="primary" variant="dot" invisible={!(props.actual !== false && props.actual !== null)}>
                <Button
                  onClick={() => toggleActual('actual')}
                  variant="outlined"
                >
                  Включенные
                </Button>
              </Badge>
            </Grid>
            <Grid item>
              <Badge color="primary" variant="dot" invisible={!(props.actual !== true && props.actual !== null)}>
                <Button
                  onClick={() => toggleActual('archive')}
                  variant="outlined"
                >
                  Выключенные
                </Button>
              </Badge>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <PerPage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BillingTabDataTweaks;
