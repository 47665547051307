import React from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import FraudMonitoringRuleSettingCreateForm from '../../../Widgets/FraudMonitoring/RuleSettingsTab/Operations/FraudMonitoringRuleSettingCreateForm';
import {IRule} from '../../../types/app/fraud/Rule';

const FraudMonitoringRuleSettingCreate = () => {
  const { rules } = useLoaderData() as { rules: IRule[] };

  return (
    <Await resolve={rules}>
      <FraudMonitoringRuleSettingCreateForm />
    </Await>
  );
};

export default FraudMonitoringRuleSettingCreate;
