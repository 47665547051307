import {TCategoryData} from '../../types/response/catalog/CategoryData';
import {ICategory} from '../../types/app/catalog/Category';
import moment from 'moment';
import {TProductData} from '../../types/response/catalog/ProductData';
import {IProduct} from '../../types/app/catalog/Product';
import {TProductDetailData} from '../../types/response/catalog/ProductDetailData';
import {IProductDetail} from '../../types/app/catalog/ProductDetail';
import {IStatusHistoryDetail, TStatusHistoryDetail} from '../../types/app/catalog/StatusHistoryDetail';
import {isArray} from 'lodash';

export class CatalogFactory {
  /**a
   * Get category object from response data
   *
   * @param data
   */
  public static getCategoryFromResponse(data: TCategoryData): ICategory {
    return {
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      key: data.id,
      label: data.name,
      parentCategoryId: data.parent_category_id,
      productsCount: data.products_count,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
    };
  }

  /**
   * Get product object from response data
   *
   * @param {TProductData} data
   */
  public static getProductFromResponse(data: TProductData): IProduct {

    return {
      category: data.category,
      categoryId: data.category_id,
      byCertificate: data.by_certificate === '1',
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      description: data.description,
      discountsAndBonuses: isArray(data.discounts_and_bonuses)
        ? null
        : {
        productId: data.discounts_and_bonuses.product_id,
        price: Number(data.discounts_and_bonuses.price),
        discountAmount: data.discounts_and_bonuses.discount_amount,
        bonusPercent: Number(data.discounts_and_bonuses.bonus_percent),
        bonusLifetimeDays: data.discounts_and_bonuses.bonus_lifetime_days,
        bonusDetails: {
          amount: data.discounts_and_bonuses.bonus_details.amount,
          percent: data.discounts_and_bonuses.bonus_details.percent,
          tariffId: data.discounts_and_bonuses.bonus_details.tariff_id,
          typeBonus: data.discounts_and_bonuses.bonus_details.type_bonus,
        },
        discountDetails: {
          amount: data.discounts_and_bonuses.discount_details.amount,
          percent: data.discounts_and_bonuses.discount_details.percent,
          tariffId: data.discounts_and_bonuses.discount_details.tariff_id,
          typeDiscount: data.discounts_and_bonuses.discount_details.type_discount,
        },
      },
      extProductId: data.ext_product_id ?? null,
      id: data.id,
      isRecurrent: data.is_recurrent === '1',
      mainImage: data.main_image,
      mobileImage: data.mobile_image,
      name: data.name,
      partnerId: data.partner_id ?? null,
      price: Number(data.price),
      recurrentPeriod: data.recurrent_period,
      shortDescription: data.short_description,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
      variations: data.variations,
      productStatusId: data.product_status_id
    };
  }

  public static getProductFromResponseLight(data: Pick<TProductData, 'id' | 'name' | 'by_certificate'>): Pick<IProduct, 'name' | 'id' | 'byCertificate'> {
    return {
      byCertificate: data.by_certificate === '1',
      id: data.id,
      name: data.name,
    };
  }

  public static getProductDetailFromResponseData(data: TProductDetailData): IProductDetail {
    return {
      byCertificate: Number(data.by_certificate) === 1,
      categoryId: data.category_id,
      categoryName: data.category_name,
      createdAt: data.created_at ? moment(data.created_at).unix() : null,
      description: data.description,
      extProductId: data.ext_product_id,
      id: data.id,
      isRecurrent: data.is_recurrent,
      name: data.name,
      partnerId: data.partner_id,
      price: Number(data.price),
      productStatusId: data.product_status_id,
      recurrentPeriod: data.recurrent_period,
      shortDescription: data.short_description,
      storageId: data.storage_id,
      updatedAt: data.updated_at ? moment(data.updated_at).unix() : null,
      deliveryType: data.delivery_type,
      mainImage: data.main_image,
      mobileImage: data.mobile_image
    };
  }

  public static getProductStatusHistoryDetails(data: TStatusHistoryDetail[]): IStatusHistoryDetail[] {
    return data.map(item => {
      return {
        createdAt: moment(item.created_at).unix(),
        description: item.description,
        comment: item.comment
      };
    });
  }
}