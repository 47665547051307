import React from 'react';
import { IDialogFilterable } from '../../../../types/app/common/DialogFilterable';
import { defaultFilterState } from './RolesTabContentMain';
import FilterDialog from '../../Filter/FilterDialog';
import FilterInput from '../../Filter/FilterInput';
import Divider from '../../../../UI/Delimiter/Divider';
import FilterSwitch from '../../Filter/FilterSwitch';
import {UserRoleTypes} from '../../../../enums/SystemUserRoles';

const RolesTabFilters = (props: IDialogFilterable<typeof defaultFilterState>) => {
  return (
    <FilterDialog close={props.closeFilters} open={props.isFiltersOpen} apply={props.applyFilters}>
      <FilterInput
        label="Наименование роли"
        addFilter={props.addFilter}
        field="role"
        filters={props.filters}
        placeholder="Укажите Email конкретного Пользователя"
      />

      <Divider />

      <FilterSwitch
        label="Тип"
        addFilter={props.addFilter}
        field="type"
        filters={props.filters}
        items={[
          {key: UserRoleTypes.SYSTEM, label: 'Системный'},
          {key: UserRoleTypes.CUSTOM, label: 'Пользовательский'},
        ]}
      />
      <Divider />
    </FilterDialog>
  );
};

export default RolesTabFilters;
