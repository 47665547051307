import { IProduct } from '../../types/app/catalog/Product';
import { ICategory } from '../../types/app/catalog/Category';
import { IPartner } from '../../types/app/partner/Partner';
import { defer } from 'react-router-dom';
import { CatalogService } from '../catalog/CatalogService';
import { PartnerService } from '../partner/PartnerService';
import { BillingService } from './BillingService';
import {ITariffDetail} from '../../types/app/billing/TariffDetail';
import {IPromo} from '../../types/app/billing/bonuses/Promo';

export interface ITariffCreateLoader {
  products: Pick<IProduct, 'name' | 'id'>[];
  categories: ICategory[];
  partners: IPartner[];
}

export interface ITariffShowLoader extends ITariffCreateLoader {
  tariff: ITariffDetail;
}

export interface IPromoCreateLoader {
  products: Pick<IProduct, 'name' | 'id' | 'byCertificate'>[];
  categories: ICategory[];
  partners: IPartner[];
}

export interface IShowPromoLoader extends IPromoCreateLoader {
  promo: IPromo,
}

export class BillingLoaders {
  public static async showPromoLoader({params}) {
    return defer({
      content: Promise.all([
        BillingService.getPromo({id: params.id}),
        CatalogService.getFilteredProductsLight({light: true}),
        CatalogService.getCategories(),
        PartnerService.getPartners(),
      ])
        .then(data => ({
          promo: data[0],
          products: data[1],
          categories: data[2],
          partners: data[3],
        } as IShowPromoLoader))
    });
  }

  public static async createPromoLoader() {
    return defer({
      content: Promise.all([
        CatalogService.getFilteredProductsLight({light: true}),
        CatalogService.getCategories(),
        PartnerService.getPartners(),
      ]).then(
        data =>
          ({
            products: data[0],
            categories: data[1],
            partners: data[2],
          } as IPromoCreateLoader)
      ),
    });
  }

  public static async createTariffLoader() {
    return defer({
      content: Promise.all([
        CatalogService.getFilteredProductsLight({light: true}),
        CatalogService.getCategories(),
        PartnerService.getPartners(),
      ]).then(
        data =>
          ({
            products: data[0],
            categories: data[1],
            partners: data[2],
          } as ITariffCreateLoader)
      ),
    });
  };

  public static showTariffLoader = async ({ params }) => {
    return defer({
      content: Promise.all([
        BillingService.getTariff({id: params.id}),
        CatalogService.getFilteredProductsLight({light: true}),
        CatalogService.getCategories(),
        PartnerService.getPartners(),
      ]).then(
        data =>
          ({
            tariff: data[0],
            products: data[1],
            categories: data[2],
            partners: data[3],
          } as ITariffShowLoader)
      ),
    });
  };
}
